/* eslint-disable react-hooks/exhaustive-deps */
import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";
import { MmsTextInput } from "@uiKit/Atoms/Inputs/MmsTextInput";
import { validateForm } from "@utils/formik";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import { styles } from "./CategoryEditTitle.styles";
import { validationMap } from "./CategoryEditTitle.validationMap";
import { useTranslation } from "react-i18next";

interface FormValues {
  title: string;
}

export interface CategoryEditTitleProps {
  categoryId: string;
  isFormSubmitting: boolean;
  onSubmitEnded: VoidFunction;
  onPressEsc: VoidFunction;
}

const CategoryEditTitle = ({
  categoryId,
  isFormSubmitting,
  onSubmitEnded,
  onPressEsc,
  ...formikProps
}: CategoryEditTitleProps & FormikProps<FormValues>) => {
  const { t } = useTranslation(["category"]);

  const formId = `category-edit-title-${categoryId}`;
  const [
    activeForms,
    addToActiveForms,
    removeFromActiveForms,
  ] = useActiveForms();

  const handleBlur = useCallback(() => {
    formikProps.submitForm();
  }, []);

  const handlePressEsc = useCallback(() => {
    onSubmitEnded();
    onPressEsc();
  }, []);

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useDidUpdate(() => {
    if (!isFormSubmitting) onSubmitEnded();
  }, [isFormSubmitting]);

  useEffect(() => {
    if (formikProps.isValid) return;

    if (activeForms.length > 1) {
      handlePressEsc();
    }
  }, [formikProps.isValid, activeForms]);

  return (
    <Form className={styles.form}>
      <MmsTextInput
        data-testid={formId}
        name="title"
        type="text"
        placeholder={t("category:enter-category-title-here")}
        disabled={isFormSubmitting}
        focusOnMount
        showErrorMessage
        blurOnEnter
        enableSpellCheck
        cursorToEnd
        onBlur={handleBlur}
        onPressEsc={handlePressEsc}
        fieldClassName="EditCategoryInput"
      />
    </Form>
  );
};

interface CategoryEditTitleFormProps extends CategoryEditTitleProps {
  title?: string;
  onSubmit: (values: any) => void;
}

// Wrap our form with the withFormik HoC
export const CategoryEditTitleForm = withFormik<
  CategoryEditTitleFormProps,
  FormValues
>({
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    title: props.title || "",
  }),
  validate: validateForm(validationMap),
  handleSubmit: (values, { props }) => {
    values.title !== props.title
      ? props.onSubmit(values)
      : props.onSubmitEnded();
  },
})(CategoryEditTitle);
