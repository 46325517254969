import { gql } from "@apollo/client";

export const ADDITIVE_ESSENTIAL_DATA = gql`
  fragment AdditiveEssentialData on Additive {
    id
    code
    title
    country
  }
`;
