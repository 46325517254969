import { gql } from "@apollo/client";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";

export const UPDATE_LIBRARY = gql`
  mutation UpdateLibraryMutation($input: LibraryUpdate!) {
    libraryTouch(input: $input) {
      id
      originType
      title
      favorite
      displayData {
        title
        items
      }
    }
  }
`;

export const generateUpdateLibraryVars = (
  assetType: MenuAssetEnum,
  id: string,
  title?: string
) => {
  if (assetType === MenuAssetEnum.menu) {
    return { input: { id, title } };
  }
  return { input: { id } };
};

export * as UpdateLibraryTypes from "./__generated__/UpdateLibraryMutation";
