export enum YesNoFilterEnum {
  Yes = "Yes",
  No = "No",
}

export interface MenuFilters {
  discountPresent?: YesNoFilterEnum;
  activeFromPresent?: YesNoFilterEnum;
  activenessPresent?: YesNoFilterEnum;
  titleCont?: string;
}
