import { ModelLocalAddon, ModelMenuAddon } from "@model/DAO/MenuAddon";
import { ModelMenuFlag } from "@model/DAO/MenuFlag";
import { AddonTypeEnum, LocalAddonItemsMeta } from "@model/helperTypes/Addon";
import { convertAddonItemsToNew } from "@pages/RestaurantMenuPage/utils/AddonItem";
import { generateUniqueKey } from "@utils/general";
import produce from "immer";
import { insert } from "ramda";
import { useCallback, useReducer } from "react";
import {
  checkIsAddonNew,
  convertMenuAddonToLocalAddon,
  decomposeAddonType,
} from "../utils/Addon";

export type LocalAddonsCallbacksType = {
  create: (addon: ModelLocalAddon) => void;
  clone: (addonId: string) => void;
  remove: (addonId: string) => void;
  toggle: (addonId: string, autoenableTimestamp: number) => void;
  changeType: (addonId: string, newType: AddonTypeEnum) => void;
  changeTitle: (addonId: string, newTitle: string) => void;
  changeAddonMenuFlags: (
    addonId: string,
    newMenuFlags: ModelMenuFlag[]
  ) => void;
  changeAddonItems: (
    addonId: string,
    newItemsMeta: LocalAddonItemsMeta
  ) => void;
  togglePriceLevelDependent: (addonId: string) => void;
  reset: VoidFunction;
  reorder: (reorderedLocalAddons: ModelLocalAddon[]) => void;
  changeMinMax: (
    addonId: string,
    values: { min?: number; max?: number }
  ) => void;
};

const CREATE = "CREATE";
const REMOVE = "REMOVE";
const CHANGE = "CHANGE";
const SET = "SET";

type CreateAddonActionType = {
  type: typeof CREATE;
  payload: {
    addon: ModelLocalAddon;
  };
};

type RemoveAddonActionType = {
  type: typeof REMOVE;
  payload: {
    addon: ModelLocalAddon;
  };
};

type ChangeAddonActionType = {
  type: typeof CHANGE;
  payload: {
    addon: ModelLocalAddon;
  };
};

type SetStateActionType = {
  type: typeof SET;
  payload: {
    state: LocalAddonsReducerStateType;
  };
};

type LocalAddonsReducerActionType =
  | CreateAddonActionType
  | RemoveAddonActionType
  | ChangeAddonActionType
  | SetStateActionType;

const localAddonsReducer = (
  state: LocalAddonsReducerStateType,
  action: LocalAddonsReducerActionType
): LocalAddonsReducerStateType => {
  const { localAddons, newAddons, removedAddons, changedAddons } = state;

  switch (action.type) {
    case CREATE: {
      const { addon } = action.payload;

      return {
        localAddons: [...localAddons, addon],
        newAddons: [...newAddons, addon],
        removedAddons,
        changedAddons,
      };
    }
    case REMOVE: {
      const { addon } = action.payload;
      const isNew = checkIsAddonNew(addon.id);

      return {
        localAddons: localAddons.filter(({ id }) => id !== addon.id),
        newAddons: isNew
          ? newAddons.filter(({ id }) => id !== addon.id)
          : newAddons,
        removedAddons: isNew ? removedAddons : [...removedAddons, addon],
        changedAddons: isNew
          ? changedAddons
          : changedAddons.filter(({ id }) => id !== addon.id),
      };
    }
    case CHANGE: {
      const { addon } = action.payload;
      const isNew = checkIsAddonNew(addon.id);
      const changedLocalAddonIndex = localAddons.findIndex(
        (localAddon) => localAddon.id === addon.id
      );

      return {
        // to not to break the order
        localAddons: produce(localAddons, (newLocalAddons) => {
          newLocalAddons.splice(changedLocalAddonIndex, 1, addon);
        }),
        // order is not important here
        newAddons: isNew
          ? [...newAddons.filter(({ id }) => id !== addon.id), addon]
          : newAddons,
        removedAddons,
        // order is not important here
        changedAddons: isNew
          ? changedAddons
          : [...changedAddons.filter(({ id }) => id !== addon.id), addon],
      };
    }
    case SET: {
      return action.payload.state;
    }
  }
};

export type LocalAddonsReducerStateType = {
  localAddons: ModelLocalAddon[];
  newAddons: ModelLocalAddon[];
  removedAddons: ModelLocalAddon[];
  changedAddons: ModelLocalAddon[];
};

const getInitState = (
  initialAddons: ModelMenuAddon[]
): LocalAddonsReducerStateType => ({
  localAddons: initialAddons.map((addon) =>
    convertMenuAddonToLocalAddon(addon)
  ),
  newAddons: [],
  removedAddons: [],
  changedAddons: [],
});

const getReorderedState = (
  state: LocalAddonsReducerStateType,
  reorderedLocalAddons: ModelLocalAddon[]
): LocalAddonsReducerStateType => ({
  ...state,
  localAddons: reorderedLocalAddons,
  newAddons: state.newAddons.map((newAddon) => ({
    ...newAddon,
    orderBy: reorderedLocalAddons.find(
      (reorderedLocalAddon) => reorderedLocalAddon.id === newAddon.id
    )!.orderBy,
  })),
  changedAddons: [
    // first, add new orderBy for currently changed addons
    ...state.changedAddons.map((changedAddon) => ({
      ...changedAddon,
      orderBy: reorderedLocalAddons.find(
        (reorderedLocalAddon) => reorderedLocalAddon.id === changedAddon.id
      )!.orderBy,
    })),
    // second, add new orderBy for the rest addons that wasn't changed yet and wasn't in newAddons array
    ...reorderedLocalAddons.filter((reorderedLocalAddon) =>
      [
        state.newAddons.every(
          (newAddon) => newAddon.id !== reorderedLocalAddon.id
        ),
        state.changedAddons.every(
          (changedAddon) => changedAddon.id !== reorderedLocalAddon.id
        ),
      ].every(Boolean)
    ),
  ],
});

export const useLocalAddons = (
  initialAddons: ModelMenuAddon[]
): [LocalAddonsReducerStateType, LocalAddonsCallbacksType] => {
  const [state, dispatch] = useReducer(
    localAddonsReducer,
    getInitState(initialAddons)
  );

  const create = useCallback((addon: ModelLocalAddon) => {
    dispatch({
      type: CREATE,
      payload: { addon: { ...addon, id: addon.id.substring("temp-".length) } },
    });
  }, []);

  const clone = useCallback(
    (addonId: string) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const originalLocalAddonIndex = state.localAddons.findIndex(
        ({ id }) => id === addonId
      );

      const clonedAddon: ModelLocalAddon = {
        ...addon,
        title: `Copy of ${addon.title}`,
        id: `cloned-${generateUniqueKey()}`,
        itemsMeta: {
          localAddonItems: convertAddonItemsToNew(
            addon.itemsMeta.localAddonItems
          ),
          newAddonItems: convertAddonItemsToNew(
            addon.itemsMeta.localAddonItems
          ),
          changedAddonItems: [],
          removedAddonItems: [],
        },
      };

      const reorderedLocalAddons: ModelLocalAddon[] = insert(
        originalLocalAddonIndex + 1,
        clonedAddon,
        state.localAddons
      ).map((addon, index) => ({ ...addon, orderBy: index + 1 }));

      let newState = { ...state, newAddons: [...state.newAddons, clonedAddon] };
      newState = getReorderedState(newState, reorderedLocalAddons);

      dispatch({
        type: SET,
        payload: { state: newState },
      });
    },
    [state]
  );

  const remove = useCallback(
    (addonId: string) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      dispatch({ type: REMOVE, payload: { addon } });
    },
    [state.localAddons]
  );

  const toggle = useCallback(
    (addonId: string, autoenableTimestamp: number) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const changedAddon = {
        ...addon,
        active: !addon.active,
        autoenableTimestamp,
      };

      dispatch({ type: CHANGE, payload: { addon: changedAddon } });
    },
    [state.localAddons]
  );

  const changeType = useCallback(
    (addonId: string, newType: AddonTypeEnum) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const changedAddon = {
        ...addon,
        ...decomposeAddonType(newType),
      };

      dispatch({ type: CHANGE, payload: { addon: changedAddon } });
    },
    [state.localAddons]
  );

  const changeTitle = useCallback(
    (addonId: string, newTitle: string) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const changedAddon = {
        ...addon,
        title: newTitle,
      };

      dispatch({ type: CHANGE, payload: { addon: changedAddon } });
    },
    [state.localAddons]
  );

  const changeAddonMenuFlags = useCallback(
    (addonId: string, newMenuFlags: ModelMenuFlag[]) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const changedAddon = {
        ...addon,
        menuFlags: newMenuFlags,
      };

      dispatch({ type: CHANGE, payload: { addon: changedAddon } });
    },
    [state.localAddons]
  );

  const changeAddonItems = useCallback(
    (addonId: string, newItemsMeta: LocalAddonItemsMeta) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const changedAddon = {
        ...addon,
        itemsMeta: newItemsMeta,
      };

      dispatch({ type: CHANGE, payload: { addon: changedAddon } });
    },
    [state.localAddons]
  );

  const togglePriceLevelDependent = useCallback(
    (addonId: string) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const changedAddon = {
        ...addon,
        priceLevelDependent: !addon.priceLevelDependent,
      };

      dispatch({ type: CHANGE, payload: { addon: changedAddon } });
    },
    [state.localAddons]
  );

  const changeMinMax = useCallback(
    (addonId: string, values: { min?: number; max?: number }) => {
      const addon = state.localAddons.find(
        ({ id }) => id === addonId
      ) as ModelLocalAddon;

      const changedAddon = {
        ...addon,
        min: values.min,
        max: values.max,
      };

      dispatch({ type: CHANGE, payload: { addon: changedAddon } });
    },
    [state.localAddons]
  );

  const reset = useCallback(() => {
    dispatch({
      type: SET,
      payload: { state: getInitState(initialAddons) },
    });
  }, [initialAddons]);

  const reorder = useCallback(
    (reorderedLocalAddons: ModelLocalAddon[]) => {
      const newState = getReorderedState(state, reorderedLocalAddons);

      dispatch({
        type: SET,
        payload: { state: newState },
      });
    },
    [state]
  );

  return [
    state,
    {
      create,
      clone,
      remove,
      toggle,
      changeType,
      changeTitle,
      changeAddonMenuFlags,
      changeAddonItems,
      togglePriceLevelDependent,
      changeMinMax,
      reset,
      reorder,
    },
  ];
};
