import { gql } from "@apollo/client";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import {
  BulkRequestActionEnum,
  LevelsBulkEnum,
} from "@model/helperTypes/BulkEditing";

export const BULK_DESTROY_ITEMS = gql`
  mutation BulkDestroyItemsMutation(
    $categoryInput: BulkData!
    $menuItemInput: BulkData!
    $isCategoriesSelected: Boolean!
    $isMenuItemsSelected: Boolean!
  ) {
    categoryDestroy: mmsBulk(input: $categoryInput)
      @include(if: $isCategoriesSelected)
    menuItemDestroy: mmsBulk(input: $menuItemInput)
      @include(if: $isMenuItemsSelected)
  }
`;

export const generateBulkDestroyItemsVars = (
  restaurantId: string,
  selectedCategoriesIds: ModelFullCategory["id"][],
  selectedMenuItemsIds: ModelMenuItem["id"][]
) => ({
  categoryInput: {
    restaurantId,
    bulkAction: BulkRequestActionEnum.destroy,
    levelType: LevelsBulkEnum.category,
    ids: selectedCategoriesIds,
  },
  menuItemInput: {
    restaurantId,
    bulkAction: BulkRequestActionEnum.destroy,
    levelType: LevelsBulkEnum.menu_item,
    ids: selectedMenuItemsIds,
  },
  isCategoriesSelected: !!selectedCategoriesIds.length,
  isMenuItemsSelected: !!selectedMenuItemsIds.length,
});

export * as BulkDestroyItemsTypes from "./__generated__/BulkDestroyItemsMutation";
