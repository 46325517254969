import { useReactiveVar } from "@apollo/client";
import { IS_IFRAME } from "@constants";
import { cx } from "@emotion/css";
import MuiModal, { ModalProps as MuiModalProps } from "@material-ui/core/Modal";
import { screenWidthVar } from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import React from "react";
import { styles } from "./styles";

export interface MmsBaseModalProps {
  contentClassName: string;
  closeOnEscPress?: boolean;
  closeOnOutsideClick?: boolean;
  onClose: VoidFunction;
  children: React.ReactNode;
}

export const MmsBaseModal: React.FC<MmsBaseModalProps> = React.forwardRef<
  MuiModalProps,
  MmsBaseModalProps
>(
  (
    {
      contentClassName,
      closeOnEscPress = true,
      closeOnOutsideClick,
      onClose,
      children,
      ...rest
    },
    ref
  ) => {
    const screenWidth = useReactiveVar(screenWidthVar);

    return (
      <MuiModal
        {...rest}
        ref={ref}
        open
        onClose={closeOnOutsideClick ? onClose : noop}
        className={styles.container(IS_IFRAME, screenWidth)}
        BackdropProps={{ invisible: true }}
      >
        <div className={cx(styles.content, contentClassName)}>{children}</div>
      </MuiModal>
    );
  }
);
