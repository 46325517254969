import { useReactiveVar } from "@apollo/client";
import { useDidUpdate } from "@hooks";
import { PopupNotificationTypesEnum } from "@model/helperTypes/Notification";
import { MmsNotification } from "@uiKit/Atoms/Notification/MmsNotification";
import { NOTIFICATION_DURATION } from "@uiKit/Atoms/Notification/MmsNotification/constants";
import { regularErrorsVar } from "@utils/apolloReactiveVars";
import throttle from "lodash.throttle";
import { uniq } from "ramda";
import React from "react";
import toast from "react-hot-toast";

export const useErrorRegularNotifications = () => {
  const regularErrors = useReactiveVar(regularErrorsVar);

  useDidUpdate(() => {
    if (!regularErrors.length) return;

    regularErrors.forEach((errorMessage) => {
      toast(
        (t) => (
          <MmsNotification
            message={errorMessage}
            type={PopupNotificationTypesEnum.error}
            showCloseButton={false}
            handleClose={() => toast.dismiss(t.id)}
          />
        ),
        {
          duration: NOTIFICATION_DURATION, // library doesn't support infinite notifications
        }
      );
    });

    regularErrorsVar([]);
  }, [regularErrors]);
};

export const addMultipleErrorRegularNotifications = (errorMessages: string[]) =>
  regularErrorsVar([...regularErrorsVar(), ...errorMessages]);

export const addErrorRegularNotification = (errorMessage: string) =>
  regularErrorsVar([...regularErrorsVar(), errorMessage]);

export const addUniqThrottledErrorRegularNotification = throttle(
  (errorMessage: string) =>
    regularErrorsVar(uniq([...regularErrorsVar(), errorMessage])),
  NOTIFICATION_DURATION,
  { trailing: false }
);
