import { useReactiveVar } from "@apollo/client";
import { AddonTypeEnum } from "@model/helperTypes/Addon";
import {
  AddonsSettingsStrategyEnum,
  NewDefaultAddonsSettingsProps,
} from "@model/helperTypes/AddonsSettings";
import { StatusBarLayout } from "@pages/RestaurantMenuPage/components/CommonSettings/StatusBarLayout/StatusBarLayout";
import { AddonsSettingsDefaultContentLayout } from "@pages/RestaurantMenuPage/layouts/AddonsSettings/DefaultContentLayout/DefaultContentLayout";
import {
  determineAddonStrategy,
  determineAddonType,
} from "@pages/RestaurantMenuPage/utils/Addon";
import { checkPreparingNewAddonItemAvailable } from "@pages/RestaurantMenuPage/utils/AddonItem";
import { themeColors } from "@theme/colors";
import { MmsAddNewEntityButton } from "@uiKit/Atoms/Buttons/MmsAddNewEntityButton";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsSeparator } from "@uiKit/Atoms/Separator/MmsSeparator";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { BtnFlatList } from "@uiKit/Molecules/BtnGroup/BtnFlatList";
import { MmsDummyTab } from "@uiKit/Molecules/Tabs/MmsTab/dummy";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { menuFlagsVar } from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import { curry } from "ramda";
import React, { useMemo } from "react";
import { AddonsSettingsBaseLayout } from "../../../layouts/AddonsSettings/BaseLayout/BaseLayout";
import { AddonItemsSettingsForm } from "../../AddonItemsSettings/AddonItemsSettingsForm";
import { CommonSettingContentTitle } from "../../CommonSettings/ContentTitle/ContentTitle";
import { MenuFlagsForm } from "../../MenuItemSettings";
import { NewAddonList } from "../NewAddonList/NewAddonList";
import { NewAddonTabContentControls } from "../NewAddonTabContentControls/NewAddonTabContentControls";
import { NewAddonTabHeaderControls } from "../NewAddonTabHeaderControls/NewAddonTabHeaderControls";
import { NewStatusBarTitle } from "../NewStatusBar/Title/Title";
import { NewStatusBarToggleAttachButton } from "../NewStatusBar/ToggleAttachButton/ToggleAttachButton";
import { styles } from "./NewDefaultAddonsSettings.styles";
import { useTranslation } from "react-i18next";

export const NewDefaultAddonsSettings: React.FC<NewDefaultAddonsSettingsProps> = ({
  addonsSettingsStrategy,
  restaurantId,
  parentTitle,
  isUpdateProcessing,
  isAddonsChanged,
  isReadyToSaveToLibrary,
  isPriceLevelAddonAvailable,
  selectedAddon,
  newAddon,
  baseAddonListProps,
  baseAddonItemsSettingsProps,
  addNewAddonClick,
  addNewAddonItemClick,
  onCancelClick,
  onUpdateClick,
  toggleAttachToMenuItem,
  updateAddonsAndProceed,
  setIsReadyToSaveToLibrary,
  changeAddonTitle,
  onChangeAddonType,
  changeAddonMenuFlags,
  togglePriceLevelDependent,
  onMinMaxSet,
}) => {
  const { t } = useTranslation(["addons", "common"]);
  const menuFlags = useReactiveVar(menuFlagsVar);

  const extendedParentTitle = useMemo(() => `${parentTitle} addons`, [
    parentTitle,
  ]);
  const selectedAddonType = useMemo(() => determineAddonType(selectedAddon!), [
    selectedAddon,
  ]);

  const showAddNewAddonItemButton = useMemo(
    () =>
      checkPreparingNewAddonItemAvailable(
        selectedAddonType,
        selectedAddon!.itemsMeta.localAddonItems.length
      ),
    [selectedAddon, selectedAddonType]
  );

  const addonmenuFlagsKey = `addon-menu-flags-${selectedAddon!.id}`;

  switch (addonsSettingsStrategy) {
    case AddonsSettingsStrategyEnum.category: {
      return (
        <AddonsSettingsBaseLayout
          statusBar={
            <StatusBarLayout
              leftCol={<NewStatusBarTitle title={extendedParentTitle} />}
            />
          }
          content={
            <AddonsSettingsDefaultContentLayout
              leftColContent={
                <>
                  <NewAddonList {...baseAddonListProps} />
                  <AccessControl>
                    <MmsDummyTab dummyClassName={styles.addNewAddonTab}>
                      <MmsAddNewEntityButton
                        id="addons-settings-add-new-addon-btn"
                        btnTitle={t("addons:add-new-addon")}
                        onClick={addNewAddonClick}
                        disabled={isUpdateProcessing}
                      />
                    </MmsDummyTab>
                  </AccessControl>
                </>
              }
              rightColHeaderTitle={
                <CommonSettingContentTitle
                  key={selectedAddon!.id}
                  uniqId={selectedAddon!.id}
                  title={selectedAddon!.title}
                  editModePlaceholder={t("addons:enter-addon-title-here")}
                  isInitEditMode={newAddon?.id === selectedAddon!.id}
                  isEditModeDisabled={getIsPartner()}
                  onChangeTitle={curry(changeAddonTitle)(selectedAddon!.id)}
                />
              }
              rightColHeaderControls={
                <NewAddonTabHeaderControls
                  restaurantId={restaurantId}
                  addonId={selectedAddon!.id}
                  isAddonsChanged={isAddonsChanged}
                  isUpdateProcessing={isUpdateProcessing}
                  isReadyToSaveToLibrary={isReadyToSaveToLibrary}
                  updateAddonsAndProceed={updateAddonsAndProceed}
                  setIsReadyToSaveToLibrary={setIsReadyToSaveToLibrary}
                />
              }
              rightColContent={
                <>
                  <NewAddonTabContentControls
                    addonsSettingsStrategy={addonsSettingsStrategy}
                    selectedAddon={selectedAddon}
                    isUpdateProcessing={isUpdateProcessing}
                    isPriceLevelAddonAvailable={isPriceLevelAddonAvailable}
                    onChangeAddonType={curry(onChangeAddonType)(
                      selectedAddon!.id
                    )}
                    togglePriceLevelDependent={togglePriceLevelDependent.bind(
                      null,
                      selectedAddon!.id
                    )}
                    onMinMaxSet={onMinMaxSet}
                  />
                  {selectedAddonType !== AddonTypeEnum.priceLevel && (
                    <React.Fragment key={addonmenuFlagsKey}>
                      <MmsSeparator className={styles.contentSeparator} />
                      <MmsFormTitle>Menu Flags</MmsFormTitle>
                      <MenuFlagsForm
                        isAlwaysOpen={false}
                        menuFlags={menuFlags}
                        selectedMenuFlags={selectedAddon?.menuFlags || []}
                        onSubmit={curry(changeAddonMenuFlags)(
                          selectedAddon!.id
                        )}
                      />
                    </React.Fragment>
                  )}
                  <MmsSeparator className={styles.contentSeparator} />
                  <AddonItemsSettingsForm
                    key={selectedAddon!.id}
                    addonItemsSettingsProps={{
                      ...baseAddonItemsSettingsProps,
                      addon: selectedAddon!,
                      addonType: selectedAddonType,
                      addonStrategy: determineAddonStrategy(
                        addonsSettingsStrategy,
                        selectedAddon!.priceLevel,
                        selectedAddon!.priceLevelDependent
                      ),
                      isPriceLevelDependent: selectedAddon!.priceLevelDependent,
                    }}
                    addonItemsMeta={selectedAddon!.itemsMeta}
                  />
                </>
              }
              rightColFooter={
                <JustifyContentBetweenLayout className={styles.btnGroup}>
                  {showAddNewAddonItemButton ? (
                    <MmsAddNewEntityButton
                      id="addons-settings-add-new-addon-item-btn"
                      containerClassName={styles.addNewAddonItemBtn}
                      btnTitle={t("addons:add-new-item-to-category", {
                        categoryTitle: selectedAddon!.title,
                      })}
                      onClick={addNewAddonItemClick}
                      disabled={isUpdateProcessing}
                    />
                  ) : (
                    <div />
                  )}
                  <BtnFlatList
                    list={[
                      <MmsSecondaryButton
                        id="addons-settings-cancel-btn"
                        className={styles.commonBtn}
                        onClick={onCancelClick}
                        disabled={isUpdateProcessing}
                      >
                        {t("common:cancel")}
                      </MmsSecondaryButton>,
                      <MmsPrimaryButton
                        id="addons-settings-update-btn"
                        className={styles.commonBtn}
                        onClick={onUpdateClick}
                        processing={isUpdateProcessing}
                        disabled={!isAddonsChanged}
                      >
                        {t("common:update")}
                      </MmsPrimaryButton>,
                    ]}
                  />
                </JustifyContentBetweenLayout>
              }
            />
          }
        />
      );
    }
    case AddonsSettingsStrategyEnum.menu_item_attached: {
      return (
        <AddonsSettingsBaseLayout
          statusBar={
            <StatusBarLayout
              bgColor={themeColors.white}
              leftCol={
                <NewStatusBarTitle
                  title={extendedParentTitle}
                  color={themeColors.black}
                />
              }
              rightCol={
                <AccessControl>
                  <NewStatusBarToggleAttachButton
                    title={t("addons:connect-addons-to-category")}
                    color={themeColors.mediumGray}
                    onClick={toggleAttachToMenuItem}
                  />
                </AccessControl>
              }
            />
          }
          content={
            <AddonsSettingsDefaultContentLayout
              leftColContent={
                <>
                  <NewAddonList {...baseAddonListProps} />
                  <AccessControl>
                    <MmsDummyTab dummyClassName={styles.addNewAddonTab}>
                      <MmsAddNewEntityButton
                        id="addons-settings-add-new-addon-btn"
                        btnTitle={t("addons:add-new-addon")}
                        onClick={addNewAddonClick}
                        disabled={isUpdateProcessing}
                      />
                    </MmsDummyTab>
                  </AccessControl>
                </>
              }
              rightColHeaderTitle={
                <CommonSettingContentTitle
                  key={selectedAddon!.id}
                  uniqId={selectedAddon!.id}
                  title={selectedAddon!.title}
                  editModePlaceholder={t("addons:enter-addon-title-here")}
                  isInitEditMode={newAddon?.id === selectedAddon!.id}
                  isEditModeDisabled={getIsPartner()}
                  onChangeTitle={curry(changeAddonTitle)(selectedAddon!.id)}
                />
              }
              rightColHeaderControls={
                <NewAddonTabHeaderControls
                  restaurantId={restaurantId}
                  addonId={selectedAddon!.id}
                  isAddonsChanged={isAddonsChanged}
                  isUpdateProcessing={isUpdateProcessing}
                  isReadyToSaveToLibrary={isReadyToSaveToLibrary}
                  updateAddonsAndProceed={updateAddonsAndProceed}
                  setIsReadyToSaveToLibrary={setIsReadyToSaveToLibrary}
                />
              }
              rightColContent={
                <>
                  <NewAddonTabContentControls
                    addonsSettingsStrategy={addonsSettingsStrategy}
                    selectedAddon={selectedAddon}
                    isUpdateProcessing={isUpdateProcessing}
                    isPriceLevelAddonAvailable={isPriceLevelAddonAvailable}
                    onChangeAddonType={curry(onChangeAddonType)(
                      selectedAddon!.id
                    )}
                    togglePriceLevelDependent={togglePriceLevelDependent.bind(
                      null,
                      selectedAddon!.id
                    )}
                    onMinMaxSet={onMinMaxSet}
                  />
                  {selectedAddonType !== AddonTypeEnum.priceLevel && (
                    <React.Fragment key={addonmenuFlagsKey}>
                      <MmsSeparator className={styles.contentSeparator} />
                      <MmsFormTitle>Menu Flags</MmsFormTitle>
                      <MenuFlagsForm
                        isAlwaysOpen={false}
                        menuFlags={menuFlags}
                        selectedMenuFlags={selectedAddon?.menuFlags || []}
                        onSubmit={curry(changeAddonMenuFlags)(
                          selectedAddon!.id
                        )}
                      />
                    </React.Fragment>
                  )}
                  <MmsSeparator className={styles.contentSeparator} />
                  <AddonItemsSettingsForm
                    key={selectedAddon!.id}
                    addonItemsSettingsProps={{
                      ...baseAddonItemsSettingsProps,
                      addon: selectedAddon!,
                      addonType: selectedAddonType,
                      addonStrategy: determineAddonStrategy(
                        addonsSettingsStrategy,
                        selectedAddon!.priceLevel,
                        selectedAddon!.priceLevelDependent
                      ),
                      isPriceLevelDependent: selectedAddon!.priceLevelDependent,
                    }}
                    addonItemsMeta={selectedAddon!.itemsMeta}
                  />
                </>
              }
              rightColFooter={
                <JustifyContentBetweenLayout className={styles.btnGroup}>
                  {showAddNewAddonItemButton ? (
                    <MmsAddNewEntityButton
                      id="addons-settings-add-new-addon-item-btn"
                      containerClassName={styles.addNewAddonItemBtn}
                      btnTitle={t("addons:add-new-item-to", {
                        item: selectedAddon!.title,
                      })}
                      onClick={addNewAddonItemClick}
                      disabled={isUpdateProcessing}
                    />
                  ) : (
                    <div />
                  )}
                  <BtnFlatList
                    list={[
                      <MmsSecondaryButton
                        id="addons-settings-cancel-btn"
                        className={styles.commonBtn}
                        onClick={onCancelClick}
                        disabled={isUpdateProcessing}
                      >
                        {t("common:cancel")}
                      </MmsSecondaryButton>,
                      <MmsPrimaryButton
                        id="addons-settings-update-btn"
                        className={styles.commonBtn}
                        onClick={onUpdateClick}
                        processing={isUpdateProcessing}
                        disabled={!isAddonsChanged}
                      >
                        {t("common:update")}
                      </MmsPrimaryButton>,
                    ]}
                  />
                </JustifyContentBetweenLayout>
              }
            />
          }
        />
      );
    }
    case AddonsSettingsStrategyEnum.menu_item_detached: {
      return (
        <AddonsSettingsBaseLayout
          statusBar={
            <StatusBarLayout
              bgColor={themeColors.lightGreen}
              leftCol={
                <NewStatusBarTitle
                  title={extendedParentTitle}
                  color={themeColors.white}
                />
              }
              rightCol={
                <AccessControl>
                  <NewStatusBarToggleAttachButton
                    title={t("addons:disconnect-from-category")}
                    color={themeColors.white}
                    onClick={toggleAttachToMenuItem}
                  />
                </AccessControl>
              }
            />
          }
          content={
            <MmsTooltip title={t("addons:disconnect-this-item")}>
              <AddonsSettingsDefaultContentLayout
                leftColContent={<NewAddonList {...baseAddonListProps} />}
                rightColHeaderTitle={
                  <CommonSettingContentTitle
                    uniqId={selectedAddon!.id}
                    title={selectedAddon!.title}
                    isEditModeDisabled
                  />
                }
                rightColContent={
                  <>
                    <NewAddonTabContentControls
                      addonsSettingsStrategy={addonsSettingsStrategy}
                      selectedAddon={selectedAddon}
                      isUpdateProcessing={isUpdateProcessing}
                      isPriceLevelAddonAvailable={isPriceLevelAddonAvailable}
                      onChangeAddonType={curry(onChangeAddonType)(
                        selectedAddon!.id
                      )}
                      togglePriceLevelDependent={togglePriceLevelDependent.bind(
                        null,
                        selectedAddon!.id
                      )}
                      onMinMaxSet={onMinMaxSet}
                    />
                    {selectedAddonType !== AddonTypeEnum.priceLevel && (
                      <React.Fragment key={addonmenuFlagsKey}>
                        <MmsSeparator className={styles.contentSeparator} />
                        <MmsFormTitle>Menu Flags</MmsFormTitle>
                        <MenuFlagsForm
                          isAlwaysOpen={false}
                          menuFlags={menuFlags}
                          selectedMenuFlags={selectedAddon?.menuFlags || []}
                          onSubmit={curry(changeAddonMenuFlags)(
                            selectedAddon!.id
                          )}
                          isEditModeDisabled
                        />
                      </React.Fragment>
                    )}
                    <MmsSeparator className={styles.contentSeparator} />
                    <AddonItemsSettingsForm
                      key={selectedAddon!.id}
                      addonItemsSettingsProps={{
                        ...baseAddonItemsSettingsProps,
                        addon: selectedAddon!,
                        addonType: selectedAddonType,
                        addonStrategy: determineAddonStrategy(
                          addonsSettingsStrategy,
                          selectedAddon!.priceLevel,
                          selectedAddon!.priceLevelDependent
                        ),
                        isPriceLevelDependent: selectedAddon!
                          .priceLevelDependent,
                      }}
                      addonItemsMeta={selectedAddon!.itemsMeta}
                    />
                  </>
                }
                rightColFooter={
                  <JustifyContentBetweenLayout className={styles.btnGroup}>
                    <div />
                    <BtnFlatList
                      list={[
                        <MmsSecondaryButton
                          id="addons-settings-cancel-btn"
                          className={styles.commonBtn}
                          onClick={onCancelClick}
                          disabled={isUpdateProcessing}
                        >
                          {t("common:cancel")}
                        </MmsSecondaryButton>,
                        <MmsPrimaryButton
                          id="addons-settings-update-btn"
                          className={styles.commonBtn}
                          onClick={onUpdateClick}
                          processing={isUpdateProcessing}
                          disabled={!isAddonsChanged}
                        >
                          {t("common:update")}
                        </MmsPrimaryButton>,
                      ]}
                    />
                  </JustifyContentBetweenLayout>
                }
              />
            </MmsTooltip>
          }
        />
      );
    }
    default: {
      throw new Error(
        "NewAddonsSettings -> NewDefaultAddonsSettings -> render(): Wrong addonsSettingsStrategy"
      );
    }
  }
};
