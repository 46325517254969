import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  buttonsRow: css`
    padding: 32px 0;

    border-top: ${themeColors.cream} solid 2px;
  `,
  buttonGroup: css`
    gap: 27px;
  `,
  uploadInputLabel: css`
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    cursor: pointer;
  `,
  uploadInput: css`
    display: none;
  `,
  loaderContainer: css`
    display: flex;
    justify-content: center;
  `,
  loader: css`
    color: ${themeColors.lightGreen};
  `,
};
