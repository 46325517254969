import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  headContainer: css`
    background-color: ${themeColors.borderLightGrey};
  `,
  headCell: css`
    font-size: 16px !important;
  `,
};
