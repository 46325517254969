import { AddonTypeData, AddonTypeEnum } from "@model/helperTypes/Addon";
import { MmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

export interface AddonTabContentSelectTypeProps {
  disabled?: boolean;
  addonTypesData: AddonTypeData[];
  currentType: AddonTypeEnum;
  onChangeType: (newType: AddonTypeEnum) => void;
}

export const AddonTabContentSelectType: React.FC<AddonTabContentSelectTypeProps> = ({
  disabled,
  addonTypesData,
  currentType,
  onChangeType,
}) => {
  const { t } = useTranslation(["addons"]);

  const handleChange = useCallback(
    (newValue: string) => {
      onChangeType(newValue as AddonTypeEnum);
    },
    [onChangeType]
  );

  return (
    <MmsMuiSelectbox
      id="addon-select-type"
      name="addon-select-type"
      label={t("addons:type")}
      disabled={disabled}
      data={addonTypesData}
      value={currentType}
      onChange={handleChange}
    />
  );
};
