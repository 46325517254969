import { NetworkStatus, useLazyQuery } from "@apollo/client";
import { LOGS_DEFAULT_PAGE } from "@constants";
import { useErrorHandler, useLogFiltersPagination } from "@hooks";
import { LogFilters } from "@model/helperTypes/LogFilters";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { produce } from "immer";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  generateGetLogsVars,
  GetLogsTypes,
  GET_LOGS,
} from "../../graphql/queries/GetLogs";

export const useGetLogs = (): [
  {
    lazyFetchLogs: (filter?: LogFilters) => void;
    onGetMoreLogs: VoidFunction;
  },
  GetLogsTypes.GetLogs | undefined,
  { logsLoading: boolean; isFetchingMore: boolean }
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [logFilters, logsPage] = useLogFiltersPagination();

  const [
    lazyQuery,
    { loading, data, fetchMore, networkStatus },
  ] = useLazyQuery<GetLogsTypes.GetLogs>(GET_LOGS, {
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const lazyQueryWithVars = useCallback(
    (filter?: LogFilters) =>
      lazyQuery(
        generateGetLogsVars(restaurantId, LOGS_DEFAULT_PAGE + 1, filter)
      ),
    [lazyQuery, restaurantId]
  );

  const getMore = useCallback(() => {
    fetchMore?.({
      query: GET_LOGS,
      variables: { restaurantId, page: logsPage + 1, filter: logFilters },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return produce(fetchMoreResult, (newLogsData) => {
          newLogsData.logs = fetchMoreResult.logs;
        });
      },
    });
  }, [fetchMore, logFilters, logsPage, restaurantId]);

  return [
    {
      lazyFetchLogs: lazyQueryWithVars,
      onGetMoreLogs: getMore,
    },
    data,
    {
      logsLoading: loading,
      isFetchingMore: networkStatus === NetworkStatus.fetchMore,
    },
  ];
};
