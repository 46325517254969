export const getNewOrderBy = <T extends { orderBy: number }>(
  entityList: T[]
): number => {
  if (!entityList.length) entityList = [{ orderBy: 0 } as T];
  return Math.max(...entityList.map(({ orderBy }) => orderBy)) + 1;
};

export const getActiveEntities = <T extends { active: boolean }>(
  entityList: T[]
): T[] => {
  return entityList.filter(({ active }) => active);
};
