import { themeColors } from "@theme/colors";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { validateField } from "@utils/formik";
import { Form, FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { styles } from "./LogsTextFilter.styles";
import { validationMap } from "./LogsTextFilter.validationMap";

interface FormValues {}

interface LogsTextFilterProps {
  label: string;
  name: "username" | "assetId";
  defaultValue?: any;
  shouldClearField: boolean;
  onClearField: VoidFunction;
}

export const LogsTextFilter: React.FC<
  LogsTextFilterProps & FormikProps<FormValues>
> = ({ name, label, onClearField, shouldClearField, ...formikProps }) => {
  useEffect(() => {
    if (!shouldClearField) return;
    onClearField();
    formikProps.resetForm();
  }, [formikProps, onClearField, shouldClearField]);

  return (
    <Form className={styles.form}>
      <MmsMuiInput
        data-testid={`${name}-logs-filter`}
        name={name}
        label={label}
        onBlur={formikProps.submitForm}
        blurOnEnter
        errorColor={themeColors.yellow}
        validate={validateField(validationMap[name])}
        fullWidth
        showErrorMessage
      />
    </Form>
  );
};

interface LogsTextFilterFormProps extends LogsTextFilterProps {
  onSubmit: (values: any) => void;
}

// Wrap our form with the withFormik HoC
export const LogsTextFilterForm = withFormik<
  LogsTextFilterFormProps,
  FormValues
>({
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: (props) => ({
    [props.name]: props.defaultValue || "",
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(LogsTextFilter);
