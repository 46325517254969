import { gql } from "@apollo/client";
import { FormattedAssetEnum as OriginTypes } from "@model/helperTypes/LibraryAsset";
import { MenuAssetEnum as DestinationTypes } from "@model/helperTypes/MenuAsset";
import { CATEGORY_ESSENTIAL_DATA } from "../../fragments/Category";
import { MENU_ADDON_ESSENTIAL_DATA } from "../../fragments/MenuAddon";
import { MENU_ITEM_ESSENTIAL_DATA } from "../../fragments/MenuItem";

export const IMPORT_LIBRARY_ASSETS = gql`
  mutation ImportLibraryAssetsMutation(
    $input: LibraryImport!
    $menuItem: Boolean!
    $category: Boolean!
    $addon: Boolean!
  ) {
    libraryImport(input: $input) {
      ... on MenuCategory @include(if: $category) {
        ...CategoryEssentialData
      }
      ... on MenuItem @include(if: $menuItem) {
        ...MenuItemEssentialData
      }
      ... on MenuAddon @include(if: $addon) {
        ...MenuAddonEssentialData
      }
    }
  }
  ${CATEGORY_ESSENTIAL_DATA}
  ${MENU_ITEM_ESSENTIAL_DATA}
  ${MENU_ADDON_ESSENTIAL_DATA}
`;

export const generateImportLibraryAssetsVars = (
  ids: string[],
  destinationId: string,
  destinationType: DestinationTypes,
  originType: OriginTypes
) => ({
  input: {
    ids,
    destinationType,
    destinationId,
  },
  menuItem: originType === OriginTypes.menuItems,
  category:
    originType === OriginTypes.categories || originType === OriginTypes.menus,
  addon: originType === OriginTypes.addons,
});

export * as ImportLibraryAssetsTypes from "./__generated__/ImportLibraryAssetsMutation";
