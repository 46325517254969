import { JWT_TOKEN_KEY } from "@pages/Auth/constants/localStorageKeys";
import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { isTokenExpired } from "../utils/jwt";

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
  component: (props: RouteComponentProps) => React.ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  ...rest
}) => {
  const [token] = useLocalStorage(JWT_TOKEN_KEY, "");
  const isAuthenticated = token && !isTokenExpired(token);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        isAuthenticated ? (
          component(props)
        ) : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
