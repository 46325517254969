import { cx } from "@emotion/css";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";

interface CommonSettingsModalProps {
  children: React.ReactNode;
  closeModal: VoidFunction;
}

export const CommonSettingsModal: React.FC<CommonSettingsModalProps> = ({
  children,
  closeModal,
}) => {
  return (
    <MmsBaseModal
      contentClassName={cx("commonSettingsModal")}
      onClose={closeModal}
      closeOnOutsideClick={false}
    >
      {children}
    </MmsBaseModal>
  );
};
