import {
  AvailableEnum,
  LocalAvailabilityOption,
} from "@model/helperTypes/Discount&Availability";
import { GalleryImagesSelectionDirectionEnum } from "@model/helperTypes/Gallery";
import { ServiceType } from "__generated__/globalTypes";
import { ImportDirectionType } from "./model/helperTypes/LibraryAsset";
import { MenuAssetEnum as DestinationTypes } from "./model/helperTypes/MenuAsset";

export const DEFAULT_SAVE_TO_HISTORY_FORM = {
  historyTitle: "",
  historyDescription: "",
};

export const DEFAULT_PUBLISH_ERRORS = {
  menu_category: undefined,
  menu_category_item: undefined,
  menu_item: undefined,
};

export const DEFAULT_IMPORT_DIRECTION_VALUE: ImportDirectionType = {
  destinationId: "",
  destinationType: DestinationTypes.menu,
};

export const DEFAULT_SELECTED_ENTITIES_VALUE = {
  selectedCategories: [],
  selectedMenuItems: [],
};

export const PUBLISH_MENU_PROGRESSBAR_ID = "PUBLISH_MENU_PROGRESSBAR_ID";
export const BULK_DELETE_GALLERY_IMAGES_PROGRESSBAR_ID =
  "BULK_DELETE_GALLERY_IMAGES_PROGRESSBAR_ID";

export const PARTNER_BANNER = "PARTNER_BANNER";
export const PARTNER_TOKEN = "PARTNER-TOKEN";

export const RELEASE_READ = "RELEASE_READ";

export const LOGS_TABLE_CELLS_NAMES = [
  "Asset ID",
  "Asset type",
  "Title",
  "Action type",
  "User",
  "Changed at",
  "Details",
];
export const LOGS_DETAILS_CELLS_NAMES = ["Data", "Value"];
export const LOGS_UPDATE_DETAILS_CELLS_NAMES = [
  "Data",
  "Before change",
  "After change",
];
export const LOGS_DEFAULT_PAGE = 0;

export const ALL_FILTER_VALUE = "All";

export const FILTER_DISABLING_VALUE = {
  discountPresent: ALL_FILTER_VALUE,
  activeFromPresent: ALL_FILTER_VALUE,
  activenessPresent: ALL_FILTER_VALUE,
  titleCont: "",
};

export const KNOWN_PROD_DOMAINS = {
  YOYO: "orderyoyo.com",
  IWAITER: "iwaiterapp.com",
};

export const IS_IFRAME = window.location !== window.parent.location;

export const AVAILABILITY_OPTIONS: LocalAvailabilityOption[] = [
  { title: "Always available", value: AvailableEnum.always },
  { title: "Start / End time", value: AvailableEnum.restricted },
];

export const MAX_IMAGE_SIZE = 33554432; // 32 mb in bytes

export const SCREEN_SIZES = {
  // mobile, from 0 to 575
  XS: 0,
  // mobile-portrait, from 576 to 767
  SM: 576,
  // tablet, from 768 to 991
  MD: 768,
  // laptop, from 992 to 1199
  LG: 992,
  // desktop, from 1200 to infinity
  XL: 1200,
};

export const SCREEN_SIZE_TO_CUT_HEADER_BREADCRUMBS = 1100; // 'px'

export const SELECTED_IMAGES_DEFAULTS = {
  selectionDirection: GalleryImagesSelectionDirectionEnum.right,
  selectedImagesIds: [],
};

export const OVERRIDE_TAXES: ServiceType[] = [
  ServiceType.Delivery,
  ServiceType.DineIn,
  ServiceType.Pickup,
];

export const DOWNLOAD_URLS = {
  mmsUrl:
    process.env.API_MMS_URL ??
    "https://app-mymenumanagementsystemapi-prd.azurewebsites.net",
  dataToolUrl: process.env.API_DATA_PAGE_TOOLS_URL ?? "https://data.page",
};
