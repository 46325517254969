import { css } from "@emotion/css";

export const styles = {
  form: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  `,
  wysiwyg: css`
    margin-bottom: 30px;
  `,
};
