import { cx } from "@emotion/css";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";
import { styles } from "./BulkMoreModal.styles";

interface BulkMoreModalProps {
  closeModal: VoidFunction;
  children: React.ReactNode;
}

export const BulkMoreModal: React.FC<BulkMoreModalProps> = ({
  closeModal,
  children,
}) => (
  <MmsBaseModal
    contentClassName={cx("bulkMoreModal", styles.modalContent)}
    onClose={closeModal}
  >
    {children}
  </MmsBaseModal>
);
