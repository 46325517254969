import { gql } from "@apollo/client";
import { ADDITIVE_ESSENTIAL_DATA } from "./Additive";
import { ALLERGEN_ESSENTIAL_DATA } from "./Allergen";
import { IMAGE_ESSENTIAL_DATA } from "./Image";
import { MENU_ADDON_ESSENTIAL_DATA } from "./MenuAddon";
import { MENU_FLAG_ESSENTIAL_DATA } from "./MenuFlag";
import { TAX_ASSOCIATION_ESSENTIAL_DATA } from "./Taxes";

export const MENU_ITEM_ESSENTIAL_DATA = gql`
  fragment MenuItemEssentialData on MenuItem {
    id
    categoryId
    iwaiterId
    title
    sku
    description
    active
    hasOwnAddons
    disableBasePrices
    enableDeliveryPrices
    enableTakeawayPrices
    enableDineInPrices
    price
    priceTakeaway
    priceDelivery
    priceDineIn
    priceLevel1
    priceLevel2
    priceLevel3
    priceLevel4
    priceLevel5
    priceLevel1Takeaway
    priceLevel2Takeaway
    priceLevel3Takeaway
    priceLevel4Takeaway
    priceLevel5Takeaway
    priceLevel1Delivery
    priceLevel2Delivery
    priceLevel3Delivery
    priceLevel4Delivery
    priceLevel5Delivery
    priceLevel1DineIn
    priceLevel2DineIn
    priceLevel3DineIn
    priceLevel4DineIn
    priceLevel5DineIn
    orderBy
    addons {
      ...MenuAddonEssentialData
    }
    image {
      ...ImageEssentialData
    }
    allergens {
      ...AllergenEssentialData
    }
    additives {
      ...AdditiveEssentialData
    }
    menuFlags {
      ...MenuFlagEssentialData
    }
    spiceType
    taxAssociations {
      ...TaxAssociationEssentialData
    }
  }
  ${MENU_ADDON_ESSENTIAL_DATA}
  ${IMAGE_ESSENTIAL_DATA}
  ${ALLERGEN_ESSENTIAL_DATA}
  ${ADDITIVE_ESSENTIAL_DATA}
  ${MENU_FLAG_ESSENTIAL_DATA}
  ${TAX_ASSOCIATION_ESSENTIAL_DATA}
`;

export const CATEGORY_MENU_ITEMS = gql`
  fragment CategoryMenuItems on MenuCategory {
    id
    items {
      ...MenuItemEssentialData
    }
  }
  ${MENU_ITEM_ESSENTIAL_DATA}
`;
