import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  notificationRow: css`
    gap: 15px;
    align-items: center;
    width: 260px;
  `,
  notificationIconWrapper: css`
    width: 32px;
    height: 32px;
  `,
  message: css`
    font-size: 14px;
    color: ${themeColors.black};
    word-break: break-word;
  `,
  closeButtonContainer: css`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;

    border-radius: 50%;

    background: ${themeColors.white};

    box-shadow: ${themeUtils.boxShadowSettings(20)} ${themeUtils.boxShadowOpacity(0.15)};
    cursor: pointer;
  `,
  closeIcon: css`
    width: 9px;
    height: 9px;
  `,
  buttonContainer: (type: string) => css`
    height: 24px;
    margin: 15px 2px 4px 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: ${themeVars.borderRadius};

    background: ${type === "success" ? themeColors.lightGreen : themeColors.pink};
  `,
  buttonText: (type: string) => css`
    font-weight: bold;
    font-size: 12px;
    color: ${themeColors.white};
  `,
};
