import { useReactiveVar } from "@apollo/client";
import { cx } from "@emotion/css";
import {
  useActiveForms,
  useDidUpdate,
  useDragAndDropClient,
  useFormErrorsProtection,
  useHeaderVisibilityControls,
  usePriceEditingMode,
  useToggle,
} from "@hooks";
import { ModelCategoryWithMenuItemsAmount } from "@model/DAO/MenuCategory";
import {
  DraggableDataItem,
  DraggableEnum,
} from "@model/helperTypes/dragAndDrop";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { MenuItemContainerProps } from "@pages/RestaurantMenuPage/containers/MenuItem/MenuItem";
import { useCategoryBulkActions } from "@pages/RestaurantMenuPage/hooks/useCategoryBulkActions";
import { usePublishValidationErrors } from "@pages/RestaurantMenuPage/hooks/usePublishErrors";
import { themeColors } from "@theme/colors";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import { MmsDummyRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink/dummy";
import { Chevron, InfoCircle } from "@uiKit/Atoms/SVGIcons";
import { MmsSwitch } from "@uiKit/Atoms/Switch/MmsSwitch";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import {
  draggingMenuItemParentCategoryVar,
  iwaiterIdVar,
  menuFiltersVar,
} from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import { isNotEmpty } from "@utils/ramdaHelpers";
import { getIsPartner } from "@utils/url";
import React, { useEffect, useMemo, useRef } from "react";
import { useDrop } from "react-dnd";
import { CategoryDesktopLayout } from "../../layouts/CategoryLayout";
import { generateMenuAssetUrl } from "../../utils/Menu";
import { styles } from "./Category.styles";
import { CategoryAddonsSection } from "./CategoryAddonsSection/CategoryAddonsSection";
import { CategoryControlsSection } from "./CategoryControlsSection/CategoryControlsSection";
import { CategoryEditTitleForm } from "./CategoryEditTitle/CategoryEditTitle";
import { CategoryTitleSection } from "./CategoryTitleSection/CategoryTitleSection";
import { useTranslation } from "react-i18next";
import { clearWysiwyg } from "@utils/strings";
import { ReactComponent as SVGStar } from "@assets/SVG/star.svg";

export interface CategoryProps {
  category: ModelCategoryWithMenuItemsAmount;
  onDelete: VoidFunction;
  onClone: VoidFunction;
  onShowCommonSettings: VoidFunction;
  onShowAddons: VoidFunction;
  onToggle: VoidFunction;
  onToggleCategoryExpanded: VoidFunction;
  onToggleCategoryExpandedManual: (manualState: boolean) => void;
  onChangeTitle: (values: any) => void;
  onDescriptionClick: VoidFunction;
  onImport?: VoidFunction;
  onSave?: VoidFunction;
  isCategoryExpanded: boolean;
  isEditTitleLoading: boolean;
  isActive: boolean;
  isNew?: boolean;
  isGreyedOut?: boolean;
  isTitleHighlighted?: boolean;
  children: React.ReactNode;
  modals: React.ReactNode;
}

export const Category: React.FC<CategoryProps> = ({
  category,
  onDelete,
  onClone,
  onShowCommonSettings,
  onShowAddons,
  onToggle,
  onChangeTitle,
  onToggleCategoryExpanded,
  onToggleCategoryExpandedManual,
  isCategoryExpanded,
  onImport = noop,
  onDescriptionClick,
  onSave = noop,
  isEditTitleLoading,
  isActive,
  isNew,
  isGreyedOut,
  isTitleHighlighted,
  children,
  modals,
}) => {
  const { t } = useTranslation(["common", "category"]);
  const iwaiterId = useReactiveVar(iwaiterIdVar);
  const menuFilters = useReactiveVar(menuFiltersVar);
  const draggingMenuItemParentCategory = useReactiveVar(
    draggingMenuItemParentCategoryVar
  );
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [showHeader, hideHeader] = useHeaderVisibilityControls();
  const needToOpenAfterDragAndDrop = useRef(false);

  const [
    { draggableRef, handleRef },
    { isDragging, didDrop },
    setIsDragAndDropEnabled,
  ] = useDragAndDropClient(DraggableEnum.category);

  const [{ isMenuItemOver }, drop] = useDrop({
    accept: `${DraggableEnum.menuItem}.${draggingMenuItemParentCategory}`,
    collect: (monitor) => ({
      isMenuItemOver: monitor.isOver(),
    }),
    drop: ({
      itemData: { menuItem, parentCategory },
    }: DraggableDataItem<MenuItemContainerProps>) => {
      if (parentCategory.id === category.id) return;

      return {
        action: "TRANSFER",
        payload: {
          itemId: menuItem.id,
          parentIdFrom: parentCategory.id,
          parentIdTo: category.id,
        },
      };
    },
  });
  drop(draggableRef);

  const [activeForms] = useActiveForms();
  const [isPriceEditingMode] = usePriceEditingMode();
  const [publishErrors] = usePublishValidationErrors();

  const [isEditMode, toggleEditMode] = useToggle(!!isNew);
  const [shouldKeepControlsVisible, toggleControlsVisible] = useToggle(false);

  const [isCheckboxEnabled, onToggleCheckbox] = useCategoryBulkActions(
    category.id,
    onToggleCategoryExpandedManual
  );

  const extraDummyLinkProps = useMemo(
    () => (isPriceEditingMode ? { tabIndex: -1 } : {}),
    [isPriceEditingMode]
  );

  const categoryOwnErrorsMsgs: string[] | undefined = useMemo(
    () => publishErrors.menu_category?.[+category.id],
    [category.id, publishErrors.menu_category]
  );

  const categoryContainsItemsWithErrors: string[] | undefined = useMemo(
    () => publishErrors.menu_category_item?.[+category.id],
    [category.id, publishErrors.menu_category_item]
  );

  const description = useMemo(() => {
    // it's possible that item.description is not empty, but after clearWysiwyg fn, it will be empty.
    // So return "No description" only after clearing
    return (
      clearWysiwyg(category.description || "") || t("common:no-description")
    );
  }, [category.description, t]);

  useDidUpdate(() => {
    if (isDragging && isCategoryExpanded) {
      needToOpenAfterDragAndDrop.current = true;
      onToggleCategoryExpanded();
    }
    if (!isDragging) {
      needToOpenAfterDragAndDrop.current && onToggleCategoryExpanded();
      needToOpenAfterDragAndDrop.current = false;
    }
  }, [isDragging, isCategoryExpanded]);

  useDidUpdate(() => {
    if (isDragging) hideHeader();
    else showHeader();
  }, [isDragging, didDrop]);

  const isDragAndDropDisabled = useMemo(
    () =>
      [
        !!activeForms.length,
        !!isNew,
        isPriceEditingMode,
        isNotEmpty(menuFilters),
      ].some(Boolean),
    [activeForms.length, isNew, isPriceEditingMode, menuFilters]
  );

  useEffect(() => {
    setIsDragAndDropEnabled(!isDragAndDropDisabled);
  }, [isDragAndDropDisabled, setIsDragAndDropEnabled]);

  useDidUpdate(() => {
    if (!categoryContainsItemsWithErrors) return;

    onToggleCategoryExpandedManual(true);
  }, [categoryContainsItemsWithErrors]);

  return (
    <div
      className={cx(
        "Category",
        styles.container(
          isDragging,
          isMenuItemOver && draggingMenuItemParentCategory !== category.id
        )
      )}
      ref={draggableRef}
    >
      <MmsDummyRouterLink
        href={generateMenuAssetUrl(
          iwaiterId,
          category.iwaiterId,
          MenuAssetEnum.category
        )}
        disableLinkPreview={getIsPartner()}
        {...extraDummyLinkProps}
      >
        <CategoryDesktopLayout
          data-testid={`category-${category.id}`}
          ref={handleRef}
          className={styles.layout(
            isDragging,
            isDragAndDropDisabled,
            !!isGreyedOut
          )}
          shouldKeepControlsVisible={shouldKeepControlsVisible}
          checkboxCell={
            category.isFreeItem ? (
              <SVGStar fill={themeColors.yellow} />
            ) : (
              <MmsTooltip
                title={
                  isCheckboxEnabled
                    ? t("category:deselect-entire-category")
                    : t("category:select-entire-category")
                }
              >
                <div>
                  <MmsCheckbox
                    data-testid={`category-checkbox-${category.id}`}
                    id={`category-checkbox-${category.id}`}
                    shapeClassName={styles.checkboxShape(!!isGreyedOut)}
                    checked={isCheckboxEnabled}
                    onChange={withActiveFormsErrorsProtection(onToggleCheckbox)}
                  />
                </div>
              </MmsTooltip>
            )
          }
          errorCell={
            categoryOwnErrorsMsgs && (
              <MmsTooltip title={categoryOwnErrorsMsgs.toString()}>
                <div>
                  <InfoCircle color={themeColors.pink} />
                </div>
              </MmsTooltip>
            )
          }
          titleCell={
            isEditMode ? (
              <CategoryEditTitleForm
                categoryId={category.id}
                title={category.title}
                onSubmit={onChangeTitle}
                onSubmitEnded={toggleEditMode}
                isFormSubmitting={isEditTitleLoading}
                onPressEsc={isNew ? onDelete : noop}
              />
            ) : (
              <div className={styles.categoryInfoWrapper}>
                <CategoryTitleSection
                  data-testid={`category-title-${category.id}`}
                  title={category.title}
                  onClick={withActiveFormsErrorsProtection(toggleEditMode)}
                  className={styles.title(!!isTitleHighlighted)}
                />
                {category.isFreeItem !== true && (
                  <MmsTooltip
                    title={t("category:click-to-add-edit-description")}
                  >
                    <div
                      className={styles.description}
                      onClick={withActiveFormsErrorsProtection(
                        onDescriptionClick
                      )}
                      data-testid={`category-item-description-${category.id}`}
                    >
                      {description}
                    </div>
                  </MmsTooltip>
                )}
              </div>
            )
          }
          addonsCell={
            <CategoryAddonsSection
              addons={category.addons}
              discount={category.discount}
              takeawayDiscount={category.takeawayDiscount}
              deliveryDiscount={category.deliveryDiscount}
              onShowMoreAddons={
                isNew ? noop : withActiveFormsErrorsProtection(onShowAddons)
              }
              categoryId={category.id}
              activeFrom={category.activeFrom}
              activeTo={category.activeTo}
              disabledOnDays={category.disabledOnDays}
            />
          }
          priceCell={null}
          controlsGroupCell={
            <CategoryControlsSection
              onShowCommonSettings={
                isNew
                  ? noop
                  : withActiveFormsErrorsProtection(onShowCommonSettings)
              }
              onShowAddons={
                isNew ? noop : withActiveFormsErrorsProtection(onShowAddons)
              }
              onClone={isNew ? noop : withActiveFormsErrorsProtection(onClone)}
              onDelete={onDelete}
              onImport={isNew ? noop : onImport}
              onSave={isNew ? noop : onSave}
              toggleControlsVisible={toggleControlsVisible}
              id={category.id}
              isFreeItem={category.isFreeItem === true}
            />
          }
          toggleCell={
            <MmsTooltip
              title={
                isActive
                  ? t("common:make-unavailable")
                  : t("common:make-available")
              }
            >
              <div>
                <MmsSwitch
                  checked={isActive}
                  onChange={onToggle}
                  id={`toggle-category-controls-${category.id}`}
                  className={styles.toggleCategory(!!isGreyedOut, isActive)}
                />
              </div>
            </MmsTooltip>
          }
          itemsAmountCell={
            <MmsTooltip title={t("category:number-of-items-in-this-category")}>
              <div id={`menu-items-amount-${category.id}`}>
                {category.menuItemsAmount}
              </div>
            </MmsTooltip>
          }
          chevronCell={
            <MmsTooltip
              title={
                isCategoryExpanded
                  ? t("common:collapse")
                  : t("common:see-more-info")
              }
            >
              <MmsSvgButton
                data-testid={`open-category-${category.id}`}
                id={`open-category-${category.id}`}
                onClick={withActiveFormsErrorsProtection(
                  onToggleCategoryExpanded
                )}
                className={styles.baseControl}
              >
                <Chevron
                  id={`open-category-${category.id}-icon`}
                  direction={isCategoryExpanded ? "bottom" : "right"}
                />
              </MmsSvgButton>
            </MmsTooltip>
          }
        />
      </MmsDummyRouterLink>
      {children}
      {modals}
    </div>
  );
};
