/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

export const useDidUpdate = (
  fn: VoidFunction | VoidFunction,
  conditions: any[]
) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    // Cleanup effects when fn returns a function
    return fn && fn();
  }, conditions);
};
