import {
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { useParams } from "react-router-dom";
import {
  GetScheduleTasksTypes,
  GET_SCHEDULE_TASKS,
} from "../../graphql/queries/GetScheduleTasks";

export const useGetScheduleTasks = (): [
  boolean,
  GetScheduleTasksTypes.GetScheduleTasksQuery | undefined,
  (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<GetScheduleTasksTypes.GetScheduleTasksQuery>>
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();

  const {
    loading,
    data,
    refetch,
  } = useQuery<GetScheduleTasksTypes.GetScheduleTasksQuery>(
    GET_SCHEDULE_TASKS,
    { variables: { restaurantId }, onError }
  );

  return [loading, data, refetch];
};
