import { LibraryAssetNodes } from "@model/DAO/Library";
import {
  AssetTabIndexes,
  TypesLabelsEnum,
} from "@model/helperTypes/LibraryAsset";
import { themeColors } from "@theme/colors";
import { MmsEmptyContentBanner } from "@uiKit/Atoms/EmptyContentBanner/MmsEmptyContentBanner";
import { Chevron, List } from "@uiKit/Atoms/SVGIcons";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React, { useMemo } from "react";
import { styles } from "./LibraryAssetDataPanel.styles";
import { LibraryDataItem } from "./LibraryDataItem/LibraryDataItem";
import { useTranslation } from "react-i18next";

interface LibraryAssetDataPanelProps {
  data: LibraryAssetNodes["displayData"];
  selectedAssetTypeId: number;
  dataOwnerName: string;
}

export const LibraryAssetDataPanel: React.FC<LibraryAssetDataPanelProps> = ({
  data,
  selectedAssetTypeId,
  dataOwnerName,
}) => {
  const { t } = useTranslation(["library"]);
  const dataOwnerTypeName = useMemo(() => {
    const tabKey = Object.keys(AssetTabIndexes).find(
      (key) =>
        AssetTabIndexes[key as keyof typeof AssetTabIndexes] ===
        selectedAssetTypeId
    );
    return TypesLabelsEnum[tabKey as keyof typeof TypesLabelsEnum];
  }, [selectedAssetTypeId]);

  const DataItemIcon = useMemo(() => {
    if (
      selectedAssetTypeId === AssetTabIndexes.menus ||
      selectedAssetTypeId === AssetTabIndexes.categories
    )
      return Chevron;
    if (selectedAssetTypeId === AssetTabIndexes.menuItems) return List;
    if (selectedAssetTypeId === AssetTabIndexes.addons) return null;
  }, [selectedAssetTypeId]);

  return (
    <>
      {!data?.length ? (
        <MmsEmptyContentBanner title={t("library:no-items-available")} />
      ) : (
        <VerticalAlignBetween className={styles.container}>
          <MmsTooltip
            title={t("library:this-is-preview-of-selected-assets-contents")}
          >
            <div className={styles.dataOwnerType}>
              Preview - {dataOwnerTypeName}
            </div>
          </MmsTooltip>
          <div
            className={styles.dataOwnerName(
              selectedAssetTypeId === AssetTabIndexes.menus
            )}
          >
            {dataOwnerName}
          </div>
          {data?.map((item: any, idx: number) => {
            return (
              <LibraryDataItem
                key={`$asset-data-${item}-${idx}`}
                title={item}
                icon={
                  <>
                    {DataItemIcon && (
                      <DataItemIcon
                        size={"small"}
                        color={themeColors.mediumGray}
                      />
                    )}
                  </>
                }
              />
            );
          })}
        </VerticalAlignBetween>
      )}
    </>
  );
};
