import { cx } from "@emotion/css";
import { MmsSkeleton } from "@uiKit/Atoms/Skeleton/MmsSkeleton";
import React from "react";
import { styles } from "./CategorySkeleton.styles";

export interface CategorySkeletonProps {
  className?: string;
}

export const CategorySkeleton = React.forwardRef<
  HTMLDivElement,
  CategorySkeletonProps & React.HTMLProps<HTMLDivElement>
>(({ className, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={cx(styles.skeletonContainer, className)}
      {...rest}
    >
      <div className={cx(styles.checkboxSkeleton, styles.skeleton)}>
        <MmsSkeleton />
      </div>
      <div className={styles.errorCell} />
      <div className={cx(styles.skeleton, styles.categoryInfoSkeleton)}>
        <MmsSkeleton />
      </div>
      <div className={cx(styles.skeleton, styles.controlsGroupSkeleton)}>
        <MmsSkeleton />
      </div>
      <div className={cx(styles.toggleSkeleton, styles.skeleton)}>
        <MmsSkeleton />
      </div>
      <div className={cx(styles.chevronSkeleton, styles.skeleton)}>
        <MmsSkeleton />
      </div>
    </div>
  );
});
