import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  hr: css`
    height: 1px;
    border: none;
    margin: 10px 0;
    background-color: ${themeColors.borderLightGrey};
  `,
  row: css`
    display: flex;
    gap: 4px;

    > * {
      flex: 1;
    }

    .MuiFormControl-root {
      width: 100%;
    }
  `,
  serviceTypeSection: css`
    margin-bottom: 2px;
  `,
  serviceTypeGroup: css`
    margin-bottom: 20px;
  `,
  serviceTypeSectionHeading: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;
  `,
};
