import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import React from "react";
import { styles } from "./AddNewMenuItem.styles";
import { useTranslation } from "react-i18next";

export interface AddNewMenuItemProps {
  onClick: VoidFunction;
  categoryTitle: string;
  categoryId: string;
}

export const AddNewMenuItem: React.FC<AddNewMenuItemProps> = ({
  onClick,
  categoryTitle,
  categoryId,
}) => {
  const { t } = useTranslation(["addons"]);

  return (
    <MmsBaseButton
      id={`add-new-menu-item--${categoryId}`}
      onClick={onClick}
      fullWidth
      className={styles.main}
    >
      <i className={styles.icon} />
      {t("addons:add-new-item-to-category", { categoryTitle: categoryTitle })}
    </MmsBaseButton>
  );
};
