import React from "react";
import { styles } from "./EmptyContentLayout.styles";

export interface AddonsSettingsEmptyContentLayoutProps {
  message: string;
  importFromLibraryBtn: React.ReactNode;
  addNewEntityBtn: React.ReactNode;
  footer: React.ReactNode;
}

export const AddonsSettingsEmptyContentLayout: React.FC<AddonsSettingsEmptyContentLayoutProps> = React.forwardRef<
  HTMLDivElement,
  AddonsSettingsEmptyContentLayoutProps
>(({ message, importFromLibraryBtn, addNewEntityBtn, footer }, ref) => {
  return (
    <div ref={ref} className={styles.container}>
      <p className={styles.message}>{message}</p>
      <div className={styles.row}>{importFromLibraryBtn}</div>
      <div className={styles.row}>{addNewEntityBtn}</div>
      <footer className={styles.footer}>{footer}</footer>
    </div>
  );
});
