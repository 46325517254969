import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./MenuItemDesktopLayout.styles";

export interface MenuItemDesktopLayoutProps {
  checkboxCell: React.ReactNode;
  errorCell: React.ReactNode;
  imageCell: React.ReactNode;
  titleCell: React.ReactNode;
  addonsCell: React.ReactNode;
  controlsGroupCell: React.ReactNode;
  toggleCell: React.ReactNode;
  chevronCell: React.ReactNode;
  className: string;
  shouldKeepControlsVisible: boolean;
  isFreeItem: boolean | null;
}

export const MenuItemDesktopLayout = React.forwardRef<
  HTMLDivElement,
  MenuItemDesktopLayoutProps & React.HTMLProps<HTMLDivElement>
>(
  (
    {
      checkboxCell,
      errorCell,
      imageCell,
      titleCell,
      addonsCell,
      controlsGroupCell,
      toggleCell,
      chevronCell,
      className,
      shouldKeepControlsVisible,
      isFreeItem,
      ...rest
    },
    ref
  ) => {
    return (
      <div ref={ref} className={cx(styles.container, className)} {...rest}>
        <div className={cx(styles.cell, styles.checkboxCell)}>
          {checkboxCell}
        </div>
        <div className={cx(styles.cell, styles.errorCell)}>{errorCell}</div>
        <div className={cx(styles.cell, styles.imageCell)}>{imageCell}</div>
        <div className={cx(styles.cell, styles.titleCell)}>{titleCell}</div>
        <div className={cx(styles.cell, styles.addonsCell)}>
          {!isFreeItem && addonsCell}
        </div>
        <div
          className={cx(
            "controlsGroupCell",
            styles.cell,
            styles.controlsGroupCell(shouldKeepControlsVisible)
          )}
        >
          {controlsGroupCell}
        </div>
        <div className={cx(styles.cell, styles.toggleCell)}>{toggleCell}</div>
        <div className={cx(styles.cell, styles.chevronCell)}>{chevronCell}</div>
      </div>
    );
  }
);
