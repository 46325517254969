import React, { useEffect, useState } from "react";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { withFormik } from "formik";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import NumberFormat from "react-number-format";
import { styles } from "./PricesForm.styles";
import { BasePricesData, MenuItemPricesData } from "@model/helperTypes/Prices";
import { useTranslation } from "react-i18next";

interface Props {
  pricesData: MenuItemPricesData;
  priceLevelAddon: ModelMenuAddon | undefined;
  onChange: (data: BasePricesData) => void;
}

const BasePricesSectionContent: React.FC<Props> = ({
  pricesData,
  priceLevelAddon,
  onChange,
}) => {
  const { t } = useTranslation(["menu"]);
  const [price, setPrice] = useState(pricesData.price);
  const [priceLevelPrices, setPriceLevelPrices] = useState(
    Array(5)
      .fill(null)
      .map(
        (_, index) =>
          pricesData[`priceLevel${index + 1}` as keyof MenuItemPricesData] as
            | string
            | null
      )
  );
  const setPriceLevelLevelPrice = (newPrice: string, index: number) =>
    setPriceLevelPrices(
      priceLevelPrices?.map((currentPrice: string | null, i: number) =>
        i === index ? newPrice : currentPrice
      )
    );

  useEffect(() => {
    onChange({
      price,
      priceLevel1: priceLevelPrices?.[0] || "0.00",
      priceLevel2: priceLevelPrices?.[1] || "0.00",
      priceLevel3: priceLevelPrices?.[2] || "0.00",
      priceLevel4: priceLevelPrices?.[3] || "0.00",
      priceLevel5: priceLevelPrices?.[4] || "0.00",
    });
    // eslint-disable-next-line
  }, [price, priceLevelPrices]);

  return (
    <div className={styles.row}>
      {!priceLevelAddon ? (
        <NumberFormat
          name="price"
          customInput={MmsMuiInput}
          decimalScale={2}
          label={t("menu:label-price")}
          allowNegative={false}
          value={price || ""}
          onChange={(e) => setPrice(e.target.value)}
        />
      ) : (
        priceLevelAddon.items.map((level) => (
          <NumberFormat
            key={level.orderBy}
            name={`priceLevel${level.orderBy}`}
            customInput={MmsMuiInput}
            decimalScale={2}
            label={level.title}
            allowNegative={false}
            value={priceLevelPrices?.[level.orderBy - 1] || ""}
            onChange={(e) =>
              setPriceLevelLevelPrice(e.target.value, level.orderBy - 1)
            }
          />
        ))
      )}
    </div>
  );
};

export const BasePricesSection = withFormik<Props, BasePricesData>({
  handleSubmit: (values) => {},
})(BasePricesSectionContent);
