import { cx } from "@emotion/css";
import { MmsSkeleton } from "@uiKit/Atoms/Skeleton/MmsSkeleton";
import React from "react";
import { styles } from "./MenuItemSkeleton.styles";

export interface MenuItemSkeletonProps {
  className?: string;
}

export const MenuItemSkeleton = React.forwardRef<
  HTMLDivElement,
  MenuItemSkeletonProps & React.HTMLProps<HTMLDivElement>
>(({ className, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={cx(styles.skeletonContainer, className)}
      {...rest}
    >
      <div className={cx(styles.skeleton, styles.checkboxSkeleton)}>
        <MmsSkeleton />
      </div>
      <div className={styles.errorCell} />
      <div className={cx(styles.skeleton, styles.imageSkeleton)}>
        <MmsSkeleton />
      </div>
      <div className={cx(styles.skeleton, styles.titleAndAddonSkeleton)}>
        <MmsSkeleton />
      </div>
      <div className={cx(styles.skeleton, styles.controlsGroupSkeleton)}>
        <MmsSkeleton />
      </div>
      <div className={cx(styles.skeleton, styles.toggleSkeleton)}>
        <MmsSkeleton />
      </div>
      <div className={cx(styles.skeleton, styles.chevronSkeleton)}>
        <MmsSkeleton />
      </div>
    </div>
  );
});
