import { cx } from "@emotion/css";
import React from "react";
import { MmsBaseModal } from "../MmsBaseModal";
import { useConfirmOnEnterButtonPress } from "../_common/hooks";
import { styles } from "./styles";

export interface MmsBaseNotificationModalProps {
  contentClassName?: string;
  titleSection: React.ReactNode;
  messageSection: React.ReactNode;
  buttonRowSection: React.ReactNode;
  onConfirm: VoidFunction;
  onClose: VoidFunction;
}

export const MmsBaseNotificationModal: React.FC<MmsBaseNotificationModalProps> = ({
  contentClassName,
  titleSection,
  messageSection,
  buttonRowSection,
  onConfirm,
  onClose,
}) => {
  useConfirmOnEnterButtonPress(onConfirm);
  return (
    <MmsBaseModal
      contentClassName={cx(
        "notificationModal",
        styles.notificationModal,
        contentClassName
      )}
      onClose={onClose}
    >
      <div className={styles.title}>{titleSection}</div>
      <div className={styles.container}>
        <div className={styles.message}>{messageSection}</div>
        <div>{buttonRowSection}</div>
      </div>
    </MmsBaseModal>
  );
};
