import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { AddonsSettingsStrategyEnum } from "@model/helperTypes/AddonsSettings";
import { RerankableEntity } from "@model/helperTypes/dragAndDrop";
import { checkIsAddonTempNew } from "@pages/RestaurantMenuPage/utils/Addon";
import { SortableList } from "@uiKit/Layouts/SortableList";
import { noop } from "@utils/noop";
import { getIsPartner } from "@utils/url";
import { curry } from "ramda";
import React, { useCallback, useMemo } from "react";
import {
  AddonTabPanelSortable,
  AddonTabPanelSortableProps,
} from "../../AddonsSettings/AddonTabPanelSortable/AddonTabPanelSortable";

export interface NewAddonListProps {
  addonsSettingsStrategy: AddonsSettingsStrategyEnum;
  localAddons: ModelLocalAddon[];
  activePriceLevelAddon?: ModelLocalAddon;
  newAddon: ModelLocalAddon | null;
  parentId: string;
  selectedTabIndex: number;
  isSelectTabModeDisabled: boolean;
  isUpdateProcessing: boolean;
  onSelect: (tabIndex: number) => void;
  onClone: (addonId: string) => void;
  onDelete: (isCurrentTabSelected: boolean, addonId: string) => void;
  onToggle: (isCurrentTabSelected: boolean, addonId: string) => void;
  onDeleteNewAddon: VoidFunction;
  onToggleNewAddon: VoidFunction;
  onChangeOrder: (data: AddonTabPanelSortableProps[]) => void;
}

export const NewAddonList: React.FC<NewAddonListProps> = ({
  addonsSettingsStrategy,
  localAddons,
  activePriceLevelAddon,
  newAddon,
  parentId,
  selectedTabIndex,
  isSelectTabModeDisabled,
  isUpdateProcessing,
  onSelect,
  onClone,
  onDelete,
  onToggle,
  onDeleteNewAddon,
  onToggleNewAddon,
  onChangeOrder,
}) => {
  const isDragAndDropDisabled = useMemo(() => {
    switch (addonsSettingsStrategy) {
      case AddonsSettingsStrategyEnum.menu_item_detached:
        return true;
      case AddonsSettingsStrategyEnum.category:
      case AddonsSettingsStrategyEnum.menu_item_attached:
        return false;
      default:
        throw new Error(
          "NewAddonsSettings -> NewAddonList -> isDragAndDropDisabled: Wrong addonsSettingsStrategy"
        );
    }
  }, [addonsSettingsStrategy]);

  const localAddonsExtended = useMemo(() => {
    switch (addonsSettingsStrategy) {
      case AddonsSettingsStrategyEnum.category:
      case AddonsSettingsStrategyEnum.menu_item_attached:
        return [...localAddons, newAddon!].filter(Boolean);
      case AddonsSettingsStrategyEnum.menu_item_detached:
        return localAddons;
      default:
        throw new Error(
          "NewAddonsSettings -> NewAddonList -> localAddonsExtended: Wrong addonsSettingsStrategy"
        );
    }
  }, [addonsSettingsStrategy, localAddons, newAddon]);

  const handleChangeOrder = useCallback(
    (data: RerankableEntity<AddonTabPanelSortableProps>[]) => {
      return onChangeOrder(data.filter(({ id }) => id !== newAddon?.id));
    },
    [newAddon, onChangeOrder]
  );

  return (
    <>
      <SortableList<RerankableEntity<AddonTabPanelSortableProps>>
        data={localAddonsExtended.map((localAddon, currentTabIndex) => {
          const isCloningDisabled = activePriceLevelAddon?.id === localAddon.id;
          const isSelected = selectedTabIndex === currentTabIndex;
          const isAddonTempNew = checkIsAddonTempNew(localAddon.id);

          const addonTabPanelCommonProps = {
            addonsSettingsStrategy,
            isCloningDisabled,
            isUpdateProcessing,
            onDelete: isAddonTempNew
              ? onDeleteNewAddon
              : curry(onDelete)(currentTabIndex === selectedTabIndex),
            onToggle: isAddonTempNew
              ? onToggleNewAddon
              : curry(onToggle)(currentTabIndex === selectedTabIndex),
          };

          switch (addonsSettingsStrategy) {
            case AddonsSettingsStrategyEnum.category:
            case AddonsSettingsStrategyEnum.menu_item_attached: {
              return isAddonTempNew
                ? {
                    id: localAddon.id,
                    mmsTabProps: {
                      tabIndex: currentTabIndex,
                      isSelected: true,
                      onSelect: noop,
                    },
                    addonTabPanelProps: {
                      ...addonTabPanelCommonProps,
                      addon: newAddon!,
                      isSelected: true,
                      onClone: noop,
                    },
                  }
                : {
                    id: localAddon.id,
                    mmsTabProps: {
                      tabIndex: currentTabIndex,
                      isSelected,
                      disabled: isSelectTabModeDisabled,
                      isDraggable: !getIsPartner(),
                      isDragAndDropDisabled,
                      draggableType: `MenuAddon-${parentId}`,
                      onSelect,
                    },
                    addonTabPanelProps: {
                      ...addonTabPanelCommonProps,
                      addon: localAddon,
                      isSelected,
                      onClone,
                    },
                  };
            }
            case AddonsSettingsStrategyEnum.menu_item_detached: {
              return {
                id: localAddon.id,
                mmsTabProps: {
                  tabIndex: currentTabIndex,
                  isSelected,
                  disabled: isSelectTabModeDisabled,
                  onSelect,
                },
                addonTabPanelProps: {
                  ...addonTabPanelCommonProps,
                  addon: localAddon,
                  isSelected,
                  onClone,
                },
              };
            }
            default:
              throw new Error(
                "NewAddonsSettings -> NewAddonList -> render(): Wrong addonsSettingsStrategy"
              );
          }
        })}
        ItemComponent={AddonTabPanelSortable}
        onChangeOrder={handleChangeOrder}
      />
    </>
  );
};
