import { useReactiveVar } from "@apollo/client";
import { activeAssetsVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

interface AssetsType {
  [key: string]: boolean;
}

export const useActiveAssets = (): [
  AssetsType,
  (assets: AssetsType) => void,
  VoidFunction
] => {
  const addAssets = useCallback((assets: AssetsType) => {
    activeAssetsVar(assets);
  }, []);

  const cleanAssets = useCallback(() => {
    activeAssetsVar({});
  }, []);

  return [useReactiveVar(activeAssetsVar), addAssets, cleanAssets];
};
