import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const EDIT_CATEGORY_DESCRIPTION = gql`
  mutation EditCategoryDescriptionMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuCategory {
        description
        id
      }
    }
  }
`;

export const generateEditCategoryDescriptionVars = (
  restaurantId: string,
  categoryId: string,
  newDescription: string
) => ({
  input: {
    restaurantId,
    entityType: Levels.CATEGORY,
    entityId: categoryId,
    category: {
      description: newDescription,
    },
  },
});

export * as EditCategoryDescriptionTypes from "./__generated__/EditCategoryDescriptionMutation";
