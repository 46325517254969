import { LOGS_TABLE_CELLS_NAMES } from "@constants";
import { useLogFiltersPagination } from "@hooks";
import Paper from "@material-ui/core/Paper";
import { Logs } from "@model/DAO/Logs";
import { themeColors } from "@theme/colors";
import { MmsEmptyContentBanner } from "@uiKit/Atoms/EmptyContentBanner/MmsEmptyContentBanner";
import { MmsTable } from "@uiKit/Molecules/Table/MmsTable";
import { MmsTableHead } from "@uiKit/Molecules/Table/MmsTableHead";
import { MmsTablePagination } from "@uiKit/Molecules/Table/MmsTablePagination";
import React from "react";
import { styles } from "./LogsTable.styles";
import { LogsTableRow } from "./LogsTableRow/LogsTableRow";

export interface LogsTableProps {
  logsData: Logs;
  disablePaginationActions: boolean;
}

export const LogsTable: React.FC<LogsTableProps> = ({
  logsData,
  disablePaginationActions,
}) => {
  const { nodes, pagination } = logsData;
  const { total, perPage, offset } = pagination;

  const [, currentPage, { onChangePage }] = useLogFiltersPagination();

  if (!nodes.length) {
    return (
      <MmsEmptyContentBanner
        title={"No logs available"}
        color={themeColors.white}
      />
    );
  }
  return (
    <MmsTable
      component={Paper}
      tableHeadComponent={
        <MmsTableHead
          className={styles.headContainer}
          colsNames={LOGS_TABLE_CELLS_NAMES}
          classNameCell={styles.headCell}
        />
      }
      tableBodyComponent={
        <>
          {nodes.map((node) => (
            <LogsTableRow key={node.id} {...node} />
          ))}
        </>
      }
      tablePaginationComponent={
        <MmsTablePagination
          page={currentPage}
          pageCount={total}
          offset={offset}
          perPage={perPage}
          onChangePage={onChangePage}
          disable={disablePaginationActions}
        />
      }
    />
  );
};
