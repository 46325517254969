import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface IconedListProps {
  className?: string;
  children: React.ReactNode;
}

export const IconedList: React.FC<IconedListProps> = ({
  className,
  children,
}) => {
  return (
    <ul className={cx(styles.list, className)}>
      {children &&
        React.Children.map(children, (child) => {
          if (!child) return;
          return <li className={styles.item}>{child}</li>;
        })}
    </ul>
  );
};
