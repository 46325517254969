import { NetworkStatus, useReactiveVar } from "@apollo/client";
import { useDidMount, useDidUpdate } from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { CategoryListErrorMessage } from "@pages/RestaurantMenuPage/components/CategoryList/CategoryListErrorMessage/CategoryListErrorMessage";
import { CategoryListSkeleton } from "@pages/RestaurantMenuPage/components/CategoryList/CategoryListSkeleton/CategoryListSkeleton";
import { useLazyGetMenuCategories } from "@pages/RestaurantMenuPage/gqlHooks/category/queries";
import { useLazyGetAllCategoriesMenuItems } from "@pages/RestaurantMenuPage/gqlHooks/menuItem/queries";
import {
  isMenuCategoriesLoadingVar,
  menuCategoriesVar,
  menuFiltersVar,
  shouldExpandAllCategoriesVar,
  shouldExpandAllMenuItemsVar,
} from "@utils/apolloReactiveVars";
import React from "react";

export interface IncomingProps {}

export interface OutgoingProps extends IncomingProps {
  categories: ModelFullCategory[];
  isCategoriesDataLoading: boolean;
  isAllCategoriesMenuItemsDataLoading: boolean;
  allCategoriesMenuItemsNetworkStatus?: NetworkStatus;
}

// TODO: need to try to get rid of switch/case based on network status
export const withCategories = (
  Component: React.ComponentType<OutgoingProps>
): React.FC<IncomingProps> => (props) => {
  const menuFilters = useReactiveVar(menuFiltersVar);
  const localCategories = useReactiveVar(menuCategoriesVar);

  const [
    fetchCategories,
    {
      data: categoriesData,
      loading: isCategoriesDataLoading,
      refetch: refetchCategoriesData,
      networkStatus: categoriesNetworkStatus,
    },
  ] = useLazyGetMenuCategories();

  const [
    fetchAllCategoriesMenuItemsQuery,
    {
      loading: isAllCategoriesMenuItemsDataLoading,
      refetch: refetchAllCategoriesMenuItemsData,
      networkStatus: allCategoriesMenuItemsNetworkStatus,
    },
  ] = useLazyGetAllCategoriesMenuItems();

  useDidMount(() => {
    fetchCategories();
    fetchAllCategoriesMenuItemsQuery();
  });

  useDidUpdate(() => {
    shouldExpandAllCategoriesVar(false);
    shouldExpandAllMenuItemsVar(false);
    refetchCategoriesData!();
    refetchAllCategoriesMenuItemsData!();
  }, [menuFilters]);

  useDidUpdate(() => {
    isMenuCategoriesLoadingVar(isCategoriesDataLoading);
  }, [isCategoriesDataLoading]);

  useDidUpdate(() => {
    if (!isAllCategoriesMenuItemsDataLoading) return;
    if (!menuFilters.activenessPresent && !menuFilters.titleCont) return;
    if (menuFilters.titleCont) shouldExpandAllMenuItemsVar(true);
    shouldExpandAllCategoriesVar(true);
  }, [isAllCategoriesMenuItemsDataLoading, menuFilters]);

  useDidUpdate(() => {
    if (!categoriesData) return;
    menuCategoriesVar(categoriesData.menu.categories as ModelFullCategory[]);
  }, [categoriesData]);

  switch (categoriesNetworkStatus) {
    case NetworkStatus.loading:
      return <CategoryListSkeleton />;
    case NetworkStatus.ready:
      return categoriesData ? (
        <Component
          {...props}
          categories={categoriesData.menu.categories as ModelFullCategory[]}
          isCategoriesDataLoading={isCategoriesDataLoading}
          isAllCategoriesMenuItemsDataLoading={
            isAllCategoriesMenuItemsDataLoading
          }
          allCategoriesMenuItemsNetworkStatus={
            allCategoriesMenuItemsNetworkStatus
          }
        />
      ) : (
        <CategoryListErrorMessage />
      );
    case NetworkStatus.setVariables:
      return localCategories ? (
        <Component
          {...props}
          categories={localCategories}
          isCategoriesDataLoading={isCategoriesDataLoading}
          isAllCategoriesMenuItemsDataLoading={
            isAllCategoriesMenuItemsDataLoading
          }
          allCategoriesMenuItemsNetworkStatus={
            allCategoriesMenuItemsNetworkStatus
          }
        />
      ) : (
        <CategoryListSkeleton />
      );
    default:
      return (
        <Component
          {...props}
          categories={
            (categoriesData?.menu.categories as ModelFullCategory[]) || []
          }
          isCategoriesDataLoading={isCategoriesDataLoading}
          isAllCategoriesMenuItemsDataLoading={
            isAllCategoriesMenuItemsDataLoading
          }
          allCategoriesMenuItemsNetworkStatus={
            allCategoriesMenuItemsNetworkStatus
          }
        />
      );
  }
};
