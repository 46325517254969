import { gql } from "@apollo/client";
import { ModelCategoryDNA, ModelFullCategory } from "@model/DAO/MenuCategory";
import {
  BulkRequestActionEnum,
  LevelsBulkEnum,
} from "@model/helperTypes/BulkEditing";

export const BULK_UPDATE_DISCOUNT = gql`
  mutation BulkUpdateDiscountMutation($input: BulkData!) {
    mmsBulk(input: $input)
  }
`;

export const generateBulkUpdateDiscountVars = (
  restaurantId: string,
  selectedCategoriesIds: ModelFullCategory["id"][],
  discount: ModelCategoryDNA["discount"],
  takeawayDiscount: ModelCategoryDNA["takeawayDiscount"],
  deliveryDiscount: ModelCategoryDNA["deliveryDiscount"]
) => ({
  input: {
    restaurantId,
    levelType: LevelsBulkEnum.category,
    ids: selectedCategoriesIds,
    bulkAction: BulkRequestActionEnum.update,
    params: { discount, takeawayDiscount, deliveryDiscount },
  },
});

export * as BulkUpdateDiscountTypes from "./__generated__/BulkUpdateDiscountMutation";
