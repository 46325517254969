import { css } from "@emotion/css";

export const styles = {
  scheduleTasksModal: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 915px;
    height: 500px;

    overflow: hidden;
  `,
};
