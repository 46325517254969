import { ReactComponent as SVGMoreHorizontal } from "@assets/SVG/more-horizontal.svg";
import { cx } from "@emotion/css";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IMoreHorizontalProps extends IIconBase {}

export const MoreHorizontal: React.FC<IMoreHorizontalProps> = ({
  id,
  size = "medium",
  className,
}) => {
  const ref = useSvgFilter(id);
  return (
    <SVGMoreHorizontal ref={ref} className={cx(styles.icon(size), className)} />
  );
};
