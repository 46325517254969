import { AuthPage } from "@pages/Auth/AuthPage";
import { ErrorPage } from "@pages/ErrorPage/ErrorPage";
import { HealthCheckPage } from "@pages/HealthCheckPage/HealthCheckPage";
import { IndexPage } from "@pages/IndexPage/IndexPage";
import LogsPage from "@pages/LogsPage/LogsPage";
import { PageNotFound } from "@pages/PageNotFound/PageNotFound";
import RestaurantMenuPage from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { RouteComponentProps } from "react-router-dom";
import ReleaseNotesPage from "@pages/ReleaseNotesPage/ReleaseNotesPage";

export interface RouteData {
  exact?: boolean;
  path: string;
  FeatureComponent:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  extraProps?: any;
  isPrivate?: boolean;
}

export const routes: RouteData[] = [
  {
    exact: true,
    path: "/auth",
    FeatureComponent: AuthPage,
  },
  {
    exact: true,
    path: "/partner/:restaurantId/menu",
    FeatureComponent: RestaurantMenuPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: "/restaurant/:restaurantId/menu",
    FeatureComponent: RestaurantMenuPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: "/restaurant/:restaurantId/menu/logs",
    FeatureComponent: LogsPage,
  },
  {
    isPrivate: true,
    exact: true,
    path: "/restaurant/:restaurantId/menu/release-notes",
    FeatureComponent: ReleaseNotesPage,
  },
  {
    exact: true,
    path: "/404",
    FeatureComponent: PageNotFound,
  },
  {
    exact: true,
    path: "/error",
    FeatureComponent: ErrorPage,
  },
  {
    exact: true,
    path: "/.health",
    FeatureComponent: HealthCheckPage,
  },
  {
    path: "*",
    FeatureComponent: IndexPage,
  },
];
