import {
  useBulkEditing,
  useDidUpdate,
  useFormErrorsProtection,
  useOpenClose,
  useToggle,
} from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { BulkMoreActionsEnum } from "@model/helperTypes/BulkEditing";
import {
  useBulkUpdateAddonItemPricesMutation,
  useBulkUpdateAvailabilityMutation,
  useBulkUpdateDiscountMutation,
  useBulkUpdateMenuItemPricesMutation,
} from "@pages/RestaurantMenuPage/gqlHooks/bulkActions/mutations";
import { useLazyGetAllCategoriesMenuItems } from "@pages/RestaurantMenuPage/gqlHooks/menuItem/queries";
import { useBulkSelectedEntities } from "@pages/RestaurantMenuPage/hooks/useBulkSelectedEntities";
import { BulkMoreModal } from "@pages/RestaurantMenuPage/modals/BulkMoreModal/BulkMoreModal";
import { themeColors } from "@theme/colors";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import { MmsSimpleSpinner } from "@uiKit/Atoms/Spinner/MmsSimpleSpinner";
import { Chevron, MoreHorizontalSolid, Trash } from "@uiKit/Atoms/SVGIcons";
import { MmsSwitch, SizeEnum } from "@uiKit/Atoms/Switch/MmsSwitch";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { noop } from "@utils/noop";
import React, { useCallback, useEffect, useMemo } from "react";
import { styles } from "./BulkEditingPanel.styles";
import { BulkMoreSettings } from "./BulkMoreSettings/BulkMoreSettings";
import { useTranslation } from "react-i18next";

interface BulkEditingPanelProps {
  categoriesIds: ModelFullCategory["id"][];
  isSelectedEntitiesEnabled: boolean;
  isEnableDisableEntitiesLoading: boolean;
  onBulkDelete: VoidFunction;
  onBulkEnableDisable: VoidFunction;
}

export const BulkEditingPanel: React.FC<BulkEditingPanelProps> = ({
  categoriesIds,
  isSelectedEntitiesEnabled,
  isEnableDisableEntitiesLoading,
  onBulkDelete,
  onBulkEnableDisable,
}) => {
  const { t } = useTranslation(["bulk-edit"]);

  const {
    selectedEntities,
    callbacks: { onSelectAllCategories, onDeselectAllCategories },
  } = useBulkSelectedEntities();
  const { selectedMenuItems, selectedCategories } = selectedEntities;

  const [
    isBulkMoreModalOpen,
    openBulkMoreModal,
    closeBulkMoreModal,
  ] = useOpenClose(false);

  const [, setExpandCollapseAction, , cleanBulkAction] = useBulkEditing();
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();
  const [
    fetchAllCategoriesMenuItemsQuery,
    {
      data: allCategoriesMenuItemsData,
      loading: isAllCategoriesMenuItemsDataLoading,
    },
  ] = useLazyGetAllCategoriesMenuItems();

  const [
    isSelectedEntitiesExpanded,
    toggleSelectedEntitiesExpanded,
    toggleSelectedEntitiesExpandedManual,
  ] = useToggle(false);

  const [
    bulkUpdateAvailabilityMutation,
    isBulkUpdateAvailabilityProcessing,
  ] = useBulkUpdateAvailabilityMutation();

  const [
    bulkUpdateDiscountMutation,
    isBulkUpdateDiscountProcessing,
  ] = useBulkUpdateDiscountMutation();

  const [
    bulkUpdateMenuItemPricesMutation,
    isUpdateMenuItemPricesProcessing,
  ] = useBulkUpdateMenuItemPricesMutation();

  const [
    bulkUpdateAddonItemPricesMutation,
    isUpdateAddonItemPricesProcessing,
  ] = useBulkUpdateAddonItemPricesMutation();

  const handleApplyClick = useCallback(
    (currentBulkAction: BulkMoreActionsEnum, data: { [key: string]: any }) => {
      switch (currentBulkAction) {
        case BulkMoreActionsEnum.discountUpdate: {
          const { discount, takeawayDiscount, deliveryDiscount } = data;
          bulkUpdateDiscountMutation(
            selectedEntities,
            discount,
            takeawayDiscount,
            deliveryDiscount
          );
          break;
        }
        case BulkMoreActionsEnum.availabilityUpdate: {
          const { activeFrom, activeTo } = data;
          bulkUpdateAvailabilityMutation(
            selectedEntities,
            activeFrom,
            activeTo
          );
          break;
        }
        case BulkMoreActionsEnum.menuItemPricesUpdate: {
          const { bulkPriceUpdateOption, bulkPrice } = data;
          bulkUpdateMenuItemPricesMutation(
            selectedEntities,
            bulkPriceUpdateOption,
            bulkPrice
          );
          break;
        }
        case BulkMoreActionsEnum.addonItemPricesUpdate: {
          const { bulkPriceUpdateOption, bulkPrice } = data;
          bulkUpdateAddonItemPricesMutation(
            selectedEntities,
            bulkPriceUpdateOption,
            bulkPrice
          );
          break;
        }
        default:
          throw new Error(
            "BulkEditingPanel -> BulkMoreSettings -> handleApplyClick: Wrong currentBulkAction"
          );
      }
    },
    [
      bulkUpdateAddonItemPricesMutation,
      bulkUpdateAvailabilityMutation,
      bulkUpdateDiscountMutation,
      bulkUpdateMenuItemPricesMutation,
      selectedEntities,
    ]
  );

  const isCheckboxEnabled = useMemo(() => {
    return categoriesIds.length === selectedCategories.length;
  }, [categoriesIds, selectedCategories.length]);

  const selectedEntitiesCount = useMemo(
    () => selectedCategories.length + selectedMenuItems.length,
    [selectedCategories.length, selectedMenuItems.length]
  );

  const isBulkEditingToolbarVisible = useMemo(() => {
    return !!selectedCategories.length || !!selectedMenuItems.length;
  }, [selectedCategories.length, selectedMenuItems.length]);

  const onToggleCheckbox = useCallback(() => {
    cleanBulkAction();
    if (!isCheckboxEnabled) onSelectAllCategories();
    else onDeselectAllCategories();
  }, [
    cleanBulkAction,
    isCheckboxEnabled,
    onDeselectAllCategories,
    onSelectAllCategories,
  ]);

  useEffect(() => {
    if (isBulkEditingToolbarVisible) return;

    toggleSelectedEntitiesExpandedManual(false);
  }, [isBulkEditingToolbarVisible, toggleSelectedEntitiesExpandedManual]);

  // send query on each bulk expand action
  // it will be called on each expand action
  // but because of apollo cache, request will not be sent each time if data isn't changed
  useDidUpdate(() => {
    if (!isSelectedEntitiesExpanded) return;
    fetchAllCategoriesMenuItemsQuery();
  }, [isSelectedEntitiesExpanded]);

  useDidUpdate(() => {
    // if user tries to expand categories with bulk editing, but there is no data for menu items,
    // or new data is being loaded - don't expand categories, because they may be rendered with outdated data
    if (isSelectedEntitiesExpanded) {
      if (!allCategoriesMenuItemsData) return;
      if (isAllCategoriesMenuItemsDataLoading) return;
    }

    // if user tries to collapse categories with bulk editing,
    // or tries to expand ones and already has a fresh data - then do it
    setExpandCollapseAction(isSelectedEntitiesExpanded);
  }, [
    isAllCategoriesMenuItemsDataLoading,
    allCategoriesMenuItemsData,
    isSelectedEntitiesExpanded,
  ]);

  const isLoaderOnApplyButtonVisible = useMemo(
    () =>
      [
        isBulkUpdateAvailabilityProcessing,
        isBulkUpdateDiscountProcessing,
        isUpdateMenuItemPricesProcessing,
        isUpdateAddonItemPricesProcessing,
      ].some(Boolean),
    [
      isBulkUpdateAvailabilityProcessing,
      isBulkUpdateDiscountProcessing,
      isUpdateAddonItemPricesProcessing,
      isUpdateMenuItemPricesProcessing,
    ]
  );

  if (!categoriesIds.length) return null;

  return (
    <>
      <div className={styles.container}>
        <MmsTooltip
          title={
            isCheckboxEnabled
              ? t("bulk-edit:deselect-entire-menu")
              : t("bulk-edit:select-entire-menu")
          }
        >
          <div>
            <MmsCheckbox
              id={`category-list-checkbox`}
              containerClassName={styles.selectAllBtn}
              checked={isCheckboxEnabled}
              onChange={withActiveFormsErrorsProtection(onToggleCheckbox)}
            />
          </div>
        </MmsTooltip>
        {isBulkEditingToolbarVisible && (
          <>
            <AccessControl>
              <MmsTooltip
                title={`Delete ${selectedEntitiesCount} item${
                  selectedEntitiesCount === 1 ? "" : "s"
                }`}
              >
                <MmsSvgButton
                  onClick={withActiveFormsErrorsProtection(onBulkDelete)}
                  id={`delete-selected-categories`}
                  className={styles.commonIconStyles}
                >
                  <Trash
                    id={`delete-selected-categories-icon`}
                    size="small"
                    color={themeColors.white}
                  />
                </MmsSvgButton>
              </MmsTooltip>
            </AccessControl>
            <div
              className={styles.commonIconStyles}
              onClick={withActiveFormsErrorsProtection(onBulkEnableDisable)}
            >
              {isEnableDisableEntitiesLoading ? (
                <MmsSimpleSpinner />
              ) : (
                <MmsSwitch
                  id={`toggle-selected-items-controls`}
                  size={SizeEnum.small}
                  checked={isSelectedEntitiesEnabled}
                  onChange={noop}
                />
              )}
            </div>
            {isAllCategoriesMenuItemsDataLoading ? (
              <div className={styles.commonIconStyles}>
                <MmsSimpleSpinner />
              </div>
            ) : (
              <MmsTooltip title={t("bulk-edit:expand-collapse-these-assets")}>
                <MmsSvgButton
                  onClick={withActiveFormsErrorsProtection(
                    toggleSelectedEntitiesExpanded
                  )}
                  id={`expand-selected-categories`}
                  className={styles.commonIconStyles}
                >
                  <Chevron
                    id={`expand-selected-categories-icon`}
                    direction={isSelectedEntitiesExpanded ? "bottom" : "right"}
                    color={themeColors.white}
                  />
                </MmsSvgButton>
              </MmsTooltip>
            )}
            <MmsTooltip title={t("bulk-edit:show-more-bulk-action")}>
              <MmsSvgButton
                onClick={withActiveFormsErrorsProtection(openBulkMoreModal)}
                id={`show-more-bulk-actions`}
                className={styles.commonIconStyles}
              >
                <MoreHorizontalSolid
                  id={`show-more-bulk-actions-icon`}
                  color={themeColors.white}
                />
              </MmsSvgButton>
            </MmsTooltip>
          </>
        )}
      </div>
      {isBulkMoreModalOpen && (
        <BulkMoreModal closeModal={closeBulkMoreModal}>
          <BulkMoreSettings
            onCancelClick={closeBulkMoreModal}
            onApplyClick={handleApplyClick}
            isApplyProcessing={isLoaderOnApplyButtonVisible}
          />
        </BulkMoreModal>
      )}
    </>
  );
};
