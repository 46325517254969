import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    justify-content: flex-start;
  `,
  dataOwnerType: css`
    margin-bottom: 20px;

    color: ${themeColors.mediumGray};
    font-weight: bold;
    text-transform: uppercase;
  `,
  dataOwnerName: (isMenuType: boolean) => css`
    margin-bottom: 1px;
    padding: 8px 17px;

    border-radius: ${themeVars.borderRadius};

    background-color: ${isMenuType ? themeColors.mediumGray : themeColors.white};

    color: ${isMenuType ? themeColors.white : themeColors.mediumGray};
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
};
