import { useActiveAssets, useAddonsLibraryModal, useOpenClose } from "@hooks";
import { useCallback, useEffect } from "react";

type UseImportAddonsFromLibraryAPI = {
  confirmImportFromLibrary: VoidFunction;
  handleImportFromLibraryClick: VoidFunction;
  closeUnsavedChangesWarningModal: VoidFunction;
};
type ReturnTupleType = [boolean, UseImportAddonsFromLibraryAPI];

export const useImportAddonsFromLibrary = (
  isUpdateProcessing: boolean,
  isAddonsChanged: boolean,
  updateAddonsAndProceed: VoidFunction
): ReturnTupleType => {
  const [, openAddonsLibraryModal] = useAddonsLibraryModal();
  const [
    isReadyToImportFromLibrary,
    setReadyToImportFromLibrary,
    setNotReadyToImportFromLibrary,
  ] = useOpenClose(false);

  const [
    isUnsavedChangesWarningModalOpen,
    openUnsavedChangesWarningModal,
    closeUnsavedChangesWarningModal,
  ] = useOpenClose(false);

  const confirmImportFromLibrary = useCallback(() => {
    updateAddonsAndProceed();
    setReadyToImportFromLibrary();
  }, [setReadyToImportFromLibrary, updateAddonsAndProceed]);

  const [, setAssets] = useActiveAssets();
  const importAddonsFromLibrary = useCallback(() => {
    setAssets({ addons: true });
    openAddonsLibraryModal();
  }, [openAddonsLibraryModal, setAssets]);

  const handleImportFromLibraryClick = useCallback(() => {
    if (isUpdateProcessing) return;

    isAddonsChanged
      ? openUnsavedChangesWarningModal()
      : importAddonsFromLibrary();
  }, [
    isUpdateProcessing,
    isAddonsChanged,
    openUnsavedChangesWarningModal,
    importAddonsFromLibrary,
  ]);

  useEffect(() => {
    if (!isReadyToImportFromLibrary) return;
    if (isUpdateProcessing) return;

    closeUnsavedChangesWarningModal();
    importAddonsFromLibrary();
    setNotReadyToImportFromLibrary();
  }, [
    isReadyToImportFromLibrary,
    isUpdateProcessing,
    closeUnsavedChangesWarningModal,
    importAddonsFromLibrary,
    setNotReadyToImportFromLibrary,
  ]);

  return [
    isUnsavedChangesWarningModalOpen,
    {
      confirmImportFromLibrary,
      handleImportFromLibraryClick,
      closeUnsavedChangesWarningModal,
    },
  ];
};
