import { ProviderExoticComponent, ProviderProps } from "react";

export enum DraggableEnum {
  category = "category",
  menuItem = "menuItem",
  addon = "addon",
  addonItem = "addonItem",
  dummy = "dummy",
}

export type PositionDataType = {
  id: string;
  orderBy: number;
};

export type RerankableEntity<T> = T & {
  id: string;
};

export enum LevelsRerank {
  category = "category",
  menu = "menu",
}

export interface DraggableDataItem<T> {
  id: number;
  itemData: T;
}

export interface DraggableDataState<T> {
  initialDraggableData: DraggableDataItem<T>[];
  mutableDraggableData: DraggableDataItem<T>[];
}

export interface TransferableData {
  itemId: string;
  parentIdFrom: string;
  parentIdTo: string;
}

export interface DragAndDropOptions<T> {
  draggableItem: {
    id: number;
    itemData: T;
  };
  moveDraggableItem: (draggedId: number, hoverId: number) => void;
  onRerankEnd: VoidFunction;
  onTransferEnd: (transferedData: TransferableData) => void;
}

export type DragAndDropProviderReturnTuple<T> = [
  DraggableDataItem<T>[],
  (id: number, afterId: number) => void,
  ProviderExoticComponent<ProviderProps<DragAndDropOptions<T>>>
];

export type DragAndDropClientReturnTuple = [
  {
    draggableRef: React.RefObject<HTMLDivElement>;
    handleRef: React.RefObject<HTMLDivElement>;
    previewRef: React.RefObject<HTMLDivElement>;
  },
  { isDragging: boolean; didDrop: boolean },
  (isEnabled: boolean) => void
];

export interface TransferAction {
  action: "TRANSFER";
  payload: TransferableData;
}

export interface DefaultDropResult {
  move: string;
}

export type DropResult = TransferAction | DefaultDropResult | null;
