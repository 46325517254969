import { css } from "@emotion/css";

export const styles = {
  controlContainer: css`
    gap: 5px;
    align-items: center;
    margin-left: 15px;
  `,
  baseControl: css`
    width: 17px;
    height: 17px;
  `,
  baseTrashControl: css`
    width: 15px;
    height: 15px;
  `,
};
