import { gql } from "@apollo/client";
import { CATEGORY_ESSENTIAL_DATA } from "../../fragments/Category";

export const GET_MENU_CATEGORIES = gql`
  query GetMenuCategoriesQuery($menuId: ID!, $filters: MenuFilter) {
    menu(id: $menuId, q: $filters) {
      categories {
        ...CategoryEssentialData
      }
    }
  }
  ${CATEGORY_ESSENTIAL_DATA}
`;

export * as GetMenuCategoriesTypes from "./__generated__/GetMenuCategoriesQuery";
