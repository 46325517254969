import { css } from "@emotion/css";

export const styles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    min-height: 100vh;

    background-color: #29846b;
  `,
  img: css`
    width: 450px;
  `,
};
