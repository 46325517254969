import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  headerLayout: (visibility: string) => css`
    visibility: ${visibility};
  `,
  baseControl: css`
    padding: 0 18px;
  `,
  libraryPopper: css`
    margin-top: 15px;

    z-index: 10;
  `,
  publishMenuPopper: css`
    margin-top: 15px;

    z-index: 10;
  `,
  uploadInput: css`
    display: none;
  `,
  filterBtn: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      border-radius: 8px;

      background-color: ${themeColors.black};

      opacity: 0;
    }

    &:active::before {
      opacity: 0.05;
    }
  `,
};
