import { ModelAllergen } from "@model/DAO/Allergen";
import { MmsMultiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMultiSelectbox";
import { MultiSelectboxDataType } from "@uiKit/Atoms/Selectbox/MmsMultiSelectbox/types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface AllergensFormProps {
  countryAllergens: ModelAllergen[];
  selectedAllergens: ModelAllergen[];
  onSubmit: (allergens: ModelAllergen[]) => void;
}

const allergensToMultiSelectboxDataType = (
  allergens: ModelAllergen[]
): MultiSelectboxDataType[] => {
  return allergens.map(({ id, code, title }) => ({
    label: `${code} - ${title}`,
    value: id,
  }));
};

const selectedOptionsToAllergens = (
  options: MultiSelectboxDataType[],
  allergens: ModelAllergen[]
): ModelAllergen[] => {
  const optionsValues = options.map(({ value }) => value);
  return allergens.filter(({ id }) => optionsValues.includes(id));
};

export const AllergensForm: React.FC<AllergensFormProps> = ({
  countryAllergens,
  selectedAllergens,
  onSubmit,
}) => {
  const { t } = useTranslation(["menu"]);
  const allOptions = useMemo(
    () => allergensToMultiSelectboxDataType(countryAllergens),
    [countryAllergens]
  );
  const selectedOptions = useMemo(
    () => allergensToMultiSelectboxDataType(selectedAllergens),
    [selectedAllergens]
  );

  const handleChangeAllergens = useCallback(
    (selectedOptions) => {
      onSubmit(selectedOptionsToAllergens(selectedOptions, countryAllergens));
    },
    [countryAllergens, onSubmit]
  );

  return (
    <MmsMultiSelectbox
      selectedOptions={selectedOptions}
      allOptions={allOptions}
      onChange={handleChangeAllergens}
      isAlwaysOpen
      maxMenuHeight={225}
      placeholder={t("menu:no-allergens-available")}
    />
  );
};
