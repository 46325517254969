import { css } from "@emotion/css";
import { themeVars } from "@theme/vars";

export const styles = {
  tooltipTitle: css`
    display: flex;
    justify-content: center;
    margin: 0 10px;

    font-family: ${themeVars.fontFamily};
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    text-align: center;

    overflow: hidden;
    z-index: 1400;
  `,
};
