import { ReactComponent as SVGBack } from "@assets/SVG/back.svg";
import { cx } from "@emotion/css";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IBackProps extends IIconBase {}

export const Back: React.FC<IBackProps> = ({
  id,
  size = "medium",
  className,
}) => {
  const ref = useSvgFilter(id);
  return <SVGBack ref={ref} className={cx(styles.icon(size), className)} />;
};
