import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { hundredPercentFormat } from "@utils/inputFomats";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import { MmsFormDescription } from "@uiKit/Atoms/FormDescription/MmsFormDescription";

interface StaticFormValues {
  discount: string;
  takeawayDiscount: string;
  deliveryDiscount: string;
}

interface DiscountFormContentProps {
  uniqId: string;
  title: string;
}

const DiscountFormContent: React.FC<
  DiscountFormContentProps & FormikProps<StaticFormValues>
> = ({ uniqId, title, ...formikProps }) => {
  const { t } = useTranslation(["discounts"]);
  const formId = `discount-form-${uniqId}`;

  const handleBlur = useCallback(() => {
    formikProps.submitForm();
  }, [formikProps]);

  return (
    <Form>
      <MmsFormTitle>{t("discounts:takeaway")}</MmsFormTitle>
      <NumberFormat
        data-testid={formId}
        id={formId}
        customInput={MmsMuiInput}
        format={hundredPercentFormat}
        name="takeawayDiscount"
        label={t("discounts:discount")}
        value={formikProps?.values?.takeawayDiscount}
        onBlur={handleBlur}
        fullWidth
        showUnderline
      />
      <MmsFormDescription>
        {t("discounts:takeaway-discount-will-override-common-discount")}
      </MmsFormDescription>
      <MmsFormTitle>{t("discounts:delivery-discount")}</MmsFormTitle>
      <NumberFormat
        data-testid={formId}
        id={formId}
        customInput={MmsMuiInput}
        format={hundredPercentFormat}
        name="deliveryDiscount"
        label={t("discounts:discount")}
        value={formikProps?.values?.deliveryDiscount}
        onBlur={handleBlur}
        fullWidth
        showUnderline
      />
      <MmsFormDescription>
        {t("discounts:delivery-discount-will-override-common-discount")}
      </MmsFormDescription>
      <MmsFormTitle>{t("discounts:common-discount")}</MmsFormTitle>
      <NumberFormat
        data-testid={formId}
        id={formId}
        customInput={MmsMuiInput}
        format={hundredPercentFormat}
        name="discount"
        label={t("discounts:discount")}
        value={formikProps?.values?.discount}
        onBlur={handleBlur}
        fullWidth
        showUnderline
      />
      <MmsFormDescription>
        {t("discounts:common-discount-will-be-applied-for-delivery-and-pickup")}
      </MmsFormDescription>
    </Form>
  );
};
export interface DiscountFormProps extends DiscountFormContentProps {
  discount: string;
  takeawayDiscount: string;
  deliveryDiscount: string;
  onSubmit: (values: any) => void;
}

export const DiscountForm = withFormik<DiscountFormProps, StaticFormValues>({
  mapPropsToValues: ({ discount, takeawayDiscount, deliveryDiscount }) => ({
    discount,
    takeawayDiscount,
    deliveryDiscount,
  }),
  handleSubmit: (
    values: any,
    { props: { discount, takeawayDiscount, deliveryDiscount, onSubmit } }
  ) => {
    if (
      discount !== values.discount ||
      takeawayDiscount !== values.takeawayDiscount ||
      deliveryDiscount !== values.deliveryDiscount
    )
      return onSubmit(values);
  },
})(DiscountFormContent);
