import {
  useActiveProgressBars,
  useFormErrorsProtection,
  useKeepFocus,
  usePriceEditingMode,
  useTogglePopper,
} from "@hooks";
import { LogAuditableEnum } from "@model/helperTypes/LogFilters";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { generateLibraryProgressBarId } from "@pages/RestaurantMenuPage/utils/Library";
import { generateLogsAssetParams } from "@pages/RestaurantMenuPage/utils/Logs";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink";
import {
  Clock,
  Clone,
  Cloud,
  List,
  Settings,
  Trash,
} from "@uiKit/Atoms/SVGIcons";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsProgressBar } from "@uiKit/Molecules/ProgressBar/MmsProgressBar";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CloudPopper } from "../../../modals/CloudPopper/CloudPopper";
import { styles } from "./MenuItemControlsSection.styles";
import { useTranslation } from "react-i18next";

interface MenuItemControlsSectionProps {
  id: string;
  onDelete: VoidFunction;
  onClone: VoidFunction;
  onShowCommonSettings: VoidFunction;
  onShowAddons: VoidFunction;
  onSave: VoidFunction;
  onImport: VoidFunction;
  toggleControlsVisible: VoidFunction;
  hasOwnAddons: boolean;
  isFreeItem: boolean | null;
}

export const MenuItemControlsSection: React.FC<MenuItemControlsSectionProps> = ({
  id,
  onDelete,
  onClone,
  onShowCommonSettings,
  onShowAddons,
  onSave,
  onImport,
  toggleControlsVisible,
  hasOwnAddons,
  isFreeItem,
}) => {
  const { t } = useTranslation(["common", "addons", "category", "library"]);
  const { restaurantId } = useParams<MatchParams>();

  const [, setMenuItemProgressBarId] = useActiveProgressBars();
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [, focusElemWithId] = useKeepFocus();

  const [
    libraryAnchorEl,
    isLibraryPopperOpen,
    openLibraryPopper,
    closeLibraryPopper,
  ] = useTogglePopper(false);

  const [isPriceEditingMode] = usePriceEditingMode();

  const extraLogsLinkProps = useMemo(
    () => (isPriceEditingMode ? { tabIndex: -1 } : {}),
    [isPriceEditingMode]
  );

  const handleOpenLibraryPopper = useCallback(
    ({ target }) => {
      openLibraryPopper(target);
      toggleControlsVisible();
    },
    [openLibraryPopper, toggleControlsVisible]
  );
  const handleCloseLibraryPopper = useCallback(() => {
    toggleControlsVisible();
    closeLibraryPopper();
  }, [closeLibraryPopper, toggleControlsVisible]);

  const withFocus = useCallback(
    (fn, id) => (...args: any[]) => {
      focusElemWithId(id);
      return fn(...args);
    },
    [focusElemWithId]
  );

  const handleSave = useCallback(() => {
    onSave();
    setMenuItemProgressBarId(
      generateLibraryProgressBarId(MenuAssetEnum.menu_item, id)
    );
  }, [id, onSave, setMenuItemProgressBarId]);

  return (
    <div className={styles.controlsContainer}>
      <AccessControl
        noAccessComponent={
          <>
            <MmsTooltip title={t("category:edit-settings")}>
              <MmsSvgButton
                onClick={withActiveFormsErrorsProtection(
                  withFocus(
                    onShowCommonSettings,
                    `settings-menu-item-controls-${id}`
                  )
                )}
                id={`settings-menu-item-controls-${id}`}
              >
                <Settings
                  id={`settings-menu-item-controls-${id}-icon`}
                  size="small"
                  className={styles.baseControl}
                />
              </MmsSvgButton>
            </MmsTooltip>
            <MmsTooltip title={t("addons:edit-addons")}>
              <MmsSvgButton
                onClick={withActiveFormsErrorsProtection(
                  withFocus(onShowAddons, `list-menu-item-controls-${id}`)
                )}
                id={`list-menu-item-controls-${id}`}
              >
                <List
                  id={`list-menu-item-controls-${id}-icon`}
                  size="small"
                  className={styles.baseControl}
                />
              </MmsSvgButton>
            </MmsTooltip>
          </>
        }
      >
        <MmsTooltip title={t("common:view-asset-logs")}>
          <div>
            <MmsRouterLink
              id={`menu-item-logs-controls-${id}`}
              href={`/restaurant/${restaurantId}/menu/logs`}
              search={generateLogsAssetParams({
                assetId: id,
                assetType: LogAuditableEnum.MenuItem,
              })}
              shouldOpenInNewTab
              {...extraLogsLinkProps}
            >
              <Clock
                id={`menu-item-logs-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
            </MmsRouterLink>
          </div>
        </MmsTooltip>
        <MmsTooltip
          title={t("category:save-import-from-my-library")}
          disable={isLibraryPopperOpen}
        >
          <div>
            <MmsSvgButton
              onClick={withActiveFormsErrorsProtection(
                withFocus(
                  handleOpenLibraryPopper,
                  `cloud-menu-item-controls-${id}`
                )
              )}
              id={`cloud-menu-item-controls-${id}`}
            >
              <Cloud
                id={`cloud-menu-item-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
              <MmsProgressBar
                id={generateLibraryProgressBarId(MenuAssetEnum.menu_item, id)}
                className={styles.progressBar}
              />
            </MmsSvgButton>
          </div>
        </MmsTooltip>
        <MmsTooltip title={t("category:edit-settings")}>
          <MmsSvgButton
            onClick={withActiveFormsErrorsProtection(
              withFocus(
                onShowCommonSettings,
                `settings-menu-item-controls-${id}`
              )
            )}
            id={`settings-menu-item-controls-${id}`}
          >
            <Settings
              id={`settings-menu-item-controls-${id}-icon`}
              size="small"
              className={styles.baseControl}
            />
          </MmsSvgButton>
        </MmsTooltip>
        {!isFreeItem && (
          <MmsTooltip title={t("addons:edit-addons")}>
            <MmsSvgButton
              onClick={withActiveFormsErrorsProtection(
                withFocus(onShowAddons, `list-menu-item-controls-${id}`)
              )}
              id={`list-menu-item-controls-${id}`}
            >
              <List
                id={`list-menu-item-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
            </MmsSvgButton>
          </MmsTooltip>
        )}
        <MmsTooltip title={t("addons:clone-this-asset")}>
          <MmsSvgButton
            onClick={withActiveFormsErrorsProtection(
              withFocus(onClone, `clone-menu-item-controls-${id}`)
            )}
            id={`clone-menu-item-controls-${id}`}
          >
            <Clone
              id={`clone-menu-item-controls-${id}-icon`}
              size="small"
              className={styles.baseControl}
            />
          </MmsSvgButton>
        </MmsTooltip>
        <MmsTooltip title={t("common:delete")}>
          <MmsSvgButton
            onClick={withFocus(onDelete, `trash-menu-item-controls-${id}`)}
            id={`trash-menu-item-controls-${id}`}
          >
            <Trash
              id={`trash-menu-item-controls-${id}-icon`}
              size="small"
              className={styles.baseControl}
            />
          </MmsSvgButton>
        </MmsTooltip>
        {isLibraryPopperOpen && (
          <CloudPopper
            uniqId={id}
            anchorEl={libraryAnchorEl}
            onClose={handleCloseLibraryPopper}
            onSaveToLibraryClick={handleSave}
            onImportFromLibraryClick={onImport}
            saveToLibraryTooltipTitle={t(
              "library:save-this-entire-menu-item-to-your-library"
            )}
            importFromLibraryTooltipTitle={t(
              "category:import-addon-from-your-library-to-this-menu-item"
            )}
            isImportFromLibraryDisabled={!hasOwnAddons}
          />
        )}
      </AccessControl>
    </div>
  );
};
