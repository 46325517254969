import { cx } from "@emotion/css";
import { MmsCol, MmsContainer, MmsRow } from "@uiKit/Molecules/Grid/MmsGrid";
import React from "react";
import { styles } from "./FooterLayout.styles";

export interface FooterLayoutProps {
  wrapperClassName?: string;
  children: React.ReactNode;
}

export const FooterLayout: React.FC<FooterLayoutProps> = ({
  wrapperClassName,
  children,
}) => {
  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <MmsContainer className={cx(styles.container)} fluid>
        {children && (
          <MmsRow className="d-flex justify-content-center">
            {React.Children.map(children, (child) => {
              if (!child) return;
              return <MmsCol xs="auto">{child}</MmsCol>;
            })}
          </MmsRow>
        )}
      </MmsContainer>
    </div>
  );
};
