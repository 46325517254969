import { gql } from "@apollo/client";

export const SCHEDULE_TASK_ESSENTIAL_DATA = gql`
  fragment ScheduleTaskEssentialData on MenuScheduleTask {
    id
    entityId
    entityType
    entityTitle
    userName
    runAt
  }
`;
