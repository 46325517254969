import { css } from "@emotion/css";
import { themeVars } from "@theme/vars";

export const styles = css`
  min-height: 80px;
  margin-top: 8px;

  background-image: linear-gradient(to right, white 50%, transparent 50%),
    linear-gradient(to right, white 50%, transparent 50%),
    linear-gradient(to bottom, white 50%, transparent 50%),
    linear-gradient(to bottom, white 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 12px 1px, 12px 1px, 1px 12px, 1px 12px;

  border-radius: ${themeVars.borderRadius};

  color: white;
  font-size: 16px;
  font-weight: bold;
  line-height: 48px;

  overflow: hidden;
`;
