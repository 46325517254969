import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const TOGGLE_CATEGORY = gql`
  mutation ToggleCategoryMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuCategory {
        active
        id
      }
    }
  }
`;

export const generateToggleCategoryVars = (
  restaurantId: string,
  categoryId: string,
  active: boolean,
  autoenableTimestamp: number
) => ({
  input: {
    restaurantId,
    entityType: Levels.CATEGORY,
    entityId: categoryId,
    category: {
      active,
      delayEnableAt: autoenableTimestamp,
    },
  },
});

export * as ToggleCategoryTypes from "./__generated__/ToggleCategoryMutation";
