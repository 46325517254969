import { ModelAdditive } from "@model/DAO/Additive";
import { MmsMultiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMultiSelectbox";
import { MultiSelectboxDataType } from "@uiKit/Atoms/Selectbox/MmsMultiSelectbox/types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface AdditivesFormProps {
  countryAdditives: ModelAdditive[];
  selectedAdditives: ModelAdditive[];
  onSubmit: (additives: ModelAdditive[]) => void;
}

const additivesToMultiSelectboxDataType = (
  additives: ModelAdditive[]
): MultiSelectboxDataType[] => {
  return additives.map(({ id, code, title }) => ({
    label: `${code} - ${title}`,
    value: id,
  }));
};

const selectedOptionsToAdditives = (
  options: MultiSelectboxDataType[],
  additives: ModelAdditive[]
): ModelAdditive[] => {
  const optionsValues = options.map(({ value }) => value);
  return additives.filter(({ id }) => optionsValues.includes(id));
};

export const AdditivesForm: React.FC<AdditivesFormProps> = ({
  countryAdditives,
  selectedAdditives,
  onSubmit,
}) => {
  const { t } = useTranslation(["menu"]);
  const allOptions = useMemo(
    () => additivesToMultiSelectboxDataType(countryAdditives),
    [countryAdditives]
  );
  const selectedOptions = useMemo(
    () => additivesToMultiSelectboxDataType(selectedAdditives),
    [selectedAdditives]
  );

  const handleChangeAdditives = useCallback(
    (selectedOptions) => {
      onSubmit(selectedOptionsToAdditives(selectedOptions, countryAdditives));
    },
    [countryAdditives, onSubmit]
  );

  return (
    <MmsMultiSelectbox
      selectedOptions={selectedOptions}
      allOptions={allOptions}
      onChange={handleChangeAdditives}
      isAlwaysOpen
      maxMenuHeight={225}
      placeholder={t("menu:no-additives-available")}
    />
  );
};
