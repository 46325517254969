import { useDidUpdate } from "@hooks";
import { useAddMenuToHistory } from "@pages/RestaurantMenuPage/gqlHooks/history/mutations";
import { addUniqThrottledSuccessRegularNotification } from "@pages/RestaurantMenuPage/hooks/useSuccessNotifications";
import React from "react";
import { SaveToHistorySettings } from "../../components/SaveToHistorySettings/SaveToHistorySettings";
import { useTranslation } from "react-i18next";

interface SaveToHistorySettingsContainerProps {
  publishedMenuId: string;
  onSkip: VoidFunction;
}

export const SaveToHistorySettingsContainer: React.FC<SaveToHistorySettingsContainerProps> = ({
  publishedMenuId,
  onSkip,
}) => {
  const { t } = useTranslation(["history"]);
  const [
    saveMenuToHistory,
    saveMenuToHistoryLoading,
    savedToHistoryMenuId,
  ] = useAddMenuToHistory(publishedMenuId);

  useDidUpdate(() => {
    if (saveMenuToHistoryLoading) return;
    if (!savedToHistoryMenuId) return;

    addUniqThrottledSuccessRegularNotification(
      t("history:menu-successfully-added-to-history")
    );
    onSkip();
  }, [saveMenuToHistoryLoading, savedToHistoryMenuId]);

  return (
    <SaveToHistorySettings
      onSaveToHistory={saveMenuToHistory}
      onSkip={onSkip}
      isSaveProcessing={saveMenuToHistoryLoading}
    />
  );
};
