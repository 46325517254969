import { gql } from "@apollo/client";

export const TAX_ASSOCIATION_ESSENTIAL_DATA = gql`
  fragment TaxAssociationEssentialData on TaxAssociation {
    menuCategoryId
    menuItemId
    serviceType
    taxAssociationId
    taxIds
  }
`;

export const COUNTRY_TAXES_ESSENTIAL_DATA = gql`
  fragment CountryTaxesEssentialData on Tax {
    id
    abbreviation
    name
    percentage
    country
    inclusionType
  }
`;
