import { useEffect } from "react";
import throttle from "lodash.throttle";
import { screenWidthVar } from "@utils/apolloReactiveVars";

const RESIZE_LISTENER_PENDING_INTERVAL = 300;

export const useScreenResizeWidth = () => {
  useEffect(() => {
    const handleWindowSizeChange = throttle(() => {
      screenWidthVar(document.documentElement.clientWidth);
    }, RESIZE_LISTENER_PENDING_INTERVAL);

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
};
