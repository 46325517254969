import { cx } from "@emotion/css";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { JustifyContentStartLayout } from "@uiKit/Layouts/JustifyContentStartLayout";
import React from "react";
import { styles } from "./LibraryAssetDesktopLayout.styles";

interface LibraryAssetDesktopLayoutProps {
  checkboxCell: React.ReactNode;
  titleCell: React.ReactNode;
  controlsGroupCell: React.ReactNode;
}

export const LibraryAssetDesktopLayout: React.FC<LibraryAssetDesktopLayoutProps> = ({
  checkboxCell,
  titleCell,
  controlsGroupCell,
}) => {
  return (
    <JustifyContentBetweenLayout>
      <JustifyContentStartLayout className={cx(styles.cell, styles.mainCell)}>
        <div>{checkboxCell}</div>
        <div>{titleCell}</div>
      </JustifyContentStartLayout>
      <div className={styles.cell}>{controlsGroupCell}</div>
    </JustifyContentBetweenLayout>
  );
};
