import TableRow, { TableRowProps } from "@material-ui/core/TableRow";
import React from "react";

interface MmsTableRowProps {
  children: TableRowProps["children"];
}

export const MmsTableRow: React.FC<MmsTableRowProps> = ({ children }) => (
  <TableRow>{children}</TableRow>
);
