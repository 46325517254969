import { useHover, useOpenClose } from "@hooks";
import { Tooltip, TooltipProps } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import "./globalStyles";
import { styles } from "./styles";

export interface MmsTooltipProps {
  children: TooltipProps["children"];
  title: string;
  disable?: boolean;
}

let hoverTimeout: ReturnType<typeof setTimeout>;

// todo: need to wrap tooltip's children by div
export const MmsTooltip: React.FC<MmsTooltipProps> = ({
  children,
  title,
  disable = false,
}) => {
  const [isOpenTooltip, openTooltip, closeTooltip] = useOpenClose(false);

  const [ref, { isHovered, unhover }] = useHover();

  const isDisabled = useMemo(() => isMobile || disable, [disable]);

  useEffect(() => {
    if (isHovered) {
      hoverTimeout = setTimeout(openTooltip, 1000);
    } else {
      closeTooltip();
      clearTimeout(hoverTimeout);
    }
  }, [isHovered, openTooltip, closeTooltip]);

  useEffect(() => {
    if (!isDisabled) return;

    closeTooltip();
    clearTimeout(hoverTimeout);
    unhover();
  }, [isDisabled, closeTooltip, unhover]);

  return (
    <Tooltip
      className={"MmsTooltip"}
      ref={ref}
      title={
        isDisabled ? "" : <span className={styles.tooltipTitle}>{title}</span>
      }
      disableHoverListener={true}
      disableTouchListener={true}
      disableFocusListener={true}
      arrow={!isDisabled}
      open={isOpenTooltip}
    >
      {children}
      {/* todo: need to wrap children with div */}
    </Tooltip>
  );
};
