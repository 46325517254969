import { ScheduleTasksItem } from "@pages/RestaurantMenuPage/components/ScheduleTasksSettings/ScheduleTasksItem/ScheduleTasksItem";
import { useGetScheduleTasks } from "@pages/RestaurantMenuPage/gqlHooks/schedule_tasks/queries";
import { MmsTable } from "@uiKit/Molecules/Table/MmsTable";
import { MmsTableHead } from "@uiKit/Molecules/Table/MmsTableHead";
import React, { useEffect } from "react";
import { styles } from "./ScheduleTasksSettings.styles";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { useTranslation } from "react-i18next";

interface ScheduleTasksSettingsProps {
  onClose: VoidFunction;
}

const ScheduleTableHeaders = [
  "Title",
  "Asset type",
  "Set by",
  "Auto-enable at",
  "Action",
];
export const ScheduleTasksSettings: React.FC<ScheduleTasksSettingsProps> = ({
  onClose,
}) => {
  const { t } = useTranslation(["common"]);
  const [, scheduleTasksData, scheduleTasksRefetch] = useGetScheduleTasks();

  useEffect(() => {
    scheduleTasksRefetch();
  }, [scheduleTasksRefetch]);

  return (
    <>
      <header className={styles.header}>Scheduled updates</header>
      <section className={styles.body}>
        {scheduleTasksData && (
          <MmsTable
            tableHeadComponent={
              <MmsTableHead
                className={styles.tableHeader}
                colsNames={ScheduleTableHeaders}
                classNameCell={styles.tableCell}
              />
            }
            tableBodyComponent={
              <>
                {scheduleTasksData.scheduleTasks.map((task) => (
                  <ScheduleTasksItem
                    key={task.id}
                    task={task}
                    classNameCell={styles.tableCell}
                  />
                ))}
              </>
            }
          />
        )}
      </section>
      <footer className={styles.footer}>
        <MmsSecondaryButton id="cancel-scheduled-modal" onClick={onClose}>
          {t("common:cancel")}
        </MmsSecondaryButton>
      </footer>
    </>
  );
};
