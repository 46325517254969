import { useActiveForms, useDidMount } from "@hooks";
import { BulkPriceUpdateOptionsEnum } from "@model/helperTypes/BulkEditing";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { ConnectedMmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { validateField } from "@utils/formik";
import { hundredPercentFormat } from "@utils/inputFomats";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useMemo } from "react";
import NumberFormat from "react-number-format";
import { styles } from "./BulkPriceUpdateForm.styles";
import { validationMap } from "./BulkPriceUpdateForm.validationMap";
import { useTranslation } from "react-i18next";

export const BULK_PRICE_UPDATE_FORM_DEFAULT_VALUES = {
  bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum.increaseByPercent,
  bulkPrice: "",
};

interface FormValues {
  bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum;
  bulkPrice: string;
}

interface BulkPriceUpdateFormContentProps {
  uniqId: string;
  title: string;
  onSubmit: (value: any) => void;
}

export const BulkPriceUpdateFormContent: React.FC<
  BulkPriceUpdateFormContentProps & FormikProps<FormValues>
> = ({ uniqId, title, onSubmit, ...formikProps }) => {
  const { t } = useTranslation(["bulk-update"]);

  const formId = `bulk-price-update-form-${uniqId}`;

  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();

  const handleBulkPriceUpdateOptionChange = useCallback(() => {
    if (!formikProps.getFieldMeta("bulkPrice").touched) return;

    formikProps.submitForm();
  }, [formikProps]);

  const syncFormicWithFormattedFields = useCallback(
    (fieldName, fieldValue) => {
      formikProps.setFieldValue(fieldName, fieldValue);

      if (!(formikProps as any).errors[fieldName]) return;
      if (validateField((validationMap as any)[fieldName])(fieldValue)) return;
      formikProps.setFieldError(fieldName, undefined);
    },
    [formikProps]
  );

  const handleChangeFormattedValue = useCallback(
    (name, values) => {
      const { formattedValue: value } = values;
      syncFormicWithFormattedFields(name, value);
    },
    [syncFormicWithFormattedFields]
  );

  const handleChangeBulkPrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      syncFormicWithFormattedFields(name, value);
    },
    [syncFormicWithFormattedFields]
  );

  const bulkPriceExtraProps = useMemo(() => {
    switch (formikProps.values.bulkPriceUpdateOption) {
      case BulkPriceUpdateOptionsEnum.increaseByPercent:
      case BulkPriceUpdateOptionsEnum.decreaseByPercent:
        return {
          format: hundredPercentFormat,
          decimalScale: 0,
        };
      case BulkPriceUpdateOptionsEnum.increaseByValue:
      case BulkPriceUpdateOptionsEnum.decreaseByValue:
        return {
          decimalScale: 2,
        };
      default:
        throw new Error(
          "BulkEditingPanel -> BulkMoreSettings -> BulkPriceUpdateForm -> bulkPriceExtraProps: Wrong bulkPriceUpdateOptions value"
        );
    }
  }, [formikProps.values.bulkPriceUpdateOption]);

  const bulkPriceUpdateData = [
    {
      title: t("bulk-update:increase-by-percents"),
      value: BulkPriceUpdateOptionsEnum.increaseByPercent,
    },
    {
      title: t("bulk-update:decrease-by-percents"),
      value: BulkPriceUpdateOptionsEnum.decreaseByPercent,
    },
    {
      title: t("bulk-update:add-to-price"),
      value: BulkPriceUpdateOptionsEnum.increaseByValue,
    },
    {
      title: t("bulk-update:substract-from-price"),
      value: BulkPriceUpdateOptionsEnum.decreaseByValue,
    },
  ];

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  return (
    <Form>
      <MmsFormTitle>{title}</MmsFormTitle>
      <ConnectedMmsMuiSelectbox
        data-testid={`bulk-price-update-options-${uniqId}`}
        id={`bulk-price-update-options-${uniqId}`}
        name={"bulkPriceUpdateOption"}
        label={t("bulk-update:choose-operation")}
        className={styles.optionsSelectbox}
        data={bulkPriceUpdateData}
        onChange={handleBulkPriceUpdateOptionChange}
        fullWidth
      />
      <NumberFormat
        data-testid={`bulk-price-${uniqId}`}
        id={`bulk-price-${uniqId}`}
        customInput={MmsMuiInput}
        name="bulkPrice"
        label={t("bulk-update:value")}
        onBlur={formikProps.submitForm}
        onPressEnter={formikProps.submitForm}
        validate={validateField(validationMap.bulkPrice)}
        value={formikProps.values.bulkPrice}
        onValueChange={handleChangeFormattedValue.bind(null, "bulkPrice")}
        onChange={handleChangeBulkPrice}
        allowNegative={false}
        fullWidth
        showErrorMessage
        showUnderline
        {...bulkPriceExtraProps}
      />
    </Form>
  );
};

interface BulkPriceUpdateFormProps extends BulkPriceUpdateFormContentProps {}

// Wrap our form with the withFormik HoC
export const BulkPriceUpdateForm = withFormik<
  BulkPriceUpdateFormProps,
  FormValues
>({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => BULK_PRICE_UPDATE_FORM_DEFAULT_VALUES,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(BulkPriceUpdateFormContent);
