import React from "react";
import { IReleaseNote } from "../../withEssentialData";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Chevron } from "@uiKit/Atoms/SVGIcons";
import { styles } from "./ReleaseItem.styles";

interface ReleaseItemProps {
  release: IReleaseNote;
  expended: boolean;
}

export const ReleaseItem: React.FC<ReleaseItemProps> = ({
  release,
  expended,
}) => {
  return (
    <div className={styles.root}>
      <Accordion
        defaultExpanded={expended}
        square
        TransitionProps={{
          timeout: 250,
          unmountOnExit: true,
        }}
      >
        <AccordionSummary
          expandIcon={<Chevron direction={"bottom"} />}
          className={styles.title}
        >
          Release notes - {release.title}
        </AccordionSummary>
        <AccordionDetails>
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{ __html: release.body }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
