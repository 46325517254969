import PageLayoutWithMobileHack from "@sharedComponents/PageLayout/PageLayout";
import React from "react";
import { Helmet } from "react-helmet-async";
import { ErrorPageContent } from "./Content/Content";
import { styles } from "./ErrorPage.styles";

export const ErrorPage = () => {
  return (
    <PageLayoutWithMobileHack className={styles.container}>
      <>
        <Helmet>
          <title>Unexpected Error</title>
        </Helmet>
        <ErrorPageContent />
      </>
    </PageLayoutWithMobileHack>
  );
};
