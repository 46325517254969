import { useCallback, useEffect, useState } from "react";

type ReturnTuple = [boolean, VoidFunction, VoidFunction];

export const useAddonItemEditingMode = (addonItemId: string): ReturnTuple => {
  const [
    isCurrentAddonItemEditingMode,
    setCurrentAddonItemEditingMode,
  ] = useState(false);

  const enterCurrentAddonItemEditingMode = useCallback(() => {
    if (isCurrentAddonItemEditingMode) return;

    setCurrentAddonItemEditingMode(true);
  }, [isCurrentAddonItemEditingMode]);

  const leaveCurrentAddonItemEditingMode = useCallback(() => {
    if (!isCurrentAddonItemEditingMode) return;

    setCurrentAddonItemEditingMode(false);
  }, [isCurrentAddonItemEditingMode]);

  useEffect(() => {
    if (!isCurrentAddonItemEditingMode) return;

    const intervalId = setInterval(function () {
      if (
        document.querySelector(":focus")?.parentElement?.parentElement?.dataset
          .addonitemid === addonItemId
      )
        return;
      leaveCurrentAddonItemEditingMode();
    }, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    addonItemId,
    isCurrentAddonItemEditingMode,
    leaveCurrentAddonItemEditingMode,
  ]);

  return [
    isCurrentAddonItemEditingMode,
    enterCurrentAddonItemEditingMode,
    leaveCurrentAddonItemEditingMode,
  ];
};
