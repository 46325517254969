import { gql } from "@apollo/client";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import {
  BulkPriceUpdateOptionsEnum,
  BulkRequestActionEnum,
  LevelsBulkEnum,
} from "@model/helperTypes/BulkEditing";

export const BULK_UPDATE_MENU_ITEM_PRICES = gql`
  mutation BulkUpdateMenuItemPricesMutation($input: BulkData!) {
    mmsBulk(input: $input)
  }
`;

export const generateBulkUpdateMenuItemPricesVars = (
  restaurantId: string,
  selectedMenuItemIds: ModelMenuItem["id"][],
  bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum,
  bulkPrice: string
) => ({
  input: {
    restaurantId,
    levelType: LevelsBulkEnum.menu_item,
    ids: selectedMenuItemIds,
    bulkAction: BulkRequestActionEnum.update,
    params: { [bulkPriceUpdateOption]: bulkPrice },
  },
});

export * as BulkUpdateMenuItemPricesTypes from "./__generated__/BulkUpdateMenuItemPricesMutation";
