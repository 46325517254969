import { AddonsOwnerEnum } from "@model/helperTypes/Addon";
import { AddonsSettingsStrategyEnum } from "@model/helperTypes/AddonsSettings";

export const getAddonsSettingsStrategy = (
  addonsOwner: AddonsOwnerEnum,
  isAttachedToMenuItem: boolean
): AddonsSettingsStrategyEnum => {
  if (addonsOwner === AddonsOwnerEnum.category)
    return AddonsSettingsStrategyEnum.category;
  if (isAttachedToMenuItem)
    return AddonsSettingsStrategyEnum.menu_item_attached;
  return AddonsSettingsStrategyEnum.menu_item_detached;
};
