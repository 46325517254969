import { useToggle } from "@hooks";
import { LibraryAssetNodes } from "@model/DAO/Library";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import { MmsTab } from "@uiKit/Molecules/Tabs/MmsTab";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React, { useCallback } from "react";
import { LibraryAssetDesktopLayout } from "../../layouts/LibraryAssetLayout";
import { LibraryAssetControlsSection } from "./LibraryAssetControlsSection/LibraryAssetControlsSection";
import { LibraryAssetEditTitleForm } from "./LibraryAssetEditTitle/LibraryAssetEditTitle";
import { styles } from "./LibraryAssetSettings.styles";
import { LibraryAssetTitleSection } from "./LibraryAssetTitleSection/LibraryAssetTitleSection";
import { useTranslation } from "react-i18next";

export interface LibraryAssetSettingsProps {
  id: LibraryAssetNodes["id"];
  title: LibraryAssetNodes["title"];
  favorite: LibraryAssetNodes["favorite"];
  onSelectAsset: (id: number | null) => void;
  onToggleCheckbox: (id: LibraryAssetNodes["id"]) => void;
  onChangeTitle: (values: any) => void;
  onDelete: VoidFunction;
  onToggleFavorite: VoidFunction;
  isTabSelected: boolean;
  isCheckboxSelected: boolean;
  isEditTitleLoading: boolean;
  modals: React.ReactNode;
}

export const LibraryAssetSettings: React.FC<LibraryAssetSettingsProps> = ({
  id,
  title,
  favorite,
  onSelectAsset,
  onToggleCheckbox,
  onChangeTitle,
  onDelete,
  onToggleFavorite,
  isTabSelected,
  isCheckboxSelected,
  isEditTitleLoading,
  modals,
}) => {
  const { t } = useTranslation(["library"]);

  const [isEditMode, toggleEditMode] = useToggle(false);

  const handleToggleCheckbox = useCallback(() => {
    onToggleCheckbox(id);
  }, [id, onToggleCheckbox]);

  return (
    <div className={styles.tab}>
      <MmsTab
        tabIndex={Number(id)}
        isSelected={isTabSelected}
        onSelect={onSelectAsset}
      >
        <LibraryAssetDesktopLayout
          checkboxCell={
            <MmsTooltip title={t("library:select-this-asset-for-import")}>
              <div>
                <MmsCheckbox
                  data-testid={`library-asset-${id}`}
                  id={`library-asset-${id}`}
                  checked={isCheckboxSelected}
                  onChange={handleToggleCheckbox}
                />
              </div>
            </MmsTooltip>
          }
          titleCell={
            isEditMode ? (
              <LibraryAssetEditTitleForm
                assetId={id}
                title={title}
                onSubmit={onChangeTitle}
                onSubmitEnded={toggleEditMode}
                isFormSubmitting={isEditTitleLoading}
                onPressEsc={onDelete}
              />
            ) : (
              <LibraryAssetTitleSection
                data-testid={`library-asset-title-${id}`}
                title={title}
                onClick={toggleEditMode}
              />
            )
          }
          controlsGroupCell={
            <LibraryAssetControlsSection
              id={id}
              isFavorite={favorite}
              onDelete={onDelete}
              onToggleFavorite={onToggleFavorite}
            />
          }
        />
      </MmsTab>
      {modals}
    </div>
  );
};
