import { themeColors } from "@theme/colors";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Chevron, Star, Trash } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./LibraryAssetControlsSection.styles";
import { useTranslation } from "react-i18next";

interface LibraryAssetControlsSectionProps {
  id: string;
  isFavorite: boolean;
  onDelete: VoidFunction;
  onToggleFavorite: VoidFunction;
}

export const LibraryAssetControlsSection: React.FC<LibraryAssetControlsSectionProps> = ({
  id,
  isFavorite,
  onDelete,
  onToggleFavorite,
}) => {
  const { t } = useTranslation(["assets"]);
  return (
    <JustifyContentBetweenLayout className={styles.controlContainer}>
      <MmsTooltip title={t("assets:save-this-asset-so-it-is-not-deleted")}>
        <MmsSvgButton
          data-testid={`toggle-library-asset-favorite-${id}`}
          id={`toggle-library-asset-favorite-${id}`}
          onClick={onToggleFavorite}
        >
          <Star
            className={styles.baseControl}
            isFilled={isFavorite}
            color={themeColors.yellow}
          />
        </MmsSvgButton>
      </MmsTooltip>
      <MmsTooltip title={t("assets:remove-this-asset-from-your-library")}>
        <MmsSvgButton
          data-testid={`delete-library-asset-${id}`}
          id={`delete-library-asset-${id}`}
          onClick={onDelete}
        >
          <Trash
            id={`delete-asset-${id}-icon`}
            size="small"
            className={styles.baseTrashControl}
          />
        </MmsSvgButton>
      </MmsTooltip>
      <Chevron size={"small"} />
    </JustifyContentBetweenLayout>
  );
};
