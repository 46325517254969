import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  main: css`
    min-height: 56px;
    padding-left: 70px;

    border-top: 1px solid ${themeColors.veryLightPink};
    border-bottom-right-radius: ${themeVars.borderRadius};
    border-bottom-left-radius: ${themeVars.borderRadius};

    background: white;

    color: ${themeColors.lightGreen};
    font-size: 14px;
    font-weight: 700;
    text-align: left;
  `,
  icon: css`
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 22px;

    border: 1px solid ${themeColors.lightGreen};
    border-radius: 50%;

    vertical-align: middle;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: ${themeColors.lightGreen};
    }
    &::before {
      width: 2px;
      margin: 3px auto;
    }
    &::after {
      height: 2px;
      margin: auto 3px;
    }
  `,
};
