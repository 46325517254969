import { cx } from "@emotion/css";
import { MmsCol, MmsContainer, MmsRow } from "@uiKit/Molecules/Grid/MmsGrid";
import React from "react";
import { styles } from "./HeaderLayout.styles";

export interface HeaderLayoutProps {
  wrapperClassName?: string;
  leftCol?: React.ReactNode;
  rightCol?: React.ReactNode;
}

export const HeaderLayout: React.FC<HeaderLayoutProps> = ({
  wrapperClassName,
  leftCol,
  rightCol,
}) => {
  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <MmsContainer className={cx(styles.container)} fluid>
        <MmsRow className="d-flex justify-content-between">
          {/* mobile view */}
          {/* <MmsCol xs="1" lg="auto" className="d-lg-none" /> */}
          <MmsCol xs="auto">{leftCol}</MmsCol>
          <MmsCol
            // mobile view
            // xs="1"
            // lg="auto"
            className="d-flex justify-content-end align-items-center"
            // desktop view
            xs="auto"
          >
            {rightCol}
          </MmsCol>
        </MmsRow>
      </MmsContainer>
    </div>
  );
};
