import React from "react";
import { Form, FormikProps, withFormik } from "formik";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { styles } from "./MainTaxes.styles";
import { ConnectedMmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { TaxesInfo, TaxModel } from "@model/DAO/MenuItemTax";
import { cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { ServiceType } from "__generated__/globalTypes";

export type MainTaxFormValues = {
  mainTax: string | undefined;
};

interface TaxesFormContentProps {
  uniqId: string;
  title: string;
  taxesTitle: string;
  taxData: TaxModel | undefined;
  taxOptions: TaxesInfo[];
  onSubmit: (values: any, serviceType: ServiceType | null) => void;
  onChangeAvailable: (newTax: TaxModel) => void;
  serviceType: ServiceType | null;
}

const MainTaxContent: React.FC<
  TaxesFormContentProps & FormikProps<MainTaxFormValues>
> = ({ uniqId, title, taxOptions, onSubmit, serviceType, taxesTitle }) => {
  const { t } = useTranslation(["taxes"]);

  const handleChangeTaxes = (newTax: string) => onSubmit(newTax, serviceType);
  let taxesDataDropdown = taxOptions.map((a) => ({
    title: `${a.abbreviation} ${a.percentage}%`,
    value: a.id,
  }));
  taxesDataDropdown.unshift({ title: "", value: "" });

  return (
    <>
      <Form className={styles.form}>
        <MmsFormTitle className={styles.baseInput}>{taxesTitle}</MmsFormTitle>
        <ConnectedMmsMuiSelectbox
          data-testid={`taxes-option-${uniqId}`}
          id={`taxes-option-${uniqId}`}
          data={taxesDataDropdown}
          label={t("taxes:taxes")}
          name={title}
          onChange={handleChangeTaxes}
          className={cx(styles.baseInput, styles.selectBox)}
          fullWidth
        />
      </Form>
    </>
  );
};

export const MainTaxForm = withFormik<TaxesFormContentProps, MainTaxFormValues>(
  {
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: ({ taxData }) => ({ mainTax: taxData?.id }),
    enableReinitialize: true,
    handleSubmit: (values: any, { props: { onSubmit, serviceType } }) =>
      onSubmit(values, serviceType),
  }
)(MainTaxContent);
