import { ModelRestoredMenu } from "@model/DAO/MenuState";
import {
  HistoryModalDesktopLayout,
  HistoryModalEmptyDesktopLayout,
} from "@pages/RestaurantMenuPage/layouts/HistoryModalLayout";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { MmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./HistorySettings.styles";
import { useTranslation } from "react-i18next";

export interface HistorySettingsProps {
  history: ModelRestoredMenu[];
  selectedMenu: ModelRestoredMenu | null;
  onChange: (id: ModelRestoredMenu["id"]) => void;
  onDelete: VoidFunction;
  onRestore: VoidFunction;
  onCancel: VoidFunction;
  isRestoreProcessing: boolean;
  modals: React.ReactNode;
}

export const HistorySettings: React.FC<HistorySettingsProps> = ({
  history,
  selectedMenu,
  onChange,
  onDelete,
  onRestore,
  onCancel,
  isRestoreProcessing,
  modals,
}) => {
  const { t } = useTranslation(["common", "history"]);
  if (selectedMenu) {
    return (
      <>
        <HistoryModalDesktopLayout
          selectboxCell={
            <MmsTooltip title={t("history:select-menu-from-the-history-list")}>
              <div>
                <MmsMuiSelectbox
                  id={`history-entities-selectbox`}
                  name="historyEntitiesSelectbox"
                  label={t("history:choose-menu")}
                  data={history.map(({ title, id, publishedAt }) => ({
                    title: `${publishedAt} - ${title}`,
                    value: id,
                  }))}
                  value={selectedMenu.id}
                  onChange={onChange}
                  fullWidth
                />
              </div>
            </MmsTooltip>
          }
          descriptionCell={selectedMenu.description}
          publishedByCell={selectedMenu.publishedBy}
          publishedAtCell={selectedMenu.publishedAt}
          buttonCell={
            <>
              <JustifyContentBetweenLayout className={styles.footerBtnRightRow}>
                <MmsSecondaryButton
                  id={"close-history-settings"}
                  onClick={onCancel}
                >
                  {t("common:cancel")}
                </MmsSecondaryButton>
                <MmsTooltip
                  title={t(
                    "history:restore-this-menu-and-overwrite-the-current-draft"
                  )}
                >
                  <div>
                    <MmsPrimaryButton
                      id={`restore-menu-${selectedMenu.id}`}
                      onClick={onRestore}
                      processing={isRestoreProcessing}
                      disabled={isRestoreProcessing}
                    >
                      Restore
                    </MmsPrimaryButton>
                  </div>
                </MmsTooltip>
              </JustifyContentBetweenLayout>
              <MmsTooltip
                title={t("history:delete-this-menu-from-the-history-list")}
              >
                <div>
                  <MmsSecondaryButton
                    id={`delete-menu-${selectedMenu.id}-from-history`}
                    onClick={onDelete}
                  >
                    {t("common:delete")}
                  </MmsSecondaryButton>
                </div>
              </MmsTooltip>
            </>
          }
        />
        {modals}
      </>
    );
  }
  return (
    <HistoryModalEmptyDesktopLayout
      buttonCell={
        <MmsSecondaryButton id={"close-history-settings"} onClick={onCancel}>
          {t("common:delete")}
        </MmsSecondaryButton>
      }
    />
  );
};
