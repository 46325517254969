import { RefObject, useRef } from "react";

export const useFocus = (): [VoidFunction, RefObject<any>] => {
  const htmlElRef = useRef<any>(null);

  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [setFocus, htmlElRef];
};
