import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { redirectToMsalAuth } from "../../../utils/msal";
import { useAuthLogin } from "../gqlHooks/auth/mutations";
import { getHashValues, getDomainPath } from "../../../utils/url";
import {
  JWT_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../constants/localStorageKeys";

interface LocationState {
  from: {
    pathname: string;
  };
}

export function useAuthProvider() {
  const history = useHistory();
  const [authLogin, , data] = useAuthLogin();
  const { hash, state } = useLocation<LocationState>();
  const redirectUri = getDomainPath() + "/auth";

  React.useEffect(() => {
    if (hash) {
      const { code } = getHashValues(hash);
      authLogin(code, redirectUri);
    } else {
      const stateBeforeAuth =
        state?.from?.pathname ||
        process.env.REACT_APP_MMS2_CURRENT_ADMIN_APP_URL!;
      redirectToMsalAuth(stateBeforeAuth, redirectUri);
    }
  }, [hash, state, authLogin, redirectUri]);

  React.useEffect(() => {
    if (!data?.authLogin) return;
    const { state } = getHashValues(hash);
    const redirectPath = decodeURIComponent(state);

    localStorage.setItem(JWT_TOKEN_KEY, data?.authLogin?.jwtToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, data?.authLogin?.refreshToken);
    history.push(redirectPath);
  }, [data, history, hash]);
}
