import { cx } from "@emotion/css";
import { MmsPopper, MmsPopperProps } from "@uiKit/Molecules/Popper/MmsPopper";
import React from "react";
import { styles } from "./HistoryModal.styles";

interface HistoryModalProps {
  id: MmsPopperProps["id"];
  anchorEl: MmsPopperProps["anchorEl"];
  onClose: MmsPopperProps["onClose"];
  children: MmsPopperProps["children"];
}

export const HistoryModal: React.FC<HistoryModalProps> = ({
  children,
  ...restProps
}) => (
  <MmsPopper className={cx("historyModal", styles.modal)} {...restProps}>
    {children}
  </MmsPopper>
);
