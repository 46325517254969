import { cx } from "@emotion/css";
import { useDidMount, useOnScrollPagination } from "@hooks";
import { ModelGalleryImage, ModelMenuGallery } from "@model/DAO/MenuGallery";
import { useSelectedGalleryImages } from "@pages/RestaurantMenuPage/hooks/useSelectedGalleryImages";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Trash } from "@uiKit/Atoms/SVGIcons";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { breakWord } from "@utils/breakWord";
import { isHasMore } from "@utils/pagination";
import React, { useCallback } from "react";
import { styles } from "./GalleryImagesPaginatedList.styles";
export interface GalleryImagesPaginatedListProps {
  onDeleteImage: (imageId: string) => void;
  imageGallery: ModelMenuGallery;
  selectedImage: ModelGalleryImage | null;
  setSelectedImage: (image: ModelGalleryImage) => void;
  fetchMoreGalleryImages: (page: number) => void;
  isGalleryLoading: boolean;
}

export const GalleryImagesPaginatedList: React.FC<GalleryImagesPaginatedListProps> = ({
  imageGallery,
  selectedImage,
  setSelectedImage,
  fetchMoreGalleryImages,
  onDeleteImage,
  isGalleryLoading,
}) => {
  const [selectedImagesIds, { selectImage }] = useSelectedGalleryImages();
  const [pageNumber, setPageNumber] = React.useState(1);
  const {
    pagination: { offset, total, perPage },
    nodes,
  } = imageGallery.gallery;

  let hasMore = React.useMemo(() => isHasMore(offset, perPage, total), [
    offset,
    total,
    perPage,
  ]);

  const fetchMoreItems = React.useCallback(() => {
    const newPageNumber = pageNumber + 1;
    fetchMoreGalleryImages(newPageNumber);
    setPageNumber(newPageNumber);
  }, [pageNumber, fetchMoreGalleryImages]);

  const [rootContainer, lastElementRef] = useOnScrollPagination(
    fetchMoreItems,
    hasMore,
    isGalleryLoading
  );

  const handleSelectImage = useCallback(
    (image: ModelGalleryImage, e?: React.MouseEvent) => {
      selectImage(image.id, e);
      setSelectedImage(image);
    },
    [selectImage, setSelectedImage]
  );

  useDidMount(() => {
    if (!selectedImage) return;
    selectImage(selectedImage.id);
  });

  return (
    <>
      <div ref={rootContainer} className={styles.imagesContainer}>
        {nodes.map((image: ModelGalleryImage, index: number) => {
          const isLastItem = nodes.length === index + 1;
          const isSelected = selectedImagesIds.includes(image.id);

          return (
            image?.imageUrl && (
              <div
                key={image.id}
                className={styles.imageContainer}
                onClick={handleSelectImage.bind(null, image)}
              >
                {isSelected && (
                  <MmsSvgButton
                    id={`gallery-image-${image.id}`}
                    onClick={onDeleteImage.bind(null, image.id)}
                    className={styles.deleteIconContainer}
                  >
                    <Trash id={`gallery-image-${image.id}-icon`} size="small" />
                  </MmsSvgButton>
                )}

                <img
                  key={image.id}
                  ref={isLastItem ? lastElementRef : null}
                  src={image.imageUrl}
                  className={cx(
                    styles.image,
                    isSelected && styles.selectedImage
                  )}
                  alt={image.name}
                />

                <MmsTooltip title={breakWord(image.name)}>
                  <div className={styles.imageName}>{image.name}</div>
                </MmsTooltip>
              </div>
            )
          );
        })}
      </div>
    </>
  );
};
