import { gql } from "@apollo/client";
import { PositionDataType } from "@model/helperTypes/dragAndDrop";
import { LevelsRerank } from "__generated__/globalTypes";

// export enum LevelsRerank {
//   menu = "menu",
//   category = "category",
// }

export const RERANK_ENTITIES = gql`
  mutation RerankEntitiesMutation($input: RerankData!) {
    mmsRerank(input: $input)
  }
`;

export const generateRerankEntitiesVars = (
  restaurantId: string,
  areaType: LevelsRerank,
  areaId: string,
  positions: PositionDataType[]
) => ({
  input: {
    restaurantId,
    areaType,
    areaId,
    positions,
  },
});

export * as RerankEntitiesMutationType from "./__generated__/RerankEntitiesMutation";
