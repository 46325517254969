import { gql } from "@apollo/client";
import { ADDITIVE_ESSENTIAL_DATA } from "../../fragments/Additive";

export const GET_COUNTRY_ADDITIVES = gql`
  query GetCountryAdditivesQuery($country: Countries) {
    additives(country: $country) {
      ...AdditiveEssentialData
    }
  }
  ${ADDITIVE_ESSENTIAL_DATA}
`;

export * as GetCountryAdditivesTypes from "./__generated__/GetCountryAdditivesQuery";
