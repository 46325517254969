import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { AllAddonsSettingsProps } from "@model/helperTypes/AddonsSettings";
import React, { useMemo } from "react";
import { AddonItemsSettingsBaseProps } from "../AddonItemsSettings/AddonItemsSettings";
import { NewAddonListProps } from "./NewAddonList/NewAddonList";
import { NewDefaultAddonsSettings } from "./NewDefaultAddonsSettings/NewDefaultAddonsSettings";
import { NewEmptyAddonsSettings } from "./NewEmptyAddonsSettings/NewEmptyAddonsSettings";

export interface NewAddonsSettingsProps {
  localAddons: ModelLocalAddon[];
  allAddonsSettingsProps: AllAddonsSettingsProps;
  baseAddonListProps: NewAddonListProps;
  baseAddonItemsSettingsProps: Omit<
    AddonItemsSettingsBaseProps,
    "addon" | "addonType" | "addonStrategy" | "isPriceLevelDependent"
  >;
}

export const NewAddonsSettings: React.FC<NewAddonsSettingsProps> = ({
  localAddons,
  allAddonsSettingsProps: {
    addonsSettingsStrategy,
    restaurantId,
    parentTitle,
    isUpdateProcessing,
    isAddonsChanged,
    selectedAddon,
    newAddon,
    isReadyToSaveToLibrary,
    isPriceLevelAddonAvailable,
    addNewAddonClick,
    addNewAddonItemClick,
    onCancelClick,
    onUpdateClick,
    updateAddonsAndProceed,
    toggleAttachToMenuItem,
    setIsReadyToSaveToLibrary,
    changeAddonTitle,
    onChangeAddonType,
    changeAddonMenuFlags,
    togglePriceLevelDependent,
    onMinMaxSet,
  },
  baseAddonListProps,
  baseAddonItemsSettingsProps,
}) => {
  const isAddonListEmpty = useMemo(
    () => localAddons.length === 0 && !newAddon,
    [localAddons.length, newAddon]
  );

  return isAddonListEmpty ? (
    <NewEmptyAddonsSettings
      {...{
        addonsSettingsStrategy,
        parentTitle,
        isUpdateProcessing,
        isAddonsChanged,
        addNewAddonClick,
        onCancelClick,
        onUpdateClick,
        updateAddonsAndProceed,
        toggleAttachToMenuItem,
      }}
    />
  ) : (
    <NewDefaultAddonsSettings
      {...{
        addonsSettingsStrategy,
        restaurantId,
        parentTitle,
        isUpdateProcessing,
        isAddonsChanged,
        isReadyToSaveToLibrary,
        isPriceLevelAddonAvailable,
        selectedAddon,
        newAddon,
        baseAddonListProps,
        baseAddonItemsSettingsProps,
        addNewAddonClick,
        addNewAddonItemClick,
        onCancelClick,
        onUpdateClick,
        toggleAttachToMenuItem,
        updateAddonsAndProceed,
        setIsReadyToSaveToLibrary,
        changeAddonTitle,
        onChangeAddonType,
        changeAddonMenuFlags,
        togglePriceLevelDependent,
        onMinMaxSet,
      }}
    />
  );
};
