import React from "react";
import { styles } from "./DefaultContentLayout.styles";

export interface AddonsSettingsDefaultContentLayoutProps {
  isHeaderShadowVisible?: boolean;
  leftColContent: React.ReactNode;
  rightColHeaderTitle: React.ReactNode;
  rightColHeaderControls?: React.ReactNode;
  rightColContent: React.ReactNode;
  rightColFooter: React.ReactNode;
}

export const AddonsSettingsDefaultContentLayout: React.FC<AddonsSettingsDefaultContentLayoutProps> = React.forwardRef<
  HTMLDivElement,
  AddonsSettingsDefaultContentLayoutProps
>(
  (
    {
      isHeaderShadowVisible = true,
      leftColContent,
      rightColHeaderTitle,
      rightColHeaderControls = null,
      rightColContent,
      rightColFooter,
      ...rest
    },
    ref
  ) => {
    return (
      <div {...rest} ref={ref} className={styles.container}>
        <div className={styles.leftCol}>{leftColContent}</div>
        <div className={styles.rightCol}>
          <header className={styles.rightColHeader(isHeaderShadowVisible)}>
            <div className={styles.rightColHeaderTitle}>
              {rightColHeaderTitle}
            </div>
            <div className={styles.rightColHeaderControls}>
              {rightColHeaderControls}
            </div>
          </header>
          <section className={styles.rightColContent}>
            {rightColContent}
          </section>
          <footer className={styles.rightColFooter}>{rightColFooter}</footer>
        </div>
      </div>
    );
  }
);
