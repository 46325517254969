import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import React from "react";
import { MmsBaseNotificationModal } from "../MmsBaseNotificationModal";
import { styles } from "./styles";

export interface MmsConfirmationModalProps {
  title?: string;
  message: React.ReactNode;
  confirmBtnText: string;
  declineBtnText: string;
  isDataProcessing?: boolean;
  isConfirmBtnDisabled?: boolean;
  onConfirmBtnClick: VoidFunction;
  onDeclineBtnClick: VoidFunction;
}

export const MmsConfirmationModal: React.FC<MmsConfirmationModalProps> = ({
  title = "Enable asset back on",
  message,
  confirmBtnText,
  declineBtnText,
  isDataProcessing,
  isConfirmBtnDisabled,
  onConfirmBtnClick,
  onDeclineBtnClick,
}) => (
  <MmsBaseNotificationModal
    contentClassName="confirmationModal"
    titleSection={title}
    messageSection={message}
    buttonRowSection={
      <div className={styles.buttonsRow}>
        <MmsSecondaryButton
          id="decline-btn"
          onClick={onDeclineBtnClick}
          fullWidth
        >
          {declineBtnText}
        </MmsSecondaryButton>
        <MmsPrimaryButton
          id="confirm-btn"
          onClick={onConfirmBtnClick}
          fullWidth
          processing={isDataProcessing}
          disabled={isConfirmBtnDisabled}
        >
          {confirmBtnText}
        </MmsPrimaryButton>
      </div>
    }
    onClose={onDeclineBtnClick}
    onConfirm={onConfirmBtnClick}
  />
);
