import { useCallback, useRef } from "react";

interface MutableRefObject<T> {
  current: T;
}

export const useOnScrollPagination = (
  onFetchMore: VoidFunction,
  hasMore: boolean,
  isLoading: boolean
): [MutableRefObject<HTMLInputElement | null>, (node: any) => void] => {
  const observer = useRef<IntersectionObserver | null>(null);

  const rootRef = useRef<HTMLInputElement | null>(null);

  const lastRef = useCallback(
    (node) => {
      if (observer?.current) {
        ((observer!.current as unknown) as IntersectionObserver).disconnect();
      }

      observer.current = new IntersectionObserver(
        (entries) => {
          if (!entries[0].isIntersecting) return;
          if (!hasMore) return;
          if (isLoading) return;

          onFetchMore();
        },
        { root: rootRef.current, threshold: 0.8 }
      );

      if (node) observer.current.observe(node);
    },
    [hasMore, onFetchMore, isLoading]
  );

  return [rootRef, lastRef];
};
