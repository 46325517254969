import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface MmsDummyTabProps {
  children: React.ReactNode;
  className?: string;
  dummyClassName?: string;
}

export const MmsDummyTab: React.FC<MmsDummyTabProps> = ({
  children,
  className,
  dummyClassName,
}) => {
  return (
    <div className={className}>
      <div
        className={cx(
          styles.container(false, false, false),
          styles.dummy,
          dummyClassName
        )}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};
