import { useReactiveVar } from "@apollo/client";
import { useCommonTopLevelEffects, useOpenClose } from "@hooks";
import { useRefreshTokenByInterval } from "@pages/Auth/hooks/useRefreshTokenByInterval";
import MmsLogoSpinnerWithMobileHack from "@uiKit/Atoms/Spinner/MmsLogoSpinner";
import { menuIdVar, restaurantCachedDataVar } from "@utils/apolloReactiveVars";
import React, { useEffect } from "react";
import { useGetDraftMenu } from "./gqlHooks/menu/queries";
import { useUpdatedSelectedEntities } from "./hooks/useUpdatedSelectedEntities";

export const withEssentialData = <P,>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P) => {
  useCommonTopLevelEffects();
  useRefreshTokenByInterval();
  useUpdatedSelectedEntities();

  const [isMenuDataLoading, menuData] = useGetDraftMenu();
  const [isSpinnerOpen, openSpinner, closeSpinner] = useOpenClose(true);
  const menuId = useReactiveVar(menuIdVar);

  useEffect(() => {
    if (!menuData) return;
    menuIdVar(menuData.restaurant.menu.id);
  }, [menuData]);

  useEffect(() => {
    if (!menuData) return;
    restaurantCachedDataVar(menuData.restaurant);
  }, [menuData]);

  useEffect(() => {
    if (!menuId) return;
    openSpinner();

    const timeoutId = setTimeout(closeSpinner, 1500);
    return () => clearTimeout(timeoutId);
  }, [menuId, closeSpinner, openSpinner]);

  return isSpinnerOpen || isMenuDataLoading || !menuData ? (
    <MmsLogoSpinnerWithMobileHack />
  ) : (
    <Component {...props} />
  );
};
