export enum LogFilterTypesEnum {
  actionEq = "actionEq",
  usernameCont = "usernameCont",
  auditableTypeEq = "auditableTypeEq",
  auditableIdEq = "auditableIdEq",
}

export enum LogActionsEnum {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DESTROY = "DESTROY",
}

export enum LogAuditableEnum {
  Menu = "Menu",
  MenuCategory = "MenuCategory",
  MenuItem = "MenuItem",
  MenuAddon = "MenuAddon",
  MenuAddonItem = "MenuAddonItem",
}

export interface LogFilters {
  actionEq?: LogActionsEnum;
  usernameCont?: string;
  auditableTypeEq?: LogAuditableEnum;
  auditableIdEq?: string;
}
