import { gql } from "@apollo/client";
import { CATEGORY_ESSENTIAL_DATA } from "../../fragments/Category";

export const GET_CATEGORY = gql`
  query GetCategoryQuery($categoryId: ID!) {
    category(id: $categoryId) {
      ...CategoryEssentialData
    }
  }
  ${CATEGORY_ESSENTIAL_DATA}
`;

export * as GetCategoryTypes from "./__generated__/GetCategoryQuery";
