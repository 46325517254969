import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  imagesContainer: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 10px;

    position: relative;

    overflow-y: auto;

    ${themeUtils.scrollBar}
  `,
  imageContainer: css`
    position: relative;
    max-width: 150px;
    min-height: 150px;
    margin-bottom: 50px;

    cursor: pointer;

    &,
    & * {
      user-select: none;
    }
  `,
  image: css`
    display: block;
    width: 150px;
    height: 150px;
    margin-bottom: 5px;

    border: solid ${themeColors.cream} 10px;
    border-radius: ${themeVars.borderRadius};

    font-size: 12px;
    word-wrap: break-word;
    overflow: hidden;
    object-fit: cover;
  `,
  selectedImage: css`
    border: solid ${themeColors.lightGreen} 10px;
  `,
  imageName: css`
    color: ${themeColors.mediumGray};
    font-size: 12px;
    font-weight: 300;

    /* taken from https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css */
    /* hack to set maximum 2 number of lines - START */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    max-height: 32px; /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    /* hack to set maximum 2 number of lines - END */
  `,
  deleteIconContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 20px;
    height: 22px;
    width: 22px;

    border-radius: 50%;

    background-color: ${themeColors.white};

    z-index: 1;
  `,
};
