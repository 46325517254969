import { css } from "@emotion/css";

export const styles = (color: string) => css`
  color: ${color};
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;

  cursor: pointer;
  user-select: none;
`;
