import { gql } from "@apollo/client";
import { IMAGE_ESSENTIAL_DATA } from "../../fragments/Image";

export const UPLOAD_IMAGE_TO_GALLERY = gql`
  mutation UploadImageToGalleyMutation($input: ImageAdd!, $file: Upload!) {
    galleryAdd(input: $input, file: $file) {
      ...ImageEssentialData
    }
  }
  ${IMAGE_ESSENTIAL_DATA}
`;

export const generateUploadImageToGalleryVars = (
  restaurantId: string,
  name: string,
  file: File
) => ({
  file,
  input: {
    restaurantId,
    name,
  },
});

export * as UploadImageToGalleyTypes from "./__generated__/UploadImageToGalleyMutation";
