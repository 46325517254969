import { ModelMenuItem } from "@model/DAO/MenuItem";
import { ServiceType } from "__generated__/globalTypes";
import {
  MenuItemPricesData,
  ServiceTypePricesData,
} from "@model/helperTypes/Prices";
import { ModelMenuAddonItem } from "@model/DAO/MenuAddonItem";

interface UnknownServiceTypePricesData {
  price: string | null | undefined;
  addonPrices: (string | null | undefined)[] | undefined;
}

const ApiServiceTypes = Object.values(ServiceType).map((v) => getTypeName(v));

export function getTypeName(type: ServiceType) {
  return {
    [ServiceType.Delivery]: "Delivery",
    [ServiceType.Pickup]: "Takeaway",
    [ServiceType.DineIn]: "DineIn",
  }[type];
}

export function getPriceProperties(
  menuItem: ModelMenuItem
): MenuItemPricesData {
  const {
    disableBasePrices,
    enableDeliveryPrices,
    enableTakeawayPrices,
    enableDineInPrices,
    price,
    priceTakeaway,
    priceDelivery,
    priceDineIn,
    priceLevel1,
    priceLevel2,
    priceLevel3,
    priceLevel4,
    priceLevel5,
    priceLevel1Takeaway,
    priceLevel2Takeaway,
    priceLevel3Takeaway,
    priceLevel4Takeaway,
    priceLevel5Takeaway,
    priceLevel1Delivery,
    priceLevel2Delivery,
    priceLevel3Delivery,
    priceLevel4Delivery,
    priceLevel5Delivery,
    priceLevel1DineIn,
    priceLevel2DineIn,
    priceLevel3DineIn,
    priceLevel4DineIn,
    priceLevel5DineIn,
  } = menuItem;

  return {
    disableBasePrices,
    enableDeliveryPrices,
    enableTakeawayPrices,
    enableDineInPrices,
    price,
    priceDelivery,
    priceTakeaway,
    priceDineIn,
    priceLevel1,
    priceLevel2,
    priceLevel3,
    priceLevel4,
    priceLevel5,
    priceLevel1Takeaway,
    priceLevel2Takeaway,
    priceLevel3Takeaway,
    priceLevel4Takeaway,
    priceLevel5Takeaway,
    priceLevel1Delivery,
    priceLevel2Delivery,
    priceLevel3Delivery,
    priceLevel4Delivery,
    priceLevel5Delivery,
    priceLevel1DineIn,
    priceLevel2DineIn,
    priceLevel3DineIn,
    priceLevel4DineIn,
    priceLevel5DineIn,
  };
}

export function removeFalseFlags(
  data: MenuItemPricesData,
  originalData: MenuItemPricesData
): MenuItemPricesData {
  return {
    ...data,
    ...(originalData.disableBasePrices === null &&
      !data.disableBasePrices && { disableBasePrices: undefined }),
    ...(originalData.enableDeliveryPrices === null &&
      !data.enableDeliveryPrices && { enableDeliveryPrices: undefined }),
    ...(originalData.enableTakeawayPrices === null &&
      !data.enableTakeawayPrices && { enableTakeawayPrices: undefined }),
    ...(originalData.enableDineInPrices === null &&
      !data.enableDineInPrices && { enableDineInPrices: undefined }),
  };
}

export function validate(data: MenuItemPricesData): MenuItemPricesData {
  if (
    data.disableBasePrices &&
    !data.enableTakeawayPrices &&
    !data.enableDeliveryPrices &&
    !data.enableDineInPrices
  ) {
    return {};
  }

  return data;
}

export function pickRelevantProperties(
  data: MenuItemPricesData,
  hasPriceLevels: boolean
): MenuItemPricesData {
  const validForBase = [
    "disableBasePrices",
    ...(!hasPriceLevels
      ? ["price"]
      : [...Array(5)].map((_, i) => `priceLevel${i + 1}`)),
  ];
  const validForServiceTypeSpecific = [
    "disableBasePrices",
    ...ApiServiceTypes.map((x) => `enable${x}Prices`),
    ...(!hasPriceLevels
      ? ApiServiceTypes.map((x) => `price${x}`)
      : ApiServiceTypes.map((x) =>
          [...Array(5)].map((_, i) => `priceLevel${i + 1}${x}`)
        ).flat()),
  ];

  const isValidKey = (key: string): boolean =>
    data.disableBasePrices
      ? validForServiceTypeSpecific.includes(key)
      : validForBase.includes(key);

  return Object.fromEntries(
    Object.entries(data).filter(
      ([key, value]) => value !== null && value !== undefined && isValidKey(key)
    )
  );
}

export function getInitialPrice(
  type: ServiceType,
  item: ModelMenuItem | MenuItemPricesData,
  levelIndex?: number
) {
  const levelPropertyName = levelIndex ? `Level${levelIndex}` : "";
  const serviceTypePropertyName = {
    [ServiceType.Delivery]: "Delivery",
    [ServiceType.Pickup]: "Takeaway",
    [ServiceType.DineIn]: "DineIn",
  }[type];
  const propertyName = `price${levelPropertyName}${serviceTypePropertyName}` as keyof (
    | ModelMenuItem
    | MenuItemPricesData
  );

  return item[propertyName] as string | null | undefined;
}

export function getServiceTypePricesData(
  type: ServiceType,
  data: UnknownServiceTypePricesData
): ServiceTypePricesData {
  const typeName = getTypeName(type);
  return {
    [`price${typeName}`]: data.price || "0.00",
    [`priceLevel1${typeName}`]: data.addonPrices?.[0] || "0.00",
    [`priceLevel2${typeName}`]: data.addonPrices?.[1] || "0.00",
    [`priceLevel3${typeName}`]: data.addonPrices?.[2] || "0.00",
    [`priceLevel4${typeName}`]: data.addonPrices?.[3] || "0.00",
    [`priceLevel5${typeName}`]: data.addonPrices?.[4] || "0.00",
  };
}
