import { css } from "@emotion/css";

export const styles = {
  menuName: css`
    max-width: 28ch;

    text-overflow: ellipsis;
    white-space: nowrap;

    overflow: hidden;
  `,
  // mobile view
  // breadcrumbTitle: css`
  //   @media (max-width: ${SCREEN_SIZES.LG - 1}px) {
  //     font-size: 16px;
  //     font-weight: 600;
  //   }
  // `,
};
