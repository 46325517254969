import { MenuFilters, YesNoFilterEnum } from "@model/helperTypes/MenuFilters";

export const formatMenuFiltersVars = (filters: MenuFilters) =>
  Object.keys(filters).reduce((accum, currentKey) => {
    const key = currentKey as keyof typeof filters;

    if (filters[key] === YesNoFilterEnum.Yes) return { ...accum, [key]: true };
    if (filters[key] === YesNoFilterEnum.No) return { ...accum, [key]: false };

    return { ...accum, [key]: filters[key] };
  }, {});
