import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: ${themeVars.borderRadius};

    background-color: ${themeColors.cream};

    cursor: default;
  `,
  img: css`
    width: 36px;
    height: 36px;

    font-size: 8px;
    word-wrap: break-word;
    overflow: hidden;
    object-fit: cover;
  `,
  noImage: css`
    width: 20px;
    height: 20px;
  `,
};
