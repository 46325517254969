import { ModelWorkingUser } from "@model/DAO/WorkingUsers";
import { User } from "@uiKit/Atoms/SVGIcons";
import { IconedItemLayout } from "@uiKit/Layouts/IconedItemLayout";
import { IconedList } from "@uiKit/Layouts/IconedList";
import { MmsBaseMobileModal } from "@uiKit/Molecules/Modals/MmsBaseMobileModal";
import React from "react";
import { useTranslation } from "react-i18next";

interface WorkingUsersMobileModalProps {
  workingUsers?: ModelWorkingUser[];
  isWorkingUserListModalOpen: boolean;
  closeWorkingUserListModal: VoidFunction;
}

export const WorkingUsersMobileModal: React.FC<WorkingUsersMobileModalProps> = ({
  workingUsers,
  isWorkingUserListModalOpen,
  closeWorkingUserListModal,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <MmsBaseMobileModal
      uniqId="working-user-list-mobile-modal"
      contentClassName="workingUserListMobileModal"
      isOpen={isWorkingUserListModalOpen}
      closeOnEscPress
      closeOnOutsideClick
      onClose={closeWorkingUserListModal}
    >
      <IconedList>
        {workingUsers?.map((user) => (
          <IconedItemLayout
            key={user.id}
            itemIcon={
              <User id={`working-user-${user.name}-footer-icon`} size="small" />
            }
            itemTitle={
              <span>{`${user.name}${
                user.current ? ` (${t("common:you")})` : ""
              }`}</span>
            }
          />
        ))}
      </IconedList>
    </MmsBaseMobileModal>
  );
};
