import { useReactiveVar } from "@apollo/client";
import { DEFAULT_SELECTED_ENTITIES_VALUE } from "@constants";
import { ModelMainCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { SelectedEntitiesReturnDataType } from "@model/helperTypes/SelectedEntities";
import {
  allCategoriesMenuItemsVar,
  menuCategoriesVar,
  selectedEntitiesVar,
} from "@utils/apolloReactiveVars";
import { uniq } from "ramda";
import { useCallback, useMemo } from "react";
import { extractCategoriesItemsIdsFromCategoryList } from "../utils/Category";
import {
  getSelectedCategoryById,
  getSelectedMenuItemsByCategoryId,
  transformToSelectedCategoriesShape,
  transformToSelectedMenuItemsShape,
} from "../utils/selectedEntities";

export const useBulkSelectedEntities = (): SelectedEntitiesReturnDataType => {
  const categories = useReactiveVar(menuCategoriesVar);
  const categoriesItemsIds = extractCategoriesItemsIdsFromCategoryList(
    categories.filter((c) => c.isFreeItem !== true)
  );
  const allCategoriesMenuItems = useReactiveVar(allCategoriesMenuItemsVar);

  const allCategories = useMemo(
    () => transformToSelectedCategoriesShape(categoriesItemsIds),
    [categoriesItemsIds]
  );

  const allMenuItems = useMemo(
    () => transformToSelectedMenuItemsShape(allCategoriesMenuItems),
    [allCategoriesMenuItems]
  );

  const onSelectCategory = useCallback(
    (categoryId: ModelMainCategory["id"]) => {
      const { selectedCategories, selectedMenuItems } = selectedEntitiesVar();

      selectedEntitiesVar({
        selectedCategories: uniq([
          ...selectedCategories,
          getSelectedCategoryById(categoriesItemsIds, categoryId),
        ]),
        selectedMenuItems: uniq([
          ...selectedMenuItems,
          ...getSelectedMenuItemsByCategoryId(
            allCategoriesMenuItems,
            categoryId
          ),
        ]),
      });
    },
    [allCategoriesMenuItems, categoriesItemsIds]
  );

  const onSelectAllCategories = useCallback(() => {
    selectedEntitiesVar({
      selectedCategories: allCategories,
      selectedMenuItems: allMenuItems,
    });
  }, [allCategories, allMenuItems]);

  const onSelectMenuItem = useCallback(
    (menuItemId: ModelMenuItem["id"]) => {
      const selectedEntities = selectedEntitiesVar();

      selectedEntitiesVar({
        ...selectedEntities,
        selectedMenuItems: uniq([
          ...selectedEntities.selectedMenuItems,
          allMenuItems.find(({ id }) => id === menuItemId)!,
        ]),
      });
    },
    [allMenuItems]
  );

  const onDeselectCategory = useCallback(
    (categoryId: ModelMainCategory["id"]) => {
      const { selectedCategories, selectedMenuItems } = selectedEntitiesVar();

      selectedEntitiesVar({
        selectedCategories: [
          ...selectedCategories.filter(({ id }) => id !== categoryId),
        ],
        selectedMenuItems: [
          ...selectedMenuItems.filter((item) => item.categoryId !== categoryId),
        ],
      });
    },
    []
  );

  const onDeselectAllCategories = useCallback(() => {
    selectedEntitiesVar(DEFAULT_SELECTED_ENTITIES_VALUE);
  }, []);

  const onDeselectMenuItem = useCallback((menuItemId: ModelMenuItem["id"]) => {
    const { selectedCategories, selectedMenuItems } = selectedEntitiesVar();

    selectedEntitiesVar({
      selectedCategories,
      selectedMenuItems: [
        ...selectedMenuItems.filter(({ id }) => id !== menuItemId),
      ],
    });
  }, []);

  return {
    selectedEntities: useReactiveVar(selectedEntitiesVar),
    callbacks: {
      onSelectCategory,
      onSelectAllCategories,
      onSelectMenuItem,
      onDeselectCategory,
      onDeselectAllCategories,
      onDeselectMenuItem,
    },
  };
};
