import { injectGlobal } from "@emotion/css";

injectGlobal`
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
`;
