import { css } from "@emotion/css";

export const styles = {
  default: css`
    padding: 0;

    border: none;

    background-color: transparent;

    cursor: pointer;
    user-select: none;

    &,
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  `,
  fullWidth: css`
    display: block;
    width: 100%;
  `,
};
