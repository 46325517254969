import { cx } from "@emotion/css";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { useField } from "formik";
import React, { useCallback } from "react";
import "./globalStyles";
import { styles } from "./styles";
import { SelectboxDataType } from "./types";

export interface MmsMuiSelectboxProps {
  id: string;
  name: string;
  label: string;
  disabled?: boolean;
  data: SelectboxDataType[];
  value: string;
  className?: string;
  onChange: (newValue: string) => void;
  fullWidth?: boolean;
}

export const MmsMuiSelectbox: React.FC<MmsMuiSelectboxProps> = ({
  id,
  name,
  label,
  disabled,
  data,
  value,
  className,
  onChange,
  fullWidth,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div
      className={cx(
        "MmsMuiSelectbox",
        fullWidth && "MmsMuiSelectbox-fullWidth",
        styles.container(false),
        className
      )}
    >
      <FormControl variant="filled" disabled={!!disabled}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          native
          value={value}
          onChange={handleChange}
          inputProps={{
            name,
            id,
          }}
        >
          {data.map(({ title, value }, index) => (
            <option key={index} value={value}>
              {title}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export interface ConnectedMmsMuiSelectboxProps
  extends Omit<MmsMuiSelectboxProps, "onChange" | "value"> {
  validate?: (value: any) => undefined | string | Promise<any>;
  onChange?: (newValue: string) => void;
}

export const ConnectedMmsMuiSelectbox: React.FC<ConnectedMmsMuiSelectboxProps> = ({
  name,
  validate,
  onChange,
  ...restProps
}) => {
  const [field, , helpers] = useField({ name, validate });

  const handleChange = useCallback(
    (newValue: string) => {
      helpers.setValue(newValue);
      onChange?.(newValue);
    },
    [helpers, onChange]
  );

  return <MmsMuiSelectbox {...field} {...restProps} onChange={handleChange} />;
};
