import { gql } from "@apollo/client";
import { ModelAllergen } from "@model/DAO/Allergen";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { Levels } from "__generated__/globalTypes";
import { MENU_ITEM_ESSENTIAL_DATA } from "../../fragments/MenuItem";

export const UPDATE_MENU_ITEM_ALLERGENS = gql`
  mutation UpdateMenuItemAllergensMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ...MenuItemEssentialData
    }
  }
  ${MENU_ITEM_ESSENTIAL_DATA}
`;

export const generateUpdateMenuItemAllergensVars = (
  restaurantId: string,
  menuItemId: ModelMenuItem["id"],
  allergenIds: ModelAllergen["id"][]
) => {
  return {
    input: {
      restaurantId,
      entityType: Levels.MENU_ITEM,
      entityId: menuItemId,
      menuItem: {
        allergenIds,
      },
    },
  };
};

export * as UpdateMenuItemAllergensTypes from "./__generated__/UpdateMenuItemAllergensMutation";
