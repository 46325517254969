import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  menuStatus: css`
    display: flex;
    align-items: center;
    gap: 5px;
  `,
  menuStatusTitle: (isPublished: boolean) => css`
    color: ${isPublished ? themeColors.lightGreen : themeColors.yellow};
    font-size: 10px;
    font-weight: bold;
  `,
  progressBar: css`
    bottom: -10px;
  `,
};
