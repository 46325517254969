import React, { useEffect, useState } from "react";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { ServiceTypePricesSection } from "./ServiceTypePricesSection";
import { ServiceType } from "__generated__/globalTypes";
import { BasePricesSection } from "./BasePricesSection";
import { styles } from "./PricesForm.styles";
import {
  BasePricesData,
  MenuItemPricesData,
  ServiceTypePricesData,
} from "@model/helperTypes/Prices";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { pickRelevantProperties, removeFalseFlags, validate } from "./utils";
import { useTranslation } from "react-i18next";
import { MmsSwitch } from "@uiKit/Atoms/Switch/MmsSwitch";

export interface PricesFormProps {
  pricesData: MenuItemPricesData;
  savedPricesData: MenuItemPricesData;
  preserveLocalPrices: (data: MenuItemPricesData) => void;
  menuItemPriceLevelAddon: ModelMenuAddon | undefined;
}

enum PricingModel {
  Base = "base",
  ServiceTypeSpecific = "serviceTypeSpecific",
}

export const PricesForm: React.FC<PricesFormProps> = ({
  pricesData,
  savedPricesData,
  preserveLocalPrices,
  menuItemPriceLevelAddon,
}) => {
  const { t } = useTranslation(["menu"]);
  const [data, setData] = useState<MenuItemPricesData>(pricesData);

  const [pricingModel, setPricingModel] = useState(
    pricesData.disableBasePrices
      ? PricingModel.ServiceTypeSpecific
      : PricingModel.Base
  );

  const [deliveryTakeawayEnabled, setDeliveryTakeawayEnabled] = useState(
    pricesData.enableDeliveryPrices && pricesData.enableTakeawayPrices
  );
  const [dineInEnabled, setDineInEnabled] = useState(
    pricesData.enableDineInPrices
  );

  const setPricingModelData = (model: PricingModel) => {
    setPricingModel(model);
    setData((prevData) => ({
      ...prevData,
      disableBasePrices: model === PricingModel.ServiceTypeSpecific,
    }));
  };

  const setPartialData = (
    priceData: BasePricesData | ServiceTypePricesData
  ) => {
    setData((prevData) => ({
      ...prevData,
      ...priceData,
    }));
  };

  useEffect(() => {
    preserveLocalPrices({
      ...savedPricesData,
      ...validate(
        pickRelevantProperties(
          removeFalseFlags(data, savedPricesData),
          !!menuItemPriceLevelAddon
        )
      ),
    });
    // eslint-disable-next-line
  }, [data]);

  return (
    <div>
      <MmsFormTitle>{t("menu:pricing-model")}</MmsFormTitle>
      <MmsMuiSelectbox
        id="disableBasePrices"
        name="disableBasePrices"
        label={t("menu:pricing-model")}
        fullWidth
        value={pricingModel}
        onChange={(value) => setPricingModelData(value as PricingModel)}
        data={Object.values(PricingModel).map((value) => ({
          title: {
            [PricingModel.Base]: t("menu:pricing-model-base-option"),
            [PricingModel.ServiceTypeSpecific]: t(
              "menu:pricing-model-service-type-option"
            ),
          }[value],
          value: value,
        }))}
      />

      <hr className={styles.hr} />

      <MmsFormTitle>
        {
          {
            [PricingModel.Base]: t("menu:pricing-model-base-title"),
            [PricingModel.ServiceTypeSpecific]: t(
              "menu:pricing-model-service-type-title"
            ),
          }[pricingModel]
        }
      </MmsFormTitle>

      {pricingModel === PricingModel.Base ? (
        <BasePricesSection
          pricesData={pricesData}
          priceLevelAddon={menuItemPriceLevelAddon}
          onChange={setPartialData}
        />
      ) : (
        <>
          <div className={styles.serviceTypeGroup}>
            <div className={styles.serviceTypeSectionHeading}>
              <small>{t("menu:label-delivery")}</small>
              <MmsSwitch
                checked={deliveryTakeawayEnabled || false}
                onChange={() => {
                  setPartialData({
                    enableDeliveryPrices: !deliveryTakeawayEnabled,
                    enableTakeawayPrices: !deliveryTakeawayEnabled,
                  });
                  setDeliveryTakeawayEnabled(!deliveryTakeawayEnabled);
                }}
                id={`enableDeliveryTakeawayPrices`}
              />
            </div>

            <ServiceTypePricesSection
              type={ServiceType.Delivery}
              pricesData={pricesData}
              priceLevelAddon={menuItemPriceLevelAddon}
              enabled={deliveryTakeawayEnabled || false}
              onChange={setPartialData}
            />

            <div className={styles.serviceTypeSectionHeading}>
              <small>{t("menu:label-takeaway")}</small>
            </div>

            <ServiceTypePricesSection
              type={ServiceType.Pickup}
              pricesData={pricesData}
              priceLevelAddon={menuItemPriceLevelAddon}
              enabled={deliveryTakeawayEnabled || false}
              onChange={setPartialData}
            />
          </div>

          <div className={styles.serviceTypeSectionHeading}>
            <small>{t("menu:label-dine-in")}</small>

            <MmsSwitch
              checked={dineInEnabled || false}
              onChange={() => {
                setPartialData({
                  enableDineInPrices: !dineInEnabled,
                });
                setDineInEnabled(!dineInEnabled);
              }}
              id={`enableDineInPrices`}
            />
          </div>

          <ServiceTypePricesSection
            type={ServiceType.DineIn}
            pricesData={pricesData}
            priceLevelAddon={menuItemPriceLevelAddon}
            enabled={dineInEnabled || false}
            onChange={setPartialData}
          />
        </>
      )}
    </div>
  );
};
