import { themeColors } from "@theme/colors";
import { Cloud } from "@uiKit/Atoms/SVGIcons";
import React from "react";
import { MmsBaseButtonProps } from "../MmsBaseButton";
import { MmsIconTitleButton } from "../MmsIconTextButton";

export interface MmsImportFromLibraryButtonProps extends MmsBaseButtonProps {
  disabled?: boolean;
  btnTitle: string;
  containerClassName?: string;
  className?: string;
}

export const MmsImportFromLibraryButton: React.FC<MmsImportFromLibraryButtonProps> = ({
  btnTitle,
  ...restProps
}) => {
  return (
    <MmsIconTitleButton
      btnIcon={
        <Cloud
          id={`import-from-library-icon`}
          size="small"
          color={themeColors.lightGreen}
        />
      }
      btnTitle={btnTitle}
      {...restProps}
    />
  );
};
