import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./CategoryDesktopLayout.styles";

export interface CategoryDesktopLayoutProps {
  checkboxCell: React.ReactNode;
  errorCell: React.ReactNode;
  titleCell: React.ReactNode;
  addonsCell: React.ReactNode;
  priceCell: React.ReactNode;
  controlsGroupCell: React.ReactNode;
  toggleCell: React.ReactNode;
  itemsAmountCell: React.ReactNode;
  chevronCell: React.ReactNode;
  className?: string;
  shouldKeepControlsVisible: boolean;
}

export const CategoryDesktopLayout = React.forwardRef<
  HTMLDivElement,
  CategoryDesktopLayoutProps & React.HTMLProps<HTMLDivElement>
>(
  (
    {
      checkboxCell,
      errorCell,
      titleCell,
      addonsCell,
      priceCell,
      controlsGroupCell,
      toggleCell,
      itemsAmountCell,
      chevronCell,
      className,
      shouldKeepControlsVisible,
      ...rest
    },
    ref
  ) => {
    return (
      <div ref={ref} className={cx(styles.container, className)} {...rest}>
        <div className={cx(styles.cell, styles.checkboxCell)}>
          {checkboxCell}
        </div>
        <div className={cx(styles.cell, styles.errorCell)}>{errorCell}</div>
        <div className={cx(styles.cell, styles.titleCell)}>{titleCell}</div>
        <div className={cx(styles.cell, styles.addonsCell)}>{addonsCell}</div>
        <div className={cx(styles.cell, styles.priceCell)}>{priceCell}</div>
        <div
          className={cx(
            "controlsGroupCell",
            styles.cell,
            styles.controlsGroupCell(shouldKeepControlsVisible)
          )}
        >
          {controlsGroupCell}
        </div>
        <div className={cx(styles.cell, styles.toggleCell)}>{toggleCell}</div>
        <div className={cx(styles.cell, styles.itemsAmountCell)}>
          {itemsAmountCell}
        </div>
        <div className={cx(styles.cell, styles.chevronCell)}>{chevronCell}</div>
      </div>
    );
  }
);
