import { useCallback, useMemo, useState } from "react";
import { MmsTabsCallbacksType } from "./types";

export const useMmsTabs = (
  tabsAmount: number,
  initialTab = 0
): [number, MmsTabsCallbacksType] => {
  const firstTabIndex = 0;
  const lastTabIndex = useMemo(() => (tabsAmount ? tabsAmount - 1 : 0), [
    tabsAmount,
  ]);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(initialTab);

  const selectTab = useCallback((tabIndex) => {
    setSelectedTabIndex(tabIndex);
  }, []);

  const selectNewTab = useCallback(() => {
    selectTab(tabsAmount);
  }, [selectTab, tabsAmount]);

  const selectLastTab = useCallback(() => {
    selectTab(lastTabIndex);
  }, [selectTab, lastTabIndex]);

  const removeNewTab = useCallback(() => {
    selectTab(lastTabIndex);
  }, [lastTabIndex, selectTab]);

  const removeTab = useCallback(
    (tabIndex) => {
      let nextSelectedTabIndex = firstTabIndex;

      switch (true) {
        // if it's the last item
        case tabIndex! === lastTabIndex:
          // set selection on the tab before last (it will be the last after tab gets removed)
          nextSelectedTabIndex = lastTabIndex ? lastTabIndex - 1 : 0;
          break;
        // if it's in the middle of the tab list
        case tabIndex! > firstTabIndex && tabIndex! < lastTabIndex:
          // leave selection on the next tab which index will be the same after current tab is deleted
          nextSelectedTabIndex = tabIndex!;
          break;
        // if it's the first item, or something else
        case tabIndex! === firstTabIndex:
        default:
          // leave current selection
          break;
      }

      selectTab(nextSelectedTabIndex);
    },
    [firstTabIndex, lastTabIndex, selectTab]
  );

  return [
    selectedTabIndex,
    {
      selectTab,
      removeTab,
      selectNewTab,
      removeNewTab,
      selectLastTab,
    },
  ];
};
