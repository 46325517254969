import { useQuery } from "@apollo/client";
import { useDidUpdate, useErrorHandler } from "@hooks";
import { ModelMenuState } from "@model/DAO/MenuState";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import produce from "immer";
import { useState } from "react";
import { useParams } from "react-router";
import {
  GetMenuStateTypes,
  GET_MENU_STATE,
} from "../../graphql/queries/GetMenuState";
import {
  generateGetNotificationsVars,
  GetNotificationsTypes,
  GET_NOTIFICATION,
} from "../../graphql/queries/GetNotifications";

const POLLING_INTERVAL = 10000;

export const useGetNotifications = (
  menuState: ModelMenuState
): [boolean, GetNotificationsTypes.GetNotificationsQuery] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [menuLocalState, setMenuLocalState] = useState<ModelMenuState | null>(
    null
  );

  const {
    loading,
    data,
    client,
    error,
  } = useQuery<GetNotificationsTypes.GetNotificationsQuery>(GET_NOTIFICATION, {
    variables: generateGetNotificationsVars(menuLocalState),
    skip: !menuLocalState,
    pollInterval: POLLING_INTERVAL,
    onCompleted: (data) => {
      const state = client.readQuery<GetMenuStateTypes.GetMenuStateQuery>({
        query: GET_MENU_STATE,
        variables: { restaurantId },
      });

      const prevLastUpdatedAt = state?.restaurant.state?.lastUpdatedAt;
      const newLastUpdatedAt = data?.notifications?.newState?.lastUpdatedAt;
      // don not update the cache if we already have actual data
      if (prevLastUpdatedAt === newLastUpdatedAt) return;

      client.writeQuery<GetMenuStateTypes.GetMenuStateQuery>({
        query: GET_MENU_STATE,
        variables: { restaurantId },
        data: produce(state!, (newState) => {
          if (!newState) return;
          newState.restaurant.state = data.notifications.newState;
        }),
      });
    },
    onError,
  });

  const [
    notificationsData,
    setNotificationsData,
  ] = useState<GetNotificationsTypes.GetNotificationsQuery>(data!);

  // prevents nullable values before new data is loaded
  useDidUpdate(() => {
    loading || setNotificationsData(data!);
  }, [data, loading]);

  useDidUpdate(() => {
    error && onError(error);
  }, [error, onError]);

  useDidUpdate(() => {
    if (!menuState || menuLocalState) return;
    setMenuLocalState(menuState);
  }, [menuState, menuLocalState]);

  return [loading, notificationsData!];
};
