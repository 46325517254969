import { NetworkStatus, useQuery } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { Library } from "@model/DAO/Library";
import { FormattedAssetEnum } from "@model/helperTypes/LibraryAsset";
import {
  GetLibraryTypes,
  GET_LIBRARY,
} from "@pages/RestaurantMenuPage/graphql/queries/GetLibrary";
import { produce } from "immer";
import { useCallback } from "react";

export const useGetLibrary = (
  includedAssets: {
    [key: string]: boolean;
  },
  page: number
): [
  boolean,
  Library | undefined,
  (page: number, includedAsset: FormattedAssetEnum) => void,
  {
    isFetchingMore: boolean;
    isUpdatingCache: boolean;
  }
] => {
  const [, { onError }] = useErrorHandler([]);
  const queryVars = {
    menus: !!includedAssets.menus,
    menuItems: !!includedAssets.menuItems,
    categories: !!includedAssets.categories,
    addons: !!includedAssets.addons,
  };
  const {
    loading,
    data,
    fetchMore,
    networkStatus,
  } = useQuery<GetLibraryTypes.GetLibrary>(GET_LIBRARY, {
    variables: { ...queryVars, page },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onError,
  });

  const getMore = useCallback(
    (page: number, includedAsset: FormattedAssetEnum) => {
      fetchMore({
        variables: { ...queryVars, page },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return produce(fetchMoreResult, (newLibraryData) => {
            newLibraryData.library = {
              ...prev.library,
              [includedAsset]: {
                ...prev.library[includedAsset],
                nodes: [
                  ...(prev.library[includedAsset]?.nodes || []),
                  ...(fetchMoreResult.library[includedAsset]?.nodes || []),
                ],
                pagination: {
                  ...(prev.library[includedAsset]?.pagination || {}),
                  ...(fetchMoreResult.library[includedAsset]?.pagination || {}),
                },
              },
            };
          });
        },
      });
    },
    [fetchMore, queryVars]
  );

  return [
    loading,
    data?.library,
    getMore,
    {
      isFetchingMore: networkStatus === NetworkStatus.fetchMore,
      isUpdatingCache: networkStatus === NetworkStatus.loading,
    },
  ];
};
