import { gql } from "@apollo/client";
import { ModelMenuState } from "@model/DAO/MenuState";
import { MENU_STATE } from "../../fragments/Menu";

export const GET_NOTIFICATION = gql`
  query GetNotificationsQuery($input: StateData!) {
    notifications(input: $input) {
      newState {
        ...MenuState
      }
      notifications {
        action
        code
        message
      }
      workingUsers {
        current
        displayName
        email
        id
        name
        role
      }
    }
  }
  ${MENU_STATE}
`;

export const generateGetNotificationsVars = (
  menuState: ModelMenuState | null
) => ({
  input: {
    id: menuState?.id,
    restaurantId: menuState?.restaurantId,
    lastUpdatedAt: menuState?.lastUpdatedAt,
    lastUpdatedBy: menuState?.lastUpdatedBy,
    publishedBy: menuState?.publishedBy,
    publishedAt: menuState?.publishedAt,
  },
});

export * as GetNotificationsTypes from "./__generated__/GetNotificationsQuery";
