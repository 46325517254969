import React from "react";
import { IReleaseNote } from "../../withEssentialData";
import { ReleaseItem } from "@pages/ReleaseNotesPage/components/ReleaseItem/ReleaseItem";

interface ReleaseListProps {
  releases: IReleaseNote[];
}

export const ReleaseList: React.FC<ReleaseListProps> = ({ releases }) => {
  return (
    <>
      {releases.map((release, index) => (
        <ReleaseItem
          release={release}
          expended={index === 0}
          key={`item-${index}`}
        />
      ))}
    </>
  );
};

export default ReleaseList;
