import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  title: css`
    margin: 6px 16px;

    font-size: 15px;
    font-weight: bold;
  `,
  detailsCell: css`
    width: 100%;
    padding: 0px 40px 30px !important;

    background-color: ${themeColors.lightGrey};

    tr:last-child td {
      border-bottom: none !important;
    }

    td,
    th {
      padding: 2px 16px !important;

      border-bottom: 1px solid ${themeColors.borderGreyTransparent};

      font-weight: lighter;
    }
  `,
  prevValueCell: css`
    color: ${themeColors.yellow} !important;
    word-break: break-word;
  `,
  valueCell: css`
    color: ${themeColors.lightGreen} !important;
    word-break: break-word;
  `,
  comment: css`
    margin: 0px 16px 16px;

    color: ${themeColors.lightGreen} !important;
    font-style: italic;
    font-size: 13px;
  `,
};
