import { css } from "@emotion/css";

export const styles = {
  btnGroup: css`
    align-items: center;

    width: 100%;
  `,
  commonBtn: css`
    min-width: 140px;
  `,
};
