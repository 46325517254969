import { Meta, Story } from "@storybook/react/types-6-0";
import { TopCenterAligned } from "@storybookKit";
import React from "react";
import MmsLogoSpinnerWithMobileHack from "./MmsLogoSpinner";
import { MmsSimpleSpinner } from "./MmsSimpleSpinner";

export default {
  title: "uiKit/Atoms/Spinners",
  decorators: [TopCenterAligned],
} as Meta;

export const LogoSpinner: Story = () => <MmsLogoSpinnerWithMobileHack />;

export const SimpleSpinner: Story = () => <MmsSimpleSpinner />;
