import React from "react";
import { styles } from "./styles";

export interface BtnFlatListProps {
  list: React.ReactElement[];
}

export const BtnFlatList: React.FC<BtnFlatListProps> = ({ list }) => {
  return (
    <div className={styles.list}>
      {list.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};
