import { ApolloProvider } from "@apollo/client";
import "@theme/app";
import { createApolloClient } from "@utils/apolloSetup";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Switch } from "react-router-dom";
import { getRoutes } from "./routes/RouterService";

const client = createApolloClient();

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              marginTop: "110px",
              marginLeft: "75vw",
            },
          }}
        />
        <DndProvider backend={HTML5Backend}>
          <HelmetProvider>
            <Switch>{getRoutes()}</Switch>
          </HelmetProvider>
        </DndProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
