import { useActiveImportDirection, useOnScrollPagination } from "@hooks";
import { Library, LibraryAssetNodes } from "@model/DAO/Library";
import {
  AssetTabIndexes,
  FormattedAssetEnum,
} from "@model/helperTypes/LibraryAsset";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { MmsEmptyContentBanner } from "@uiKit/Atoms/EmptyContentBanner/MmsEmptyContentBanner";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { isHasMore } from "@utils/pagination";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelectedLibraryTabs } from "../../hooks/useSelectedLibraryTabs";
import { LibraryModalLayout } from "../../layouts/LibraryModalLayout/LibraryModalLayout";
import { LibraryAssetDataPanel } from "./LibraryAssetDataPanel/LibraryAssetDataPanel";
import { LibraryAssetTabsPanel } from "./LibraryAssetTabsPanel/LibraryAssetTabsPanel";
import { styles } from "./LibrarySettings.styles";
import { useTranslation } from "react-i18next";

export interface LibrarySettingsProps<ComponentProps> {
  libraryData: Library;
  assetsTabs: { [key: string]: boolean };
  onImport: (
    ids: string[],
    destinationId: string,
    destinationType: MenuAssetEnum,
    originType: FormattedAssetEnum
  ) => void;
  onCancel: VoidFunction;
  onFetchMore: (page: number, includedAsset: FormattedAssetEnum) => void;
  importLoading: boolean;
  libraryLoading: boolean;
  isRefreshing: boolean;
  ItemComponent: React.ComponentType<ComponentProps>;
}

export const LibrarySettings = <ComponentProps,>({
  libraryData,
  assetsTabs,
  onImport,
  onCancel,
  onFetchMore,
  importLoading,
  libraryLoading,
  isRefreshing,
  ItemComponent,
}: PropsWithChildren<LibrarySettingsProps<ComponentProps>>) => {
  const { t } = useTranslation(["common"]);
  const [libraryPage, setLibraryPage] = useState<number>(1);

  const [assetNodes, setAssetNodes] = useState<LibraryAssetNodes[]>([]);

  const [assetNodeData, setAssetNodeData] = useState<{
    data: LibraryAssetNodes["displayData"];
    title: string;
  }>({ data: [], title: "" });

  const [selectedTabs, setSelectedTabs] = useState<string[]>([]);

  const {
    selectedAssetTypeId,
    selectedNodeTabId,
    selectedAssetTabName,
    callbacks: { onSelectAssetType, onSelectNode },
  } = useSelectedLibraryTabs(libraryData);

  const [destination] = useActiveImportDirection();

  const emptyListText = useMemo(() => {
    const isIncluded = Object.keys(AssetTabIndexes).reduce((tempValue, key) => {
      if (
        AssetTabIndexes[key as keyof typeof AssetTabIndexes] ===
        selectedAssetTypeId
      ) {
        return !!assetsTabs[key];
      }
      return tempValue;
    }, false);

    if (isIncluded) return "";

    switch (selectedAssetTypeId) {
      case AssetTabIndexes.menus:
        return `
          You cannot import menus when you access the library from a specific menu asset. Open the library from the main menu to access this section.
        `;
      case AssetTabIndexes.categories:
        return `
          You cannot import categories when you access the library from a specific menu asset. Open the library from the main menu to access this section.
        `;
      case AssetTabIndexes.menuItems:
        return `
          You cannot import menu items when you access the library from the main menu. Open the library from a specific category to access this section.
        `;
      case AssetTabIndexes.addons:
        return `
          You cannot import menu addons when you access the library from the main menu. Open the library from a specific category or menu item to access this section.
        `;
      default:
        return "";
    }
  }, [assetsTabs, selectedAssetTypeId]);

  const hasMore = useMemo(() => {
    const library = libraryData[selectedAssetTabName];
    if (!library?.pagination) return false;
    return isHasMore(
      library.pagination.offset,
      library.pagination.perPage,
      library.pagination.total
    );
  }, [libraryData, selectedAssetTabName]);

  const handleFerchMoreLibraryData = useCallback(() => {
    onFetchMore(libraryPage + 1, selectedAssetTabName as FormattedAssetEnum);
    setLibraryPage(libraryPage + 1);
  }, [onFetchMore, libraryPage, selectedAssetTabName]);

  const [rootContainer, lastElementRef] = useOnScrollPagination(
    handleFerchMoreLibraryData,
    hasMore,
    libraryLoading
  );

  const onToggleCheckbox = useCallback(
    (id: LibraryAssetNodes["id"]) => {
      if (selectedTabs.includes(id)) {
        setSelectedTabs(selectedTabs.filter((tabId) => tabId !== id));
      } else setSelectedTabs([...selectedTabs, id]);
    },
    [selectedTabs]
  );

  const handleSelectAssetType = useCallback(
    (key: any) => {
      onSelectAssetType(key);
      setSelectedTabs([]);
    },
    [onSelectAssetType]
  );

  const handleImport = useCallback(() => {
    onImport(
      selectedTabs,
      destination.destinationId,
      destination.destinationType,
      selectedAssetTabName as FormattedAssetEnum
    );
  }, [
    destination.destinationId,
    destination.destinationType,
    onImport,
    selectedAssetTabName,
    selectedTabs,
  ]);

  useEffect(() => {
    const nodes: LibraryAssetNodes[] =
      libraryData[selectedAssetTabName]?.nodes || [];
    const data =
      nodes.find(({ id }) => id === selectedNodeTabId)?.displayData || {};

    setAssetNodes(nodes);
    setAssetNodeData({
      data: data.items || [],
      title: data.title || "",
    });
  }, [libraryData, selectedAssetTabName, selectedNodeTabId]);

  useEffect(() => {
    selectedTabs.forEach((currentId) => {
      if (assetNodes.find(({ id }) => id === currentId)) return;
      onToggleCheckbox(currentId);
    });
  }, [assetNodes, onToggleCheckbox, selectedTabs]);

  return (
    <VerticalAlignBetween>
      <div className={styles.title}>Import from Library</div>
      <LibraryModalLayout
        firstCol={
          <LibraryAssetTabsPanel
            assets={assetsTabs}
            selectedTab={selectedAssetTypeId}
            onSelect={handleSelectAssetType}
          />
        }
        secondCol={
          assetNodes.length !== 0 ? (
            <>
              {assetNodes.map((node, index) => {
                const isLastItem = assetNodes.length === index + 1;
                return (
                  <div key={node.id} ref={isLastItem ? lastElementRef : null}>
                    <ItemComponent
                      {...(({
                        ...node,
                        isTabSelected: selectedNodeTabId === node.id,
                        isCheckboxSelected: selectedTabs.includes(node.id),
                        onToggleCheckbox: onToggleCheckbox,
                        onSelectAsset: onSelectNode,
                      } as unknown) as ComponentProps)}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <MmsEmptyContentBanner
              title={"No items available"}
              description={emptyListText}
              className={styles.emtyListContainer}
            />
          )
        }
        thirdCol={
          <LibraryAssetDataPanel
            data={assetNodeData.data}
            selectedAssetTypeId={selectedAssetTypeId}
            dataOwnerName={assetNodeData.title}
          />
        }
        contentLoading={libraryLoading && !isRefreshing}
        secondColRef={rootContainer}
      />
      <JustifyContentEndLayout className={styles.btnRow}>
        <MmsSecondaryButton id="cancel-import-assets" onClick={onCancel}>
          {t("common:cancel")}
        </MmsSecondaryButton>
        <MmsTooltip
          title={
            !selectedTabs.length
              ? "You need to select 1 or more assets to import from the library"
              : "Import the selected assets to this menu"
          }
        >
          <div>
            <MmsPrimaryButton
              id="import-assets"
              onClick={handleImport}
              processing={importLoading}
              disabled={!selectedTabs.length}
            >
              Import
            </MmsPrimaryButton>
          </div>
        </MmsTooltip>
      </JustifyContentEndLayout>
    </VerticalAlignBetween>
  );
};
