import { stripHtml } from "string-strip-html";

export const clearWysiwyg = (text: string): string =>
  stripHtml(text)
    .result.replace(/&amp;/g, "&")
    .replace(/(\n|\r|&nbsp;)+/g, " ")
    .trim();

export const convertCellValueToString = (value: any) => {
  if (value === true || value === false) return value.toString();
  if (Array.isArray(value)) return value.join(", ");
  return value || "-";
};
