import { Cloud, CloudDownload, FileUpload, Csv } from "@uiKit/Atoms/SVGIcons";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { MmsPopper, MmsPopperProps } from "@uiKit/Molecules/Popper/MmsPopper";
import { noop } from "@utils/noop";
import React, { useCallback } from "react";
import { styles } from "./CloudPopper.styles";
import { CloudPopperButton } from "./CloudPopperButton/CloudPopperButton";
import { useTranslation } from "react-i18next";

export interface CloudPopperProps {
  uniqId: string;
  className?: string;
  anchorEl: MmsPopperProps["anchorEl"];
  saveToLibraryTooltipTitle: string;
  importFromLibraryTooltipTitle: string;
  importMenuTooltipTitle?: string;
  downloadMenuTooltipTitle?: string;
  isPopperOwnerMenu?: boolean;
  isSavingToLibraryDisabled?: boolean;
  isImportFromLibraryDisabled?: boolean;
  onClose: VoidFunction;
  onSaveToLibraryClick: VoidFunction;
  onImportFromLibraryClick: VoidFunction;
  onImportMenuClick?: VoidFunction;
  onDownloadMenuCsv?: VoidFunction;
}

export const CloudPopper: React.FC<CloudPopperProps> = ({
  uniqId,
  className,
  anchorEl,
  saveToLibraryTooltipTitle,
  importFromLibraryTooltipTitle,
  importMenuTooltipTitle,
  downloadMenuTooltipTitle,
  isPopperOwnerMenu,
  isSavingToLibraryDisabled,
  isImportFromLibraryDisabled,
  onClose,
  onSaveToLibraryClick,
  onImportFromLibraryClick,
  onImportMenuClick = noop,
  onDownloadMenuCsv,
}) => {
  const { t } = useTranslation(["library"]);

  const handleSaveToLibraryClick = useCallback(() => {
    onClose();
    onSaveToLibraryClick();
  }, [onSaveToLibraryClick, onClose]);

  const handleImportFromLibraryClick = useCallback(() => {
    onClose();
    onImportFromLibraryClick();
  }, [onClose, onImportFromLibraryClick]);

  const handleImportMenuClick = useCallback(() => {
    onClose();
    onImportMenuClick();
  }, [onClose, onImportMenuClick]);

  const handleDownloadMenuCsv = useCallback(() => {
    onClose();
    if (onDownloadMenuCsv) {
      onDownloadMenuCsv();
    }
  }, [onClose, onDownloadMenuCsv]);

  return (
    <MmsPopper
      id={`cloud-popper-${uniqId}`}
      className={className}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <VerticalAlignBetween className={styles.container}>
        <CloudPopperButton
          buttonId={`save-to-library-${uniqId}`}
          isDisabled={isSavingToLibraryDisabled}
          tooltipTitle={saveToLibraryTooltipTitle}
          buttonTitle={
            isPopperOwnerMenu
              ? t("library:save-menu-to-library")
              : t("library:save-to-library")
          }
          onClick={handleSaveToLibraryClick}
          IconComponent={CloudDownload}
        />
        <CloudPopperButton
          buttonId={`import-from-library-${uniqId}`}
          isDisabled={isImportFromLibraryDisabled}
          tooltipTitle={importFromLibraryTooltipTitle}
          buttonTitle={t("library:import-from-library")}
          onClick={handleImportFromLibraryClick}
          IconComponent={Cloud}
        />
        {isPopperOwnerMenu && (
          <CloudPopperButton
            buttonId={`import-menu-${uniqId}`}
            tooltipTitle={importMenuTooltipTitle!}
            buttonTitle={t("library:import-menu-from-file")}
            onClick={handleImportMenuClick}
            IconComponent={FileUpload}
          />
        )}
        <CloudPopperButton
          buttonId={`download-menu-${uniqId}`}
          tooltipTitle={downloadMenuTooltipTitle!}
          buttonTitle={t("library:download-menu-as-csv-file")}
          onClick={handleDownloadMenuCsv}
          IconComponent={Csv}
        />
      </VerticalAlignBetween>
    </MmsPopper>
  );
};
