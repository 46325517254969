import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface JustifyContentCenterLayoutProps {
  className?: string;
  children: React.ReactNode;
}

export const JustifyContentCenterLayout = React.forwardRef<
  HTMLDivElement,
  JustifyContentCenterLayoutProps & React.HTMLProps<HTMLDivElement>
>(({ className, children, ...restProps }, ref) => (
  <div ref={ref} {...restProps} className={cx(styles, className)}>
    {children}
  </div>
));
