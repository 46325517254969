import React from "react";
import { styles } from "./MenuItemPriceList.styles";

interface MenuItemPriceListProps {
  priceList: React.ReactNode;
  onPriceListClick?: VoidFunction;
}

export const MenuItemPriceList: React.FC<MenuItemPriceListProps> = ({
  priceList,
  onPriceListClick,
}) => {
  return (
    <div className={styles(!!onPriceListClick)} onClick={onPriceListClick}>
      {priceList}
    </div>
  );
};
