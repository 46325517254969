import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const EDIT_CATEGORY_TITLE = gql`
  mutation EditCategoryTitleMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuCategory {
        title
        id
      }
    }
  }
`;

export const generateEditCategoryTitleVars = (
  restaurantId: string,
  categoryId: string,
  newTitle: string
) => ({
  input: {
    restaurantId,
    entityType: Levels.CATEGORY,
    entityId: categoryId,
    category: {
      title: newTitle,
    },
  },
});

export * as EditCategoryTitleTypes from "./__generated__/EditCategoryTitleMutation";
