import React from "react";
import ReactDOM from "react-dom";
import { styles } from "./styles";

interface MmsOverlayProps {
  children?: React.ReactElement;
  opacity: number;
}

export const MmsOverlay: React.FC<MmsOverlayProps> = ({
  children,
  opacity,
}) => {
  return ReactDOM.createPortal(
    <div data-test-id="overlay" className={styles(opacity)}>
      {children}
    </div>,
    document.body
  );
};
