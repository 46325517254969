// based on code from https://react-dnd.github.io/react-dnd/examples/sortable/stress-test

import {
  DragAndDropContext,
  useDragAndDropContext,
} from "@contexts/DragAndDropContext";
import {
  DragAndDropClientReturnTuple,
  DragAndDropProviderReturnTuple,
  DraggableDataItem,
  DraggableDataState,
  DropResult,
} from "@model/helperTypes/dragAndDrop";
import { mapDataToDraggable } from "@utils/dragAndDrop";
import produce from "immer";
import debounce from "lodash.debounce";
import { equals } from "ramda";
import { useCallback, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDidUpdate } from "./useDidUpdate";
import { useToggle } from "./useToggle";

export const useDragAndDropProvider = <DataType>(
  data: DataType[]
): DragAndDropProviderReturnTuple<DataType> => {
  const [
    { initialDraggableData, mutableDraggableData },
    setDraggableData,
  ] = useState<DraggableDataState<DataType>>(
    mapDataToDraggable<DataType>(data)
  );

  const moveDraggableItem = useCallback(
    debounce(
      (id: number, afterId: number) => {
        const draggableItem: any = initialDraggableData[id];
        const afterDraggableItem = initialDraggableData[afterId];

        const draggableItemIndex = mutableDraggableData.indexOf(draggableItem);
        const afterDraggableIndex = mutableDraggableData.indexOf(
          afterDraggableItem
        );

        setDraggableData({
          initialDraggableData,
          mutableDraggableData: produce(
            mutableDraggableData,
            (newMutableDraggableData) => {
              newMutableDraggableData.splice(draggableItemIndex, 1);
              newMutableDraggableData.splice(
                afterDraggableIndex,
                0,
                draggableItem
              );
            }
          ),
        });
      },
      10,
      { leading: false }
    ),
    [initialDraggableData, mutableDraggableData]
  );

  useDidUpdate(() => {
    const newDraggableData = mapDataToDraggable(data);
    if (equals(initialDraggableData, newDraggableData.initialDraggableData))
      return;

    setDraggableData(newDraggableData);
  }, [data]);

  return [mutableDraggableData, moveDraggableItem, DragAndDropContext.Provider];
};

export const useDragAndDropClient = (
  draggableType: string
): DragAndDropClientReturnTuple => {
  const {
    draggableItem,
    moveDraggableItem,
    onRerankEnd,
    onTransferEnd,
  } = useDragAndDropContext();

  const draggableRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [isEnabled, , setIsEnabled] = useToggle(true);

  const [{ isDragging, didDrop }, drag, connectPreview] = useDrag({
    type: draggableType,
    item: draggableItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      didDrop: monitor.didDrop(),
    }),
    end: ({ id: draggedId }, monitor) => {
      const dropResult: DropResult = monitor.getDropResult();

      if (dropResult && "action" in dropResult) {
        switch (dropResult.action) {
          case "TRANSFER":
            const { itemId, parentIdFrom, parentIdTo } = dropResult.payload;
            onTransferEnd({
              itemId,
              parentIdFrom,
              parentIdTo,
            });
            return;
          default:
            return;
        }
      } else {
        if (monitor.didDrop()) return;
        onRerankEnd();
      }
    },
  });

  const [, drop] = useDrop({
    accept: draggableType,
    hover({ id: draggedId }: DraggableDataItem<any>) {
      // if an item was dropped to the same position
      if (draggedId === draggableItem.id) return;

      moveDraggableItem(draggedId, draggableItem.id);
    },
    drop() {
      onRerankEnd();
    },
  });

  drag(isEnabled ? handleRef : null);
  drop(isEnabled ? draggableRef : null);
  connectPreview(isEnabled ? previewRef : null);

  return [
    { draggableRef, handleRef, previewRef },
    { isDragging, didDrop },
    setIsEnabled,
  ];
};
