import { cx } from "@emotion/css";
import React from "react";
import { styles } from "../_common/styles";

export interface MmsLinkProps {
  href: string;
  className?: string;
  children: React.ReactNode;
  extraProps?: any;
}

export const MmsLink: React.FC<MmsLinkProps> = ({
  href,
  className,
  children,
  extraProps,
}) => (
  <a href={href} className={cx(styles.link, className)} {...extraProps}>
    {children}
  </a>
);
