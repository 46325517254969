import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { validateField } from "@utils/formik";
import { Form, FormikProps, withFormik } from "formik";
import React from "react";
import { styles } from "./MenuTextFilter.styles";
import { validationMap } from "./MenuTextFilter.validationMap";

interface FormValues {
  [key: string]: string;
}

interface MenuTextFilterProps {
  label: string;
  name: string;
  defaultValue?: string;
}

export const MenuTextFilter: React.FC<
  MenuTextFilterProps & FormikProps<FormValues>
> = ({ name, label, ...formikProps }) => {
  return (
    <Form className={styles.form}>
      <MmsMuiInput
        data-testid={`${name}-logs-filter`}
        name={name}
        label={label}
        onBlur={formikProps.submitForm}
        validate={validateField(validationMap)}
        blurOnEnter
        showErrorMessage
      />
    </Form>
  );
};

interface MenuTextFilterFormProps extends MenuTextFilterProps {
  onSubmit: (value: any) => void;
}

// Wrap our form with the withFormik HoC
export const MenuTextFilterForm = withFormik<
  MenuTextFilterFormProps,
  FormValues
>({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: ({ name, defaultValue }) => ({
    [name]: defaultValue || "",
  }),
  handleSubmit: (values, { props: { defaultValue, name, onSubmit } }) => {
    if (!defaultValue && !values[name]) return;
    onSubmit(values[name]);
  },
})(MenuTextFilter);
