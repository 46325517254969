import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  dataItem: css`
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    margin: 1px 0;
    padding: 14px;

    border-radius: ${themeVars.borderRadius};

    background-color: ${themeColors.white};
  `,
  dataText: css`
    max-width: 28ch;

    color: ${themeColors.mediumGray};
    font-size: 12px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;

    overflow: hidden;
  `,
};
