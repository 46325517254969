import { SCREEN_SIZES } from "@constants";
import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

export const styles = {
  wrapper: css`
    width: 100%;
    position: sticky;
    top: 0;

    background-color: ${themeColors.white};

    box-shadow: ${themeUtils.boxShadowSettings()} ${themeUtils.boxShadowOpacity()};
    z-index: 10;
  `,
  container: css`
    padding-top: 29px;
    padding-bottom: 29px;
    /* mobile view */
    /* @media (max-width: ${SCREEN_SIZES.LG - 1}px) {
      padding-top: 7px;
      padding-bottom: 7px;
    } */
  `,
};
