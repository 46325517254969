import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  notificationModal: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 470px;
    height: fit-content;
  `,
  title: css`
    max-width: 100%;
    padding: 10px 19px;

    background: ${themeColors.cream};

    font-size: 20px;
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  container: css`
    padding: 45px 40px;
  `,
  message: css`
    padding-bottom: 30px;

    font-size: 20px;
    text-align: center;
  `,
};
