import React from "react";
import { styles } from "./index.styles";

interface CommonSettingsTabPanelLayoutProps {
  children: React.ReactNode;
}

export const CommonSettingsTabPanelLayout: React.FC<CommonSettingsTabPanelLayoutProps> = ({
  children,
}) => <div className={styles}>{children}</div>;
