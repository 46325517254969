import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  container: (isIFrame: boolean) => css`
    width: 1125px;
    max-width: 100vw;
    height: 100%;

    border-radius: ${themeVars.borderRadius};

    background-color: ${themeColors.white};

    box-shadow: ${themeUtils.boxShadowSettings(80)} ${themeUtils.boxShadowOpacity()};
    overflow: hidden;

    ${isIFrame &&
    css`
      @media (max-width: 920px) {
        max-width: 920px;
      }
    `}
  `,
  statusBar: css`
    height: 50px;

    border-bottom: 2px solid ${themeColors.cream};
  `,
  content: css`
    height: calc(100% - 50px);
  `,
};
