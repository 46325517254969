import { MenuAssetEnum } from "./MenuAsset";

export enum FormattedAssetEnum {
  menuItems = "menuItems",
  addons = "addons",
  categories = "categories",
  menus = "menus",
}

export enum AssetTabIndexes {
  menus = 0,
  categories = 1,
  menuItems = 2,
  addons = 3,
}

// TODO: need to remove translates from enum
export enum TypesLabelsEnum {
  menus = "Menu",
  categories = "Category",
  menuItems = "Menu item",
  addons = "Addon",
}

export interface ImportDirectionType {
  destinationId: string;
  destinationType: MenuAssetEnum;
}
