import { useFormErrorsProtection } from "@hooks";
import {
  AddonsSettingsStrategyEnum,
  NewEmptyAddonsSettingsProps,
} from "@model/helperTypes/AddonsSettings";
import { StatusBarLayout } from "@pages/RestaurantMenuPage/components/CommonSettings/StatusBarLayout/StatusBarLayout";
import { useImportAddonsFromLibrary } from "@pages/RestaurantMenuPage/hooks/useImportAddonsFromLibrary";
import { AddonsSettingsBaseLayout } from "@pages/RestaurantMenuPage/layouts/AddonsSettings/BaseLayout/BaseLayout";
import { AddonsSettingsEmptyContentLayout } from "@pages/RestaurantMenuPage/layouts/AddonsSettings/EmptyContentLayout/EmptyContentLayout";
import { themeColors } from "@theme/colors";
import { MmsAddNewEntityButton } from "@uiKit/Atoms/Buttons/MmsAddNewEntityButton";
import { MmsImportFromLibraryButton } from "@uiKit/Atoms/Buttons/MmsImportFromLibraryButton";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { BtnFlatList } from "@uiKit/Molecules/BtnGroup/BtnFlatList";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { noop } from "@utils/noop";
import { getIsPartner } from "@utils/url";
import React, { useMemo } from "react";
import { NewStatusBarTitle } from "../NewStatusBar/Title/Title";
import { NewStatusBarToggleAttachButton } from "../NewStatusBar/ToggleAttachButton/ToggleAttachButton";
import { styles } from "./NewEmptyAddonsSettings.styles";
import { useTranslation } from "react-i18next";

export const NewEmptyAddonsSettings: React.FC<NewEmptyAddonsSettingsProps> = ({
  addonsSettingsStrategy,
  parentTitle,
  isUpdateProcessing,
  isAddonsChanged,
  addNewAddonClick,
  onCancelClick,
  onUpdateClick,
  updateAddonsAndProceed,
  toggleAttachToMenuItem,
}) => {
  const { t } = useTranslation(["addons", "common"]);
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();
  const [
    isUnsavedChangesWarningModalOpen,
    {
      confirmImportFromLibrary,
      handleImportFromLibraryClick,
      closeUnsavedChangesWarningModal,
    },
  ] = useImportAddonsFromLibrary(
    isUpdateProcessing,
    isAddonsChanged,
    updateAddonsAndProceed
  );

  const extendedParentTitle = useMemo(() => `${parentTitle} addons`, [
    parentTitle,
  ]);

  switch (addonsSettingsStrategy) {
    case AddonsSettingsStrategyEnum.category: {
      return (
        <>
          <AddonsSettingsBaseLayout
            statusBar={
              <StatusBarLayout
                leftCol={<NewStatusBarTitle title={extendedParentTitle} />}
              />
            }
            content={
              <AddonsSettingsEmptyContentLayout
                message={t("addons:category-has-no-addons-yet")}
                importFromLibraryBtn={
                  <MmsImportFromLibraryButton
                    id="addons-settings-import-from-library-btn"
                    btnTitle={t("addons:import-addon-from-library")}
                    onClick={withActiveFormsErrorsProtection(
                      handleImportFromLibraryClick
                    )}
                    disabled={getIsPartner() || isUpdateProcessing}
                  />
                }
                addNewEntityBtn={
                  <MmsAddNewEntityButton
                    id="addons-settings-add-new-addon-btn"
                    btnTitle={t("addons:add-new-addon")}
                    onClick={addNewAddonClick}
                    disabled={getIsPartner() || isUpdateProcessing}
                  />
                }
                footer={
                  <BtnFlatList
                    list={[
                      <MmsSecondaryButton
                        id="addons-settings-cancel-btn"
                        className={styles.commonBtn}
                        onClick={onCancelClick}
                        disabled={isUpdateProcessing}
                      >
                        {t("common:cancel")}
                      </MmsSecondaryButton>,
                      <MmsPrimaryButton
                        id="addons-settings-update-btn"
                        className={styles.commonBtn}
                        onClick={onUpdateClick}
                        processing={isUpdateProcessing}
                        disabled={!isAddonsChanged}
                      >
                        {t("common:update")}
                      </MmsPrimaryButton>,
                    ]}
                  />
                }
              />
            }
          />

          {isUnsavedChangesWarningModalOpen && (
            <MmsConfirmationModal
              message={t("addons:you-have-unsaved-changes")}
              confirmBtnText={t("addons:save-and-proceed")}
              declineBtnText={t("common:cancel")}
              onConfirmBtnClick={confirmImportFromLibrary}
              onDeclineBtnClick={closeUnsavedChangesWarningModal}
              isDataProcessing={isUpdateProcessing}
            />
          )}
        </>
      );
    }
    case AddonsSettingsStrategyEnum.menu_item_attached: {
      return (
        <>
          <AddonsSettingsBaseLayout
            statusBar={
              <StatusBarLayout
                bgColor={themeColors.white}
                leftCol={
                  <NewStatusBarTitle
                    title={extendedParentTitle}
                    color={themeColors.black}
                  />
                }
                rightCol={
                  <AccessControl>
                    <NewStatusBarToggleAttachButton
                      title={t("addons:connect-addons-to-category")}
                      color={themeColors.mediumGray}
                      onClick={toggleAttachToMenuItem}
                    />
                  </AccessControl>
                }
              />
            }
            content={
              <AddonsSettingsEmptyContentLayout
                message={t("addons:this-item-has-no-addons-yet")}
                importFromLibraryBtn={
                  <MmsImportFromLibraryButton
                    id="addons-settings-import-from-library-btn"
                    btnTitle={t("addons:import-addon-from-library")}
                    onClick={withActiveFormsErrorsProtection(
                      handleImportFromLibraryClick
                    )}
                    disabled={getIsPartner() || isUpdateProcessing}
                  />
                }
                addNewEntityBtn={
                  <MmsAddNewEntityButton
                    id="addons-settings-add-new-addon-btn"
                    btnTitle={t("addons:add-new-addon")}
                    onClick={addNewAddonClick}
                    disabled={getIsPartner() || isUpdateProcessing}
                  />
                }
                footer={
                  <BtnFlatList
                    list={[
                      <MmsSecondaryButton
                        id="addons-settings-cancel-btn"
                        className={styles.commonBtn}
                        onClick={onCancelClick}
                        disabled={isUpdateProcessing}
                      >
                        {t("common:cancel")}
                      </MmsSecondaryButton>,
                      <MmsPrimaryButton
                        id="addons-settings-update-btn"
                        className={styles.commonBtn}
                        onClick={onUpdateClick}
                        processing={isUpdateProcessing}
                        disabled={!isAddonsChanged}
                      >
                        {t("common:update")}
                      </MmsPrimaryButton>,
                    ]}
                  />
                }
              />
            }
          />
          {isUnsavedChangesWarningModalOpen && (
            <MmsConfirmationModal
              message={t("addons:you-have-unsaved-changes")}
              confirmBtnText={t("addons:save-and-proceed")}
              declineBtnText={t("common:cancel")}
              onConfirmBtnClick={confirmImportFromLibrary}
              onDeclineBtnClick={closeUnsavedChangesWarningModal}
              isDataProcessing={isUpdateProcessing}
            />
          )}
        </>
      );
    }
    case AddonsSettingsStrategyEnum.menu_item_detached: {
      return (
        <AddonsSettingsBaseLayout
          statusBar={
            <StatusBarLayout
              leftCol={<NewStatusBarTitle title={extendedParentTitle} />}
              rightCol={
                <AccessControl>
                  <NewStatusBarToggleAttachButton
                    title={t("addons:disconnect-addons-from-category")}
                    onClick={toggleAttachToMenuItem}
                  />
                </AccessControl>
              }
            />
          }
          content={
            <MmsTooltip title={t("addons:disconnect-this-item")}>
              <AddonsSettingsEmptyContentLayout
                message={t("addons:this-item-has-no-addons-yet")}
                importFromLibraryBtn={
                  <MmsImportFromLibraryButton
                    id="addons-settings-import-from-library-btn"
                    btnTitle={t("addons:import-addon-from-library")}
                    onClick={noop}
                    disabled
                  />
                }
                addNewEntityBtn={
                  <MmsAddNewEntityButton
                    id="addons-settings-add-new-addon-btn"
                    btnTitle={t("addons:add-new-addon")}
                    onClick={noop}
                    disabled
                  />
                }
                footer={
                  <BtnFlatList
                    list={[
                      <MmsSecondaryButton
                        id="addons-settings-cancel-btn"
                        className={styles.commonBtn}
                        onClick={onCancelClick}
                      >
                        {t("common:cancel")}
                      </MmsSecondaryButton>,
                      <MmsPrimaryButton
                        id="addons-settings-update-btn"
                        className={styles.commonBtn}
                        onClick={noop}
                        processing={false}
                        disabled
                      >
                        {t("common:update")}
                      </MmsPrimaryButton>,
                    ]}
                  />
                }
              />
            </MmsTooltip>
          }
        />
      );
    }
    default: {
      throw new Error(
        "NewAddonsSettings -> NewEmptyAddonsSettings -> render(): Wrong addonsSettingsStrategy"
      );
    }
  }
};
