import { useOpenClose } from "@hooks";
import { noop } from "@utils/noop";
import React, { useCallback } from "react";
import { CommonSettingsContentEditTitleForm } from "./EditTitle/EditTitle";
import { CommonSettingsContentPlainTitle } from "./PlainTitle/PlainTitle";

export interface CommonSettingContentProps {
  uniqId: string;
  title: string;
  editModePlaceholder?: string;
  isInitEditMode?: boolean;
  isEditModeDisabled: boolean;
  onChangeTitle?: (newTitle: string) => void;
}

export const CommonSettingContentTitle: React.FC<CommonSettingContentProps> = ({
  uniqId,
  title,
  editModePlaceholder = "Enter title here",
  isInitEditMode = false,
  isEditModeDisabled,
  onChangeTitle = noop,
}) => {
  const [isEditMode, openEditMode, closeEditMode] = useOpenClose(
    isInitEditMode
  );

  const handleChangeTitle = useCallback(
    (values: any) => {
      onChangeTitle(values.title);
      closeEditMode();
    },
    [closeEditMode, onChangeTitle]
  );

  return (
    <>
      {isEditMode ? (
        <CommonSettingsContentEditTitleForm
          uniqId={uniqId}
          title={title}
          placeholder={editModePlaceholder}
          onSubmit={handleChangeTitle}
          onPressEsc={closeEditMode}
        />
      ) : (
        <CommonSettingsContentPlainTitle
          uniqId={uniqId}
          title={title}
          isEditModeDisabled={isEditModeDisabled}
          onTitleClick={openEditMode}
        />
      )}
    </>
  );
};
