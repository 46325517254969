import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    min-width: 190px;
    padding: 0 10px;

    border: 2px solid ${themeColors.lightGreen};
    border-radius: 20.5px;

    background-color: ${themeColors.lightGreen};

    color: ${themeColors.white};
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;

    &[disabled] {
      cursor: not-allowed;

      & > * {
        opacity: 0.5;
      }
    }
  `,
};
