import { css } from "@emotion/css";

export const styles = {
  modal: css`
    width: 770px;
    height: 450px;

    margin-top: 15px;

    z-index: 10;
  `,
};
