import { Table, TableBody, TableContainer } from "@material-ui/core";
import React from "react";
import "./globalStyles";

interface MmsTableProps {
  caption?: React.ReactElement | string;
  component?: any;
  tableHeadComponent?: React.ReactElement;
  tableBodyComponent: React.ReactElement;
  tablePaginationComponent?: React.ReactElement;
}

export const MmsTable: React.FC<MmsTableProps> = ({
  caption,
  component,
  tableHeadComponent,
  tableBodyComponent,
  tablePaginationComponent,
}) => (
  <TableContainer className={"MmsTableContainer"} component={component}>
    {caption}
    <Table aria-label={"collapsible table"}>
      {tableHeadComponent}
      <TableBody>{tableBodyComponent}</TableBody>
    </Table>
    {tablePaginationComponent}
  </TableContainer>
);
