import { LocalAddonItemsMeta } from "@model/helperTypes/Addon";
import isDateInFuture from "date-fns/isFuture";

export const notEmpty = (errorMessage = "This field can't be blank") => (
  value: string
) => {
  return !value.trim().length ? errorMessage : undefined;
};

export const minLength = (
  length: number,
  errorMessage = "Must be more than {length} characters"
) => (value: string) => {
  if (!value) return;
  return value.trim().length < length
    ? errorMessage.replace("{length}", String(length))
    : undefined;
};

export const maxLength = (
  length: number,
  errorMessage = "Must be less than {length} characters"
) => (value: string) => {
  if (!value) return;
  return value.trim().length > length
    ? errorMessage.replace("{length}", String(length))
    : undefined;
};

export const timeFormat = (errorMessage = "Time format is incorrect") => (
  value: string
) => {
  const [maxHours, maxMinutes] = [23, 59];
  const hoursMinsArray = value.split(":");
  const [currentHoursStr, currentMinutesStr] = hoursMinsArray;

  if (hoursMinsArray.length !== 2) return errorMessage;
  if (hoursMinsArray.some((item) => item.length !== 2)) return errorMessage;
  if (hoursMinsArray.some((item) => isNaN(Number(item)))) return errorMessage;
  if (Number(currentHoursStr) > maxHours) return errorMessage;
  if (Number(currentMinutesStr) > maxMinutes) return errorMessage;

  return undefined;
};

export const priceLevelNotEmpty = (
  errorMessage = "You must add at least 1 addon item to 'price level'"
) => (addonItemsMeta: LocalAddonItemsMeta) => {
  return !addonItemsMeta.localAddonItems.length ? errorMessage : undefined;
};

export const dateInFuture = (
  errorMessage = "Choose date and time in the future"
) => (date: Date) => {
  return !isDateInFuture(date) ? errorMessage : undefined;
};
