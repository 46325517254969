import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    gap: 9px;
    align-items: center;
  `,
  filtersRow: css`
    gap: 8px;
  `,
  filterBtn: (isActive?: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;

    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      border-radius: 8px;

      ${isActive
        ? css`
            background-color: ${themeColors.lightGreen};
          `
        : css`
            background-color: ${themeColors.black};
            opacity: 0.2;
          `}

      z-index: -1;
    }
  `,
  appliedFiltersContainer: css`
    width: 16px;
    height: 16px;
    position: absolute;
    top: -6px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: ${themeColors.pink};
  `,
  appliedFiltersText: css`
    font-size: 12px;
    color: white;
    font-weight: bold;
  `,
};
