import React from "react";
import { styles } from "./Layout.styles";

export interface CommonSettingsLayoutProps {
  statusBar: React.ReactNode;
  leftColContent: React.ReactNode;
  rightColHeaderTitle: React.ReactNode;
  rightColContent: React.ReactNode;
  rightColFooter: React.ReactNode;
}

export const CommonSettingsLayout = React.forwardRef<
  HTMLDivElement,
  CommonSettingsLayoutProps
>(
  (
    {
      statusBar,
      leftColContent,
      rightColHeaderTitle,
      rightColContent,
      rightColFooter,
      ...rest
    },
    ref
  ) => {
    return (
      <div {...rest} ref={ref} className={styles.container}>
        <header className={styles.statusBar}>{statusBar}</header>
        <main className={styles.mainContent}>
          <div className={styles.leftCol}>{leftColContent}</div>
          <div className={styles.rightCol}>
            <header className={styles.rightColHeader}>
              <div className={styles.rightColHeaderTitle}>
                {rightColHeaderTitle}
              </div>
            </header>
            <section className={styles.rightColContent}>
              {rightColContent}
            </section>
            <footer className={styles.rightColFooter}>{rightColFooter}</footer>
          </div>
        </main>
      </div>
    );
  }
);
