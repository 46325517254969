import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { ModelMenuFlag } from "@model/DAO/MenuFlag";
import { AddonItemsSettingsBaseProps } from "@pages/RestaurantMenuPage/components/AddonItemsSettings/AddonItemsSettings";
import { NewAddonListProps } from "@pages/RestaurantMenuPage/components/NewAddonsSettings/NewAddonList/NewAddonList";
import { AddonTypeEnum } from "./Addon";

export enum AddonsSettingsStrategyEnum {
  category = "category",
  menu_item_attached = "menu_item_attached",
  menu_item_detached = "menu_item_detached",
}

export interface CommonAddonsSettingsProps {
  addonsSettingsStrategy: AddonsSettingsStrategyEnum;
  parentTitle: string;
  isUpdateProcessing: boolean;
  isAddonsChanged: boolean;
  addNewAddonClick: VoidFunction;
  onCancelClick: VoidFunction;
  onUpdateClick: VoidFunction;
  updateAddonsAndProceed: VoidFunction;
  toggleAttachToMenuItem: VoidFunction;
}

export interface AllAddonsSettingsProps extends CommonAddonsSettingsProps {
  restaurantId: string;
  isReadyToSaveToLibrary: boolean;
  isPriceLevelAddonAvailable: boolean;
  selectedAddon: ModelLocalAddon | null;
  newAddon: ModelLocalAddon | null;
  addNewAddonItemClick: VoidFunction;
  setIsReadyToSaveToLibrary: (isReady: boolean) => void;
  changeAddonTitle: (addonId: string, newTitle: string) => void;
  onChangeAddonType: (addonId: string, newType: AddonTypeEnum) => void;
  togglePriceLevelDependent: (addonId: string) => void;
  changeAddonMenuFlags: (
    addonId: string,
    newMenuFlags: ModelMenuFlag[]
  ) => void;
  onMinMaxSet: (
    addonId: string,
    values: { min?: number; max?: number }
  ) => void;
}

export interface NewEmptyAddonsSettingsProps
  extends CommonAddonsSettingsProps {}

export interface NewDefaultAddonsSettingsProps
  extends CommonAddonsSettingsProps {
  restaurantId: string;
  isReadyToSaveToLibrary: boolean;
  isPriceLevelAddonAvailable: boolean;
  selectedAddon: ModelLocalAddon | null;
  newAddon: ModelLocalAddon | null;
  baseAddonListProps: NewAddonListProps;
  baseAddonItemsSettingsProps: Omit<
    AddonItemsSettingsBaseProps,
    "addon" | "addonType" | "addonStrategy" | "isPriceLevelDependent"
  >;
  addNewAddonItemClick: VoidFunction;
  setIsReadyToSaveToLibrary: (isReady: boolean) => void;
  changeAddonTitle: (addonId: string, newTitle: string) => void;
  onChangeAddonType: (addonId: string, newType: AddonTypeEnum) => void;
  changeAddonMenuFlags: (
    addonId: string,
    newMenuFlags: ModelMenuFlag[]
  ) => void;
  togglePriceLevelDependent: (addonId: string) => void;
  onMinMaxSet: (
    addonId: string,
    values: { min?: number; max?: number }
  ) => void;
}
