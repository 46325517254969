import { gql } from "@apollo/client";

export const MENU_STATE = gql`
  fragment MenuState on MenuState {
    id
    restaurantId
    lastUpdatedAt
    lastUpdatedBy
    published
    publishedAt
    publishedBy
  }
`;
