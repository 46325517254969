import { ModelGalleryImage } from "@model/DAO/MenuGallery";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Camera, Trash } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import React, { useCallback } from "react";
import { styles } from "./MenuItemImageSettings.styles";
import { useTranslation } from "react-i18next";

export interface MenuItemImageSettingsProps {
  onCancel: VoidFunction;
  onUpdateImage: (id: string) => void;
  selectedImage: ModelGalleryImage | null;
  setSelectedImage: (image: ModelGalleryImage | null) => void;
  onChooseFile: VoidFunction;
  onRemoveImage: VoidFunction;
}

export const MenuItemImageSettings: React.FC<MenuItemImageSettingsProps> = ({
  onCancel,
  onUpdateImage,
  selectedImage,
  setSelectedImage,
  onChooseFile,
  onRemoveImage,
}) => {
  const { t } = useTranslation(["common"]);
  const updateImage = useCallback(() => {
    if (!selectedImage) {
      onRemoveImage();
      onCancel();
      return;
    }
    onUpdateImage(selectedImage.id);
  }, [selectedImage, onUpdateImage, onRemoveImage, onCancel]);

  const deleteLocalImage = useCallback(() => {
    setSelectedImage(null);
  }, [setSelectedImage]);

  return (
    <>
      <JustifyContentBetweenLayout>
        <MmsBaseButton
          id={`choose-file-button`}
          onClick={onChooseFile}
          className={styles.chooseFileButton}
        >
          Choose file
        </MmsBaseButton>
      </JustifyContentBetweenLayout>
      {selectedImage?.imageUrl ? (
        <JustifyContentBetweenLayout className={styles.imageContainer}>
          <div className={styles.imageBox}>
            <MmsSvgButton
              id={`selected-image-${selectedImage.id}`}
              onClick={deleteLocalImage}
              className={styles.deleteIconContainer}
            >
              <Trash
                id={`selected-image-${selectedImage.id}-icon`}
                className={styles.trashIcon}
              />
            </MmsSvgButton>
            <img
              alt={selectedImage.name}
              src={selectedImage.imageUrl}
              className={styles.img}
            />
          </div>
        </JustifyContentBetweenLayout>
      ) : (
        <div className={styles.svgBoxWrapper}>
          <div className={styles.svgBox}>
            <Camera className={styles.svg} />
          </div>
        </div>
      )}

      <JustifyContentBetweenLayout className={styles.buttonsRow}>
        <MmsSecondaryButton id="cancel" onClick={onCancel}>
          {t("common:cancel")}
        </MmsSecondaryButton>
        <MmsPrimaryButton id="update" onClick={updateImage}>
          {t("common:update")}
        </MmsPrimaryButton>
      </JustifyContentBetweenLayout>
    </>
  );
};
