import { ReactComponent as SVGRelease } from "@assets/SVG/release-notes.svg";
import { cx } from "@emotion/css";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IReleaseProps extends IIconBase {}

export const Release: React.FC<IReleaseProps> = ({
  id,
  size = "medium",
  className,
}) => {
  const ref = useSvgFilter(id);

  return <SVGRelease ref={ref} className={cx(styles.icon(size), className)} />;
};
