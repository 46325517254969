import { CategoryList } from "@pages/RestaurantMenuPage/components/CategoryList/CategoryList";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import { noop } from "@utils/noop";
import React from "react";
import { CategorySkeleton } from "../../Category/CategorySkeleton/CategorySkeleton";
import { styles } from "./CategoryListSkeleton.styles";

export const CategoryListSkeleton: React.FC = () => {
  return (
    <CategoryList
      bulkPanel={
        <div className={styles.bulkPanel}>
          <MmsCheckbox
            key={`category-list-skeleton-checkbox`}
            id={`category-list-skeleton-checkbox`}
            containerClassName={styles.selectAllBtn}
            checked={false}
            onChange={noop}
          />
        </div>
      }
    >
      {Array.from({ length: 3 }, (_, index) => (
        <CategorySkeleton key={`category-skeleton-${index}`} />
      ))}
    </CategoryList>
  );
};

export const SimpleCategoryListSkeleton: React.FC = () => {
  return (
    <CategoryList showToolbar={false}>
      {Array.from({ length: 3 }, (_, index) => (
        <CategorySkeleton key={`category-skeleton-${index}`} />
      ))}
    </CategoryList>
  );
};
