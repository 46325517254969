import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  input: css`
    display: none;
  `,
  shape: css`
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0;

    cursor: pointer;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
    }
    &:before {
      top: 3px;
      width: 16px;
      height: 16px;

      border: 1px solid ${themeColors.borderLightGrey};
      border-radius: ${themeVars.borderRadius};
    }
    &:after {
      left: 3px;
      top: 6px;
      width: 10px;
      height: 10px;

      background-color: ${themeColors.lightGreen};

      transform: scale(0);
      transition: transform 0.3s;
    }

    &[data-disabled="true"] {
      cursor: default;

      &:after {
        background-color: ${themeColors.lightGrey};
      }
    }

    input[type="checkbox"]:checked + & {
      &:after {
        transform: scale(1);
        transition: transform 0.3s;
      }
    }
  `,
};
