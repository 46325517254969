import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";
import { AddonTypeEnum, LocalAddonItemsMeta } from "@model/helperTypes/Addon";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { validateField } from "@utils/formik";
import { FormikProps, withFormik } from "formik";
import React from "react";
import {
  AddonItemsSettings,
  AddonItemsSettingsBaseProps,
} from "./AddonItemsSettings";
import { styles } from "./AddonItemsSettings.styles";
import { validationMap } from "./AddonItemsSettingsForm.validationMap";

interface FormValues {
  addonItemsMeta: LocalAddonItemsMeta;
}

interface AddonItemsSettingsFormContentProps {
  addonItemsSettingsProps: AddonItemsSettingsBaseProps;
  addonItemsMeta: LocalAddonItemsMeta;
}

const AddonItemsSettingsFormContent: React.FC<
  AddonItemsSettingsFormContentProps & FormikProps<FormValues>
> = ({ addonItemsSettingsProps, ...formikProps }) => {
  const formId = `addon-items-settings-form-${addonItemsSettingsProps.addon.id}`;

  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useDidUpdate(() => {
    formikProps.resetForm();
  }, [addonItemsSettingsProps.addonType]);

  return (
    <>
      <MmsFormTitle className={styles.formTitle}>Addon items</MmsFormTitle>
      <AddonItemsSettings
        {...addonItemsSettingsProps}
        fieldName="addonItemsMeta"
        validate={validateField(
          validationMap(
            addonItemsSettingsProps.addonType === AddonTypeEnum.priceLevel
          ).addonItemsMeta
        )}
      />
    </>
  );
};

export const AddonItemsSettingsForm = withFormik<
  AddonItemsSettingsFormContentProps,
  FormValues
>({
  enableReinitialize: true,
  mapPropsToValues: ({ addonItemsMeta }) => ({ addonItemsMeta }),
  handleSubmit: (
    values: FormValues,
    {
      props: {
        addonItemsSettingsProps: { addon, updateAddonItems },
      },
    }
  ) => updateAddonItems(addon.id, values.addonItemsMeta),
})(AddonItemsSettingsFormContent);
