import { PUBLISH_MENU_PROGRESSBAR_ID } from "@constants";
import { themeColors } from "@theme/colors";
import {
  MmsSvgButton,
  MmsSvgButtonProps,
} from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Play } from "@uiKit/Atoms/SVGIcons";
import { MmsProgressBar } from "@uiKit/Molecules/ProgressBar/MmsProgressBar";
import React from "react";
import { styles } from "./PublishMenuButton.styles";

interface PublishMenuButtonProps extends MmsSvgButtonProps {
  isPublished: boolean;
}

export const PublishMenuButton = React.forwardRef<
  HTMLButtonElement,
  PublishMenuButtonProps
>(({ isPublished, ...restProps }, ref) => {
  return (
    <MmsSvgButton {...restProps} ref={ref}>
      <div className={styles.menuStatus}>
        <Play
          id={`${restProps.id}-icon`}
          size="large"
          color={isPublished ? themeColors.lightGreen : themeColors.yellow}
        />
        <span className={styles.menuStatusTitle(isPublished)}>
          {isPublished ? "Published" : "Publish"}
        </span>
      </div>
      <MmsProgressBar
        id={PUBLISH_MENU_PROGRESSBAR_ID}
        className={styles.progressBar}
      />
    </MmsSvgButton>
  );
});
