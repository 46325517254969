import { cx } from "@emotion/css";
import { PopupNotificationTypesEnum } from "@model/helperTypes/Notification";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import {
  Cancel,
  CheckCircle,
  ErrorCircle,
  InfoCircle,
} from "@uiKit/Atoms/SVGIcons";
import { JustifyContentStartLayout } from "@uiKit/Layouts/JustifyContentStartLayout";
import React, { useMemo } from "react";
import "./globalStyles";
import { styles } from "./styles";

interface ActionButton {
  type: PopupNotificationTypesEnum;
  onClick: VoidFunction;
  text: string;
}

export interface MmsNotificationProps {
  id?: string;
  handleClose: VoidFunction;
  message: string;
  type: string;
  showCloseButton?: boolean;
  actionButtons?: ActionButton[] | null;
}

export const MmsNotification: React.FC<MmsNotificationProps> = ({
  id,
  handleClose,
  message,
  type,
  showCloseButton,
  actionButtons,
}) => {
  const NotificationIcon = useMemo(() => {
    switch (type) {
      case PopupNotificationTypesEnum.success:
        return CheckCircle;
      case PopupNotificationTypesEnum.error:
        return ErrorCircle;
      default:
        return InfoCircle;
    }
  }, [type]);

  return (
    <div>
      <JustifyContentStartLayout
        className={cx(`MuiSnackbar-${type}`, styles.notificationRow)}
      >
        {showCloseButton && (
          <div onClick={handleClose} className={styles.closeButtonContainer}>
            <Cancel className={styles.closeIcon} />
          </div>
        )}
        <div className={styles.notificationIconWrapper}>
          <NotificationIcon
            id={`mms-notification-${id || ""}-icon`}
            size="large"
          />
        </div>

        <div className={styles.message}>{message}</div>
      </JustifyContentStartLayout>
      {actionButtons?.map((button, index) => (
        <MmsBaseButton
          key={index}
          id={`toast-button-${index}`}
          onClick={button.onClick}
          fullWidth
          className={styles.buttonContainer(button.type)}
        >
          <span className={styles.buttonText(button.type)}>{button.text}</span>
        </MmsBaseButton>
      ))}
    </div>
  );
};
