import { MmsLabelSwitch } from "@uiKit/Atoms/Switch/MmsLabelSwitch";
import React, { useCallback } from "react";
import { styles } from "./HalalToggle.styles";
import { useTranslation } from "react-i18next";

interface HalalToggleProps {
  halalFriendly: boolean;
  toggleHalalFriendly: (halalFriendly: boolean) => void;
}

export const HalalToggle: React.FC<HalalToggleProps> = ({
  halalFriendly,
  toggleHalalFriendly,
}) => {
  const { t } = useTranslation(["menu"]);
  const handleToggleIsHalal = useCallback(() => {
    toggleHalalFriendly(!halalFriendly);
  }, [halalFriendly, toggleHalalFriendly]);

  return (
    <MmsLabelSwitch
      id="toggle-halal-friendly"
      labelClassName={styles.label}
      label={t("menu:halal")}
      checked={halalFriendly}
      onChange={handleToggleIsHalal}
      inverted
    />
  );
};
