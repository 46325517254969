import { noop } from "@utils/noop";
import React from "react";
import { styles } from "./PlainTitle.styles";

interface CommonSettingsContentPlainTitleProps {
  uniqId: string;
  title: string;
  isEditModeDisabled: boolean;
  onTitleClick: VoidFunction;
}

export const CommonSettingsContentPlainTitle: React.FC<CommonSettingsContentPlainTitleProps> = ({
  uniqId,
  title,
  isEditModeDisabled,
  onTitleClick,
}) => (
  <div className={styles.container}>
    <div
      data-testid={`common-settings-content-plain-title-${uniqId}`}
      className={styles.title(isEditModeDisabled)}
      onClick={isEditModeDisabled ? noop : onTitleClick}
    >
      {title}
    </div>
  </div>
);
