import { cx } from "@emotion/css";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import React from "react";
import "./globalStyles";
import { styles } from "./styles";

export interface MmsTableCellProps {
  className?: TableCellProps["className"];
  align?: TableCellProps["align"];
  colSpan?: TableCellProps["colSpan"];
  showUnderline?: boolean;
  children: TableCellProps["children"];
}

export const MmsTableCell: React.FC<MmsTableCellProps> = ({
  className,
  align = "center",
  colSpan,
  showUnderline = true,
  children,
}) => {
  return (
    <TableCell
      colSpan={colSpan}
      className={cx("MmsTableCell", styles.cell(!!showUnderline), className)}
      align={align}
    >
      {children}
    </TableCell>
  );
};
