import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";
import { MENU_ITEM_ESSENTIAL_DATA } from "../../fragments/MenuItem";

export const CREATE_MENU_ITEM = gql`
  mutation CreateMenuItemMutation($input: MenuNodeAddData!) {
    mmsAdd(input: $input) {
      ...MenuItemEssentialData
    }
  }
  ${MENU_ITEM_ESSENTIAL_DATA}
`;

export const generateCreateMenuItemVars = (
  restaurantId: string,
  categoryId: string,
  menuItemTitle: string,
  active: boolean
) => ({
  input: {
    restaurantId,
    levelType: "menu_item",
    entityType: Levels.CATEGORY,
    entityId: categoryId,
    menuItem: {
      title: menuItemTitle,
      active,
      hasOwnAddons: false,
      price: 0,
      priceLevel1: 0,
      priceLevel2: 0,
      priceLevel3: 0,
      priceLevel4: 0,
      priceLevel5: 0,
    },
  },
});

export * as CreateMenuItemTypes from "./__generated__/CreateMenuItemMutation";
