import { useCommonTopLevelEffects } from "@hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { styles } from "./AuthPage.styles";
import { useAuthProvider } from "./hooks/useAuthProvider";

export const AuthPage = () => {
  useAuthProvider();
  useCommonTopLevelEffects();

  return (
    <div className={styles.pageWrapper}>
      <CircularProgress
        color={"inherit"}
        thickness={4}
        className={styles.loader}
      />
    </div>
  );
};
