import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const DESTROY_CATEGORY = gql`
  mutation DestroyCategoryMutation($input: DestroyData!) {
    mmsDestroy(input: $input)
  }
`;

export const generateDestroyCategoryVars = (
  restaurantId: string,
  categoryId: string
) => ({
  input: {
    restaurantId,
    entityType: Levels.CATEGORY,
    entityId: categoryId,
  },
});

export * as DestroyCategoryTypes from "./__generated__/DestroyCategoryMutation";
