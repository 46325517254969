import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsPopper, MmsPopperProps } from "@uiKit/Molecules/Popper/MmsPopper";
import React from "react";
import { styles } from "./PublishMenuPopper.styles";
import { useTranslation } from "react-i18next";

export interface PublishMenuPopperProps {
  id: string;
  className: string;
  anchorEl: MmsPopperProps["anchorEl"];
  message: React.ReactNode;
  onClose: VoidFunction;
  onPublishAnyway: VoidFunction;
}

export const PublishMenuPopper: React.FC<PublishMenuPopperProps> = ({
  id,
  className,
  anchorEl,
  message,
  onClose,
  onPublishAnyway,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <MmsPopper
      id={`publish-menu-popper-${id}`}
      className={className}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <div className={styles.container}>
        <div className={styles.errorMessage}>{message}</div>
        <JustifyContentBetweenLayout className={styles.btnGroup}>
          <MmsSecondaryButton
            id="cancel-publish"
            className={styles.btn}
            onClick={onClose}
          >
            {t("common:cancel")}
          </MmsSecondaryButton>
          <MmsPrimaryButton
            id="publish-anyway"
            className={styles.btn}
            onClick={onPublishAnyway}
          >
            {t("common:publish-anyway")}
          </MmsPrimaryButton>
        </JustifyContentBetweenLayout>
      </div>
    </MmsPopper>
  );
};
