import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const TOGGLE_MENU_ITEM_HAS_OWN_ADDONS = gql`
  mutation ToggleMenuItemHasOwnAddonsMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuItem {
        hasOwnAddons
        id
      }
    }
  }
`;

export const generateToggleMenuItemHasOwnAddonsVars = (
  restaurantId: string,
  menuItemId: string,
  hasOwnAddons: boolean
) => ({
  input: {
    restaurantId,
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
    menuItem: {
      hasOwnAddons,
    },
  },
});

export * as ToggleMenuItemHasOwnAddonsTypes from "./__generated__/ToggleMenuItemHasOwnAddonsMutation";
