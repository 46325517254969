import {
  MmsSvgButton,
  MmsSvgButtonProps,
} from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MoreHorizontal } from "@uiKit/Atoms/SVGIcons";
import React from "react";
import { styles } from "./ShowMoreButton.style";

interface ShowMoreButtonProps extends MmsSvgButtonProps {
  marker?: boolean;
}

export const ShowMoreButton = React.forwardRef<
  HTMLButtonElement,
  ShowMoreButtonProps
>(({ marker, ...props }, ref) => {
  return (
    <MmsSvgButton {...props} ref={ref}>
      <MoreHorizontal id={`${props.id}-icon`} size="large" />
      {marker && <span className={styles.marker} />}
    </MmsSvgButton>
  );
});
