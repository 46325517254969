import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  container: (isDragging: boolean, isMenuItemOver: boolean) => css`
    margin-top: 8px;

    border-radius: ${themeVars.borderRadius};

    background-color: white;
    opacity: ${isDragging || isMenuItemOver ? 0.5 : 1};
  `,
  layout: (isDragging: boolean, isDragAndDropDisabled: boolean, isGreyedOut: boolean) => css`
    cursor: ${isDragAndDropDisabled ? "auto" : "move"};
    opacity: ${isDragging || isGreyedOut ? 0.5 : 1};
    ${isGreyedOut &&
    css`
      background-color: ${themeColors.veryLightPink};
    `}
  `,
  baseControl: css`
    margin: 5px;
  `,
  checkboxShape: (isGreyedOut: boolean) => css`
    ${isGreyedOut &&
    css`
      &:before {
        border-color: ${themeColors.borderGrey};
      }
    `}
  `,
  toggleCategory: (isGreyedOut: boolean, isActive: boolean) => css`
    ${isGreyedOut &&
    !isActive &&
    css`
      .react-switch-bg {
        background-color: ${themeColors.borderGrey} !important;
      }
    `}
  `,
  categoryInfoWrapper: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  title: (isHighlighted: boolean) => css`
    ${isHighlighted &&
    css`
      background-color: ${themeColors.mediumGreenTransparent};
    `}
  `,
  description: css`
    color: ${themeColors.mediumGray};
    font-size: 12px;
    cursor: pointer;
  `,
};
