import { generateUniqueKey } from "@utils/general";
import { useEffect, useState } from "react";

export const useForceReloadOnUpdate = (
  dependencies: ReadonlyArray<any>
): string => {
  const [componentKey, setNewKey] = useState(generateUniqueKey());

  // eslint-disable-next-line
  useEffect(() => setNewKey(generateUniqueKey()), dependencies);

  return componentKey;
};
