import { useReactiveVar } from "@apollo/client";
import { cx } from "@emotion/css";
import {
  useActiveForms,
  useDidMount,
  useDidUpdate,
  useFormErrorsProtection,
  useGlobalLoading,
  useOpenClose,
  usePrevious,
} from "@hooks";
import { ModelLocalAddon, ModelMenuAddon } from "@model/DAO/MenuAddon";
import { ModelMainCategory } from "@model/DAO/MenuCategory";
import { AddonsOwnerEnum } from "@model/helperTypes/Addon";
import { FormattedAssetEnum } from "@model/helperTypes/LibraryAsset";
import { useAddonItemEditingModeReactiveList } from "@pages/RestaurantMenuPage/hooks/useAddonItemEditingModeReactiveList";
import { useNewAddon } from "@pages/RestaurantMenuPage/hooks/useNewAddon";
import { usePriceLevelAddon } from "@pages/RestaurantMenuPage/hooks/usePriceLevelAddon";
import { useSelectedAddon } from "@pages/RestaurantMenuPage/hooks/useSelectedAddon";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { getAddonsSettingsStrategy } from "@pages/RestaurantMenuPage/utils/AddonsSettings";
import { checkIsLoadingGlobal } from "@pages/RestaurantMenuPage/utils/globalLoader";
import { generateImportFromLibraryLoaderId } from "@pages/RestaurantMenuPage/utils/Library";
import { MmsAlert } from "@uiKit/Molecules/Modals/MmsAlert";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { useMmsTabs } from "@uiKit/Molecules/Tabs/MmsTab/useMmsTabs";
import { activeAddonItemsSettingsVar } from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import { isNotEmpty } from "@utils/ramdaHelpers";
import { curryN } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  LocalAddonsReducerStateType,
  useLocalAddons,
} from "../../hooks/useLocalAddons";
import { AutoenableSettings } from "../AutoenableSettings/AutoenableSettings";
import { NewAddonsSettings } from "../NewAddonsSettings/NewAddonsSettings";
import { AddonTabPanelSortableProps } from "./AddonTabPanelSortable/AddonTabPanelSortable";
import { useTranslation } from "react-i18next";

export interface AddonsSettingsProps {
  parentId: string;
  parentTitle: ModelMainCategory["title"];
  addonsOwner: AddonsOwnerEnum;
  addons: ModelMenuAddon[];
  isUpdateProcessing: boolean;
  isAddonsAttachedToMenuItem: boolean;
  isError: boolean;
  onCancel: VoidFunction;
  onUpdate: (differentAddons: LocalAddonsReducerStateType) => void;
  toggleAttachAddonsToMenuItem: VoidFunction;
  onAddonsSettingModalOpen: VoidFunction;
}

export const AddonsSettings: React.FC<AddonsSettingsProps> = ({
  parentId,
  parentTitle,
  addonsOwner,
  addons,
  isUpdateProcessing,
  isAddonsAttachedToMenuItem,
  isError,
  onCancel,
  onUpdate,
  toggleAttachAddonsToMenuItem,
  onAddonsSettingModalOpen,
}) => {
  const { t } = useTranslation(["common", "addons"]);

  const addonsSettingsStrategy = useMemo(
    () => getAddonsSettingsStrategy(addonsOwner, isAddonsAttachedToMenuItem),
    [addonsOwner, isAddonsAttachedToMenuItem]
  );

  const { restaurantId } = useParams<MatchParams>();

  const [
    { localAddons, newAddons, removedAddons, changedAddons },
    {
      create,
      clone,
      remove,
      toggle,
      changeType,
      changeTitle,
      changeAddonItems,
      changeAddonMenuFlags,
      togglePriceLevelDependent,
      reset,
      reorder,
      changeMinMax,
    },
  ] = useLocalAddons(addons);

  const [
    selectedTabIndex,
    { selectTab, removeTab, selectNewTab, removeNewTab, selectLastTab },
  ] = useMmsTabs(localAddons.length);

  const [
    newAddon,
    {
      clearNewAddon,
      toggleNewAddon,
      toggleNewAddonPriceLevelDependent,
      changeNewAddonType,
      prepareNewAddon,
      createNewAddon,
      changeNewAddonMinMax,
    },
  ] = useNewAddon({ localAddons, create }, { selectNewTab, removeNewTab });

  const selectedAddonItemsSettings = useReactiveVar(
    activeAddonItemsSettingsVar
  );

  const {
    3: immediateLeaveEveryAddonItemEditingMode,
  } = useAddonItemEditingModeReactiveList();

  const withImmediateLeaveEveryAddonItemEditingMode = useCallback(
    (fn) => (...args: any[]) => {
      immediateLeaveEveryAddonItemEditingMode();
      setTimeout(() => fn(...args), 0);
    },
    [immediateLeaveEveryAddonItemEditingMode]
  );

  const selectedAddon: ModelLocalAddon | null = useSelectedAddon(
    localAddons,
    newAddon,
    selectedTabIndex
  );

  const [
    activePriceLevelAddon,
    isPriceLevelAddonAvailable,
  ] = usePriceLevelAddon(localAddons);

  const isAddonsChanged = useMemo(
    () =>
      [newAddons, removedAddons, changedAddons].some((addons) => addons.length),
    [changedAddons, newAddons, removedAddons]
  );

  const [isReadyToUpdate, setReadyToUpdate, setNotReadyToUpdate] = useOpenClose(
    false
  );

  // it's defined not at the AddonTabContent, but here
  // in order to make saving of new addons to the library work correctly
  const [isReadyToSaveToLibrary, setIsReadyToSaveToLibrary] = useState(false);

  const [activeForms] = useActiveForms();
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [loaderIdList] = useGlobalLoading();
  const addonsImportLoadingGlobal = useMemo(() => {
    return checkIsLoadingGlobal(
      generateImportFromLibraryLoaderId(FormattedAssetEnum.addons),
      loaderIdList
    );
  }, [loaderIdList]);

  const prevAddonsImportLoadingGlobal = usePrevious(addonsImportLoadingGlobal);

  const [
    shouldCloseModalAfterUpdate,
    setShouldCloseModalAfterUpdate,
  ] = useState(true);

  const [
    isDeleteConfirmationModalOpen,
    openDeleteConfirmationModalOpen,
    closeDeleteConfirmationModalOpen,
  ] = useOpenClose(false);

  const [
    isCancelConfirmationModalOpen,
    openCancelConfirmationModal,
    closeCancelConfirmationModal,
  ] = useOpenClose(false);

  const [
    isPriceLevelAlertOpen,
    openPriceLevelAlert,
    closePriceLevelAlert,
  ] = useOpenClose(false);

  const [
    isAutoenableSettingsModalOpen,
    openAutoenableSettingsModal,
    closeAutoenableSettingsModal,
  ] = useOpenClose(false);

  const toggleWithAutoenable = useCallback(() => {
    selectedAddon!.active
      ? openAutoenableSettingsModal()
      : toggle(selectedAddon!.id, 0);
  }, [openAutoenableSettingsModal, selectedAddon, toggle]);

  const toggleWithPriceLevelProtection = useCallback(() => {
    // allow toggle either for non-priceLevel addon;
    // or for any type of addon, if one is active;
    // or for for priceLevel addon, if there ara no more active priceLevel addons in the addon list;
    const isToggleAllowed = [
      !selectedAddon!.priceLevel,
      selectedAddon!.active,
      isPriceLevelAddonAvailable,
    ].some(Boolean);

    return isToggleAllowed ? toggleWithAutoenable() : openPriceLevelAlert();
  }, [
    toggleWithAutoenable,
    isPriceLevelAddonAvailable,
    openPriceLevelAlert,
    selectedAddon,
  ]);

  const toggleNewAddonWithAutoenable = useCallback(() => {
    newAddon!.active ? openAutoenableSettingsModal() : toggleNewAddon(0);
  }, [newAddon, openAutoenableSettingsModal, toggleNewAddon]);

  const finishAutoenable = useCallback(
    (autoenableTimestamp: number) => {
      newAddon
        ? toggleNewAddon(autoenableTimestamp)
        : toggle(selectedAddon!.id, autoenableTimestamp);
      closeAutoenableSettingsModal();
    },
    [
      closeAutoenableSettingsModal,
      newAddon,
      selectedAddon,
      toggle,
      toggleNewAddon,
    ]
  );

  const handleSkipAutoenable = useCallback(() => finishAutoenable(0), [
    finishAutoenable,
  ]);

  const handleSaveAutoenable = useCallback(
    (autoenableDateTime: Date) =>
      finishAutoenable(autoenableDateTime.getTime()),
    [finishAutoenable]
  );

  const confirmDeleting = useCallback(() => {
    remove(selectedAddon!.id);
    removeTab(selectedTabIndex);
    closeDeleteConfirmationModalOpen();
  }, [
    closeDeleteConfirmationModalOpen,
    remove,
    removeTab,
    selectedAddon,
    selectedTabIndex,
  ]);

  const declineDeleting = useCallback(() => {
    closeDeleteConfirmationModalOpen();
  }, [closeDeleteConfirmationModalOpen]);

  const handleCloseCancelConfirmationModal = useCallback(() => {
    onCancel();
    reset();
    closeCancelConfirmationModal();
  }, [closeCancelConfirmationModal, onCancel, reset]);

  const update = useCallback(() => {
    onUpdate({ localAddons, newAddons, removedAddons, changedAddons });
  }, [changedAddons, localAddons, newAddons, onUpdate, removedAddons]);

  const handleUpdateClick = useCallback(() => {
    setShouldCloseModalAfterUpdate(true);
    setReadyToUpdate();
  }, [setReadyToUpdate]);

  const updateAddonsAndProceed = useCallback(() => {
    setShouldCloseModalAfterUpdate(false);
    setReadyToUpdate();
  }, [setReadyToUpdate]);

  const handleChangeOrder = useCallback(
    (data: AddonTabPanelSortableProps[]) => {
      const reorderedLocalAddons = data.map(
        ({ addonTabPanelProps: { addon } }, index) => ({
          ...addon,
          orderBy: index + 1,
        })
      );
      const newSelectedTabIndex = newAddon
        ? data.length
        : data.findIndex(({ mmsTabProps: { isSelected } }) => isSelected);
      reorder(reorderedLocalAddons);
      selectTab(newSelectedTabIndex);
    },
    [newAddon, reorder, selectTab]
  );

  const handleCloseModal = useCallback(() => {
    isAddonsChanged ? openCancelConfirmationModal() : onCancel();
  }, [isAddonsChanged, onCancel, openCancelConfirmationModal]);

  const isSelectTabModeDisabled = useMemo(() => {
    return [
      newAddon,
      activeForms.some(({ formAPI }) => isNotEmpty(formAPI.errors)),
    ].some(Boolean);
  }, [activeForms, newAddon]);

  useDidMount(onAddonsSettingModalOpen);

  useDidUpdate(() => {
    if (isUpdateProcessing || isError) return;

    shouldCloseModalAfterUpdate
      ? onCancel()
      : setShouldCloseModalAfterUpdate(true);
  }, [isUpdateProcessing]);

  useDidUpdate(() => {
    addons && reset();
  }, [addons]);

  useEffect(() => {
    if (!prevAddonsImportLoadingGlobal) return;
    if (addonsImportLoadingGlobal === prevAddonsImportLoadingGlobal) return;

    selectLastTab();
  }, [addonsImportLoadingGlobal, prevAddonsImportLoadingGlobal, selectLastTab]);

  useDidUpdate(() => {
    if (!isReadyToUpdate) return;
    update();
    setNotReadyToUpdate();
  }, [isReadyToUpdate]);

  return (
    <>
      <MmsBaseModal
        contentClassName={cx("addonsSettingsModal")}
        onClose={handleCloseModal}
      >
        <NewAddonsSettings
          localAddons={localAddons}
          allAddonsSettingsProps={{
            addonsSettingsStrategy,
            restaurantId,
            parentTitle,
            isUpdateProcessing,
            isAddonsChanged,
            selectedAddon,
            newAddon,
            isReadyToSaveToLibrary,
            isPriceLevelAddonAvailable,
            addNewAddonClick: withActiveFormsErrorsProtection(prepareNewAddon),
            addNewAddonItemClick:
              (!newAddon && selectedAddonItemsSettings?.prepareNewAddonItem) ||
              noop,
            onCancelClick: handleCloseModal,
            onUpdateClick: withActiveFormsErrorsProtection(
              withImmediateLeaveEveryAddonItemEditingMode(handleUpdateClick)
            ),
            updateAddonsAndProceed: withImmediateLeaveEveryAddonItemEditingMode(
              updateAddonsAndProceed
            ),
            toggleAttachToMenuItem: withActiveFormsErrorsProtection(
              toggleAttachAddonsToMenuItem
            ),
            setIsReadyToSaveToLibrary,
            changeAddonTitle: newAddon ? createNewAddon : changeTitle,
            onChangeAddonType: newAddon ? changeNewAddonType : changeType,
            changeAddonMenuFlags: changeAddonMenuFlags,
            togglePriceLevelDependent: newAddon
              ? toggleNewAddonPriceLevelDependent
              : togglePriceLevelDependent,
            onMinMaxSet: newAddon ? changeNewAddonMinMax : changeMinMax,
          }}
          baseAddonListProps={{
            addonsSettingsStrategy,
            localAddons,
            newAddon,
            activePriceLevelAddon,
            parentId,
            selectedTabIndex,
            isSelectTabModeDisabled,
            isUpdateProcessing,
            onSelect: withActiveFormsErrorsProtection(selectTab),
            onClone: withActiveFormsErrorsProtection(clone),
            onDelete: curryN(
              2,
              withActiveFormsErrorsProtection
            )(openDeleteConfirmationModalOpen),
            onToggle: curryN(
              2,
              withActiveFormsErrorsProtection
            )(toggleWithPriceLevelProtection),
            onDeleteNewAddon: clearNewAddon,
            onToggleNewAddon: toggleNewAddonWithAutoenable,
            onChangeOrder: handleChangeOrder,
          }}
          baseAddonItemsSettingsProps={{
            addonsSettingsStrategy,
            activePriceLevelAddon,
            isUpdateProcessing,
            updateAddonItems: changeAddonItems,
          }}
        />
      </MmsBaseModal>

      {isDeleteConfirmationModalOpen && (
        <MmsConfirmationModal
          message={t("addons:want-to-delete-addon", {
            addonTitle: selectedAddon!.title,
          })}
          confirmBtnText={t("common:delete")}
          declineBtnText={t("common:cancel")}
          onConfirmBtnClick={confirmDeleting}
          onDeclineBtnClick={declineDeleting}
        />
      )}

      {isCancelConfirmationModalOpen && (
        <MmsConfirmationModal
          message={t("common:changes-will-be-lost")}
          confirmBtnText={t("common:close-anyway")}
          declineBtnText={t("common:stay")}
          onConfirmBtnClick={handleCloseCancelConfirmationModal}
          onDeclineBtnClick={closeCancelConfirmationModal}
        />
      )}

      {isPriceLevelAlertOpen && (
        <MmsAlert
          message={t("addons:price-level-can-be-only-one-per-category-item")}
          closeBtnText={t("common:ok")}
          onCloseBtnClick={closePriceLevelAlert}
        />
      )}

      {isAutoenableSettingsModalOpen && (
        <AutoenableSettings
          uniqId="toggle-addon-autoenable-settings"
          onSkip={handleSkipAutoenable}
          onSave={handleSaveAutoenable}
        />
      )}
    </>
  );
};
