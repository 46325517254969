import {
  ModelLocalAddonItem,
  ModelMenuAddonItem,
} from "@model/DAO/MenuAddonItem";
import { AddonStrategyEnum, AddonTypeEnum } from "@model/helperTypes/Addon";
import {
  LevelDisabledType,
  LevelType,
  PriceLevelPrices,
} from "@model/helperTypes/PriceLevelPrices";
import { findMissingNumsInSequence } from "@utils/numbers";
import { getIsPartner } from "@utils/url";

export const MAX_PRICE_LEVEL_ADDON_ITEMS_AMOUNT = 5;

export const checkIsAddonItemNew = (addonItemId: string): boolean =>
  !!addonItemId.match(/^(new-addon-item|cloned)-/);

export const getNewAddonItemOrderBy = <T extends { orderBy: number }>(
  localAddonItems: T[]
): number => {
  if (!localAddonItems.length) return 1;

  const missingOrderBy = findMissingNumsInSequence([
    0,
    ...localAddonItems.map(({ orderBy }) => orderBy),
  ]);

  if (!missingOrderBy.length) return localAddonItems.length + 1;

  return missingOrderBy[0];
};

export const getVisiblePriceLevels = <
  T extends Pick<ModelMenuAddonItem, "active" | "orderBy">
>(
  priceLevelAddonItems: T[]
): LevelType[] => {
  return priceLevelAddonItems.reduce(
    (levelTypeList: LevelType[], { active, orderBy }, index) =>
      active
        ? [...levelTypeList, `priceLevel${orderBy || index + 1}` as LevelType]
        : levelTypeList,
    []
  );
};

export const getVisiblePriceLevelPrices = (
  visiblePriceLevels: LevelType[],
  topLevelPriceLevelPrices: PriceLevelPrices[]
): PriceLevelPrices[] => {
  const topLevelPriceLevelObjectPrices = Object.fromEntries(
    topLevelPriceLevelPrices
  );

  return visiblePriceLevels.map((priceLevel) => [
    priceLevel,
    topLevelPriceLevelObjectPrices[priceLevel],
  ]);
};

export type PriceLevelType = "price" | "priceLevel" | LevelType;

export interface PriceItemData {
  name: PriceLevelType;
  value: string;
  label: string;
  disabled: boolean;
}

export const generatePriceListData = (
  addonStrategy: AddonStrategyEnum,
  localAddonItem: ModelLocalAddonItem,
  visiblePriceLevels: LevelType[],
  visiblePriceLevelAddonItems: ModelLocalAddonItem[]
): PriceItemData[] | null => {
  switch (addonStrategy) {
    case AddonStrategyEnum.PRICE_LEVEL__CATEGORY_OWNER:
    case AddonStrategyEnum.PRICE_LEVEL__MENU_ITEM_OWNER:
      return null;
    case AddonStrategyEnum.REGULAR__PRICE_LEVEL_DEPENDENT:
      return visiblePriceLevels.length
        ? visiblePriceLevels.map((level, index) => ({
            name: level,
            value: localAddonItem[level],
            label: visiblePriceLevelAddonItems[index].title.slice(0, 3) + ".",
            disabled: localAddonItem[`${level}Disabled` as LevelDisabledType],
          }))
        : [
            {
              name: "price",
              value: localAddonItem.price,
              label: "pr.",
              disabled: false,
            },
          ];
    case AddonStrategyEnum.REGULAR__PRICE_LEVEL_INDEPENDENT:
      return visiblePriceLevels.length
        ? [
            {
              name: "priceLevel",
              value: localAddonItem[visiblePriceLevels[0]],
              label: "pr.",
              disabled:
                localAddonItem[
                  `${visiblePriceLevels[0]}Disabled` as LevelDisabledType
                ],
            },
          ]
        : [
            {
              name: "price",
              value: localAddonItem.price,
              label: "pr.",
              disabled: false,
            },
          ];
    default:
      throw new Error("Wrong addon strategy");
  }
};

export const decomposePriceFields = (
  addonStrategy: AddonStrategyEnum,
  visiblePriceLevels: LevelType[],
  priceFields: any
): any => {
  switch (addonStrategy) {
    case AddonStrategyEnum.PRICE_LEVEL__CATEGORY_OWNER:
    case AddonStrategyEnum.PRICE_LEVEL__MENU_ITEM_OWNER:
      return {};
    case AddonStrategyEnum.REGULAR__PRICE_LEVEL_INDEPENDENT:
      return visiblePriceLevels.length
        ? {
            priceLevel1: priceFields.priceLevel,
            priceLevel2: priceFields.priceLevel,
            priceLevel3: priceFields.priceLevel,
            priceLevel4: priceFields.priceLevel,
            priceLevel5: priceFields.priceLevel,
          }
        : priceFields;
    case AddonStrategyEnum.REGULAR__PRICE_LEVEL_DEPENDENT:
      return priceFields;
    default:
      throw new Error("Wrong addon strategy");
  }
};

export const convertAddonItemsToNew = (
  addonItems: ModelLocalAddonItem[]
): ModelLocalAddonItem[] =>
  addonItems.map((addonItem) => ({
    ...addonItem,
    id: `new-addon-item-${addonItem.id}`,
  }));

export const checkPreparingNewAddonItemAvailable = (
  addonType: AddonTypeEnum,
  addonItemsAmount: number
) => {
  return (
    [
      addonType !== AddonTypeEnum.priceLevel,
      addonItemsAmount < MAX_PRICE_LEVEL_ADDON_ITEMS_AMOUNT,
    ].some(Boolean) && !getIsPartner()
  );
};
