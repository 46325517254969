import { MenuItemImage } from "@model/DAO/MenuItemImage";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { Camera } from "@uiKit/Atoms/SVGIcons";
import React from "react";
import { styles } from "./MenuItemImageSection.styles";
export interface MenuItemImageSectionProps {
  image: MenuItemImage | null;
  itemId: string;
  toggleImageModalOpen: VoidFunction;
  disabled?: boolean;
}

const Image: React.FC<{ image: MenuItemImage | null }> = ({ image }) => {
  if (image?.imageUrl) {
    return <img alt={image.name} src={image.imageUrl} className={styles.img} />;
  }
  return <Camera className={styles.noImage} />;
};

export const MenuItemImageSection: React.FC<MenuItemImageSectionProps> = ({
  image,
  toggleImageModalOpen,
  itemId,
  disabled,
}) => {
  return (
    <>
      {Boolean(disabled) ? (
        <div className={styles.container}>
          <Image image={image} />
        </div>
      ) : (
        <MmsBaseButton
          id={`menu-item-image-section-${itemId}`}
          className={styles.container}
          onClick={toggleImageModalOpen}
        >
          <Image image={image} />
        </MmsBaseButton>
      )}
    </>
  );
};
