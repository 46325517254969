import { css } from "@emotion/css";

export const styles = {
  form: css`
    text-align: left;
    margin-bottom: 10px;
  `,
  message: css`
    margin-bottom: 5px;
  `,
};
