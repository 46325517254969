import { KeyboardKeyEnum } from "@model/helperTypes/forms";
import { useEffect } from "react";

export const useConfirmOnEnterButtonPress = (onConfirm: VoidFunction) => {
  useEffect(() => {
    const pressingEnterKeyListener = (e: KeyboardEvent) => {
      if (e.key !== KeyboardKeyEnum.Enter) return;
      onConfirm();
    };

    window.addEventListener("keypress", pressingEnterKeyListener);
    return () => {
      window.removeEventListener("keypress", pressingEnterKeyListener);
    };
  }, [onConfirm]);
};
