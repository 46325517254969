import { css } from "@emotion/css";

export const styles = {
  cell: css`
    display: flex;
    align-items: center;
  `,
  mainCell: css`
    min-width: 0;
    gap: 10px;

    overflow: hidden;
  `,
};
