import PageLayoutWithMobileHack from "@sharedComponents/PageLayout/PageLayout";
import React from "react";
import { Helmet } from "react-helmet-async";
import { PageNotFoundContent } from "./Content/Content";
import { styles } from "./PageNotFound.styles";

export const PageNotFound = () => {
  return (
    <PageLayoutWithMobileHack className={styles.container}>
      <>
        <Helmet>
          <title>Page is not found</title>
        </Helmet>
        <PageNotFoundContent />
      </>
    </PageLayoutWithMobileHack>
  );
};
