import { ApolloError, useReactiveVar } from "@apollo/client";
import { DEFAULT_PUBLISH_ERRORS } from "@constants";
import { ParsePublishValidationErrorsResult } from "@model/helperTypes/errors";
import {
  menuCategoriesVar,
  publishValidationErrorsVar,
} from "@utils/apolloReactiveVars";
import { useCallback, useState } from "react";
import { extractCategoriesItemsIdsFromCategoryList } from "../utils/Category";
import { parsePublishValidationErrors } from "../utils/Menu";

type PublishErrorsCallbacksType = {
  setErrors: (error: ApolloError) => void;
  clearErrors: VoidFunction;
};

type PublishErrorsTuple = [
  ParsePublishValidationErrorsResult,
  string | undefined,
  PublishErrorsCallbacksType
];

export const usePublishValidationErrors = (): PublishErrorsTuple => {
  const categories = useReactiveVar(menuCategoriesVar);
  const categoriesItemsIds = extractCategoriesItemsIdsFromCategoryList(
    categories
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const setErrors = useCallback(
    (errors: ApolloError) => {
      publishValidationErrorsVar(
        parsePublishValidationErrors(categoriesItemsIds, errors)
      );
      setErrorMessage(errors?.message);
    },
    [categoriesItemsIds]
  );

  const clearErrors = useCallback(() => {
    publishValidationErrorsVar(DEFAULT_PUBLISH_ERRORS);
    setErrorMessage(undefined);
  }, []);

  return [
    useReactiveVar(publishValidationErrorsVar),
    errorMessage,
    { setErrors, clearErrors },
  ];
};
