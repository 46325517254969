import { cx } from "@emotion/css";
import { useHiddenText } from "@hooks";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { clearWysiwyg } from "@utils/strings";
import React, { useMemo } from "react";
import { styles } from "./MenuItemTitleSection.styles";
import { useTranslation } from "react-i18next";

interface MenuItemTitleSectionProps {
  item: ModelMenuItem;
  collapsed: boolean;
  className?: string;
  onTitleClick: VoidFunction;
  onSkuClick: VoidFunction;
  onDescriptionClick: VoidFunction;
}

export const MenuItemTitleSection: React.FC<MenuItemTitleSectionProps> = ({
  item,
  collapsed,
  className,
  onTitleClick,
  onSkuClick,
  onDescriptionClick,
  ...rest
}) => {
  const { t } = useTranslation(["common", "menu-item"]);

  const [titleRef, isTooltipOpen] = useHiddenText();

  const description = useMemo(() => {
    // it's possible that item.description is not empty, but after clearWysiwyg fn, it will be empty.
    // So return "No description" only after clearing
    return clearWysiwyg(item.description || "") || t("common:no-description");
  }, [item.description, t]);

  return (
    <div className={styles.container} {...rest}>
      <MmsTooltip title={item.title} disable={!isTooltipOpen}>
        <div
          ref={titleRef}
          className={cx(styles.title, className)}
          onClick={onTitleClick}
          data-testid={`menu-item-title-${item.id}`}
        >
          {item.title}
        </div>
      </MmsTooltip>

      <MmsTooltip
        title={t("menu-item:click-to-add-sku")}
        disable={!isTooltipOpen}
      >
        <div
          className={cx(styles.sku, className)}
          onClick={onSkuClick}
          data-testid={`menu-item-sku-${item.id}`}
        >
          {item.sku ? item.sku : t("menu-item:no-article-number")}
        </div>
      </MmsTooltip>

      <MmsTooltip title={t("common:click-to-add-edit-description")}>
        <div
          className={styles.description(collapsed)}
          onClick={onDescriptionClick}
          data-testid={`menu-item-description-${item.id}`}
        >
          {description}
        </div>
      </MmsTooltip>
    </div>
  );
};
