import { cx } from "@emotion/css";
import React from "react";
import { MmsSwitch, MmsSwitchProps } from "../MmsSwitch";
import { styles } from "./styles";

export interface MmsLabelSwitchProps extends MmsSwitchProps {
  label: string;
  /**
   * width in pixels
   */
  width?: number;
  containerClassName?: string;
  labelClassName?: string;
  inverted?: boolean;
}

export const MmsLabelSwitch: React.FC<MmsLabelSwitchProps> = ({
  label,
  checked,
  onChange,
  width,
  containerClassName,
  labelClassName,
  inverted = false,
  ...restProps
}) => {
  const containerWidth = width ? `${width}px` : "auto";

  return (
    <div
      className={cx(
        "MmsLabelSwitch",
        styles.container(
          inverted,
          checked,
          containerWidth,
          !!restProps.disabled
        ),
        containerClassName
      )}
    >
      <span
        className={cx(styles.label(inverted), labelClassName)}
        onClick={onChange}
      >
        {label}
      </span>
      <MmsSwitch {...restProps} checked={checked} onChange={onChange} />
    </div>
  );
};
