import { useRef, useEffect, RefObject } from "react";

export const useSvgFilter = (id?: string): RefObject<SVGSVGElement> | null => {
  let ref = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (!id) return;
    if (!ref.current) return;

    const filter = ref.current.querySelector("filter");
    const gLayout = ref.current.getElementById("svg-icon");

    if (!filter || !gLayout) return;

    filter.setAttribute("id", id);
    gLayout.setAttribute("filter", `url(#${id})`);
  }, [id]);

  return ref;
};
