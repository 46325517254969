import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  buttonsRow: css`
    gap: 10px;
  `,
  chooseFileButton: css`
    padding: 5px 25px;

    border-radius: ${themeVars.borderRadius};

    background: #efefef;

    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: ${themeColors.mediumGray};
  `,
  imageContainer: css`
    width: 100%;
    justify-content: center;
    padding: 40px 0;
  `,
  imageBox: css`
    position: relative;
    display: flex;
    max-width: 200px;
    min-height: 200px;
    justify-content: center;
  `,
  svgBoxWrapper: css`
    display: flex;
    justify-content: center;
    padding: 40px 0;
  `,
  svgBox: css`
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    justify-content: center;

    border: 10px ${themeColors.cream} solid;
    border-radius: ${themeVars.borderRadius};
  `,
  svg: css`
    width: 40px;
    height: 40px;
  `,
  img: css`
    width: 200px;
    height: 200px;

    border: 10px ${themeColors.cream} solid;
    border-radius: ${themeVars.borderRadius};

    font-size: 12px;
    word-wrap: break-word;
    overflow: hidden;
    object-fit: cover;
  `,

  cancelButton: css`
    width: 15px;
    height: 15px;

    cursor: pointer;
  `,

  deleteIconContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 15px;
    height: 22px;
    width: 22px;

    border-radius: 50%;

    background-color: ${themeColors.white};

    z-index: 1;
  `,

  trashIcon: css`
    width: 15px;
    height: 15px;
  `,
};
