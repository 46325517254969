import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import React from "react";
import { styles } from "./styles";

export interface MmsEmptyContentBannerProps {
  title: string;
  description?: string;
  color?: string;
  className?: string;
}

export const MmsEmptyContentBanner: React.FC<MmsEmptyContentBannerProps> = ({
  title,
  description,
  color = themeColors.mediumGray,
  className,
}) => {
  return (
    <VerticalAlignBetween className={cx(styles.container(color), className)}>
      {title}
      {description && <span>{description}</span>}
    </VerticalAlignBetween>
  );
};
