import { useQuery } from "@apollo/client";
import { useDidUpdate, useErrorHandler } from "@hooks";
import { galleryImagesIdsVar } from "@utils/apolloReactiveVars";
import { produce } from "immer";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  GetRestaurantGalleryQueryTypes,
  GET_RESTAURANT_GALLERY,
} from "../../graphql/queries/GetGallery";
import { MatchParams } from "../../RestaurantMenuPage";

export const useGetGallery = (
  page: number
): [
  boolean,
  GetRestaurantGalleryQueryTypes.GetRestaurantGalleryQuery,
  (page: number) => void
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();

  const {
    loading,
    data,
    fetchMore,
  } = useQuery<GetRestaurantGalleryQueryTypes.GetRestaurantGalleryQuery>(
    GET_RESTAURANT_GALLERY,
    {
      variables: { restaurantId, page },
      notifyOnNetworkStatusChange: true,
      onError,
    }
  );

  const getMore = useCallback(
    (page: number): void => {
      fetchMore({
        variables: { restaurantId, page },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return produce(fetchMoreResult, (draftGalleryObject) => {
            draftGalleryObject.gallery.nodes = [
              ...prev.gallery.nodes,
              ...fetchMoreResult.gallery.nodes,
            ];
          });
        },
      });
    },
    [restaurantId, fetchMore]
  );

  useDidUpdate(() => {
    if (!data) return;
    galleryImagesIdsVar(data.gallery.nodes.map(({ id }) => id));
  }, [data]);

  return [
    loading,
    data as GetRestaurantGalleryQueryTypes.GetRestaurantGalleryQuery,
    getMore,
  ];
};
