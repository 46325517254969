import { gql } from "@apollo/client";

export const TRANSFER_MENU_ITEM = gql`
  mutation TransferMenuItemMutation($input: TransferItemData!) {
    mmsTransferItem(input: $input)
  }
`;

export const generateTransferMenuItemVars = (
  restaurantId: string,
  menuItemId: string,
  categoryIdToTransfer: string
) => ({
  input: {
    restaurantId,
    itemId: menuItemId,
    destinationId: categoryIdToTransfer,
  },
});

export * as TransferMenuItemMutationTypes from "./__generated__/TransferMenuItemMutation";
