import { SizeType } from "./types";

export const getMeasureDependsOnSize = (size: SizeType) => {
  switch (size) {
    case "small":
      return 16;
    case "medium":
      return 24;
    case "large":
      return 32;
    case "extra-large":
      return 96;
    default:
      return 32;
  }
};
