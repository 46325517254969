import { cx } from "@emotion/css";
import { MmsSkeleton } from "@uiKit/Atoms/Skeleton/MmsSkeleton";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { JustifyContentStartLayout } from "@uiKit/Layouts/JustifyContentStartLayout";
import React from "react";
import { styles } from "./LibraryAssetDesktopSkeletonLayout.styles";

export const LibraryAssetDesktopSkeletonLayout: React.FC = () => {
  return (
    <JustifyContentBetweenLayout>
      <JustifyContentStartLayout
        className={cx(styles.skeleton, styles.mainCell)}
      >
        <MmsSkeleton className={styles.checkboxSkeleton} />
        <MmsSkeleton className={styles.titleSkeleton} />
      </JustifyContentStartLayout>
      <div className={cx(styles.skeleton, styles.controlSectionSkeleton)}>
        <MmsSkeleton />
      </div>
    </JustifyContentBetweenLayout>
  );
};
