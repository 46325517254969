import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    display: flex;
    align-items: flex-end;
    justify-content: center;
  `,
  content: css`
    width: 100%;
    min-height: 60px;
    max-height: calc(100vh - 40px);

    border-radius: ${themeVars.borderRadius};

    background-color: white;

    box-shadow: ${themeUtils.boxShadowSettings(20)} ${themeUtils.boxShadowOpacity(0.5)};
    overflow: overlay;

    ${themeUtils.scrollBar}

    &,
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  `,
  handle: css`
    height: 30px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 64px;
      height: 4px;

      border-radius: ${themeVars.borderRadius};

      background-color: ${themeColors.veryLightPink};

      transform: translate(-50%);
    }
  `,
};
