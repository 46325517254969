import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { iconStyles } from "../_common/styles";
import { SizeType } from "../_common/types";

export const styles = {
  icon: (size: SizeType, active: boolean) => css`
    ${iconStyles(size)};
    [class*="LinkSVGIcon"] {
      fill: ${active ? themeColors.lightGreen : themeColors.black};
    }
  `,
};
