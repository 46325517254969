import { useReactiveVar } from "@apollo/client";
import {
  useActiveImportDirection,
  useActiveProgressBars,
  useDidUpdate,
  useErrorCatch,
  useOpenClose,
  useToggle,
} from "@hooks";
import { ModelCategoryWithMenuItemsAmount } from "@model/DAO/MenuCategory";
import { AddonsOwnerEnum } from "@model/helperTypes/Addon";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { YesNoFilterEnum } from "@model/helperTypes/MenuFilters";
import { AddonsSettings } from "@pages/RestaurantMenuPage/components/AddonsSettings/AddonsSettings";
import { AutoenableSettings } from "@pages/RestaurantMenuPage/components/AutoenableSettings/AutoenableSettings";
import { Category } from "@pages/RestaurantMenuPage/components/Category/Category";
import { CategorySkeleton } from "@pages/RestaurantMenuPage/components/Category/CategorySkeleton/CategorySkeleton";
import { useImportFromLibrary } from "@pages/RestaurantMenuPage/hooks/useImportFromLibrary";
import { LocalAddonsReducerStateType } from "@pages/RestaurantMenuPage/hooks/useLocalAddons";
import { CommonSettingsModal } from "@pages/RestaurantMenuPage/modals/CommonSettingsModal/CommonSettingsModal";
import { generateLibraryProgressBarId } from "@pages/RestaurantMenuPage/utils/Library";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import {
  menuFiltersVar,
  shouldExpandAllCategoriesVar,
} from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  useCloneCategory,
  useDestroyCategory,
  useEditCategoryTitle,
  useToggleCategory,
  useUpdateCategoryAddons,
} from "../../gqlHooks/category/mutations";
import {
  useAddAssetToLibrary,
  useForceAddToLibrary,
} from "../../gqlHooks/library/mutations";
import { addUniqThrottledSuccessRegularNotification } from "../../hooks/useSuccessNotifications";
import { LibraryModal } from "../../modals/LibraryModal/LibraryModal";
import { CategorySettingsContainer } from "../CategorySettings/CategorySettings";
import { LibrarySettingsContainer } from "../LibrarySettings/LibrarySettings";
import MenuItemListContainer from "../MenuItemList/MenuItemList";
import { useTranslation } from "react-i18next";
import { MenuItemEditDescriptionModal } from "@pages/RestaurantMenuPage/modals/MenuItemEditDescriptionModal/MenuItemEditDescriptionModal";
import { MenuItemEditDescriptionForm } from "@pages/RestaurantMenuPage/components/MenuItem/MenuItemEditDescription/MenuItemEditDescription";
import { clearWysiwyg } from "@utils/strings";
import { useEditCategoryDescription } from "@pages/RestaurantMenuPage/gqlHooks/category/mutations";

export interface CategoryContainerProps {
  category: ModelCategoryWithMenuItemsAmount;
}

export const CategoryContainer: React.FC<CategoryContainerProps> = ({
  category,
}) => {
  const { t } = useTranslation(["common"]);
  const menuFilters = useReactiveVar(menuFiltersVar);
  const isCategoryExpandedInitial = useReactiveVar(
    shouldExpandAllCategoriesVar
  );

  const [
    isLibraryModalOpen,
    openLibraryModal,
    closeLibraryModal,
  ] = useImportFromLibrary(category.id, MenuAssetEnum.category);

  const [
    isDeleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
  ] = useOpenClose(false);

  const [
    isLibraryConfirmationModalOpen,
    openLibraryConfirmationModal,
    closeLibraryConfirmationModal,
  ] = useOpenClose(false);

  const [
    isAddonsSettingModalOpen,
    openAddonsSettingsModal,
    closeAddonsSettingsModal,
  ] = useOpenClose(false);

  const [
    isEditDescriptionModalOpen,
    openEditDescriptionModal,
    closeEditDescriptionModal,
  ] = useOpenClose(false);

  const [
    isCategorySettingsModalOpen,
    openCategorySettingsModal,
    closeCategorySettingsModal,
  ] = useOpenClose(false);

  const [, , removeCategoryProgressBarId] = useActiveProgressBars();
  const [
    isCategoryExpanded,
    toggleCategoryExpanded,
    toggleCategoryExpandedManual,
  ] = useToggle(isCategoryExpandedInitial);

  const [
    isAutoenableSettingsModalOpen,
    openAutoenableSettingsModal,
    closeAutoenableSettingsModal,
  ] = useOpenClose(false);

  const isCategoryGreyedOut = useMemo(() => {
    if (category.active) {
      return menuFilters.activenessPresent === YesNoFilterEnum.No;
    } else {
      return menuFilters.activenessPresent === YesNoFilterEnum.Yes;
    }
  }, [category.active, menuFilters.activenessPresent]);

  const isCategoryTitleHightlighted = useMemo(() => {
    if (menuFilters.titleCont === undefined) return false;
    return category.title
      .toLowerCase()
      .includes(menuFilters.titleCont.toLowerCase());
  }, [category.title, menuFilters.titleCont]);

  const [toggleCategoryMutation, isToggleCategoryLoading] = useToggleCategory(
    category.id,
    !category.active
  );

  const cloneCategoryCompletedCallback = useCallback(() => {
    addUniqThrottledSuccessRegularNotification(
      "Category has been successfully cloned"
    );
  }, []);

  const [cloneCategory, isCloneCategoryLoading] = useCloneCategory(
    category.id,
    cloneCategoryCompletedCallback
  );

  const [
    destroyCategoryMutation,
    isDestroyCategoryLoading,
  ] = useDestroyCategory(category.id);

  const [
    editCategoryTitleMutation,
    isEditCategoryTitleLoading,
  ] = useEditCategoryTitle(category.id);

  const [
    editCategoryDescriptionMutation,
    isEditCategoryDescriptionLoading,
  ] = useEditCategoryDescription(category.id, closeEditDescriptionModal);

  const [
    updateCategoryAddonsMutation,
    isUpdateCategoryAddonsLoading,
    isUpdateCategoryAddonsError,
  ] = useUpdateCategoryAddons(category.id);

  const handleChangeTitle = useCallback(
    (values: any) => {
      editCategoryTitleMutation(values.title);
    },
    [editCategoryTitleMutation]
  );

  const handleChangeDescription = useCallback(
    (values: any) => {
      editCategoryDescriptionMutation(clearWysiwyg(values.description));
    },
    [editCategoryDescriptionMutation]
  );

  const handleUpdateAddons = useCallback(
    (differentAddons: LocalAddonsReducerStateType) => {
      const { newAddons, removedAddons, changedAddons } = differentAddons;
      const isAddonsChanged = [newAddons, removedAddons, changedAddons].some(
        (addons) => addons.length
      );

      if (!isAddonsChanged) return;
      updateCategoryAddonsMutation(differentAddons);
    },
    [updateCategoryAddonsMutation]
  );

  const [
    ,
    setImportDirection,
    cleanImportDirection,
  ] = useActiveImportDirection();

  const [
    addingToLibraryError,
    setAddingToLibraryError,
    libraryCategoryId,
    onCleanAddingToLibraryError,
  ] = useErrorCatch(openLibraryConfirmationModal);

  const [
    onSaveCategoryToLibrary,
    categoryAddedToLibraryLoading,
    addedCategoryId,
  ] = useAddAssetToLibrary(
    MenuAssetEnum.category,
    category.id,
    setAddingToLibraryError
  );

  const [
    onForceSaveCategoryLibrary,
    categoryForceAddedToLibraryLoading,
    forceAddedCategoryId,
    onEndForceCategoryAdding,
  ] = useForceAddToLibrary(MenuAssetEnum.category, libraryCategoryId);

  const handleForceSaveToLibrary = useCallback(() => {
    closeLibraryConfirmationModal();
    onForceSaveCategoryLibrary();
  }, [closeLibraryConfirmationModal, onForceSaveCategoryLibrary]);

  const handleMountAddonsSettingsModal = useCallback(() => {
    setImportDirection(category.id, MenuAssetEnum.category);
  }, [category.id, setImportDirection]);

  const handleCloseAddonsSettingsModal = useCallback(() => {
    closeAddonsSettingsModal();
    cleanImportDirection();
  }, [cleanImportDirection, closeAddonsSettingsModal]);

  const handleCloseLibraryConfirmationModal = useCallback(() => {
    closeLibraryConfirmationModal();
    removeCategoryProgressBarId(
      generateLibraryProgressBarId(MenuAssetEnum.category, category.id)
    );
  }, [category.id, closeLibraryConfirmationModal, removeCategoryProgressBarId]);

  const handleToggleCategory = useCallback(() => {
    category.active ? openAutoenableSettingsModal() : toggleCategoryMutation(0);
  }, [category.active, openAutoenableSettingsModal, toggleCategoryMutation]);

  const finishAutoenable = useCallback(
    (autoenableTimestamp: number) => {
      toggleCategoryMutation(autoenableTimestamp);
      closeAutoenableSettingsModal();
    },
    [closeAutoenableSettingsModal, toggleCategoryMutation]
  );

  const handleSkipAutoenable = useCallback(() => finishAutoenable(0), [
    finishAutoenable,
  ]);

  const handleSaveAutoenable = useCallback(
    (autoenableDateTime: Date) =>
      finishAutoenable(autoenableDateTime.getTime()),
    [finishAutoenable]
  );

  useDidUpdate(() => {
    if (isDestroyCategoryLoading) return;
    closeDeleteConfirmationModal();
  }, [isDestroyCategoryLoading]);

  useEffect(() => {
    if (
      (!categoryAddedToLibraryLoading && addedCategoryId) ||
      (!categoryForceAddedToLibraryLoading && forceAddedCategoryId)
    ) {
      addUniqThrottledSuccessRegularNotification(
        "The asset was successfully added to library!"
      );
      handleCloseLibraryConfirmationModal();
      onEndForceCategoryAdding();
      onCleanAddingToLibraryError();
    }
  }, [
    categoryAddedToLibraryLoading,
    addedCategoryId,
    categoryForceAddedToLibraryLoading,
    forceAddedCategoryId,
    handleCloseLibraryConfirmationModal,
    onEndForceCategoryAdding,
    onCleanAddingToLibraryError,
  ]);

  return (
    <>
      <Category
        category={category}
        onToggle={isToggleCategoryLoading ? noop : handleToggleCategory}
        onDelete={isDestroyCategoryLoading ? noop : openDeleteConfirmationModal}
        onClone={isCloneCategoryLoading ? noop : cloneCategory}
        onShowCommonSettings={openCategorySettingsModal}
        onShowAddons={openAddonsSettingsModal}
        onSave={onSaveCategoryToLibrary}
        onImport={openLibraryModal}
        isActive={category.active}
        onToggleCategoryExpanded={toggleCategoryExpanded}
        onToggleCategoryExpandedManual={toggleCategoryExpandedManual}
        onDescriptionClick={openEditDescriptionModal}
        isCategoryExpanded={isCategoryExpanded}
        onChangeTitle={handleChangeTitle}
        isEditTitleLoading={isEditCategoryTitleLoading}
        isGreyedOut={isCategoryGreyedOut}
        isTitleHighlighted={isCategoryTitleHightlighted}
        modals={
          <>
            {isEditDescriptionModalOpen && (
              <MenuItemEditDescriptionModal
                closeModal={closeEditDescriptionModal}
              >
                <MenuItemEditDescriptionForm
                  menuItemId={category.id}
                  isFormSubmitting={isEditCategoryDescriptionLoading}
                  onSubmit={handleChangeDescription}
                  onCancelClick={closeEditDescriptionModal}
                  onSubmitEnded={closeEditDescriptionModal}
                  description={clearWysiwyg(category.description || "")}
                />
              </MenuItemEditDescriptionModal>
            )}
            {isAddonsSettingModalOpen && (
              <AddonsSettings
                parentId={category.id}
                parentTitle={category.title}
                addons={category.addons}
                addonsOwner={AddonsOwnerEnum.category}
                onCancel={handleCloseAddonsSettingsModal}
                onUpdate={handleUpdateAddons}
                onAddonsSettingModalOpen={handleMountAddonsSettingsModal}
                isUpdateProcessing={isUpdateCategoryAddonsLoading}
                isAddonsAttachedToMenuItem={false}
                toggleAttachAddonsToMenuItem={noop}
                isError={[isUpdateCategoryAddonsError].some(Boolean)}
              />
            )}
            {isLibraryModalOpen && (
              <LibraryModal closeModal={closeLibraryModal}>
                <LibrarySettingsContainer onFinish={closeLibraryModal} />
              </LibraryModal>
            )}
            {isLibraryConfirmationModalOpen && (
              <MmsConfirmationModal
                message={addingToLibraryError}
                confirmBtnText={t("common:update")}
                declineBtnText={t("common:cancel")}
                onConfirmBtnClick={handleForceSaveToLibrary}
                onDeclineBtnClick={handleCloseLibraryConfirmationModal}
              />
            )}
            {isDeleteConfirmationModalOpen && (
              <MmsConfirmationModal
                message={t("common:are-you-sure-you-want-to-delete", {
                  title: category.title,
                })}
                confirmBtnText={t("common:delete")}
                declineBtnText={t("common:cancel")}
                isDataProcessing={isDestroyCategoryLoading}
                onConfirmBtnClick={destroyCategoryMutation}
                onDeclineBtnClick={closeDeleteConfirmationModal}
              />
            )}
            {isCategorySettingsModalOpen && (
              <CommonSettingsModal closeModal={closeCategorySettingsModal}>
                <CategorySettingsContainer
                  category={category}
                  closeCategorySettingsModal={closeCategorySettingsModal}
                />
              </CommonSettingsModal>
            )}
            {isAutoenableSettingsModalOpen && (
              <AutoenableSettings
                isAutoenableLoading={isToggleCategoryLoading}
                uniqId="toggle-category-autoenable-settings"
                onSkip={handleSkipAutoenable}
                onSave={handleSaveAutoenable}
              />
            )}
          </>
        }
      >
        {isCategoryExpanded && (
          <MenuItemListContainer parentCategory={category} />
        )}
      </Category>
      {isCloneCategoryLoading && <CategorySkeleton />}
    </>
  );
};
