import { useCallback, useMemo, useState, useEffect } from "react";
import { Library } from "@model/DAO/Library";
import { useWillUnmount } from "@hooks";
import { AssetTabIndexes } from "@model/helperTypes/LibraryAsset";
import { LibraryTabsReturnDataType } from "@model/helperTypes/LibraryTabs";
import { head } from "ramda";

export const useSelectedLibraryTabs = (
  data: Library
): LibraryTabsReturnDataType => {
  const [selectedAssetTabId, setSelectedAssetTab] = useState<number>(-1);
  const [selectedNodeTabId, setSelectedNodeTab] = useState<number>(-1);

  const selectedAssetTab = useMemo(() => {
    return Object.keys(AssetTabIndexes).find(
      (key) =>
        AssetTabIndexes[key as keyof typeof AssetTabIndexes] ===
        selectedAssetTabId
    );
  }, [selectedAssetTabId]);

  const onSelectAssetTab = useCallback(
    (id: number) => {
      setSelectedAssetTab(id);

      const tabKey = Object.keys(AssetTabIndexes).find(
        (key) => AssetTabIndexes[key as keyof typeof AssetTabIndexes] === id
      );

      const temp = data[tabKey as keyof Omit<Library, "__typename">];
      temp?.nodes && setSelectedNodeTab((head(temp?.nodes) as any)?.id || "");
    },
    [setSelectedNodeTab, data]
  );

  const onClean = useCallback(() => {
    setSelectedAssetTab(-1);
    setSelectedNodeTab(-1);
  }, []);

  useEffect(() => {
    if (selectedAssetTabId !== -1) return;
    const [, key] = Object.keys(data);
    if (!key) return;
    onSelectAssetTab(AssetTabIndexes[key as keyof typeof AssetTabIndexes]);
  }, [data, onSelectAssetTab, selectedAssetTabId]);

  useWillUnmount(onClean);

  return {
    selectedAssetTypeId: selectedAssetTabId,
    selectedNodeTabId: selectedNodeTabId.toString(),
    selectedAssetTabName: selectedAssetTab as keyof Omit<Library, "__typename">,
    callbacks: {
      onSelectAssetType: onSelectAssetTab,
      onSelectNode: setSelectedNodeTab,
    },
  };
};
