import { useReactiveVar } from "@apollo/client";
import { PARTNER_BANNER } from "@constants";
import { useDidMount, useOpenClose } from "@hooks";
import PageLayoutWithMobileHack from "@sharedComponents/PageLayout/PageLayout";
import { MmsOverlay } from "@uiKit/Atoms/Overlay";
import {
  iwaiterIdVar,
  restaurantCachedDataVar,
  restaurantCurrencyVar,
} from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import Cookies from "js-cookie";
import React, { useCallback, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { PartnerBanner } from "./components/PartnerBanner/PartnerBanner";
import CategoryListContainer from "./containers/CategoryList/CategoryList";
import { MenuFooterContainer } from "./containers/MenuFooter/MenuFooter";
import { MenuHeaderContainer } from "./containers/MenuHeader/MenuHeader";
import { useGetCountryAdditives } from "./gqlHooks/additives/queries";
import { useGetCountryAllergens } from "./gqlHooks/allergens/queries";
import { useGetMenuFlags } from "./gqlHooks/menuflags/queries";
import { useGetCountryTaxes } from "./gqlHooks/taxes/queries";
import { withEssentialData } from "./withEssentialData";
import { LogoSpinner } from "@uiKit/Atoms/Spinner/Spinner.stories";

export interface MatchParams {
  restaurantId: string;
}

interface RestaurantMenuPageProps {}

const RestaurantMenuPage: React.FC<RestaurantMenuPageProps> = () => {
  useGetCountryTaxes();
  useGetCountryAllergens();
  useGetCountryAdditives();
  useGetMenuFlags();
  const restaurant = useReactiveVar(restaurantCachedDataVar);
  const { currency, iwaiterId, name } = restaurant;

  const [
    isPartnerBannerVisible,
    showPartnerBanner,
    closePartnerBanner,
  ] = useOpenClose(false);

  useDidMount(() => {
    restaurantCurrencyVar(currency);
    iwaiterIdVar(iwaiterId);
  });

  useDidMount(() => {
    if (!getIsPartner()) return;

    const isBannerShownToUser = Cookies.get(PARTNER_BANNER);
    !isBannerShownToUser && showPartnerBanner();
  });

  const closePartnerBannerCallback = useCallback(
    (dontShowAgain: boolean) => {
      Cookies.set(
        PARTNER_BANNER,
        "true",
        { expires: dontShowAgain ? 5500 : 0.5 } // 15 years or 12 hours
      );
      closePartnerBanner();
    },
    [closePartnerBanner]
  );

  return (
    <Suspense fallback={<LogoSpinner />}>
      <PageLayoutWithMobileHack
        header={<MenuHeaderContainer {...restaurant} />}
        footer={<MenuFooterContainer />}
      >
        <Helmet>
          <title>{name}</title>
          <meta name="description" content={name} />
        </Helmet>

        <CategoryListContainer />

        {isPartnerBannerVisible && (
          <MmsOverlay opacity={0.85}>
            <PartnerBanner onContinueClick={closePartnerBannerCallback} />
          </MmsOverlay>
        )}
      </PageLayoutWithMobileHack>
    </Suspense>
  );
};

export default withEssentialData<RestaurantMenuPageProps>(RestaurantMenuPage);
