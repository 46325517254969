export enum PopupNotificationTypesEnum {
  success = "success",
  error = "error",
  info = "info",
}

export type NotificationType = {
  id: string;
  message: string;
  type: PopupNotificationTypesEnum;
};
