import { useDidUpdate, useOpenClose } from "@hooks";
import { ModelRestoredMenu } from "@model/DAO/MenuState";
import {
  useDestroyRestorableMenu,
  useRestoreMenu,
} from "@pages/RestaurantMenuPage/gqlHooks/history/mutations";
import { addUniqThrottledSuccessRegularNotification } from "@pages/RestaurantMenuPage/hooks/useSuccessNotifications";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { menuFiltersVar } from "@utils/apolloReactiveVars";
import React, { useCallback, useState } from "react";
import { HistorySettings } from "../../components/HistorySettings/HistorySettings";
import { withHistory } from "./withHistory";
import { useTranslation } from "react-i18next";

interface HistorySettingsContainerProps {
  history: ModelRestoredMenu[];
  onCancel: VoidFunction;
}

const HistorySettingsContainer: React.FC<HistorySettingsContainerProps> = ({
  history,
  onCancel,
}) => {
  const { t } = useTranslation(["common", "history"]);
  const [currentMenu, setCurrentMenu] = useState<ModelRestoredMenu | null>(
    history[0] || null
  );

  const [
    isDeleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
  ] = useOpenClose(false);

  const [
    onRestoreMenu,
    restoreMenuLoading,
    restoredMenuData,
  ] = useRestoreMenu();

  const onEndMenuDeleting = useCallback(() => {
    closeDeleteConfirmationModal();
    addUniqThrottledSuccessRegularNotification(
      t("history:menu-has-been-successfully-deleted-from-history")
    );
    history.length ? setCurrentMenu(history[0]) : setCurrentMenu(null);
  }, [closeDeleteConfirmationModal, history, t]);

  const [onDeleteMenu, deleteMenuLoading] = useDestroyRestorableMenu(
    onEndMenuDeleting
  );

  const handleChange = useCallback(
    (currentId) => {
      const selectedMenu = history.find(({ id }) => id === currentId);
      if (!selectedMenu) return;
      setCurrentMenu(selectedMenu);
    },
    [history]
  );

  const handleDelete = useCallback(() => {
    if (!currentMenu) return;
    onDeleteMenu(currentMenu.id);
  }, [currentMenu, onDeleteMenu]);

  const handleRestore = useCallback(() => {
    if (!currentMenu) return;
    onRestoreMenu(currentMenu.id);
  }, [currentMenu, onRestoreMenu]);

  useDidUpdate(() => {
    if (restoreMenuLoading) return;
    if (!restoredMenuData) return;

    onCancel();
    menuFiltersVar({});
    addUniqThrottledSuccessRegularNotification(
      t("history:menu-successfully-restored")
    );
  }, [restoreMenuLoading, restoredMenuData]);

  return (
    <HistorySettings
      history={history}
      selectedMenu={currentMenu}
      onChange={handleChange}
      onCancel={onCancel}
      onDelete={openDeleteConfirmationModal}
      onRestore={handleRestore}
      isRestoreProcessing={restoreMenuLoading}
      modals={
        <>
          {isDeleteConfirmationModalOpen && (
            <MmsConfirmationModal
              message={t("common:are-you-sure-you-want-to-delete", {
                title: currentMenu?.title,
              })}
              confirmBtnText={t("common:delete")}
              declineBtnText={t("common:cancel")}
              isDataProcessing={deleteMenuLoading}
              onConfirmBtnClick={handleDelete}
              onDeclineBtnClick={closeDeleteConfirmationModal}
            />
          )}
        </>
      }
    />
  );
};

export default withHistory(HistorySettingsContainer);
