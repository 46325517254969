import { useHiddenText } from "@hooks";
import { BreadcrumbType } from "@model/helperTypes/Breadcrumb";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./HeaderBreadcrumbItem.styles";

interface HeaderBreadcrumbItemProps {
  breadcrumbData: BreadcrumbType;
  restaurantStatus: string | null;
}

export const HeaderBreadcrumbItem: React.FC<HeaderBreadcrumbItemProps> = ({
  breadcrumbData,
  restaurantStatus,
}) => {
  const { name: breadcrumbName, label } = breadcrumbData;
  const [restaurantTitleRef, isRestaurantTooltipOpen] = useHiddenText();

  if (breadcrumbName === "restaurantName") {
    return (
      <MmsTooltip title={label} disable={!isRestaurantTooltipOpen}>
        <JustifyContentBetweenLayout>
          <div ref={restaurantTitleRef} className={styles.menuName}>
            {label}&nbsp;
          </div>
          <div>{restaurantStatus ? `(${restaurantStatus})` : ""}</div>
        </JustifyContentBetweenLayout>
      </MmsTooltip>
    );
  }
  return (
    <span /* mobile-view */ /* className={styles.breadcrumbTitle} */>
      {label}
    </span>
  );
};
