import { css } from "@emotion/css";

export const styles = {
  controlsContainer: css`
    display: flex;

    @media (max-width: 900px) {
      flex-wrap: wrap;
      min-width: 90px;
    }
  `,
  baseControl: css`
    margin: 7px;
  `,
  progressBar: css`
    bottom: -5px;
  `,
};
