import { ModelRestaurant } from "@model/DAO/Restaurant";
import PageLayoutWithMobileHack from "@sharedComponents/PageLayout/PageLayout";
import React from "react";
import { Helmet } from "react-helmet-async";
import { ReleaseNotesHeader } from "./components/Header/Header";
import ReleaseList from "./components/ReleaseList/ReleaseList";
import { IReleaseNote, withEssentialData } from "./withEssentialData";

interface ReleaseNotesPageProps {
  restaurant: ModelRestaurant;
  releases: IReleaseNote[];
}

const ReleaseNotesPage: React.FC<ReleaseNotesPageProps> = ({
  restaurant,
  releases,
}) => {
  return (
    <PageLayoutWithMobileHack header={<ReleaseNotesHeader {...restaurant} />}>
      <Helmet>
        <title>Release Notes</title>
      </Helmet>
      <ReleaseList releases={releases} />
    </PageLayoutWithMobileHack>
  );
};

export default withEssentialData(ReleaseNotesPage);
