import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { PARTNER_TOKEN } from "@constants";
import { KnownErrorCodesEnum } from "@model/helperTypes/errors";
import { JWT_TOKEN_KEY } from "@pages/Auth/constants/localStorageKeys";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "js-cookie";
import {
  getAdminApiUrl,
  getIsPartner,
  getPartnerApiUrl,
  getRestaurantIdFromPath,
} from "./url";

const isPartner = getIsPartner();

const errorLink = onError(({ graphQLErrors }) => {
  if (!graphQLErrors) return;

  graphQLErrors.forEach(({ extensions }) => {
    if (extensions?.code !== KnownErrorCodesEnum.unauthenticated) return;

    window.location.href = process.env.REACT_APP_MMS2_CURRENT_ADMIN_APP_URL!;
  });
});

const httpLink = (createUploadLink({
  uri: isPartner ? getPartnerApiUrl() : getAdminApiUrl(),
  // credentials: isPartner ? "include" : "",
}) as unknown) as ApolloLink;

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: isPartner
      ? {
          ...headers,
          Authorization: `Bearer ${Cookies.get(PARTNER_TOKEN)}`,
          RestaurantId: getRestaurantIdFromPath(),
        }
      : {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN_KEY) || ""}`,
          RestaurantId: getRestaurantIdFromPath(),
        },
  }));

  return forward(operation);
});

export const createApolloClient = () => {
  return new ApolloClient({
    link: from([errorLink, authMiddleware, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            category(_, { args, toReference }) {
              return toReference({
                __typename: "MenuCategory",
                id: args!.id,
              });
            },
          },
        },
      },
    }),
  });
};
