import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface DefaultModalPaperProps {
  children: React.ReactNode;
  className?: string;
}

export const DefaultModalPaper: React.FC<DefaultModalPaperProps> = ({
  className,
  children,
}) => <div className={cx(styles, className)}>{children}</div>;
