import { css } from "@emotion/css";

export const styles = {
  container: css`
    width: 100%;
    padding: 37px 30px;
    gap: 15px;
  `,
  title: css`
    margin: 0;

    font-size: 20px;
    font-weight: bold;
  `,
  infoSection: css`
    min-height: 0;
    gap: 5px;

    font-weight: 300;

    b {
      font-weight: bold;
    }
  `,
};
