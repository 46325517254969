import { themeColors } from "@theme/colors";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { Play, PlayBack } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { MmsPopper, MmsPopperProps } from "@uiKit/Molecules/Popper/MmsPopper";
import React, { useCallback } from "react";
import { styles } from "./PublishingSettingsPopper.styles";

export interface PublishingSettingsPopperProps {
  id: string;
  className: string;
  anchorEl: MmsPopperProps["anchorEl"];
  onPublish: VoidFunction;
  onPublishWithHistory: VoidFunction;
  onClose: VoidFunction;
}

export const PublishingSettingsPopper: React.FC<PublishingSettingsPopperProps> = ({
  id,
  className,
  anchorEl,
  onPublish,
  onPublishWithHistory,
  onClose,
}) => {
  const handlePublish = useCallback(() => {
    onPublish();
    onClose();
  }, [onClose, onPublish]);

  const handlePublishWithHistory = useCallback(() => {
    onPublishWithHistory();
    onClose();
  }, [onClose, onPublishWithHistory]);

  return (
    <MmsPopper
      id={`publish-menu-popper-${id}`}
      className={className}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <VerticalAlignBetween className={styles.container}>
        <MmsBaseButton
          id={`publish-menu-${id}-btn`}
          className={styles.btnText}
          onClick={handlePublish}
        >
          <JustifyContentBetweenLayout className={styles.button}>
            <Play
              id={`publish-menu-${id}-btn-icon`}
              size="small"
              color={themeColors.black}
            />
            <div className={styles.btnText}>Publish</div>
          </JustifyContentBetweenLayout>
        </MmsBaseButton>
        <MmsBaseButton
          id={`publish-menu-${id}-with-history-btn`}
          className={styles.btnText}
          onClick={handlePublishWithHistory}
        >
          <JustifyContentBetweenLayout className={styles.button}>
            <PlayBack
              id={`publish-menu-${id}-with-history-btn-icon`}
              size="small"
            />
            <div className={styles.btnText}>Publish with History</div>
          </JustifyContentBetweenLayout>
        </MmsBaseButton>
      </VerticalAlignBetween>
    </MmsPopper>
  );
};
