import { MenuItemPricesData } from "@model/helperTypes/Prices";

export function mapPricesForApi(data: MenuItemPricesData) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) =>
      key.startsWith("price") && typeof value === "string"
        ? [key, parseFloat(value)]
        : [key, value]
    )
  );
}
