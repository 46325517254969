import { gql } from "@apollo/client";
import { CATEGORY_MENU_ITEMS } from "../../fragments/MenuItem";

export const GET_ALL_CATEGORIES_MENU_ITEMS = gql`
  query GetAllCategoriesMenuItemsQuery($menuId: ID!, $filters: MenuFilter) {
    menu(id: $menuId, q: $filters) {
      categories {
        ...CategoryMenuItems
      }
    }
  }
  ${CATEGORY_MENU_ITEMS}
`;

export * as GetAllCategoriesMenuItemsTypes from "./__generated__/GetAllCategoriesMenuItemsQuery";
