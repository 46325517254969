import { css } from "@emotion/css";

export const styles = {
  baseControl: css`
    padding: 0 7px;
  `,
  logsControl: css`
    display: flex;
  `,
  progressBar: css`
    bottom: -5px;
  `,
  libraryPopper: css`
    z-index: 1300;
  `,
};
