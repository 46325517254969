import { css } from "@emotion/css";

export const styles = (color: string) => css`
  margin: 0;

  color: ${color};
  font-size: 20px;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
