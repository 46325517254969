import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

export const styles = {
  container: (error: boolean) => css`
    position: relative;
    ${error && "border: 2px solid $pink !important;"}

    .rdw-editor-toolbar {
      margin: 0 -15px;
      padding: 0;
      border: none;
      background-color: ${themeColors.white};

      > div {
        padding: 0 10px;
        border-right: 1px solid ${themeColors.borderLightGrey};

        &:last-child {
          border-right: none;
        }

        .rdw-option-wrapper {
          background-color: transparent;
          border-color: transparent;
        }

        .rdw-dropdown-wrapper {
          background-color: transparent;
          border-color: transparent;
          min-width: 60px;

          .rdw-dropdown-selectedtext {
            justify-content: center;
          }

          .rdw-dropdown-carettoopen {
            border-top-color: ${themeColors.borderLightGrey};
          }

          .rdw-dropdown-optionwrapper {
            width: 140%;
          }

          a {
            color: ${themeColors.borderLightGrey};
          }
        }
      }
    }

    .rdw-editor-main {
      min-height: 240px;
      max-height: 240px;
      background-color: #fff;

      ${themeUtils.scrollBar}
    }

    .rdw-link-modal-target-option {
      display: none;
    }

    .public-DraftEditor-content {
      min-height: 38px;
    }
  `,
  maxLength: css`
    position: absolute;
    right: 27px;
    bottom: 7px;
    z-index: 1;
  `,
};
