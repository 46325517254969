import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  root: css`
    margin: 5px 0 !important;

    border-radius: ${themeVars.borderRadius}; ;
  `,
  title: css`
    margin-bottom: 5px;

    font-size: 20px;
    font-weight: bold;

    &.MuiAccordionSummary-root {
      padding: 0 20px;

      border-bottom: 1px solid ${themeColors.lightGrey};
    }

    .MuiAccordionSummary-content {
      margin: 20px 0;
    }
  `,
  body: css`
    color: ${themeColors.darkGrey};

    ul,
    ol {
      margin-left: 30px;
      margin-bottom: 20px;

      list-style: revert;
    }
    li {
      margin-bottom: 7px;
    }
  `,
};
