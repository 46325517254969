import { gql } from "@apollo/client";
import { MENU_ADDON_ESSENTIAL_DATA } from "./MenuAddon";
import { TAX_ASSOCIATION_ESSENTIAL_DATA } from "./Taxes";

export const CATEGORY_ESSENTIAL_DATA = gql`
  fragment CategoryEssentialData on MenuCategory {
    id
    iwaiterId
    title
    description
    active
    activeFrom
    activeTo
    disabledOnDays
    discount
    takeawayDiscount
    deliveryDiscount
    isFreeItem
    minBasketValue
    menuFlags {
      ...MenuFlagEssentialData
    }
    addons {
      ...MenuAddonEssentialData
    }
    orderBy
    items {
      categoryId
      id
    }
    taxAssociations {
      ...TaxAssociationEssentialData
    }
  }
  ${MENU_ADDON_ESSENTIAL_DATA}
  ${TAX_ASSOCIATION_ESSENTIAL_DATA}
`;
