import { PARTNER_TOKEN } from "@constants";
import { useErrorHandler, RestError } from "@hooks";
import { JWT_TOKEN_KEY } from "@pages/Auth/constants/localStorageKeys";
import { getIsPartner, getRestApiUrl } from "@utils/url";
import Cookies from "js-cookie";
import { useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { MatchParams } from "../RestaurantMenuPage";

export const useUploadMultipleImagesToGallery = (): [
  (files: File[]) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onRestError }] = useErrorHandler([]);

  const isLoading = useRef(false);
  const mutationWithVars = useCallback(
    (files: File[]) => {
      isLoading.current = true;

      const formData = new FormData();
      files.forEach((file) => {
        formData.append(`files`, file);
      });

      const token = getIsPartner()
        ? Cookies.get(PARTNER_TOKEN)
        : localStorage.getItem(JWT_TOKEN_KEY);

      fetch(
        `${getRestApiUrl()}/restaurants/${restaurantId}/menu/import/images?api-version=v1`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then(async (response) => {
          if (response.status !== 200) {
            throw new RestError(
              JSON.stringify(await response.json()),
              response.status
            );
          }
          window.location.reload();
        })
        .catch((err) => {
          onRestError(err);
        })
        .finally(() => {
          isLoading.current = false;
        });
    },
    [restaurantId, onRestError]
  );

  return [mutationWithVars, isLoading.current];
};
