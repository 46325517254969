import { ModelLocalAddon } from "@model/DAO/MenuAddon";

export const useSelectedAddon = (
  localAddons: ModelLocalAddon[],
  newAddon: ModelLocalAddon | null,
  selectedTabIndex: number
): ModelLocalAddon | null => {
  // new addon is allways selected
  if (newAddon) return newAddon;
  // there's not a single addon
  if (localAddons.length === 0) return null;
  // selected tab is existing localAddon
  if (selectedTabIndex >= 0 && selectedTabIndex < localAddons.length)
    return localAddons[selectedTabIndex];
  return null;
};
