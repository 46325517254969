import { css } from "@emotion/css";

export const styles = {
  skeleton: css`
    display: flex;
    align-items: center;
    height: 24px;
  `,
  mainCell: css`
    min-width: 0;
    gap: 10px;
  `,
  checkboxSkeleton: css`
    width: 24px;
  `,
  titleSkeleton: css`
    width: 180px;
  `,
  controlSectionSkeleton: css`
    width: 55px;
  `,
};
