import { css } from "@emotion/css";
import { themeUtils } from "@theme/utils";

export const styles = {
  title: css`
    max-width: 20ch;
    margin-top: 1px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${themeUtils.underlineOnHover}
  `,
};
