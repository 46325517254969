import { BreadcrumbType } from "@model/helperTypes/Breadcrumb";
import { MmsLink } from "@uiKit/Atoms/Link/MmsLink";
import React, { Fragment } from "react";
import { styles } from "./styles";

export interface MmsBreadcrumbsProps {
  data: BreadcrumbType[];
  renderBreadcrumbs: (breadcrumb: BreadcrumbType) => React.ReactNode;
}

export const MmsBreadcrumbs: React.FC<MmsBreadcrumbsProps> = ({
  data,
  renderBreadcrumbs,
  ...restBreadcrumbProps
}) => {
  return (
    <div className={styles.container}>
      {data.map((item, index) => {
        const Breadcrumb = renderBreadcrumbs(item);
        return (
          <Fragment key={`${item.name}-${item.href}-${index}`}>
            <MmsLink
              {...restBreadcrumbProps}
              href={item.href || ""}
              className={styles.breadcrumb}
            >
              {Breadcrumb}
            </MmsLink>
            {index !== data.length - 1 && (
              <div className={styles.septum}>{`>`}</div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
