import { gql } from "@apollo/client";
import { ALLERGEN_ESSENTIAL_DATA } from "../../fragments/Allergen";

export const GET_COUNTRY_ALLERGENS = gql`
  query GetCountryAllergensQuery($country: Countries) {
    allergens(country: $country) {
      ...AllergenEssentialData
    }
  }
  ${ALLERGEN_ESSENTIAL_DATA}
`;

export * as GetCountryAllergensTypes from "./__generated__/GetCountryAllergensQuery";
