import { useReactiveVar } from "@apollo/client";
import { useDidUpdate, useOpenClose } from "@hooks";
import { ModelAdditive } from "@model/DAO/Additive";
import { ModelAllergen } from "@model/DAO/Allergen";
import { ModelMenuFlag } from "@model/DAO/MenuFlag";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import {
  MenuItemSettings,
  SettingsTabsEnum,
} from "../../components/MenuItemSettings/MenuItemSettings";
import { useUpdateMenuItemSettings } from "@pages/RestaurantMenuPage/gqlHooks/menuItem/mutations";
import { CommonSettingsModal } from "@pages/RestaurantMenuPage/modals/CommonSettingsModal/CommonSettingsModal";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import {
  countryAdditivesVar,
  countryAllergensVar,
  menuFlagsVar,
} from "@utils/apolloReactiveVars";
import { equals, omit } from "ramda";
import React, { useCallback, useMemo, useState } from "react";
import { TaxAssociationData } from "__generated__/globalTypes";
import { useTranslation } from "react-i18next";
import { MenuItemPricesData } from "@model/helperTypes/Prices";
import { getPriceProperties } from "../../components/MenuItemSettings/PricesForm/utils";
import { separatePriceLevelAddonFromRest } from "../../utils/Addon";
import { ModelFullCategory } from "@model/DAO/MenuCategory";

export interface MenuItemSettingsProps {
  menuItem: ModelMenuItem;
  parentCategory: ModelFullCategory;
  closeMenuItemSettingsModal: VoidFunction;
  initialTab?: SettingsTabsEnum;
}

export const MenuItemSettingsContainer: React.FC<MenuItemSettingsProps> = ({
  menuItem,
  parentCategory,
  closeMenuItemSettingsModal,
  initialTab,
}) => {
  const { t } = useTranslation(["common"]);
  const countryAllergens = useReactiveVar(countryAllergensVar);
  const countryAdditives = useReactiveVar(countryAdditivesVar);
  const menuFlags = useReactiveVar(menuFlagsVar);

  const [, priceLevelAddon] = separatePriceLevelAddonFromRest(
    menuItem.hasOwnAddons ? menuItem.addons : parentCategory.addons
  );

  const [updateMenuItemSettingsMutation, isLoading] = useUpdateMenuItemSettings(
    menuItem.id,
    menuItem.title
  );

  const [localAllergens, setLocalAllergens] = useState<ModelAllergen[]>(
    menuItem.allergens
  );

  const [localAdditives, setLocalAdditives] = useState<ModelAdditive[]>(
    menuItem.additives
  );

  const [localMenuFlags, setLocalMenuFlags] = useState<ModelMenuFlag[]>(
    menuItem.menuFlags
  );

  const [localMenuItemSpiceType, setLocalSpiceType] = useState<number>(
    menuItem.spiceType
  );

  const [localTaxes, setLocalTaxes] = useState<TaxAssociationData[]>(
    menuItem.taxAssociations
  );

  const [localPrices, setLocalPrices] = useState<MenuItemPricesData>(
    getPriceProperties(menuItem)
  );

  const isAllergensChanged = useMemo(
    () => !equals(menuItem.allergens, localAllergens),
    [localAllergens, menuItem.allergens]
  );

  const isAdditivesChanged = useMemo(
    () => !equals(menuItem.additives, localAdditives),
    [localAdditives, menuItem.additives]
  );

  const isMenuFlagsChanged = useMemo(
    () => !equals(menuItem.menuFlags, localMenuFlags),
    [localMenuFlags, menuItem.menuFlags]
  );

  const isSpiceTypeChanged = useMemo(
    () => !equals(menuItem.spiceType, localMenuItemSpiceType),
    [localMenuItemSpiceType, menuItem.spiceType]
  );

  const isTaxesChanged = useMemo(
    () => !equals(menuItem.taxAssociations, localTaxes),
    [menuItem.taxAssociations, localTaxes]
  );

  const isPricesChanged = useMemo(
    () => !equals(getPriceProperties(menuItem), localPrices),
    [menuItem, localPrices]
  );

  const isSomeSettingsChanged = useMemo(
    () =>
      [
        isAllergensChanged,
        isAdditivesChanged,
        isMenuFlagsChanged,
        isSpiceTypeChanged,
        isTaxesChanged,
        isPricesChanged,
      ].some(Boolean),
    [
      isAdditivesChanged,
      isAllergensChanged,
      isMenuFlagsChanged,
      isSpiceTypeChanged,
      isTaxesChanged,
      isPricesChanged,
    ]
  );

  const isSomeUpdatesLoading = useMemo(() => [isLoading].some(Boolean), [
    isLoading,
  ]);

  const updateMenuItemSettings = useCallback(() => {
    if (
      !isAdditivesChanged &&
      !isAllergensChanged &&
      !isMenuFlagsChanged &&
      !isSpiceTypeChanged &&
      !isTaxesChanged &&
      !isPricesChanged
    ) {
      return;
    }

    const additivesIds = localAdditives.map(({ id }) => id);
    const allergenIds = localAllergens.map(({ id }) => id);
    const menuFlagIds = localMenuFlags.map(({ id }) => parseInt(id));
    const taxes = localTaxes.map((item) => {
      return omit(["__typename"], item);
    });

    updateMenuItemSettingsMutation(
      additivesIds,
      allergenIds,
      menuFlagIds,
      localMenuItemSpiceType,
      taxes,
      localPrices
    );
  }, [
    isAdditivesChanged,
    isAllergensChanged,
    isMenuFlagsChanged,
    isSpiceTypeChanged,
    isTaxesChanged,
    isPricesChanged,
    localAdditives,
    localAllergens,
    localMenuFlags,
    localMenuItemSpiceType,
    localTaxes,
    localPrices,
    updateMenuItemSettingsMutation,
  ]);

  const handleUpdateClick = useCallback(() => {
    updateMenuItemSettings();
  }, [updateMenuItemSettings]);

  const [
    isCancelConfirmationModalOpen,
    openCancelConfirmationModal,
    closeCancelConfirmationModal,
  ] = useOpenClose(false);

  const handleCancelClick = useCallback(() => {
    isSomeSettingsChanged
      ? openCancelConfirmationModal()
      : closeMenuItemSettingsModal();
  }, [
    isSomeSettingsChanged,
    closeMenuItemSettingsModal,
    openCancelConfirmationModal,
  ]);

  useDidUpdate(() => {
    if (isSomeUpdatesLoading) return;
    closeMenuItemSettingsModal();
  }, [isSomeUpdatesLoading]);

  return (
    <CommonSettingsModal closeModal={handleCancelClick}>
      <MenuItemSettings
        menuItemId={menuItem.id}
        menuItemTitle={menuItem.title}
        menuItemAllergens={localAllergens}
        menuItemAdditives={localAdditives}
        menuItemFlags={localMenuFlags}
        menuItemSpiceType={localMenuItemSpiceType}
        menuItemAssociatedTaxes={localTaxes}
        menuItemPrices={localPrices}
        menuItemSavedPrices={getPriceProperties(menuItem)}
        menuItemPriceLevelAddon={priceLevelAddon}
        countryAllergens={countryAllergens}
        countryAdditives={countryAdditives}
        menuFlags={menuFlags}
        isUpdateProcessing={isSomeUpdatesLoading}
        isSettingsChanged={isSomeSettingsChanged}
        preserveLocalAllergens={setLocalAllergens}
        preserveLocalAdditives={setLocalAdditives}
        preserveLocalMenuFlags={setLocalMenuFlags}
        preserveLocalSpiceType={setLocalSpiceType}
        preserveLocalTaxes={setLocalTaxes}
        preserveLocalPrices={setLocalPrices}
        onCancelClick={handleCancelClick}
        onUpdateClick={handleUpdateClick}
        isFreeItem={parentCategory.isFreeItem}
        initialTab={initialTab}
      />

      {isCancelConfirmationModalOpen && (
        <MmsConfirmationModal
          message={t(
            "common:if-you-close-this-modal-all-your-changes-will-be-lost"
          )}
          confirmBtnText={t("common:close-anyway")}
          declineBtnText={t("common:stay")}
          onConfirmBtnClick={closeMenuItemSettingsModal}
          onDeclineBtnClick={closeCancelConfirmationModal}
        />
      )}
    </CommonSettingsModal>
  );
};
