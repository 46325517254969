import { css } from "@emotion/css";
import { themeUtils } from "@theme/utils";

export const styles = {
  clickable: css`
    cursor: pointer;

    ${themeUtils.underlineOnHover}
  `,
};
