import { cx } from "@emotion/css";
import { usePriceEditingMode } from "@hooks";
import React, { useMemo } from "react";
import { styles } from "./styles";

export interface MmsBaseButtonProps {
  id: string;
  className?: string;
  onClick: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
  fullWidth?: boolean;
  keepFocus?: boolean;
  disableTabIndex?: boolean;
}

export const MmsBaseButton = React.forwardRef<
  HTMLButtonElement,
  MmsBaseButtonProps & React.HTMLProps<HTMLButtonElement>
>(function MmsBaseButton(
  { className, children, fullWidth, disableTabIndex = true, ...restProps },
  ref
) {
  const [isEditingMode] = usePriceEditingMode();

  const restPropsEnhanced = useMemo(
    () =>
      isEditingMode || disableTabIndex
        ? { ...restProps, tabIndex: -1 }
        : restProps,
    [disableTabIndex, isEditingMode, restProps]
  );

  return (
    <button
      {...restPropsEnhanced}
      type="button"
      className={cx(styles.default, fullWidth && styles.fullWidth, className)}
      ref={ref}
    >
      {children &&
        React.Children.map(children, (child) => {
          if (!child) return;
          return child;
        })}
    </button>
  );
});
