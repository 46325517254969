import { useReactiveVar } from "@apollo/client";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { useCachedGetMenuItems } from "@pages/RestaurantMenuPage/gqlHooks/menuItem/queries";
import { addUniqThrottledErrorRegularNotification } from "@pages/RestaurantMenuPage/hooks/useErrorRegularNotifications";
import { menuCategoriesVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useDuplicateMenuAssetValidation = () => {
  const categoriesData = useReactiveVar(menuCategoriesVar);
  const [onGetMenuItemsData] = useCachedGetMenuItems();

  const handleCheckCategoryTitle = useCallback(
    (currentTitle: ModelFullCategory["title"]) => {
      const sameCategories = categoriesData.filter(
        ({ title }: any) => title === currentTitle
      );

      if (!sameCategories.length) return;

      addUniqThrottledErrorRegularNotification(
        `${currentTitle} category already exist. Please make sure you are not creating the copy`
      );
    },
    [categoriesData]
  );

  const handleCheckMenuItemTitle = useCallback(
    (
      categoryId: ModelFullCategory["id"],
      currentTitle: ModelMenuItem["title"]
    ) => {
      const menuItemsData = onGetMenuItemsData(categoryId);

      const sameMenuItems = menuItemsData.filter(
        ({ title }: any) => title === currentTitle
      );

      const parentCategoryTitle = categoriesData.find(
        ({ id }) => categoryId === id
      );

      if (sameMenuItems.length <= 1 || !parentCategoryTitle) return;

      addUniqThrottledErrorRegularNotification(
        `${currentTitle} item already exist in the ${parentCategoryTitle.title} category. Please make sure you are not creating the copy`
      );
    },
    [categoriesData, onGetMenuItemsData]
  );

  const onCheckDuplication = useCallback(
    (assetType: MenuAssetEnum, ...restProps: any) => {
      switch (assetType) {
        case MenuAssetEnum.category: {
          handleCheckCategoryTitle.apply(null, restProps);
          return;
        }
        case MenuAssetEnum.menu_item:
        default: {
          handleCheckMenuItemTitle.apply(null, restProps);
        }
      }
    },
    [handleCheckCategoryTitle, handleCheckMenuItemTitle]
  );

  return [onCheckDuplication];
};
