import { gql } from "@apollo/client";

export const AUTH_LOGIN = gql`
  mutation AuthLoginMutation($input: AuthLoginData!) {
    authLogin(input: $input) {
      expiry
      jwtToken
      refreshToken
    }
  }
`;

export * as AuthLoginTypes from "./__generated__/AuthLoginMutation";
