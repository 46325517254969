import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

export const styles = {
  container: css`
    max-width: 100%;
  `,
  title: css`
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${themeUtils.underlineOnHover}
  `,
  sku: css`
    font-size: 12px;
    font-weight: bold;

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${themeUtils.underlineOnHover}
  `,
  description: (collapsed: boolean) => css`
    color: ${themeColors.mediumGray};
    font-size: 12px;

    cursor: pointer;
    ${collapsed &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

    ${themeUtils.underlineOnHover}
  `,
};
