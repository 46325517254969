import { AutoenableModal } from "@pages/RestaurantMenuPage/modals/AutoenableModal/AutoenableModal";
import { add, set } from "date-fns";
import React, { useCallback, useState } from "react";
import { AutoenableForm } from "./AutoenableForm/AutoenableForm";

export interface AutoenableSettingsProps {
  isAutoenableLoading?: boolean;
  uniqId: string;
  onSkip: VoidFunction;
  onSave: (autoenableDateTime: Date) => void;
}

const tomorrow = add(new Date(), {
  days: 1,
});

const tomorrowEightAM = set(tomorrow, {
  hours: 8,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});

export const AutoenableSettings: React.FC<AutoenableSettingsProps> = ({
  isAutoenableLoading = false,
  uniqId,
  onSkip,
  onSave,
}) => {
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(
    tomorrowEightAM
  );
  const [isAnyValidationErrors, setValidationErrors] = useState(false);

  const handleSubmitAutoenableDateTime = useCallback(() => {
    onSave(selectedDateTime);
  }, [onSave, selectedDateTime]);

  return (
    <AutoenableModal
      isAutoenableLoading={isAutoenableLoading}
      isAnyValidationErrors={isAnyValidationErrors}
      onSkip={onSkip}
      onSave={handleSubmitAutoenableDateTime}
    >
      <AutoenableForm
        autoenableDateTime={selectedDateTime}
        uniqId={uniqId}
        onSubmit={setSelectedDateTime}
        onError={setValidationErrors}
      />
    </AutoenableModal>
  );
};
