import { ReactComponent as SVGFilters } from "@assets/SVG/apply-filters.svg";
import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IFiltersProps extends IIconBase {
  color?: string;
}

export const Filters: React.FC<IFiltersProps> = ({
  id,
  size = "medium",
  className,
  color = themeColors.black,
}) => {
  const ref = useSvgFilter(id);
  return (
    <SVGFilters
      ref={ref}
      className={cx(styles.icon(size), className)}
      fill={color}
    />
  );
};
