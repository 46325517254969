import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  button: (disabled: boolean) => css`
    display: flex;
    align-items: center;
    max-width: 100%;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `,
  icon: css`
    display: flex;
    align-items: center;
    margin-right: 8px;
  `,
  title: css`
    color: ${themeColors.lightGreen};
    font-size: 12px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;

    overflow: hidden;
  `,
};
