import React from "react";
import { styles } from "./Layout.styles";

export interface DiscountAndAavailabilityLayoutProps {
  discountSection?: React.ReactElement | boolean;
  availabilitySection?: React.ReactElement | boolean;
}

export const DiscountAndAavailabilityLayout: React.FC<DiscountAndAavailabilityLayoutProps> = ({
  discountSection,
  availabilitySection,
}) => {
  return (
    <>
      {discountSection && (
        <section className={styles.contentSection}>{discountSection}</section>
      )}
      {availabilitySection && (
        <section className={styles.contentSection}>
          {availabilitySection}
        </section>
      )}
    </>
  );
};
