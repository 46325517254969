import { css } from "@emotion/css";

export const styles = {
  container: (textColor: string) => css`
    flex: 1;
    justify-content: center;

    color: ${textColor};
    font-style: italic;
    text-align: center;
  `,
};
