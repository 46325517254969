import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  `,
  field: (isError: boolean, isSpellCheckEnabled: boolean) => css`
    width: 100%;
    ${isSpellCheckEnabled
      ? css`
          height: 38px;
          padding: 4px 12px 0 12px;
        `
      : css`
          padding: 0 12px;
        `}

    border: solid 1px ${isError ? themeColors.red : "transparent"};
    border-radius: ${themeVars.borderRadius};
    outline: 0;

    background-color: ${themeColors.lightGrey};

    font-family: ${themeVars.fontFamily};
    font-size: 14px;
    font-weight: 300;
    line-height: 29px;

    resize: none;
    overflow: hidden;

    &[disabled] {
      background-color: ${themeColors.veryLightPink};
      border-color: ${themeColors.veryLightPink};
    }
  `,
  label: css``,
  error: css`
    position: absolute;
    top: 100%;
    margin-top: 3px;

    color: ${themeColors.red};
    font-size: 12px;
  `,
  warning: css`
    position: absolute;
    top: 100%;
    margin-top: 3px;

    color: ${themeColors.yellow};
    font-size: 12px;
  `,
};
