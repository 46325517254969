import { ModelAdditive } from "@model/DAO/Additive";
import { ModelAllergen } from "@model/DAO/Allergen";
import { ModelMenuFlag } from "@model/DAO/MenuFlag";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { themeColors } from "@theme/colors";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { BtnFlatList } from "@uiKit/Molecules/BtnGroup/BtnFlatList";
import { MmsTab } from "@uiKit/Molecules/Tabs/MmsTab";
import { useMmsTabs } from "@uiKit/Molecules/Tabs/MmsTab/useMmsTabs";
import React, { useMemo } from "react";
import { CommonSettingContentTitle } from "../CommonSettings/ContentTitle/ContentTitle";
import { CommonSettingsLayout } from "../CommonSettings/Layout/Layout";
import { StatusBarLayout } from "../CommonSettings/StatusBarLayout/StatusBarLayout";
import { CommonSettingsTabPanelLayout } from "../CommonSettings/TabPanelLayout";
import { NewStatusBarTitle } from "../NewAddonsSettings/NewStatusBar/Title/Title";
import {
  AdditivesForm,
  AllergensForm,
  MenuFlagsForm,
  SpiceLevelForm,
} from "../MenuItemSettings";
import { styles } from "./MenuItemSettings.styles";
import { Taxes } from "../CategorySettings/Taxes/Taxes";
import { Levels, TaxAssociationData } from "__generated__/globalTypes";
import { countryTaxesVar } from "@utils/apolloReactiveVars";
import { useReactiveVar } from "@apollo/client/react/hooks/useReactiveVar";
import { useTranslation } from "react-i18next";
import { PricesForm } from "./PricesForm/PricesForm";
import { MenuItemPricesData } from "@model/helperTypes/Prices";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { getIsPartner } from "@utils/url";

export interface MenuItemSettingsProps {
  menuItemId: ModelMenuItem["id"];
  menuItemTitle: string;
  menuItemAllergens: ModelAllergen[];
  menuItemAdditives: ModelAdditive[];
  menuItemFlags: ModelMenuFlag[];
  menuItemSpiceType: number;
  menuItemAssociatedTaxes: TaxAssociationData[];
  menuItemPrices: MenuItemPricesData;
  menuItemSavedPrices: MenuItemPricesData;
  menuItemPriceLevelAddon: ModelMenuAddon | undefined;
  countryAllergens: ModelAllergen[];
  countryAdditives: ModelAdditive[];
  menuFlags: ModelMenuFlag[];
  isUpdateProcessing: boolean;
  isSettingsChanged: boolean;
  preserveLocalAllergens: (allergenIds: ModelAllergen[]) => void;
  preserveLocalAdditives: (additiveIds: ModelAdditive[]) => void;
  preserveLocalMenuFlags: (menuFlagIds: ModelMenuFlag[]) => void;
  preserveLocalSpiceType: (spiceType: number) => void;
  preserveLocalTaxes: (newLocalTaxes: TaxAssociationData[]) => void;
  preserveLocalPrices: (newLocalPrices: MenuItemPricesData) => void;
  onCancelClick: VoidFunction;
  onUpdateClick: VoidFunction;
  isFreeItem: boolean | null;
  initialTab?: SettingsTabsEnum;
}

export enum SettingsTabsEnum {
  allergens = "allergens",
  additives = "additives",
  menuflags = "menuflags",
  spicelevel = "spicelevel",
  taxes = "taxes",
  prices = "prices",
}

export const MenuItemSettings: React.FC<MenuItemSettingsProps> = ({
  menuItemId,
  menuItemTitle,
  menuItemAllergens,
  menuItemAdditives,
  menuItemFlags,
  menuItemSpiceType,
  menuItemAssociatedTaxes,
  menuItemPrices,
  menuItemSavedPrices,
  menuItemPriceLevelAddon,
  countryAllergens,
  countryAdditives,
  menuFlags,
  isUpdateProcessing,
  isSettingsChanged,
  preserveLocalAllergens,
  preserveLocalAdditives,
  preserveLocalMenuFlags,
  preserveLocalSpiceType,
  preserveLocalTaxes,
  preserveLocalPrices,
  onCancelClick,
  onUpdateClick,
  isFreeItem,
  initialTab = SettingsTabsEnum.allergens,
}) => {
  const settingsTabPanelsData = [
    {
      tabIndex: 0,
      tabTitle: "Allergens",
      enumValue: SettingsTabsEnum.allergens,
    },
    {
      tabIndex: 1,
      tabTitle: "Additives",
      enumValue: SettingsTabsEnum.additives,
    },
    {
      tabIndex: 2,
      tabTitle: "Menu Flags",
      enumValue: SettingsTabsEnum.menuflags,
    },
    {
      tabIndex: 3,
      tabTitle: "Spice Level",
      enumValue: SettingsTabsEnum.spicelevel,
    },
    ...(!isFreeItem
      ? [
          {
            tabIndex: 4,
            tabTitle: "Taxes Level",
            enumValue: SettingsTabsEnum.taxes,
          },
          {
            tabIndex: 5,
            tabTitle: "Prices",
            enumValue: SettingsTabsEnum.prices,
          },
        ]
      : []),
  ];
  const { t } = useTranslation(["menu", "common"]);
  const initialIndex =
    settingsTabPanelsData.find((x) => x.enumValue === initialTab)?.tabIndex ||
    0;
  const [selectedTabIndex, { selectTab }] = useMmsTabs(
    settingsTabPanelsData[settingsTabPanelsData.length - 1].tabIndex,
    initialIndex
  );
  const countryTaxes = useReactiveVar(countryTaxesVar);

  const selectedTabType: SettingsTabsEnum = useMemo(() => {
    switch (selectedTabIndex) {
      case 0:
        return SettingsTabsEnum.allergens;
      case 1:
        return SettingsTabsEnum.additives;
      case 2:
        return SettingsTabsEnum.menuflags;
      case 3:
        return SettingsTabsEnum.spicelevel;
      case 4:
        return SettingsTabsEnum.taxes;
      case 5:
        return SettingsTabsEnum.prices;
      default:
        throw new Error("MenuItemSettings -> selectedTabType: wrong tab index");
    }
  }, [selectedTabIndex]);

  return (
    <CommonSettingsLayout
      statusBar={
        <StatusBarLayout
          bgColor={themeColors.white}
          leftCol={
            <NewStatusBarTitle
              title={t("common:item_settings", { title: menuItemTitle })}
              color={themeColors.black}
            />
          }
        />
      }
      leftColContent={
        <>
          {settingsTabPanelsData.map(({ tabIndex, tabTitle }) => {
            if (tabIndex === 4 && countryTaxes.length === 0) {
              return null;
            } else if (tabIndex === 5 && getIsPartner()) {
              /* INT-82 - Hide Service Type specific prices UI in Partner Centre */
              return null;
            } else {
              return (
                <MmsTab
                  key={tabIndex}
                  tabIndex={tabIndex}
                  isSelected={tabIndex === selectedTabIndex}
                  onSelect={selectTab}
                >
                  <CommonSettingsTabPanelLayout>
                    {tabTitle}
                  </CommonSettingsTabPanelLayout>
                </MmsTab>
              );
            }
          })}
        </>
      }
      rightColHeaderTitle={
        {
          [SettingsTabsEnum.allergens]: (
            <CommonSettingContentTitle
              uniqId={`allergens-${menuItemId}`}
              title="Allergens"
              isEditModeDisabled
            />
          ),
          [SettingsTabsEnum.additives]: (
            <CommonSettingContentTitle
              uniqId={`additives-${menuItemId}`}
              title="Additives"
              isEditModeDisabled
            />
          ),
          [SettingsTabsEnum.menuflags]: (
            <CommonSettingContentTitle
              uniqId={`menuflags-${menuItemId}`}
              title="Menu Flags"
              isEditModeDisabled
            />
          ),
          [SettingsTabsEnum.spicelevel]: (
            <CommonSettingContentTitle
              uniqId={`spicelevel-${menuItemId}`}
              title="Spice Level"
              isEditModeDisabled
            />
          ),
          [SettingsTabsEnum.taxes]: (
            <CommonSettingContentTitle
              uniqId={`taxeslevel-${menuItemId}`}
              title="Taxes Level"
              isEditModeDisabled
            />
          ),
          [SettingsTabsEnum.prices]: (
            <CommonSettingContentTitle
              uniqId={`prices-${menuItemId}`}
              title="Prices"
              isEditModeDisabled
            />
          ),
        }[selectedTabType]
      }
      rightColContent={
        {
          [SettingsTabsEnum.allergens]: (
            <AllergensForm
              countryAllergens={countryAllergens}
              selectedAllergens={menuItemAllergens}
              onSubmit={preserveLocalAllergens}
            />
          ),
          [SettingsTabsEnum.additives]: (
            <AdditivesForm
              countryAdditives={countryAdditives}
              selectedAdditives={menuItemAdditives}
              onSubmit={preserveLocalAdditives}
            />
          ),
          [SettingsTabsEnum.menuflags]: (
            <MenuFlagsForm
              isAlwaysOpen
              menuFlags={menuFlags}
              selectedMenuFlags={menuItemFlags}
              onSubmit={preserveLocalMenuFlags}
            />
          ),
          [SettingsTabsEnum.spicelevel]: (
            <SpiceLevelForm
              menuSpiceType={menuItemSpiceType}
              onSubmit={preserveLocalSpiceType}
            />
          ),
          [SettingsTabsEnum.taxes]: (
            <Taxes
              associatedTaxes={menuItemAssociatedTaxes}
              preserveLocalTaxes={preserveLocalTaxes}
              uniqId={menuItemId}
              entityType={Levels.MENU_ITEM}
            />
          ),
          [SettingsTabsEnum.prices]: (
            <PricesForm
              pricesData={menuItemPrices}
              savedPricesData={menuItemSavedPrices}
              preserveLocalPrices={preserveLocalPrices}
              menuItemPriceLevelAddon={menuItemPriceLevelAddon}
            />
          ),
        }[selectedTabType]
      }
      rightColFooter={
        <JustifyContentEndLayout className={styles.btnGroup}>
          <BtnFlatList
            list={[
              <MmsSecondaryButton
                id="menu-item-settings-cancel"
                className={styles.commonBtn}
                onClick={onCancelClick}
                disabled={isUpdateProcessing}
              >
                {t("common:cancel")}
              </MmsSecondaryButton>,
              <MmsPrimaryButton
                id="menu-item-settings-update"
                className={styles.commonBtn}
                onClick={onUpdateClick}
                processing={isUpdateProcessing}
                disabled={!isSettingsChanged}
              >
                {t("common:update")}
              </MmsPrimaryButton>,
            ]}
          />
        </JustifyContentEndLayout>
      }
    />
  );
};
