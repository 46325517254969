import { ReactComponent as SVGReapplyFilters } from "@assets/SVG/reapply-filters.svg";
import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import React from "react";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IReapplyFiltersProps extends IIconBase {
  color?: string;
}

export const ReapplyFilters: React.FC<IReapplyFiltersProps> = ({
  size = "medium",
  color = themeColors.black,
  className,
}) => (
  <SVGReapplyFilters
    className={cx(styles.icon(size), className)}
    fill={color}
  />
);
