import { SCREEN_SIZES } from "@constants";
import { css } from "@emotion/css";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-width: 1220px;
    padding: 0 15px;

    border-radius: ${themeVars.borderRadius};

    &:hover .controlsGroupCell {
      visibility: visible;
    }

    @media (max-width: ${SCREEN_SIZES.MD - 1}px) {
      .controlsGroupCell {
        visibility: visible;
      }
    }
  `,
  cell: css`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 80px;
    padding: 5px;
  `,
  checkboxCell: css`
    max-width: 29px;
    padding-right: 0;
  `,
  errorCell: css`
    max-width: 24px;
    padding-left: 0;
    padding-right: 0;
  `,
  titleCell: css`
    max-width: 295px;
    padding-left: 2px;
  `,
  addonsCell: css`
    max-width: 225px;
    position: relative;
  `,
  priceCell: css`
    max-width: 355px;
  `,
  controlsGroupCell: (shouldKeepControlsVisible: boolean) => css`
    max-width: 190px;
    justify-content: flex-end;

    visibility: ${shouldKeepControlsVisible ? "visible" : "hidden"};
  `,
  toggleCell: css`
    max-width: 45px;
    padding-bottom: 7px;
  `,
  chevronCell: css`
    max-width: 45px;
  `,
  itemsAmountCell: css`
    max-width: 16px;
    justify-content: center;
    margin-left: 5px;
    padding-bottom: 6px;

    font-size: 14px;
    font-weight: bold;
  `,
};
