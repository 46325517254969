import { injectGlobal } from "@emotion/css";

injectGlobal`
.MmsMuiTooltip {
  .MuiTooltip-tooltip {
    z-index: 9999;
  }
  .MuiTooltip-arrow {
    z-index: 9999;
  }
}
`;
