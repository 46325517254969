import { makeVar } from "@apollo/client";
import {
  DEFAULT_IMPORT_DIRECTION_VALUE,
  DEFAULT_PUBLISH_ERRORS,
  DEFAULT_SELECTED_ENTITIES_VALUE,
  LOGS_DEFAULT_PAGE,
  SELECTED_IMAGES_DEFAULTS,
} from "@constants";
import { ModelAdditive } from "@model/DAO/Additive";
import { ModelAllergen } from "@model/DAO/Allergen";
import { ModelFullCategory, ModelMainCategory } from "@model/DAO/MenuCategory";
import { ModelMenuFlag } from "@model/DAO/MenuFlag";
import { ModelCategoryMenuItems } from "@model/DAO/MenuItem";
import { ModelRestaurant } from "@model/DAO/Restaurant";
import { TaxModel } from "@model/DAO/MenuItemTax";
import {
  ActiveAddonItemsSettingsType,
  AddonItemEditingModeReactiveItem,
} from "@model/helperTypes/AddonItemsSettings";
import { BulkActionEnum } from "@model/helperTypes/BulkEditing";
import { DraggableEnum } from "@model/helperTypes/dragAndDrop";
import { ParsePublishValidationErrorsResult } from "@model/helperTypes/errors";
import { ActiveFormType } from "@model/helperTypes/forms";
import { ISelectedGalleryImages } from "@model/helperTypes/Gallery";
import { HeaderVisibilityEnum } from "@model/helperTypes/Header";
import { ImportDirectionType } from "@model/helperTypes/LibraryAsset";
import { LogFilters } from "@model/helperTypes/LogFilters";
import { MenuStateEnum } from "@model/helperTypes/Menu";
import { MenuFilters } from "@model/helperTypes/MenuFilters";
import { NotificationType } from "@model/helperTypes/Notification";
import { SelectedEntitiesType } from "@model/helperTypes/SelectedEntities";

export const menuIdVar = makeVar<string>("");
export const restaurantCachedDataVar = makeVar<ModelRestaurant>(
  {} as ModelRestaurant
);
export const activeFormsVar = makeVar<ActiveFormType[]>([]);
export const focusedElemIdVar = makeVar<string | null>(null);
export const activeAddonVar = makeVar<string>("");
export const notificationsVar = makeVar<NotificationType[]>([]);
export const priceEditingModeVar = makeVar<boolean>(false);
export const isAddonsLibraryModalOpenVar = makeVar<boolean>(false);
export const activeAssetsVar = makeVar<{ [key: string]: boolean }>({});
export const activeImportDirectionVar = makeVar<ImportDirectionType>(
  DEFAULT_IMPORT_DIRECTION_VALUE
);
export const activeProgressBarsVar = makeVar<string[]>([]);
export const selectedEntitiesVar = makeVar<SelectedEntitiesType>(
  DEFAULT_SELECTED_ENTITIES_VALUE
);
export const openedCategoiesVar = makeVar<ModelMainCategory["id"][]>([]);
export const bulkActionVar = makeVar<BulkActionEnum>(BulkActionEnum.default);
export const publishValidationErrorsVar = makeVar<ParsePublishValidationErrorsResult>(
  DEFAULT_PUBLISH_ERRORS
);
export const menuFiltersVar = makeVar<MenuFilters>({});
export const logFiltersVar = makeVar<LogFilters>({});
export const logsPageVar = makeVar<number>(LOGS_DEFAULT_PAGE);

export const blockingErrorsVar = makeVar<string[]>([]);
export const regularErrorsVar = makeVar<string[]>([]);
export const successNotificationsVar = makeVar<string[]>([]);

export const restaurantCurrencyVar = makeVar<string>("");
export const iwaiterIdVar = makeVar<number>(-1);
export const headerVisibilityVar = makeVar<HeaderVisibilityEnum>(
  HeaderVisibilityEnum.visible
);

export const screenWidthVar = makeVar<number>(
  document.documentElement.clientWidth
);

export const isMenuCategoriesLoadingVar = makeVar<boolean>(false);
export const menuCategoriesVar = makeVar<ModelFullCategory[]>([]);
export const allCategoriesMenuItemsVar = makeVar<ModelCategoryMenuItems[]>([]);
export const activeAddonItemsSettingsVar = makeVar<ActiveAddonItemsSettingsType>(
  null
);

export const globalLoaderIdListVar = makeVar<string[]>([]);
export const shouldExpandAllCategoriesVar = makeVar<boolean>(false);
export const shouldExpandAllMenuItemsVar = makeVar<boolean>(false);
export const draggingMenuItemParentCategoryVar = makeVar<string>(
  DraggableEnum.dummy
);
export const countryAllergensVar = makeVar<ModelAllergen[]>([]);
export const countryAdditivesVar = makeVar<ModelAdditive[]>([]);
export const menuFlagsVar = makeVar<ModelMenuFlag[]>([]);
export const spiceLevelVar = makeVar<string[]>([]);
export const menuStateVar = makeVar<MenuStateEnum>(MenuStateEnum.showLoader);
export const addonItemEditingModeReactiveListVar = makeVar<
  AddonItemEditingModeReactiveItem[]
>([]);
export const galleryImagesIdsVar = makeVar<string[]>([]);
export const selectedGalleryImagesVar = makeVar<ISelectedGalleryImages>(
  SELECTED_IMAGES_DEFAULTS
);
export const countryTaxesVar = makeVar<TaxModel[]>([]);
