import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface MmsFormTitleProps {
  children: string;
  className?: string;
}

export const MmsFormTitle: React.FC<MmsFormTitleProps> = ({
  children,
  className,
}) => <div className={cx(styles, className)}>{children}</div>;
