import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = css`
  margin-top: 15px;
  margin-bottom: 0;

  color: ${themeColors.yellow};
  font-size: 12px;
`;
