import { gql } from "@apollo/client";

export const GET_DRAFT_MENU = gql`
  query GetDraftMenuQuery($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      name
      iwaiterId
      currency
      country
      status
      menu {
        id
        halalFriendly
        usesPopularItems
      }
      family {
        url
      }
      canDelivery
      canTakeaway
    }
  }
`;

export * as GetDraftMenuTypes from "./__generated__/GetDraftMenuQuery";
