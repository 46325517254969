import TableHead, { TableHeadProps } from "@material-ui/core/TableHead";
import React from "react";
import { MmsTableCell, MmsTableCellProps } from "../MmsTableCell";
import TableRow from "@material-ui/core/TableRow";

interface MmsTableHeadProps {
  className?: TableHeadProps["className"];
  classNameCell?: string;
  colsNames: string[];
  align?: MmsTableCellProps["align"];
}

export const MmsTableHead: React.FC<MmsTableHeadProps> = ({
  className,
  classNameCell,
  colsNames,
  align,
}) => {
  return (
    <TableHead className={className}>
      <TableRow>
        {colsNames.map((name, index) => (
          <MmsTableCell
            key={`${name}-${index}`}
            className={classNameCell}
            align={align}
          >
            {name}
          </MmsTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
