import { KNOWN_PROD_DOMAINS } from "@constants";
import { matchPath } from "react-router-dom";

interface UrlHashValuesObject {
  [key: string]: string;
}

//takes hash part of url and maps to object
//example #code=0.AVwAsFTfDp7cj0-OpAYZzOAhbhqYDq4yJWdBl6Ci-wOZSLpcAAA.AQABAAIAAAD&state=eyJpZCI6ImZmNTk3YzZkLTc3OGQtNGQ0NC1hMTNiLTI2NjZlZWRmYmMxMiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3d&session_state=3d5af20b-448d-41ac-a6d4-f3cd82932a87
export const getHashValues = (urlHash: string): UrlHashValuesObject =>
  urlHash
    .substring(1) // removes # symbol
    .split("&")
    .reduce((res, item) => {
      let parts = item.split("=");
      const [key, value] = parts;
      res[key] = value;
      return res;
    }, {} as UrlHashValuesObject);

// breaks url by / symbol ["", "restaurant", "1", "menu"]
// and takes 3 element which is id of the restaurant
export const getRestaurantIdFromPath = () =>
  window.location.pathname.split("/")[2];

export const getDomainPath = (): string => {
  const { host, protocol } = window.location;
  return `${protocol}//${host}`;
};

export const getIsPartner = (): boolean =>
  Boolean(
    matchPath(window.location.pathname, {
      path: "/partner/:id/menu",
      exact: true,
      strict: true,
    })
  );

export const getRestApiUrl = () => {
  return process.env.REACT_APP_MMS2_RESTAPI_URL;
};

export const getAdminApiUrl = () => {
  const { host } = window.location;

  switch (true) {
    case host.includes(KNOWN_PROD_DOMAINS.YOYO):
      return process.env.REACT_APP_MMS2_GRAPHQL_URL_YOYO;
    case host.includes(KNOWN_PROD_DOMAINS.IWAITER):
    default:
      return process.env.REACT_APP_MMS2_GRAPHQL_URL;
  }
};

export const getPartnerApiUrl = () => {
  const { host } = window.location;

  switch (true) {
    case host.includes(KNOWN_PROD_DOMAINS.YOYO):
      return process.env.REACT_APP_PARTNER_MMS2_GRAPHQL_URL_YOYO;
    case host.includes(KNOWN_PROD_DOMAINS.IWAITER):
    default:
      return process.env.REACT_APP_PARTNER_MMS2_GRAPHQL_URL;
  }
};
