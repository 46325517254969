import { MAX_IMAGE_SIZE } from "@constants";
import { useOpenClose, useToggle, useWillUnmount } from "@hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ModelGalleryImage, ModelMenuGallery } from "@model/DAO/MenuGallery";
import { useSelectedGalleryImages } from "@pages/RestaurantMenuPage/hooks/useSelectedGalleryImages";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsAlert } from "@uiKit/Molecules/Modals/MmsAlert";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { noop } from "@utils/noop";
import React, { useEffect } from "react";
import { GalleryImagesPaginatedList } from "../GalleryImagesPaginatedList/GalleryImagesPaginatedList";
import { styles } from "./MenuItemChooseImageSettings.styles";
import { useTranslation } from "react-i18next";

export interface MenuItemChooseImageSettingsProps {
  onCancel: VoidFunction;
  onDeleteImage: (id: string) => void;
  uploadImages: (files: File[]) => void;
  imageGallery: ModelMenuGallery;
  selectedImage: ModelGalleryImage | null;
  setSelectedImage: (image: ModelGalleryImage) => void;
  onOkButtonPress: VoidFunction;
  fetchMoreGalleryImages: (page: number) => void;
  isUploadLoading: boolean;
  isGalleryLoading: boolean;
}

export const MenuItemChooseImageSettings: React.FC<MenuItemChooseImageSettingsProps> = ({
  onCancel,
  onDeleteImage,
  uploadImages,
  imageGallery,
  selectedImage,
  setSelectedImage,
  onOkButtonPress,
  fetchMoreGalleryImages,
  isUploadLoading,
  isGalleryLoading,
}) => {
  const { t } = useTranslation(["common"]);
  const [
    selectedImagesIds,
    { resetSelectedImages },
  ] = useSelectedGalleryImages();
  const [isOkBtnDisabled, , toggleOkBtnDisabledManual] = useToggle(false);
  const [
    isImageUploadAlertModalOpen,
    openImageUploadAlertModal,
    closeImageUploadAlertModal,
  ] = useOpenClose(false);

  const onFileUpload = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const files: File[] = Array.from(target.files as FileList);

      if (!files.length) return;

      const anyMaxSizeExcess = files.some((file) => file.size > MAX_IMAGE_SIZE);
      if (anyMaxSizeExcess) openImageUploadAlertModal();
      else uploadImages(files);
    },
    [openImageUploadAlertModal, uploadImages]
  );

  useEffect(() => {
    if (selectedImagesIds.length === 1) {
      toggleOkBtnDisabledManual(false);
    } else {
      toggleOkBtnDisabledManual(true);
    }
  }, [selectedImagesIds.length, toggleOkBtnDisabledManual]);

  useWillUnmount(() => {
    resetSelectedImages();
  });

  if (!imageGallery) return null;
  return (
    <>
      <GalleryImagesPaginatedList
        onDeleteImage={onDeleteImage}
        imageGallery={imageGallery}
        isGalleryLoading={isGalleryLoading}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        fetchMoreGalleryImages={fetchMoreGalleryImages}
      />
      <JustifyContentBetweenLayout className={styles.buttonsRow}>
        <MmsSecondaryButton id="upload" onClick={noop}>
          {isUploadLoading ? (
            <div className={styles.loaderContainer}>
              <CircularProgress
                className={styles.loader}
                size={20}
                color={"inherit"}
                thickness={4}
              />
            </div>
          ) : (
            <label className={styles.uploadInputLabel} htmlFor="pic">
              Upload
              <input
                accept="image/gif, image/jpeg, image/png"
                className={styles.uploadInput}
                type="file"
                id="pic"
                onChange={onFileUpload}
                multiple
              />
            </label>
          )}
        </MmsSecondaryButton>
        <JustifyContentBetweenLayout className={styles.buttonGroup}>
          <MmsSecondaryButton id="cancel" onClick={onCancel} fullWidth>
            {t("common:cancel")}
          </MmsSecondaryButton>
          <MmsTooltip
            title={
              "Select one image to proceed. Only one image can be set as item image"
            }
            disable={!isOkBtnDisabled}
          >
            <div>
              <MmsPrimaryButton
                id="ok"
                onClick={onOkButtonPress}
                fullWidth
                disabled={isOkBtnDisabled}
              >
                Ok
              </MmsPrimaryButton>
            </div>
          </MmsTooltip>
        </JustifyContentBetweenLayout>
      </JustifyContentBetweenLayout>

      {isImageUploadAlertModalOpen && (
        <MmsAlert
          message="The size of this image is not supported. Please select an image with size less than 32 MB"
          closeBtnText="OK"
          onCloseBtnClick={closeImageUploadAlertModal}
        />
      )}
    </>
  );
};
