import { css } from "@emotion/css";

export const styles = css`
  display: flex;
  flex-wrap: nowrap;
  gap: 25px;
  align-items: center;

  font-size: 14px;
  font-weight: 300;
`;
