import { useOpenClose } from "@hooks";
import {
  useDestroyScheduleTask,
  useUpdateScheduleTask,
} from "@pages/RestaurantMenuPage/gqlHooks/schedule_tasks/mutations";
import { GetScheduleTasksQuery_scheduleTasks } from "@pages/RestaurantMenuPage/graphql/queries/GetScheduleTasks/__generated__/GetScheduleTasksQuery";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { Pencil, Trash } from "@uiKit/Atoms/SVGIcons";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { MmsTableCell } from "@uiKit/Molecules/Table/MmsTableCell";
import { MmsTableRow } from "@uiKit/Molecules/Table/MmsTableRow";
import dateFnsFormat from "date-fns/format";
import React from "react";
import { styles } from "./ScheduleTasksItem.styles";
import { ScheduleTasksFormModal } from "@pages/RestaurantMenuPage/components/ScheduleTasksSettings/ScheduleTasksFormModal/ScheduleTasksFormModal";
import { useTranslation } from "react-i18next";

interface ScheduleTasksItemProps {
  task: GetScheduleTasksQuery_scheduleTasks;
  classNameCell?: string;
}

export const ScheduleTasksItem: React.FC<ScheduleTasksItemProps> = ({
  task,
  classNameCell,
}) => {
  const { t } = useTranslation(["common", "schedule"]);
  const [destroyTask] = useDestroyScheduleTask(task.id);
  const [updateTask] = useUpdateScheduleTask(task.id);

  const [
    isDeleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
  ] = useOpenClose(false);

  const [isEditModalOpen, openEditModal, closeEditModal] = useOpenClose(false);

  return (
    <MmsTableRow>
      <MmsTableCell className={classNameCell}>{task.entityTitle}</MmsTableCell>
      <MmsTableCell className={classNameCell}>{task.entityType}</MmsTableCell>
      <MmsTableCell className={classNameCell}>{task.userName}</MmsTableCell>
      <MmsTableCell className={classNameCell}>
        <MmsBaseButton
          id={`schedule-task-edit-${task.id}`}
          className={styles.clickable}
          onClick={openEditModal}
        >
          {dateFnsFormat(Date.parse(task.runAt), "dd.MM.yyyy HH:mm")}
        </MmsBaseButton>
      </MmsTableCell>
      <MmsTableCell className={classNameCell} align={"center"}>
        <MmsBaseButton
          onClick={openEditModal}
          id={`schedule-task-edit-pencil-${task.id}`}
        >
          <Pencil id={`schedule-task-edit-${task.id}-icon`} size="small" />
        </MmsBaseButton>
        <MmsBaseButton
          onClick={openDeleteConfirmationModal}
          id={`schedule-task-del-${task.id}`}
        >
          <Trash id={`schedule-task-del-${task.id}-icon`} size="small" />
        </MmsBaseButton>
      </MmsTableCell>

      {isDeleteConfirmationModalOpen && (
        <MmsConfirmationModal
          title={t("schedule:delete-scheduled-update")}
          message={t(
            "schedule:are-you-sure-you-want-to-delete-scheduled-update",
            {
              task: task!.entityTitle,
            }
          )}
          confirmBtnText={t("common:delete")}
          declineBtnText={t("common:cancel")}
          onConfirmBtnClick={destroyTask}
          onDeclineBtnClick={closeDeleteConfirmationModal}
        />
      )}

      {isEditModalOpen && (
        <ScheduleTasksFormModal
          runAt={new Date(task.runAt)}
          onSave={updateTask}
          onSkip={closeEditModal}
        />
      )}
    </MmsTableRow>
  );
};
