import { injectGlobal } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

injectGlobal`
.MmsMultiSelectbox {
  .react-select {
    &__control {
      &, &:hover, &:focus, &:active, &--is-focused {
        border-color: ${themeColors.lightGreen};
      }

      &:hover, &:focus, &:active, &--is-focused {
        box-shadow: 0 0 0 1px ${themeColors.lightGreen};
      }
    }
    &__clear-indicator {
      color: ${themeColors.darkGrey};
      cursor: pointer;
    }
    &__multi-value {
      background-color: ${themeColors.mediumGreenTransparent};
    }
    &__multi-value__remove {
      color: ${themeColors.darkGrey};

      &:hover {
        background-color: ${themeColors.lightGreen};
      }
    }
    &__option {
      padding-top: 4px;
      padding-bottom: 4px;

      font-size: 14px;

      &:hover, &:focus, &:active, &--is-focused {
        background-color: ${themeColors.mediumGreenTransparent};
      }
    }
    &__menu {
      margin: 0;
      padding-top: 8px;
      padding-bottom: 15px;
      z-index: 11;
      box-shadow: none;
      background-color: ${themeColors.white};
    }
    &__menu-list {
      border-radius: 4px;
      box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);

      overflow: overlay;
      ${themeUtils.scrollBar}
    }
  }
}
`;
