import { gql } from "@apollo/client";
import { MENU_STATE } from "../../fragments/Menu";

export const GET_MENU_STATE = gql`
  query GetMenuStateQuery($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      state {
        ...MenuState
      }
    }
  }
  ${MENU_STATE}
`;

export * as GetMenuStateTypes from "./__generated__/GetMenuStateQuery";
