import { css } from "@emotion/css";
import { themeUtils } from "@theme/utils";

export const styles = {
  title: css`
    max-width: 100%;

    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${themeUtils.underlineOnHover}
  `,
};
