import { gql } from "@apollo/client";
import { SCHEDULE_TASK_ESSENTIAL_DATA } from "../../fragments/ScheduleTask";

export const UPDATE_SCHEDULE_TASK = gql`
  mutation UpdateScheduleTaskMutation($input: ScheduleTaskUpdateData!) {
    scheduleUpdate(input: $input) {
      ...ScheduleTaskEssentialData
    }
  }
  ${SCHEDULE_TASK_ESSENTIAL_DATA}
`;

export const generateUpdateScheduleTaskVars = (
  restaurantId: string,
  id: string,
  runAt: Date
) => ({
  input: {
    restaurantId,
    id,
    runAt: runAt.getTime(),
  },
});

export * as UpdateScheduleTaskTypes from "./__generated__/UpdateScheduleTaskMutation";
