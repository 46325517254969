import { cx } from "@emotion/css";
import { usePriceEditingMode } from "@hooks";
import { themeColors } from "@theme/colors";
import throttle from "lodash.throttle";
import React, { useCallback, useMemo } from "react";
import ReactSwitch from "react-switch";
import { styles } from "./styles";

type VariantType = "primary";

export enum SizeEnum {
  small = "small",
  medium = "medium",
}

export interface MmsSwitchDefaultProps {
  /**
   * Available variants of Component's view
   */
  variant?: VariantType;
  /**
   * How large should the button be?
   */
  size?: SizeEnum;
  /**
   * For customization of styles from top-level components
   */
  className?: string;
  /**
   * every interactive element should have an id for testing reason
   */
  id: string;
}
export interface MmsSwitchProps extends MmsSwitchDefaultProps {
  onChange: VoidFunction;
  checked: boolean;
  disableTabIndex?: boolean;
  disabled?: boolean;
}

const getVariantProps = (variant: VariantType) => {
  switch (variant) {
    case "primary":
    default:
      return {
        onColor: themeColors.lightGreen,
        offColor: themeColors.veryLightPink,
        boxShadow: "0 1px 0.5px 0 rgba(0, 0, 0, 0.5)",
        activeBoxShadow: "0 1px 1px 1px rgba(0, 0, 0, 0.5)",
      };
  }
};

const getSizeProps = (size: SizeEnum) => {
  switch (size) {
    case "small":
      return {
        width: 24,
        height: 12,
      };
    case "medium":
    default:
      return {
        width: 32,
        height: 16,
      };
  }
};

/**
 * Primary Switch component for toggling some form state
 */
export const MmsSwitch: React.FC<MmsSwitchProps> = ({
  variant = "primary",
  size = SizeEnum.medium,
  className,
  disableTabIndex = true,
  ...restProps
}) => {
  const [isEditingMode] = usePriceEditingMode();

  const extraProps = useMemo(
    () => (isEditingMode || disableTabIndex ? { tabIndex: -1 } : {}),
    [disableTabIndex, isEditingMode]
  );

  // without this, sometimes appears a misbihaviour when you clicking on `react-switch-bg` element
  const throttledOnChange = useCallback(
    throttle(
      () => {
        restProps.onChange();
      },
      300,
      { leading: false }
    ),
    [restProps.onChange]
  );

  const handlechange = useCallback(() => {
    restProps.disabled || throttledOnChange();
  }, [restProps.disabled, throttledOnChange]);

  return (
    <div
      className={cx(
        "MmsSwitch",
        styles.container(!!restProps.disabled),
        className
      )}
    >
      <ReactSwitch
        {...getVariantProps(variant)}
        {...getSizeProps(size)}
        {...extraProps}
        {...restProps}
        className={cx("toggleBtn")}
        checkedIcon={false}
        uncheckedIcon={false}
        onChange={handlechange}
      />
    </div>
  );
};
