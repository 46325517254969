import { gql } from "@apollo/client";
import { omit } from "ramda";
import { Levels } from "__generated__/globalTypes";
import { LocalAddonsReducerStateType } from "../../../hooks/useLocalAddons";
import { convertObjectPricesToNumbers } from "../../../utils/Addon";
import { CATEGORY_ESSENTIAL_DATA } from "../../fragments/Category";

export const UPDATE_CATEGORY_ADDONS = gql`
  mutation UpdateCategoryAddonsMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ...CategoryEssentialData
    }
  }
  ${CATEGORY_ESSENTIAL_DATA}
`;

export const generateUpdateCategoryAddonsVars = (
  restaurantId: string,
  categoryId: string,
  differentAddons: LocalAddonsReducerStateType
) => ({
  input: {
    restaurantId,
    entityType: Levels.CATEGORY,
    entityId: categoryId,
    category: {
      addonsAttributes: [
        ...differentAddons.removedAddons.map(({ id }) => ({
          id,
          destroy: true,
        })),
        ...differentAddons.newAddons.map(
          ({
            id,
            iwaiterId,
            items,
            itemsMeta: { newAddonItems },
            autoenableTimestamp: delayEnableAt,
            ...newAddon
          }) => {
            const postAddon = {
              ...newAddon,
              delayEnableAt,
              menuFlagIds: [...newAddon.menuFlags.map((f) => parseInt(f.id))],
              itemsAttributes: newAddonItems.map(
                ({
                  id,
                  iwaiterId,
                  autoenableTimestamp: delayEnableAt,
                  ...newAddonItem
                }) => ({
                  ...convertObjectPricesToNumbers(newAddonItem),
                  delayEnableAt,
                })
              ),
            };
            return omit(["menuFlags"], postAddon);
          }
        ),
        ...differentAddons.changedAddons.map(
          ({
            items,
            iwaiterId,
            itemsMeta,
            autoenableTimestamp: delayEnableAt,
            ...addon
          }) => {
            const changedAddon = {
              ...addon,
              delayEnableAt,
              itemsAttributes: [
                ...itemsMeta.newAddonItems.map(
                  ({
                    id,
                    iwaiterId,
                    autoenableTimestamp: delayEnableAt,
                    ...newAddonItem
                  }) => ({
                    ...convertObjectPricesToNumbers(newAddonItem),
                    delayEnableAt,
                  })
                ),
                ...itemsMeta.removedAddonItems.map(({ id }) => ({
                  id,
                  destroy: true,
                })),
                ...itemsMeta.changedAddonItems.map(
                  ({
                    iwaiterId,
                    autoenableTimestamp: delayEnableAt,
                    ...addonItem
                  }) => ({
                    ...convertObjectPricesToNumbers(addonItem),
                    delayEnableAt,
                  })
                ),
              ],
              menuFlagIds: [...addon.menuFlags.map((f) => parseInt(f.id))],
            };
            return omit(["menuFlags"], changedAddon);
          }
        ),
      ],
    },
  },
});

export * as UpdateCategoryAddonsTypes from "./__generated__/UpdateCategoryAddonsMutation";
