import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { IndicatorColorType } from "./AddonRowLayout";

export const styles = {
  container: css`
    display: flex;
    gap: 10px;
    align-items: flex-start;
    width: 100%;

    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  `,
  leftCol: css`
    position: relative;
    width: 100%;
    max-width: 220px;
  `,
  rightCol: css`
    display: flex;
    width: 100%;
    max-width: 350px;
  `,
  indicator: (indicatorColor: IndicatorColorType) => css`
    position: absolute;
    left: 0;
    top: 5px;
    width: 5px;
    height: 5px;
    margin-right: 5px;
    border-radius: 50%;

    background-color: ${themeColors[indicatorColor]};

    vertical-align: middle;
  `,
  title: css`
    display: block;
    padding-left: 10px;

    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  `,
};
