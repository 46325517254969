import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface IconedItemLayoutProps {
  className?: string;
  itemIcon: React.ReactNode;
  itemTitle: React.ReactNode;
}

export const IconedItemLayout: React.FC<IconedItemLayoutProps> = ({
  className,
  itemIcon,
  itemTitle,
}) => {
  return (
    <div className={cx(styles, className)}>
      {itemIcon}
      {itemTitle}
    </div>
  );
};
