import { cx } from "@emotion/css";
import { ClickAwayListener, Popper, PopperProps } from "@material-ui/core";
import React, { useState } from "react";
import "./globalStyles";

export interface MmsPopperProps {
  id: string;
  className?: string;
  children: PopperProps["children"];
  anchorEl: PopperProps["anchorEl"];
  isOpen?: boolean;
  onClose: VoidFunction;
}

export const MmsPopper: React.FC<MmsPopperProps> = ({
  id,
  className,
  children,
  anchorEl,
  isOpen = true,
  onClose,
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        className={cx("MmsPopper", className)}
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        disablePortal={false}
        placement={"bottom"}
      >
        <span className={"arrow"} ref={setArrowRef} />
        {children}
      </Popper>
    </ClickAwayListener>
  );
};
