import { useEffect, useRef } from "react";
import { useOpenClose } from "./useOpenClose";

interface MutableRefObject<T> {
  current: T;
}

type HoverBag = {
  isHovered: boolean;
  hover: VoidFunction;
  unhover: VoidFunction;
};

export const useHover = (): [
  MutableRefObject<HTMLElement | null>,
  HoverBag
] => {
  const [isHovered, hover, unhover] = useOpenClose(false);

  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const node: HTMLElement | null = ref.current;
    if (!node) return;

    const hoverListener = (e: Event) => {
      e.stopPropagation();
      hover();
    };

    node.addEventListener("mouseover", hoverListener);
    node.addEventListener("mouseout", unhover);

    return () => {
      node.removeEventListener("mouseover", hoverListener);
      node.removeEventListener("mouseout", unhover);
    };
  }, [hover, unhover]);

  return [ref, { isHovered, hover, unhover }];
};
