import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

export const styles = {
  wrapper: css`
    width: 100%;
    position: fixed;
    bottom: 0;

    background-color: ${themeColors.white};

    box-shadow: ${themeUtils.boxShadowSettings()} ${themeUtils.boxShadowOpacity()};
    z-index: 10;
  `,
  container: css`
    padding-top: 12px;
    padding-bottom: 12px;
  `,
};
