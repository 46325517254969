import { useReactiveVar } from "@apollo/client";
import { focusedElemIdVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useKeepFocus = (): [
  string | null,
  (elemId: string) => void,
  VoidFunction
] => {
  const focusElemWithId = useCallback((elemId: string) => {
    focusedElemIdVar(elemId);
  }, []);
  const clearFocus = useCallback(() => {
    focusedElemIdVar(null);
  }, []);

  return [useReactiveVar(focusedElemIdVar), focusElemWithId, clearFocus];
};
