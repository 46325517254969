import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface MmsFormDescriptionProps {
  children: string;
  className?: string;
}

export const MmsFormDescription: React.FC<MmsFormDescriptionProps> = ({
  children,
  className,
}) => <div className={cx(styles, className)}>{children}</div>;
