import React from "react";
import { styles } from "./MenuItemChooseImageLayout.styles";

interface MenuItemChooseImageLayoutProps {
  galleryBulkPanel: React.ReactNode;
  galleryContent: React.ReactNode;
}

export const MenuItemChooseImageLayout: React.FC<MenuItemChooseImageLayoutProps> = ({
  galleryBulkPanel,
  galleryContent,
}) => {
  return (
    <div className={styles.container}>
      {galleryBulkPanel && (
        <div className={styles.galleryBulkPanel}>{galleryBulkPanel}</div>
      )}
      <div className={styles.galleryContent}>{galleryContent}</div>
    </div>
  );
};
