import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

export const styles = {
  container: css`
    min-width: 100%;
    height: 450px;
    padding: 21px;

    overflow: hidden;
  `,
  headerCell: css`
    padding-bottom: 6px;
  `,
  mainContentCell: css`
    flex: 1;
    min-height: 0;
    gap: 19px;
    padding: 40px 20px;

    overflow: overlay;

    ${themeUtils.scrollBar}
  `,
  descriptionCell: css`
    font-size: 20px;
    color: ${themeColors.black};
  `,
  detailsCell: css`
    padding-left: 0 !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  `,
  footerCell: css`
    flex-direction: row-reverse;
    padding-top: 6px;
  `,
  publishedByCell: css`
    color: ${themeColors.lightGreen} !important;
  `,
};
