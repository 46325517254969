/* eslint-disable react-hooks/exhaustive-deps */
import { cx } from "@emotion/css";
import {
  ContentState,
  convertToRaw,
  EditorState,
  SelectionState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useField } from "formik";
import htmlToDraft from "html-to-draftjs";
import React, { useCallback, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "./react-draft-wysiwyg-styles";
import { styles } from "./styles";

type MmsWysiwygState = { editorState: EditorState; textLength: number };

const getRawTextLength = (editorState: EditorState) => {
  const raw = convertToRaw(editorState.getCurrentContent());

  const textLength = raw.blocks
    .map((block) => block.text.length)
    .reduce((a, b) => a + b, 0);

  return textLength || 0;
};

const createState = (value?: string): MmsWysiwygState => {
  if (!value) {
    return {
      editorState: EditorState.createEmpty(),
      textLength: 0,
    };
  }

  const blocksFromHTML = htmlToDraft(value);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const editorState = EditorState.createWithContent(state);
  const textLength = getRawTextLength(editorState);

  return { editorState, textLength };
};

const getValueFromState = (editorState: EditorState): string => {
  const raw = convertToRaw(editorState.getCurrentContent());
  return draftToHtml(raw);
};

const moveSelectionToEnd = (editorState: EditorState) => {
  const content = editorState.getCurrentContent();
  const blockMap = content.getBlockMap();
  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();

  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });

  return EditorState.forceSelection(editorState, selection);
};

export interface MmsWysiwygProps {
  value: string;
  placeholder: string;
  maxCharacters?: number;
  name: string;
  wysiwygClassName?: string;
  validate?: (value: any) => undefined | string | Promise<any>;
  onBlurCustom?: VoidFunction;
}

export const MmsWysiwyg: React.FC<
  MmsWysiwygProps & React.HTMLProps<HTMLInputElement>
> = ({
  maxCharacters,
  name,
  placeholder,
  wysiwygClassName,
  validate,
  onBlurCustom,
}) => {
  const [mmsWysiwygState, setMmsWysiwygState] = useState<MmsWysiwygState>(
    createState()
  );

  const [field, meta, helpers] = useField({ name, validate });

  const focusWysiwyg = useCallback((ref: any) => {
    if (!ref) return;

    let { editorState, textLength } = createState(field.value);
    editorState = moveSelectionToEnd(editorState);

    setMmsWysiwygState({
      editorState,
      textLength,
    });
  }, []);

  const handleEditorStateChange = useCallback(
    (newEditorState: EditorState) => {
      const currentValue = getValueFromState(mmsWysiwygState.editorState);
      const newValue = getValueFromState(newEditorState);

      if (currentValue !== newValue) {
        helpers.setValue(newValue);
      }

      const textLength = getRawTextLength(newEditorState);
      setMmsWysiwygState({ editorState: newEditorState, textLength });
    },
    [mmsWysiwygState]
  );

  return (
    <div className={cx(wysiwygClassName, styles.container(!!meta.error))}>
      <Editor
        placeholder={placeholder}
        toolbarHidden
        editorRef={focusWysiwyg}
        editorState={mmsWysiwygState.editorState}
        onEditorStateChange={handleEditorStateChange}
        toolbar={{
          options: ["inline", "colorPicker", "emoji"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          colorPicker: {
            colors: ["black", "red", "green", "blue", "grey"],
          },
          emoji: {
            emojis: [
              "🍔",
              "🍟",
              "🍕",
              "🌶️",
              "🥜",
              "🥯",
              "🍣",
              "🥢",
              "🍽️",
              "🙂",
              "🥤",
              "🧑‍🍳",
            ],
          },
        }}
      />
      {maxCharacters && (
        <div className={styles.maxLength}>
          {`${mmsWysiwygState.textLength}/${maxCharacters}`}
        </div>
      )}
    </div>
  );
};
