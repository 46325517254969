import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    text-align: center;
  `,
  compassRose: css`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 266px;
    margin-bottom: 50px;
  `,
  messageContainer: css`
    margin-bottom: 50px;

    color: ${themeColors.white};
  `,
  messageTitle: css`
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
  `,
  messageDescription: css`
    font-size: 20px;
  `,
};
