/* eslint-disable react-hooks/exhaustive-deps */
import { cx } from "@emotion/css";
import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";
import { MmsTextInput } from "@uiKit/Atoms/Inputs/MmsTextInput";
import { validateForm } from "@utils/formik";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import { styles } from "./LibraryAssetEditTitle.styles";
import { validationMap } from "./LibraryAssetEditTitle.validationMap";
import { useTranslation } from "react-i18next";

interface FormValues {
  title: string;
}

export interface LibraryAssetEditTitleProps {
  assetId: string;
  isFormSubmitting: boolean;
  onSubmitEnded: VoidFunction;
  onPressEsc: VoidFunction;
}

const LibraryAssetEditTitle = ({
  assetId,
  isFormSubmitting,
  onSubmitEnded,
  onPressEsc,
  ...formikProps
}: LibraryAssetEditTitleProps & FormikProps<FormValues>) => {
  const { t } = useTranslation(["library"]);
  const formId = `library-asset-edit-title-${assetId}`;
  const [
    activeForms,
    addToActiveForms,
    removeFromActiveForms,
  ] = useActiveForms();

  const handleBlur = useCallback(() => {
    formikProps.submitForm();
  }, []);

  const handlePressEsc = useCallback(() => {
    onSubmitEnded();
    onPressEsc();
  }, []);

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useDidUpdate(() => {
    if (!isFormSubmitting) onSubmitEnded();
  }, [isFormSubmitting]);

  useEffect(() => {
    if (formikProps.isValid) return;

    if (activeForms.length > 1) {
      handlePressEsc();
    }
  }, [formikProps.isValid, activeForms]);

  return (
    <Form className={styles.form}>
      <MmsTextInput
        data-testid={formId}
        name="title"
        type="text"
        placeholder={t("library:enter-library-asset-title-here")}
        disabled={isFormSubmitting}
        focusOnMount
        onBlur={handleBlur}
        onPressEsc={handlePressEsc}
        fieldClassName={cx("EditLibraryAssetInput", styles.input)}
      />
    </Form>
  );
};

interface LibraryAssetEditTitleFormProps extends LibraryAssetEditTitleProps {
  title?: string;
  onSubmit: (values: any) => void;
}

// Wrap our form with the withFormik HoC
export const LibraryAssetEditTitleForm = withFormik<
  LibraryAssetEditTitleFormProps,
  FormValues
>({
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    title: props.title || "",
  }),
  validate: validateForm(validationMap),
  handleSubmit: (values, { props }) => {
    values.title !== props.title
      ? props.onSubmit(values)
      : props.onSubmitEnded();
  },
})(LibraryAssetEditTitle);
