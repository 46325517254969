import { css } from "@emotion/css";

export const styles = {
  container: (disabled: boolean) => css`
    display: flex;
    position: relative;

    ${disabled &&
    css`
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        cursor: not-allowed;
      }
    `}
  `,
};
