import { SCREEN_SIZES } from "@constants";
import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-width: 1220px;
    padding: 0 15px;

    border-top: 1px solid ${themeColors.veryLightPink};

    &:hover .controlsGroupCell {
      visibility: visible;
    }

    @media (max-width: ${SCREEN_SIZES.MD - 1}px) {
      .controlsGroupCell {
        visibility: visible;
      }
    }
  `,
  cell: css`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 56px;
    padding: 5px;
  `,
  checkboxCell: css`
    padding-top: 15px;
    padding-right: 0;
    max-width: 29px;
  `,
  errorCell: css`
    align-items: flex-start;
    max-width: 24px;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
  `,
  imageCell: css`
    padding-top: 10px;
    padding-left: 2px;
    max-width: 46px;
  `,
  titleCell: css`
    padding-top: 9px;
    max-width: 249px;
  `,
  addonsCell: css`
    padding-top: 10px;
    width: 100%;
    max-width: 580px;
  `,
  controlsGroupCell: (shouldKeepControlsVisible: boolean) => css`
    padding-top: 13px;
    max-width: 190px;
    justify-content: flex-end;

    visibility: ${shouldKeepControlsVisible ? "visible" : "hidden"};
  `,
  toggleCell: css`
    padding-top: 20px;
    max-width: 45px;
  `,
  chevronCell: css`
    padding-top: 15px;
    max-width: 45px;
    justify-content: center;
    align-items: flex-start;
  `,
};
