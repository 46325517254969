import {
  ApolloError,
  Reference,
  useMutation,
  useReactiveVar,
} from "@apollo/client";
import { useErrorHandler } from "@hooks";
import {
  ModelCategoryDNA,
  ModelCategoryMenuFlags,
  ModelCategoryTaxes,
  ModelFullCategory,
  ModelMainCategory,
} from "@model/DAO/MenuCategory";
import { PositionDataType } from "@model/helperTypes/dragAndDrop";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { CATEGORY_ESSENTIAL_DATA } from "@pages/RestaurantMenuPage/graphql/fragments/Category";
import { menuIdVar } from "@utils/apolloReactiveVars";
import produce from "immer";
import { insert } from "ramda";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { LevelsRerank } from "__generated__/globalTypes";
import {
  CloneCategoryTypes,
  CLONE_CATEGORY,
  generateCloneCategoryVars,
} from "../../graphql/mutatioins/CloneCategory";
import {
  CreateCategoryTypes,
  CREATE_CATEGORY,
  generateCreateCategoryVars,
} from "../../graphql/mutatioins/CreateCategory";
import {
  DestroyCategoryTypes,
  DESTROY_CATEGORY,
  generateDestroyCategoryVars,
} from "../../graphql/mutatioins/DestroyCategory";
import {
  EditCategoryDNATypes,
  EDIT_CATEGORY_DISCOUNT_N_AVAILABILITY,
  generateEditCategoryDNAVars,
} from "../../graphql/mutatioins/EditCategoryDiscountNAvailability";
import {
  EditCategoryTaxesTypes,
  EDIT_CATEGORY_TAXES,
  generateEditCategoryTaxesVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/EditTaxes";
import {
  EditCategoryMenuFlagsTypes,
  EDIT_CATEGORY_MENU_FLAGS,
  generateEditCategoryMenuFlagsVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/EditMenuFlags";
import {
  EditCategoryTitleTypes,
  EDIT_CATEGORY_TITLE,
  generateEditCategoryTitleVars,
} from "../../graphql/mutatioins/EditCategoryTitle";
import {
  generateRerankEntitiesVars,
  RerankEntitiesMutationType,
  RERANK_ENTITIES,
} from "../../graphql/mutatioins/RerankEntities";
import {
  generateToggleCategoryVars,
  ToggleCategoryTypes,
  TOGGLE_CATEGORY,
} from "../../graphql/mutatioins/ToggleCategory";
import {
  generateUpdateCategoryAddonsVars,
  UpdateCategoryAddonsTypes,
  UPDATE_CATEGORY_ADDONS,
} from "../../graphql/mutatioins/UpdateCategoryAddons";
import { useDuplicateMenuAssetValidation } from "../../hooks/useDuplicateMenuAssetValidation";
import { LocalAddonsReducerStateType } from "../../hooks/useLocalAddons";
import { MatchParams } from "../../RestaurantMenuPage";
import { sortableEntitiesToPositionDataType } from "../../utils/rerank";
import {
  EDIT_CATEGORY_DESCRIPTION,
  EditCategoryDescriptionTypes,
  generateEditCategoryDescriptionVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/EditCategoryDescription";

export const useToggleCategory = (
  categoryId: string,
  active: boolean
): [(autoenableTimestamp: number) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);

  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<ToggleCategoryTypes.ToggleCategoryMutation>(TOGGLE_CATEGORY, {
    onError,
  });

  const mutationWithVars = useCallback(
    (autoenableTimestamp: number) => {
      mutation({
        variables: generateToggleCategoryVars(
          restaurantId,
          categoryId,
          active,
          autoenableTimestamp
        ),
        optimisticResponse: {
          mmsUpdate: {
            __typename: "MenuCategory",
            id: categoryId,
            active: !active,
          },
        },
      });
    },
    [active, categoryId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useEditCategoryTitle = (
  categoryId: string
): [(newTitle: string) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const [onCheckDuplication] = useDuplicateMenuAssetValidation();

  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<EditCategoryTitleTypes.EditCategoryTitleMutation>(
    EDIT_CATEGORY_TITLE,
    { onError }
  );

  const mutationWithVars = useCallback(
    (newTitle: string) => {
      mutation({
        variables: generateEditCategoryTitleVars(
          restaurantId,
          categoryId,
          newTitle
        ),
      }).then(() => onCheckDuplication(MenuAssetEnum.category, newTitle));
    },
    [categoryId, mutation, onCheckDuplication, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useEditCategoryDescription = (
  categoryId: string,
  onCompleted: VoidFunction
): [(newDescription: string) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const [onCheckDuplication] = useDuplicateMenuAssetValidation();

  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<EditCategoryDescriptionTypes.EditCategoryDescriptionMutation>(
    EDIT_CATEGORY_DESCRIPTION,
    { onError, onCompleted }
  );

  const mutationWithVars = useCallback(
    (newDescription: string) => {
      mutation({
        variables: generateEditCategoryDescriptionVars(
          restaurantId,
          categoryId,
          newDescription
        ),
      }).then(() => onCheckDuplication(MenuAssetEnum.category, newDescription));
    },
    [categoryId, mutation, onCheckDuplication, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useCreateCategory = (): [
  (newLocalCategory: ModelMainCategory) => void,
  boolean
] => {
  const menuId = useReactiveVar(menuIdVar);
  const [, { onError }] = useErrorHandler([]);
  const [onCheckDuplication] = useDuplicateMenuAssetValidation();

  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<CreateCategoryTypes.CreateCategoryMutation>(CREATE_CATEGORY, {
    onError,
  });

  const mutationWithVars = useCallback(
    (newLocalCategory: ModelMainCategory) => {
      mutation({
        variables: generateCreateCategoryVars(
          restaurantId,
          menuId,
          newLocalCategory
        ),
        update(cache, { data }) {
          const newCategory = data?.mmsAdd as ModelFullCategory;

          cache.modify({
            id: cache.identify({ __typename: "Query" }),
            fields: {
              menu(prevMenu) {
                const newCategoryRef = cache.writeFragment({
                  data: newCategory,
                  fragment: CATEGORY_ESSENTIAL_DATA,
                  fragmentName: "CategoryEssentialData",
                });

                return produce(prevMenu, (newPrevMenu: any) => {
                  newPrevMenu.categories.push(newCategoryRef);
                });
              },
            },
          });
        },
      }).then(() =>
        onCheckDuplication(MenuAssetEnum.category, newLocalCategory.title)
      );
    },
    [menuId, mutation, onCheckDuplication, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useDestroyCategory = (
  categoryId: string
): [VoidFunction, boolean] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<DestroyCategoryTypes.DestroyCategoryMutation>(
    DESTROY_CATEGORY,
    { onError }
  );

  const mutationWithVars = useCallback(() => {
    mutation({
      variables: generateDestroyCategoryVars(restaurantId, categoryId),
      update(cache) {
        cache.evict({ id: `MenuCategory:${categoryId}` });
      },
    });
  }, [categoryId, mutation, restaurantId]);

  return [mutationWithVars, loading];
};

export const useUpdateCategoryAddons = (
  categoryId: string
): [
  (differentAddons: LocalAddonsReducerStateType) => void,
  boolean,
  ApolloError | undefined
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading, error },
  ] = useMutation<UpdateCategoryAddonsTypes.UpdateCategoryAddonsMutation>(
    UPDATE_CATEGORY_ADDONS,
    { onError }
  );

  const mutationWithVars = useCallback(
    (differentAddons: LocalAddonsReducerStateType) => {
      mutation({
        variables: generateUpdateCategoryAddonsVars(
          restaurantId,
          categoryId,
          differentAddons
        ),
      });
    },
    [categoryId, mutation, restaurantId]
  );

  return [mutationWithVars, loading, error];
};

export const useEditCategoryDiscountNAvailability = (
  categoryId: string
): [
  (newCategoryDNA: ModelCategoryDNA) => void,
  boolean,
  ApolloError | undefined
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading, error },
  ] = useMutation<EditCategoryDNATypes.EditCategoryDNAMutation>(
    EDIT_CATEGORY_DISCOUNT_N_AVAILABILITY,
    { onError }
  );

  const mutationWithVars = useCallback(
    (newCategoryDNA: ModelCategoryDNA) => {
      mutation({
        variables: generateEditCategoryDNAVars(
          restaurantId,
          categoryId,
          newCategoryDNA
        ),
      });
    },
    [categoryId, mutation, restaurantId]
  );

  return [mutationWithVars, loading, error];
};

export const useEditCategoryTaxes = (
  categoryId: string
): [
  (newTaxes: ModelCategoryTaxes) => void,
  boolean,
  ApolloError | undefined
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading, error },
  ] = useMutation<EditCategoryTaxesTypes.EditCategoryTaxesMutation>(
    EDIT_CATEGORY_TAXES,
    { onError }
  );

  const mutationWithVars = useCallback(
    (newCategoryTaxes: ModelCategoryTaxes) => {
      mutation({
        variables: generateEditCategoryTaxesVars(
          restaurantId,
          categoryId,
          newCategoryTaxes
        ),
      });
    },
    [categoryId, mutation, restaurantId]
  );

  return [mutationWithVars, loading, error];
};

export const useEditCategoryMenuFlags = (
  categoryId: string
): [
  (newMenuFlags: ModelCategoryMenuFlags) => void,
  boolean,
  ApolloError | undefined
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading, error },
  ] = useMutation<EditCategoryMenuFlagsTypes.EditCategoryMenuFlagsMutation>(
    EDIT_CATEGORY_MENU_FLAGS,
    { onError }
  );

  const mutationWithVars = useCallback(
    (newCategoryMenuFlags: ModelCategoryMenuFlags) => {
      mutation({
        variables: generateEditCategoryMenuFlagsVars(
          restaurantId,
          categoryId,
          newCategoryMenuFlags
        ),
      });
    },
    [categoryId, mutation, restaurantId]
  );

  return [mutationWithVars, loading, error];
};

export const useCloneCategory = (
  clonedCategoryId: string,
  onCompleted: VoidFunction
): [VoidFunction, boolean] => {
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<CloneCategoryTypes.CloneCategoryMutation>(CLONE_CATEGORY, {
    onCompleted,
    onError,
  });

  const mutationWithVars = useCallback(() => {
    mutation({
      variables: generateCloneCategoryVars(clonedCategoryId),
      update(cache, { data }) {
        const newCategory = data?.mmsClone as ModelFullCategory;

        cache.modify({
          id: cache.identify({ __typename: "Query" }),
          fields: {
            menu(prevMenu, { readField }) {
              const newCategoryRef = cache.writeFragment({
                data: newCategory,
                fragment: CATEGORY_ESSENTIAL_DATA,
                fragmentName: "CategoryEssentialData",
              });

              return produce(prevMenu, (newPrevMenu: any) => {
                const { categories } = newPrevMenu;
                const clonedCategoryIndex = categories.findIndex(
                  (category: Reference) =>
                    readField("id", category) === clonedCategoryId
                );

                newPrevMenu.categories = insert(
                  clonedCategoryIndex + 1,
                  newCategoryRef,
                  categories
                );
              });
            },
          },
        });
      },
    });
  }, [clonedCategoryId, mutation]);

  return [mutationWithVars, loading];
};

export const useRerankCategories = (): [
  (reorderedCategories: ModelFullCategory[]) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const menuId = useReactiveVar(menuIdVar);
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<RerankEntitiesMutationType.RerankEntitiesMutation>(
    RERANK_ENTITIES,
    { onError }
  );

  const mutationWithVars = useCallback(
    (reorderedCategories: ModelFullCategory[]) => {
      const positions: PositionDataType[] = sortableEntitiesToPositionDataType<ModelFullCategory>(
        reorderedCategories
      );

      mutation({
        variables: generateRerankEntitiesVars(
          restaurantId,
          LevelsRerank.MENU,
          menuId,
          positions
        ),
        update(cache) {
          reorderedCategories.forEach((category) => {
            cache.modify({
              id: cache.identify({
                __typename: "MenuCategory",
                id: category.id,
              }),
              fields: {
                orderBy() {
                  return category.orderBy;
                },
              },
            });
          });

          cache.modify({
            id: cache.identify({ __typename: "Query" }),
            fields: {
              menu(prevMenu, { readField }) {
                return produce(prevMenu, (newPrevMenu: any) => {
                  newPrevMenu.categories = newPrevMenu.categories.sort(
                    (
                      prevCategoryRef: Reference,
                      nextCategoryRef: Reference
                    ) => {
                      const prevCategoryOrderBy = readField(
                        "orderBy",
                        prevCategoryRef
                      );
                      const nextCategoryOrderBy = readField(
                        "orderBy",
                        nextCategoryRef
                      );

                      return (
                        Number(prevCategoryOrderBy) -
                        Number(nextCategoryOrderBy)
                      );
                    }
                  );
                });
              },
            },
          });
        },
      });
    },
    [menuId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};
