import { injectGlobal } from "@emotion/css";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

injectGlobal`
.MmsPopper {
  margin-top: 10px;

  border-radius: ${themeVars.borderRadius};

  background: white;

  box-shadow: ${themeUtils.boxShadowSettings(
    80
  )} ${themeUtils.boxShadowOpacity()};
  z-index: 9;

  .arrow {
    position: absolute;
    width: 1em;
    height: 1em;

    &:after {
      content: '';

      position: absolute;
      top: -20px;

      border-bottom: 10px solid white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid transparent;

      z-index: 10;
    }
  }
}
`;
