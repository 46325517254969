import { css } from "@emotion/css";

export const styles = {
  form: css`
    width: 100%;
  `,
  input: css`
    line-height: 23px;
  `,
};
