import { ReactComponent as SVGCsv } from "@assets/SVG/csv.svg";
import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface ICsvProps extends IIconBase {
  color?: string;
}

export const Csv: React.FC<ICsvProps> = ({
  id,
  size = "medium",
  className,
  color = themeColors.black,
}) => {
  const ref = useSvgFilter(id);
  return (
    <SVGCsv
      ref={ref}
      className={cx(styles.icon(size), className)}
      fill={color}
    />
  );
};
