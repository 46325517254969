import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { validateForm } from "@utils/formik";
import { protectPriceFromNull } from "@utils/protectors";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import { MenuItemAddonsFormData } from "../../../../utils/Addon";
import { styles } from "./MenuItemEditPriceList.styles";
import { validationMap } from "./MenuItemEditPriceList.validationMap";
import { useTranslation } from "react-i18next";

interface MenuItemEditPriceListProps {
  formData: MenuItemAddonsFormData[];
  menuItemId: string;
  focusFirstField: boolean;
  shouldLeaveEditingModeOnSubmit: boolean;
  onSubmit: (values: any) => void;
  setShouldLeaveEditingModeOnSubmit: (should: boolean) => void;
  leaveEditingMode: VoidFunction;
}

const MenuItemEditPriceList: React.FC<
  MenuItemEditPriceListProps & FormikProps<any>
> = ({
  formData,
  menuItemId,
  focusFirstField,
  shouldLeaveEditingModeOnSubmit,
  onSubmit,
  setShouldLeaveEditingModeOnSubmit,
  leaveEditingMode,
  ...formikProps
}) => {
  const { t } = useTranslation(["menu-item"]);
  const handleChangeFormattedValue = useCallback(
    (fieldName, values) => {
      const { formattedValue: fieldValue } = values;
      formikProps.setFieldValue(fieldName, fieldValue);
    },
    [formikProps]
  );

  const handleChangePrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      formikProps.setFieldValue(name, value);
    },
    [formikProps]
  );

  const submitAndLeaveEditingMode = useCallback(() => {
    setShouldLeaveEditingModeOnSubmit(true);
    formikProps.submitForm();
  }, [formikProps, setShouldLeaveEditingModeOnSubmit]);

  return (
    <Form className={styles.form}>
      {formData.map((formField, index) => (
        <NumberFormat
          key={formField.name}
          customInput={MmsMuiInput}
          decimalScale={2}
          data-testid={`menu-item-input-${formField.name}-${menuItemId}`}
          name={formField.name}
          label={t("menu-item:price-short")}
          onBlur={formikProps.submitForm}
          onPressEsc={leaveEditingMode}
          onPressEnter={submitAndLeaveEditingMode}
          width={58}
          value={(formikProps.values as any)[formField.name]}
          onValueChange={handleChangeFormattedValue.bind(null, formField.name)}
          onChange={handleChangePrice}
          focusOnMount={focusFirstField && index === 0}
          allowNegative={false}
          showUnderline
        />
      ))}
    </Form>
  );
};

export interface MenuItemEditPriceListFormProps
  extends MenuItemEditPriceListProps {}

export const MenuItemEditPriceListForm = withFormik<
  MenuItemEditPriceListFormProps,
  any
>({
  // Transform outer props into form values
  mapPropsToValues: ({ formData }) => {
    return formData.reduce(
      (accum, { name, value }) => ({
        ...accum,
        [name]: protectPriceFromNull(value),
      }),
      {}
    );
  },
  validate: (values, { formData }) =>
    validateForm(validationMap(formData))(values),
  handleSubmit: (
    values: any,
    {
      props: {
        formData,
        shouldLeaveEditingModeOnSubmit,
        onSubmit,
        leaveEditingMode,
      },
    }
  ) => {
    const isFormChanged = formData.reduce(
      (accum, { name, value }) => accum || value !== values[name],
      false
    );
    if (isFormChanged) onSubmit(values);
    else shouldLeaveEditingModeOnSubmit && leaveEditingMode();
  },
})(MenuItemEditPriceList);
