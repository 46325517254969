import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  form: css`
    margin-left: -16px;
    margin-right: -16px;
  `,
  baseInput: css`
    gap: 10px;
    padding-left: 16px;
    padding-right: 16px;
  `,
  selectBox: css`
    margin-bottom: 16px;
  `,
  info: css`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 16px;
    font-size: 13px;
    color: ${themeColors.mediumGray};
  `,
  hr: css`
    height: 1px;
    border: none;
    margin: 10px 16px;
    background-color: ${themeColors.borderLightGrey};
  `,
  toggleWrapper: css`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 16px;
    margin-bottom: 6px;
    text-transform: capitalize;
  `,
  toggleLabel: css`
    cursor: pointer;
  `,
};
