import { MmsLabelSwitch } from "@uiKit/Atoms/Switch/MmsLabelSwitch";
import React, { useCallback } from "react";
import { styles } from "./UsesPopularItemsToggle.styles";
import { useTranslation } from "react-i18next";

interface UsesPopularItemsToggleProps {
  usesPopularItems: boolean;
  toggleUsesPopularItems: (usesPopularItems: boolean) => void;
}

export const UsesPopularItemsToggle: React.FC<UsesPopularItemsToggleProps> = ({
  usesPopularItems,
  toggleUsesPopularItems,
}) => {
  const { t } = useTranslation(["menu"]);

  const handleToggleIsUsesPopularItems = useCallback(() => {
    toggleUsesPopularItems(!usesPopularItems);
  }, [usesPopularItems, toggleUsesPopularItems]);

  return (
    <MmsLabelSwitch
      id="toggle-uses-popular-items"
      labelClassName={styles.label}
      label={t("menu:popular-items")}
      checked={usesPopularItems}
      onChange={handleToggleIsUsesPopularItems}
      inverted
    />
  );
};
