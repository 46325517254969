import { gql } from "@apollo/client";
import { COUNTRY_TAXES_ESSENTIAL_DATA } from "../../fragments/Taxes";

export const GET_COUNTRY_TAXES = gql`
  query GetCountryTaxes($country: Countries!) {
    taxes(country: $country) {
      ...CountryTaxesEssentialData
    }
  }
  ${COUNTRY_TAXES_ESSENTIAL_DATA}
`;

export * as GetCountryTaxesTypes from "./__generated__/GetCountryTaxes";
