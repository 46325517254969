import { useBulkEditing } from "@hooks";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { BulkActionEnum } from "@model/helperTypes/BulkEditing";
import { useCallback, useEffect, useMemo } from "react";
import { useBulkSelectedEntities } from "./useBulkSelectedEntities";

type MenuItemBulkActionsHookReturnTuple = [boolean, VoidFunction];

type MenuItemBulkActionsHookType = (
  menuItemId: ModelMenuItem["id"],
  toggleExpandedManual: (isExpanded: boolean) => void
) => MenuItemBulkActionsHookReturnTuple;

export const useMenuItemBulkActions: MenuItemBulkActionsHookType = (
  menuItemId,
  toggleExpandedManual
) => {
  const {
    selectedEntities,
    callbacks: { onSelectMenuItem, onDeselectMenuItem },
  } = useBulkSelectedEntities();

  const [currentBulkAction, , , cleanBulkAction] = useBulkEditing();

  const isCheckboxEnabled = useMemo(() => {
    return !!selectedEntities.selectedMenuItems.find(
      ({ id }) => id === menuItemId
    );
  }, [menuItemId, selectedEntities.selectedMenuItems]);

  const onToggleCheckbox = useCallback(() => {
    cleanBulkAction();
    if (isCheckboxEnabled) onDeselectMenuItem(menuItemId);
    else onSelectMenuItem(menuItemId);
  }, [
    cleanBulkAction,
    isCheckboxEnabled,
    onDeselectMenuItem,
    menuItemId,
    onSelectMenuItem,
  ]);

  useEffect(() => {
    if (!isCheckboxEnabled) return;

    switch (currentBulkAction) {
      case BulkActionEnum.expand:
        toggleExpandedManual(false);
        break;
      case BulkActionEnum.collapse:
        toggleExpandedManual(true);
        break;
      default:
        break;
    }
  }, [currentBulkAction, isCheckboxEnabled, toggleExpandedManual]);

  return [isCheckboxEnabled, onToggleCheckbox];
};
