import { ApolloError } from "@apollo/client";
import { KnownErrorCodesEnum } from "@model/helperTypes/errors";
import { addUniqErrorBlockingNotification } from "@pages/RestaurantMenuPage/hooks/useErrorBlockingNotifications";
import { addUniqThrottledErrorRegularNotification } from "@pages/RestaurantMenuPage/hooks/useErrorRegularNotifications";
import { getErrorCode } from "@utils/errors";
import { getIsPartner } from "@utils/url";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";

export type HandleErrorTools = {
  onError: (error: ApolloError) => void;
  clearError: VoidFunction;
  withClearError: (fn: any) => (...args: any) => any;
  onRestError: (error: RestError) => void;
};

const blockingErrors = [
  KnownErrorCodesEnum.stateInvalid,
  KnownErrorCodesEnum.librariesRequired,
  KnownErrorCodesEnum.recordNotFound,
];

export class RestError extends Error {
  constructor(message: string, status: number) {
    super(message);

    this.status = status;
  }
  status: number;
}

const four0fourErrors = [KnownErrorCodesEnum.iwaiterError];
const authErrors = [KnownErrorCodesEnum.unauthorized];
const authErrorsRest = [401];

const superAdminAccessErr = `You need 'Super Menu User' rights to destroy the restore menu`;

// todo: need to cover tuple with more precise types
export const useErrorHandler = (
  skipErrorCodeList: KnownErrorCodesEnum[],
  skipErrorCallback?: (error: ApolloError) => void
): [ApolloError | undefined, HandleErrorTools] => {
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const history = useHistory();
  const isPartner = getIsPartner();

  const onRestError = useCallback(
    (error: RestError) => {
      if (authErrorsRest.includes(error.status)) {
        return isPartner
          ? history.push("/error")
          : history.push({
              pathname: "/auth",
              state: { from: { pathname: history.location.pathname } },
            });
      }

      addUniqThrottledErrorRegularNotification(error.message);
    },
    [history, isPartner]
  );

  const onError = useCallback(
    (newError: ApolloError) => {
      switch (true) {
        case !!newError.networkError:
          addUniqThrottledErrorRegularNotification(
            "Failed to fetch. Please, check your internet connection"
          );
          return;
        case newError?.message?.includes(superAdminAccessErr):
          addUniqThrottledErrorRegularNotification(superAdminAccessErr);
          break;
        case skipErrorCodeList.includes(getErrorCode(newError)):
          skipErrorCallback?.(newError);
          break;
        case four0fourErrors.includes(getErrorCode(newError)):
          history.push("/404");
          break;
        case authErrors.includes(getErrorCode(newError)):
          return isPartner
            ? history.push("/error")
            : history.push({
                pathname: "/auth",
                state: { from: { pathname: history.location.pathname } },
              });
        case blockingErrors.includes(getErrorCode(newError)):
          addUniqErrorBlockingNotification(newError.message);
          break;
        default:
          addUniqThrottledErrorRegularNotification(newError.message);
          break;
      }

      setError(newError);
    },
    [skipErrorCodeList, skipErrorCallback, history, isPartner]
  );

  const clearError = useCallback(() => setError(undefined), []);

  const withClearError = useCallback(
    (fn: any) => (...args: any) => {
      setError(undefined);
      return fn(...args);
    },
    []
  );

  return [error, { onError, clearError, withClearError, onRestError }];
};
