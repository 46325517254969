import { ModelLocalAddonItem } from "@model/DAO/MenuAddonItem";
import {
  AddonStrategyEnum,
  LocalAddonItemsMeta,
} from "@model/helperTypes/Addon";
import { LevelType } from "@model/helperTypes/PriceLevelPrices";
import {
  decomposePriceFields,
  getNewAddonItemOrderBy,
} from "@pages/RestaurantMenuPage/utils/AddonItem";
import { generateUniqueKey } from "@utils/general";
import { noop } from "@utils/noop";
import { useCallback, useState } from "react";

const getNewAddonItemFixture = (
  addonItemsMeta: LocalAddonItemsMeta
): Partial<ModelLocalAddonItem> => {
  return {
    id: `new-addon-item-${generateUniqueKey()}`,
    iwaiterId: null,
    active: true,
    title: "",
    price: "0.00",
    priceLevel1: "0.00",
    priceLevel2: "0.00",
    priceLevel3: "0.00",
    priceLevel4: "0.00",
    priceLevel5: "0.00",
    autoenableTimestamp: 0,
    orderBy: getNewAddonItemOrderBy(addonItemsMeta.localAddonItems),
  };
};

interface UseNewAddonItemArgs {
  addonItemsMeta: LocalAddonItemsMeta;
  addonStrategy: AddonStrategyEnum;
  visiblePriceLevels: LevelType[];
  create: (addonItem: ModelLocalAddonItem) => void;
}

export type NewAddonItemAPI = {
  prepareNewAddonItem: VoidFunction;
  clearNewAddonItem: VoidFunction;
  toggleNewAddonItem: (autoenableTimestamp: number) => void;
  createNewAddonItem: (
    addonId: string,
    newTitle: string,
    priceFields: any
  ) => void;
};

type ReturnTupleType = [ModelLocalAddonItem | null, NewAddonItemAPI];

export const useNewAddonItem = ({
  addonItemsMeta,
  addonStrategy,
  visiblePriceLevels,
  create,
}: UseNewAddonItemArgs): ReturnTupleType => {
  const [newAddonItem, setNewAddonItem] = useState<ModelLocalAddonItem | null>(
    null
  );

  const clearNewAddonItem = useCallback(() => {
    setNewAddonItem(null);
  }, []);

  const prepareNewAddonItem = useCallback(() => {
    let newAddonItem = getNewAddonItemFixture(addonItemsMeta);
    setNewAddonItem(newAddonItem as ModelLocalAddonItem);
  }, [addonItemsMeta]);

  const createNewAddonItem = useCallback(
    (_, newTitle: string, priceFields: any) => {
      const newPriceFields = decomposePriceFields(
        addonStrategy,
        visiblePriceLevels,
        priceFields
      );
      newAddonItem &&
        create({ ...newAddonItem, title: newTitle, ...newPriceFields });
      clearNewAddonItem();
    },
    [addonStrategy, clearNewAddonItem, create, newAddonItem, visiblePriceLevels]
  );

  return [
    newAddonItem,
    {
      clearNewAddonItem,
      toggleNewAddonItem: noop, // in order to prevent bug of creating 6-th price level addon item
      prepareNewAddonItem,
      createNewAddonItem,
    },
  ];
};
