import { css } from "@emotion/css";

export const styles = {
  container: css`
    min-width: 100%;
    padding: 21px;
  `,
  emptyContent: css`
    min-height: 0;
    gap: 19px;
  `,
};
