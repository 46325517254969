import { useQuery } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import {
  GetMenuFlagsTypes,
  Get_Menu_Flags,
} from "@pages/RestaurantMenuPage/graphql/queries/GetMenuFlags";
import { menuFlagsVar, spiceLevelVar } from "@utils/apolloReactiveVars";
import { useEffect } from "react";

export const useGetMenuFlags = (): void => {
  const [, { onError }] = useErrorHandler([]);

  const { data } = useQuery<GetMenuFlagsTypes.GetMenuFlagsQuery>(
    Get_Menu_Flags,
    {
      onError,
    }
  );

  useEffect(() => {
    if (!data) return;

    spiceLevelVar(data.spiceTypes);
    menuFlagsVar(data.menuFlags);
  }, [data]);
};
