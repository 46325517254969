import { cx } from "@emotion/css";
import { useActiveProgressBars, useOpenClose } from "@hooks";
import { LinearProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import "./globalStyles";
import { styles } from "./styles";

interface MmsProgressBarProps {
  id: string;
  className?: string;
}

export const MmsProgressBar: React.FC<MmsProgressBarProps> = ({
  id,
  className,
}) => {
  const [isOpenProgressBar, openProgressBar, closeProgressBar] = useOpenClose(
    false
  );

  const [progressBarsIds] = useActiveProgressBars();

  useEffect(() => {
    if (progressBarsIds.includes(id)) openProgressBar();
    else closeProgressBar();
  }, [closeProgressBar, id, openProgressBar, progressBarsIds]);

  if (!isOpenProgressBar) return null;
  return (
    <div className={cx("MmsMuiProgressBar", styles.container, className)}>
      <LinearProgress id={id} />
    </div>
  );
};
