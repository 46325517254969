import { useActiveAddon, useActiveProgressBars } from "@hooks";
import { useCallback, useEffect } from "react";

type UseSaveAddonsToLibraryAPI = {
  handleSaveToLibrary: VoidFunction;
};
type ReturnTupleType = [UseSaveAddonsToLibraryAPI];

export const useSaveAddonsToLibrary = (
  addonId: string,
  libraryProgressbarId: string,
  isAddonNew: boolean,
  isReadyToSaveToLibrary: boolean,
  isUpdateProcessing: boolean,
  setIsReadyToSaveToLibrary: (isReady: boolean) => void,
  updateAddonsAndProceed: VoidFunction
): ReturnTupleType => {
  const [, setAddonProgressBarId] = useActiveProgressBars();

  const handleSaveToLibrary = useCallback(() => {
    updateAddonsAndProceed();
    setIsReadyToSaveToLibrary(true);
  }, [updateAddonsAndProceed, setIsReadyToSaveToLibrary]);

  const [, addAddonToSave] = useActiveAddon();

  useEffect(() => {
    if (!isReadyToSaveToLibrary) return;
    if (isUpdateProcessing) return;
    if (isAddonNew) return;

    addAddonToSave(addonId);
    setAddonProgressBarId(libraryProgressbarId);
    setIsReadyToSaveToLibrary(false);
  }, [
    addAddonToSave,
    addonId,
    isAddonNew,
    isReadyToSaveToLibrary,
    isUpdateProcessing,
    libraryProgressbarId,
    setAddonProgressBarId,
    setIsReadyToSaveToLibrary,
  ]);

  return [{ handleSaveToLibrary }];
};
