import React from "react";
import { cx } from "@emotion/css";
import { ReactComponent as SVGCancel } from "@assets/SVG/cancel.svg";
import { styles } from "./styles";
import { IIconBase } from "../_common/types";

interface ICancelProps extends IIconBase {}

export const Cancel: React.FC<ICancelProps> = ({
  size = "medium",
  className,
}) => <SVGCancel className={cx(styles.icon(size), className)} />;
