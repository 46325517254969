import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    display: flex;
    flex: 1;

    width: 905px;
    max-height: 100%;

    border-radius: ${themeVars.borderRadius};

    background-color: ${themeColors.cream};

    overflow: hidden;
  `,
  firstCol: css`
    width: 28%;
    max-width: 28%;
    max-height: 100%;
    padding: 10px;

    border-right: 3px solid ${themeColors.veryLightPink};
  `,
  secondCol: css`
    width: 36%;
    max-width: 36%;
    max-height: 100%;
    padding: 10px;

    border-right: 3px solid ${themeColors.veryLightPink};

    overflow: overlay;

    ${themeUtils.scrollBar}
  `,
  thirdCol: css`
    width: 36%;
    max-width: 36%;
    max-height: 100%;
    margin-right: 3px;
    padding: 10px 20px;

    overflow: overlay;

    ${themeUtils.scrollBar}
  `,
};
