import { cx } from "@emotion/css";
import { useHiddenText } from "@hooks";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./CategoryTitleSection.styles";

interface CategoryTitleSectionProps {
  title: string;
  className?: string;
  onClick: VoidFunction;
}

export const CategoryTitleSection: React.FC<CategoryTitleSectionProps> = ({
  title,
  className,
  ...rest
}) => {
  const [titleRef, isTooltipOpen] = useHiddenText();
  return (
    <MmsTooltip title={title} disable={!isTooltipOpen}>
      <div
        ref={titleRef}
        className={cx("CategoryTitle", styles.title, className)}
        {...rest}
      >
        {title}
      </div>
    </MmsTooltip>
  );
};
