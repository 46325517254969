import { useReactiveVar } from "@apollo/client";
import { usePriceEditingMode } from "@hooks";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { PriceLevelPrices } from "@model/helperTypes/PriceLevelPrices";
import { AddonRowLayout } from "@pages/RestaurantMenuPage/layouts/AddonRowLayout/AddonRowLayout";
import { generateFormDataForMenuItemAddonsSection } from "@pages/RestaurantMenuPage/utils/Addon";
import {
  getVisiblePriceLevelPrices,
  getVisiblePriceLevels,
} from "@pages/RestaurantMenuPage/utils/AddonItem";
import { getActiveEntities } from "@pages/RestaurantMenuPage/utils/GenericEntity";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { menuFiltersVar } from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import { protectPriceFromNull } from "@utils/protectors";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styles } from "../MenuItemAddonsSection.styles";
import { MenuItemEditPriceListForm } from "../MenuItemEditPriceList/MenuItemEditPriceList";
import { MenuItemPriceList } from "../MenuItemPriceList/MenuItemPriceList";
import { useTranslation } from "react-i18next";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { SettingsTabsEnum } from "../../../MenuItemSettings/MenuItemSettings";
import { getIsPartner } from "@utils/url";

interface HeadingAddonProps {
  currency: string;
  priceLevelAddon?: ModelMenuAddon;
  hasOwnAddons: boolean;
  hasServiceTypeSpecificPrices: boolean;
  onShowCommonSettings: (initialTab?: SettingsTabsEnum) => void;
  price: string;
  topLevelPriceLevelPrices: PriceLevelPrices[];
  isNew: boolean;
  menuItemId: string;
  onChangePrices: (values: any) => void;
}

export const HeadingAddon: React.FC<HeadingAddonProps> = ({
  currency,
  priceLevelAddon,
  hasOwnAddons,
  hasServiceTypeSpecificPrices,
  onShowCommonSettings,
  price,
  topLevelPriceLevelPrices,
  isNew,
  menuItemId,
  onChangePrices,
}) => {
  const { t } = useTranslation(["addons"]);
  const menuFilters = useReactiveVar(menuFiltersVar);

  const [
    isEditingMode,
    enterEditingMode,
    leaveEditingMode,
  ] = usePriceEditingMode();
  const [isOriginalPriceEditingForm, setIsOriginalPriceEditingForm] = useState(
    false
  );

  const [
    shouldLeaveEditingModeOnSubmit,
    setShouldLeaveEditingModeOnSubmit,
  ] = useState(false);

  const handlePriceListClick = useCallback(() => {
    enterEditingMode();
    setIsOriginalPriceEditingForm(true);
  }, [enterEditingMode]);

  const handleLeaveEditingMode = useCallback(() => {
    setShouldLeaveEditingModeOnSubmit(false);
    leaveEditingMode();
  }, [leaveEditingMode]);

  const handleSubmit = useCallback(
    (values: any) => {
      shouldLeaveEditingModeOnSubmit && handleLeaveEditingMode();
      onChangePrices(values);
    },
    [handleLeaveEditingMode, onChangePrices, shouldLeaveEditingModeOnSubmit]
  );

  const checkIsTitleHighlighted = useCallback(
    (title: string) => {
      if (menuFilters.titleCont === undefined) return false;
      return title.toLowerCase().includes(menuFilters.titleCont.toLowerCase());
    },
    [menuFilters.titleCont]
  );

  const visiblePriceLevelAddonItems = useMemo(
    () => (priceLevelAddon ? getActiveEntities(priceLevelAddon.items) : []),
    [priceLevelAddon]
  );
  const visiblePriceLevels = useMemo(
    () =>
      priceLevelAddon ? getVisiblePriceLevels(visiblePriceLevelAddonItems) : [],
    [priceLevelAddon, visiblePriceLevelAddonItems]
  );

  const visiblePriceLevelPrices = useMemo(
    () =>
      priceLevelAddon
        ? getVisiblePriceLevelPrices(
            visiblePriceLevels,
            topLevelPriceLevelPrices
          )
        : [],
    [priceLevelAddon, topLevelPriceLevelPrices, visiblePriceLevels]
  );

  const leftColumnText = useMemo(
    () =>
      priceLevelAddon
        ? visiblePriceLevelAddonItems.map(({ title }, index, arr) => {
            const isHighlighted = checkIsTitleHighlighted(title);

            return (
              <React.Fragment key={index}>
                <span className={styles.title(isHighlighted)}>{title}</span>
                {index < arr.length - 1 && ", "}
              </React.Fragment>
            );
          })
        : "price",
    [checkIsTitleHighlighted, priceLevelAddon, visiblePriceLevelAddonItems]
  );

  const rightColumnText = useMemo(
    () =>
      priceLevelAddon
        ? visiblePriceLevelPrices
            .map(([, price]) => price)
            .map(protectPriceFromNull)
            .join(", ") + ` ${currency}`
        : `${price} ${currency}`,
    [currency, price, priceLevelAddon, visiblePriceLevelPrices]
  );

  const priceEditingModeData = useMemo(
    () =>
      generateFormDataForMenuItemAddonsSection({
        priceLevelAddon,
        menuItemPriceLevelPrices: topLevelPriceLevelPrices,
        menuItemPrice: price,
      }),
    [price, priceLevelAddon, topLevelPriceLevelPrices]
  );

  useEffect(() => {
    if (isEditingMode) return;
    setIsOriginalPriceEditingForm(false);
  }, [isEditingMode]);

  return (
    <AddonRowLayout
      data-testid="menu-item-addon--price-level"
      indicatorColor={hasOwnAddons ? "pink" : "yellow"}
      leftColumn={leftColumnText}
      rightColumn={
        hasServiceTypeSpecificPrices ? (
          <small>
            <span>{t("addons:prices-service-type-specific")}</span>
            {/* INT-82 - Hide Service Type specific prices UI in Partner Centre */}
            {!getIsPartner() && (
              <MmsBaseButton
                id={`menu-item-addon--prices-${menuItemId}`}
                className={styles.allPricesButton}
                onClick={() => onShowCommonSettings(SettingsTabsEnum.prices)}
              >
                {t("addons:prices-view-all")}
              </MmsBaseButton>
            )}
          </small>
        ) : isEditingMode ? (
          <MenuItemEditPriceListForm
            onSubmit={handleSubmit}
            formData={priceEditingModeData}
            menuItemId={menuItemId}
            setShouldLeaveEditingModeOnSubmit={
              setShouldLeaveEditingModeOnSubmit
            }
            leaveEditingMode={handleLeaveEditingMode}
            focusFirstField={isOriginalPriceEditingForm}
            shouldLeaveEditingModeOnSubmit={shouldLeaveEditingModeOnSubmit}
          />
        ) : (
          <MmsTooltip title={t("addons:click-to-edit-price")}>
            <div>
              <MenuItemPriceList
                priceList={rightColumnText}
                onPriceListClick={isNew ? noop : handlePriceListClick}
              />
            </div>
          </MmsTooltip>
        )
      }
    />
  );
};
