import { useReactiveVar } from "@apollo/client";
import { useDidUpdate } from "@hooks";
import { PopupNotificationTypesEnum } from "@model/helperTypes/Notification";
import { MmsNotification } from "@uiKit/Atoms/Notification/MmsNotification";
import { NOTIFICATION_DURATION } from "@uiKit/Atoms/Notification/MmsNotification/constants";
import { successNotificationsVar } from "@utils/apolloReactiveVars";
import throttle from "lodash.throttle";
import { uniq } from "ramda";
import React from "react";
import toast from "react-hot-toast";

export const useSuccessNotifications = (): void => {
  const successNotifications = useReactiveVar(successNotificationsVar);

  useDidUpdate(() => {
    if (!successNotifications.length) return;

    successNotifications.forEach((notificationMessage) => {
      toast(
        (t) => (
          <MmsNotification
            id={t.id}
            message={notificationMessage}
            type={PopupNotificationTypesEnum.success}
            handleClose={() => toast.dismiss(t.id)}
          />
        ),
        {
          duration: NOTIFICATION_DURATION,
        }
      );
    });

    successNotificationsVar([]);
  }, [successNotifications]);
};

export const addMultipleSuccessRegularNotifications = (
  successMessage: string[]
) => successNotificationsVar([...successNotificationsVar(), ...successMessage]);

export const addSuccessRegularNotification = (errorMessage: string) =>
  successNotificationsVar([...successNotificationsVar(), errorMessage]);

export const addUniqThrottledSuccessRegularNotification = throttle(
  (successMessage: string) =>
    successNotificationsVar(
      uniq([...successNotificationsVar(), successMessage])
    ),
  NOTIFICATION_DURATION,
  { trailing: false }
);
