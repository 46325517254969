import { ModelCategoryDNA } from "@model/DAO/MenuCategory";
import {
  AvailableEnum,
  LocalAvailabilityData,
} from "@model/helperTypes/Discount&Availability";
import { setDay } from "date-fns";

export const getInitLocalAvailabilityData = (
  activeFrom: ModelCategoryDNA["activeFrom"],
  activeTo: ModelCategoryDNA["activeTo"],
  disabledOnDays: ModelCategoryDNA["disabledOnDays"]
): LocalAvailabilityData => ({
  available: [activeFrom, activeTo].some(Boolean)
    ? AvailableEnum.restricted
    : AvailableEnum.always,
  activeFrom,
  activeTo,
  disabledOnDays,
});

export const decomposeLocalAvailabilityData = ({
  available,
  activeFrom,
  activeTo,
  disabledOnDays,
}: LocalAvailabilityData): Omit<LocalAvailabilityData, "available"> =>
  available === AvailableEnum.always
    ? {
        activeFrom: null,
        activeTo: null,
        disabledOnDays,
      }
    : { activeFrom, activeTo, disabledOnDays };

// Disabled days

export const getDayBitValue = (index: number) => Math.pow(2, index);

export const DAYS = Array.from(Array(7).keys()).map((i) => {
  const index = (i + 1) % 7;
  const day = setDay(new Date(), index);

  return {
    index,
    bitValue: getDayBitValue(index),
    nameShort: new Intl.DateTimeFormat(navigator.language, {
      weekday: "short",
    }).format(day),
    nameFull: new Intl.DateTimeFormat(navigator.language, {
      weekday: "long",
    }).format(day),
  };
});

export const allDaysBitValue = DAYS.map((day) => day.bitValue).reduce(
  (a, b) => a + b,
  0
);

export const isDayEnabled = (disabledOnDays: number | null, index: number) =>
  !((disabledOnDays || 0) & getDayBitValue(index));

export const getEnabledDays = (disabledOnDays: number | null) =>
  DAYS.filter((day) => isDayEnabled(disabledOnDays, day.index));
