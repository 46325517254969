import {
  NetworkStatus,
  useLazyQuery,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { formatMenuFiltersVars } from "@pages/RestaurantMenuPage/utils/MenuFilters";
import { menuFiltersVar, menuIdVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";
import {
  GetCategoryTypes,
  GET_CATEGORY,
} from "../../graphql/queries/GetCategory";
import {
  GetMenuCategoriesTypes,
  GET_MENU_CATEGORIES,
} from "../../graphql/queries/GetMenuCategories";

interface QueryResult<T> {
  loading: boolean;
  data?: T;
  refetch?: VoidFunction;
  networkStatus?: NetworkStatus;
}

export const useLazyGetMenuCategories = (): [
  VoidFunction,
  QueryResult<GetMenuCategoriesTypes.GetMenuCategoriesQuery>
] => {
  const menuId = useReactiveVar(menuIdVar);
  const filters = useReactiveVar(menuFiltersVar);
  const [, { onError }] = useErrorHandler([]);

  const [
    lazyQuery,
    result,
  ] = useLazyQuery<GetMenuCategoriesTypes.GetMenuCategoriesQuery>(
    GET_MENU_CATEGORIES,
    {
      notifyOnNetworkStatusChange: true,
      onError,
    }
  );

  const lazyQueryWithVars = useCallback(
    () =>
      lazyQuery({
        variables: { menuId, filters: formatMenuFiltersVars(filters) },
      }),
    [lazyQuery, filters, menuId]
  );

  const refetchWithVars = useCallback(() => {
    result.fetchMore?.({
      variables: {
        menuId,
        filters: formatMenuFiltersVars(filters),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult || prev;
      },
    });
  }, [filters, menuId, result]);

  return [lazyQueryWithVars, { ...result, refetch: refetchWithVars }];
};

export const useGetCurrentCategory = (
  categoryId: string
): ModelFullCategory => {
  const [, { onError }] = useErrorHandler([]);
  const { data } = useQuery<GetCategoryTypes.GetCategoryQuery>(GET_CATEGORY, {
    variables: { categoryId },
    onError,
  });

  return data!.category as ModelFullCategory;
};
