import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const EDIT_MENU_ITEM_TITLE = gql`
  mutation EditMenuItemTitleMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuItem {
        title
        id
      }
    }
  }
`;

export const generateEditMenuItemTitleVars = (
  restaurantId: string,
  menuItemId: string,
  newTitle: string
) => ({
  input: {
    restaurantId,
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
    menuItem: {
      title: newTitle,
    },
  },
});

export * as EditMenuItemTitleTypes from "./__generated__/EditMenuItemTitleMutation";
