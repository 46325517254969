import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./styles";

export interface MmsSeparatorProps {
  className?: string;
}

export const MmsSeparator: React.FC<MmsSeparatorProps> = ({ className }) => (
  <div className={cx(styles, className)} />
);
