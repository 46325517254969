import { cx } from "@emotion/css";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";

interface LibraryModalProps {
  children: React.ReactNode;
  closeModal: VoidFunction;
}

export const LibraryModal: React.FC<LibraryModalProps> = ({
  children,
  closeModal,
}) => {
  return (
    <MmsBaseModal
      contentClassName={cx("libraryModal")}
      onClose={closeModal}
      closeOnOutsideClick={true}
    >
      {children}
    </MmsBaseModal>
  );
};
