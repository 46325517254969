import { ModelCategoryWithMenuItemsAmount } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { MenuItemSkeleton } from "@pages/RestaurantMenuPage/components/MenuItem/MenuItemSkeleton/MenuItemSkeleton";
import { MenuItemListErrorMessage } from "@pages/RestaurantMenuPage/components/MenuItemList/MenuItemListErrorMessage/MenuItemListErrorMessage";
import { useGetMenuItems } from "@pages/RestaurantMenuPage/gqlHooks/menuItem/queries";
import React from "react";

export interface IncomingProps {
  parentCategory: ModelCategoryWithMenuItemsAmount;
}

export interface OutgoingProps extends IncomingProps {
  menuItems: ModelMenuItem[];
}

export const withMenuItems = (
  Component: React.ComponentType<OutgoingProps>
): React.FC<IncomingProps> => (props) => {
  const [menuItemsDataLoading, menuItemsData] = useGetMenuItems(
    props.parentCategory.id
  );

  if (menuItemsDataLoading) {
    return (
      <>
        {Array.from(Array(props.parentCategory.menuItemsAmount)).map(
          (_, index) => (
            <MenuItemSkeleton
              key={`menu-item-skeleton-${props.parentCategory.id}-${index}`}
            />
          )
        )}
      </>
    );
  }
  if (!menuItemsData) return <MenuItemListErrorMessage />;

  return (
    <Component
      {...props}
      menuItems={menuItemsData.category.items as ModelMenuItem[]}
    />
  );
};
