import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  popper: css`
    margin-top: 15px;

    z-index: 10;
  `,
  container: css`
    gap: 8px;

    padding: 15px 25px;
  `,
  button: css`
    gap: 15px;
    align-items: center;
  `,
  btnText: css`
    flex: 1;
    display: flex;

    font-family: ${themeVars.fontFamily};
    font-size: 16px;
    font-weight: 300;
    color: ${themeColors.black};

    cursor: pointer;
  `,
  link: css`
    &,
    &:hover {
      color: ${themeColors.black};
      text-decoration: none;
    }
  `,
  separator: css`
    margin: 3px 0;

    border-bottom: 1px solid ${themeColors.borderGreyTransparent};
  `,
  markerWrapper: css`
    position: relative;
  `,
  marker: css`
    right: -5px;
    top: 0px;
    width: 8px;
    height: 8px;
    position: absolute;

    border-radius: 4px;

    background: ${themeColors.red};
  `,
};
