import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { AddonTypeEnum } from "@model/helperTypes/Addon";
import { getNewOrderBy } from "@pages/RestaurantMenuPage/utils/GenericEntity";
import { MmsTabsCallbacksType } from "@uiKit/Molecules/Tabs/MmsTab/types";
import { generateUniqueKey } from "@utils/general";
import { useCallback, useState } from "react";
import {
  convertMenuAddonToLocalAddon,
  decomposeAddonType,
} from "../utils/Addon";
import { LocalAddonsCallbacksType } from "./useLocalAddons";
import { usePriceLevelAddon } from "./usePriceLevelAddon";

const getNewAddonFixture = (
  localAddons: ModelLocalAddon[],
  isPriceLevelTypeAvailable: boolean
): ModelLocalAddon => {
  return convertMenuAddonToLocalAddon({
    id: `temp-new-addon-${generateUniqueKey()}`,
    iwaiterId: null,
    title: "",
    active: true,
    priceLevel: isPriceLevelTypeAvailable,
    priceLevelDependent: !isPriceLevelTypeAvailable,
    selectOne: !isPriceLevelTypeAvailable,
    items: [],
    spiceType: 0,
    menuFlags: [],
    orderBy: getNewOrderBy<ModelLocalAddon>(localAddons),
    __typename: "MenuAddon",
  });
};

type LocalAddonsAPI = {
  localAddons: ModelLocalAddon[];
  create: LocalAddonsCallbacksType["create"];
};

type SelectedAddonsTabsAPI = {
  selectNewTab: MmsTabsCallbacksType["selectNewTab"];
  removeNewTab: MmsTabsCallbacksType["removeNewTab"];
};

type NewAddonAPI = {
  clearNewAddon: VoidFunction;
  toggleNewAddon: (autoenableTimestamp: number) => void;
  toggleNewAddonPriceLevelDependent: VoidFunction;
  changeNewAddonType: (addonId: string, newType: AddonTypeEnum) => void;
  prepareNewAddon: VoidFunction;
  createNewAddon: (addonId: string, newTitle: string) => void;
  changeNewAddonMinMax: (
    addonId: string,
    values: { min?: number; max?: number }
  ) => void;
};

type ReturnTupleType = [ModelLocalAddon | null, NewAddonAPI];

export const useNewAddon = (
  { localAddons, create }: LocalAddonsAPI,
  { selectNewTab, removeNewTab }: SelectedAddonsTabsAPI
): ReturnTupleType => {
  const [, isPriceLevelAddonAvailable] = usePriceLevelAddon(localAddons);
  const [newAddon, setNewAddon] = useState<ModelLocalAddon | null>(null);

  const clearNewAddon = useCallback(() => {
    setNewAddon(null);
    removeNewTab();
  }, [removeNewTab]);

  const toggleNewAddon = useCallback(
    (autoenableTimestamp: number) => {
      setNewAddon({
        ...newAddon!,
        active: !newAddon!.active,
        autoenableTimestamp,
      });
    },
    [newAddon]
  );

  const toggleNewAddonPriceLevelDependent = useCallback(() => {
    setNewAddon({
      ...newAddon!,
      priceLevelDependent: !newAddon!.priceLevelDependent,
    });
  }, [newAddon]);

  const changeNewAddonType = useCallback(
    (_, newType: AddonTypeEnum) => {
      setNewAddon({ ...newAddon!, ...decomposeAddonType(newType) });
    },
    [newAddon]
  );

  const changeNewAddonMinMax = useCallback(
    (_, values: { min?: number; max?: number }) => {
      setNewAddon({
        ...newAddon!,
        min: values.min,
        max: values.max,
      });
    },
    [newAddon]
  );

  const prepareNewAddon = useCallback(() => {
    const newAddon = getNewAddonFixture(
      localAddons,
      isPriceLevelAddonAvailable
    );

    setNewAddon(newAddon);
    selectNewTab();
  }, [isPriceLevelAddonAvailable, localAddons, selectNewTab]);

  const createNewAddon = useCallback(
    (_, newTitle: string) => {
      create({ ...newAddon!, title: newTitle });
      setNewAddon(null);
    },
    [create, newAddon]
  );

  return [
    newAddon,
    {
      clearNewAddon,
      toggleNewAddon,
      toggleNewAddonPriceLevelDependent,
      changeNewAddonType,
      prepareNewAddon,
      createNewAddon,
      changeNewAddonMinMax,
    },
  ];
};
