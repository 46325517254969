import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";

const i18InitOptions: InitOptions = {
  fallbackLng: "en",
  supportedLngs: ["en", "de", "dk", "pl"],
  backend: {
    projectId:
      process.env.REACT_APP_LOCIZE_PROJECTID ??
      "4e039ad6-77dd-4889-89ee-3e5baac1c06d",
    referenceLng: "en",
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18InitOptions);

export default i18n;
