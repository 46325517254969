import { ApolloError, Reference, useMutation } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { ModelAdditive } from "@model/DAO/Additive";
import { ModelAllergen } from "@model/DAO/Allergen";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuFlagId } from "@model/DAO/MenuFlag";
import { ModelCategoryMenuItems, ModelMenuItem } from "@model/DAO/MenuItem";
import {
  PositionDataType,
  TransferableData,
} from "@model/helperTypes/dragAndDrop";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { CATEGORY_ESSENTIAL_DATA } from "@pages/RestaurantMenuPage/graphql/fragments/Category";
import { CATEGORY_MENU_ITEMS } from "@pages/RestaurantMenuPage/graphql/fragments/MenuItem";
import {
  EditMenuItemSpiceTypeTypes,
  EDIT_MENU_ITEM_SPICE_TYPE,
  generateEditMenuItemSpiceTypeVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/EditMenuItemSpiceType";
import {
  generateTransferMenuItemVars,
  TransferMenuItemMutationTypes,
  TRANSFER_MENU_ITEM,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/TransferMenuItem";
import {
  generateUpdateMenuItemSettingsVars,
  generateUpdateMenuItemAdditivesVars,
  UpdateMenuItemAdditivesTypes,
  UPDATE_MENU_ITEM_SETTINGS,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/UpdateMenuItemAdditives";
import {
  generateUpdateMenuItemAllergensVars,
  UpdateMenuItemAllergensTypes,
  UPDATE_MENU_ITEM_ALLERGENS,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/UpdateMenuItemAllergens";
import {
  addSuccessRegularNotification,
  addUniqThrottledSuccessRegularNotification,
} from "@pages/RestaurantMenuPage/hooks/useSuccessNotifications";
import produce from "immer";
import { insert } from "ramda";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { LevelsRerank, TaxAssociationData } from "__generated__/globalTypes";
import {
  CloneMenuItemTypes,
  CLONE_MENU_ITEM,
  generateCloneMenuItemVars,
} from "../../graphql/mutatioins/CloneMenuItem";
import {
  CreateMenuItemTypes,
  CREATE_MENU_ITEM,
  generateCreateMenuItemVars,
} from "../../graphql/mutatioins/CreateMenuItem";
import {
  DestroyMenuItemTypes,
  DESTROY_MENU_ITEM,
  generateDestroyMenuItemVars,
} from "../../graphql/mutatioins/DestroyMenuItem";
import {
  EditMenuItemDescriptionTypes,
  EDIT_MENU_ITEM_DESCRIPTION,
  generateEditMenuItemDescriptionVars,
} from "../../graphql/mutatioins/EditMenuItemDescription";
import {
  EditMenuItemPricesTypes,
  EDIT_MENU_ITEM_PRICES,
  generateEditMenuItemPricesVars,
} from "../../graphql/mutatioins/EditMenuItemPrices";
import {
  EditMenuItemTitleTypes,
  EDIT_MENU_ITEM_TITLE,
  generateEditMenuItemTitleVars,
} from "../../graphql/mutatioins/EditMenuItemTitle";
import {
  generateRerankEntitiesVars,
  RerankEntitiesMutationType,
  RERANK_ENTITIES,
} from "../../graphql/mutatioins/RerankEntities";
import {
  generateToggleMenuItemVars,
  ToggleMenuItemTypes,
  TOGGLE_MENU_ITEM,
} from "../../graphql/mutatioins/ToggleMenuItem";
import {
  generateToggleMenuItemHasOwnAddonsVars,
  ToggleMenuItemHasOwnAddonsTypes,
  TOGGLE_MENU_ITEM_HAS_OWN_ADDONS,
} from "../../graphql/mutatioins/ToggleMenuItemHasOwnAddons";
import {
  generateUpdateMenuItemAddonsVars,
  UpdateMenuItemAddonsTypes,
  UPDATE_MENU_ITEM_ADDONS,
} from "../../graphql/mutatioins/UpdateMenuItemAddons";
import {
  generateUpdateMenuItemImageVars,
  UpdateMenuItemImageTypes,
  UPDATE_MENU_ITEM_IMAGE,
} from "../../graphql/mutatioins/UpdateMenuItemImage";
import { useDuplicateMenuAssetValidation } from "../../hooks/useDuplicateMenuAssetValidation";
import { LocalAddonsReducerStateType } from "../../hooks/useLocalAddons";
import { MatchParams } from "../../RestaurantMenuPage";
import { sortableEntitiesToPositionDataType } from "../../utils/rerank";
import {
  EditMenuItemSkuTypes,
  EDIT_MENU_ITEM_SKU,
  generateEditMenuItemSkuVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/EditMenuItemSku";
import { MenuItemPricesData } from "@model/helperTypes/Prices";

export const useToggleMenuItem = (
  menuItemId: string,
  active: boolean
): [(autoenableTimestamp: number) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<ToggleMenuItemTypes.ToggleMenuItemMutation>(
    TOGGLE_MENU_ITEM,
    {
      onError,
    }
  );

  const mutationWithVars = useCallback(
    (autoenableTimestamp: number) => {
      mutation({
        variables: generateToggleMenuItemVars(
          restaurantId,
          menuItemId,
          active,
          autoenableTimestamp
        ),
        optimisticResponse: {
          mmsUpdate: {
            __typename: "MenuItem",
            id: menuItemId,
            active: !active,
          },
        },
      });
    },
    [active, menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useEditMenuItemTitle = (
  categoryId: string,
  menuItemId: string
): [(newTitle: string) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [onCheckDuplication] = useDuplicateMenuAssetValidation();

  const [
    mutation,
    { loading },
  ] = useMutation<EditMenuItemTitleTypes.EditMenuItemTitleMutation>(
    EDIT_MENU_ITEM_TITLE,
    {
      onError,
    }
  );

  const mutationWithVars = useCallback(
    (newTitle: string) => {
      mutation({
        variables: generateEditMenuItemTitleVars(
          restaurantId,
          menuItemId,
          newTitle
        ),
      }).then(() =>
        onCheckDuplication(MenuAssetEnum.menu_item, categoryId, newTitle)
      );
    },
    [categoryId, menuItemId, mutation, onCheckDuplication, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useEditMenuItemSku = (
  menuItemId: string,
  onCompleted: VoidFunction
): [(newSku: string) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<EditMenuItemSkuTypes.EditMenuItemSkuMutation>(
    EDIT_MENU_ITEM_SKU,
    { onError, onCompleted }
  );

  const mutationWithVars = useCallback(
    (newSku: string) => {
      mutation({
        variables: generateEditMenuItemSkuVars(
          restaurantId,
          menuItemId,
          newSku
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useEditMenuItemDescription = (
  menuItemId: string,
  onCompleted: VoidFunction
): [(newDescription: string) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<EditMenuItemDescriptionTypes.EditMenuItemDescriptionMutation>(
    EDIT_MENU_ITEM_DESCRIPTION,
    { onError, onCompleted }
  );

  const mutationWithVars = useCallback(
    (newDescription: string) => {
      mutation({
        variables: generateEditMenuItemDescriptionVars(
          restaurantId,
          menuItemId,
          newDescription
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useCreateMenuItem = (
  categoryId: string
): [(newTitle: string, active: boolean) => void, boolean] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);
  const [onCheckDuplication] = useDuplicateMenuAssetValidation();

  const [
    mutation,
    { loading },
  ] = useMutation<CreateMenuItemTypes.CreateMenuItemMutation>(
    CREATE_MENU_ITEM,
    { onError }
  );

  const mutationWithVars = useCallback(
    (newTitle: string, active: boolean) => {
      mutation({
        variables: generateCreateMenuItemVars(
          restaurantId,
          categoryId,
          newTitle,
          active
        ),
        update(cache, { data }) {
          const newMenuItem = data!.mmsAdd as ModelMenuItem;

          const categoryMenuItemsData = cache.readFragment<ModelCategoryMenuItems>(
            {
              id: `MenuCategory:${categoryId}`,
              fragment: CATEGORY_MENU_ITEMS,
              fragmentName: "CategoryMenuItems",
            }
          );

          const fullCategoryData = cache.readFragment<ModelFullCategory>({
            id: `MenuCategory:${categoryId}`,
            fragment: CATEGORY_ESSENTIAL_DATA,
            fragmentName: "CategoryEssentialData",
          });

          cache.writeFragment<ModelCategoryMenuItems>({
            id: `MenuCategory:${categoryId}`,
            fragment: CATEGORY_MENU_ITEMS,
            fragmentName: "CategoryMenuItems",
            data: produce(
              categoryMenuItemsData!,
              (newCategoryMenuItemsData) => {
                newCategoryMenuItemsData.items.push(newMenuItem);
              }
            ),
          });

          cache.writeFragment<ModelFullCategory>({
            id: `MenuCategory:${categoryId}`,
            fragment: CATEGORY_ESSENTIAL_DATA,
            fragmentName: "CategoryEssentialData",
            data: produce(fullCategoryData!, (newFullCategoryData) => {
              const { id, __typename } = newMenuItem;
              newFullCategoryData.items.push({ id, categoryId, __typename });
            }),
          });
        },
      }).then(() =>
        onCheckDuplication(MenuAssetEnum.menu_item, categoryId, newTitle)
      );
    },
    [categoryId, mutation, onCheckDuplication, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useDestroyMenuItem = (
  categoryId: string,
  menuItemId: string
): [VoidFunction, boolean] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<DestroyMenuItemTypes.DestroyMenuItemMutation>(
    DESTROY_MENU_ITEM,
    { onError }
  );

  const mutationWithVars = useCallback(() => {
    mutation({
      variables: generateDestroyMenuItemVars(restaurantId, menuItemId),
      update(cache) {
        const categoryMenuItemsData = cache.readFragment<ModelCategoryMenuItems>(
          {
            id: `MenuCategory:${categoryId}`,
            fragment: CATEGORY_MENU_ITEMS,
            fragmentName: "CategoryMenuItems",
          }
        );

        const fullCategoryData = cache.readFragment<ModelFullCategory>({
          id: `MenuCategory:${categoryId}`,
          fragment: CATEGORY_ESSENTIAL_DATA,
          fragmentName: "CategoryEssentialData",
        });

        cache.writeFragment<ModelCategoryMenuItems>({
          id: `MenuCategory:${categoryId}`,
          fragment: CATEGORY_MENU_ITEMS,
          fragmentName: "CategoryMenuItems",
          data: produce(categoryMenuItemsData!, (newCategoryMenuItemsData) => {
            newCategoryMenuItemsData.items = newCategoryMenuItemsData.items.filter(
              ({ id }) => id !== menuItemId
            );
          }),
        });

        cache.writeFragment<ModelFullCategory>({
          id: `MenuCategory:${categoryId}`,
          fragment: CATEGORY_ESSENTIAL_DATA,
          fragmentName: "CategoryEssentialData",
          data: produce(fullCategoryData!, (newFullCategoryData) => {
            newFullCategoryData.items = newFullCategoryData.items.filter(
              ({ id }) => id !== menuItemId
            );
          }),
        });
      },
    });
  }, [categoryId, menuItemId, mutation, restaurantId]);

  return [mutationWithVars, loading];
};

export const useUpdateMenuItemAddons = (
  menuItemId: string
): [
  (differentAddons: LocalAddonsReducerStateType) => void,
  boolean,
  ApolloError | undefined
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading, error },
  ] = useMutation<UpdateMenuItemAddonsTypes.UpdateMenuItemAddonsMutation>(
    UPDATE_MENU_ITEM_ADDONS,
    { onError }
  );

  const mutationWithVars = useCallback(
    (differentAddons: LocalAddonsReducerStateType) => {
      mutation({
        variables: generateUpdateMenuItemAddonsVars(
          restaurantId,
          menuItemId,
          differentAddons
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading, error];
};

export const useToggleMenuItemHasOwnAddons = (
  menuItemId: string,
  hasOwnAddons: boolean
): [VoidFunction, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<ToggleMenuItemHasOwnAddonsTypes.ToggleMenuItemHasOwnAddonsMutation>(
    TOGGLE_MENU_ITEM_HAS_OWN_ADDONS,
    {
      variables: generateToggleMenuItemHasOwnAddonsVars(
        restaurantId,
        menuItemId,
        hasOwnAddons
      ),
      onError,
    }
  );

  return [mutation, loading];
};

export const useUpdateMenuItemImage = (
  menuItemId: string,
  categoryId: string
): [(imageId: string | null) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<UpdateMenuItemImageTypes.UpdateMenuItemImageMutation>(
    UPDATE_MENU_ITEM_IMAGE,
    { onError }
  );

  const mutationWithVars = useCallback(
    (imageId: string | null) => {
      mutation({
        variables: generateUpdateMenuItemImageVars(
          restaurantId,
          menuItemId,
          imageId
        ),
        update(cache, { data }) {
          const categoryMenuItemsData = cache.readFragment<ModelCategoryMenuItems>(
            {
              id: `MenuCategory:${categoryId}`,
              fragment: CATEGORY_MENU_ITEMS,
              fragmentName: "CategoryMenuItems",
            }
          );

          cache.writeFragment<ModelCategoryMenuItems>({
            id: `MenuCategory:${categoryId}`,
            fragment: CATEGORY_MENU_ITEMS,
            fragmentName: "CategoryMenuItems",
            data: produce(
              categoryMenuItemsData!,
              (newCategoryMenuItemsData) => {
                const newMenuItemData = {
                  ...data!.mmsUpdate,
                } as UpdateMenuItemImageTypes.UpdateMenuItemImageMutation_mmsUpdate_MenuItem;

                newCategoryMenuItemsData.items.find(
                  ({ id }) => id === menuItemId
                )!.image = newMenuItemData.image;
              }
            ),
          });
        },
      });
    },
    [categoryId, menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useEditMenuItemPrices = (
  menuItemId: string
): [(newPrices: any) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<EditMenuItemPricesTypes.EditMenuItemPricesMutation>(
    EDIT_MENU_ITEM_PRICES,
    { onError }
  );

  const mutationWithVars = useCallback(
    (newPrices: any) => {
      mutation({
        variables: generateEditMenuItemPricesVars(
          restaurantId,
          menuItemId,
          newPrices
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useCloneMenuItem = (
  categoryId: string,
  clonedMenuItemId: string
): [VoidFunction, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addUniqThrottledSuccessRegularNotification(
        "Menu item has been successfully cloned"
      ),
    []
  );

  const [
    mutation,
    { loading },
  ] = useMutation<CloneMenuItemTypes.CloneMenuItemMutation>(CLONE_MENU_ITEM, {
    onError,
    onCompleted: handleCompleted,
  });

  const mutationWithVars = useCallback(() => {
    mutation({
      variables: generateCloneMenuItemVars(clonedMenuItemId),
      update(cache, { data }) {
        const clonedMenuItem = data?.mmsClone as ModelMenuItem;

        const categoryMenuItemsData = cache.readFragment<ModelCategoryMenuItems>(
          {
            id: `MenuCategory:${categoryId}`,
            fragment: CATEGORY_MENU_ITEMS,
            fragmentName: "CategoryMenuItems",
          }
        );

        const fullCategoryData = cache.readFragment<ModelFullCategory>({
          id: `MenuCategory:${categoryId}`,
          fragment: CATEGORY_ESSENTIAL_DATA,
          fragmentName: "CategoryEssentialData",
        });

        cache.writeFragment<ModelCategoryMenuItems>({
          id: `MenuCategory:${categoryId}`,
          fragment: CATEGORY_MENU_ITEMS,
          fragmentName: "CategoryMenuItems",
          data: produce(categoryMenuItemsData!, (newCategoryMenuItemsData) => {
            const { items } = newCategoryMenuItemsData;
            const clonedMenuItemIndex = items.findIndex(
              (item) => item.id === clonedMenuItemId
            );
            newCategoryMenuItemsData.items = insert(
              clonedMenuItemIndex + 1,
              clonedMenuItem,
              items
            );
          }),
        });

        cache.writeFragment<ModelFullCategory>({
          id: `MenuCategory:${categoryId}`,
          fragment: CATEGORY_ESSENTIAL_DATA,
          fragmentName: "CategoryEssentialData",
          data: produce(fullCategoryData!, (newFullCategoryData) => {
            const { items } = newFullCategoryData;
            const { id, __typename } = clonedMenuItem;
            const clonedMenuItemIndex = items.findIndex(
              (item) => item.id === clonedMenuItemId
            );
            newFullCategoryData.items = insert(
              clonedMenuItemIndex + 1,
              { id, categoryId, __typename },
              items
            );
          }),
        });
      },
    });
  }, [categoryId, clonedMenuItemId, mutation]);

  return [mutationWithVars, loading];
};

export const useRerankMenuItems = (
  areaType: LevelsRerank.CATEGORY,
  areaId: string
): [(reorderedMenuItems: ModelMenuItem[]) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<RerankEntitiesMutationType.RerankEntitiesMutation>(
    RERANK_ENTITIES,
    { onError }
  );

  const mutationWithVars = useCallback(
    (reorderedMenuItems: ModelMenuItem[]) => {
      const positions: PositionDataType[] = sortableEntitiesToPositionDataType<ModelMenuItem>(
        reorderedMenuItems
      );

      mutation({
        variables: generateRerankEntitiesVars(
          restaurantId,
          areaType,
          areaId,
          positions
        ),
        update(cache) {
          const categoryMenuItemsData = cache.readFragment<ModelCategoryMenuItems>(
            {
              id: `MenuCategory:${areaId}`,
              fragment: CATEGORY_MENU_ITEMS,
              fragmentName: "CategoryMenuItems",
            }
          );

          const fullCategoryData = cache.readFragment<ModelFullCategory>({
            id: `MenuCategory:${areaId}`,
            fragment: CATEGORY_ESSENTIAL_DATA,
            fragmentName: "CategoryEssentialData",
          });

          cache.writeFragment<ModelCategoryMenuItems>({
            id: `MenuCategory:${areaId}`,
            fragment: CATEGORY_MENU_ITEMS,
            fragmentName: "CategoryMenuItems",
            data: produce(
              categoryMenuItemsData!,
              (newCategoryMenuItemsData) => {
                newCategoryMenuItemsData.items = reorderedMenuItems;
              }
            ),
          });

          cache.writeFragment<ModelFullCategory>({
            id: `MenuCategory:${areaId}`,
            fragment: CATEGORY_ESSENTIAL_DATA,
            fragmentName: "CategoryEssentialData",
            data: produce(fullCategoryData!, (newFullCategoryData) => {
              newFullCategoryData.items = reorderedMenuItems.map(
                ({ id, __typename }) => ({ id, categoryId: areaId, __typename })
              );
            }),
          });
        },
      });
    },
    [areaId, areaType, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useTransferMenuItem = (): [
  (transferedData: TransferableData) => void,
  boolean
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<TransferMenuItemMutationTypes.TransferMenuItemMutation>(
    TRANSFER_MENU_ITEM,
    { onError }
  );

  const mutationWithVars = useCallback(
    ({ itemId, parentIdFrom, parentIdTo }: TransferableData) => {
      mutation({
        variables: generateTransferMenuItemVars(
          restaurantId,
          itemId,
          parentIdTo
        ),
        optimisticResponse: {
          mmsTransferItem: true,
        },
        update(cache) {
          let transferedMenuItemRef: Reference;

          cache.modify({
            id: `MenuCategory:${parentIdFrom}`,
            fields: {
              items(menuItems, { readField }) {
                const transferedItemIndex = menuItems.findIndex(
                  (menuItem: any) => readField("id", menuItem) === itemId
                );
                transferedMenuItemRef = menuItems[transferedItemIndex];

                return produce(menuItems, (newMenuItems: any) => {
                  delete newMenuItems[transferedItemIndex];
                });
              },
            },
          });

          cache.modify({
            id: `MenuCategory:${parentIdTo}`,
            fields: {
              items(menuItems) {
                return produce(menuItems, (newMenuItems: any) => {
                  newMenuItems.push(transferedMenuItemRef);
                });
              },
            },
          });
        },
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useUpdateMenuItemAllergens = (
  menuItemId: string,
  menuItemTitle: string
): [(allergenIds: ModelAllergen["id"][]) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addSuccessRegularNotification(
        `The allergens for the "${menuItemTitle}" item were successfully updated!`
      ),
    [menuItemTitle]
  );
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<UpdateMenuItemAllergensTypes.UpdateMenuItemAllergensMutation>(
    UPDATE_MENU_ITEM_ALLERGENS,
    { onError, onCompleted: handleCompleted }
  );

  const mutationWithVars = useCallback(
    (allergenIds: ModelAllergen["id"][]) => {
      mutation({
        variables: generateUpdateMenuItemAllergensVars(
          restaurantId,
          menuItemId,
          allergenIds
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useUpdateMenuItemAdditives = (
  menuItemId: string,
  menuItemTitle: string
): [(additiveIds: ModelAdditive["id"][]) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addSuccessRegularNotification(
        `The additives for the "${menuItemTitle}" item were successfully updated!`
      ),
    [menuItemTitle]
  );
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<UpdateMenuItemAdditivesTypes.UpdateMenuItemAdditivesMutation>(
    UPDATE_MENU_ITEM_SETTINGS,
    { onError, onCompleted: handleCompleted }
  );

  const mutationWithVars = useCallback(
    (additiveIds: ModelAdditive["id"][]) => {
      mutation({
        variables: generateUpdateMenuItemAdditivesVars(
          restaurantId,
          menuItemId,
          additiveIds
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useUpdateMenuItemSettings = (
  menuItemId: string,
  menuItemTitle: string
): [
  (
    additiveIds: ModelAdditive["id"][],
    allergenIds: ModelAllergen["id"][],
    menuFlagIds: ModelMenuFlagId["id"][],
    spiceType: number,
    localTaxes: TaxAssociationData[],
    localPrices: MenuItemPricesData
  ) => void,
  boolean
] => {
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addSuccessRegularNotification(
        `Settings for the "${menuItemTitle}" item were successfully updated!`
      ),
    [menuItemTitle]
  );
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<UpdateMenuItemAdditivesTypes.UpdateMenuItemAdditivesMutation>(
    UPDATE_MENU_ITEM_SETTINGS,
    { onError, onCompleted: handleCompleted }
  );

  const mutationWithVars = useCallback(
    (
      additiveIds: ModelAdditive["id"][],
      allergenIds: ModelAllergen["id"][],
      menuFlagIds: ModelMenuFlagId["id"][],
      spiceType: number,
      localTaxes: TaxAssociationData[],
      localPrices: MenuItemPricesData
    ) => {
      mutation({
        variables: generateUpdateMenuItemSettingsVars(
          restaurantId,
          menuItemId,
          additiveIds,
          allergenIds,
          menuFlagIds,
          spiceType,
          localTaxes,
          localPrices,
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useEditMenuItemSpiceType = (
  menuItemId: string,
  onCompleted: VoidFunction
): [(spiceType: number) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<EditMenuItemSpiceTypeTypes.EditMenuItemSpiceTypeMutation>(
    EDIT_MENU_ITEM_SPICE_TYPE,
    { onError, onCompleted }
  );

  const mutationWithVars = useCallback(
    (spiceType: number) => {
      mutation({
        variables: generateEditMenuItemSpiceTypeVars(
          restaurantId,
          menuItemId,
          spiceType
        ),
      });
    },
    [menuItemId, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};
