import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: (isError: boolean, errorColor: string = themeColors.red) => css`
    position: relative;
    display: inline-block;

    border: solid 1px ${isError ? errorColor : "transparent"};
  `,
};
