import { css } from "@emotion/css";

export const styles = {
  container: css`
    min-width: 100%;
    padding: 21px;
  `,
  headerSkeleton: css`
    height: 40px;
    padding-bottom: 6px;
  `,
  mainContentCell: css`
    flex: 1;
    min-height: 0;
    gap: 19px;
    padding: 47px 0;
  `,
  descriptionSkeleton: css`
    height: 65px;
  `,
  detailsSkeleton: css`
    height: 47px;
  `,
  footerCell: css`
    padding-top: 6px;
  `,
};
