import { gql } from "@apollo/client";
import { MENU_FLAG_ESSENTIAL_DATA } from "../../fragments/MenuFlag";

export const Get_Menu_Flags = gql`
  query GetMenuFlags {
    spiceTypes
    menuFlags {
      ...MenuFlagEssentialData
    }
  }
  ${MENU_FLAG_ESSENTIAL_DATA}
`;

export * as GetMenuFlagsTypes from "./__generated__/GetMenuFlagsQuery";
