import { gql } from "@apollo/client";
import { ModelCategoryMenuFlags } from "@model/DAO/MenuCategory";
import { Levels } from "__generated__/globalTypes";
import { MENU_FLAG_ESSENTIAL_DATA } from "@pages/RestaurantMenuPage/graphql/fragments/MenuFlag";

export const EDIT_CATEGORY_MENU_FLAGS = gql`
  mutation EditCategoryMenuFlagsMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuCategory {
        id
        menuFlags {
          ...MenuFlagEssentialData
        }
      }
    }
  }
  ${MENU_FLAG_ESSENTIAL_DATA}
`;

export const generateEditCategoryMenuFlagsVars = (
  restaurantId: string,
  categoryId: string,
  { id, menuFlags }: ModelCategoryMenuFlags
) => {
  return {
    input: {
      restaurantId,
      entityType: Levels.CATEGORY,
      entityId: categoryId,
      category: {
        id,
        menuFlags: menuFlags.map(({ id }) => parseInt(id)),
      },
    },
  };
};

export * as EditCategoryMenuFlagsTypes from "./__generated__/EditCategoryMenuFlagsMutation";
