import { useQuery } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import {
  GetMenuStateTypes,
  GET_MENU_STATE,
} from "@pages/RestaurantMenuPage/graphql/queries/GetMenuState";
import { useParams } from "react-router-dom";
import {
  GetDraftMenuTypes,
  GET_DRAFT_MENU,
} from "../../graphql/queries/GetDraftMenu";
import { MatchParams } from "../../RestaurantMenuPage";

export const useGetDraftMenu = (): [
  boolean,
  GetDraftMenuTypes.GetDraftMenuQuery?
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();

  const { loading, data } = useQuery<GetDraftMenuTypes.GetDraftMenuQuery>(
    GET_DRAFT_MENU,
    {
      variables: { restaurantId },
      onError,
    }
  );

  return [loading, data];
};

export const useGetMenuState = (): [
  boolean,
  GetMenuStateTypes.GetMenuStateQuery | undefined
] => {
  const { restaurantId } = useParams<MatchParams>();

  const { loading, data } = useQuery<GetMenuStateTypes.GetMenuStateQuery>(
    GET_MENU_STATE,
    {
      variables: { restaurantId },
    }
  );

  return [loading, data];
};
