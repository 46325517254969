/* eslint-disable react-hooks/exhaustive-deps */
import { cx } from "@emotion/css";
import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";
import { MmsTextInput } from "@uiKit/Atoms/Inputs/MmsTextInput";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import { styles } from "./MenuItemEditSku.styles";

interface FormValues {
  title: string;
}

export interface MenuItemEditSkuProps {
  menuItemId: string;
  maxlength?: number;
  isFormSubmitting: boolean;
  onSubmitEnded: VoidFunction;
  onPressEsc: VoidFunction;
}

const MenuItemEditSku: React.FC<
  MenuItemEditSkuProps & FormikProps<FormValues>
> = ({
  menuItemId,
  maxlength,
  isFormSubmitting,
  onSubmitEnded,
  onPressEsc,
  ...formikProps
}) => {
  const formId = `menu-item-edit-sku-${menuItemId}`;
  const [
    activeForms,
    addToActiveForms,
    removeFromActiveForms,
  ] = useActiveForms();

  const handleBlur = useCallback(() => {
    formikProps.submitForm();
  }, []);

  const handlePressEsc = useCallback(() => {
    onSubmitEnded();
    onPressEsc();
  }, []);

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useDidUpdate(() => {
    if (!isFormSubmitting) onSubmitEnded();
  }, [isFormSubmitting]);

  useEffect(() => {
    if (formikProps.isValid) return;

    if (!!activeForms.length) {
      handlePressEsc();
    }
  }, [formikProps.isValid, activeForms]);

  return (
    <Form className={styles.form}>
      <MmsTextInput
        data-testid={formId}
        name="title"
        type="text"
        placeholder="Enter article number here"
        disabled={isFormSubmitting}
        maxlength={maxlength}
        focusOnMount
        showErrorMessage
        onBlur={handleBlur}
        onPressEsc={handlePressEsc}
        fieldClassName={cx("EditMenuItemInput", styles.input)}
      />
    </Form>
  );
};

interface MenuItemEditSkuFormProps extends MenuItemEditSkuProps {
  title?: string;
  onSubmit: (values: any) => void;
}

// Wrap our form with the withFormik HoC
export const MenuItemEditSkuForm = withFormik<
  MenuItemEditSkuFormProps,
  FormValues
>({
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    title: props.title || "",
  }),
  handleSubmit: (values, { props }) => {
    values.title !== props.title
      ? props.onSubmit(values)
      : props.onSubmitEnded();
  },
})(MenuItemEditSku);
