import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

export const styles = {
  header: css`
    padding: 10px 22px;

    border-bottom: 2px solid ${themeColors.cream};

    background-color: ${themeColors.white};

    font-size: 20px;
    font-weight: bold;
  `,
  body: css`
    height: calc(100% - 52px);

    .MmsTableContainer,
    table {
      height: 100%;
    }

    tbody {
      display: block;
      overflow: auto;

      height: calc(100% - 49px);

      ${themeUtils.scrollBar}
    }

    thead,
    tbody tr {
      display: table;
      table-layout: fixed;

      width: 100%;
    }
  `,
  tableHeader: css`
    background-color: ${themeColors.borderLightGrey};
  `,
  tableCell: css`
    padding: 12px !important;

    text-align: left !important;

    button {
      margin: 0 5px;
    }

    &:first-child {
      padding-left: 22px !important;

      width: 25%;
    }

    &:last-child {
      padding-right: 22px !important;

      text-align: center !important;

      width: 12%;
    }
  `,
  footer: css`
    display: flex;
    justify-content: flex-end;

    padding: 25px 30px;
  `,
};
