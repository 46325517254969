import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  mutation RefreshTokenMutation($input: AuthRefreshData!) {
    authRefresh(input: $input) {
      expiry
      jwtToken
      refreshToken
    }
  }
`;

export * as RefreshTokenTypes from "./__generated__/RefreshTokenMutation";
