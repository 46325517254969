import { useReactiveVar } from "@apollo/client";
import { SELECTED_IMAGES_DEFAULTS } from "@constants";
import {
  GalleryImagesSelectionDirectionEnum,
  ISelectedGalleryImages,
} from "@model/helperTypes/Gallery";
import {
  galleryImagesIdsVar,
  selectedGalleryImagesVar,
} from "@utils/apolloReactiveVars";
import produce from "immer";
import { useCallback } from "react";

type ReturnTuple = [
  string[],
  {
    selectImage: (id: string, e?: React.MouseEvent) => void;
    resetSelectedImages: VoidFunction;
  }
];

interface ISelectionMetaData {
  firstSelectedImageIndex: number;
  imageToSelectIndex: number;
}

const getSelectionMetaData = (
  firstSelectedImageId: string,
  imageIdToSelect: string,
  galleryImagesIds: string[]
): ISelectionMetaData => {
  const firstSelectedImageIndex = galleryImagesIds.findIndex(
    (id) => id === firstSelectedImageId
  );
  const imageToSelectIndex = galleryImagesIds.findIndex(
    (id) => id === imageIdToSelect
  );

  return {
    firstSelectedImageIndex,
    imageToSelectIndex,
  };
};

const getSelectionDirection = (
  firstSelectedImageIndex: number,
  imageToSelectIndex: number
): GalleryImagesSelectionDirectionEnum => {
  return firstSelectedImageIndex > imageToSelectIndex
    ? GalleryImagesSelectionDirectionEnum.left
    : GalleryImagesSelectionDirectionEnum.right;
};

const getNewSelectedImagesIds = (
  galleryImagesIds: string[],
  currentSelectedImagesIds: string[],
  selectionDirection: GalleryImagesSelectionDirectionEnum,
  imageIdToSelect: string,
  e?: React.MouseEvent
): ISelectedGalleryImages => {
  if (!currentSelectedImagesIds.length)
    return { selectionDirection, selectedImagesIds: [imageIdToSelect] };
  if (
    currentSelectedImagesIds.length === 1 &&
    currentSelectedImagesIds.includes(imageIdToSelect)
  )
    return { selectionDirection, selectedImagesIds: [imageIdToSelect] };

  const [firstSelectedImageId] = currentSelectedImagesIds;
  const { firstSelectedImageIndex, imageToSelectIndex } = getSelectionMetaData(
    firstSelectedImageId,
    imageIdToSelect,
    galleryImagesIds
  );

  switch (true) {
    case e?.shiftKey: {
      const newSelectionDirection = getSelectionDirection(
        firstSelectedImageIndex,
        imageToSelectIndex
      );

      const newSelectedImagesIds = produce(
        galleryImagesIds,
        (draftGalleryImagesIds) => {
          const [start, deleteCount] =
            newSelectionDirection === GalleryImagesSelectionDirectionEnum.left
              ? [
                  imageToSelectIndex,
                  firstSelectedImageIndex - imageToSelectIndex + 1,
                ]
              : [
                  firstSelectedImageIndex,
                  imageToSelectIndex - firstSelectedImageIndex + 1,
                ];
          const selectedImagesIds = [...draftGalleryImagesIds].splice(
            start,
            deleteCount
          );

          return newSelectionDirection ===
            GalleryImagesSelectionDirectionEnum.left
            ? selectedImagesIds.reverse()
            : selectedImagesIds;
        }
      );

      return {
        selectionDirection: newSelectionDirection,
        selectedImagesIds: newSelectedImagesIds,
      };
    }
    case e?.ctrlKey:
    case e?.metaKey: {
      const isCurrentImageSelected = !!currentSelectedImagesIds.find(
        (selectedImageId) => selectedImageId === imageIdToSelect
      );

      if (isCurrentImageSelected) {
        const newSelectedImagesIds = currentSelectedImagesIds.filter(
          (selectedImageId) => selectedImageId !== imageIdToSelect
        );
        return { selectionDirection, selectedImagesIds: newSelectedImagesIds };
      } else {
        let newSelectedImagesIds;
        const newSelectionDirection = getSelectionDirection(
          firstSelectedImageIndex,
          imageToSelectIndex
        );

        if (newSelectionDirection === selectionDirection) {
          newSelectedImagesIds = [...currentSelectedImagesIds, imageIdToSelect];
        } else {
          const reversedCurrentSelectedImagesIds = [
            ...currentSelectedImagesIds,
          ].reverse();

          newSelectedImagesIds = [
            ...reversedCurrentSelectedImagesIds,
            imageIdToSelect,
          ];
        }

        return { selectionDirection, selectedImagesIds: newSelectedImagesIds };
      }
    }
    default: {
      return { selectionDirection, selectedImagesIds: [imageIdToSelect] };
    }
  }
};

export const useSelectedGalleryImages = (): ReturnTuple => {
  const galleryImagesIds = useReactiveVar(galleryImagesIdsVar);

  const selectImage = useCallback(
    (id: string, e?: React.MouseEvent) => {
      const {
        selectionDirection,
        selectedImagesIds,
      } = selectedGalleryImagesVar();

      const newSelectedGalleryImages = getNewSelectedImagesIds(
        galleryImagesIds,
        selectedImagesIds,
        selectionDirection,
        id,
        e
      );

      selectedGalleryImagesVar(newSelectedGalleryImages);
    },
    [galleryImagesIds]
  );

  const resetSelectedImages = useCallback(
    () => selectedGalleryImagesVar(SELECTED_IMAGES_DEFAULTS),
    []
  );

  return [
    useReactiveVar(selectedGalleryImagesVar).selectedImagesIds,
    { selectImage, resetSelectedImages },
  ];
};
