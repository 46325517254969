import React from "react";
import { MmsBaseButtonProps } from "../MmsBaseButton";
import { MmsIconTitleButton } from "../MmsIconTextButton";
import { styles } from "./styles";

export interface MmsAddNewEntityButtonProps extends MmsBaseButtonProps {
  disabled?: boolean;
  btnTitle: string;
  containerClassName?: string;
  className?: string;
}

export const MmsAddNewEntityButton: React.FC<MmsAddNewEntityButtonProps> = ({
  btnTitle,
  ...restProps
}) => {
  return (
    <MmsIconTitleButton
      btnIcon={<i className={styles.icon} />}
      btnTitle={btnTitle}
      {...restProps}
    />
  );
};
