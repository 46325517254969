import { useReactiveVar } from "@apollo/client";
import {
  useActiveAddon,
  useActiveAssets,
  useActiveProgressBars,
  useAddonsLibraryModal,
  useErrorCatch,
  useOpenClose,
  useToggle,
} from "@hooks";
import {
  ModelCategoryWithMenuItemsAmount,
  ModelFullCategory,
} from "@model/DAO/MenuCategory";
import { RerankableEntity } from "@model/helperTypes/dragAndDrop";
import { MenuStateEnum } from "@model/helperTypes/Menu";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { AutoenableSettings } from "@pages/RestaurantMenuPage/components/AutoenableSettings/AutoenableSettings";
import { BulkEditingPanel } from "@pages/RestaurantMenuPage/components/BulkEditingPanel/BulkEditingPanel";
import { CategoryList } from "@pages/RestaurantMenuPage/components/CategoryList/CategoryList";
import { SimpleCategoryListSkeleton } from "@pages/RestaurantMenuPage/components/CategoryList/CategoryListSkeleton/CategoryListSkeleton";
import { EmptyMenuBanner } from "@pages/RestaurantMenuPage/components/EmptyMenuBanner/EmptyMenuBanner";
import { MenuFiltersPanel } from "@pages/RestaurantMenuPage/components/MenuFiltersPanel/MenuFiltersPanel";
import { ScheduleTasksSettings } from "@pages/RestaurantMenuPage/components/ScheduleTasksSettings/ScheduleTasksSettings";
import {
  useCreateCategory,
  useRerankCategories,
} from "@pages/RestaurantMenuPage/gqlHooks/category/mutations";
import { useToggleHalalFriendly } from "@pages/RestaurantMenuPage/gqlHooks/menu/mutations";
import { useToggleUsesPopularItems } from "@pages/RestaurantMenuPage/gqlHooks/menu/mutations";
import { useMenuState } from "@pages/RestaurantMenuPage/hooks/useMenuState";
import { addUniqThrottledSuccessRegularNotification } from "@pages/RestaurantMenuPage/hooks/useSuccessNotifications";
import { ScheduleTasksModal } from "@pages/RestaurantMenuPage/modals/ScheduleTasksModal/ScheduleTasksModal";
import { generateLibraryProgressBarId } from "@pages/RestaurantMenuPage/utils/Library";
import { categoryListDataToCategories } from "@pages/RestaurantMenuPage/utils/rerank";
import { themeColors } from "@theme/colors";
import { MmsEmptyContentBanner } from "@uiKit/Atoms/EmptyContentBanner/MmsEmptyContentBanner";
import { SortableList } from "@uiKit/Layouts/SortableList";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import {
  menuFiltersVar,
  menuStateVar,
  restaurantCachedDataVar,
} from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import { compose, isEmpty } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AddNewCategory } from "../../components/AddNewCategory/AddNewCategory";
import {
  useBulkDestroyMutation,
  useBulkEnableDisableMutation,
} from "../../gqlHooks/bulkActions/mutations";
import {
  useAddAssetToLibrary,
  useForceAddToLibrary,
} from "../../gqlHooks/library/mutations";
import { useBulkSelectedEntities } from "../../hooks/useBulkSelectedEntities";
import { LibraryModal } from "../../modals/LibraryModal/LibraryModal";
import {
  extractCategoriesItemsIdsFromCategoryList,
  getCategoriesIds,
  getCategoryMenuItemsAmount,
  getNewCategoryFixture,
  getNewFreeItemCategoryFixture,
} from "../../utils/Category";
import {
  CategoryContainer,
  CategoryContainerProps,
} from "../Category/Category";
import { LibrarySettingsContainer } from "../LibrarySettings/LibrarySettings";
import { NewCategoryContainer } from "../NewCategory/NewCategory";
import { OutgoingProps, withCategories } from "./withCategories";
import { useTranslation } from "react-i18next";

interface CategoryListContainerProps extends OutgoingProps {}

// TODO: Need to try to divide component into a few smaller
const CategoryListContainer: React.FC<CategoryListContainerProps> = ({
  categories,
  isCategoriesDataLoading,
  isAllCategoriesMenuItemsDataLoading,
  allCategoriesMenuItemsNetworkStatus,
}) => {
  const { t } = useTranslation(["common", "category", "menu-item"]);

  useMenuState({
    categories,
    isCategoriesDataLoading,
    isAllCategoriesMenuItemsDataLoading,
    allCategoriesMenuItemsNetworkStatus,
  });

  const {
    menu: { halalFriendly, usesPopularItems },
  } = useReactiveVar(restaurantCachedDataVar);

  const [
    toggleHalalFriendlyMutation,
    { loading: toggleHalalFriendlyMutationLoading },
  ] = useToggleHalalFriendly();

  const [
    toggleUsesPopularItemsMutation,
    { loading: toggleUsesPopularItemsMutationLoading },
  ] = useToggleUsesPopularItems();

  const menuFilters = useReactiveVar(menuFiltersVar);
  const menuState = useReactiveVar(menuStateVar);
  const categoriesItemsIds = useMemo(
    () => extractCategoriesItemsIdsFromCategoryList(categories),
    [categories]
  );

  const [
    newCategoryFixture,
    setNewCategoryFixture,
  ] = useState<ModelCategoryWithMenuItemsAmount | null>(null);

  const [createCategoryMutation, isCreateCategoryLoading] = useCreateCategory();

  const [, , removeAddonProgressBarId] = useActiveProgressBars();

  const [
    isForcePushConfirmationModalOpen,
    openForcePushConfirmationModal,
    closeForcePushConfirmationModal,
  ] = useOpenClose(false);

  const [
    isBulkDeleteConfirmationModalOpen,
    openBulkDeleteConfirmationModal,
    closeBulkDeleteConfirmationModal,
  ] = useOpenClose(false);

  const [
    isScheduleTasksModalOpen,
    openScheduleTasksModalOpen,
    closeScheduleTasksModalOpen,
  ] = useOpenClose(false);

  const [
    addingToLibraryError,
    setAddingToLibraryError,
    libraryAddonId,
    onCleanAddingToLibraryError,
  ] = useErrorCatch(openForcePushConfirmationModal);

  const makeNewCategory = useCallback((categories: ModelFullCategory[]) => {
    compose<ModelFullCategory[], ModelCategoryWithMenuItemsAmount, void>(
      setNewCategoryFixture,
      getNewCategoryFixture
    )(categories);
  }, []);

  const clearNewCategory = useCallback(() => {
    setNewCategoryFixture(null);
  }, []);

  const [addonToSave, , deleteAddon] = useActiveAddon();
  const [
    isAddonsLibraryModalOpen,
    ,
    closeAddonsLibraryModal,
  ] = useAddonsLibraryModal();
  const { 2: cleanAssets } = useActiveAssets();
  const { selectedEntities } = useBulkSelectedEntities();

  const [
    deleteSelectedEntitiesMutation,
    deletingLoading,
  ] = useBulkDestroyMutation();

  const handleDeleteSelectedEntities = useCallback(() => {
    deleteSelectedEntitiesMutation(selectedEntities);
  }, [deleteSelectedEntitiesMutation, selectedEntities]);

  const [
    onSaveAddonToLibrary,
    addonAddedToLibraryLoading,
    addedAddonId,
  ] = useAddAssetToLibrary(
    MenuAssetEnum.addon,
    addonToSave,
    setAddingToLibraryError
  );

  const [
    onForceSaveAddonToLibrary,
    addonForceAddedToLibraryLoading,
    forceAddedAddonId,
    onEndForceAddonAdding,
  ] = useForceAddToLibrary(MenuAssetEnum.addon, libraryAddonId);

  const handleCloseAddonsLibraryModal = useCallback(() => {
    closeAddonsLibraryModal();
    cleanAssets();
  }, [cleanAssets, closeAddonsLibraryModal]);

  const handleCloseForcePushConfirmationModal = useCallback(() => {
    closeForcePushConfirmationModal();
    onCleanAddingToLibraryError();
    removeAddonProgressBarId(
      generateLibraryProgressBarId(MenuAssetEnum.addon, addonToSave)
    );
    deleteAddon();
  }, [
    addonToSave,
    closeForcePushConfirmationModal,
    deleteAddon,
    onCleanAddingToLibraryError,
    removeAddonProgressBarId,
  ]);

  const handleForceSaveToLibrary = useCallback(() => {
    closeForcePushConfirmationModal();
    onForceSaveAddonToLibrary();
  }, [closeForcePushConfirmationModal, onForceSaveAddonToLibrary]);

  const [rerankCategoriesMutation] = useRerankCategories();

  const handleOnChangeOrder = useCallback(
    (reorderedData) => {
      compose(
        rerankCategoriesMutation,
        categoryListDataToCategories
      )(reorderedData);
    },
    [rerankCategoriesMutation]
  );

  const [isSelectedEntitiesEnabled, toggleSelectedEntitiesEnabled] = useToggle(
    false
  );

  const [
    isAutoenableSettingsModalOpen,
    openAutoenableSettingsModal,
    closeAutoenableSettingsModal,
  ] = useOpenClose(false);

  const [
    enableDisableSelectedEntitiesMutation,
    enableDisableSelectedEntitiesLoading,
  ] = useBulkEnableDisableMutation();

  const handleBulkEnableDisable = useCallback(
    (autoenableTimestamp: number) => {
      enableDisableSelectedEntitiesMutation(
        selectedEntities,
        !isSelectedEntitiesEnabled,
        autoenableTimestamp
      );
      toggleSelectedEntitiesEnabled();
    },
    [
      enableDisableSelectedEntitiesMutation,
      selectedEntities,
      isSelectedEntitiesEnabled,
      toggleSelectedEntitiesEnabled,
    ]
  );

  const handleBulkEnableDisableWithAutoenable = useCallback(() => {
    isSelectedEntitiesEnabled
      ? openAutoenableSettingsModal()
      : handleBulkEnableDisable(0);
  }, [
    isSelectedEntitiesEnabled,
    openAutoenableSettingsModal,
    handleBulkEnableDisable,
  ]);

  const finishAutoenable = useCallback(
    (autoenableTimestamp: number) => {
      handleBulkEnableDisable(autoenableTimestamp);
      closeAutoenableSettingsModal();
    },
    [closeAutoenableSettingsModal, handleBulkEnableDisable]
  );

  const handleSkipAutoenable = useCallback(() => finishAutoenable(0), [
    finishAutoenable,
  ]);

  const handleSaveAutoenable = useCallback(
    (autoenableDateTime: Date) =>
      finishAutoenable(autoenableDateTime.getTime()),
    [finishAutoenable]
  );

  useEffect(() => {
    if (addonToSave) onSaveAddonToLibrary();
  }, [addonToSave, onSaveAddonToLibrary]);

  useEffect(() => {
    if (
      (!addonAddedToLibraryLoading && addedAddonId) ||
      (!addonForceAddedToLibraryLoading && forceAddedAddonId)
    ) {
      addUniqThrottledSuccessRegularNotification(
        t("menu-item:the-asset-was-successfully-added-to-library")
      );
      handleCloseForcePushConfirmationModal();
      onEndForceAddonAdding();
    }
  }, [
    addedAddonId,
    addonAddedToLibraryLoading,
    addonForceAddedToLibraryLoading,
    forceAddedAddonId,
    handleCloseForcePushConfirmationModal,
    onEndForceAddonAdding,
    t,
  ]);

  useEffect(() => {
    if (deletingLoading) return;
    closeBulkDeleteConfirmationModal();
  }, [closeBulkDeleteConfirmationModal, deletingLoading]);

  useEffect(() => {
    if (
      !isCreateCategoryLoading &&
      categories &&
      !categories.find((c) => c.isFreeItem === true)
    ) {
      const nextCategoryState = getNewFreeItemCategoryFixture();
      createCategoryMutation(nextCategoryState);
    }
  }, [categories, createCategoryMutation, isCreateCategoryLoading]);

  const freeItemCategory = categories.find((c) => c.isFreeItem === true);

  return (
    <CategoryList
      bulkPanel={
        <BulkEditingPanel
          categoriesIds={getCategoriesIds(categories)}
          isSelectedEntitiesEnabled={isSelectedEntitiesEnabled}
          isEnableDisableEntitiesLoading={enableDisableSelectedEntitiesLoading}
          onBulkDelete={openBulkDeleteConfirmationModal}
          onBulkEnableDisable={handleBulkEnableDisableWithAutoenable}
        />
      }
      filtersPanel={
        <MenuFiltersPanel
          halalFriendly={halalFriendly}
          toggleHalalFriendly={
            toggleHalalFriendlyMutationLoading
              ? noop
              : toggleHalalFriendlyMutation
          }
          usesPopularItems={usesPopularItems}
          toggleUsesPopularItems={
            toggleUsesPopularItemsMutationLoading
              ? noop
              : toggleUsesPopularItemsMutation
          }
          openScheduleTaskModal={openScheduleTasksModalOpen}
        />
      }
      modals={
        <>
          {isAddonsLibraryModalOpen && (
            <LibraryModal closeModal={handleCloseAddonsLibraryModal}>
              <LibrarySettingsContainer
                onFinish={handleCloseAddonsLibraryModal}
              />
            </LibraryModal>
          )}

          {isForcePushConfirmationModalOpen && (
            <MmsConfirmationModal
              message={addingToLibraryError}
              confirmBtnText={t("common:update")}
              declineBtnText={t("common:cancel")}
              onConfirmBtnClick={handleForceSaveToLibrary}
              onDeclineBtnClick={handleCloseForcePushConfirmationModal}
            />
          )}

          {isBulkDeleteConfirmationModalOpen && (
            <MmsConfirmationModal
              message={t(
                "category:are-you-sure-you-want-to-delete-all-selected-items"
              )}
              confirmBtnText={t("common:delete")}
              declineBtnText={t("common:cancel")}
              onConfirmBtnClick={handleDeleteSelectedEntities}
              onDeclineBtnClick={closeBulkDeleteConfirmationModal}
              isDataProcessing={deletingLoading}
            />
          )}

          {isAutoenableSettingsModalOpen && (
            <AutoenableSettings
              uniqId="toggle-bulk-autoenable-settings"
              onSkip={handleSkipAutoenable}
              onSave={handleSaveAutoenable}
            />
          )}

          {isScheduleTasksModalOpen && (
            <ScheduleTasksModal onClose={closeScheduleTasksModalOpen}>
              <ScheduleTasksSettings onClose={closeScheduleTasksModalOpen} />
            </ScheduleTasksModal>
          )}
        </>
      }
    >
      {
        {
          [MenuStateEnum.showLoader]: <SimpleCategoryListSkeleton />,
          [MenuStateEnum.showEmptyMessage]: (
            <MmsEmptyContentBanner
              title={t("category:no-menu-assets-that-satisfy-current-filters")}
              color={themeColors.white}
            />
          ),
          [MenuStateEnum.showEmptyMenuBanner]: <EmptyMenuBanner />,
          [MenuStateEnum.showCategoryList]: (
            <>
              {freeItemCategory && (
                <CategoryContainer
                  category={{
                    ...freeItemCategory,
                    menuItemsAmount: getCategoryMenuItemsAmount(
                      freeItemCategory.id,
                      categoriesItemsIds
                    ),
                  }}
                />
              )}
              <SortableList<RerankableEntity<CategoryContainerProps>>
                onChangeOrder={handleOnChangeOrder}
                data={categories
                  .filter((c) => c.isFreeItem !== true)
                  .map((category) => ({
                    id: category.id,
                    category: {
                      ...category,
                      menuItemsAmount: getCategoryMenuItemsAmount(
                        category.id,
                        categoriesItemsIds
                      ),
                    },
                  }))}
                ItemComponent={CategoryContainer}
              />
              {newCategoryFixture && (
                <NewCategoryContainer
                  newCategoryFixture={newCategoryFixture}
                  onCategoryCreated={clearNewCategory}
                  onDeleteClick={clearNewCategory}
                />
              )}
              {isEmpty(menuFilters) && (
                <AddNewCategory
                  onClick={makeNewCategory.bind(null, categories)}
                />
              )}
            </>
          ),
        }[menuState]
      }
    </CategoryList>
  );
};

export default withCategories(CategoryListContainer);
