import { useDidUpdate } from "@hooks";
import {
  ModelCategoryDNA,
  ModelCategoryMenuFlags,
  ModelCategoryTaxes,
  ModelCategoryWithMenuItemsAmount,
} from "@model/DAO/MenuCategory";
import { CategorySettings } from "@pages/RestaurantMenuPage/components/CategorySettings/CategorySettings";
import {
  useEditCategoryDiscountNAvailability,
  useEditCategoryMenuFlags,
  useEditCategoryTaxes,
} from "@pages/RestaurantMenuPage/gqlHooks/category/mutations";
import { equals, omit, pick } from "ramda";
import React, { useCallback, useMemo, useState } from "react";
import { TaxAssociationData } from "__generated__/globalTypes";
import { ModelMenuFlag } from "@model/DAO/MenuFlag";

interface CategorySettingsContainerProps {
  category: ModelCategoryWithMenuItemsAmount;
  closeCategorySettingsModal: VoidFunction;
}

export const CategorySettingsContainer: React.FC<CategorySettingsContainerProps> = ({
  category,
  closeCategorySettingsModal,
}) => {
  const [
    editCategoryDNAMutation,
    isEditCategoryDNALoading,
    isEditCategoryDNAError,
  ] = useEditCategoryDiscountNAvailability(category.id);
  const [
    editCategoryTaxesMutation,
    isEditCategoryTaxesLoading,
    isEditCategoryTaxesError,
  ] = useEditCategoryTaxes(category.id);

  const categoryDNA = useMemo(
    () =>
      pick(
        [
          "id",
          "discount",
          "takeawayDiscount",
          "deliveryDiscount",
          "activeFrom",
          "activeTo",
          "disabledOnDays",
          "isFreeItem",
          "minBasketValue",
        ],
        category
      ),
    [category]
  );

  const [
    editCategoryMenuFlagsMutation,
    isEditCategoryMenuFlagsLoading,
    isEditCategoryMenuFlagsError,
  ] = useEditCategoryMenuFlags(category.id);

  const categoryMenuFlags = useMemo(() => pick(["id", "menuFlags"], category), [
    category,
  ]);

  const [
    localCategoryMenuFlags,
    setLocalCategoryMenuFlags,
  ] = useState<ModelCategoryMenuFlags>({
    id: category.id,
    menuFlags: category.menuFlags,
  });

  const handleCategoryMenuFlags = (menuFlags: ModelMenuFlag[]): void => {
    setLocalCategoryMenuFlags({
      id: category.id,
      menuFlags,
    });
  };

  const haveCategoryMenuFlagsChanged = useMemo(
    () => !equals(categoryMenuFlags, localCategoryMenuFlags),
    [categoryMenuFlags, localCategoryMenuFlags]
  );

  const categoryTaxes = useMemo(
    () => pick(["id", "taxAssociations"], category),
    [category]
  );

  const [localCategoryDNA, setLocalCategoryDNA] = useState<ModelCategoryDNA>(
    categoryDNA
  );

  const [
    localCategoryTaxes,
    setLocalCategoryTaxes,
  ] = useState<ModelCategoryTaxes>({
    id: category.id,
    taxAssociations: category.taxAssociations,
  });

  const handleTaxAssociations = (taxes: TaxAssociationData[]): void =>
    setLocalCategoryTaxes({
      id: category.id,
      taxAssociations: taxes.map((item) => {
        return omit(["__typename"], item);
      }),
    });

  const haveTaxesChanged = useMemo(
    () => !equals(categoryTaxes, localCategoryTaxes),
    [categoryTaxes, localCategoryTaxes]
  );

  const isDNAChanged = useMemo(() => !equals(categoryDNA, localCategoryDNA), [
    categoryDNA,
    localCategoryDNA,
  ]);

  const handleUpdateSettings = useCallback(() => {
    if (!isDNAChanged && !haveTaxesChanged && !haveCategoryMenuFlagsChanged)
      return;
    if (isDNAChanged) {
      editCategoryDNAMutation(localCategoryDNA);
    } else if (haveTaxesChanged) {
      editCategoryTaxesMutation(localCategoryTaxes);
    } else if (haveCategoryMenuFlagsChanged) {
      editCategoryMenuFlagsMutation(localCategoryMenuFlags);
    }
  }, [
    isDNAChanged,
    haveTaxesChanged,
    haveCategoryMenuFlagsChanged,
    editCategoryDNAMutation,
    localCategoryDNA,
    editCategoryTaxesMutation,
    localCategoryTaxes,
    editCategoryMenuFlagsMutation,
    localCategoryMenuFlags,
  ]);

  useDidUpdate(() => {
    if (
      isEditCategoryDNALoading ||
      isEditCategoryDNAError ||
      isEditCategoryTaxesError ||
      isEditCategoryTaxesLoading ||
      isEditCategoryMenuFlagsLoading ||
      isEditCategoryMenuFlagsError
    )
      return;
    closeCategorySettingsModal();
  }, [
    isEditCategoryDNALoading,
    isEditCategoryDNAError,
    isEditCategoryTaxesError,
    isEditCategoryTaxesLoading,
    isEditCategoryMenuFlagsLoading,
    isEditCategoryMenuFlagsError,
  ]);

  return (
    <CategorySettings
      categoryDNA={localCategoryDNA}
      categoryTaxes={localCategoryTaxes.taxAssociations}
      preserveLocalTaxes={handleTaxAssociations}
      categoryTitle={category.title}
      isUpdateProcessing={isEditCategoryDNALoading}
      isDNAChanged={isDNAChanged}
      preserveLocalDNA={setLocalCategoryDNA}
      onCancelClick={closeCategorySettingsModal}
      onUpdateClick={handleUpdateSettings}
      haveMenuFlagsChanged={haveCategoryMenuFlagsChanged}
      haveTaxesChanged={haveTaxesChanged}
      categoryMenuFlags={localCategoryMenuFlags.menuFlags}
      preserveLocalMenuFlags={handleCategoryMenuFlags}
      isFreeItem={category.isFreeItem}
    />
  );
};
