import { ALL_FILTER_VALUE } from "@constants";
import { useLogFiltersPagination, useOpenClose } from "@hooks";
import {
  LogActionsEnum,
  LogAuditableEnum,
  LogFilterTypesEnum,
} from "@model/helperTypes/LogFilters";
import { generateLogActionTypeName } from "@pages/LogsPage/utils/LogActionTypeName";
import { themeColors } from "@theme/colors";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { ClearFilters } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentStartLayout } from "@uiKit/Layouts/JustifyContentStartLayout";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { curry } from "ramda";
import React, { useCallback } from "react";
import { styles } from "./LogsFiltersList.styles";
import { LogsTextFilterForm } from "./LogsTextFilter/LogsTextFilter";

export const LogsFiltersList: React.FC = () => {
  const [
    logFilters,
    ,
    { onChangeFilters, onClearFilters },
  ] = useLogFiltersPagination();

  const [
    shouldClearAuditableIdEqField,
    startClearingAuditableIdEqField,
    finishClearingAuditableIdEqFields,
  ] = useOpenClose(false);

  const [
    shouldClearUsernameContField,
    startClearingUsernameContField,
    finishClearingUsernameContFields,
  ] = useOpenClose(false);

  const handleClear = useCallback(() => {
    startClearingAuditableIdEqField();
    startClearingUsernameContField();
    onClearFilters();
  }, [
    startClearingAuditableIdEqField,
    startClearingUsernameContField,
    onClearFilters,
  ]);

  const handleChangeValue = useCallback(
    (filterType: LogFilterTypesEnum, newValue: any) => {
      let value = newValue;

      switch (filterType) {
        case LogFilterTypesEnum.usernameCont: {
          value = newValue["username"];
          break;
        }
        case LogFilterTypesEnum.auditableIdEq: {
          value = newValue["assetId"];
          break;
        }
        default:
          break;
      }

      onChangeFilters(filterType, value);
    },
    [onChangeFilters]
  );

  return (
    <JustifyContentStartLayout className={styles.container}>
      <LogsTextFilterForm
        name={"assetId"}
        label={"Enter asset id here"}
        defaultValue={logFilters.auditableIdEq}
        onSubmit={curry(handleChangeValue)(LogFilterTypesEnum.auditableIdEq)}
        onClearField={finishClearingAuditableIdEqFields}
        shouldClearField={shouldClearAuditableIdEqField}
      />
      <MmsMuiSelectbox
        id={"log-action-type-selectbox"}
        name={"Action type"}
        label={"Select action"}
        className={styles.selectboxFilter}
        value={logFilters.actionEq || ALL_FILTER_VALUE}
        onChange={curry(handleChangeValue)(LogFilterTypesEnum.actionEq)}
        data={[
          { title: "All", value: ALL_FILTER_VALUE },
          ...Object.keys(LogActionsEnum).map((key) => {
            return { title: generateLogActionTypeName(key), value: key };
          }),
        ]}
        fullWidth
      />
      <MmsMuiSelectbox
        id={"log-entity-type-selectbox"}
        name={"Change on type"}
        label={"Select asset type"}
        className={styles.selectboxFilter}
        value={logFilters.auditableTypeEq || ALL_FILTER_VALUE}
        onChange={curry(handleChangeValue)(LogFilterTypesEnum.auditableTypeEq)}
        data={[
          { title: "All", value: ALL_FILTER_VALUE },
          ...Object.keys(LogAuditableEnum).map((key) => {
            return { title: key, value: key };
          }),
        ]}
        fullWidth
      />
      <LogsTextFilterForm
        name={"username"}
        label={"Enter username here"}
        onSubmit={curry(handleChangeValue)(LogFilterTypesEnum.usernameCont)}
        onClearField={finishClearingUsernameContFields}
        shouldClearField={shouldClearUsernameContField}
      />
      <MmsTooltip title={"Clear all filters"}>
        <div>
          <MmsSvgButton id="clear-all-filters-btn" onClick={handleClear}>
            <ClearFilters size={"large"} color={themeColors.white} />
          </MmsSvgButton>
        </div>
      </MmsTooltip>
    </JustifyContentStartLayout>
  );
};
