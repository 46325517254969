import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  container: (isSelected: boolean, disabled: boolean, isDragging: boolean) => css`
    position: relative;
    width: 100%;
    padding: 10px 7px 10px 18px;

    border-radius: ${themeVars.borderRadius};

    background-color: ${isDragging || isSelected ? themeColors.white : themeColors.cream};

    border-bottom: ${isSelected && `2px solid ${themeColors.lightGreen}`};

    cursor: ${disabled ? "not-allowed" : "pointer"};
    opacity: ${isDragging ? 0.3 : 1};

    margin-bottom: 10px;

    &:hover {
      img {
        opacity: 0.7;
      }
    }
  `,
  handle: (isDragAndDropDisabled: boolean) => css`
    display: flex;
    align-items: center;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 18px;

    cursor: ${isDragAndDropDisabled ? "pointer" : "move"};

    img {
      width: 100%;

      opacity: 0;
    }
  `,
  dummy: css`
    cursor: default;
  `,
};
