import { ReactComponent as SVGClone } from "@assets/SVG/regular-clone.svg";
import { cx } from "@emotion/css";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface ICloneProps extends IIconBase {}

export const Clone: React.FC<ICloneProps> = ({
  id,
  size = "medium",
  className,
}) => {
  const ref = useSvgFilter(id);
  return <SVGClone ref={ref} className={cx(styles.icon(size), className)} />;
};
