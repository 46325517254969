import { IS_IFRAME } from "@constants";
import React from "react";
import { styles } from "./BaseLayout.styles";

export interface AddonsSettingsBaseLayoutProps {
  statusBar: React.ReactNode;
  content: React.ReactNode;
}

export const AddonsSettingsBaseLayout: React.FC<AddonsSettingsBaseLayoutProps> = ({
  statusBar,
  content,
}) => {
  return (
    <div className={styles.container(IS_IFRAME)}>
      <header className={styles.statusBar}>{statusBar}</header>
      <section className={styles.content}>{content}</section>
    </div>
  );
};
