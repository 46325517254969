import { useCallback, useState } from "react";

export const useErrorCatch = (
  callback: VoidFunction
): [string, (error: string, errorData: any) => void, any, VoidFunction] => {
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState(null);

  const onSetError = useCallback(
    (error: string, errorData: any) => {
      setError(error);
      setErrorData(errorData);
      callback();
    },
    [callback]
  );
  const onCleanError = useCallback(() => {
    setError("");
    setErrorData(null);
  }, []);

  return [error, onSetError, errorData, onCleanError];
};
