import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { JustifyContentCenterLayout } from "@uiKit/Layouts/JustifyContentCenterLayout";
import React from "react";
import { MmsBaseNotificationModal } from "../MmsBaseNotificationModal";

export interface MmsAlertProps {
  title?: string;
  message: React.ReactNode;
  closeBtnText: string;
  onCloseBtnClick: VoidFunction;
}

export const MmsAlert: React.FC<MmsAlertProps> = ({
  title = "Alert",
  message,
  closeBtnText,
  onCloseBtnClick,
}) => (
  <MmsBaseNotificationModal
    contentClassName="alertModal"
    titleSection={title}
    messageSection={message}
    buttonRowSection={
      <JustifyContentCenterLayout>
        <MmsPrimaryButton id="close-btn" onClick={onCloseBtnClick}>
          {closeBtnText}
        </MmsPrimaryButton>
      </JustifyContentCenterLayout>
    }
    onClose={onCloseBtnClick}
    onConfirm={onCloseBtnClick}
  />
);
