import { cx } from "@emotion/css";
import Backdrop from "@material-ui/core/Backdrop";
import MuiModal, { ModalProps as MuiModalProps } from "@material-ui/core/Modal";
import { themeColors } from "@theme/colors";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { noop } from "@utils/noop";
import React, { useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import { DefaultContent } from "./contents/DefaultContent";
import { styles } from "./styles";
import { SlideDown } from "./transitions/SlideDown";

export interface MmsBaseMobileModalProps {
  uniqId: string;
  contentClassName: string;
  isOpen: boolean;
  closeOnEscPress?: boolean;
  closeOnOutsideClick?: boolean;
  isBackdropVisible?: boolean;
  onClose: VoidFunction;
  children: React.ReactNode;
}

const BaseMobileModal: React.FC<MmsBaseMobileModalProps> = React.forwardRef<
  MuiModalProps,
  MmsBaseMobileModalProps
>(
  (
    {
      uniqId,
      contentClassName,
      isOpen,
      closeOnEscPress = true,
      closeOnOutsideClick,
      isBackdropVisible = true,
      onClose,
      children,
      ...rest
    },
    ref
  ) => {
    const backdropProps = useMemo(
      () =>
        isBackdropVisible
          ? {
              color: themeColors.lightGreenTransparent,
              timeout: 300,
            }
          : { invisible: true },
      [isBackdropVisible]
    );
    const handlers = useSwipeable({
      onSwipedDown: onClose,
      trackMouse: true,
    });

    return (
      <MuiModal
        {...rest}
        ref={ref}
        open={isOpen}
        onClose={closeOnOutsideClick ? onClose : noop}
        className={styles.container}
        BackdropProps={backdropProps}
        BackdropComponent={Backdrop}
        keepMounted
        closeAfterTransition
      >
        <SlideDown in={isOpen} className={cx(styles.content, contentClassName)}>
          <MmsBaseButton
            {...handlers}
            id={`close-mobile-modal-btn-${uniqId}`}
            className={styles.handle}
            fullWidth
            onClick={onClose}
          />
          {children}
        </SlideDown>
      </MuiModal>
    );
  }
);

export const MmsBaseMobileModal = Object.assign(BaseMobileModal, {
  DefaultContent,
});
