import { AVAILABILITY_OPTIONS } from "@constants";
import { cx } from "@emotion/css";
import { useForceReloadOnUpdate, useFormErrorsProtection } from "@hooks";
import { BulkMoreActionsEnum } from "@model/helperTypes/BulkEditing";
import {
  AvailableEnum,
  LocalAvailabilityData,
} from "@model/helperTypes/Discount&Availability";
import { useBulkSelectedEntities } from "@pages/RestaurantMenuPage/hooks/useBulkSelectedEntities";
import {
  decomposeLocalAvailabilityData,
  getInitLocalAvailabilityData,
} from "@pages/RestaurantMenuPage/utils/Discount&Availability";
import { getSelectedMenuItemsWithoutSelectedCategory } from "@pages/RestaurantMenuPage/utils/selectedEntities";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { getIsPartner } from "@utils/url";
import React, { useCallback, useMemo, useState } from "react";
import { AvailabilityForm } from "../../CategorySettings/Discount&Availability/AvailabilityForm/AvailabilityForm";
import { DiscountForm } from "../../CategorySettings/Discount&Availability/DiscountForm/DiscountForm";
import { BulkAddonItemsUpdateWarning } from "./BulkAddonItemsUpdateWarning/BulkAddonItemsUpdateWarning";
import { styles } from "./BulkMoreSettings.styles";
import {
  BulkPriceUpdateForm,
  BULK_PRICE_UPDATE_FORM_DEFAULT_VALUES,
} from "./BulkPriceUpdateForm/BulkPriceUpdateForm";
import { useTranslation } from "react-i18next";

interface BulkMoreSettingsProps {
  isApplyProcessing: boolean;
  onCancelClick: VoidFunction;
  onApplyClick: (
    currentBulkAction: BulkMoreActionsEnum,
    data: { [key: string]: any }
  ) => void;
}

interface BulkActionOptionsType {
  title: string;
  value: BulkMoreActionsEnum;
}

export const BulkMoreSettings: React.FC<BulkMoreSettingsProps> = ({
  isApplyProcessing,
  onCancelClick,
  onApplyClick,
}) => {
  const { t } = useTranslation(["bulk-settings"]);

  const {
    selectedEntities: { selectedMenuItems, selectedCategories },
  } = useBulkSelectedEntities();

  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [localDiscount, setLocalDiscount] = useState("");
  const [localTakeawayDiscount, setlocalTakeawayDiscount] = useState<string>(
    ""
  );
  const [localDeliveryDiscount, setLocalDeliveryDiscount] = useState<string>(
    ""
  );

  const [localAvailabilityData, setLocalAvailabilityData] = useState(
    getInitLocalAvailabilityData(null, null, null)
  );
  const [localBulkPriceUpdateData, setLocalPriceUpdateData] = useState(
    BULK_PRICE_UPDATE_FORM_DEFAULT_VALUES
  );

  const [
    currentBulkAction,
    setCurrentBulkAction,
  ] = useState<BulkMoreActionsEnum>(BulkMoreActionsEnum.discountUpdate);

  const handleChangeCurrentBulkAction = useCallback((newValue: string) => {
    setCurrentBulkAction(newValue as BulkMoreActionsEnum);
  }, []);

  const handleChangeAvailable = useCallback(
    (newAvailable: AvailableEnum) => {
      setLocalAvailabilityData({
        ...localAvailabilityData,
        available: newAvailable,
      });
    },
    [localAvailabilityData]
  );

  const handleChangeAvailabilityData = useCallback(
    (newValues: LocalAvailabilityData) => {
      setLocalAvailabilityData(newValues);
    },
    []
  );

  const handleChangeDiscount = useCallback(
    ({ discount, takeawayDiscount, deliveryDiscount }) => {
      setLocalDiscount(discount);
      setlocalTakeawayDiscount(takeawayDiscount);
      setLocalDeliveryDiscount(deliveryDiscount);
    },
    []
  );

  const handleApplyClick = useCallback(() => {
    switch (currentBulkAction) {
      case BulkMoreActionsEnum.discountUpdate: {
        onApplyClick(currentBulkAction, {
          discount: localDiscount ? parseInt(localDiscount) : 0,
          takeawayDiscount: localTakeawayDiscount
            ? parseInt(localTakeawayDiscount)
            : 0,
          deliveryDiscount: localDeliveryDiscount
            ? parseInt(localDeliveryDiscount)
            : 0,
        });
        break;
      }
      case BulkMoreActionsEnum.availabilityUpdate: {
        onApplyClick(
          currentBulkAction,
          decomposeLocalAvailabilityData(localAvailabilityData)
        );
        break;
      }
      case BulkMoreActionsEnum.menuItemPricesUpdate: {
        const { bulkPriceUpdateOption, bulkPrice } = localBulkPriceUpdateData;
        onApplyClick(currentBulkAction, {
          bulkPriceUpdateOption,
          bulkPrice: bulkPrice ? parseFloat(bulkPrice) : 0,
        });
        break;
      }
      case BulkMoreActionsEnum.addonItemPricesUpdate: {
        const { bulkPriceUpdateOption, bulkPrice } = localBulkPriceUpdateData;
        onApplyClick(currentBulkAction, {
          bulkPriceUpdateOption,
          bulkPrice: bulkPrice ? parseFloat(bulkPrice) : 0,
        });
        break;
      }
      default:
        throw new Error(
          "BulkEditingPanel -> BulkMoreSettings -> handleApplyClick: Wrong currentBulkAction"
        );
    }
  }, [
    currentBulkAction,
    localAvailabilityData,
    localBulkPriceUpdateData,
    localDiscount,
    localTakeawayDiscount,
    localDeliveryDiscount,
    onApplyClick,
  ]);

  const endingWords = useMemo(() => {
    switch (currentBulkAction) {
      case BulkMoreActionsEnum.discountUpdate:
      case BulkMoreActionsEnum.availabilityUpdate: {
        return `${selectedCategories.length} categor${
          selectedCategories.length === 1 ? "y" : "ies"
        }`;
      }
      case BulkMoreActionsEnum.menuItemPricesUpdate: {
        return `${selectedMenuItems.length} item${
          selectedMenuItems.length === 1 ? "" : "s"
        }`;
      }
      case BulkMoreActionsEnum.addonItemPricesUpdate: {
        const selectedAssetsAmount =
          selectedCategories.length + selectedMenuItems.length;
        return `${selectedAssetsAmount} asset${
          selectedAssetsAmount === 1 ? "" : "s"
        }`;
      }
      default:
        throw new Error(
          "BulkEditingPanel -> BulkMoreSettings -> endingWords: Wrong currentBulkAction"
        );
    }
  }, [currentBulkAction, selectedCategories.length, selectedMenuItems.length]);

  const isBulkAddonItemsUpdateWarningVisible = useMemo(() => {
    if (currentBulkAction !== BulkMoreActionsEnum.addonItemPricesUpdate)
      return false;
    const selectedMenuItemsWithoutSelectedCategory = getSelectedMenuItemsWithoutSelectedCategory(
      { selectedMenuItems, selectedCategories }
    );
    const isSomeAttachedToCategory = selectedMenuItemsWithoutSelectedCategory.some(
      ({ hasOwnAddons }) => !hasOwnAddons
    );

    return isSomeAttachedToCategory;
  }, [currentBulkAction, selectedCategories, selectedMenuItems]);

  const availabilityFormKey = useForceReloadOnUpdate([
    localAvailabilityData.available,
  ]);

  const bulkActionOptions = [
    {
      title: t("bulk-settings:update-discount"),
      value: BulkMoreActionsEnum.discountUpdate,
    },
    {
      title: t("bulk-settings:update-availability"),
      value: BulkMoreActionsEnum.availabilityUpdate,
    },
    !getIsPartner() && {
      title: t("bulk-settings:update-item-prices"),
      value: BulkMoreActionsEnum.menuItemPricesUpdate,
    },
    !getIsPartner() && {
      title: t("bulk-settings:update-addon-item-prices"),
      value: BulkMoreActionsEnum.addonItemPricesUpdate,
    },
  ].filter(Boolean) as BulkActionOptionsType[];

  return (
    <section className={styles.container}>
      <header className={cx(styles.header, styles.flexEdge)}>
        Apply changes to {endingWords}
      </header>
      <div className={cx(styles.mainContent, styles.flexCenter)}>
        <div className={styles.formGroup}>
          <MmsFormTitle>1. Choose action</MmsFormTitle>
          <MmsMuiSelectbox
            id={`bulk-actions-selectbox`}
            name="bulkActionsSelectbox"
            label={t("bulk-settings:available-actions")}
            data={bulkActionOptions}
            value={currentBulkAction}
            onChange={handleChangeCurrentBulkAction}
            fullWidth
          />
        </div>
        <div key={availabilityFormKey} className={styles.formGroup}>
          {
            {
              [BulkMoreActionsEnum.discountUpdate]: (
                <DiscountForm
                  uniqId="bulk"
                  title={`2. ${t("bulk-settings:discount")}`}
                  discount={localDiscount}
                  takeawayDiscount={localTakeawayDiscount}
                  deliveryDiscount={localDeliveryDiscount}
                  onSubmit={handleChangeDiscount}
                />
              ),
              [BulkMoreActionsEnum.availabilityUpdate]: (
                <AvailabilityForm
                  uniqId="bulk"
                  title={`2. ${t("bulk-settings:availability")}`}
                  onSubmit={handleChangeAvailabilityData}
                  onChangeAvailable={handleChangeAvailable}
                  availabilityData={localAvailabilityData}
                  availabilityOptions={AVAILABILITY_OPTIONS}
                />
              ),
              [BulkMoreActionsEnum.menuItemPricesUpdate]: (
                <BulkPriceUpdateForm
                  uniqId="menu-item"
                  title={`2. ${t("bulk-settings:item-prices")}`}
                  onSubmit={setLocalPriceUpdateData}
                />
              ),
              [BulkMoreActionsEnum.addonItemPricesUpdate]: (
                <>
                  <BulkPriceUpdateForm
                    uniqId="addon-item"
                    title={`2. ${t("bulk-settings:addon-item-prices")}`}
                    onSubmit={setLocalPriceUpdateData}
                  />
                  {isBulkAddonItemsUpdateWarningVisible && (
                    <BulkAddonItemsUpdateWarning />
                  )}
                </>
              ),
            }[currentBulkAction]
          }
        </div>
      </div>
      <footer className={cx(styles.footer, styles.flexEdge)}>
        <JustifyContentEndLayout className={styles.btnGroup}>
          <MmsSecondaryButton id="bulk-more-cancel" onClick={onCancelClick}>
            {t("bulk-settings:close-window")}
          </MmsSecondaryButton>
          <MmsPrimaryButton
            id="bulk-more-apply"
            onClick={withActiveFormsErrorsProtection(handleApplyClick)}
            processing={isApplyProcessing}
          >
            {t("bulk-settings:apply-to-text", { text: endingWords })}
          </MmsPrimaryButton>
        </JustifyContentEndLayout>
      </footer>
    </section>
  );
};
