import { cx } from "@emotion/css";
import { useActiveForms, useDidMount, useOpenClose } from "@hooks";
import {
  AvailableEnum,
  LocalAvailabilityData,
  LocalAvailabilityOption,
} from "@model/helperTypes/Discount&Availability";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { ConnectedMmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { FlexCol } from "@uiKit/Layouts/FlexCol";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsAlert } from "@uiKit/Molecules/Modals/MmsAlert";
import { checkStartTimeLaterThanEndTime } from "@utils/dateTime";
import { validateField } from "@utils/formik";
import { isNotEmpty } from "@utils/ramdaHelpers";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useState } from "react";
import NumberFormat from "react-number-format";
import { styles } from "./AvailabilityForm.styles";
import { validationMap } from "./AvailabilityForm.validationMap";
import { useTranslation } from "react-i18next";
import { MmsSwitch, SizeEnum } from "@uiKit/Atoms/Switch/MmsSwitch";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import { InfoCircle } from "@uiKit/Atoms/SVGIcons";
import { themeColors } from "@theme/colors";
import {
  allDaysBitValue,
  DAYS,
  getDayBitValue,
  getEnabledDays,
  isDayEnabled,
} from "@pages/RestaurantMenuPage/utils/Discount&Availability";

type StaticFormValues =
  | {
      available: AvailableEnum.always;
      disabledOnDays: number;
    }
  | {
      available: AvailableEnum.restricted;
      activeFrom: string;
      activeTo: string;
      disabledOnDays: number;
    };

interface AvailabilityFormContentProps {
  uniqId: string;
  title: string;
  availabilityData: LocalAvailabilityData;
  availabilityOptions: LocalAvailabilityOption[];
  onSubmit: (values: any) => void;
  onChangeAvailable: (newAvailable: AvailableEnum) => void;
}

const AvailabilityFormContent: React.FC<
  AvailabilityFormContentProps & FormikProps<StaticFormValues>
> = ({
  uniqId,
  title,
  availabilityData,
  availabilityOptions,
  onSubmit,
  onChangeAvailable,
  ...formikProps
}) => {
  const { t } = useTranslation(["availability", "common"]);
  const formId = `availability-form-${uniqId}`;

  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();
  const [didUserSeeWarningAlert, setUserSawWarningAlert] = useState(false);

  const [
    isWarningAlertOpen,
    openWarningAlert,
    closeWarningAlert,
  ] = useOpenClose(false);

  const handleCloseWarningAlert = useCallback(() => {
    setUserSawWarningAlert(true);
    closeWarningAlert();
  }, [closeWarningAlert]);

  const handleChangeAvailable = useCallback(
    (newValue) => {
      onChangeAvailable(newValue as AvailableEnum);
    },
    [onChangeAvailable]
  );

  const handleBlurTime = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const { name: fieldName } = e.target;
      const { activeFrom, activeTo } = formikProps.values as any;

      formikProps.validateField(fieldName);

      const touchedFields = Object.keys({
        ...formikProps.touched,
        [fieldName]: true,
      });
      const isEveryTouched = ["activeFrom", "activeTo"].every((field) =>
        touchedFields.includes(field)
      );
      const isEveryNotEmpty = Object.values(formikProps.values).every(
        isNotEmpty
      );

      const shouldSubmit = [isEveryTouched, isEveryNotEmpty].some(Boolean);
      // user will see validation errors only after submit form
      if (shouldSubmit) formikProps.submitForm();

      if (didUserSeeWarningAlert) return;
      if (!formikProps.dirty) return;
      if (!isEveryNotEmpty) return;
      if (!checkStartTimeLaterThanEndTime(activeFrom, activeTo)) return;

      openWarningAlert();
    },
    [didUserSeeWarningAlert, formikProps, openWarningAlert]
  );

  const syncFormicWithFormattedFields = useCallback(
    (fieldName, fieldValue) => {
      formikProps.setFieldValue(fieldName, fieldValue);

      if (!(formikProps as any).errors[fieldName]) return;
      if (validateField((validationMap as any)[fieldName])(fieldValue)) return;
      formikProps.setFieldError(fieldName, undefined);
    },
    [formikProps]
  );

  const handleChangeFormattedValue = useCallback(
    (name, values) => {
      const { formattedValue: value } = values;
      syncFormicWithFormattedFields(name, value);
    },
    [syncFormicWithFormattedFields]
  );

  const handleChangeTime = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      syncFormicWithFormattedFields(name, value);
    },
    [syncFormicWithFormattedFields]
  );

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  const enabledDays = getEnabledDays(formikProps.values.disabledOnDays).map(
    (day) => day.nameFull
  );

  const allDaysEnabled = !formikProps.values.disabledOnDays;
  const singleDayEnabled = DAYS.some(
    (day) =>
      formikProps.values.disabledOnDays === allDaysBitValue - day.bitValue
  );
  const noDayEnabled = formikProps.values.disabledOnDays === allDaysBitValue;

  const toggleDay = (index: number) => {
    const currentValue = formikProps.values.disabledOnDays;
    const newValue =
      getDayBitValue(index) *
        (isDayEnabled(formikProps.values.disabledOnDays, index) ? 1 : -1) +
      currentValue;
    syncFormicWithFormattedFields("disabledOnDays", newValue);
    formikProps.submitForm();
  };

  const toggleEveryday = () => {
    const newValue = allDaysEnabled ? allDaysBitValue : 0;
    syncFormicWithFormattedFields("disabledOnDays", newValue);
    formikProps.submitForm();
  };

  const getDescriptionTranslationKey = () => {
    if (formikProps.values.available === AvailableEnum.always) {
      if (allDaysEnabled) {
        return "availability:description-all-days-all-day";
      } else if (singleDayEnabled) {
        return "availability:description-single-day-all-day";
      } else if (!noDayEnabled) {
        return "availability:description-multiple-days-all-day";
      }
    } else {
      if (allDaysEnabled) {
        return "availability:description-all-days-hours";
      } else if (singleDayEnabled) {
        return "availability:description-single-day-hours";
      } else if (!noDayEnabled) {
        return "availability:description-multiple-days-hours";
      }
    }
    return "availability:description-no-days";
  };

  return (
    <Form className={styles.form}>
      <div className={styles.info}>
        <InfoCircle size={SizeEnum.small} color={themeColors.mediumGray} />
        <span>
          {t(getDescriptionTranslationKey(), {
            daysWithoutLast: enabledDays.slice(0, -1).join(", "),
            lastDay: enabledDays.slice(-1),
            activeFrom: (formikProps.values as any).activeFrom,
            activeTo: (formikProps.values as any).activeTo,
          })}
        </span>
      </div>
      <MmsFormTitle className={styles.baseInput}>{title}</MmsFormTitle>
      <ConnectedMmsMuiSelectbox
        data-testid={`availability-option-${uniqId}`}
        id={`availability-option-${uniqId}`}
        name="available"
        label={t("availability:duration")}
        data={availabilityOptions}
        onChange={handleChangeAvailable}
        className={cx(styles.baseInput, styles.selectBox)}
        fullWidth
      />
      {formikProps.values.available === AvailableEnum.restricted && (
        <JustifyContentBetweenLayout className={styles.baseInput}>
          <FlexCol>
            <NumberFormat
              data-testid={`availability-activeFrom-${uniqId}`}
              id={`availability-activeFrom-${uniqId}`}
              customInput={MmsMuiInput}
              name="activeFrom"
              label={t("availability:start-time")}
              placeholder="HH:MM"
              mask={["H", "H", "M", "M"]}
              format="##:##"
              onBlur={handleBlurTime}
              validate={validateField(validationMap.activeFrom)}
              value={formikProps.values.activeFrom}
              onValueChange={handleChangeFormattedValue.bind(
                null,
                "activeFrom"
              )}
              onChange={handleChangeTime}
              focusOnMount={!formikProps.values.activeFrom}
              fullWidth
              blurOnEnter
              showErrorMessage
              showUnderline
            />
          </FlexCol>
          <FlexCol>
            <NumberFormat
              data-testid={`availability-activeTo-${uniqId}`}
              id={`availability-activeTo-${uniqId}`}
              customInput={MmsMuiInput}
              name="activeTo"
              label={t("availability:end-time")}
              placeholder="HH:MM"
              mask={["H", "H", "M", "M"]}
              format="##:##"
              onBlur={handleBlurTime}
              validate={validateField(validationMap.activeTo)}
              value={formikProps.values.activeTo}
              onValueChange={handleChangeFormattedValue.bind(null, "activeTo")}
              onChange={handleChangeTime}
              fullWidth
              blurOnEnter
              showErrorMessage
              showUnderline
            />
          </FlexCol>
        </JustifyContentBetweenLayout>
      )}
      <hr className={styles.hr} />
      <MmsFormTitle className={styles.baseInput}>
        {t("availability:select-days")}
      </MmsFormTitle>
      <input
        type="hidden"
        name="disabledOnDays"
        value={formikProps.values.disabledOnDays}
      />
      <div className={styles.toggleWrapper}>
        <MmsSwitch
          id="availability-everyday"
          checked={allDaysEnabled}
          onChange={toggleEveryday}
        />
        <span className={styles.toggleLabel} onClick={toggleEveryday}>
          {t("availability:everyday")}
        </span>
      </div>
      {DAYS.map((day) => (
        <div className={styles.toggleWrapper}>
          <MmsCheckbox
            key={day.nameFull}
            id={`availability-days-${day.nameFull}`}
            checked={isDayEnabled(formikProps.values.disabledOnDays, day.index)}
            onChange={() => toggleDay(day.index)}
          />
          <span
            className={styles.toggleLabel}
            onClick={() => toggleDay(day.index)}
          >
            {day.nameFull}
          </span>
        </div>
      ))}
      {isWarningAlertOpen && (
        <MmsAlert
          title={t("availability:pay-attention")}
          message={t("availability:end-time-is-before-the-start-time")}
          closeBtnText={t("common:ok")}
          onCloseBtnClick={handleCloseWarningAlert}
        />
      )}
    </Form>
  );
};

export const AvailabilityForm = withFormik<
  AvailabilityFormContentProps,
  StaticFormValues
>({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: ({
    availabilityData: { available, activeFrom, activeTo, disabledOnDays },
  }) =>
    available === AvailableEnum.restricted
      ? {
          available: AvailableEnum.restricted,
          activeFrom: activeFrom || "",
          activeTo: activeTo || "",
          disabledOnDays: disabledOnDays || 0,
        }
      : {
          available: AvailableEnum.always,
          disabledOnDays: disabledOnDays || 0,
        },
  handleSubmit: (values: any, { props: { onSubmit } }) => onSubmit(values),
})(AvailabilityFormContent);
