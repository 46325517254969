import { useCallback, useMemo, useState } from "react";
import Cookies from "js-cookie";
import { RELEASE_READ } from "@constants";
import { getCurrentReleaseVersion } from "@pages/ReleaseNotesPage/utils/ReleaseNote";

export const useReleaseReadState = (): [boolean, VoidFunction] => {
  const isCurrentRead = useMemo(
    () => getCurrentReleaseVersion() === Cookies.get(RELEASE_READ),
    []
  );

  const [isRead, setIsRead] = useState<boolean>(isCurrentRead);

  const setIsReleaseRead = useCallback(() => {
    setIsRead(true);
    Cookies.set(RELEASE_READ, getCurrentReleaseVersion(), { expires: 5500 }); // 15.years
  }, []);

  return [isRead, setIsReleaseRead];
};
