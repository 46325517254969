import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { AUTH_LOGIN, AuthLoginTypes } from "../../graphql/mutations/authLogin";
import {
  REFRESH_TOKEN,
  RefreshTokenTypes,
} from "../../graphql/mutations/refreshToken";
import { useErrorHandler } from "@hooks";

export const useAuthLogin = (): [
  (code: string, redirectUri: string) => void,
  boolean,
  AuthLoginTypes.AuthLoginMutation
] => {
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading, data },
  ] = useMutation<AuthLoginTypes.AuthLoginMutation>(AUTH_LOGIN, { onError });

  const mutationWithVars = useCallback(
    (code: string, redirectUri: string) => {
      mutation({
        variables: { input: { code, redirectUri } },
      });
    },
    [mutation]
  );

  return [mutationWithVars, loading, data as AuthLoginTypes.AuthLoginMutation];
};

export const useRefreshToken = (): [
  (jwtToken: string, refreshToken: string) => void,
  boolean,
  RefreshTokenTypes.RefreshTokenMutation
] => {
  const [, { onError }] = useErrorHandler([]);
  const [
    mutation,
    { loading, data },
  ] = useMutation<RefreshTokenTypes.RefreshTokenMutation>(REFRESH_TOKEN, {
    onError,
  });

  const mutationWithVars = useCallback(
    (jwtToken: string, refreshToken: string) => {
      mutation({
        variables: { input: { jwtToken, refreshToken } },
      });
    },
    [mutation]
  );
  return [
    mutationWithVars,
    loading,
    data as RefreshTokenTypes.RefreshTokenMutation,
  ];
};
