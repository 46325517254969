import { gql } from "@apollo/client";
import { MENU_ITEM_ESSENTIAL_DATA } from "../../fragments/MenuItem";

export const GET_MENU_ITEMS_BY_CAT = gql`
  query GetMenuItemsByCatQuery($categoryId: ID!) {
    category(id: $categoryId) {
      id
      items {
        ...MenuItemEssentialData
      }
    }
  }
  ${MENU_ITEM_ESSENTIAL_DATA}
`;

export * as GetMenuItemsByCatTypes from "./__generated__/GetMenuItemsByCatQuery";
