import { css } from "@emotion/css";

export const styles = {
  link: css`
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  `,
};
