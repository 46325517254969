import { gql } from "@apollo/client";
import { ModelCategoryDNA, ModelFullCategory } from "@model/DAO/MenuCategory";
import {
  BulkRequestActionEnum,
  LevelsBulkEnum,
} from "@model/helperTypes/BulkEditing";

export const BULK_UPDATE_AVAILABILITY = gql`
  mutation BulkUpdateAvailabilityMutation($input: BulkData!) {
    mmsBulk(input: $input)
  }
`;

export const generateBulkUpdateAvailabilityVars = (
  restaurantId: string,
  selectedCategoriesIds: ModelFullCategory["id"][],
  activeFrom: ModelCategoryDNA["activeFrom"],
  activeTo: ModelCategoryDNA["activeTo"]
) => ({
  input: {
    restaurantId,
    levelType: LevelsBulkEnum.category,
    ids: selectedCategoriesIds,
    bulkAction: BulkRequestActionEnum.update,
    params: { activeFrom, activeTo },
  },
});

export * as BulkUpdateAvailabilityTypes from "./__generated__/BulkUpdateAvailabilityMutation";
