import { ClickAwayListener } from "@material-ui/core";
import React, { useCallback, useRef } from "react";
import { styles } from "./ScrollToFieldWithError.styles";

export interface ScrollToFieldWithErrorProps {
  shouldScrollToField?: boolean;
  children: React.ReactNode;
}

export const ScrollToFieldWithError: React.FC<ScrollToFieldWithErrorProps> = ({
  children,
  shouldScrollToField = true,
}) => {
  let containerRef = useRef<HTMLDivElement>(null);

  const handleClickAwayProblematicAsset = useCallback(() => {
    if (!containerRef.current) return;
    if (!shouldScrollToField) return;
    containerRef.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }, [shouldScrollToField]);

  return (
    <ClickAwayListener onClickAway={handleClickAwayProblematicAsset}>
      <div className={styles.container} ref={containerRef}>
        {children}
      </div>
    </ClickAwayListener>
  );
};
