import { useReactiveVar } from "@apollo/client";
import { useDidUpdate, useToggle } from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { PriceLevelPrices } from "@model/helperTypes/PriceLevelPrices";
import { restaurantCurrencyVar } from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import React, { useCallback } from "react";
import { MenuItem } from "../../components/MenuItem/MenuItem";
import { useCreateMenuItem } from "../../gqlHooks/menuItem/mutations";

export interface NewMenuItemContainerProps {
  newMenuItem: ModelMenuItem;
  parentCategory: ModelFullCategory;
  onMenuItemCreated: VoidFunction;
  onDeleteClick: VoidFunction;
  topLevelPriceLevelPrices: PriceLevelPrices[];
}

export const NewMenuItemContainer: React.FC<NewMenuItemContainerProps> = ({
  newMenuItem,
  parentCategory,
  onMenuItemCreated,
  onDeleteClick,
  topLevelPriceLevelPrices,
}) => {
  const [isMenuItemActive, toggleMenuItemActive] = useToggle(
    newMenuItem.active
  );
  const currency = useReactiveVar(restaurantCurrencyVar);

  const [createMenuItemMutation, isCreateMenuItemLoading] = useCreateMenuItem(
    parentCategory.id
  );

  const handleChangeTitle = useCallback(
    (values: any) => {
      createMenuItemMutation(values.title, isMenuItemActive);
    },
    [createMenuItemMutation, isMenuItemActive]
  );

  useDidUpdate(() => {
    !isCreateMenuItemLoading && onMenuItemCreated();
  }, [isCreateMenuItemLoading, onMenuItemCreated]);

  return (
    <MenuItem
      currency={currency}
      toggleImageModalOpen={noop}
      parentCategory={parentCategory}
      isNew
      menuItem={newMenuItem}
      onClone={noop}
      onDelete={onDeleteClick}
      onToggle={toggleMenuItemActive}
      isActive={isMenuItemActive}
      topLevelPriceLevelPrices={topLevelPriceLevelPrices}
      onChangeTitle={handleChangeTitle}
      onChangeSku={noop}
      onDescriptionClick={noop}
      isEditTitleLoading={isCreateMenuItemLoading}
      isEditSkuLoading={isCreateMenuItemLoading}
      onShowCommonSettings={noop}
      onShowAddons={noop}
      modals={null}
    />
  );
};
