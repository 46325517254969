import { useReactiveVar } from "@apollo/client";
import logoTransparent from "@assets/SVG/loader-static-transparent.svg";
import { useOpenClose } from "@hooks";
import { ModelCategoryWithMenuItemsAmount } from "@model/DAO/MenuCategory";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { LibrarySettingsContainer } from "@pages/RestaurantMenuPage/containers/LibrarySettings/LibrarySettings";
import { NewCategoryContainer } from "@pages/RestaurantMenuPage/containers/NewCategory/NewCategory";
import { useImportFromLibrary } from "@pages/RestaurantMenuPage/hooks/useImportFromLibrary";
import { LibraryModal } from "@pages/RestaurantMenuPage/modals/LibraryModal/LibraryModal";
import { getNewCategoryFixture } from "@pages/RestaurantMenuPage/utils/Category";
import { themeColors } from "@theme/colors";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Cloud, NewItem } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { menuIdVar } from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import React, { useCallback, useState } from "react";
import { AddNewCategory } from "../AddNewCategory/AddNewCategory";
import { styles } from "./EmptyMenuBanner.styles";
import { useTranslation } from "react-i18next";

export interface EmptyMenuBannerProps {}

// TODO: separate component from container
export const EmptyMenuBanner: React.FC<EmptyMenuBannerProps> = () => {
  const { t } = useTranslation(["empty-menu-banner"]);
  const isPartner = getIsPartner();
  const menuId = useReactiveVar(menuIdVar);

  const [
    newCategoryFixture,
    setNewCategoryFixture,
  ] = useState<ModelCategoryWithMenuItemsAmount | null>(null);

  const [
    isLibraryModalOpen,
    openLibraryModal,
    closeLibraryModal,
  ] = useImportFromLibrary(menuId, MenuAssetEnum.menu);

  const [
    isNewCategoryBlockOpen,
    openNewCategoryBlock,
    closeNewCategoryBlock,
  ] = useOpenClose(false);

  const clearNewCategory = useCallback(() => {
    setNewCategoryFixture(null);
    closeNewCategoryBlock();
  }, [closeNewCategoryBlock]);

  const makeNewCategory = useCallback(() => {
    setNewCategoryFixture(getNewCategoryFixture([]));
  }, []);

  const handleOpenNewCategoryBlock = useCallback(() => {
    makeNewCategory();
    openNewCategoryBlock();
  }, [makeNewCategory, openNewCategoryBlock]);

  if (isNewCategoryBlockOpen) {
    return (
      <>
        {newCategoryFixture && (
          <NewCategoryContainer
            newCategoryFixture={newCategoryFixture}
            onCategoryCreated={clearNewCategory}
            onDeleteClick={clearNewCategory}
          />
        )}
        <AddNewCategory onClick={makeNewCategory} />
      </>
    );
  }
  return (
    <>
      <img className={styles.logoTransparent} src={logoTransparent} alt={""} />
      <VerticalAlignBetween className={styles.container}>
        <div className={styles.title}>Getting started</div>
        <div className={styles.subtitle}>
          No menu here yet - add some stuff to get started!
        </div>
        <JustifyContentBetweenLayout className={styles.btnRow(isPartner)}>
          <VerticalAlignBetween className={styles.btnContainer}>
            <MmsSvgButton
              id={"add-first-category-icon-btn"}
              onClick={handleOpenNewCategoryBlock}
            >
              <NewItem
                id={"add-first-category-icon"}
                size={"extra-large"}
                color={themeColors.white}
              />
            </MmsSvgButton>
            <MmsPrimaryButton
              id={"add-first-category-btn"}
              onClick={handleOpenNewCategoryBlock}
            >
              {t("empty-menu-banner:add-first-category")}
            </MmsPrimaryButton>
          </VerticalAlignBetween>
          <AccessControl>
            <VerticalAlignBetween className={styles.btnContainer}>
              <MmsSvgButton
                id={"add-from-library-to-empty-menu-icon-btn"}
                onClick={openLibraryModal}
              >
                <Cloud
                  id={"add-from-library-to-empty-menu-icon"}
                  size={"extra-large"}
                  color={themeColors.white}
                />
              </MmsSvgButton>
              <MmsPrimaryButton
                id={"add-from-library-to-empty-menu-btn"}
                onClick={openLibraryModal}
              >
                {t("empty-menu-banner:add-from-library")}
              </MmsPrimaryButton>
            </VerticalAlignBetween>
          </AccessControl>
        </JustifyContentBetweenLayout>
        {isLibraryModalOpen && (
          <LibraryModal closeModal={closeLibraryModal}>
            <LibrarySettingsContainer onFinish={closeLibraryModal} />
          </LibraryModal>
        )}
      </VerticalAlignBetween>
    </>
  );
};
