import { gql } from "@apollo/client";
import { convertObjectPricesToNumbers } from "@pages/RestaurantMenuPage/utils/Addon";
import { Levels } from "__generated__/globalTypes";

export const EDIT_MENU_ITEM_PRICES = gql`
  mutation EditMenuItemPricesMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuItem {
        id
        price
        priceLevel1
        priceLevel2
        priceLevel3
        priceLevel4
        priceLevel5
      }
    }
  }
`;

export const generateEditMenuItemPricesVars = (
  restaurantId: string,
  menuItemId: string,
  newPrices: any
) => ({
  input: {
    restaurantId,
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
    menuItem: {
      ...convertObjectPricesToNumbers(newPrices),
    },
  },
});

export * as EditMenuItemPricesTypes from "./__generated__/EditMenuItemPricesMutation";
