import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    width: 800px;
    height: 100%;

    border-radius: ${themeVars.borderRadius};

    background-color: ${themeColors.white};

    box-shadow: ${themeUtils.boxShadowSettings(80)} ${themeUtils.boxShadowOpacity()};
    overflow: hidden;
  `,
  statusBar: css`
    height: 50px;

    border-bottom: 2px solid ${themeColors.cream};
  `,
  mainContent: css`
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
  `,
  leftCol: css`
    width: 30%;
    max-width: 30%;
    max-height: 100%;
    padding: 10px;

    background-color: ${themeColors.cream};

    overflow: overlay;

    ${themeUtils.scrollBar}
  `,
  rightCol: css`
    width: 70%;
    max-width: 70%;
  `,
  rightColContent: css`
    height: calc(100% - 60px - 90px); /* 100% - headerHeight - footerHeight */
    padding: 15px 35px;

    overflow: overlay;

    ${themeUtils.scrollBar}
  `,
  rightColHeader: css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 60px;
    padding: 0 35px;

    box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.1);
  `,
  rightColHeaderTitle: css`
    min-width: 50%;
    max-width: calc(100% - 115px);
    padding-right: 15px;
  `,
  rightColFooter: css`
    display: flex;
    align-items: center;

    height: 90px;
    padding: 0 35px;

    box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.1);
  `,
};
