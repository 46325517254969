import { SCREEN_SIZES } from "@constants";
import { css } from "@emotion/css";

export const styles = {
  container: css`
    @media (max-width: ${SCREEN_SIZES.MD - 1}px) {
      padding: 0 15px;
    }
  `,
  toolbar: css`
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 15px;
    min-height: 42px;
  `,
};
