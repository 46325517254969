import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { styles } from "./NewAddonMinMaxForm.styles";
import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";

interface NewAddonMinMaxFormValues {
  min?: string;
  max?: string;
}

export const NewAddonMinMax: React.FC<
  NewAddonMinMaxFormProps & FormikProps<NewAddonMinMaxFormValues>
> = ({ min, max, uniqId, ...formikProps }) => {
  const { t } = useTranslation(["addons"]);
  const formId = `addon-min-max-form-${uniqId}`;

  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useDidUpdate(() => {
    formikProps.resetForm();
  }, [min, max]);

  const handleBlur = useCallback(() => {
    formikProps.submitForm();
  }, [formikProps]);

  return (
    <form className={styles.form}>
      <NumberFormat
        id={`addon-min-${uniqId}`}
        name="min"
        label={t("addons:min")}
        customInput={MmsMuiInput}
        value={formikProps.values.min}
        onBlur={handleBlur}
        allowNegative={false}
      />
      <NumberFormat
        id={`addon-max-${uniqId}`}
        name="max"
        label={t("addons:max")}
        customInput={MmsMuiInput}
        value={formikProps.values.max}
        onBlur={handleBlur}
        allowNegative={false}
      />
    </form>
  );
};

interface NewAddonMinMaxFormProps {
  min?: number;
  max?: number;
  uniqId: string;
  onSubmit: (values: { min?: number; max?: number }) => void;
}

// Wrap our form with the withFormik HoC
export const NewAddonMinMaxForm = withFormik<
  NewAddonMinMaxFormProps,
  NewAddonMinMaxFormValues
>({
  enableReinitialize: true,
  mapPropsToValues: ({ min, max }) => ({
    min: min ? String(min) : "",
    max: max ? String(max) : "",
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({
      min: values.min ? Number(values.min) : undefined,
      max: values.max ? Number(values.max) : undefined,
    });
  },
})(NewAddonMinMax);
