import { useOpenClose } from "@hooks";
import { ModelWorkingUser } from "@model/DAO/WorkingUsers";
import React from "react";
import { FooterLayout } from "src/sharedComponents/FooterLayout/FooterLayout";
import { ShowMoreButton } from "../MenuHeader/buttons/ShowMoreButton/ShowMoreButton";
import { WorkingUsersButton } from "../MenuHeader/buttons/WorkingUsersButton/WorkingUsersButton";
import { styles } from "./MenuFooter.styles";
import { MoreActionsMobileModal } from "./mobileModals/MoreActions/MoreActions";
import { WorkingUsersMobileModal } from "./mobileModals/WorkingUsers/WorkingUsers";

interface MenuFooterProps {
  workingUsers?: ModelWorkingUser[];
}

export const MenuFooter: React.FC<MenuFooterProps> = ({ workingUsers }) => {
  const [
    isWorkingUserListModalOpen,
    openWorkingUserListModal,
    closeWorkingUserListModal,
  ] = useOpenClose(false);

  const [
    isMoreActionsModalOpen,
    openMoreActionsModal,
    closeMoreActionsModal,
  ] = useOpenClose(false);
  return (
    <>
      <FooterLayout wrapperClassName="d-lg-none">
        <WorkingUsersButton
          onClick={openWorkingUserListModal}
          id={`working-user-list-mobile-footer-btn`}
          className={styles.baseControl}
          workingUsersAmount={workingUsers?.length || 1}
        />
        {/* in stack to implementation */}
        {/*
      <MenuCloudButton
        onClick={noop}
        id={`menu-cloud-mobile-footer-btn`}
        className={styles.baseControl}
      />
      <RestoreMenuButton
        onClick={noop}
        id={`restore-menu-mobile-footer-btn`}
        className={styles.baseControl}
      />
      <PublishMenuButton
        onClick={noop}
        id={`publish-menu-mobile-footer-btn`}
        className={styles.baseControl}
        disabled={false}
        isPublished={false}
      />
      */}
        <ShowMoreButton
          onClick={openMoreActionsModal}
          id={`more-menu-mobile-footer-btn`}
          className={styles.baseControl}
        />
      </FooterLayout>

      <WorkingUsersMobileModal
        workingUsers={workingUsers}
        isWorkingUserListModalOpen={isWorkingUserListModalOpen}
        closeWorkingUserListModal={closeWorkingUserListModal}
      />
      <MoreActionsMobileModal
        isMoreActionsModalOpen={isMoreActionsModalOpen}
        closeMoreActionsModal={closeMoreActionsModal}
      />
    </>
  );
};
