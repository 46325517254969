import React, { useState } from "react";
import { MainTaxForm } from "./MainTaxes/MainTaxes";
import { styles } from "./Taxes.styles";
import { useReactiveVar } from "@apollo/client";
import { countryTaxesVar } from "@utils/apolloReactiveVars";
import {
  Levels,
  ServiceType,
  TaxAssociationData,
} from "__generated__/globalTypes";
import { TaxModel } from "@model/DAO/MenuItemTax";
import { useTranslation } from "react-i18next";

interface TaxesProps {
  associatedTaxes: TaxAssociationData[];
  preserveLocalTaxes: (newTax: TaxAssociationData[]) => void;
  uniqId: string;
  entityType: Levels;
}

const mapToTaxModel = (
  taxesGroup: TaxAssociationData[],
  countryTaxes: TaxModel[],
  serviceType: ServiceType | null = null
): TaxModel | undefined => {
  const mappedTaxes = taxesGroup
    .filter((tax) => tax.serviceType === serviceType)
    .reduce((taxArray, currentTax): any => {
      const newMappedTax = countryTaxes.filter(
        (countryTax) => +countryTax.id === +currentTax.taxIds[0]
      )[0];
      return [...taxArray, newMappedTax];
    }, []);
  return !!mappedTaxes.length ? mappedTaxes[0] : undefined;
};

export const Taxes: React.FC<TaxesProps> = ({
  associatedTaxes,
  preserveLocalTaxes,
  uniqId,
  entityType,
}) => {
  const { t } = useTranslation(["taxes"]);
  const countryTaxes = useReactiveVar(countryTaxesVar);
  const [mainTaxModel, setMainTaxModel] = useState<TaxModel | undefined>(
    mapToTaxModel(associatedTaxes, countryTaxes, null)
  );
  const [deliveryTaxModel, setDeliveryTaxModel] = useState<
    TaxModel | undefined
  >(mapToTaxModel(associatedTaxes, countryTaxes, ServiceType.Delivery));

  const [dineInTaxModel, setDineInTaxModel] = useState<TaxModel | undefined>(
    mapToTaxModel(associatedTaxes, countryTaxes, ServiceType.DineIn)
  );

  const [pickupTaxModel, setPickupTaxModel] = useState<TaxModel | undefined>(
    mapToTaxModel(associatedTaxes, countryTaxes, ServiceType.Pickup)
  );

  const [hasTaxesApplied, setHasTaxesApplied] = useState<boolean>(
    !!associatedTaxes.length
  );

  const handleChangeTaxes = (
    newTax: string,
    serviceType: ServiceType | null
  ) => {
    const taxAssociationId =
      associatedTaxes.find((tax) => tax.serviceType === serviceType)
        ?.taxAssociationId || null;

    const mappedMainTaxAssociationData: TaxAssociationData = {
      menuCategoryId: entityType === Levels.CATEGORY ? uniqId : null,
      menuItemId: entityType === Levels.MENU_ITEM ? uniqId : null,
      serviceType: serviceType,
      taxAssociationId,
      taxIds: newTax ? [newTax] : [],
      destroy: !newTax,
    };
    const otherTaxAssociationData = associatedTaxes.filter(
      (tax) => tax.serviceType !== serviceType
    );
    setHasTaxesApplied(!!newTax);
    preserveLocalTaxes([
      ...otherTaxAssociationData,
      mappedMainTaxAssociationData,
    ]);
  };

  return (
    <div className={styles.wrapper}>
      <MainTaxForm
        uniqId={`${uniqId}-mainTax`}
        taxOptions={countryTaxes}
        taxData={mainTaxModel}
        onSubmit={handleChangeTaxes}
        title="mainTax"
        onChangeAvailable={setMainTaxModel}
        serviceType={null}
        taxesTitle={t("taxes:main-tax")}
      />
      <MainTaxForm
        uniqId={`${uniqId}-deliveryTax`}
        taxOptions={countryTaxes}
        taxData={deliveryTaxModel}
        onSubmit={handleChangeTaxes}
        title="mainTax"
        onChangeAvailable={setDeliveryTaxModel}
        serviceType={ServiceType.Delivery}
        taxesTitle={t("taxes:delivery-tax")}
      />
      <MainTaxForm
        uniqId={`${uniqId}-pickupTax`}
        taxOptions={countryTaxes}
        taxData={pickupTaxModel}
        onSubmit={handleChangeTaxes}
        title="mainTax"
        onChangeAvailable={setPickupTaxModel}
        serviceType={ServiceType.Pickup}
        taxesTitle={t("taxes:pickup-tax")}
      />
      <MainTaxForm
        uniqId={`${uniqId}-dineInTax`}
        taxOptions={countryTaxes}
        taxData={dineInTaxModel}
        onSubmit={handleChangeTaxes}
        title="mainTax"
        onChangeAvailable={setDineInTaxModel}
        serviceType={ServiceType.DineIn}
        taxesTitle={t("taxes:dine-in-tax")}
      />
      {!hasTaxesApplied && (
        <div> {t("taxes:pleas-update-to-apply-new-taxes")} </div>
      )}
    </div>
  );
};
