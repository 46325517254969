import { useDidUpdate, useOpenClose } from "@hooks";
import { LibraryAssetNodes } from "@model/DAO/Library";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import React, { useCallback } from "react";
import { LibraryAssetSettings } from "../../components/LibraryAssetSettings/LibraryAssetSettings";
import {
  useDestroyLibraryAsset,
  useUpdateLibraryAsset,
} from "../../gqlHooks/library/mutations";
import { useTranslation } from "react-i18next";

export interface LibraryAssetSettingsContainerProps {
  id: LibraryAssetNodes["id"];
  title: LibraryAssetNodes["title"];
  favorite: LibraryAssetNodes["favorite"];
  isTabSelected: boolean;
  isCheckboxSelected: boolean;
  onToggleCheckbox: (id: LibraryAssetNodes["id"]) => void;
  onSelectAsset: (id: number | null) => void;
}

export const LibraryAssetSettingsContainer: React.FC<LibraryAssetSettingsContainerProps> = ({
  id,
  title,
  favorite,
  isTabSelected,
  isCheckboxSelected,
  onToggleCheckbox,
  onSelectAsset,
}) => {
  const { t } = useTranslation(["common"]);
  const [
    isDeleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
  ] = useOpenClose(false);

  const [onDestroy, destroyLoading] = useDestroyLibraryAsset(id);

  const [onUpdateAsset, updateAssetLoading] = useUpdateLibraryAsset(id);

  const handleToggleFavorite = useCallback(
    () => onUpdateAsset(title, !favorite),
    [favorite, onUpdateAsset, title]
  );

  const handleChangeTitle = useCallback(
    (values: any) => onUpdateAsset(values.title, favorite),
    [favorite, onUpdateAsset]
  );

  useDidUpdate(() => {
    if (destroyLoading) return;
    closeDeleteConfirmationModal();
  }, [destroyLoading]);

  return (
    <LibraryAssetSettings
      id={id}
      title={title}
      favorite={favorite}
      isTabSelected={isTabSelected}
      isCheckboxSelected={isCheckboxSelected}
      isEditTitleLoading={updateAssetLoading}
      onSelectAsset={onSelectAsset}
      onToggleCheckbox={onToggleCheckbox}
      onChangeTitle={handleChangeTitle}
      onDelete={openDeleteConfirmationModal}
      onToggleFavorite={handleToggleFavorite}
      modals={
        <>
          {isDeleteConfirmationModalOpen && (
            <MmsConfirmationModal
              message={t("common:are-you-sure-you-want-to-delete", {
                title: title,
              })}
              confirmBtnText={t("common:delete")}
              declineBtnText={t("common:cancel")}
              isDataProcessing={destroyLoading}
              onConfirmBtnClick={onDestroy}
              onDeclineBtnClick={closeDeleteConfirmationModal}
            />
          )}
        </>
      }
    />
  );
};
