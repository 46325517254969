import { useDidMount, useDidUpdate, useOpenClose } from "@hooks";
import { useRefreshTokenByInterval } from "@pages/Auth/hooks/useRefreshTokenByInterval";
import { useReleaseReadState } from "@pages/ReleaseNotesPage/hooks/useReleaseReadState";
import { getAllReleases } from "@pages/ReleaseNotesPage/utils/ReleaseNote";
import { useGetDraftMenu } from "@pages/RestaurantMenuPage/gqlHooks/menu/queries";
import MmsLogoSpinnerWithMobileHack from "@uiKit/Atoms/Spinner/MmsLogoSpinner";
import { iwaiterIdVar } from "@utils/apolloReactiveVars";
import React from "react";

export interface IReleaseNote {
  version: string;
  title: string;
  body: string;
}

export const withEssentialData = <P,>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P) => {
  useRefreshTokenByInterval();

  const [isMenuDataLoading, menuData] = useGetDraftMenu();
  const [isSpinnerOpen, , closeSpinner] = useOpenClose(true);
  const [, setIsReleaseRead] = useReleaseReadState();

  useDidMount(() => {
    const timeoutId = setTimeout(closeSpinner, 1500);
    return () => clearTimeout(timeoutId);
  });

  useDidMount(() => {
    setIsReleaseRead();
  });

  useDidUpdate(() => {
    menuData && iwaiterIdVar(menuData.restaurant.iwaiterId);
  }, [menuData]);

  return isMenuDataLoading || isSpinnerOpen || !menuData ? (
    <MmsLogoSpinnerWithMobileHack />
  ) : (
    <Component
      restaurant={menuData.restaurant}
      releases={getAllReleases()}
      {...props}
    />
  );
};
