import React from "react";
import { styles } from "./EmptyList.styles";
import { useTranslation } from "react-i18next";

interface AddonItemsEmptyListProps {}

export const AddonItemsEmptyList: React.FC<AddonItemsEmptyListProps> = () => {
  const { t } = useTranslation(["addons"]);
  return <div className={styles.wrapper}>{t("addons:no-items-added-yet")}</div>;
};
