import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";
import { useAddonItemEditingMode } from "@pages/RestaurantMenuPage/hooks/useAddonItemEditingMode";
import { useAddonItemEditingModeReactiveList } from "@pages/RestaurantMenuPage/hooks/useAddonItemEditingModeReactiveList";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { validateForm } from "@utils/formik";
import { getIsPartner } from "@utils/url";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import { PriceItemData } from "../../../../utils/AddonItem";
import { styles } from "./AddonItemForm.styles";
import { validationMap } from "./AddonItemForm.validationMap";
import { useTranslation } from "react-i18next";

interface StaticFormValues {
  title: string;
}

interface AddonItemFormContentProps {
  addonItemId: string;
  isNew?: boolean;
  isEditModeDisabled?: boolean;
  priceListData: PriceItemData[] | null;
  addonItemTitle: string;
  onSubmit: (values: any) => void;
  onPressEnter: VoidFunction;
}

const AddonItemFormContent: React.FC<
  AddonItemFormContentProps & FormikProps<StaticFormValues>
> = ({
  addonItemId,
  isNew,
  isEditModeDisabled,
  priceListData,
  onPressEnter,
  ...formikProps
}) => {
  const { t } = useTranslation(["common"]);
  const formId = `addon-item-form-${addonItemId}`;

  const [
    isCurrentAddonItemEditingMode,
    enterEditingMode,
    leaveEditingMode,
  ] = useAddonItemEditingMode(addonItemId);

  const [
    ,
    addAddonItemToEditingModeReactiveList,
    removeAddonItemFromEditingModeReactiveList,
  ] = useAddonItemEditingModeReactiveList();

  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();

  const handleChangeFormattedValue = useCallback(
    (fieldName, values) => {
      const { formattedValue: fieldValue } = values;
      formikProps.setFieldValue(fieldName, fieldValue);
    },
    [formikProps]
  );

  const handleChangePrice = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      formikProps.setFieldValue(name, value);
    },
    [formikProps]
  );

  const handleChangeLevelDisabled = (fieldName: string) => {
    formikProps.setFieldValue(
      fieldName,
      !(formikProps.values as any)[fieldName]
    );
    formikProps.submitForm();
  };

  const handlePressEnter = useCallback(() => {
    onPressEnter();
    formikProps.submitForm();
  }, [formikProps, onPressEnter]);

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useDidUpdate(() => {
    if (isCurrentAddonItemEditingMode) return;

    formikProps.submitForm();
  }, [isCurrentAddonItemEditingMode]);

  useDidUpdate(() => {
    if (!isCurrentAddonItemEditingMode) return;

    addAddonItemToEditingModeReactiveList(addonItemId, {
      enterEditingMode,
      leaveEditingMode,
    });

    return () => {
      removeAddonItemFromEditingModeReactiveList(addonItemId);
    };
  }, [isCurrentAddonItemEditingMode]);

  return (
    <Form className={styles.form}>
      <MmsMuiInput
        data-testid={`addon-item-input-title-${addonItemId}`}
        data-addonitemid={addonItemId}
        containerClassName={styles.baseInput}
        name="title"
        label={t("common:name")}
        focusOnMount={!!isNew}
        onFocus={enterEditingMode}
        onPressEnter={handlePressEnter}
        width={148}
        disabled={isEditModeDisabled || getIsPartner()}
      />
      <div className={styles.row}>
        {priceListData?.map((priceField) => (
          <div key={priceField.name} className={styles.itemWrapper}>
            {priceField.name !== "price" && (
              <MmsTooltip
                title={
                  (formikProps.values as any)[`${priceField.name}Disabled`]
                    ? t("addons:price-level-disabled")
                    : t("addons:price-level-enabled")
                }
              >
                <div>
                  <MmsCheckbox
                    id={`addon-item-checkbox-${priceField.name}-disabled-${addonItemId}`}
                    name={`${priceField.name}Disabled`}
                    checked={
                      !(formikProps.values as any)[`${priceField.name}Disabled`]
                    }
                    disabled={isEditModeDisabled}
                    onChange={() =>
                      handleChangeLevelDisabled(`${priceField.name}Disabled`)
                    }
                  />
                </div>
              </MmsTooltip>
            )}
            <NumberFormat
              data-testid={`addon-item-input-${priceField.name}-${addonItemId}`}
              data-addonitemid={addonItemId}
              containerClassName={styles.baseInput}
              customInput={MmsMuiInput}
              decimalScale={2}
              name={priceField.name}
              label={priceField.label}
              onFocus={enterEditingMode}
              onPressEnter={handlePressEnter}
              width={56}
              value={(formikProps.values as any)[priceField.name]}
              onValueChange={handleChangeFormattedValue.bind(
                null,
                priceField.name
              )}
              onChange={handleChangePrice}
              disabled={isEditModeDisabled}
              allowNegative={false}
            />
          </div>
        ))}
      </div>
    </Form>
  );
};

export interface AddonItemFormProps extends AddonItemFormContentProps {}

export const AddonItemForm = withFormik<AddonItemFormProps, StaticFormValues>({
  // Transform outer props into form values
  mapPropsToValues: ({ addonItemTitle, priceListData }) => {
    let dynamicFields = {};
    priceListData?.forEach(
      ({ name, value, disabled }) =>
        (dynamicFields = {
          ...dynamicFields,
          [name]: value,
          ...(name.startsWith("priceLevel") && {
            [`${name}Disabled`]: disabled,
          }),
        })
    );
    return {
      title: addonItemTitle,
      ...dynamicFields,
    };
  },
  validate: (values) => validateForm(validationMap())(values),
  handleSubmit: (
    values: any,
    { props: { addonItemTitle, priceListData, onSubmit } }
  ) => {
    if (addonItemTitle !== values.title) return onSubmit(values);
    if (!priceListData) return;

    for (let index = 0; index < priceListData.length; index++) {
      const { name, value, disabled } = priceListData[index];

      if (value !== values[name] || disabled !== values[`${name}Disabled`])
        return onSubmit(values);
    }
  },
})(AddonItemFormContent);
