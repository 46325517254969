import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { SCREEN_SIZES } from "@constants";

export const styles = {
  row: css`
    display: flex;
    align-items: center;
    margin: 5px 0;
    gap: 4px;
  `,
  addonIndicator: css`
    min-width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${themeColors.yellow};
  `,
  addonItemsTitleList: css`
    min-width: 120px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 14px;

    @media (min-width: ${SCREEN_SIZES.LG}px) {
      min-width: 220px;
    }
  `,
  singleAddonItemTitle: (isHighlighted: boolean) => css`
    ${isHighlighted &&
    css`
      background-color: ${themeColors.mediumGreenTransparent};
    `}
  `,
  extraAddons: css`
    display: block;
    margin-top: -2px;
    margin-left: 8px;
    font-size: 12px;
    color: ${themeColors.lightGreen};
  `,
  discountValue: css`
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
  `,
};
