import { MmsEmptyContentBanner } from "@uiKit/Atoms/EmptyContentBanner/MmsEmptyContentBanner";
import { MmsSeparator } from "@uiKit/Atoms/Separator/MmsSeparator";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import React from "react";
import { styles } from "./HistoryModalEmptyDesktopLayout.styles";
import { useTranslation } from "react-i18next";

interface HistoryModalEmptyDesktopLayoutProps {
  buttonCell: React.ReactNode;
}

export const HistoryModalEmptyDesktopLayout: React.FC<HistoryModalEmptyDesktopLayoutProps> = ({
  buttonCell,
}) => {
  const { t } = useTranslation(["history"]);
  return (
    <VerticalAlignBetween className={styles.container}>
      <MmsEmptyContentBanner
        className={styles.emptyContent}
        title={t("history:no-menus-available-to-restore")}
        description={t("history:save-menu-when-publishing")}
      />
      <MmsSeparator />
      <JustifyContentEndLayout>{buttonCell}</JustifyContentEndLayout>
    </VerticalAlignBetween>
  );
};
