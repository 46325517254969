import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 50px;

    z-index: 2;
  `,
  logoTransparent: css`
    position: absolute;
    width: 533px;
    right: 0;
    bottom: 0;

    z-index: 1;
  `,
  title: css`
    font-size: 60px;
    font-weight: bold;
    color: ${themeColors.white};
  `,
  subtitle: css`
    padding-top: 16px;

    font-size: 20px;
    color: ${themeColors.white};
  `,
  btnRow: (isCenterAligned: boolean = false) => css`
    ${isCenterAligned ? "justify-content: center" : ""}
    gap: 220px;
    padding-top: 100px;
  `,
  btnContainer: css`
    align-items: center;
    gap: 30px;
  `,
};
