import { ReactComponent as SVGStarBorder } from "@assets/SVG/star-border.svg";
import { ReactComponent as SVGStar } from "@assets/SVG/star.svg";
import { cx } from "@emotion/css";
import React, { useMemo } from "react";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IStarProps extends IIconBase {
  isFilled?: boolean;
  color?: string;
}

export const Star: React.FC<IStarProps> = ({
  size = "medium",
  className,
  isFilled,
  color,
}) => {
  const StarIcon = useMemo(() => (isFilled ? SVGStar : SVGStarBorder), [
    isFilled,
  ]);
  return (
    <StarIcon
      className={cx(styles.icon(size), className)}
      fill={(isFilled && color) || ""}
    />
  );
};
