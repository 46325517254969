import { injectGlobal } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

injectGlobal`
.MmsMuiSelectbox {
  .MuiFormControl-root {
    min-width: 166px;
    width: 100%;
  }
  .MuiSelect-filled.MuiSelect-filled {
    border-radius: ${themeVars.borderRadius};
  }
  .MuiFilledInput-underline {
    &:before, &:after {
      display: none;
    }
  }
  .MuiInputLabel-root {
    margin: 0;

    color: ${themeColors.mediumGray} !important;
    font-family: ${themeVars.fontFamily};
    font-size: 8px;
    font-weight: 700;
    text-transform: uppercase;
    transform: translate(7px, 14px) scale(1.3) !important;
  }

  .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(7px, 5px) !important;
  }

  .MuiSelect-root {
    padding: 14px 7px 7px;

    font-family: ${themeVars.fontFamily};
    font-size: 16px;
    font-weight: 300;

    &, &:focus {
      background-color: ${themeColors.lightGrey};
    }
  }
}
.MmsMuiSelectbox-fullWidth {
  &, & .MuiFormControl-root {
    width: 100%;
  }
}
`;
