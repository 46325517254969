import { css } from "@emotion/css";

export const styles = {
  list: css`
    margin: 0;
    padding: 10px 25px 35px;
  `,

  item: css`
    margin-top: 25px;
    margin-bottom: 25px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
};
