import { MmsDummyTab } from "@uiKit/Molecules/Tabs/MmsTab/dummy";
import React from "react";
import { LibraryAssetDesktopSkeletonLayout } from "../LibraryAssetLayout";
import { styles } from "./LibraryModalLayout.styles";

export interface LibraryModalLayoutProps {
  firstCol: React.ReactNode;
  secondCol: React.ReactNode;
  thirdCol: React.ReactNode;
  contentLoading: boolean;
  secondColRef: React.RefObject<HTMLDivElement>;
}

export const LibraryModalLayout: React.FC<LibraryModalLayoutProps> = React.forwardRef<
  HTMLDivElement,
  LibraryModalLayoutProps
>(
  (
    { firstCol, secondCol, thirdCol, contentLoading, secondColRef, ...rest },
    ref
  ) => {
    return (
      <div {...rest} ref={ref} className={styles.container}>
        <div className={styles.firstCol}>{firstCol}</div>
        <div ref={secondColRef} className={styles.secondCol}>
          {contentLoading ? (
            <MmsDummyTab>
              <LibraryAssetDesktopSkeletonLayout />
            </MmsDummyTab>
          ) : (
            secondCol
          )}
        </div>
        <div className={styles.thirdCol}>
          {contentLoading ? (
            <MmsDummyTab>
              <LibraryAssetDesktopSkeletonLayout />
            </MmsDummyTab>
          ) : (
            thirdCol
          )}
        </div>
      </div>
    );
  }
);
