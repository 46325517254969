import {
  DraggableDataItem,
  DraggableDataState,
} from "@model/helperTypes/dragAndDrop";

export const convertDraggableDataToOriginShape = <T>(
  draggableData: DraggableDataItem<T>[]
): T[] =>
  draggableData.map(({ itemData }) => ({
    ...itemData,
  }));

export const mapDataToDraggable = <T>(data: T[]) =>
  data.reduce<DraggableDataState<T>>(
    (state, item, index) => {
      const draggableDataItem = { id: index, itemData: item };

      return {
        initialDraggableData: [
          ...state.initialDraggableData,
          draggableDataItem,
        ],
        mutableDraggableData: [
          ...state.mutableDraggableData,
          draggableDataItem,
        ],
      };
    },
    {
      initialDraggableData: [],
      mutableDraggableData: [],
    }
  );
