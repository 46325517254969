import { useErrorBlockingNotifications } from "@pages/RestaurantMenuPage/hooks/useErrorBlockingNotifications";
import { useErrorRegularNotifications } from "@pages/RestaurantMenuPage/hooks/useErrorRegularNotifications";
import { useSuccessNotifications } from "@pages/RestaurantMenuPage/hooks/useSuccessNotifications";
import { useEffect } from "react";
import { usePriceEditingMode } from "./usePriceEditingMode";
import { useScreenResizeWidth } from "./useScreenResizeWidth";

const useDetectFocusedElementInPriceMode = () => {
  const [isPriceEditingMode, , leavePriceEditingMode] = usePriceEditingMode();

  useEffect(() => {
    if (!isPriceEditingMode) return;

    const intervalId = setInterval(() => {
      if (
        document
          .querySelector(":focus")
          ?.getAttribute("name")
          ?.includes("price")
      )
        return;
      leavePriceEditingMode();
    }, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, [isPriceEditingMode, leavePriceEditingMode]);
};

export const useCommonTopLevelEffects = () => {
  useDetectFocusedElementInPriceMode();
  useErrorRegularNotifications();
  useErrorBlockingNotifications();
  useSuccessNotifications();
  useScreenResizeWidth();
};
