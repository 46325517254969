import React from "react";
import { animated, useSpring } from "react-spring";

interface SlideDownProps {
  className: string;
  in: boolean;
  onEnter?: VoidFunction;
  onExited?: VoidFunction;
  children?: React.ReactNode;
}

export const SlideDown = React.forwardRef<HTMLDivElement, SlideDownProps>(
  function SlideDown(props, ref) {
    const { in: open, children, onEnter, onExited, ...rest } = props;
    const style = useSpring({
      from: { transform: "translateY(100%)" },
      to: { transform: open ? "translateY(0%)" : "translateY(100%)" },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });

    return (
      <animated.div ref={ref} style={style} {...rest}>
        {children}
      </animated.div>
    );
  }
);
