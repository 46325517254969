import { useState } from "react";

export function useLocalStorage(
  key: string,
  initialValue: string
): [string, (v: string) => void] {
  const [storedValue, setStoredValue] = useState(() => {
    return window.localStorage.getItem(key) || initialValue;
  });

  const setValue = (value: any) => {
    setStoredValue(value);
    window.localStorage.setItem(key, value);
  };

  return [storedValue, setValue];
}
