import { RefObject, useEffect, useRef, useState } from "react";
import { isSafari } from "react-device-detect";

export const useHiddenText = (): [RefObject<HTMLDivElement>, boolean] => {
  const [isTextHidden, setTextHidden] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSafari) return;
    if (!textRef.current) return;
    if (textRef.current.offsetWidth >= textRef.current.scrollWidth) return;
    setTextHidden(true);
  }, [textRef, setTextHidden]);

  return [textRef, isTextHidden];
};
