import { cx } from "@emotion/css";
import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";
import { themeColors } from "@theme/colors";
import { useField } from "formik";
import React, { useCallback } from "react";
import "./globalStyles";
import { styles } from "./styles";

export interface MmsDateTimePickerProps
  extends Omit<DateTimePickerProps, "error"> {
  id: string;
  name: string;
  label: string;
  disabled?: boolean;
  containerClassName?: string;
  error?: string;
  errorColor?: string;
  showErrorMessage?: boolean;
  showUnderline?: boolean;
  fullWidth?: boolean;
}

export const MmsDateTimePicker: React.FC<MmsDateTimePickerProps> = ({
  containerClassName,
  error,
  errorColor = themeColors.red,
  showErrorMessage,
  showUnderline = true,
  fullWidth,
  ...restProps
}) => {
  return (
    <div
      className={cx(
        "MmsDateTimePicker",
        fullWidth && "MmsDateTimePicker-fullWidth",
        showUnderline ? "underlined" : "outlined",
        styles.container(!!error, errorColor),
        containerClassName
      )}
    >
      <DateTimePicker {...restProps} />
      {!!error && showErrorMessage && (
        <div className={cx("errorMessage", styles.error(errorColor))}>
          {error}
        </div>
      )}
    </div>
  );
};

export interface ConnectedMmsDateTimePickerProps
  extends Omit<MmsDateTimePickerProps, "onChange" | "value"> {
  validate?: (value: any) => undefined | string | Promise<any>;
  onChange?: (date: Date | null) => void;
}

export const ConnectedMmsDateTimePicker: React.FC<ConnectedMmsDateTimePickerProps> = ({
  name,
  validate,
  onChange,
  ...restProps
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const handleChange = useCallback(
    (date: Date | null) => {
      helpers.setValue(date);
      onChange?.(date);
    },
    [helpers, onChange]
  );

  return (
    <MmsDateTimePicker
      {...field}
      {...restProps}
      error={meta.error}
      onChange={handleChange}
    />
  );
};
