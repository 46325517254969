import { injectGlobal } from "@emotion/css";
import { themeVars } from "@theme/vars";

injectGlobal`
.MmsTableCell {
  &.MuiTableCell-root {
    font-family: ${themeVars.fontFamily};
    font-size: 14px;
  }
  &.MuiTableCell-head {
    font-weight: bold;
    font-size: 14px;
  }
}
`;
