import { useReactiveVar } from "@apollo/client";
import { AddonItemEditingModeReactiveItem } from "@model/helperTypes/AddonItemsSettings";
import { addonItemEditingModeReactiveListVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

type ReturnTuple = [
  AddonItemEditingModeReactiveItem[],
  (
    addonItemId: string,
    addonItemAPI: AddonItemEditingModeReactiveItem["addonItemAPI"]
  ) => void,
  (addonItemId: string) => void,
  VoidFunction
];

export const useAddonItemEditingModeReactiveList = (): ReturnTuple => {
  const addToList = useCallback(
    (
      addonItemId: string,
      addonItemAPI: AddonItemEditingModeReactiveItem["addonItemAPI"]
    ) => {
      const reactiveList = addonItemEditingModeReactiveListVar();
      addonItemEditingModeReactiveListVar([
        ...reactiveList,
        { addonItemId, addonItemAPI },
      ]);
    },
    []
  );

  const removeFromList = useCallback((addonItemId: string) => {
    const reactiveList = addonItemEditingModeReactiveListVar();
    addonItemEditingModeReactiveListVar(
      reactiveList.filter(
        (reactiveItem) => reactiveItem.addonItemId !== addonItemId
      )
    );
  }, []);

  const immediateLeaveEveryAddonItemEditingMode = useCallback(() => {
    const reactiveList = addonItemEditingModeReactiveListVar();
    reactiveList.forEach((reactiveItem) =>
      reactiveItem.addonItemAPI.leaveEditingMode()
    );
  }, []);

  return [
    useReactiveVar(addonItemEditingModeReactiveListVar),
    addToList,
    removeFromList,
    immediateLeaveEveryAddonItemEditingMode,
  ];
};
