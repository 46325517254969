import { gql } from "@apollo/client";

export const DESTROY_SCHEDULE_TASK = gql`
  mutation DestroyScheduleTaskMutation($input: ScheduleTaskDeleteData!) {
    scheduleDel(input: $input)
  }
`;

export const generateDestroyScheduleTaskVars = (
  restaurantId: string,
  id: string
) => ({
  input: {
    restaurantId,
    id,
  },
});

export * as DestroyScheduleTaskTypes from "./__generated__/DestroyScheduleTaskMutation";
