import { ModelRestoredMenu } from "@model/DAO/MenuState";
import { useGetHistory } from "@pages/RestaurantMenuPage/gqlHooks/history/queries";
import { HistoryModalDesktopSkeletonLayout } from "@pages/RestaurantMenuPage/layouts/HistoryModalLayout";
import React from "react";

export interface IncomingProps {
  onCancel: VoidFunction;
}

export interface OutgoingProps extends IncomingProps {
  history: ModelRestoredMenu[];
}

export const withHistory = (
  Component: React.ComponentType<OutgoingProps>
): React.FC<IncomingProps> => (props) => {
  const [historyDataLoading, historyData] = useGetHistory();

  if (historyDataLoading) {
    return <HistoryModalDesktopSkeletonLayout onCancel={props.onCancel} />;
  }
  if (!historyData) return null;

  return <Component {...props} history={historyData.history} />;
};
