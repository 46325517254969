import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
  addOverride: css`
    color: ${themeColors.lightGreen};
    margin-left: 10px;
  `,
  addTaxWrapper: css`
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    cursor: pointer;
  `,
};
