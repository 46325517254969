import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  contentSection: css`
    padding-top: 15px;
    padding-bottom: 30px;

    border-bottom: 1px solid ${themeColors.cream};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;

      border-bottom: none;
    }
  `,
};
