import { themeColors } from "./colors";

export const themeUtils = {
  scrollBar: `
  &::-webkit-scrollbar {
    width: 4px;

    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;

    background: ${themeColors.veryLightPink};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;

    background: ${themeColors.mediumGray};
  }
`,
  underlineOnHover: `
  &:hover {
    text-decoration: underline dashed;
    text-underline-offset: 3px;
  }
`,
  boxShadowSettings: (blurRadius: number = 15) => `
  0 2px ${blurRadius}px 0
`,
  boxShadowOpacity: (opacity: number = 0.2) => `rgba(0, 0, 0, ${opacity})`,
};
