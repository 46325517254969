import { BULK_DELETE_GALLERY_IMAGES_PROGRESSBAR_ID } from "@constants";
import { useActiveProgressBars } from "@hooks";
import { GalleryBulkPanel } from "@pages/RestaurantMenuPage/components/MenuItem/GalleryBulkPanel/GalleryBulkPanel";
import { useBulkDeleteGalleryImages } from "@pages/RestaurantMenuPage/gqlHooks/gallery/mutations";
import { useSelectedGalleryImages } from "@pages/RestaurantMenuPage/hooks/useSelectedGalleryImages";
import React, { useCallback } from "react";

export const GalleryBulkPanelContainer: React.FC = () => {
  const [
    selectedImagesIds,
    { resetSelectedImages },
  ] = useSelectedGalleryImages();

  const [
    ,
    setMenuProgressBarId,
    removeMenuProgressBarId,
  ] = useActiveProgressBars();

  const handleCompleteDeleteGalleryImagesMutation = useCallback(() => {
    resetSelectedImages();
    removeMenuProgressBarId(BULK_DELETE_GALLERY_IMAGES_PROGRESSBAR_ID);
  }, [removeMenuProgressBarId, resetSelectedImages]);

  const [
    deleteGalleryImagesMutation,
    isDeleteGalleryImagesLoading,
  ] = useBulkDeleteGalleryImages(handleCompleteDeleteGalleryImagesMutation);

  const handleDeleteClick = useCallback(() => {
    deleteGalleryImagesMutation(selectedImagesIds);
    setMenuProgressBarId(BULK_DELETE_GALLERY_IMAGES_PROGRESSBAR_ID);
  }, [selectedImagesIds, deleteGalleryImagesMutation, setMenuProgressBarId]);

  return (
    <GalleryBulkPanel
      onDeleteClick={handleDeleteClick}
      isDeleteGalleryImagesLoading={isDeleteGalleryImagesLoading}
    />
  );
};
