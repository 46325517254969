import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    width: 100%;
  `,
  extraAddons: css`
    display: flex;
    margin-top: 2px;

    color: ${themeColors.lightGreen};
    font-size: 12px;
  `,
  allPricesButton: css`
    display: flex;
    margin-top: -2px;
    
    color: ${themeColors.lightGreen};
    font-size: 12px;
  `,
  title: (isHighlighted: boolean) => css`
    ${isHighlighted &&
    css`
      background-color: ${themeColors.mediumGreenTransparent};
    `}
  `,
  rightTitle: css`
    font-size: 12px;
    line-height: 14px;
  `,
};
