import { css } from "@emotion/css";

export const styles = (focused: boolean) => css`
  position: relative;
  display: flex;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    border: ${focused ? "1px solid #ccc" : "none"};
  }

  &[disabled] {
    &,
    & * {
      cursor: not-allowed;
    }

    & > * {
      opacity: 0.5;
    }
  }
`;
