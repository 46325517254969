export const themeColors = {
  black: "#292929",
  white: "#fff",
  lightGrey: "#f7f7f7",
  mediumGray: "#7c7c7c",
  darkGrey: "#555",
  yellow: "#ffb000",
  red: "#ff0000",
  standardGreen: "#2c806f",
  lightGreen: "#46c187",
  lightGreenTransparent: "rgba(70, 193, 135, 0.1)",
  mediumGreenTransparent: "rgba(70, 193, 135, 0.3)",
  lightPinkTransparent: "rgba(252, 98, 128, 0.1)",
  borderGreyTransparent: "rgba(224, 224, 224, 0.5)",
  borderLightGrey: "#ddd",
  borderGrey: "#aaa",
  pink: "#fc6280",
  veryLightPink: "#eaeaea",
  cream: "#f7f3f0",
  blue: "#2c5a7f",
  burgundy: "#631312",
};
