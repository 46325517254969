import { gql } from "@apollo/client";
import { IMAGE_ESSENTIAL_DATA } from "../../fragments/Image";

export const BULK_DELETE_GALLERY_IMAGES = gql`
  mutation BulkDeleteGalleryImagesMutation($ids: [ID!]!) {
    galleryMultipleDelete(ids: $ids) {
      nodes {
        ...ImageEssentialData
      }
      warnings {
        code
        extensions
        message
      }
    }
  }
  ${IMAGE_ESSENTIAL_DATA}
`;

export * as BulkDeleteGalleryImagesTypes from "./__generated__/BulkDeleteGalleryImagesMutation";
