import { css } from "@emotion/css";

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    min-height: calc(100vh - 2rem);
    padding: 2rem;
  `,
};
