import { DEFAULT_SAVE_TO_HISTORY_FORM } from "@constants";
import { useActiveForms, useDidMount } from "@hooks";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { validateField } from "@utils/formik";
import { Form, FormikProps, withFormik } from "formik";
import { equals } from "ramda";
import React, { useCallback } from "react";
import { styles } from "./SaveToHistorySettingsForm.styles";
import { validationMap } from "./SaveToHistorySettingsForm.validationMap";
import { useTranslation } from "react-i18next";

interface FormValues {
  historyTitle: string;
  historyDescription: string;
}

interface SaveToHistorySettingsFormContentProps {
  isSaveProcessing: boolean;
}

export const SaveToHistorySettingsFormContent: React.FC<
  SaveToHistorySettingsFormContentProps & FormikProps<FormValues>
> = ({ isSaveProcessing, ...formikProps }) => {
  const { t } = useTranslation(["history"]);
  const formId = "save-menu-to-history-form";

  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();

  const handleSubmitForm = useCallback(() => formikProps.handleSubmit(), [
    formikProps,
  ]);

  const handleBlurField = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const { name: fieldName } = e.target;

      formikProps.validateField(fieldName);
    },
    [formikProps]
  );

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  return (
    <Form className={styles.form}>
      <MmsMuiInput
        data-testid={"saved-to-history-menu-title"}
        name={"historyTitle"}
        label={t("history:enter-title-here")}
        onBlur={handleBlurField}
        validate={validateField(validationMap.title)}
        blurOnEnter
        fullWidth
        showErrorMessage
      />
      <MmsMuiInput
        data-testid={"saved-to-history-menu-description"}
        name={"historyDescription"}
        label={t("history:enter-description-here")}
        onBlur={handleBlurField}
        validate={validateField(validationMap.description)}
        fullWidth
        showErrorMessage
        multiline
        rows={5}
      />
      <MmsPrimaryButton
        id="save-to-history"
        onClick={handleSubmitForm}
        processing={isSaveProcessing}
        fullWidth
      >
        {t("history:save-to-history")}
      </MmsPrimaryButton>
    </Form>
  );
};

interface SaveToHistorySettingsFormProps
  extends SaveToHistorySettingsFormContentProps {
  onSubmit: (values: any) => void;
}

// Wrap our form with the withFormik HoC
export const SaveToHistorySettingsForm = withFormik<
  SaveToHistorySettingsFormProps,
  FormValues
>({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => DEFAULT_SAVE_TO_HISTORY_FORM,
  handleSubmit: (values, { props }) => {
    const { historyTitle, historyDescription } = DEFAULT_SAVE_TO_HISTORY_FORM;

    if (
      equals(historyTitle, values.historyTitle) ||
      equals(historyDescription, values.historyDescription)
    )
      return;

    props.onSubmit(values);
  },
})(SaveToHistorySettingsFormContent);
