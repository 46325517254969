import {
  AssetTabIndexes,
  TypesLabelsEnum,
} from "@model/helperTypes/LibraryAsset";
import { Chevron } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsTab } from "@uiKit/Molecules/Tabs/MmsTab";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React, { useCallback, useMemo } from "react";
import { styles } from "./LibraryAssetTabsPanel.styles";
import { useTranslation } from "react-i18next";

interface LibraryAssetTabsPanelProps {
  assets: { [key: string]: boolean };
  onSelect: (key: any) => void;
  selectedTab: number;
}

export const LibraryAssetTabsPanel: React.FC<LibraryAssetTabsPanelProps> = ({
  assets,
  onSelect,
  selectedTab,
}) => {
  const { t } = useTranslation(["library"]);
  const tabs: { [key: string]: any } = useMemo(() => {
    return Object.keys(TypesLabelsEnum).reduce((tempTypes, key) => {
      return {
        ...tempTypes,
        [key]: {
          label: TypesLabelsEnum[key as keyof typeof TypesLabelsEnum],
          index: AssetTabIndexes[key as keyof typeof AssetTabIndexes],
          included: !!assets[key],
        },
      };
    }, {});
  }, [assets]);

  const onSetTooltipText = useCallback(
    (key): string => {
      switch (tabs[key].index) {
        case AssetTabIndexes.menus:
          return t("library:see-all-menus-in-your-library");
        case AssetTabIndexes.categories:
          return t("library:see-all-categories-in-your-library");
        case AssetTabIndexes.menuItems:
          return t("library:see-all-menu-items-in-your-library");
        case AssetTabIndexes.addons:
          return t("library:see-all-addons-in-your-library");
        default:
          return "";
      }
    },
    [tabs, t]
  );

  return (
    <>
      {Object.keys(tabs).map((key) => {
        const tabTooltipText = onSetTooltipText(key);
        return (
          <MmsTooltip key={tabs[key].index} title={tabTooltipText}>
            <div>
              <MmsTab
                data-testid={`library-asset-tab-${tabs[key].index}`}
                className={styles.tab}
                tabIndex={tabs[key].index}
                isSelected={selectedTab === tabs[key].index}
                onSelect={onSelect}
              >
                <JustifyContentBetweenLayout
                  className={styles.tabContent(tabs[key].included)}
                >
                  <div>{tabs[key].label}</div>
                  <Chevron
                    id={`library-asset-tab-${tabs[key].index}-icon`}
                    size={"small"}
                  />
                </JustifyContentBetweenLayout>
              </MmsTab>
            </div>
          </MmsTooltip>
        );
      })}
    </>
  );
};
