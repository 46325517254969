import { useReactiveVar } from "@apollo/client";
import { useDidUpdate } from "@hooks";
import { PopupNotificationTypesEnum } from "@model/helperTypes/Notification";
import { MmsNotification } from "@uiKit/Atoms/Notification/MmsNotification";
import { INFINITE_NOTIFICATION_DURATION } from "@uiKit/Atoms/Notification/MmsNotification/constants";
import { blockingErrorsVar } from "@utils/apolloReactiveVars";
import throttle from "lodash.throttle";
import { uniq } from "ramda";
import React from "react";
import toast from "react-hot-toast";

export const useErrorBlockingNotifications = () => {
  const blockingErrors = useReactiveVar(blockingErrorsVar);

  useDidUpdate(() => {
    if (!blockingErrors.length) return;

    blockingErrors.forEach((errorMessage) => {
      toast(
        (t) => (
          <MmsNotification
            message={errorMessage}
            type={PopupNotificationTypesEnum.error}
            showCloseButton={false}
            handleClose={() => toast.dismiss(t.id)}
            actionButtons={[
              {
                text: "Reload now",
                type: PopupNotificationTypesEnum.error,
                onClick: () => document.location.reload(),
              },
            ]}
          />
        ),
        {
          duration: INFINITE_NOTIFICATION_DURATION, // library doesn't support infinite notifications
        }
      );
    });

    blockingErrorsVar([]);
  }, [blockingErrors]);
};

export const addMultipleErrorBlockingNotifications = (
  errorMessages: string[]
) => blockingErrorsVar([...blockingErrorsVar(), ...errorMessages]);

export const addErrorBlockingNotification = (errorMessage: string) =>
  blockingErrorsVar([...blockingErrorsVar(), errorMessage]);

export const addUniqErrorBlockingNotification = throttle(
  (errorMessage: string) =>
    blockingErrorsVar(uniq([...blockingErrorsVar(), errorMessage])),
  INFINITE_NOTIFICATION_DURATION,
  { trailing: false }
);
