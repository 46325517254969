import React from "react";
import { styles } from "./styles";

export interface FlexColProps {
  className?: string;
  children: React.ReactNode;
}

export const FlexCol: React.FC<FlexColProps> = ({ children }) => {
  return <div className={styles}>{children}</div>;
};
