import React from "react";
import { getIsPartner } from "../../../utils/url";

export interface AccessControlProps {
  noAccessComponent?: React.ReactNode;
  children: React.ReactNode;
}

export const AccessControl: React.FC<AccessControlProps> = ({
  noAccessComponent,
  children,
}) => {
  return getIsPartner() ? <>{noAccessComponent}</> : <>{children}</>;
};
