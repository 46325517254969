import { css } from "@emotion/css";

export const styles = {
  commonBtn: css`
    min-width: 140px;
  `,
  btnGroup: css`
    width: 100%;
  `,
  separator: css`
    margin-top: 15px;
    margin-bottom: 15px;
  `,
  dummyTab: css`
    padding-top: 5px;
    padding-bottom: 5px;
  `,
};
