import { cx } from "@emotion/css";
import { useKeepFocus } from "@hooks";
import React from "react";
import { MmsBaseButton, MmsBaseButtonProps } from "../MmsBaseButton";
import { styles } from "./styles";

export interface MmsSvgButtonProps extends MmsBaseButtonProps {
  disabled?: boolean;
}

export const MmsSvgButton = React.forwardRef<
  HTMLButtonElement,
  MmsSvgButtonProps
>(function MmsSvgButton({ disabled, className, ...restProps }, ref) {
  const [focusedElemId] = useKeepFocus();

  return (
    <MmsBaseButton
      {...restProps}
      ref={ref}
      className={cx(
        "svgBtn",
        styles(restProps.id === focusedElemId),
        className
      )}
      disabled={disabled}
    />
  );
});
