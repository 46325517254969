import { cx } from "@emotion/css";
import { MmsSeparator } from "@uiKit/Atoms/Separator/MmsSeparator";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { MmsTable } from "@uiKit/Molecules/Table/MmsTable";
import { MmsTableCell } from "@uiKit/Molecules/Table/MmsTableCell";
import { MmsTableRow } from "@uiKit/Molecules/Table/MmsTableRow";
import React from "react";
import { styles } from "./HistoryModalDesktopLayout.styles";

interface HistoryModalDesktopLayoutProps {
  selectboxCell: React.ReactNode;
  descriptionCell?: React.ReactNode;
  publishedByCell?: React.ReactNode;
  publishedAtCell?: React.ReactNode;
  buttonCell: React.ReactNode;
}

export const HistoryModalDesktopLayout: React.FC<HistoryModalDesktopLayoutProps> = ({
  selectboxCell,
  buttonCell,
  descriptionCell,
  publishedByCell,
  publishedAtCell,
}) => (
  <VerticalAlignBetween className={styles.container}>
    <header className={styles.headerCell}>{selectboxCell}</header>
    <MmsSeparator />
    <VerticalAlignBetween className={styles.mainContentCell}>
      {descriptionCell && (
        <div className={styles.descriptionCell}>{descriptionCell}</div>
      )}
      <MmsTable
        tableBodyComponent={
          <>
            {publishedByCell && (
              <MmsTableRow>
                <MmsTableCell
                  className={styles.detailsCell}
                  align={"left"}
                  showUnderline={false}
                >
                  Published by
                </MmsTableCell>
                <MmsTableCell
                  className={cx(styles.detailsCell, styles.publishedByCell)}
                  align={"left"}
                  showUnderline={false}
                >
                  {publishedByCell}
                </MmsTableCell>
              </MmsTableRow>
            )}
            {publishedAtCell && (
              <MmsTableRow>
                <MmsTableCell
                  className={styles.detailsCell}
                  align={"left"}
                  showUnderline={false}
                >
                  Published at
                </MmsTableCell>
                <MmsTableCell
                  className={styles.detailsCell}
                  align={"left"}
                  showUnderline={false}
                >
                  {publishedAtCell}
                </MmsTableCell>
              </MmsTableRow>
            )}
          </>
        }
      />
    </VerticalAlignBetween>
    <MmsSeparator />
    <JustifyContentBetweenLayout className={styles.footerCell}>
      {buttonCell}
    </JustifyContentBetweenLayout>
  </VerticalAlignBetween>
);
