import { css } from "@emotion/css";
import { SizeType, DirectionType } from "../_common/types";
import { iconStyles } from "../_common/styles";

interface IOptions {
  size: SizeType;
  direction: DirectionType;
}

const getRotateAngleDependsOnDirection = (direction: DirectionType) => {
  switch (direction) {
    case "left":
      return 180;
    case "right":
      return 0;
    case "top":
      return 270;
    case "bottom":
    default:
      return 90;
  }
};

export const styles = {
  icon: (options: IOptions) => css`
    ${iconStyles(options.size)};

    transform: rotate(${getRotateAngleDependsOnDirection(options.direction)}deg);
  `,
};
