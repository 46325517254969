import { css } from "@emotion/css";

export const styles = {
  container: css`
    padding: 30px;
    width: 385px;
  `,
  errorMessage: css`
    font-size: 20px;
    font-weight: 300;

    b {
      font-weight: bold;
    }
  `,
  btnGroup: css`
    margin-top: 40px;
  `,
  btn: css`
    min-width: 155px;
  `,
};
