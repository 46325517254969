import React, { useCallback, useState } from "react";
import {
  ScheduleTasksForm,
  ScheduleTasksFormValues,
} from "../ScheduleTasksForm/ScheduleTasksForm";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { useTranslation } from "react-i18next";

interface ScheduleTasksFormModalProps {
  runAt: Date;
  onSave: (runAt: Date) => void;
  onSkip: VoidFunction;
}

export const ScheduleTasksFormModal: React.FC<ScheduleTasksFormModalProps> = ({
  runAt,
  onSave,
  onSkip,
}) => {
  const { t } = useTranslation(["common"]);
  const [isValidationErrors, setValidationErrors] = useState(true);
  const [formData, setFormData] = useState<ScheduleTasksFormValues>();

  const handleSave = useCallback(() => {
    if (!formData) return;
    onSave(formData.runAt);
    onSkip();
  }, [formData, onSave, onSkip]);

  return (
    <MmsConfirmationModal
      message={
        <ScheduleTasksForm
          runAt={runAt}
          onSubmit={setFormData}
          onError={setValidationErrors}
        />
      }
      title={t("common:update")}
      confirmBtnText={t("common:save")}
      declineBtnText={t("common:skip")}
      isConfirmBtnDisabled={isValidationErrors || !formData}
      onConfirmBtnClick={handleSave}
      onDeclineBtnClick={onSkip}
    />
  );
};
