import { ModelLocalAddonItem } from "@model/DAO/MenuAddonItem";
import { LocalAddonItemsMeta } from "@model/helperTypes/Addon";
import { generateUniqueKey } from "@utils/general";
import produce from "immer";
import { insert } from "ramda";
import { useCallback, useReducer } from "react";
import { checkIsAddonItemNew } from "../utils/AddonItem";

export type AddonItemsReducerCallbacksType = {
  create: (addonItem: ModelLocalAddonItem) => void;
  clone: (addonItemId: string) => void;
  remove: (addonItemId: string) => void;
  toggle: (addonItemId: string, autoenableTimestamp: number) => void;
  reorder: (reorderedLocalAddonItems: ModelLocalAddonItem[]) => void;
  changeTextFields: (
    addonItemId: string,
    newTitle: string,
    newPriceFields: any
  ) => void;
};

const CREATE = "CREATE";
const REMOVE = "REMOVE";
const CHANGE = "CHANGE";
const SET = "SET";

type CreateAddonItemActionType = {
  type: typeof CREATE;
  payload: {
    addonItem: ModelLocalAddonItem;
  };
};

type RemoveAddonItemActionType = {
  type: typeof REMOVE;
  payload: {
    addonItem: ModelLocalAddonItem;
  };
};

type ChangeAddonItemActionType = {
  type: typeof CHANGE;
  payload: {
    addonItem: ModelLocalAddonItem;
  };
};

type SetAddonItemsActionType = {
  type: typeof SET;
  payload: {
    state: LocalAddonItemsMeta;
  };
};

type AddonItemsReducerActionType =
  | CreateAddonItemActionType
  | RemoveAddonItemActionType
  | ChangeAddonItemActionType
  | SetAddonItemsActionType;

const addonItemsReducer = (
  state: LocalAddonItemsMeta,
  action: AddonItemsReducerActionType
): LocalAddonItemsMeta => {
  const {
    localAddonItems,
    newAddonItems,
    removedAddonItems,
    changedAddonItems,
  } = state;

  switch (action.type) {
    case CREATE: {
      const { addonItem } = action.payload;

      return {
        localAddonItems: [...localAddonItems, addonItem],
        newAddonItems: [...newAddonItems, addonItem],
        removedAddonItems,
        changedAddonItems,
      };
    }
    case REMOVE: {
      const { addonItem } = action.payload;
      const isNew = checkIsAddonItemNew(addonItem.id);

      return {
        localAddonItems: localAddonItems.filter(
          ({ id }) => id !== addonItem.id
        ),
        newAddonItems: isNew
          ? newAddonItems.filter(({ id }) => id !== addonItem.id)
          : newAddonItems,
        removedAddonItems: isNew
          ? removedAddonItems
          : [...removedAddonItems, addonItem],
        changedAddonItems: isNew
          ? changedAddonItems
          : changedAddonItems.filter(({ id }) => id !== addonItem.id),
      };
    }
    case CHANGE: {
      const { addonItem } = action.payload;
      const isNew = checkIsAddonItemNew(addonItem.id);
      const changedLocalAddonItemIndex = localAddonItems.findIndex(
        (localAddonItem) => localAddonItem.id === addonItem.id
      );

      return {
        // to not to break the order
        localAddonItems: produce(localAddonItems, (newLocalAddonItems) => {
          newLocalAddonItems.splice(changedLocalAddonItemIndex, 1, addonItem);
        }),
        // order is not important here
        newAddonItems: isNew
          ? [
              ...newAddonItems.filter(({ id }) => id !== addonItem.id),
              addonItem,
            ]
          : newAddonItems,
        removedAddonItems,
        // order is not important here
        changedAddonItems: isNew
          ? changedAddonItems
          : [
              ...changedAddonItems.filter(({ id }) => id !== addonItem.id),
              addonItem,
            ],
      };
    }
    case SET: {
      return action.payload.state;
    }
  }
};

const getReorderedState = (
  state: LocalAddonItemsMeta,
  reorderedLocalAddonItems: ModelLocalAddonItem[]
): LocalAddonItemsMeta => ({
  ...state,
  localAddonItems: reorderedLocalAddonItems,
  newAddonItems: state.newAddonItems.map((newAddonItem) => ({
    ...newAddonItem,
    orderBy: reorderedLocalAddonItems.find(
      (reorderedLocalAddonItem) =>
        reorderedLocalAddonItem.id === newAddonItem.id
    )!.orderBy,
  })),
  changedAddonItems: [
    // first, add new orderBy for currently changed addon items
    ...state.changedAddonItems.map((changedAddonItem) => ({
      ...changedAddonItem,
      orderBy: reorderedLocalAddonItems.find(
        (reorderedLocalAddonItem) =>
          reorderedLocalAddonItem.id === changedAddonItem.id
      )!.orderBy,
    })),
    // second, add new orderBy for the rest addons that wasn't changed yet and wasn't in newAddonItems array
    ...reorderedLocalAddonItems.filter((reorderedLocalAddon) =>
      [
        state.newAddonItems.every(
          (newAddonItem) => newAddonItem.id !== reorderedLocalAddon.id
        ),
        state.changedAddonItems.every(
          (changedAddonItem) => changedAddonItem.id !== reorderedLocalAddon.id
        ),
      ].every(Boolean)
    ),
  ],
});

export const useLocalAddonItems = (
  initialAddonItemsMeta: LocalAddonItemsMeta
): [LocalAddonItemsMeta, AddonItemsReducerCallbacksType] => {
  const [state, dispatch] = useReducer(
    addonItemsReducer,
    initialAddonItemsMeta
  );

  const create = useCallback((addonItem: ModelLocalAddonItem) => {
    dispatch({ type: CREATE, payload: { addonItem } });
  }, []);

  const clone = useCallback(
    (addonItemId: string) => {
      const addonItem = state.localAddonItems.find(
        ({ id }) => id === addonItemId
      ) as ModelLocalAddonItem;

      const originalLocalAddonItemIndex = state.localAddonItems.findIndex(
        ({ id }) => id === addonItemId
      );

      const clonedAddonItem: ModelLocalAddonItem = {
        ...addonItem,
        title: `Copy of ${addonItem.title}`,
        id: `cloned-${generateUniqueKey()}`,
      };

      const reorderedLocalAddonItems: ModelLocalAddonItem[] = insert(
        originalLocalAddonItemIndex + 1,
        clonedAddonItem,
        state.localAddonItems
      ).map((addonItem, index) => ({ ...addonItem, orderBy: index + 1 }));

      let newState = {
        ...state,
        newAddonItems: [...state.newAddonItems, clonedAddonItem],
      };
      newState = getReorderedState(newState, reorderedLocalAddonItems);

      dispatch({ type: SET, payload: { state: newState } });
    },
    [state]
  );

  const remove = useCallback(
    (addonItemId: string) => {
      const addonItem = state.localAddonItems.find(
        ({ id }) => id === addonItemId
      ) as ModelLocalAddonItem;

      dispatch({ type: REMOVE, payload: { addonItem } });
    },
    [state.localAddonItems]
  );

  const toggle = useCallback(
    (addonItemId: string, autoenableTimestamp: number) => {
      const addonItem = state.localAddonItems.find(
        ({ id }) => id === addonItemId
      ) as ModelLocalAddonItem;

      const changedAddonItem = {
        ...addonItem,
        autoenableTimestamp,
        active: !Boolean(addonItem?.active),
      };

      dispatch({ type: CHANGE, payload: { addonItem: changedAddonItem } });
    },
    [state.localAddonItems]
  );

  const reorder = useCallback(
    (reorderedLocalAddonItems: ModelLocalAddonItem[]) => {
      const newState = getReorderedState(state, reorderedLocalAddonItems);

      dispatch({ type: SET, payload: { state: newState } });
    },
    [state]
  );

  const changeTextFields = useCallback(
    (addonItemId: string, newTitle: string, newPriceFields: any) => {
      const addonItem = state.localAddonItems.find(
        ({ id }) => id === addonItemId
      ) as ModelLocalAddonItem;

      const changedAddonItem = {
        ...addonItem,
        ...newPriceFields,
        title: newTitle,
      };

      dispatch({ type: CHANGE, payload: { addonItem: changedAddonItem } });
    },
    [state.localAddonItems]
  );

  return [
    state,
    {
      create,
      clone,
      remove,
      toggle,
      reorder,
      changeTextFields,
    },
  ];
};
