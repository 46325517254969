import { injectGlobal } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

injectGlobal`
.MmsMuiInput {
  .MuiFilledInput-multiline {
    padding: 0;
  }

  .MuiInputLabel-root {
    margin: 0;

    color: ${themeColors.mediumGray} !important;
    font-family: ${themeVars.fontFamily};;
    font-size: 8px;
    font-weight: 700;
    text-transform: uppercase;
    transform: translate(7px, 14px) scale(1.3);
  }

  .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(7px, 5px);
  }

  .MuiInputBase-input {
    padding: 14px 7px 7px;

    font-family: ${themeVars.fontFamily};
    font-size: 16px;
    font-weight: 300;

    &, &:hover, &:focus, &:active, &:-webkit-autofill {
      border-radius: ${themeVars.borderRadius};

      background-color: ${themeColors.lightGrey};
    }
  }

  &.underlined {
    .MuiFilledInput-underline {
      &, &:hover {
        &:before {
          border-color: ${themeColors.borderLightGrey};
        }
        &:after {
          border-color: ${themeColors.lightGreen};
        }
      }
    }
  }

  &.outlined {
    .MuiFilledInput-underline {
      border-radius: 0;

      &:before, &:after {
        display: none;
      }
    }
  }
}
.MmsMuiInput-fullWidth {
  &, & .MuiTextField-root {
    width: 100%;
  }
}
`;
