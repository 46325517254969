import { useReactiveVar } from "@apollo/client";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { generateMenuAssetUrl } from "@pages/RestaurantMenuPage/utils/Menu";
import { MmsDummyRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink/dummy";
import { MmsTab, MmsTabProps } from "@uiKit/Molecules/Tabs/MmsTab";
import { iwaiterIdVar } from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import React from "react";
import {
  AddonTabPanel,
  AddonTabPanelProps,
} from "../AddonTabPanel/AddonTabPanel";

export interface AddonTabPanelSortableProps {
  mmsTabProps: Omit<MmsTabProps, "children">;
  addonTabPanelProps: AddonTabPanelProps;
}

export const AddonTabPanelSortable: React.FC<AddonTabPanelSortableProps> = ({
  mmsTabProps,
  addonTabPanelProps,
}) => {
  const iwaiterId = useReactiveVar(iwaiterIdVar);

  return (
    <MmsDummyRouterLink
      href={generateMenuAssetUrl(
        iwaiterId,
        addonTabPanelProps.addon.iwaiterId,
        MenuAssetEnum.addon
      )}
      disableLinkPreview={getIsPartner()}
    >
      <MmsTab {...mmsTabProps}>
        <AddonTabPanel {...addonTabPanelProps} />
      </MmsTab>
    </MmsDummyRouterLink>
  );
};
