import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  pageWrapper: css`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${themeColors.standardGreen};
  `,

  loader: css`
    color: ${themeColors.lightGrey};
  `,
};
