import { FormattedAssetEnum } from "@model/helperTypes/LibraryAsset";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";

export const generateLibraryProgressBarId = (
  destinationType: MenuAssetEnum,
  id?: string
) => {
  switch (destinationType) {
    case MenuAssetEnum.menu:
      return "save-menu-to-library";
    case MenuAssetEnum.category:
      return `save-category-to-library-${id}`;
    case MenuAssetEnum.menu_item:
      return `save-menu-item-to-library-${id}`;
    case MenuAssetEnum.addon:
      return `save-addon-to-library-${id}`;
    default:
      return "";
  }
};

export const generateImportFromLibraryLoaderId = (
  originType: FormattedAssetEnum
): string => `ImportFromLibraryLoader-${originType}`;
