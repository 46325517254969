import { ApolloError, useMutation } from "@apollo/client";
import { useErrorHandler, useGlobalLoading } from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { KnownErrorCodesEnum } from "@model/helperTypes/errors";
import { FormattedAssetEnum } from "@model/helperTypes/LibraryAsset";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { MENU_ITEM_ESSENTIAL_DATA } from "@pages/RestaurantMenuPage/graphql/fragments/MenuItem";
import { menuFiltersVar } from "@utils/apolloReactiveVars";
import { isNotEmpty } from "@utils/ramdaHelpers";
import produce from "immer";
import { head } from "ramda";
import { useCallback, useState } from "react";
import { CATEGORY_ESSENTIAL_DATA } from "../../graphql/fragments/Category";
import {
  AddToLibraryTypes,
  ADD_TO_LIBRARY,
  generateAddToLibraryVars,
} from "../../graphql/mutatioins/AddToLibrary";
import {
  DestroyLibraryAssetTypes,
  DESTROY_LIBRARY_ASSET,
} from "../../graphql/mutatioins/DestroyLibraryAsset";
import {
  generateImportLibraryAssetsVars,
  ImportLibraryAssetsTypes,
  IMPORT_LIBRARY_ASSETS,
} from "../../graphql/mutatioins/ImportLibraryAssets";
import {
  generateUpdateLibraryVars,
  UpdateLibraryTypes,
  UPDATE_LIBRARY,
} from "../../graphql/mutatioins/UpdateLibrary";
import {
  generateUpdateLibraryAssetVars,
  UpdateLibraryAssetTypes,
  UPDATE_LIBRARY_ASSET,
} from "../../graphql/mutatioins/UpdateLibraryAsset";

export const useAddAssetToLibrary = (
  assetType: MenuAssetEnum,
  assetId: string,
  onErrorCallback: (error: string, errorData: any) => void,
  title?: string | undefined
): [VoidFunction, boolean, string | undefined] => {
  const handleError = useCallback(
    ({ message, graphQLErrors }) => {
      const errorData: any = head(graphQLErrors);
      onErrorCallback(
        message,
        errorData?.extensions?.duplicate ||
          errorData?.extensions?.data?.duplicate
      );
    },
    [onErrorCallback]
  );

  const [, { onError }] = useErrorHandler(
    [KnownErrorCodesEnum.entityExist],
    handleError
  );

  const [
    mutation,
    { loading, data },
  ] = useMutation<AddToLibraryTypes.AddToLibraryMutation>(ADD_TO_LIBRARY, {
    variables: generateAddToLibraryVars(assetType, assetId, title),
    onError,
  });

  return [mutation, loading, data?.libraryAdd?.id];
};

export const useForceAddToLibrary = (
  assetType: MenuAssetEnum,
  assetId: string,
  title?: string | undefined
): [VoidFunction, boolean, string | null, VoidFunction] => {
  const [, { onError }] = useErrorHandler([]);

  const [forceAddedId, setForceAddedId] = useState<string | null>(null);

  const [
    mutation,
    { loading },
  ] = useMutation<UpdateLibraryTypes.UpdateLibraryMutation>(UPDATE_LIBRARY, {
    onError,
  });

  const mutationWithVars = useCallback(async () => {
    const result = await mutation({
      variables: generateUpdateLibraryVars(assetType, assetId, title),
    });

    if (!result?.data?.libraryTouch) return;
    setForceAddedId(result.data.libraryTouch.id);
  }, [assetId, assetType, mutation, title]);

  const onFinishMutation = useCallback(() => {
    setForceAddedId(null);
  }, []);

  return [mutationWithVars, loading, forceAddedId, onFinishMutation];
};

export const useDestroyLibraryAsset = (
  assetId: string
): [VoidFunction, boolean] => {
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<DestroyLibraryAssetTypes.DestroyLibraryAssetMutation>(
    DESTROY_LIBRARY_ASSET,
    {
      variables: { id: assetId },
      update(cache) {
        cache.modify({
          fields: {
            library: (existingLibraryData, { readField }) => {
              return Object.keys(existingLibraryData).reduce(
                (tempLibraryData, key) => {
                  return {
                    ...tempLibraryData,
                    [key]:
                      key === "__typename"
                        ? existingLibraryData[key]
                        : existingLibraryData[key].nodes.filter(
                            (node: any) =>
                              readField("id", node as any) !== assetId
                          ),
                  };
                },
                {} as { [key: string]: any }
              );
            },
          },
        });
      },
      onError,
    }
  );

  return [mutation, loading];
};

export const useUpdateLibraryAsset = (
  assetId: string
): [(title: string, isFavorite: boolean) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<UpdateLibraryAssetTypes.UpdateLibraryAssetMutation>(
    UPDATE_LIBRARY_ASSET,
    { onError }
  );

  const mutationWithVars = useCallback(
    (title: string, isFavorite: boolean) =>
      mutation({
        variables: generateUpdateLibraryAssetVars(assetId, title, isFavorite),
      }),
    [assetId, mutation]
  );

  return [mutationWithVars, loading];
};

export const useImportAssets = (): [
  (
    ids: string[],
    destinationId: string,
    destinationType: MenuAssetEnum,
    originType: FormattedAssetEnum,
    loaderId: string
  ) => void,
  boolean,
  ImportLibraryAssetsTypes.ImportLibraryAssetsMutation | null | undefined,
  ApolloError | undefined
] => {
  const [, { onError }] = useErrorHandler([]);
  const [, startGlobalLoading, stopGlobalLoading] = useGlobalLoading();

  const [
    mutation,
    { loading, data: libraryImport, error },
  ] = useMutation<ImportLibraryAssetsTypes.ImportLibraryAssetsMutation>(
    IMPORT_LIBRARY_ASSETS,
    { onError }
  );

  const mutationWithVars = useCallback(
    (
      ids: string[],
      destinationId: string,
      destinationType: MenuAssetEnum,
      originType: FormattedAssetEnum,
      loaderId: string
    ) => {
      startGlobalLoading(loaderId);

      mutation({
        variables: generateImportLibraryAssetsVars(
          ids,
          destinationId,
          destinationType, // where imported asset will be added
          originType // what is the type of imported asset
        ),
        update(cache, { data }) {
          if (!data?.libraryImport?.length) return;
          const { libraryImport } = data;

          switch (destinationType) {
            case MenuAssetEnum.category:
              if (originType === FormattedAssetEnum.menuItems) {
                const importedMenuItems = libraryImport as ModelMenuItem[];

                const categoryEssentialData = cache.readFragment<ModelFullCategory>(
                  {
                    id: `MenuCategory:${destinationId}`,
                    fragment: CATEGORY_ESSENTIAL_DATA,
                    fragmentName: "CategoryEssentialData",
                  }
                );

                cache.modify({
                  id: `MenuCategory:${destinationId}`,
                  fields: {
                    items(menuItems) {
                      const importedMenuItemRefList = importedMenuItems.map(
                        (importedMenuItem) => {
                          return cache.writeFragment({
                            data: importedMenuItem,
                            fragment: MENU_ITEM_ESSENTIAL_DATA,
                            fragmentName: "MenuItemEssentialData",
                          });
                        }
                      );

                      return produce(menuItems, (newMenuItems: any) => {
                        newMenuItems.push(...importedMenuItemRefList);
                      });
                    },
                  },
                });

                cache.writeFragment<ModelFullCategory>({
                  id: `MenuCategory:${destinationId}`,
                  fragment: CATEGORY_ESSENTIAL_DATA,
                  fragmentName: "CategoryEssentialData",
                  data: produce(categoryEssentialData!, (newCategoryData) => {
                    const sipliestImportedMenuItems = importedMenuItems.map(
                      ({ id, __typename }) => ({
                        id,
                        categoryId: destinationId,
                        __typename,
                      })
                    );
                    newCategoryData.items.push(...sipliestImportedMenuItems);
                  }),
                });
              } else {
                const categoryData = cache.readFragment<ModelFullCategory>({
                  id: `MenuCategory:${destinationId}`,
                  fragment: CATEGORY_ESSENTIAL_DATA,
                  fragmentName: "CategoryEssentialData",
                });

                cache.modify({
                  id: cache.identify(categoryData as any),
                  fields: {
                    addons(existingAddons = []) {
                      return [...existingAddons, ...libraryImport];
                    },
                  },
                });
              }
              return;
            case MenuAssetEnum.menu_item:
              const menuItemData = cache.readFragment<ModelMenuItem>({
                id: `MenuItem:${destinationId}`,
                fragment: MENU_ITEM_ESSENTIAL_DATA,
                fragmentName: "MenuItemEssentialData",
              });

              cache.modify({
                id: cache.identify(menuItemData as any),
                fields: {
                  addons(existingAddons = []) {
                    return [...existingAddons, ...libraryImport];
                  },
                },
              });
              return;
            case MenuAssetEnum.menu:
              const menuFilters = menuFiltersVar();

              if (isNotEmpty(menuFilters)) {
                menuFiltersVar({});
              } else {
                const importedCategories = libraryImport as ModelFullCategory[];

                cache.modify({
                  id: cache.identify({ __typename: "Query" }),
                  fields: {
                    menu(prevMenu) {
                      const importedCategoryRefList = importedCategories.map(
                        (importedCategory) => {
                          return cache.writeFragment({
                            data: importedCategory,
                            fragment: CATEGORY_ESSENTIAL_DATA,
                            fragmentName: "CategoryEssentialData",
                          });
                        }
                      );

                      return produce(prevMenu, (newPrevMenu: any) => {
                        newPrevMenu.categories.push(...importedCategoryRefList);
                      });
                    },
                  },
                });
              }

              return;
            default:
              break;
          }
        },
      }).finally(() => {
        stopGlobalLoading(loaderId);
      });
    },
    [mutation, startGlobalLoading, stopGlobalLoading]
  );

  return [mutationWithVars, loading, libraryImport, error];
};
