import handleImg from "@assets/png/drag_indicator.png";
import { useDragAndDropClient } from "@hooks";
import { DraggableEnum } from "@model/helperTypes/dragAndDrop";
import { CategorySettingsLevel } from "@pages/RestaurantMenuPage/components/CategorySettings/CategorySettings";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React, { useCallback, useEffect } from "react";
import { styles } from "./styles";

export interface MmsTabProps {
  className?: string;
  tabIndex: CategorySettingsLevel;
  isSelected: boolean;
  disabled?: boolean;
  isDraggable?: boolean;
  isDragAndDropDisabled?: boolean;
  draggableType?: string;
  onSelect: (tabIndex: number) => void;
  children: React.ReactNode;
}

export const MmsTab: React.FC<MmsTabProps> = ({
  className,
  tabIndex,
  isSelected,
  disabled = false,
  isDraggable = false,
  isDragAndDropDisabled,
  draggableType = DraggableEnum.dummy,
  onSelect,
  children,
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      disabled || onSelect(tabIndex);
    },
    [disabled, onSelect, tabIndex]
  );

  const [
    { draggableRef, handleRef, previewRef },
    { isDragging },
    setIsDragAndDropEnabled,
  ] = useDragAndDropClient(draggableType);

  useEffect(() => {
    setIsDragAndDropEnabled(!isDragAndDropDisabled);
  }, [isDragAndDropDisabled, setIsDragAndDropEnabled]);

  return (
    <div ref={previewRef} className={className}>
      <div
        className={styles.container(isSelected, disabled, isDragging)}
        ref={draggableRef}
        onClickCapture={handleClick}
      >
        {isDraggable && (
          <MmsTooltip title={"Hold down to drag & drop"}>
            <div
              className={styles.handle(!!isDragAndDropDisabled)}
              ref={handleRef}
            >
              <img src={handleImg} alt="" />
            </div>
          </MmsTooltip>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};
