import { cx } from "@emotion/css";
import React, { useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";
import { styles } from "../_common/styles";

export interface MmsRouterLinkProps {
  id?: LinkProps["id"];
  href: LinkProps["to"];
  rel?: LinkProps["rel"];
  target?: LinkProps["target"];
  onClick?: LinkProps["onClick"];
  className?: LinkProps["className"];
  search?: string;
  shouldOpenInNewTab?: boolean;
  children: LinkProps["children"];
}

export const MmsRouterLink: React.FC<MmsRouterLinkProps> = ({
  href,
  className,
  search = "",
  shouldOpenInNewTab,
  onClick,
  children,
  ...restProps
}) => {
  const handleClick = useCallback(
    (e) => {
      onClick?.(e);

      if (!shouldOpenInNewTab) return;
      e.preventDefault();
      window.open(`${href}${search}`);
    },
    [href, onClick, search, shouldOpenInNewTab]
  );

  return (
    <Link
      to={{ pathname: `${href}`, search }}
      className={cx(styles.link, className)}
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </Link>
  );
};
