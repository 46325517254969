import { useEffect, useRef } from "react";

export function useInterval(callback: VoidFunction, delay: number) {
  const savedCallback = useRef<VoidFunction | null>();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay === null) return;

    const id = setInterval(tick, delay);
    return () => {
      clearInterval(id);
    };
  }, [callback, delay]);
}
