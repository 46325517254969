import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { MmsSeparator } from "@uiKit/Atoms/Separator/MmsSeparator";
import { MmsSkeleton } from "@uiKit/Atoms/Skeleton/MmsSkeleton";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import React from "react";
import { styles } from "./HistoryModalDesktopSkeletonLayout.styles";
import { useTranslation } from "react-i18next";

interface HistoryModalDesktopSkeletonLayoutProps {
  onCancel: VoidFunction;
}

export const HistoryModalDesktopSkeletonLayout: React.FC<HistoryModalDesktopSkeletonLayoutProps> = ({
  onCancel,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <VerticalAlignBetween className={styles.container}>
      <MmsSkeleton className={styles.headerSkeleton} />
      <MmsSeparator />
      <VerticalAlignBetween className={styles.mainContentCell}>
        <MmsSkeleton className={styles.descriptionSkeleton} />
        <MmsSkeleton className={styles.detailsSkeleton} />
      </VerticalAlignBetween>
      <MmsSeparator />
      <JustifyContentEndLayout className={styles.footerCell}>
        <MmsSecondaryButton id={"close-history-settings"} onClick={onCancel}>
          {t("common:cancel")}
        </MmsSecondaryButton>
      </JustifyContentEndLayout>
    </VerticalAlignBetween>
  );
};
