import { ModelNotification } from "@model/DAO/Notifications";
import { PopupNotificationTypesEnum } from "@model/helperTypes/Notification";
import { MmsNotification } from "@uiKit/Atoms/Notification/MmsNotification";
import {
  INFINITE_NOTIFICATION_DURATION,
  NOTIFICATION_DURATION,
} from "@uiKit/Atoms/Notification/MmsNotification/constants";
import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { NotificationActions } from "__generated__/globalTypes";

export const useHandleIncomingNotifications = (
  notifications?: ModelNotification[],
  lastPublishedAt?: string
) => {
  const lastPublishedRef = useRef<string | null>(null);

  useEffect(() => {
    if (!notifications?.length) return;
    if (lastPublishedRef.current === lastPublishedAt) return;

    lastPublishedRef.current = lastPublishedAt!;
    notifications.forEach(({ message, action }) => {
      const isBlockAction = action === NotificationActions.BLOCK;
      toast(
        (t) => (
          <MmsNotification
            id={t.id}
            message={message}
            type={PopupNotificationTypesEnum.info}
            showCloseButton={isBlockAction}
            handleClose={() => toast.dismiss(t.id)}
            actionButtons={
              isBlockAction
                ? [
                    {
                      text: "Reload now",
                      type: PopupNotificationTypesEnum.success,
                      onClick: () => document.location.reload(),
                    },
                  ]
                : null
            }
          />
        ),
        {
          duration: isBlockAction
            ? INFINITE_NOTIFICATION_DURATION
            : NOTIFICATION_DURATION, // library doesn't support infinite notifications
        }
      );
    });
  }, [notifications, lastPublishedAt]);
};
