import { useReactiveVar } from "@apollo/client";
import { useDidUpdate } from "@hooks";
import {
  allCategoriesMenuItemsVar,
  menuCategoriesVar,
  selectedEntitiesVar,
} from "@utils/apolloReactiveVars";
import { useMemo } from "react";
import { extractCategoriesItemsIdsFromCategoryList } from "../utils/Category";
import { transformToSelectedMenuItemsShape } from "../utils/selectedEntities";

// update selected entities list if some category or item was deleted
export const useUpdatedSelectedEntities = () => {
  const categories = useReactiveVar(menuCategoriesVar);
  const categoriesItemsIds = extractCategoriesItemsIdsFromCategoryList(
    categories
  );
  const allCategoriesMenuItems = useReactiveVar(allCategoriesMenuItemsVar);

  const allCategoriesIds = useMemo(
    () => categoriesItemsIds.map(({ id }) => id),
    [categoriesItemsIds]
  );

  const allMenuItemsIds = useMemo(
    () =>
      transformToSelectedMenuItemsShape(allCategoriesMenuItems).map(
        ({ id }) => id
      ),
    [allCategoriesMenuItems]
  );

  useDidUpdate(() => {
    const { selectedCategories, selectedMenuItems } = selectedEntitiesVar();

    selectedEntitiesVar({
      selectedCategories: allCategoriesIds.length
        ? selectedCategories.filter(({ id }) => allCategoriesIds.includes(id))
        : selectedCategories,
      selectedMenuItems: allMenuItemsIds.length
        ? selectedMenuItems
            .filter(({ categoryId }) => allCategoriesIds.includes(categoryId))
            .filter(({ id }) => allMenuItemsIds.includes(id))
        : selectedMenuItems,
    });
  }, [allCategoriesIds, allMenuItemsIds]);
};
