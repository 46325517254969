import { useReactiveVar } from "@apollo/client";
import { cx } from "@emotion/css";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { PriceLevelPrices } from "@model/helperTypes/PriceLevelPrices";
import { AddonRowLayout } from "@pages/RestaurantMenuPage/layouts/AddonRowLayout/AddonRowLayout";
import { getActiveEntities } from "@pages/RestaurantMenuPage/utils/GenericEntity";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { spiceLevelVar } from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import React, { useMemo } from "react";
import { separatePriceLevelAddonFromRest } from "../../../utils/Addon";
import { BodyAddons } from "./BodyAddons/BodyAddons";
import { HeadingAddon } from "./HeadingAddon/HeadingAddon";
import { styles } from "./MenuItemAddonsSection.styles";
import { useTranslation } from "react-i18next";
import { SettingsTabsEnum } from "../../MenuItemSettings/MenuItemSettings";

interface MenuItemAddonSectionProps {
  addons: ModelMenuAddon[];
  currency: string;
  onShowAddons: VoidFunction;
  collapsed: boolean;
  hasOwnAddons: boolean;
  hasServiceTypeSpecificPrices: boolean;
  onShowCommonSettings: (initialTab?: SettingsTabsEnum) => void;
  price: string;
  topLevelPriceLevelPrices: PriceLevelPrices[];
  menuItemId: string;
  isNew: boolean;
  onChangePrices: (values: any) => void;
  spiceType: number;
  menuFlags: string[];
}

export const MenuItemAddonsSection: React.FC<MenuItemAddonSectionProps> = ({
  addons,
  onShowAddons,
  collapsed,
  menuItemId,
  isNew,
  onChangePrices,
  ...restProps
}) => {
  const { t } = useTranslation(["addons", "common"]);
  const activeAddons = useMemo(() => getActiveEntities(addons), [addons]);
  const spiceTypes = useReactiveVar(spiceLevelVar);

  const [restAddons, priceLevelAddon] = useMemo(
    () => separatePriceLevelAddonFromRest(activeAddons),
    [activeAddons]
  );

  return (
    <div className={styles.container}>
      <HeadingAddon
        priceLevelAddon={priceLevelAddon}
        isNew={isNew}
        menuItemId={menuItemId}
        onChangePrices={onChangePrices}
        {...restProps}
      />
      {!!restProps.spiceType && (
        <AddonRowLayout
          data-testid="menu-item-spice-type"
          indicatorColor={"yellow"}
          leftColumn={<span className={styles.title(false)}>Spice Level</span>}
          rightColumn={
            <span className={cx(styles.rightTitle, styles.title(false))}>
              {spiceTypes[restProps.spiceType]}
            </span>
          }
        />
      )}
      {!collapsed && !!restProps.menuFlags && (
        <AddonRowLayout
          data-testid="menu-item-menu-flag"
          indicatorColor={"yellow"}
          leftColumn={<span className={styles.title(false)}>Menu Flags</span>}
          rightColumn={
            <span className={cx(styles.rightTitle, styles.title(false))}>
              {restProps.menuFlags?.join(", ")}
            </span>
          }
        />
      )}

      {!!restAddons.length &&
        (collapsed ? (
          <MmsTooltip title={t("addons:edit-addons")}>
            <MmsBaseButton
              id={`menu-item-addon--more-${menuItemId}`}
              className={styles.extraAddons}
              onClick={isNew ? noop : onShowAddons}
            >
              +{restAddons.length} ${t("common:more")}
            </MmsBaseButton>
          </MmsTooltip>
        ) : (
          <BodyAddons
            restAddons={restAddons}
            hasOwnAddons={restProps.hasOwnAddons}
          />
        ))}
    </div>
  );
};
