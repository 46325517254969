import { cx } from "@emotion/css";
import { useFormErrorsProtection, useTogglePopper } from "@hooks";
import { LogAuditableEnum } from "@model/helperTypes/LogFilters";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { useImportAddonsFromLibrary } from "@pages/RestaurantMenuPage/hooks/useImportAddonsFromLibrary";
import { useSaveAddonsToLibrary } from "@pages/RestaurantMenuPage/hooks/useSaveAddonsToLibrary";
import { CloudPopper } from "@pages/RestaurantMenuPage/modals/CloudPopper/CloudPopper";
import {
  checkIsAddonNew,
  checkIsAddonTempNew,
} from "@pages/RestaurantMenuPage/utils/Addon";
import { generateLibraryProgressBarId } from "@pages/RestaurantMenuPage/utils/Library";
import { generateLogsAssetParams } from "@pages/RestaurantMenuPage/utils/Logs";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink";
import { Clock, Cloud } from "@uiKit/Atoms/SVGIcons";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { MmsProgressBar } from "@uiKit/Molecules/ProgressBar/MmsProgressBar";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { noop } from "@utils/noop";
import React, { useCallback, useMemo } from "react";
import { styles } from "./NewAddonTabHeaderControls.styles";
import { useTranslation } from "react-i18next";

interface NewAddonTabHeaderControlsProps {
  restaurantId: string;
  addonId: string;
  isAddonsChanged: boolean;
  isUpdateProcessing: boolean;
  isReadyToSaveToLibrary: boolean;
  updateAddonsAndProceed: VoidFunction;
  setIsReadyToSaveToLibrary: (isReady: boolean) => void;
}

export const NewAddonTabHeaderControls: React.FC<NewAddonTabHeaderControlsProps> = ({
  restaurantId,
  addonId,
  isAddonsChanged,
  isUpdateProcessing,
  isReadyToSaveToLibrary,
  updateAddonsAndProceed,
  setIsReadyToSaveToLibrary,
}) => {
  const { t } = useTranslation(["common", "category", "addons", "library"]);
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const isAddonNew = useMemo(
    () =>
      [checkIsAddonNew(addonId), checkIsAddonTempNew(addonId)].some(Boolean),
    [addonId]
  );

  const libraryProgressbarId = useMemo(
    () => generateLibraryProgressBarId(MenuAssetEnum.addon, addonId),
    [addonId]
  );

  const [
    libraryAnchorEl,
    isLibraryPopperOpen,
    openLibraryPopper,
    closeLibraryPopper,
  ] = useTogglePopper(false);

  const handleOpenLibraryPopper = useCallback(
    ({ target }) => {
      openLibraryPopper(target);
    },
    [openLibraryPopper]
  );

  const [
    isUnsavedChangesWarningModalOpen,
    {
      confirmImportFromLibrary,
      handleImportFromLibraryClick,
      closeUnsavedChangesWarningModal,
    },
  ] = useImportAddonsFromLibrary(
    isUpdateProcessing,
    isAddonsChanged,
    updateAddonsAndProceed
  );

  const [{ handleSaveToLibrary }] = useSaveAddonsToLibrary(
    addonId,
    libraryProgressbarId,
    isAddonNew,
    isReadyToSaveToLibrary,
    isUpdateProcessing,
    setIsReadyToSaveToLibrary,
    updateAddonsAndProceed
  );

  return (
    <>
      <AccessControl>
        {!isAddonNew && (
          <MmsTooltip title={t("common:view-asset-logs")}>
            <MmsRouterLink
              id={`addon-logs-controls-${addonId}`}
              href={`/restaurant/${restaurantId}/menu/logs`}
              search={generateLogsAssetParams({
                assetId: addonId,
                assetType: LogAuditableEnum.MenuAddon,
              })}
              className={cx(styles.logsControl, styles.baseControl)}
              shouldOpenInNewTab
            >
              <Clock id={`addon-logs-controls-${addonId}-icon`} size="small" />
            </MmsRouterLink>
          </MmsTooltip>
        )}
        <MmsTooltip
          title={t("category:save-import-from-my-library")}
          disable={isLibraryPopperOpen}
        >
          <div>
            <MmsSvgButton
              onClick={
                isUpdateProcessing
                  ? noop
                  : withActiveFormsErrorsProtection(handleOpenLibraryPopper)
              }
              id={`cloud-addon-controls-${addonId}`}
              className={styles.baseControl}
              disabled={isUpdateProcessing}
            >
              <Cloud id={`cloud-addon-controls-${addonId}-icon`} size="small" />
              <MmsProgressBar
                id={libraryProgressbarId}
                className={styles.progressBar}
              />
            </MmsSvgButton>
          </div>
        </MmsTooltip>
        {isLibraryPopperOpen && (
          <CloudPopper
            uniqId={addonId}
            className={styles.libraryPopper}
            anchorEl={libraryAnchorEl}
            onClose={closeLibraryPopper}
            onSaveToLibraryClick={handleSaveToLibrary}
            onImportFromLibraryClick={handleImportFromLibraryClick}
            saveToLibraryTooltipTitle={t(
              "library:save-this-entire-addon-to-your-library"
            )}
            importFromLibraryTooltipTitle={t(
              "category:import-addon-from-your-library-to-this-menu-item"
            )}
          />
        )}
      </AccessControl>

      {isUnsavedChangesWarningModalOpen && (
        <MmsConfirmationModal
          message={t("addons:you-have-unsaved-changes")}
          confirmBtnText={t("addons:save-and-proceed")}
          declineBtnText={t("common:cancel")}
          onConfirmBtnClick={confirmImportFromLibrary}
          onDeclineBtnClick={closeUnsavedChangesWarningModal}
          isDataProcessing={isUpdateProcessing}
        />
      )}
    </>
  );
};
