import { ReactComponent as SVGCheckCircle } from "@assets/SVG/check-circle.svg";
import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface ICheckCircleProps extends IIconBase {}

export const CheckCircle: React.FC<ICheckCircleProps> = ({
  id,
  size = "medium",
  className,
}) => {
  const ref = useSvgFilter(id);
  return (
    <SVGCheckCircle
      ref={ref}
      className={cx(styles.icon(size), className)}
      fill={themeColors.lightGreen}
    />
  );
};
