import React from "react";
import { cx } from "@emotion/css";
import { ReactComponent as SVGCamera } from "@assets/SVG/camera.svg";
import { styles } from "./styles";
import { IIconBase } from "../_common/types";

interface ICameraProps extends IIconBase {}

export const Camera: React.FC<ICameraProps> = ({
  size = "medium",
  className,
}) => <SVGCamera className={cx(styles.icon(size), className)} />;
