import { useReactiveVar } from "@apollo/client";
import { ActiveFormType } from "@model/helperTypes/forms";
import { activeFormsVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useActiveForms = (): [
  ActiveFormType[],
  (activeForm: ActiveFormType) => void,
  (formId: ActiveFormType["formId"]) => void
] => {
  const addFormToList = useCallback(({ formId, formAPI }: ActiveFormType) => {
    const activeForms = activeFormsVar();
    activeFormsVar([...activeForms, { formId, formAPI }]);
  }, []);

  const removeFormFromList = useCallback((formId: ActiveFormType["formId"]) => {
    const activeForms = activeFormsVar();
    activeFormsVar(activeForms.filter((form) => form.formId !== formId));
  }, []);

  return [useReactiveVar(activeFormsVar), addFormToList, removeFormFromList];
};
