import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    gap: 8px;

    padding: 15px 25px;
  `,
  listItem: css`
    gap: 15px;
    align-items: center;
  `,
  btnText: css`
    flex: 1;
    display: flex;

    font-family: ${themeVars.fontFamily};
    font-size: 16px;
    font-weight: 300;
    color: ${themeColors.black};

    cursor: pointer;
  `,
  popper: css`
    margin-top: 15px;

    z-index: 10;
  `,
};
