import { gql } from "@apollo/client";

export const RESTORE_MENU = gql`
  mutation RestoreMenuMutation($input: MenuRestoreData!) {
    mmsRestoreRun(input: $input) {
      id
    }
  }
`;

export * as RestoreMenuMutationTypes from "./__generated__/RestoreMenuMutation";
