import { injectGlobal } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

injectGlobal`
.MmsDateTimePicker {
  .MuiFormControl-root {
    min-width: 200px;
  }
  .MuiInputLabel-root {
    margin: 0;

    color: ${themeColors.mediumGray} !important;
    font-family: ${themeVars.fontFamily};
    font-size: 8px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    transform: translate(7px, 14px) scale(1.3);

    z-index: 1;
  }

  .MuiInputLabel-root.MuiInputLabel-shrink {
    transform: translate(7px, 5px);
  }

  .MuiInputBase-input {
    padding: 14px 7px 7px;

    font-family: ${themeVars.fontFamily};
    font-size: 16px;
    font-weight: 300;

    &, &:hover, &:focus, &:active, &:-webkit-autofill {
      border-radius: ${themeVars.borderRadius};

      background-color: ${themeColors.lightGrey};
    }
  }

  &.underlined {
    .MuiInput-underline {
      margin: 0;

      &, &:hover {
        &:before {
          border-color: ${themeColors.borderLightGrey};
          border-bottom-width: 1px;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}
.MmsDateTimePicker-fullWidth {
  &, & .MuiTextField-root {
    width: 100%;
  }
}
`;
