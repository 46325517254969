export enum KnownErrorCodesEnum {
  publishValidation = "publishValidation",
  menuSendFail = "menuSendFail",
  stateInvalid = "stateInvalid",
  librariesRequired = "librariesRequired",
  entityExist = "entityExist",
  recordNotFound = "recordNotFound",
  imageIsUsed = "imageIsUsed",
  iwaiterError = "iwaiterError",
  unauthorized = "unauthorized",
  unauthenticated = "unauthenticated",
}

export interface ValidationErrorItem {
  [entityId: string]: string[];
}

export interface ParsePublishValidationErrorsResult {
  menu_category: ValidationErrorItem | undefined;
  menu_category_item: ValidationErrorItem | undefined; // errors of items that this category owns
  menu_item: ValidationErrorItem | undefined;
}
