import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;
    margin-top: 10px;

    background-color: ${themeColors.lightGrey};

    color: ${themeColors.mediumGray};
    font-size: 12px;
    font-style: italic;
  `,
};
