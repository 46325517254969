import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 32px;
    padding: 0 20px;
  `,
  commonIconStyles: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;

    cursor: pointer;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      border-radius: 8px;

      background-color: ${themeColors.black};

      opacity: 0.2;
      z-index: -1;
    }
  `,
  selectAllBtn: css`
    margin-right: 15px;
  `,
};
