import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  btnGroup: css`
    align-items: center;

    width: 100%;
  `,
  commonBtn: css`
    min-width: 140px;
  `,
  contentSeparator: css`
    color: ${themeColors.cream};
  `,
  addNewAddonItemBtn: css`
    max-width: 250px;
  `,
  addNewAddonTab: css`
    margin-top: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  `,
};
