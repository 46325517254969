import { cx } from "@emotion/css";
import { Skeleton, SkeletonProps } from "@material-ui/lab";
import React from "react";
import { styles } from "./styles";

export interface MmsSkeletonProps {
  className?: SkeletonProps["className"];
}

export const MmsSkeleton: React.FC<MmsSkeletonProps> = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <Skeleton variant="rect" width={"100%"} height={"100%"} />
    </div>
  );
};
