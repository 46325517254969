import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";
import { MENU_ITEM_ESSENTIAL_DATA } from "../../fragments/MenuItem";

export const CLONE_MENU_ITEM = gql`
  mutation CloneMenuItemMutation($input: CloneData!) {
    mmsClone(input: $input) {
      ... on MenuItem {
        ...MenuItemEssentialData
      }
    }
  }
  ${MENU_ITEM_ESSENTIAL_DATA}
`;

export const generateCloneMenuItemVars = (menuItemId: string) => ({
  input: {
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
  },
});

export * as CloneMenuItemTypes from "./__generated__/CloneMenuItemMutation";
