import { cx } from "@emotion/css";
import React from "react";
import { MmsBaseButton, MmsBaseButtonProps } from "../MmsBaseButton";
import { styles } from "./styles";

export interface MmsIconTitleButtonProps extends MmsBaseButtonProps {
  disabled?: boolean;
  btnTitle: string;
  containerClassName?: string;
  className?: string;
  btnIcon: React.ReactNode;
}

export const MmsIconTitleButton: React.FC<MmsIconTitleButtonProps> = ({
  disabled,
  btnTitle,
  containerClassName,
  className,
  btnIcon,
  ...restProps
}) => {
  return (
    <div className={containerClassName}>
      <MmsBaseButton
        {...restProps}
        className={cx(styles.button(!!disabled), className)}
        disabled={!!disabled}
      >
        <span className={styles.icon}>{btnIcon}</span>
        <span className={styles.title}>{btnTitle}</span>
      </MmsBaseButton>
    </div>
  );
};
