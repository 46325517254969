import { useReactiveVar } from "@apollo/client";
import { ALL_FILTER_VALUE, FILTER_DISABLING_VALUE } from "@constants";
import { useFormErrorsProtection, useToggle } from "@hooks";
import { YesNoFilterEnum } from "@model/helperTypes/MenuFilters";
import { themeColors } from "@theme/colors";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import {
  ClearFilters,
  Event,
  Filters,
  ReapplyFilters,
} from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { menuFiltersVar } from "@utils/apolloReactiveVars";
import { curry, equals, isEmpty, omit } from "ramda";
import React, { useCallback, useMemo } from "react";
import { HalalToggle } from "./HalalToggle/HalalToggle";
import { UsesPopularItemsToggle } from "./UsesPopularItemsToggle/UsesPopularItemsToggle";
import { styles } from "./MenuFiltersPanel.styles";
import { MenuTextFilterForm } from "./MenuTextFilter/MenuTextFilter";
import { useTranslation } from "react-i18next";

const yesNoFilterData = [
  { title: "All", value: ALL_FILTER_VALUE },
  ...Object.keys(YesNoFilterEnum).map((key) => {
    return { title: key, value: key };
  }),
];

interface MenuFiltersPanelProps {
  halalFriendly: boolean;
  toggleHalalFriendly: (halalFriendly: boolean) => void;
  usesPopularItems: boolean;
  toggleUsesPopularItems: (usesPopularItems: boolean) => void;
  openScheduleTaskModal: VoidFunction;
}

export const MenuFiltersPanel: React.FC<MenuFiltersPanelProps> = ({
  halalFriendly,
  toggleHalalFriendly,
  usesPopularItems,
  toggleUsesPopularItems,
  openScheduleTaskModal,
}) => {
  const { t } = useTranslation(["filter"]);

  const menuFilters = useReactiveVar(menuFiltersVar);

  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [isFiltersPanelOpen, toggleFiltersPanel] = useToggle(false);

  const appliedFiltersAmount = useMemo(
    () => Object.values(menuFilters).length,
    [menuFilters]
  );

  const handleChangeFilter = useCallback(
    (
      filterType:
        | "discountPresent"
        | "activeFromPresent"
        | "activenessPresent"
        | "titleCont",
      value: string
    ) => {
      if (equals(value, menuFilters[filterType])) return;

      let filtersObj = {};

      if (value === FILTER_DISABLING_VALUE[filterType]) {
        filtersObj = omit([filterType], menuFilters);
      } else {
        filtersObj = { ...menuFilters, [filterType]: value };
      }

      menuFiltersVar(filtersObj);
    },
    [menuFilters]
  );

  const handleReapplyFilters = useCallback(() => {
    if (isEmpty(menuFilters)) return;
    menuFiltersVar({ ...menuFilters });
  }, [menuFilters]);

  const handleClear = useCallback(() => {
    if (isEmpty(menuFilters)) return;
    menuFiltersVar({});
  }, [menuFilters]);

  return (
    <JustifyContentEndLayout className={styles.container}>
      {isFiltersPanelOpen ? (
        <>
          <MmsTooltip title={t("filter:clear-filters")}>
            <div>
              <MmsSvgButton
                onClick={withActiveFormsErrorsProtection(handleClear)}
                id={`clear-menu-filters`}
              >
                <ClearFilters color={themeColors.white} />
              </MmsSvgButton>
            </div>
          </MmsTooltip>
          <MmsTooltip title={t("filter:re-apply-filters")}>
            <div>
              <MmsSvgButton
                onClick={withActiveFormsErrorsProtection(handleReapplyFilters)}
                id={`reapply-menu-filters`}
              >
                <ReapplyFilters color={themeColors.white} />
              </MmsSvgButton>
            </div>
          </MmsTooltip>
          <JustifyContentBetweenLayout className={styles.filtersRow}>
            <MmsMuiSelectbox
              id={"menu-discount-filter"}
              name={"discountPresent"}
              label={t("filter:discount")}
              value={menuFilters.discountPresent || ALL_FILTER_VALUE}
              onChange={curry(handleChangeFilter)("discountPresent")}
              data={yesNoFilterData}
            />
            <MmsMuiSelectbox
              id={"menu-time-limit-filter"}
              name={"activeFromPresent"}
              label={t("filter:time-limit")}
              value={menuFilters.activeFromPresent || ALL_FILTER_VALUE}
              onChange={curry(handleChangeFilter)("activeFromPresent")}
              data={yesNoFilterData}
            />
            <MmsMuiSelectbox
              id={"menu-activity-filter"}
              name={"activenessPresent"}
              label={t("filter:active")}
              value={menuFilters.activenessPresent || ALL_FILTER_VALUE}
              onChange={curry(handleChangeFilter)("activenessPresent")}
              data={yesNoFilterData}
            />
            <MenuTextFilterForm
              key={menuFilters.titleCont}
              name={"titleCont"}
              label={t("filter:enter-asset-title")}
              defaultValue={menuFilters.titleCont}
              onSubmit={curry(handleChangeFilter)("titleCont")}
            />
          </JustifyContentBetweenLayout>
        </>
      ) : (
        <>
          <HalalToggle
            halalFriendly={halalFriendly}
            toggleHalalFriendly={toggleHalalFriendly}
          />
          <UsesPopularItemsToggle
            usesPopularItems={usesPopularItems}
            toggleUsesPopularItems={toggleUsesPopularItems}
          />
          <MmsTooltip title={t("filter:edit-scheduled-updates")}>
            <MmsSvgButton
              onClick={openScheduleTaskModal}
              id={`open-schedule-tasks-modal`}
              className={styles.filterBtn()}
            >
              <Event
                id={`schedule-tasks`}
                size="medium"
                color={themeColors.white}
              />
            </MmsSvgButton>
          </MmsTooltip>
        </>
      )}
      <MmsSvgButton
        onClick={withActiveFormsErrorsProtection(toggleFiltersPanel)}
        id={`open-menu-filters-panel`}
        className={styles.filterBtn(isFiltersPanelOpen)}
      >
        <Filters color={themeColors.white} />
        {appliedFiltersAmount && (
          <div className={styles.appliedFiltersContainer}>
            <span className={styles.appliedFiltersText}>
              {appliedFiltersAmount}
            </span>
          </div>
        )}
      </MmsSvgButton>
    </JustifyContentEndLayout>
  );
};
