import { useBulkEditing } from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { BulkActionEnum } from "@model/helperTypes/BulkEditing";
import { useCallback, useEffect, useMemo } from "react";
import { useBulkSelectedEntities } from "./useBulkSelectedEntities";

type CategoryBulkActionsHookReturnTuple = [boolean, VoidFunction];

type CategoryBulkActionsHookType = (
  categoryId: ModelFullCategory["id"],
  toggleExpandedManual: (isExpanded: boolean) => void
) => CategoryBulkActionsHookReturnTuple;

export const useCategoryBulkActions: CategoryBulkActionsHookType = (
  categoryId,
  toggleExpandedManual
) => {
  const {
    selectedEntities,
    callbacks: { onSelectCategory, onDeselectCategory },
  } = useBulkSelectedEntities();

  const [currentBulkAction, , , cleanBulkAction] = useBulkEditing();

  const isCheckboxEnabled = useMemo(() => {
    return !!selectedEntities.selectedCategories.find(
      ({ id }) => id === categoryId
    );
  }, [selectedEntities, categoryId]);

  const onToggleCheckbox = useCallback(() => {
    cleanBulkAction();
    if (!isCheckboxEnabled) onSelectCategory(categoryId);
    else onDeselectCategory(categoryId);
  }, [
    categoryId,
    cleanBulkAction,
    isCheckboxEnabled,
    onDeselectCategory,
    onSelectCategory,
  ]);

  useEffect(() => {
    if (!isCheckboxEnabled) return;

    switch (currentBulkAction) {
      case BulkActionEnum.expand:
        toggleExpandedManual(false);
        break;
      case BulkActionEnum.collapse:
        toggleExpandedManual(true);
        break;
      default:
        break;
    }
  }, [currentBulkAction, isCheckboxEnabled, toggleExpandedManual]);

  return [isCheckboxEnabled, onToggleCheckbox];
};
