import { themeColors } from "@theme/colors";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { IIconBase } from "@uiKit/Atoms/SVGIcons/_common/types";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./CloudPopperButton.styles";

interface IconComponentProps extends IIconBase {
  color?: string;
}

interface CloudPopperButtonProps {
  buttonId: string;
  isDisabled?: boolean;
  tooltipTitle: string;
  buttonTitle: string;
  onClick: VoidFunction;
  IconComponent: React.ComponentType<IconComponentProps>;
}

export const CloudPopperButton: React.FC<CloudPopperButtonProps> = ({
  buttonId,
  isDisabled,
  tooltipTitle,
  buttonTitle,
  onClick,
  IconComponent,
}) => {
  return (
    <MmsBaseButton id={buttonId} onClick={onClick} disabled={isDisabled}>
      <MmsTooltip title={tooltipTitle}>
        <JustifyContentBetweenLayout className={styles.buttonContent}>
          <IconComponent
            id={`${buttonId}-icon`}
            size="small"
            color={isDisabled ? themeColors.mediumGray : undefined}
          />
          <div className={styles.buttonText(isDisabled)}>{buttonTitle}</div>
        </JustifyContentBetweenLayout>
      </MmsTooltip>
    </MmsBaseButton>
  );
};
