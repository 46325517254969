import { cx } from "@emotion/css";
import { useDidMount, useFocus } from "@hooks";
import TextField from "@material-ui/core/TextField";
import { KeyboardKeyEnum } from "@model/helperTypes/forms";
import { themeColors } from "@theme/colors";
import { ScrollToFieldWithError } from "@uiKit/LogicalComponents/ScrollToFieldWithError/ScrollToFieldWithError";
import { useField } from "formik";
import React, { useCallback } from "react";
import "./globalStyles";
import { styles } from "./styles";

export interface MmsMuiInputProps {
  name: string;
  label: string;
  containerClassName?: string;
  disabled?: boolean;
  width?: number;
  rows?: number;
  errorColor?: string;
  focusOnMount?: boolean;
  fullWidth?: boolean;
  showErrorMessage?: boolean;
  blurOnEnter?: boolean;
  cursorToEnd?: boolean;
  showUnderline?: boolean;
  multiline?: boolean;
  validate?: (value: any) => undefined | string | Promise<any>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onPressEsc?: VoidFunction;
  onPressEnter?: VoidFunction;
}

export const MmsMuiInput: React.FC<MmsMuiInputProps> = ({
  name,
  containerClassName,
  width = 166,
  rows,
  focusOnMount,
  fullWidth,
  errorColor = themeColors.red,
  showErrorMessage,
  blurOnEnter = false,
  cursorToEnd,
  showUnderline,
  multiline,
  validate,
  onChange,
  onFocus,
  onBlur,
  onPressEsc,
  onPressEnter,
  ...restProps
}) => {
  const [setInputFocus, inputRef] = useFocus();

  const [field, meta, helpers] = useField({ name, validate });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
      onChange?.(e);
    },
    [field, onChange]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      helpers.setValue(e.target.value.trim());
      field.onBlur(e);
      onBlur?.(e);
    },
    [field, helpers, onBlur]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onFocus?.(e);

      if (!cursorToEnd) return;
      e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    },
    [cursorToEnd, onFocus]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === KeyboardKeyEnum.Escape) {
        onPressEsc?.();
      }
      if (e.key === KeyboardKeyEnum.Enter) {
        onPressEnter?.();

        if (!inputRef.current || !blurOnEnter) return;
        inputRef.current.blur();
      }
    },
    [blurOnEnter, inputRef, onPressEnter, onPressEsc]
  );

  const setTouchedOnFirstChange = useCallback(() => {
    !!field.value && !meta.touched && helpers.setTouched(true);
  }, [field.value, meta.touched, helpers]);

  useDidMount(() => {
    if (inputRef.current && focusOnMount) setInputFocus();
  });

  return (
    <div
      className={cx(
        "MmsMuiInput",
        !!meta.error && "error",
        fullWidth && "MmsMuiInput-fullWidth",
        showUnderline ? "underlined" : "outlined",
        styles.container(!!meta.error, errorColor),
        containerClassName
      )}
    >
      <ScrollToFieldWithError shouldScrollToField={!!meta.error}>
        <TextField
          {...field}
          {...restProps}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInput={setTouchedOnFirstChange}
          onKeyDown={handleKeyDown}
          variant="filled"
          className={cx(styles.field(width))}
          InputProps={{ inputRef }}
          multiline={multiline}
          rows={multiline ? rows : 1}
        />
        {!!meta.error && showErrorMessage && (
          <div className={cx("errorMessage", styles.error(errorColor))}>
            {meta.error}
          </div>
        )}
      </ScrollToFieldWithError>
    </div>
  );
};
