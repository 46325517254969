import { css } from "@emotion/css";

export const styles = (opacity: number) => css`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3000;
  width: 100%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, ${opacity});

  overflow-y: scroll;
`;
