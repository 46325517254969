import { css } from "@emotion/css";

export const styles = {
  form: css`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  `,
  row: css`
    display: flex;
    gap: 12px;
  `,
  itemWrapper: css`
    display: flex;
    align-items: center;
    gap: 4px;
  `,
  baseInput: css`
    margin-top: 6px;
    margin-bottom: 6px;
  `,
};
