import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import React from "react";
import { styles } from "./AddNewCategory.styles";
import { useTranslation } from "react-i18next";

export interface AddNewCategoryProps {
  onClick: VoidFunction;
}

export const AddNewCategory: React.FC<AddNewCategoryProps> = ({ onClick }) => {
  const { t } = useTranslation(["category"]);

  return (
    <MmsBaseButton
      id="add-new-category"
      onClick={onClick}
      fullWidth
      className={styles}
    >
      {t("category:add-new-category")}
    </MmsBaseButton>
  );
};
