import { ReactComponent as SVGClearFilters } from "@assets/SVG/clear-filters.svg";
import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import React from "react";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IClearFiltersProps extends IIconBase {
  color?: string;
}

export const ClearFilters: React.FC<IClearFiltersProps> = ({
  size = "medium",
  className,
  color = themeColors.black,
}) => (
  <SVGClearFilters className={cx(styles.icon(size), className)} fill={color} />
);
