import { ModelCategoryDNA } from "@model/DAO/MenuCategory";

export enum AvailableEnum {
  always = "always",
  restricted = "restricted",
}

export interface LocalAvailabilityData {
  available: AvailableEnum;
  activeFrom: ModelCategoryDNA["activeFrom"];
  activeTo: ModelCategoryDNA["activeTo"];
  disabledOnDays: ModelCategoryDNA["disabledOnDays"];
}

export interface LocalAvailabilityOption {
  title: string;
  value: AvailableEnum;
}
