import { ReactComponent as SVGErrorCircle } from "@assets/SVG/error-circle.svg";
import { cx } from "@emotion/css";
import React from "react";
import { IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IErrorCircleProps extends IIconBase {}

export const ErrorCircle: React.FC<IErrorCircleProps> = ({
  size = "medium",
  className,
}) => <SVGErrorCircle className={cx(styles.icon(size), className)} />;
