import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  deleteImageIcon: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;

    border-radius: 50%;

    background-color: ${themeColors.white};

    z-index: 1;
  `,
  progressBar: css`
    bottom: -7px;
  `,
};
