import { gql } from "@apollo/client";
import { SCHEDULE_TASK_ESSENTIAL_DATA } from "../../fragments/ScheduleTask";

export const GET_SCHEDULE_TASKS = gql`
  query GetScheduleTasksQuery($restaurantId: ID!) {
    scheduleTasks(restaurantId: $restaurantId) {
      ...ScheduleTaskEssentialData
    }
  }
  ${SCHEDULE_TASK_ESSENTIAL_DATA}
`;

export * as GetScheduleTasksTypes from "./__generated__/GetScheduleTasksQuery";
