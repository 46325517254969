import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const EDIT_MENU_ITEM_SKU = gql`
  mutation EditMenuItemSkuMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuItem {
        sku
        id
      }
    }
  }
`;

export const generateEditMenuItemSkuVars = (
  restaurantId: string,
  menuItemId: string,
  newSku: string
) => ({
  input: {
    restaurantId,
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
    menuItem: {
      sku: newSku,
    },
  },
});

export * as EditMenuItemSkuTypes from "./__generated__/EditMenuItemSkuMutation";
