import { useReactiveVar } from "@apollo/client";
import { priceEditingModeVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const usePriceEditingMode = (): [
  boolean,
  VoidFunction,
  VoidFunction
] => {
  const enterPriceEditingMode = useCallback(() => {
    priceEditingModeVar(true);
  }, []);
  const leavePriceEditingMode = useCallback(() => {
    priceEditingModeVar(false);
  }, []);

  return [
    useReactiveVar(priceEditingModeVar),
    enterPriceEditingMode,
    leavePriceEditingMode,
  ];
};
