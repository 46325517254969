import { cx } from "@emotion/css";
import { useDidMount, useFocus } from "@hooks";
import { ScrollToFieldWithError } from "@uiKit/LogicalComponents/ScrollToFieldWithError/ScrollToFieldWithError";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";
import { KeyboardKeyEnum } from "@model/helperTypes/forms";
import { styles } from "./styles";

export interface MmsTextInputProps {
  type: "text" | "email";
  name: string;
  label?: string;
  containerClassName?: string;
  fieldClassName?: string;
  placeholder?: string;
  maxlength?: number;
  focusOnMount?: boolean;
  blurOnEnter?: boolean;
  showErrorMessage?: boolean;
  showWarningMessage?: boolean;
  warningMessage?: string;
  enableSpellCheck?: boolean;
  cursorToEnd?: boolean;
  validate?: (value: any) => undefined | string | Promise<any>;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onFocus?: (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onPressEsc?: VoidFunction;
  onPressEnter?: VoidFunction;
}

export const MmsTextInput: React.FC<
  MmsTextInputProps & React.HTMLProps<HTMLTextAreaElement | HTMLInputElement>
> = React.memo(function MmsTextInput({
  name,
  label,
  containerClassName,
  fieldClassName,
  focusOnMount,
  blurOnEnter = false,
  showErrorMessage,
  showWarningMessage,
  warningMessage,
  enableSpellCheck,
  cursorToEnd,
  validate,
  onChange,
  onFocus,
  onBlur,
  onPressEsc,
  onPressEnter,
  ...restProps
}) {
  const [setInputFocus, inputRef] = useFocus();

  const [field, meta, helpers] = useField({ name, validate });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      field.onChange(e);
      onChange?.(e);
    },
    [field, onChange]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      helpers.setValue(e.target.value.trim());
      field.onBlur(e);
      onBlur?.(e);
    },
    [field, helpers, onBlur]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onFocus?.(e);

      if (!cursorToEnd) return;
      e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    },
    [cursorToEnd, onFocus]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.key === KeyboardKeyEnum.Escape) {
        onPressEsc?.();
      }
      if (e.key === KeyboardKeyEnum.Enter) {
        onPressEnter?.();

        if (!inputRef.current || !blurOnEnter) return;
        inputRef.current.blur();
      }
    },
    [blurOnEnter, inputRef, onPressEnter, onPressEsc]
  );

  const setTouchedOnFirstChange = useCallback(() => {
    !!field.value && !meta.touched && helpers.setTouched(true);
  }, [field.value, meta.touched, helpers]);

  const inputProps = useMemo(() => {
    return {
      ...field,
      ...restProps,
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onInput: setTouchedOnFirstChange,
      onKeyDown: handleKeyDown,
      className: cx(
        styles.field(!!meta.error, !!enableSpellCheck),
        fieldClassName
      ),
      ref: inputRef,
      rows: 1,
    };
  }, [
    enableSpellCheck,
    field,
    fieldClassName,
    handleBlur,
    handleChange,
    handleFocus,
    handleKeyDown,
    inputRef,
    meta.error,
    restProps,
    setTouchedOnFirstChange,
  ]);

  useDidMount(() => {
    if (inputRef.current && focusOnMount) setInputFocus();
  });

  return (
    <div className={cx(styles.container, containerClassName)}>
      <ScrollToFieldWithError shouldScrollToField={!!meta.error}>
        {enableSpellCheck ? (
          <textarea {...inputProps} />
        ) : (
          <input {...inputProps} />
        )}
        {label && <label className={styles.label}>{label}</label>}
        {!!meta.error && showErrorMessage && (
          <div className={cx("errorMessage", styles.error)}>{meta.error}</div>
        )}
        {showWarningMessage && (
          <div className={cx("warningMessage", styles.warning)}>
            {warningMessage}
          </div>
        )}
      </ScrollToFieldWithError>
    </div>
  );
});
