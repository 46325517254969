import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  workingUsersContainer: css`
    width: 16px;
    height: 16px;
    position: absolute;
    top: -2px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: ${themeColors.pink};
  `,
  workingUsersAmount: css`
    font-size: 12px;
    color: white;
    font-weight: bold;
  `,
};
