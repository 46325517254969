import { injectGlobal } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

injectGlobal`
.MmsMuiProgressBar {
  .MuiLinearProgress-root {
    width: 80%;
    height: 4px !important;

    .MuiLinearProgress-barColorPrimary {
      border-radius: ${themeVars.borderRadius};

      background-color: ${themeColors.lightGreen} !important;
    }
  }

  .MuiLinearProgress-colorPrimary {
    border-radius: ${themeVars.borderRadius};

    background-color: ${themeColors.veryLightPink} !important;
  }
}
`;
