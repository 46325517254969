import { IS_IFRAME } from "@constants";
import { cx } from "@emotion/css";
import { MMS2_ENV_Enum } from "@model/helperTypes/env";
import { themeColors } from "@theme/colors";
import { MmsContainer } from "@uiKit/Molecules/Grid/MmsContainer";
import React, { useMemo } from "react";
import { withMobileHack } from "src/hocs/withMobileHack/withMobileHack";
import { styles } from "./PageLayout.styles";

export interface PageLayoutProps {
  className?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  className,
  header = null,
  footer = null,
  children,
}) => {
  const backgroundColor = useMemo(() => {
    switch (process.env.REACT_APP_MMS2_SERVER_ENV) {
      case MMS2_ENV_Enum.MMS2_LOCAL:
        return themeColors.blue;
      case MMS2_ENV_Enum.MMS2_DEV:
        return themeColors.mediumGray;
      case MMS2_ENV_Enum.MMS2_PREPROD:
        return themeColors.burgundy;
      case MMS2_ENV_Enum.MMS2_PROD:
      default:
        return themeColors.standardGreen;
    }
  }, []);

  return (
    <div className={cx(styles.body(IS_IFRAME, backgroundColor), className)}>
      {header}
      <MmsContainer fluid className={styles.container}>
        {children}
      </MmsContainer>
      {footer}
    </div>
  );
};

export default withMobileHack(PageLayout);
