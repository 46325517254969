import { css } from "@emotion/css";

export const styles = {
  controlsContainer: css`
    display: flex;
  `,
  baseControl: css`
    margin: 7px;
  `,
  progressBar: css`
    bottom: -5px;
  `,
};
