import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeVars } from "@theme/vars";

export const styles = {
  buttonContent: css`
    gap: 15px;
    align-items: center;
  `,
  buttonText: (btnDisable?: boolean) => css`
    flex: 1;
    display: flex;

    font-family: ${themeVars.fontFamily};
    font-size: 16px;
    font-weight: 300;
    color: ${btnDisable ? themeColors.mediumGray : themeColors.black};

    cursor: pointer;
  `,
};
