type ValidationListType = Array<(value: any) => string | undefined>;

type ValidationMapType = {
  [key: string]: ValidationListType;
};

type ValuesType = {
  [key: string]: any;
};

export const validateField = (validationList: ValidationListType) => (
  value: any
) => {
  const error = validationList.reduce(
    (accum: string | undefined, item: (value: any) => string | undefined) => {
      return accum || item(value);
    },
    undefined
  );

  return error;
};

export const validateForm = (validationMap: ValidationMapType) => (
  values: ValuesType
) => {
  const errors = Object.entries(validationMap).reduce((errorsAccum, field) => {
    const [fieldName, validationList] = field;
    const fieldError = validateField(validationList)(values[fieldName]);

    return fieldError
      ? { ...errorsAccum, [fieldName]: fieldError }
      : errorsAccum;
  }, {});

  return errors;
};
