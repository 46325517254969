import { useReactiveVar } from "@apollo/client";
import {
  useActiveForms,
  useDidUpdate,
  useDragAndDropClient,
  useFormErrorsProtection,
  useHeaderVisibilityControls,
  usePriceEditingMode,
  useToggle,
} from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { DraggableEnum } from "@model/helperTypes/dragAndDrop";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { PriceLevelPrices } from "@model/helperTypes/PriceLevelPrices";
import { useMenuItemBulkActions } from "@pages/RestaurantMenuPage/hooks/useMenuItemBulkActions";
import { usePublishValidationErrors } from "@pages/RestaurantMenuPage/hooks/usePublishErrors";
import { generateMenuAssetUrl } from "@pages/RestaurantMenuPage/utils/Menu";
import { themeColors } from "@theme/colors";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import { MmsDummyRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink/dummy";
import { Chevron, InfoCircle } from "@uiKit/Atoms/SVGIcons";
import { MmsSwitch } from "@uiKit/Atoms/Switch/MmsSwitch";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import {
  draggingMenuItemParentCategoryVar,
  iwaiterIdVar,
  menuFiltersVar,
  shouldExpandAllMenuItemsVar,
} from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import { protectPriceFromNull } from "@utils/protectors";
import { isNotEmpty } from "@utils/ramdaHelpers";
import { getIsPartner } from "@utils/url";
import React, { useEffect, useMemo } from "react";
import { MenuItemDesktopLayout } from "../../layouts/MenuItemLayout";
import { styles } from "./MenuItem.styles";
import { MenuItemAddonsSection } from "./MenuItemAddonsSection/MenuItemAddonsSection";
import { MenuItemControlsSection } from "./MenuItemControlsSection/MenuItemControlsSection";
import { MenuItemEditTitleForm } from "./MenuItemEditTitle/MenuItemEditTitle";
import { MenuItemImageSection } from "./MenuItemImageSection/MenuItemImageSection";
import { MenuItemTitleSection } from "./MenuItemTitleSection/MenuItemTitleSection";
import { MenuItemEditSkuForm } from "./MenuItemEditSku/MenuItemEditSku";
import { SettingsTabsEnum } from "../MenuItemSettings/MenuItemSettings";

export interface MenuItemProps {
  parentCategory: ModelFullCategory;
  currency: string;
  menuItem: ModelMenuItem;
  onDelete: VoidFunction;
  onClone: VoidFunction;
  onToggle: VoidFunction;
  onChangeTitle: (values: { title: string }) => void;
  onChangeSku: (values: { title: string }) => void;
  onChangePrices?: (values: any) => void;
  onDescriptionClick: VoidFunction;
  onShowCommonSettings: (initialTab?: SettingsTabsEnum) => void;
  onShowAddons: VoidFunction;
  onImport?: VoidFunction;
  onSave?: VoidFunction;
  isEditTitleLoading: boolean;
  isEditSkuLoading: boolean;
  isActive: boolean;
  isNew?: boolean;
  topLevelPriceLevelPrices: PriceLevelPrices[];
  modals: React.ReactNode;
  toggleImageModalOpen: VoidFunction;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  parentCategory,
  currency,
  menuItem,
  onClone,
  onDelete,
  onToggle,
  onChangeTitle,
  onChangeSku,
  onChangePrices = noop,
  onDescriptionClick,
  onShowCommonSettings,
  onShowAddons,
  onImport = noop,
  onSave = noop,
  isEditTitleLoading,
  isEditSkuLoading,
  isActive,
  isNew,
  topLevelPriceLevelPrices,
  modals,
  toggleImageModalOpen,
}) => {
  const iwaiterId = useReactiveVar(iwaiterIdVar);
  const menuFilters = useReactiveVar(menuFiltersVar);
  const isExpandedInitial = useReactiveVar(shouldExpandAllMenuItemsVar);
  const isExpandedSkuInitial = useReactiveVar(shouldExpandAllMenuItemsVar);
  const [showHeader, hideHeader] = useHeaderVisibilityControls();

  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [
    { draggableRef, handleRef },
    { isDragging, didDrop },
    setIsDragAndDropEnabled,
  ] = useDragAndDropClient(`${DraggableEnum.menuItem}.${parentCategory.id}`);
  const [activeForms] = useActiveForms();
  const [isPriceEditingMode] = usePriceEditingMode();
  const [publishErrors] = usePublishValidationErrors();

  const [isEditMode, toggleTitleEditMode] = useToggle(!!isNew);
  const [isExpanded, toggleExpanded, toggleExpandedManual] = useToggle(
    isExpandedInitial
  );

  const [isEditModeSku, toggleSkuEditMode] = useToggle(isExpandedSkuInitial);

  const [isCheckboxEnabled, onToggleCheckbox] = useMenuItemBulkActions(
    menuItem.id,
    toggleExpandedManual
  );

  const [shouldKeepControlsVisible, toggleControlsVisible] = useToggle(false);

  const extraDummyLinkProps = useMemo(
    () => (isPriceEditingMode ? { tabIndex: -1 } : {}),
    [isPriceEditingMode]
  );

  const menuItemErrorsMsgs: string[] | undefined = useMemo(
    () => publishErrors.menu_item?.[+menuItem.id],
    [menuItem.id, publishErrors.menu_item]
  );

  const isDragAndDropDisabled = useMemo(
    () =>
      [
        !!activeForms.length,
        !!isNew,
        isPriceEditingMode,
        isNotEmpty(menuFilters),
      ].some(Boolean),
    [activeForms.length, isNew, isPriceEditingMode, menuFilters]
  );

  const isTitleHighlighted = useMemo(() => {
    if (menuFilters.titleCont === undefined) return false;
    return menuItem.title
      .toLowerCase()
      .includes(menuFilters.titleCont.toLowerCase());
  }, [menuItem.title, menuFilters.titleCont]);

  useEffect(() => {
    setIsDragAndDropEnabled(!isDragAndDropDisabled);
  }, [isDragAndDropDisabled, setIsDragAndDropEnabled]);

  useDidUpdate(() => {
    draggingMenuItemParentCategoryVar(isDragging ? parentCategory.id : "");
  }, [isDragging]);

  useDidUpdate(() => {
    if (isDragging) hideHeader();
    else showHeader();
  }, [isDragging, didDrop]);

  return (
    <div ref={draggableRef} className={styles.container(isDragging)}>
      <MmsDummyRouterLink
        href={generateMenuAssetUrl(
          iwaiterId,
          menuItem.iwaiterId,
          MenuAssetEnum.menu_item
        )}
        disableLinkPreview={getIsPartner()}
        {...extraDummyLinkProps}
      >
        <MenuItemDesktopLayout
          data-testid={`menu-item-${menuItem.id}`}
          ref={handleRef}
          className={styles.layout(isDragging, isDragAndDropDisabled)}
          shouldKeepControlsVisible={shouldKeepControlsVisible}
          isFreeItem={parentCategory.isFreeItem}
          checkboxCell={
            <MmsTooltip
              title={`${isCheckboxEnabled ? "Deselect" : "Select"} this item`}
            >
              <div>
                <MmsCheckbox
                  id={`menu-item-checkbox-${menuItem.id}`}
                  checked={isCheckboxEnabled}
                  onChange={onToggleCheckbox}
                />
              </div>
            </MmsTooltip>
          }
          errorCell={
            menuItemErrorsMsgs && (
              <MmsTooltip title={menuItemErrorsMsgs.toString()}>
                <div>
                  <InfoCircle color={themeColors.pink} />
                </div>
              </MmsTooltip>
            )
          }
          imageCell={
            <AccessControl
              noAccessComponent={
                <MenuItemImageSection
                  itemId={menuItem.id}
                  image={menuItem.image}
                  toggleImageModalOpen={noop}
                  disabled
                />
              }
            >
              <MmsTooltip title={"Add/edit image"}>
                <div>
                  <MenuItemImageSection
                    itemId={menuItem.id}
                    image={menuItem.image}
                    toggleImageModalOpen={withActiveFormsErrorsProtection(
                      toggleImageModalOpen
                    )}
                  />
                </div>
              </MmsTooltip>
            </AccessControl>
          }
          titleCell={
            isEditModeSku ? (
              <MenuItemEditSkuForm
                menuItemId={menuItem.id}
                title={menuItem.sku ?? ""}
                maxlength={4}
                isFormSubmitting={isEditSkuLoading}
                onSubmit={onChangeSku}
                onSubmitEnded={toggleSkuEditMode}
                onPressEsc={isNew ? onDelete : noop}
              />
            ) : isEditMode ? (
              <MenuItemEditTitleForm
                menuItemId={menuItem.id}
                title={menuItem.title}
                isFormSubmitting={isEditTitleLoading}
                onSubmit={onChangeTitle}
                onSubmitEnded={toggleTitleEditMode}
                onPressEsc={isNew ? onDelete : noop}
              />
            ) : (
              <MenuItemTitleSection
                data-testid={`menu-item-title-${menuItem.id}`}
                item={menuItem}
                collapsed={!isExpanded}
                onTitleClick={withActiveFormsErrorsProtection(
                  toggleTitleEditMode
                )}
                onSkuClick={withActiveFormsErrorsProtection(toggleSkuEditMode)}
                onDescriptionClick={withActiveFormsErrorsProtection(
                  onDescriptionClick
                )}
                className={styles.title(!!isTitleHighlighted)}
              />
            )
          }
          addonsCell={
            <MenuItemAddonsSection
              currency={currency}
              menuItemId={menuItem.id}
              hasOwnAddons={menuItem.hasOwnAddons}
              hasServiceTypeSpecificPrices={menuItem.disableBasePrices}
              onShowCommonSettings={onShowCommonSettings}
              addons={
                menuItem.hasOwnAddons ? menuItem.addons : parentCategory.addons
              }
              onShowAddons={withActiveFormsErrorsProtection(onShowAddons)}
              price={protectPriceFromNull(menuItem.price)}
              topLevelPriceLevelPrices={topLevelPriceLevelPrices}
              collapsed={!isExpanded}
              isNew={!!isNew}
              onChangePrices={onChangePrices}
              spiceType={menuItem.spiceType}
              menuFlags={menuItem.menuFlags?.map(({ title }) => title)}
            />
          }
          controlsGroupCell={
            <MenuItemControlsSection
              onShowCommonSettings={
                isNew
                  ? noop
                  : withActiveFormsErrorsProtection(onShowCommonSettings)
              }
              onShowAddons={
                isNew ? noop : withActiveFormsErrorsProtection(onShowAddons)
              }
              onClone={isNew ? noop : withActiveFormsErrorsProtection(onClone)}
              onDelete={onDelete}
              onImport={isNew ? noop : onImport}
              onSave={isNew ? noop : onSave}
              toggleControlsVisible={toggleControlsVisible}
              hasOwnAddons={menuItem.hasOwnAddons}
              isFreeItem={parentCategory.isFreeItem}
              id={menuItem.id}
            />
          }
          toggleCell={
            <MmsTooltip
              title={`Make ${isActive ? "unavailable" : "available"}`}
            >
              <div>
                <MmsSwitch
                  checked={isActive}
                  onChange={onToggle}
                  id={`toggle-menu-item-controls-${menuItem.id}`}
                  className={styles.isActiveToggle(
                    isActive && !parentCategory.active
                  )}
                />
              </div>
            </MmsTooltip>
          }
          chevronCell={
            <MmsTooltip title={isExpanded ? "Collapse" : "See more info"}>
              <MmsSvgButton
                onClick={toggleExpanded}
                id={`menu-control-open-prices-${menuItem.id}`}
                className={styles.baseControl}
              >
                <Chevron
                  id={`menu-control-open-prices-${menuItem.id}-icon`}
                  direction={isExpanded ? "bottom" : "right"}
                  size="small"
                />
              </MmsSvgButton>
            </MmsTooltip>
          }
        />
      </MmsDummyRouterLink>
      {modals}
    </div>
  );
};
