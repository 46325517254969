import { activeProgressBarsVar } from "@utils/apolloReactiveVars";
import { useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

export const useActiveProgressBars = (): [
  string[],
  (id: string) => void,
  (id: string) => void
] => {
  const setProgressBarId = useCallback((id: string) => {
    const activeProgressBarIds = activeProgressBarsVar();
    activeProgressBarsVar([...activeProgressBarIds, id]);
  }, []);

  const removeProgressBarId = useCallback((id: string) => {
    const activeProgressBarIds = activeProgressBarsVar();
    activeProgressBarsVar(
      activeProgressBarIds.filter((progressBarId) => progressBarId !== id)
    );
  }, []);

  return [
    useReactiveVar(activeProgressBarsVar),
    setProgressBarId,
    removeProgressBarId,
  ];
};
