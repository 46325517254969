import { cx } from "@emotion/css";
import { MmsSimpleSpinner } from "@uiKit/Atoms/Spinner/MmsSimpleSpinner";
import React from "react";
import { MmsBaseButton, MmsBaseButtonProps } from "../MmsBaseButton";
import { styles } from "./styles";

export interface MmsPrimaryButtonProps extends MmsBaseButtonProps {
  disabled?: boolean;
  processing?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const MmsPrimaryButton = React.forwardRef<
  HTMLButtonElement,
  MmsPrimaryButtonProps
>(function MmsPrimaryButton(
  { disabled, processing, children, className, ...restProps },
  ref
) {
  return (
    <MmsBaseButton
      {...restProps}
      ref={ref}
      className={cx("primaryBtn", styles.container, className)}
      disabled={disabled || processing}
    >
      <div>{processing ? <MmsSimpleSpinner /> : children}</div>
    </MmsBaseButton>
  );
});
