import DateFnsUtils from "@date-io/date-fns";
import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConnectedMmsDateTimePicker } from "@uiKit/Atoms/Inputs/MmsDateTimePicker";
import { validateForm } from "@utils/formik";
import { isNotEmpty } from "@utils/ramdaHelpers";
import { Form, FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { styles } from "./ScheduleTasksForm.styles";
import { validationMap } from "./ScheduleTasksForm.validation";
import { useTranslation } from "react-i18next";

export interface ScheduleTasksFormValues {
  runAt: Date;
}

interface ScheduleTasksFormContentProps {
  runAt: Date;
  onSubmit: (values: ScheduleTasksFormValues) => void;
  onError: (isAnyError: boolean) => void;
}

export const ScheduleTasksFormContent: React.FC<
  ScheduleTasksFormContentProps & FormikProps<ScheduleTasksFormValues>
> = ({ runAt, onSubmit, onError, ...formikProps }) => {
  const { t } = useTranslation(["common"]);
  const formId = "schedule-task-update-form";
  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useEffect(() => {
    onError(isNotEmpty(formikProps.errors));
  }, [formikProps.errors, onError]);

  useDidUpdate(() => {
    if (!formikProps.touched) return;
    formikProps.submitForm();
  }, [formikProps.values]);

  return (
    <Form className={styles.form}>
      <p className={styles.message}>Auto-enable at</p>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ConnectedMmsDateTimePicker
          autoOk
          disablePast
          disableToolbar
          showErrorMessage
          fullWidth
          ampm={false}
          format="dd.MM.yyyy HH:mm"
          views={["date", "hours"]}
          id="runAt-date-time-picker"
          name="runAt"
          label={t("common:date-time")}
        />
      </MuiPickersUtilsProvider>
    </Form>
  );
};

export const ScheduleTasksForm = withFormik<
  ScheduleTasksFormContentProps,
  ScheduleTasksFormValues
>({
  validateOnBlur: false,
  validateOnChange: false,
  // Transform outer props into form values
  mapPropsToValues: ({ runAt }) => ({
    runAt,
  }),
  validate: validateForm(validationMap),
  handleSubmit: (values: ScheduleTasksFormValues, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(ScheduleTasksFormContent);
