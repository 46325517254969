import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import React from "react";
import { styles } from "./StatusBarLayout.styles";

interface StatusBarLayoutProps {
  leftCol: React.ReactElement;
  rightCol?: React.ReactNode;
  className?: string;
  bgColor?: string;
}

export const StatusBarLayout: React.FC<StatusBarLayoutProps> = ({
  leftCol,
  rightCol = null,
  className,
  bgColor = themeColors.lightGreen,
}) => {
  return (
    <JustifyContentBetweenLayout
      className={cx(styles.container(bgColor), className)}
    >
      <div className={styles.leftCol}>{leftCol}</div>
      <div className={styles.rightCol}>{rightCol}</div>
    </JustifyContentBetweenLayout>
  );
};
