import { css } from "@emotion/css";

export const styles = {
  imageModal: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 430px;
    height: fit-content;

    padding: 20px;
  `,
};
