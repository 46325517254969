import { useHiddenText } from "@hooks";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./LibraryDataItem.styles";

interface LibraryDataItemProps {
  title: string;
  icon: React.ReactNode;
}

export const LibraryDataItem: React.FC<LibraryDataItemProps> = ({
  title,
  icon,
}) => {
  const [titleRef, isTooltipOpen] = useHiddenText();
  return (
    <JustifyContentBetweenLayout className={styles.dataItem}>
      {icon}
      <MmsTooltip title={title} disable={!isTooltipOpen}>
        <div className={styles.dataText} ref={titleRef}>
          {title}
        </div>
      </MmsTooltip>
    </JustifyContentBetweenLayout>
  );
};
