import { css } from "@emotion/css";

export const styles = {
  form: css`
    margin-left: -16px;
    margin-right: -16px;
  `,
  baseInput: css`
    gap: 10px;
    padding-left: 16px;
    padding-right: 16px;
  `,
  selectBox: css`
    margin-bottom: 16px;
  `,
};
