import { useReactiveVar } from "@apollo/client";
import { globalLoaderIdListVar } from "@utils/apolloReactiveVars";
import { uniq } from "ramda";
import { useCallback } from "react";

type ReturnTupleType = [
  string[],
  (loaderId: string) => void,
  (loaderId: string) => void
];

// this hook is intended to detect loading of any request globally
export const useGlobalLoading = (): ReturnTupleType => {
  const loaderIdList = useReactiveVar(globalLoaderIdListVar);

  const startGlobalLoading = useCallback(
    (loaderId: string) => {
      globalLoaderIdListVar(uniq([...loaderIdList, loaderId]));
    },
    [loaderIdList]
  );

  const stopGlobalLoading = useCallback(
    (loaderId: string) => {
      globalLoaderIdListVar(
        loaderIdList.filter((loaderIdItem) => loaderIdItem === loaderId)
      );
    },
    [loaderIdList]
  );

  return [loaderIdList, startGlobalLoading, stopGlobalLoading];
};
