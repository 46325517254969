import { useCallback, useState } from "react";

export const useToggle = (
  initValue: boolean
): [boolean, VoidFunction, (manualState: boolean) => void] => {
  const [isTrue, setIsTrue] = useState<boolean>(initValue);

  const toggleTrue = useCallback(() => {
    setIsTrue(!isTrue);
  }, [isTrue]);

  const toggleTrueManual = useCallback((manualState: boolean) => {
    setIsTrue(manualState);
  }, []);

  return [isTrue, toggleTrue, toggleTrueManual];
};
