import { NetworkStatus, useReactiveVar } from "@apollo/client";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { MenuStateEnum } from "@model/helperTypes/Menu";
import { menuFiltersVar, menuStateVar } from "@utils/apolloReactiveVars";
import { isEmpty } from "ramda";
import { useEffect, useMemo } from "react";

interface IUseMenuState {
  categories: ModelFullCategory[];
  isCategoriesDataLoading: boolean;
  isAllCategoriesMenuItemsDataLoading: boolean;
  allCategoriesMenuItemsNetworkStatus?: NetworkStatus;
}

export const useMenuState = ({
  categories,
  isCategoriesDataLoading,
  isAllCategoriesMenuItemsDataLoading,
  allCategoriesMenuItemsNetworkStatus,
}: IUseMenuState) => {
  const menuFilters = useReactiveVar(menuFiltersVar);

  const menuState: MenuStateEnum = useMemo(() => {
    if (isEmpty(menuFilters)) {
      if (isCategoriesDataLoading) return MenuStateEnum.showLoader;
      if (!categories.length) return MenuStateEnum.showEmptyMenuBanner;
    } else {
      if (menuFilters.activenessPresent || menuFilters.titleCont) {
        if (isCategoriesDataLoading) return MenuStateEnum.showLoader;
        if (
          isAllCategoriesMenuItemsDataLoading &&
          allCategoriesMenuItemsNetworkStatus !== NetworkStatus.refetch
        )
          return MenuStateEnum.showLoader;
      }
      if (!categories.length) return MenuStateEnum.showEmptyMessage;
    }
    return MenuStateEnum.showCategoryList;
  }, [
    allCategoriesMenuItemsNetworkStatus,
    categories.length,
    isAllCategoriesMenuItemsDataLoading,
    isCategoriesDataLoading,
    menuFilters,
  ]);

  useEffect(() => {
    menuStateVar(menuState);
  }, [menuState]);
};
