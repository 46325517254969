import { useReactiveVar } from "@apollo/client";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { AddonRowLayout } from "@pages/RestaurantMenuPage/layouts/AddonRowLayout/AddonRowLayout";
import { menuFiltersVar } from "@utils/apolloReactiveVars";
import React, { useCallback } from "react";
import { styles } from "../MenuItemAddonsSection.styles";
import { MenuItemPriceList } from "../MenuItemPriceList/MenuItemPriceList";

interface BodyAddonsProps {
  restAddons: ModelMenuAddon[];
  hasOwnAddons: boolean;
}

export const BodyAddons: React.FC<BodyAddonsProps> = ({
  restAddons,
  hasOwnAddons,
}) => {
  const menuFilters = useReactiveVar(menuFiltersVar);

  const checkIsTitleHighlighted = useCallback(
    (title: string) => {
      if (menuFilters.titleCont === undefined) return false;
      return title.toLowerCase().includes(menuFilters.titleCont.toLowerCase());
    },
    [menuFilters.titleCont]
  );

  return (
    <>
      {restAddons.map((addon) => {
        const rightColumnText = addon.items.map(({ title }, index, arr) => {
          const isHighlighted = checkIsTitleHighlighted(title);

          return (
            <>
              <span className={styles.title(isHighlighted)}>{title}</span>
              {index < arr.length - 1 && ", "}
            </>
          );
        });

        return (
          <AddonRowLayout
            data-testid="menu-item-addon--other"
            key={addon.id}
            indicatorColor={hasOwnAddons ? "pink" : "yellow"}
            leftColumn={
              <span
                className={styles.title(checkIsTitleHighlighted(addon.title))}
              >
                {addon.title}
              </span>
            }
            rightColumn={<MenuItemPriceList priceList={rightColumnText} />}
          />
        );
      })}
    </>
  );
};
