import { injectGlobal } from "@emotion/css";

injectGlobal`
.MmsTablePagination {
  .MuiTablePagination-caption,
  .MuiTablePagination-selectRoot {
    display: none;
  }
}
`;
