import { css } from "@emotion/css";

export const styles = {
  bulkPanel: css`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 32px;
    padding: 0 20px;
  `,
  selectAllBtn: css`
    margin-right: 15px;
  `,
};
