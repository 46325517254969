import { useReactiveVar } from "@apollo/client";
import { DEFAULT_IMPORT_DIRECTION_VALUE } from "@constants";
import { ImportDirectionType } from "@model/helperTypes/LibraryAsset";
import { activeImportDirectionVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useActiveImportDirection = (): [
  ImportDirectionType,
  (
    destinationId: ImportDirectionType["destinationId"],
    destinationType: ImportDirectionType["destinationType"]
  ) => void,
  VoidFunction
] => {
  const setImportDirection = useCallback(
    (
      destinationId: ImportDirectionType["destinationId"],
      destinationType: ImportDirectionType["destinationType"]
    ) => {
      activeImportDirectionVar({ destinationId, destinationType });
    },
    []
  );

  const cleanImportDirection = useCallback(() => {
    activeImportDirectionVar(DEFAULT_IMPORT_DIRECTION_VALUE);
  }, []);

  return [
    useReactiveVar(activeImportDirectionVar),
    setImportDirection,
    cleanImportDirection,
  ];
};
