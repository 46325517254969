import { useDragAndDropProvider } from "@hooks";
import { TransferableData } from "@model/helperTypes/dragAndDrop";
import { convertDraggableDataToOriginShape } from "@utils/dragAndDrop";
import { noop } from "@utils/noop";
import { equals } from "ramda";
import React, { useCallback } from "react";

export interface SortableListProps<ComponentProps extends { id: string }> {
  data: ComponentProps[];
  ItemComponent: React.ComponentType<ComponentProps>;
  onChangeOrder: (reorderedData: ComponentProps[]) => void;
  onTransferItem?: (transferedData: TransferableData) => void;
}

export const SortableList = <ComponentProps extends { id: string }>({
  data,
  ItemComponent,
  onChangeOrder,
  onTransferItem = noop,
}: SortableListProps<ComponentProps>) => {
  const [
    draggableData,
    moveDraggableItem,
    DragAndDropContextProvider,
  ] = useDragAndDropProvider<ComponentProps>(data);

  const handleRerankEnd = useCallback(() => {
    const originReorderedData = convertDraggableDataToOriginShape<ComponentProps>(
      draggableData
    );
    if (equals(data, originReorderedData)) return;

    onChangeOrder(originReorderedData);
    // eslint-disable-next-line
  }, [data, draggableData, onChangeOrder]);

  return (
    <>
      {draggableData.map((draggableItem) => (
        <DragAndDropContextProvider
          value={{
            draggableItem,
            moveDraggableItem,
            onRerankEnd: handleRerankEnd,
            onTransferEnd: onTransferItem,
          }}
          key={draggableItem.itemData.id}
        >
          <ItemComponent {...draggableItem.itemData} />
        </DragAndDropContextProvider>
      ))}
    </>
  );
};
