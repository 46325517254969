import {
  LOGS_DETAILS_CELLS_NAMES,
  LOGS_TABLE_CELLS_NAMES,
  LOGS_UPDATE_DETAILS_CELLS_NAMES,
} from "@constants";
import { LogData } from "@model/DAO/Logs";
import { MmsTable } from "@uiKit/Molecules/Table/MmsTable";
import { MmsTableCell } from "@uiKit/Molecules/Table/MmsTableCell";
import { MmsTableHead } from "@uiKit/Molecules/Table/MmsTableHead";
import { MmsTableRow } from "@uiKit/Molecules/Table/MmsTableRow";
import { isNotEmpty } from "@utils/ramdaHelpers";
import { convertCellValueToString } from "@utils/strings";
import React, { useMemo } from "react";
import { styles } from "./LogsTableRowDetails.styles";

interface LogsTableRowDetailsProps {
  actionTypeName: string;
  changes: LogData["changes"];
  comment: LogData["comment"];
}

const createRowValue = (isUpdate: boolean, cellName: string, data: any) => {
  switch (true) {
    case isUpdate: {
      const [prevValue, nextValue] = data;
      return (
        <>
          <MmsTableCell align={"left"}>{cellName}</MmsTableCell>
          <MmsTableCell className={styles.prevValueCell} align={"left"}>
            {convertCellValueToString(prevValue)}
          </MmsTableCell>
          <MmsTableCell className={styles.valueCell} align={"left"}>
            {convertCellValueToString(nextValue)}
          </MmsTableCell>
        </>
      );
    }
    case data !== null: {
      return (
        <>
          <MmsTableCell align={"left"}>{cellName}</MmsTableCell>
          <MmsTableCell className={styles.valueCell} align={"left"}>
            {convertCellValueToString(data)}
          </MmsTableCell>
        </>
      );
    }
    default:
      return null;
  }
};

export const LogsTableRowDetails: React.FC<LogsTableRowDetailsProps> = ({
  actionTypeName,
  changes,
  comment,
}) => {
  const isUpdateAction = useMemo(() => actionTypeName === "Update", [
    actionTypeName,
  ]);

  return (
    <MmsTableRow>
      <MmsTableCell
        className={styles.detailsCell}
        colSpan={LOGS_TABLE_CELLS_NAMES.length}
        align={"left"}
      >
        <MmsTable
          caption={
            <>
              <div className={styles.title}>{actionTypeName}</div>
              <div className={styles.comment}>{comment}</div>
            </>
          }
          tableHeadComponent={
            <>
              {changes && isNotEmpty(changes) && (
                <MmsTableHead
                  colsNames={
                    isUpdateAction
                      ? LOGS_UPDATE_DETAILS_CELLS_NAMES
                      : LOGS_DETAILS_CELLS_NAMES
                  }
                  align={"left"}
                />
              )}
            </>
          }
          tableBodyComponent={
            <>
              {changes &&
                isNotEmpty(changes) &&
                Object.keys(changes).map((key, index) => {
                  return (
                    <MmsTableRow key={`${key}-${index}-${changes[key]}`}>
                      {createRowValue(isUpdateAction, key, changes[key])}
                    </MmsTableRow>
                  );
                })}
            </>
          }
        />
      </MmsTableCell>
    </MmsTableRow>
  );
};
