import { gql } from "@apollo/client";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";

export const ADD_TO_LIBRARY = gql`
  mutation AddToLibraryMutation($input: LibraryAdd!) {
    libraryAdd(input: $input) {
      id
      originType
      title
      favorite
      displayData {
        title
        items
      }
    }
  }
`;

export const generateAddToLibraryVars = (
  origin: MenuAssetEnum,
  originId: string,
  title?: string
) => {
  if (origin === MenuAssetEnum.menu) {
    return { input: { origin, originId, title } };
  }
  return { input: { origin, originId } };
};

export * as AddToLibraryTypes from "./__generated__/AddToLibraryMutation";
