import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  tab: css`
    margin-bottom: 10px;
  `,
  tabContent: (isIncluded: boolean) => css`
    align-items: center;
    margin-top: 1px;

    ${!isIncluded &&
    `
      font-style: italic;
      color: ${themeColors.mediumGray};
    `}
  `,
};
