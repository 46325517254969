import { cx } from "@emotion/css";
import React, { useCallback } from "react";
import Select, { createFilter } from "react-select";
import "./globalStyles";
import { styles } from "./styles";
import { MultiSelectboxDataType } from "./types";

export interface MmsMultiSelectboxProps {
  id?: string;
  selectedOptions: MultiSelectboxDataType[];
  allOptions: MultiSelectboxDataType[];
  isAlwaysOpen?: boolean;
  isOpen?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
  className?: string;
  onChange: (selectedOptions: MultiSelectboxDataType[]) => void;
  noOptionsMessage?: any;
  closeMenuOnSelect?: boolean;
  isDisabled?: boolean;
}

const filterConfig = {
  stringify: (option: MultiSelectboxDataType) => `${option.label}`,
};

export const MmsMultiSelectbox: React.FC<MmsMultiSelectboxProps> = ({
  selectedOptions,
  allOptions,
  isAlwaysOpen,
  isOpen,
  className,
  onChange,
  ...rest
}) => {
  const handleChange = useCallback(
    (selectedOptions) => {
      onChange(selectedOptions);
    },
    [onChange]
  );

  return (
    <div className={cx("MmsMultiSelectbox", styles.container, className)}>
      <Select
        defaultValue={selectedOptions}
        options={allOptions}
        isMulti
        menuIsOpen={isAlwaysOpen || isOpen}
        className="react-select-container"
        classNamePrefix="react-select"
        onChange={handleChange}
        components={{
          DropdownIndicator: null,
        }}
        filterOption={createFilter(filterConfig)}
        {...rest}
      />
    </div>
  );
};
