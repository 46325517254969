import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: (isDragging: boolean) => css`
    opacity: ${isDragging ? 0.5 : 1};
    background-color: ${isDragging ? themeColors.standardGreen : "white"};
  `,
  layout: (isDragging: boolean, isDragAndDropDisabled: boolean) => css`
    cursor: ${isDragAndDropDisabled ? "auto" : "move"};
    opacity: ${isDragging ? 0.5 : 1};
  `,
  isActiveToggle: (isSemiTransparent: boolean) => css`
    opacity: ${isSemiTransparent ? 0.3 : 1};
  `,
  baseControl: css`
    margin: 5px;
  `,
  title: (isHighlighted: boolean) => css`
    ${isHighlighted &&
    css`
      background-color: ${themeColors.mediumGreenTransparent};
    `}
  `,
};
