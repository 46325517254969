import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { AddonTypeData, AddonTypeEnum } from "@model/helperTypes/Addon";
import { AddonsSettingsStrategyEnum } from "@model/helperTypes/AddonsSettings";
import { determineAddonType } from "@pages/RestaurantMenuPage/utils/Addon";
import { MAX_PRICE_LEVEL_ADDON_ITEMS_AMOUNT } from "@pages/RestaurantMenuPage/utils/AddonItem";
import { MmsLabelSwitch } from "@uiKit/Atoms/Switch/MmsLabelSwitch";
import { JustifyContentStartLayout } from "@uiKit/Layouts/JustifyContentStartLayout";
import { getIsPartner } from "@utils/url";
import React, { useMemo } from "react";
import { AddonTabContentSelectType } from "../../AddonsSettings/AddonTabContent/AddonTabContentSelectType/AddonTabContentSelectType";
import { styles } from "./NewAddonTabContentControls.styles";
import { useTranslation } from "react-i18next";
import { NewAddonMinMaxForm } from "../NewAddonMinMaxForm/NewAddonMinMaxForm";

interface NewAddonTabContentControlsProps {
  addonsSettingsStrategy: AddonsSettingsStrategyEnum;
  selectedAddon: ModelLocalAddon | null;
  isUpdateProcessing: boolean;
  isPriceLevelAddonAvailable: boolean;
  onChangeAddonType: (newType: AddonTypeEnum) => void;
  togglePriceLevelDependent: VoidFunction;
  onMinMaxSet: (
    addonId: string,
    values: { min?: number; max?: number }
  ) => void;
}

export const NewAddonTabContentControls: React.FC<NewAddonTabContentControlsProps> = ({
  addonsSettingsStrategy,
  selectedAddon,
  isUpdateProcessing,
  isPriceLevelAddonAvailable,
  onChangeAddonType,
  togglePriceLevelDependent,
  onMinMaxSet,
}) => {
  const { t } = useTranslation(["addons"]);
  const currentAddonType = useMemo(() => determineAddonType(selectedAddon!), [
    selectedAddon,
  ]);

  const showPriceLevelOption = [
    isPriceLevelAddonAvailable &&
      selectedAddon!.itemsMeta.localAddonItems.length <=
        MAX_PRICE_LEVEL_ADDON_ITEMS_AMOUNT,
    currentAddonType === AddonTypeEnum.priceLevel,
  ].some(Boolean);

  const availableAddonTypesData: AddonTypeData[] = useMemo<any>(() => {
    return [
      showPriceLevelOption && {
        title: t("addons:price-level"),
        value: "priceLevel",
      },
      {
        title: t("addons:select-one"),
        value: "selectOne",
      },
      {
        title: t("addons:select-multiple"),
        value: "selectMultiple",
      },
    ].filter(Boolean);
  }, [showPriceLevelOption, t]);

  const isEditModeDisabled = useMemo(() => {
    switch (addonsSettingsStrategy) {
      case AddonsSettingsStrategyEnum.category:
      case AddonsSettingsStrategyEnum.menu_item_attached:
        return [getIsPartner(), isUpdateProcessing].some(Boolean);
      case AddonsSettingsStrategyEnum.menu_item_detached:
      default:
        return true;
    }
  }, [addonsSettingsStrategy, isUpdateProcessing]);

  const showPriceLevelDependentSwitcher = useMemo(() => {
    return [
      !isPriceLevelAddonAvailable,
      currentAddonType !== AddonTypeEnum.priceLevel,
      !getIsPartner(),
    ].every(Boolean);
  }, [currentAddonType, isPriceLevelAddonAvailable]);

  return (
    <JustifyContentStartLayout className={styles.settingsControls}>
      <AddonTabContentSelectType
        disabled={isEditModeDisabled}
        currentType={currentAddonType}
        onChangeType={onChangeAddonType}
        addonTypesData={availableAddonTypesData}
      />
      {currentAddonType === AddonTypeEnum.selectMultiple && (
        <NewAddonMinMaxForm
          uniqId={selectedAddon!.id}
          min={selectedAddon?.min}
          max={selectedAddon?.max}
          onSubmit={(values) => onMinMaxSet(selectedAddon!.id!, values)}
        />
      )}
      {showPriceLevelDependentSwitcher ? (
        <MmsLabelSwitch
          id="toggle-price-level-addon-item"
          label={t("addons:multi-prices")}
          checked={selectedAddon!.priceLevelDependent}
          onChange={togglePriceLevelDependent}
          disabled={isEditModeDisabled}
        />
      ) : (
        <div />
      )}
    </JustifyContentStartLayout>
  );
};
