import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import React from "react";
import { useTranslation } from "react-i18next";

export interface AutoenableModalProps {
  isAutoenableLoading: boolean;
  isAnyValidationErrors: boolean;
  onSkip: VoidFunction;
  onSave: VoidFunction;
  children: React.ReactNode;
}

export const AutoenableModal: React.FC<AutoenableModalProps> = ({
  isAutoenableLoading,
  isAnyValidationErrors,
  onSkip,
  onSave,
  children,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <MmsConfirmationModal
      message={children}
      title={t("common:auto-enable")}
      confirmBtnText={t("common:save")}
      declineBtnText={t("common:skip")}
      isDataProcessing={isAutoenableLoading}
      isConfirmBtnDisabled={isAnyValidationErrors}
      onConfirmBtnClick={onSave}
      onDeclineBtnClick={onSkip}
    />
  );
};
