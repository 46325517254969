import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  link: css`
    &,
    &:hover {
      color: ${themeColors.black};
      text-decoration: none;
    }
  `,
};
