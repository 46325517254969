import { gql } from "@apollo/client";
import { ModelCategoryTaxes } from "@model/DAO/MenuCategory";
import { Levels } from "__generated__/globalTypes";

export const EDIT_CATEGORY_TAXES = gql`
  mutation EditCategoryTaxesMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuCategory {
        id
        taxAssociations {
          menuCategoryId
          menuItemId
          serviceType
          taxAssociationId
          taxIds
        }
      }
    }
  }
`;

export const generateEditCategoryTaxesVars = (
  restaurantId: string,
  categoryId: string,
  { id, taxAssociations }: ModelCategoryTaxes
) => {
  return {
    input: {
      restaurantId,
      entityType: Levels.CATEGORY,
      entityId: categoryId,
      category: {
        id,
        taxAssociations,
      },
    },
  };
};

export * as EditCategoryTaxesTypes from "./__generated__/EditCategoryTaxesMutation";
