import { SCREEN_SIZES } from "@constants";
import { css } from "@emotion/css";

export const styles = {
  wrapper: (width: number) => css`
    @media (max-width: ${SCREEN_SIZES.MD - 1}px) {
      width: 1024px;
      height: ${(1024 / width) * 100}vh;
    }
  `,
};
