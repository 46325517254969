import { gql } from "@apollo/client";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import {
  BulkPriceUpdateOptionsEnum,
  BulkRequestActionEnum,
  LevelsBulkEnum,
  SubLevelsBulk,
} from "@model/helperTypes/BulkEditing";

export const BULK_UPDATE_ADDON_ITEMS_PRICES = gql`
  mutation BulkUpdateAddonItemsPricesMutation(
    $categoryInput: BulkData!
    $menuItemInput: BulkData!
    $isCategoriesSelected: Boolean!
    $isMenuItemsSelected: Boolean!
  ) {
    categoryUpdate: mmsBulk(input: $categoryInput)
      @include(if: $isCategoriesSelected)
    menuItemUpdate: mmsBulk(input: $menuItemInput)
      @include(if: $isMenuItemsSelected)
  }
`;

export const generateBulkUpdateAddonItemsPricesVars = (
  restaurantId: string,
  selectedCategoriesIds: ModelFullCategory["id"][],
  selectedMenuItemsIds: ModelMenuItem["id"][],
  bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum,
  bulkPrice: string
) => ({
  categoryInput: {
    restaurantId,
    levelType: LevelsBulkEnum.category,
    subLevelType: SubLevelsBulk.addon_item,
    ids: selectedCategoriesIds,
    bulkAction: BulkRequestActionEnum.update,
    params: { [bulkPriceUpdateOption]: bulkPrice },
  },
  menuItemInput: {
    restaurantId,
    levelType: LevelsBulkEnum.menu_item,
    subLevelType: SubLevelsBulk.addon_item,
    ids: selectedMenuItemsIds,
    bulkAction: BulkRequestActionEnum.update,
    params: { [bulkPriceUpdateOption]: bulkPrice },
  },
  isCategoriesSelected: !!selectedCategoriesIds.length,
  isMenuItemsSelected: !!selectedMenuItemsIds.length,
});

export * as BulkUpdateAddonItemsPricesTypes from "./__generated__/BulkUpdateAddonItemsPricesMutation";
