import { css } from "@emotion/css";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  container: (isIFrame: boolean, screenWidth: number) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    ${isIFrame && screenWidth <= 920 && `transform: scale(${screenWidth / 920});`}
  `,
  content: css`
    display: flex;

    height: 500px;
    margin-top: 55px;
    margin-bottom: 15px;

    border-radius: ${themeVars.borderRadius};
    outline: none;

    background-color: white;

    box-shadow: ${themeUtils.boxShadowSettings(80)} ${themeUtils.boxShadowOpacity()};
  `,
};
