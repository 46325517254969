import { useReactiveVar } from "@apollo/client";
import partnerManual from "@assets/pdf/Menu_Builder_User_Manual_Partner_Centre_V11.pdf";
import adminManual from "@assets/pdf/MMS_User_Manual_V11.pdf";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { MmsRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink";
import { Clock, Globe, Release, UserManual } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsPopper, MmsPopperProps } from "@uiKit/Molecules/Popper/MmsPopper";
import { restaurantCachedDataVar } from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import React from "react";
import { useParams } from "react-router-dom";
import { styles } from "./HeaderMorePopper.styles";
import { useTranslation } from "react-i18next";

export interface HeaderMorePopperProps {
  id: string;
  anchorEl: MmsPopperProps["anchorEl"];
  onClose: VoidFunction;
  releaseMarker?: boolean;
}

export const HeaderMorePopper: React.FC<HeaderMorePopperProps> = ({
  id,
  anchorEl,
  onClose,
  releaseMarker,
}) => {
  const { t } = useTranslation(["navigation"]);
  const isPartner = getIsPartner();
  const { restaurantId } = useParams<MatchParams>();
  const { family } = useReactiveVar(restaurantCachedDataVar);

  return (
    <MmsPopper
      id={id}
      className={styles.popper}
      onClose={onClose}
      anchorEl={anchorEl}
    >
      <VerticalAlignBetween className={styles.container}>
        {family?.url && (
          <AccessControl>
            <MmsRouterLink
              href={
                family.url?.includes("https://")
                  ? family.url
                  : `https://${family.url}`
              }
              className={styles.link}
              onClick={onClose}
              shouldOpenInNewTab
            >
              <JustifyContentBetweenLayout className={styles.button}>
                <Globe id={`go-to-restaurant-site-${id}-icon`} size="small" />
                <div className={styles.btnText}>{t("navigation:website")}</div>
              </JustifyContentBetweenLayout>
            </MmsRouterLink>
          </AccessControl>
        )}
        <AccessControl>
          <MmsRouterLink
            href={`/restaurant/${restaurantId}/menu/logs`}
            className={styles.link}
            onClick={onClose}
            shouldOpenInNewTab
          >
            <JustifyContentBetweenLayout className={styles.button}>
              <Clock id={`go-to-menu-${id}-logs-icon`} size="small" />
              <div className={styles.btnText}>{t("navigation:logs")}</div>
            </JustifyContentBetweenLayout>
          </MmsRouterLink>
        </AccessControl>
        <AccessControl>
          <span className={styles.separator} />
        </AccessControl>
        <AccessControl>
          <MmsRouterLink
            href={`/restaurant/${restaurantId}/menu/release-notes`}
            className={styles.link}
            onClick={onClose}
            shouldOpenInNewTab
          >
            <JustifyContentBetweenLayout className={styles.button}>
              <div className={styles.markerWrapper}>
                <Release id={`go-to-release-${id}-icon`} size="small" />
                {releaseMarker && <span className={styles.marker} />}
              </div>
              <div className={styles.btnText}>
                {" "}
                {t("navigation:release-notes")}{" "}
              </div>
            </JustifyContentBetweenLayout>
          </MmsRouterLink>
        </AccessControl>
      </VerticalAlignBetween>
    </MmsPopper>
  );
};
