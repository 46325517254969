import { BULK_DELETE_GALLERY_IMAGES_PROGRESSBAR_ID } from "@constants";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Trash } from "@uiKit/Atoms/SVGIcons";
import { MmsProgressBar } from "@uiKit/Molecules/ProgressBar/MmsProgressBar";
import React from "react";
import { styles } from "./GalleryBulkPanel.styles";

interface GalleryBulkPanelProps {
  isDeleteGalleryImagesLoading: boolean;
  onDeleteClick: VoidFunction;
}

export const GalleryBulkPanel: React.FC<GalleryBulkPanelProps> = ({
  isDeleteGalleryImagesLoading,
  onDeleteClick,
}) => {
  return (
    <MmsSvgButton
      id={`gallery-bulk-delete`}
      onClick={onDeleteClick}
      className={styles.deleteImageIcon}
      disabled={isDeleteGalleryImagesLoading}
    >
      <Trash id={`gallery-bulk-delete-icon`} size="small" />
      <MmsProgressBar
        id={BULK_DELETE_GALLERY_IMAGES_PROGRESSBAR_ID}
        className={styles.progressBar}
      />
    </MmsSvgButton>
  );
};
