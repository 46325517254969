import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

export const styles = {
  container: css`
    display: flex;

    height: 100%;

    border-radius: ${themeVars.borderRadius};

    background-color: ${themeColors.white};

    overflow: hidden;
  `,
  leftCol: css`
    width: 30%;
    max-width: 30%;
    max-height: 100%;
    padding: 10px;
    overflow: auto;

    background-color: ${themeColors.cream};

    ${themeUtils.scrollBar}
  `,
  rightCol: css`
    width: 70%;
    max-width: 70%;
  `,
  rightColContent: css`
    height: calc(100% - 60px - 90px);
    padding: 15px 35px;

    overflow: auto;

    ${themeUtils.scrollBar}
  `,
  rightColHeader: (isHeaderShadowVisible: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 60px;
    padding: 0 35px;

    ${isHeaderShadowVisible &&
    css`
      box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.1);
    `}
  `,
  rightColHeaderTitle: css`
    min-width: 50%;
    max-width: calc(100% - 115px);
    padding-right: 15px;
  `,
  rightColHeaderControls: css`
    display: flex;
    align-items: center;
    gap: 5px;

    max-width: 115px;
  `,
  rightColFooter: css`
    display: flex;
    align-items: center;

    height: 90px;
    padding: 0 35px;

    box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.1);
  `,
};
