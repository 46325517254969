import {
  FetchResult,
  MutationResult,
  useMutation,
  useReactiveVar,
} from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { KnownErrorCodesEnum } from "@model/helperTypes/errors";
import {
  generateToggleHalalFriendlyVars,
  ToggleHalalFriendlyTypes,
  TOGGLE_HALAL_FRIENDLY,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/ToggleHalalFriendly";
import {
  generateToggleUsesPopularItemsVars,
  ToggleUsesPopularItemsTypes,
  TOGGLE_USES_POPULAR_ITEMS,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/ToggleUsesPopularItems";
import { GET_MENU_STATE } from "@pages/RestaurantMenuPage/graphql/queries/GetMenuState";
import { menuIdVar, restaurantCachedDataVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";
import { useParams } from "react-router";
import {
  generatePublishDraftMenuVars,
  PublishDraftMenuTypes,
  PUBLISH_DRAFT_MENU,
} from "../../graphql/mutatioins/PublishMenu";
import { MatchParams } from "../../RestaurantMenuPage";
import { useGetMenuState } from "./queries";

interface EnhancedMutationResult<TData> extends MutationResult<TData> {
  clearError: VoidFunction;
}

type PublishDraftMenuTuple<TData> = [
  (force: boolean) => Promise<FetchResult<TData>>,
  EnhancedMutationResult<TData>
];

type IPublishDraftMenuHook = () => PublishDraftMenuTuple<PublishDraftMenuTypes.PublishDraftMenuMutation>;

export const usePublishDraftMenu: IPublishDraftMenuHook = () => {
  const { restaurantId } = useParams<MatchParams>();
  const [, menuStateData] = useGetMenuState();
  const [error, { onError, clearError }] = useErrorHandler([
    KnownErrorCodesEnum.publishValidation,
  ]);

  const [mutation, result] = useMutation<
    PublishDraftMenuTypes.PublishDraftMenuMutation,
    PublishDraftMenuTypes.PublishDraftMenuMutationVariables
  >(PUBLISH_DRAFT_MENU, { onError });

  const mutationWithVars = useCallback(
    (force: boolean) => {
      const {
        id: menuId,
        lastUpdatedAt,
        lastUpdatedBy,
      } = menuStateData!.restaurant.state!;

      return mutation({
        variables: generatePublishDraftMenuVars({
          restaurantId,
          menuId,
          force,
          lastUpdatedAt,
          lastUpdatedBy,
        }),
        refetchQueries: [
          { query: GET_MENU_STATE, variables: { restaurantId } },
        ],
      });
    },
    [menuStateData, mutation, restaurantId]
  );

  return [mutationWithVars, { ...result, error, clearError }];
};

type ToggleHalalFriendlyTuple<TData> = [
  (halalFriendly: boolean) => Promise<FetchResult<TData>>,
  MutationResult<TData>
];

type IToggleHalalFriendlyHook = () => ToggleHalalFriendlyTuple<ToggleHalalFriendlyTypes.ToggleHalalFriendlyMutation>;

export const useToggleHalalFriendly: IToggleHalalFriendlyHook = () => {
  const { restaurantId } = useParams<MatchParams>();
  const menuId = useReactiveVar(menuIdVar);
  const [, { onError }] = useErrorHandler([]);

  const [mutation, result] = useMutation<
    ToggleHalalFriendlyTypes.ToggleHalalFriendlyMutation,
    ToggleHalalFriendlyTypes.ToggleHalalFriendlyMutationVariables
  >(TOGGLE_HALAL_FRIENDLY, { onError });

  const mutationWithVars = useCallback(
    (halalFriendly: boolean) => {
      return mutation({
        variables: generateToggleHalalFriendlyVars(
          restaurantId,
          menuId,
          halalFriendly
        ),
        update(cache) {
          cache.modify({
            id: `Menu:${menuId}`,
            fields: {
              halalFriendly() {
                return halalFriendly;
              },
            },
          });
          /* after cache.modify, component wasn't rerendered
          /* because of https://github.com/apollographql/apollo-client/issues/5963
          /* so this hack is workaround while apollo bug is not fixed */
          const restaurantCachedData = restaurantCachedDataVar();
          restaurantCachedDataVar({
            ...restaurantCachedData,
            menu: { ...restaurantCachedData.menu, halalFriendly },
          });
        },
      });
    },
    [menuId, mutation, restaurantId]
  );

  return [mutationWithVars, result];
};

type ToggleUsesPopularItemsTuple<TData> = [
  (usesPopularItems: boolean) => Promise<FetchResult<TData>>,
  MutationResult<TData>
];

type IToggleUsesPopularItemsHook = () => ToggleUsesPopularItemsTuple<ToggleUsesPopularItemsTypes.ToggleUsesPopularItemsMutation>;

export const useToggleUsesPopularItems: IToggleUsesPopularItemsHook = () => {
  const { restaurantId } = useParams<MatchParams>();
  const menuId = useReactiveVar(menuIdVar);
  const [, { onError }] = useErrorHandler([]);

  const [mutation, result] = useMutation<
    ToggleUsesPopularItemsTypes.ToggleUsesPopularItemsMutation,
    ToggleUsesPopularItemsTypes.ToggleUsesPopularItemsMutationVariables
  >(TOGGLE_USES_POPULAR_ITEMS, { onError });

  const mutationWithVars = useCallback(
    (usesPopularItems: boolean) => {
      return mutation({
        variables: generateToggleUsesPopularItemsVars(
          restaurantId,
          menuId,
          usesPopularItems
        ),
        update(cache) {
          cache.modify({
            id: `Menu:${menuId}`,
            fields: {
              usesPopularItems() {
                return usesPopularItems;
              },
            },
          });
          /* after cache.modify, component wasn't rerendered
          /* because of https://github.com/apollographql/apollo-client/issues/5963
          /* so this hack is workaround while apollo bug is not fixed */
          const restaurantCachedData = restaurantCachedDataVar();
          restaurantCachedDataVar({
            ...restaurantCachedData,
            menu: { ...restaurantCachedData.menu, usesPopularItems },
          });
        },
      });
    },
    [menuId, mutation, restaurantId]
  );

  return [mutationWithVars, result];
};
