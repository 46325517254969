import { IReleaseNote } from "@pages/ReleaseNotesPage/withEssentialData";

export const getAllReleases = (): IReleaseNote[] => {
  const context = require.context("../releases", false, /\/\d+.json$/);
  return context
    .keys()
    .sort((a, b) => (a > b ? -1 : 1))
    .map((v) => ({ ...context(v), version: v.replace(/[^0-9]/g, "") }));
};

export const getCurrentReleaseVersion = (): string => {
  return getAllReleases()[0].version;
};
