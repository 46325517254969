import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./AddonRowLayout.styles";

export type IndicatorColorType = "yellow" | "pink";

interface AddonRowLayoutProps {
  indicatorColor: IndicatorColorType;
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}

export const AddonRowLayout: React.FC<AddonRowLayoutProps> = ({
  indicatorColor,
  leftColumn,
  rightColumn,
  ...restProps
}) => {
  return (
    <div {...restProps} className={styles.container}>
      <div className={styles.leftCol}>
        <span
          className={cx(indicatorColor, styles.indicator(indicatorColor))}
        />
        <span className={cx("addonItemTitle", styles.title)}>{leftColumn}</span>
      </div>
      <div className={cx("addonItemPrice", styles.rightCol)}>{rightColumn}</div>
    </div>
  );
};
