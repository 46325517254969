import { cx } from "@emotion/css";
import { DefaultModalPaper } from "@uiKit/Layouts/DefaultModalPaper";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";
import { styles } from "./MenuItemEditDescriptionModal.styles";

interface MenuItemEditDescriptionModalProps {
  closeModal: VoidFunction;
  children: React.ReactNode;
}

export const MenuItemEditDescriptionModal: React.FC<MenuItemEditDescriptionModalProps> = ({
  closeModal,
  children,
}) => (
  <MmsBaseModal
    contentClassName={cx("menuItemDescriptionModal", styles)}
    onClose={closeModal}
  >
    <DefaultModalPaper>{children}</DefaultModalPaper>
  </MmsBaseModal>
);
