import { MmsPopperProps } from "@uiKit/Molecules/Popper/MmsPopper";
import { useCallback, useState } from "react";
import { useOpenClose } from "./useOpenClose";

export const useTogglePopper = (
  initValue: boolean
): [
  MmsPopperProps["anchorEl"],
  boolean,
  (target: MmsPopperProps["anchorEl"]) => void,
  VoidFunction
] => {
  const [isOpen, open, close] = useOpenClose(initValue);
  const [anchorEl, setAnchorEl] = useState<MmsPopperProps["anchorEl"]>(null);

  const onOpen = useCallback(
    (target) => {
      setAnchorEl(target);
      open();
    },
    [open]
  );
  const onClose = useCallback(() => {
    setAnchorEl(null);
    close();
  }, [close]);

  return [anchorEl, isOpen, onOpen, onClose];
};
