import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    position: relative;
    width: 60vw;
    min-width: 750px;
    height: 100%;
  `,
  galleryBulkPanel: css`
    display: flex;
    align-items: center;

    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;

    background-color: ${themeColors.lightGreen};
  `,
  galleryContent: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    width: 100%;
    height: 100%;
    padding: 30px 30px 0 30px;

    background-color: ${themeColors.white};
  `,
};
