import { useMutation } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { SaveToHistoryFormType } from "@model/DAO/History";
import {
  AddToHistoryMutationTypes,
  ADD_TO_HISTORY,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/AddToHistory";
import {
  DestroyRestorableMenuMutationTypes,
  DESTROY_RESTORABLE_MENU,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/DestroyRestorableMenu";
import {
  RestoreMenuMutationTypes,
  RESTORE_MENU,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/RestoreMenu";
import { GET_DRAFT_MENU } from "@pages/RestaurantMenuPage/graphql/queries/GetDraftMenu";
import {
  GetHistoryTypes,
  GET_HISTORY,
} from "@pages/RestaurantMenuPage/graphql/queries/GetHistory";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import produce from "immer";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

export const useAddMenuToHistory = (
  menuId: string
): [(form: SaveToHistoryFormType) => void, boolean, string | undefined] => {
  const { restaurantId } = useParams<MatchParams>();

  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading, data },
  ] = useMutation<AddToHistoryMutationTypes.AddToHistoryMutation>(
    ADD_TO_HISTORY,
    {
      onError,
    }
  );

  const mutationWithVars = useCallback(
    (form: SaveToHistoryFormType) => {
      const { historyTitle, historyDescription } = form;
      mutation({
        variables: {
          input: {
            restaurantId,
            menuId,
            title: historyTitle,
            description: historyDescription,
          },
        },
        refetchQueries: [{ query: GET_HISTORY, variables: { restaurantId } }],
      });
    },
    [menuId, mutation, restaurantId]
  );

  return [mutationWithVars, loading, data?.mmsRestoreAdd?.id];
};

export const useRestoreMenu = (): [
  (menuId: string) => void,
  boolean,
  RestoreMenuMutationTypes.RestoreMenuMutation | null | undefined
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();

  const [
    mutation,
    { loading, data },
  ] = useMutation<RestoreMenuMutationTypes.RestoreMenuMutation>(RESTORE_MENU, {
    onError,
  });

  const mutationWithVars = useCallback(
    (menuId: string) =>
      mutation({
        variables: { input: { restaurantId, menuId } },
        refetchQueries: [
          { query: GET_DRAFT_MENU, variables: { restaurantId } },
        ],
      }),
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading, data];
};

export const useDestroyRestorableMenu = (
  onCompleted: VoidFunction
): [(menuId: string) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();

  const [
    mutation,
    { loading },
  ] = useMutation<DestroyRestorableMenuMutationTypes.DestroyRestorableMenuMutation>(
    DESTROY_RESTORABLE_MENU,
    { onError, onCompleted }
  );

  const mutationWithVars = useCallback(
    (menuId: string) =>
      mutation({
        variables: { input: { restaurantId, menuId } },
        update(cache) {
          const historyData = cache.readQuery<GetHistoryTypes.GetHistoryQuery>({
            query: GET_HISTORY,
            variables: { restaurantId },
          });

          cache.writeQuery<GetHistoryTypes.GetHistoryQuery>({
            query: GET_HISTORY,
            variables: { restaurantId },
            data: produce(historyData!, (newHistoryData) => {
              newHistoryData!.history = historyData!.history.filter(
                ({ id }) => id !== menuId
              );
            }),
          });
        },
      }),
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};
