import {
  useActiveProgressBars,
  useFormErrorsProtection,
  useKeepFocus,
  usePriceEditingMode,
  useTogglePopper,
} from "@hooks";
import { LogAuditableEnum } from "@model/helperTypes/LogFilters";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { generateLibraryProgressBarId } from "@pages/RestaurantMenuPage/utils/Library";
import { generateLogsAssetParams } from "@pages/RestaurantMenuPage/utils/Logs";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink";
import {
  Clock,
  Clone,
  Cloud,
  List,
  Settings,
  Trash,
} from "@uiKit/Atoms/SVGIcons";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsProgressBar } from "@uiKit/Molecules/ProgressBar/MmsProgressBar";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CloudPopper } from "../../../modals/CloudPopper/CloudPopper";
import { styles } from "./CategoryControlsSection.styles";
import { useTranslation } from "react-i18next";

interface CategoryControlsSectionProps {
  onShowCommonSettings: VoidFunction;
  onShowAddons: VoidFunction;
  onDelete: VoidFunction;
  onClone: VoidFunction;
  onImport: VoidFunction;
  onSave: VoidFunction;
  toggleControlsVisible: VoidFunction;
  id: string;
  isFreeItem: boolean;
}

export const CategoryControlsSection: React.FC<CategoryControlsSectionProps> = ({
  onShowCommonSettings,
  onShowAddons,
  onDelete,
  onClone,
  onSave,
  onImport,
  toggleControlsVisible,
  id,
  isFreeItem,
}) => {
  const { t } = useTranslation(["common", "category", "addons"]);

  const { restaurantId } = useParams<MatchParams>();

  const [, setCategoryProgressBarId] = useActiveProgressBars();
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [, focusElemWithId] = useKeepFocus();

  const [
    libraryAnchorEl,
    isLibraryPopperOpen,
    openLibraryPopper,
    closeLibraryPopper,
  ] = useTogglePopper(false);

  const [isPriceEditingMode] = usePriceEditingMode();

  const extraLogsLinkProps = useMemo(
    () => (isPriceEditingMode ? { tabIndex: -1 } : {}),
    [isPriceEditingMode]
  );

  const handleOpenLibraryPopper = useCallback(
    ({ target }) => {
      openLibraryPopper(target);
      toggleControlsVisible();
    },
    [openLibraryPopper, toggleControlsVisible]
  );
  const handleCloseLibraryPopper = useCallback(() => {
    closeLibraryPopper();
    toggleControlsVisible();
  }, [closeLibraryPopper, toggleControlsVisible]);

  const withFocus = useCallback(
    (fn, id) => (...args: any[]) => {
      focusElemWithId(id);
      return fn(...args);
    },
    [focusElemWithId]
  );

  const handleSave = useCallback(() => {
    onSave();
    setCategoryProgressBarId(
      generateLibraryProgressBarId(MenuAssetEnum.category, id)
    );
  }, [id, onSave, setCategoryProgressBarId]);

  return (
    <div className={styles.controlsContainer}>
      <AccessControl
        noAccessComponent={
          <>
            <MmsTooltip title={t("category:edit-settings")}>
              <div>
                <MmsSvgButton
                  onClick={withActiveFormsErrorsProtection(
                    withFocus(
                      onShowCommonSettings,
                      `settings-category-controls-${id}`
                    )
                  )}
                  id={`settings-category-controls-${id}`}
                >
                  <Settings
                    id={`settings-category-controls-${id}-icon`}
                    size="small"
                    className={styles.baseControl}
                  />
                </MmsSvgButton>
              </div>
            </MmsTooltip>
            <MmsTooltip title={t("addons:edit-addons")}>
              <div>
                <MmsSvgButton
                  onClick={withActiveFormsErrorsProtection(
                    withFocus(onShowAddons, `list-category-controls-${id}`)
                  )}
                  id={`list-category-controls-${id}`}
                >
                  <List
                    id={`list-category-controls-${id}-icon`}
                    size="small"
                    className={styles.baseControl}
                  />
                </MmsSvgButton>
              </div>
            </MmsTooltip>
          </>
        }
      >
        <MmsTooltip title={t("common:view-asset-logs")}>
          <div>
            <MmsRouterLink
              id={`category-logs-controls-${id}`}
              href={`/restaurant/${restaurantId}/menu/logs`}
              search={generateLogsAssetParams({
                assetId: id,
                assetType: LogAuditableEnum.MenuCategory,
              })}
              shouldOpenInNewTab
              {...extraLogsLinkProps}
            >
              <Clock
                id={`category-logs-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
            </MmsRouterLink>
          </div>
        </MmsTooltip>
        <MmsTooltip
          title={t("category:save-import-from-my-library")}
          disable={isLibraryPopperOpen}
        >
          <div>
            <MmsSvgButton
              onClick={withActiveFormsErrorsProtection(
                withFocus(
                  handleOpenLibraryPopper,
                  `cloud-category-controls-${id}`
                )
              )}
              id={`cloud-category-controls-${id}`}
            >
              <Cloud
                id={`cloud-category-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
              <MmsProgressBar
                id={generateLibraryProgressBarId(MenuAssetEnum.category, id)}
                className={styles.progressBar}
              />
            </MmsSvgButton>
          </div>
        </MmsTooltip>
        <MmsTooltip title={t("category:edit-settings")}>
          <div>
            <MmsSvgButton
              onClick={withActiveFormsErrorsProtection(
                withFocus(
                  onShowCommonSettings,
                  `settings-category-controls-${id}`
                )
              )}
              id={`settings-category-controls-${id}`}
            >
              <Settings
                id={`settings-category-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
            </MmsSvgButton>
          </div>
        </MmsTooltip>
        {!isFreeItem && (
          <MmsTooltip title={t("category:edit-addons")}>
            <div>
              <MmsSvgButton
                onClick={withActiveFormsErrorsProtection(
                  withFocus(onShowAddons, `list-category-controls-${id}`)
                )}
                id={`list-category-controls-${id}`}
              >
                <List
                  id={`list-category-controls-${id}-icon`}
                  size="small"
                  className={styles.baseControl}
                />
              </MmsSvgButton>
            </div>
          </MmsTooltip>
        )}
        {!isFreeItem && (
          <MmsTooltip title={t("addons:clone-this-asset")}>
            <MmsSvgButton
              onClick={withActiveFormsErrorsProtection(
                withFocus(onClone, `clone-category-controls-${id}`)
              )}
              id={`clone-category-controls-${id}`}
            >
              <Clone
                id={`clone-category-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
            </MmsSvgButton>
          </MmsTooltip>
        )}
        {!isFreeItem && (
          <MmsTooltip title={t("common:delete")}>
            <MmsSvgButton
              onClick={withFocus(onDelete, `trash-category-controls-${id}`)}
              id={`trash-category-controls-${id}`}
            >
              <Trash
                id={`trash-category-controls-${id}-icon`}
                size="small"
                className={styles.baseControl}
              />
            </MmsSvgButton>
          </MmsTooltip>
        )}
        {isLibraryPopperOpen && (
          <CloudPopper
            uniqId={id}
            anchorEl={libraryAnchorEl}
            onClose={handleCloseLibraryPopper}
            onSaveToLibraryClick={handleSave}
            onImportFromLibraryClick={onImport}
            saveToLibraryTooltipTitle={t(
              "category:save-this-entire-category-to-your-library"
            )}
            importFromLibraryTooltipTitle={t(
              "category:import-addon-from-your-library-to-this-menu-item"
            )}
          />
        )}
      </AccessControl>
    </div>
  );
};
