import { useMutation } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { ModelCategoryDNA } from "@model/DAO/MenuCategory";
import {
  BulkPriceUpdateOptionsEnum,
  BulkRequestActionEnum,
} from "@model/helperTypes/BulkEditing";
import { SelectedEntitiesType } from "@model/helperTypes/SelectedEntities";
import {
  BulkDestroyItemsTypes,
  BULK_DESTROY_ITEMS,
  generateBulkDestroyItemsVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/BulkDestroyItems";
import {
  BULK_ENABLE_DISABLE_ITEMS,
  generateBulkEnableDisableItemsVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/BulkEnableDisableItems";
import {
  BulkUpdateAddonItemsPricesTypes,
  BULK_UPDATE_ADDON_ITEMS_PRICES,
  generateBulkUpdateAddonItemsPricesVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/BulkUpdateAddonItemsPrices";
import {
  BulkUpdateAvailabilityTypes,
  BULK_UPDATE_AVAILABILITY,
  generateBulkUpdateAvailabilityVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/BulkUpdateAvailability";
import {
  BulkUpdateDiscountTypes,
  BULK_UPDATE_DISCOUNT,
  generateBulkUpdateDiscountVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/BulkUpdateDiscount";
import {
  BulkUpdateMenuItemPricesTypes,
  BULK_UPDATE_MENU_ITEM_PRICES,
  generateBulkUpdateMenuItemPricesVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/BulkUpdateMenuItemPrices";
import { GET_ALL_CATEGORIES_MENU_ITEMS } from "@pages/RestaurantMenuPage/graphql/queries/GetAllCategoriesMenuItemsQuery";
import { GET_MENU_CATEGORIES } from "@pages/RestaurantMenuPage/graphql/queries/GetMenuCategories";
import { formatMenuFiltersVars } from "@pages/RestaurantMenuPage/utils/MenuFilters";
import {
  getSelectedCategoriesIdsForBulkAddonItemsPriceUpdate,
  getSelectedMenuItemsIdsForBulkEnableDisable,
  getSelectedMenuItemsWithoutSelectedCategory,
} from "@pages/RestaurantMenuPage/utils/selectedEntities";
import { menuFiltersVar, menuIdVar } from "@utils/apolloReactiveVars";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { addUniqThrottledSuccessRegularNotification } from "../../hooks/useSuccessNotifications";
import { MatchParams } from "../../RestaurantMenuPage";

export const useBulkDestroyMutation = (): [
  (selectedEntities: SelectedEntitiesType) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<BulkDestroyItemsTypes.BulkDestroyItemsMutation>(
    BULK_DESTROY_ITEMS,
    { onError }
  );

  const mutationWithVars = useCallback(
    (selectedEntities: SelectedEntitiesType) => {
      const selectedCategoriesIds = selectedEntities.selectedCategories.map(
        ({ id }) => id
      );
      const selectedMenuItemsIds = getSelectedMenuItemsWithoutSelectedCategory(
        selectedEntities
      ).map(({ id }) => id);

      mutation({
        variables: generateBulkDestroyItemsVars(
          restaurantId,
          selectedCategoriesIds,
          selectedMenuItemsIds
        ),
        update(cache) {
          selectedCategoriesIds.forEach((id) =>
            cache.evict({ id: `MenuCategory:${id}` })
          );
          selectedMenuItemsIds.forEach((id) =>
            cache.evict({ id: `MenuItem:${id}` })
          );
        },
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useBulkEnableDisableMutation = (): [
  (
    selectedEntities: SelectedEntitiesType,
    shouldEnable: boolean,
    autoenableTimestamp: number
  ) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<BulkDestroyItemsTypes.BulkDestroyItemsMutation>(
    BULK_ENABLE_DISABLE_ITEMS,
    { onError }
  );

  const mutationWithVars = useCallback(
    (
      selectedEntities: SelectedEntitiesType,
      shouldEnable: boolean,
      autoenableTimestamp: number
    ) => {
      const selectedCategoriesIds = selectedEntities.selectedCategories.map(
        ({ id }) => id
      );
      const selectedMenuItemsIds = getSelectedMenuItemsIdsForBulkEnableDisable(
        selectedEntities
      );

      mutation({
        variables: generateBulkEnableDisableItemsVars(
          restaurantId,
          selectedCategoriesIds,
          selectedMenuItemsIds,
          shouldEnable
            ? BulkRequestActionEnum.enable
            : BulkRequestActionEnum.disable,
          autoenableTimestamp
        ),
        update(cache) {
          if (selectedCategoriesIds.length) {
            selectedCategoriesIds.forEach((id) =>
              cache.modify({
                id: cache.identify({ __typename: "MenuCategory", id }),
                fields: {
                  active() {
                    return shouldEnable;
                  },
                },
              })
            );
          }
          if (selectedMenuItemsIds.length) {
            selectedMenuItemsIds.forEach((id) =>
              cache.modify({
                id: cache.identify({ __typename: "MenuItem", id }),
                fields: {
                  active() {
                    return shouldEnable;
                  },
                },
              })
            );
          }
        },
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useBulkUpdateDiscountMutation = (): [
  (
    selectedEntities: SelectedEntitiesType,
    discount: ModelCategoryDNA["discount"],
    takeawayDiscount: ModelCategoryDNA["takeawayDiscount"],
    deliveryDiscount: ModelCategoryDNA["deliveryDiscount"]
  ) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [selectedCategoriesAmount, setSelectedCategoriesAmount] = useState(0);
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addUniqThrottledSuccessRegularNotification(
        `Discount was updated for ${selectedCategoriesAmount} categories`
      ),
    [selectedCategoriesAmount]
  );

  const [
    mutation,
    { loading },
  ] = useMutation<BulkUpdateDiscountTypes.BulkUpdateDiscountMutation>(
    BULK_UPDATE_DISCOUNT,
    { onError, onCompleted: handleCompleted }
  );

  const mutationWithVars = useCallback(
    (
      selectedEntities: SelectedEntitiesType,
      discount: ModelCategoryDNA["discount"],
      takeawayDiscount: ModelCategoryDNA["takeawayDiscount"],
      deliveryDiscount: ModelCategoryDNA["deliveryDiscount"]
    ) => {
      const selectedCategoriesIds = selectedEntities.selectedCategories.map(
        ({ id }) => id
      );

      const vars = generateBulkUpdateDiscountVars(
        restaurantId,
        selectedCategoriesIds,
        discount,
        takeawayDiscount,
        deliveryDiscount
      );

      setSelectedCategoriesAmount(selectedCategoriesIds.length);

      mutation({
        variables: vars,
        update(cache) {
          if (!selectedCategoriesIds.length) return;

          selectedCategoriesIds.forEach((id) =>
            cache.modify({
              id: cache.identify({ __typename: "MenuCategory", id }),
              fields: {
                discount() {
                  return discount;
                },
                takeawayDiscount() {
                  return takeawayDiscount;
                },
                deliveryDiscount() {
                  return deliveryDiscount;
                },
              },
            })
          );
        },
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useBulkUpdateAvailabilityMutation = (): [
  (
    selectedEntities: SelectedEntitiesType,
    activeFrom: ModelCategoryDNA["activeFrom"],
    activeTo: ModelCategoryDNA["activeTo"]
  ) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [selectedCategoriesAmount, setSelectedCategoriesAmount] = useState(0);
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addUniqThrottledSuccessRegularNotification(
        `Availability was updated for ${selectedCategoriesAmount} categories`
      ),
    [selectedCategoriesAmount]
  );

  const [
    mutation,
    { loading },
  ] = useMutation<BulkUpdateAvailabilityTypes.BulkUpdateAvailabilityMutation>(
    BULK_UPDATE_AVAILABILITY,
    { onError, onCompleted: handleCompleted }
  );

  const mutationWithVars = useCallback(
    (
      selectedEntities: SelectedEntitiesType,
      activeFrom: ModelCategoryDNA["activeFrom"],
      activeTo: ModelCategoryDNA["activeTo"]
    ) => {
      const selectedCategoriesIds = selectedEntities.selectedCategories.map(
        ({ id }) => id
      );

      const vars = generateBulkUpdateAvailabilityVars(
        restaurantId,
        selectedCategoriesIds,
        activeFrom,
        activeTo
      );

      setSelectedCategoriesAmount(selectedCategoriesIds.length);

      mutation({
        variables: vars,
        update(cache) {
          if (!selectedCategoriesIds.length) return;

          selectedCategoriesIds.forEach((id) =>
            cache.modify({
              id: cache.identify({ __typename: "MenuCategory", id }),
              fields: {
                activeFrom() {
                  return activeFrom;
                },
                activeTo() {
                  return activeTo;
                },
              },
            })
          );
        },
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useBulkUpdateMenuItemPricesMutation = (): [
  (
    selectedEntities: SelectedEntitiesType,
    bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum,
    bulkPrice: string
  ) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [selectedMenuItemsAmount, setSelectedMenuItemsAmount] = useState(0);
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addUniqThrottledSuccessRegularNotification(
        `Price was updated for ${selectedMenuItemsAmount} items`
      ),
    [selectedMenuItemsAmount]
  );

  const [
    mutation,
    { loading },
  ] = useMutation<BulkUpdateMenuItemPricesTypes.BulkUpdateMenuItemPricesMutation>(
    BULK_UPDATE_MENU_ITEM_PRICES,
    {
      onError,
      onCompleted: handleCompleted,
    }
  );

  const mutationWithVars = useCallback(
    (
      selectedEntities: SelectedEntitiesType,
      bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum,
      bulkPrice: string
    ) => {
      const selectedMenuItemsIds = selectedEntities.selectedMenuItems.map(
        ({ id }) => id
      );

      setSelectedMenuItemsAmount(selectedMenuItemsIds.length);

      mutation({
        variables: generateBulkUpdateMenuItemPricesVars(
          restaurantId,
          selectedMenuItemsIds,
          bulkPriceUpdateOption,
          bulkPrice
        ),
        update(cache) {
          if (!selectedMenuItemsIds.length) return;

          selectedMenuItemsIds.forEach((id) =>
            cache.modify({
              id: cache.identify({ __typename: "MenuItem", id }),
              fields: {
                price(_, { DELETE }) {
                  return DELETE;
                },
                priceLevel1(_, { DELETE }) {
                  return DELETE;
                },
                priceLevel2(_, { DELETE }) {
                  return DELETE;
                },
                priceLevel3(_, { DELETE }) {
                  return DELETE;
                },
                priceLevel4(_, { DELETE }) {
                  return DELETE;
                },
                priceLevel5(_, { DELETE }) {
                  return DELETE;
                },
              },
            })
          );
        },
        onQueryUpdated(observableQuery) {
          if (observableQuery.queryName !== "GetAllCategoriesMenuItemsQuery")
            return;
          observableQuery.refetch({
            menuId: menuIdVar(),
            filters: formatMenuFiltersVars(menuFiltersVar()),
          });
        },
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useBulkUpdateAddonItemPricesMutation = (): [
  (
    selectedEntities: SelectedEntitiesType,
    bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum,
    bulkPrice: string
  ) => void,
  boolean
] => {
  const { restaurantId } = useParams<MatchParams>();
  const [selectedAssetsAmount, setSelectedAssetsAmount] = useState(0);
  const [, { onError }] = useErrorHandler([]);
  const handleCompleted = useCallback(
    () =>
      addUniqThrottledSuccessRegularNotification(
        `Addon items' prices were updated for ${selectedAssetsAmount} assets`
      ),
    [selectedAssetsAmount]
  );

  const [
    mutation,
    { loading },
  ] = useMutation<BulkUpdateAddonItemsPricesTypes.BulkUpdateAddonItemsPricesMutation>(
    BULK_UPDATE_ADDON_ITEMS_PRICES,
    {
      onError,
      onCompleted: handleCompleted,
    }
  );

  const mutationWithVars = useCallback(
    (
      { selectedCategories, selectedMenuItems }: SelectedEntitiesType,
      bulkPriceUpdateOption: BulkPriceUpdateOptionsEnum,
      bulkPrice: string
    ) => {
      const selectedCategoriesIds = getSelectedCategoriesIdsForBulkAddonItemsPriceUpdate(
        {
          selectedCategories,
          selectedMenuItems,
        }
      );
      const selectedMenuItemsIds = selectedMenuItems.map(({ id }) => id);

      setSelectedAssetsAmount(
        selectedCategories.length + selectedMenuItems.length
      );

      mutation({
        variables: generateBulkUpdateAddonItemsPricesVars(
          restaurantId,
          selectedCategoriesIds,
          selectedMenuItemsIds,
          bulkPriceUpdateOption,
          bulkPrice
        ),
        refetchQueries: [
          {
            query: GET_ALL_CATEGORIES_MENU_ITEMS,
            variables: {
              menuId: menuIdVar(),
              filters: formatMenuFiltersVars(menuFiltersVar()),
            },
          },
          {
            query: GET_MENU_CATEGORIES,
            variables: {
              menuId: menuIdVar(),
              filters: formatMenuFiltersVars(menuFiltersVar()),
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};
