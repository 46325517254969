import { gql } from "@apollo/client";
import { ModelMainCategory } from "@model/DAO/MenuCategory";
import { Levels } from "__generated__/globalTypes";
import { CATEGORY_ESSENTIAL_DATA } from "../../fragments/Category";

export const CREATE_CATEGORY = gql`
  mutation CreateCategoryMutation($input: MenuNodeAddData!) {
    mmsAdd(input: $input) {
      ...CategoryEssentialData
    }
  }
  ${CATEGORY_ESSENTIAL_DATA}
`;

export const generateCreateCategoryVars = (
  restaurantId: string,
  menuId: string,
  { title, active, orderBy, isFreeItem, minBasketValue }: ModelMainCategory
) => ({
  input: {
    restaurantId,
    levelType: "category",
    entityType: Levels.MENU,
    entityId: menuId,
    category: {
      title,
      active,
      orderBy,
      menuFlags: [],
      isFreeItem,
      minBasketValue,
    },
  },
});

export * as CreateCategoryTypes from "./__generated__/CreateCategoryMutation";
