import { cx } from "@emotion/css";
import { useActiveForms, useDidMount } from "@hooks";
import { MmsTextInput } from "@uiKit/Atoms/Inputs/MmsTextInput";
import { validateForm } from "@utils/formik";
import { Form, FormikProps, withFormik } from "formik";
import React from "react";
import { styles } from "./EditTitle.styles";
import { validationMap } from "./EditTitle.validationMap";

interface FormValues {
  title: string;
}

export interface CommonSettingsContentEditTitleProps {
  uniqId: string;
  placeholder: string;
  onPressEsc: VoidFunction;
  onSubmit: (values: any) => void;
}

const CommonSettingsContentEditTitle: React.FC<
  CommonSettingsContentEditTitleProps & FormikProps<FormValues>
> = ({ uniqId, placeholder, onPressEsc, ...formikProps }) => {
  const formId = `common-settings-content-edit-title-${uniqId}`;

  const [, addToActiveForms, removeFromActiveForms] = useActiveForms();

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  return (
    <Form className={styles.form}>
      <MmsTextInput
        data-testid={formId}
        name="title"
        type="text"
        placeholder={placeholder}
        focusOnMount
        showErrorMessage
        onBlur={formikProps.submitForm}
        onPressEsc={onPressEsc}
        fieldClassName={cx("EditAddonTitleInput")}
        containerClassName={styles.input}
      />
    </Form>
  );
};

interface CommonSettingsContentEditTitleFormProps
  extends CommonSettingsContentEditTitleProps {
  title?: string;
}

// Wrap our form with the withFormik HoC
export const CommonSettingsContentEditTitleForm = withFormik<
  CommonSettingsContentEditTitleFormProps,
  FormValues
>({
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    title: props.title || "",
  }),
  validate: validateForm(validationMap),
  handleSubmit: (values, { props }) => {
    props.title === values.title ? props.onPressEsc() : props.onSubmit(values);
  },
})(CommonSettingsContentEditTitle);
