import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = css`
  margin: 10px 0;

  color: ${themeColors.lightGreen};
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
