import { css } from "@emotion/css";
import { themeVars } from "@theme/vars";

export const styles = {
  container: (bgColor: string) => css`
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px 17px 8px 22px;

    border-radius: ${themeVars.borderRadius};
    background-color: ${bgColor};
  `,
  leftCol: css`
    max-width: 65%;
  `,
  rightCol: css``,
};
