import {
  useCommonTopLevelEffects,
  useDidMount,
  useDidUpdate,
  useLogFiltersPagination,
  useOpenClose,
} from "@hooks";
import { Logs } from "@model/DAO/Logs";
import { ModelRestaurant } from "@model/DAO/Restaurant";
import { LogAuditableEnum } from "@model/helperTypes/LogFilters";
import { useRefreshTokenByInterval } from "@pages/Auth/hooks/useRefreshTokenByInterval";
import { useGetDraftMenu } from "@pages/RestaurantMenuPage/gqlHooks/menu/queries";
import MmsLogoSpinnerWithMobileHack from "@uiKit/Atoms/Spinner/MmsLogoSpinner";
import { logFiltersVar } from "@utils/apolloReactiveVars";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGetLogs } from "./gqlHooks/logs/queries";

export interface WithEssentialDataProps {
  restaurant: ModelRestaurant;
  logs: Logs;
  isFetchingMore: boolean;
}

export const withEssentialData = <P,>(
  Component: React.ComponentType<P>
): React.FC<P & WithEssentialDataProps> => (props: P) => {
  useRefreshTokenByInterval();
  useCommonTopLevelEffects();

  const history = useHistory();
  const { location } = history;
  const auditableIdEq =
    new URLSearchParams(location.search).get("assetId") || "";
  const auditableTypeEq =
    (new URLSearchParams(location.search).get(
      "assetType"
    ) as LogAuditableEnum) || undefined;

  const [logFilters, logsPage] = useLogFiltersPagination();

  const [isSpinnerOpen, , closeSpinner] = useOpenClose(true);

  const [menuLoading, menuData] = useGetDraftMenu();
  const [
    { lazyFetchLogs, onGetMoreLogs },
    logsData,
    { logsLoading, isFetchingMore },
  ] = useGetLogs();

  useDidMount(() => {
    const timeoutId = setTimeout(closeSpinner, 1500);
    return () => clearTimeout(timeoutId);
  });

  useEffect(() => {
    if (location.search) {
      lazyFetchLogs({ auditableIdEq, auditableTypeEq });
      logFiltersVar({ auditableIdEq, auditableTypeEq });

      history.replace({ ...location, search: "" });

      return;
    }
    lazyFetchLogs();
  }, [auditableIdEq, auditableTypeEq, history, lazyFetchLogs, location]);

  useDidUpdate(onGetMoreLogs, [logFilters, logsPage]);

  if ((logsLoading && !logsData) || menuLoading || isSpinnerOpen)
    return <MmsLogoSpinnerWithMobileHack />;
  if (!menuData || !logsData) return <div>Page wasn't found</div>;
  return (
    <Component
      restaurant={menuData.restaurant}
      logs={logsData.logs}
      isFetchingMore={isFetchingMore}
      {...props}
    />
  );
};
