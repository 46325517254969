import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { generateLibraryProgressBarId } from "@pages/RestaurantMenuPage/utils/Library";
import {
  MmsSvgButton,
  MmsSvgButtonProps,
} from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Cloud } from "@uiKit/Atoms/SVGIcons";
import { MmsProgressBar } from "@uiKit/Molecules/ProgressBar/MmsProgressBar";
import React from "react";
import { styles } from "./MenuCloudButton.styles";

interface MenuCloudButtonProps extends MmsSvgButtonProps {}

export const MenuCloudButton = React.forwardRef<
  HTMLButtonElement,
  MenuCloudButtonProps
>((props, ref) => {
  return (
    <MmsSvgButton {...props} ref={ref}>
      <Cloud id={`${props.id}-icon`} size="large" />
      <MmsProgressBar
        id={generateLibraryProgressBarId(MenuAssetEnum.menu)}
        className={styles.progressBar}
      />
    </MmsSvgButton>
  );
});
