import {
  ModelCategoryItemsIds,
  ModelFullCategory,
} from "@model/DAO/MenuCategory";
import { ModelCategoryMenuItems, ModelMenuItem } from "@model/DAO/MenuItem";
import {
  SelectedCategoryType,
  SelectedEntitiesType,
  SelectedMenuItemsGroupedByCategoryType,
  SelectedMenuItemType,
} from "@model/helperTypes/SelectedEntities";
import { difference, intersection } from "ramda";

export const getSelectedMenuItemsWithoutSelectedCategory = ({
  selectedCategories,
  selectedMenuItems,
}: SelectedEntitiesType): SelectedMenuItemType[] =>
  selectedMenuItems.filter(
    ({ categoryId }) =>
      !selectedCategories.map(({ id }) => id).includes(categoryId)
  );

export const getSelectedCategoriesWithoutSelectedMenuItems = (
  selectedCategoriesIds: ModelFullCategory["id"][],
  selectedMenuItemsGroupedByCat: SelectedMenuItemsGroupedByCategoryType
): ModelFullCategory["id"][] => {
  const selectedCategoriesIdsWithSelectedMenuItems = Object.keys(
    selectedMenuItemsGroupedByCat
  );

  const selectedCategoriesWithoutSelectedMenuItems = difference(
    selectedCategoriesIds,
    selectedCategoriesIdsWithSelectedMenuItems
  );

  return selectedCategoriesWithoutSelectedMenuItems;
};

export const getSelectedCategoriesWithSelectedMenuItemsThatHasOwnAddons = (
  selectedCategoriesIds: ModelFullCategory["id"][],
  selectedMenuItemsGroupedByCat: SelectedMenuItemsGroupedByCategoryType
): ModelFullCategory["id"][] => {
  let selectedCategoriesIdsWithSelectedMenuItems = Object.entries(
    selectedMenuItemsGroupedByCat
  )
    .filter(([, selectedMenuItemsByCat]) => {
      return selectedMenuItemsByCat.every(({ hasOwnAddons }) => hasOwnAddons);
    })
    .map(([catId]) => catId);

  selectedCategoriesIdsWithSelectedMenuItems = intersection(
    selectedCategoriesIds,
    selectedCategoriesIdsWithSelectedMenuItems
  );

  return selectedCategoriesIdsWithSelectedMenuItems;
};

export const getSelectedMenuItemsIds = (
  selectedMenuItems: SelectedMenuItemType[]
): ModelMenuItem["id"][] => selectedMenuItems.map(({ id }) => id);

export const transformToSelectedCategoryShape = (
  categoryItemsIds: ModelCategoryItemsIds
): SelectedCategoryType => ({
  id: categoryItemsIds.id,
  itemsIds: categoryItemsIds.items.map(({ id }) => id),
});

export const transformToSelectedCategoriesShape = (
  categoriesItemsIds: ModelCategoryItemsIds[]
) =>
  categoriesItemsIds.map((categoryItemsIds) =>
    transformToSelectedCategoryShape(categoryItemsIds)
  );

export const transformToSelectedMenuItemsShape = (
  allCategoriesMenuItems: ModelCategoryMenuItems[]
) =>
  allCategoriesMenuItems.reduce<SelectedMenuItemType[]>(
    (accum, category) => [...accum, ...category.items],
    []
  );

export const getSelectedCategoryById = (
  categoriesItemsIds: ModelCategoryItemsIds[],
  categoryId: ModelFullCategory["id"]
) =>
  transformToSelectedCategoryShape(
    categoriesItemsIds.find(({ id }) => id === categoryId)!
  );

export const getSelectedMenuItemsByCategoryId = (
  allCategoriesMenuItems: ModelCategoryMenuItems[],
  categoryId: ModelFullCategory["id"]
) => {
  return allCategoriesMenuItems.find(({ id }) => id === categoryId)!.items;
};

const groupSelectedMenuItemsByCat = (
  selectedMenuItems: SelectedMenuItemType[]
): SelectedMenuItemsGroupedByCategoryType =>
  selectedMenuItems.reduce(
    (accum: any, item) => ({
      ...accum,
      [item.categoryId]: accum[item.categoryId]
        ? [...accum[item.categoryId], item]
        : [item],
    }),
    {}
  );

// if category has all its menu items selected - then we need to exclude all that menu items from bulk enable/disable action
// if category has at least one deselected menu item - we should count all rest menu items of this category
export const getSelectedMenuItemsIdsForBulkEnableDisable = ({
  selectedMenuItems,
  selectedCategories,
}: SelectedEntitiesType): ModelMenuItem["id"][] => {
  const selectedMenuItemsGroupedByCat = groupSelectedMenuItemsByCat(
    selectedMenuItems
  );

  return Object.entries(selectedMenuItemsGroupedByCat)
    .filter(([catId, selectedMenuItemsByCat]) => {
      const correspondingSelectedCategory = selectedCategories.find(
        ({ id }) => id === catId
      );
      return (
        !correspondingSelectedCategory ||
        correspondingSelectedCategory.itemsIds.length >
          selectedMenuItemsByCat.length
      );
    })
    .map(([, selectedMenuItemsByCat]) => selectedMenuItemsByCat)
    .flat()
    .map(({ id }) => id);
};

// if category includes at least one selected menu item with
// hasOwnAddons: false - then exclude this category from update mutation.
// Instead, update only its menu items
export const getSelectedCategoriesIdsForBulkAddonItemsPriceUpdate = ({
  selectedCategories,
  selectedMenuItems,
}: SelectedEntitiesType): ModelFullCategory["id"][] => {
  const selectedMenuItemsGroupedByCat = groupSelectedMenuItemsByCat(
    selectedMenuItems
  );
  const selectedCategoriesIds = selectedCategories.map(({ id }) => id);

  const selectedCategoriesIdsWithSelectedMenuItems = getSelectedCategoriesWithSelectedMenuItemsThatHasOwnAddons(
    selectedCategoriesIds,
    selectedMenuItemsGroupedByCat
  );

  const selectedCategoriesWithoutSelectedMenuItems = getSelectedCategoriesWithoutSelectedMenuItems(
    selectedCategoriesIds,
    selectedMenuItemsGroupedByCat
  );

  return [
    ...selectedCategoriesIdsWithSelectedMenuItems,
    ...selectedCategoriesWithoutSelectedMenuItems,
  ];
};
