import { useMutation } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { ModelGalleryImage } from "@model/DAO/MenuGallery";
import {
  BulkDeleteGalleryImagesTypes,
  BULK_DELETE_GALLERY_IMAGES,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/BulkDeleteGalleryImages";
import { addMultipleErrorRegularNotifications } from "@pages/RestaurantMenuPage/hooks/useErrorRegularNotifications";
import { addSuccessRegularNotification } from "@pages/RestaurantMenuPage/hooks/useSuccessNotifications";
import produce from "immer";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  DeleteGalleryImageTypes,
  DELETE_GALLERY_IMAGE,
} from "../../graphql/mutatioins/DeleteGalleryImage";
import {
  generateUploadImageToGalleryVars,
  UploadImageToGalleyTypes,
  UPLOAD_IMAGE_TO_GALLERY,
} from "../../graphql/mutatioins/UploadImageToGallery";
import {
  GetRestaurantGalleryQueryTypes,
  GET_RESTAURANT_GALLERY,
} from "../../graphql/queries/GetGallery";
import { MatchParams } from "../../RestaurantMenuPage";

export const useUploadImageToGallery = (): [(file: File) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);

  const { restaurantId } = useParams<MatchParams>();
  const [
    mutation,
    { loading },
  ] = useMutation<UploadImageToGalleyTypes.UploadImageToGalleyMutation>(
    UPLOAD_IMAGE_TO_GALLERY,
    { onError }
  );

  const mutationWithVars = useCallback(
    (file: File) => {
      mutation({
        variables: generateUploadImageToGalleryVars(
          restaurantId,
          file.name,
          file
        ),
        update(cache, { data }) {
          const galleryData = cache.readQuery<GetRestaurantGalleryQueryTypes.GetRestaurantGalleryQuery>(
            {
              query: GET_RESTAURANT_GALLERY,
              variables: { restaurantId, page: 1 },
            }
          );
          cache.writeQuery<GetRestaurantGalleryQueryTypes.GetRestaurantGalleryQuery>(
            {
              query: GET_RESTAURANT_GALLERY,
              variables: { restaurantId, page: 1 },
              data: produce(galleryData!, (newGalleryData) => {
                const newNode = data!.galleryAdd as ModelGalleryImage;
                newGalleryData.gallery.nodes.unshift(newNode);
              }),
            }
          );
        },
      });
    },
    [mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};

export const useDeleteGalleryImage = (): [
  (imageId: string) => void,
  boolean
] => {
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<DeleteGalleryImageTypes.DeleteGalleryImageMutation>(
    DELETE_GALLERY_IMAGE,
    { onError }
  );

  const mutationWithVars = useCallback(
    (deletedImageId: string) => {
      mutation({
        variables: {
          id: deletedImageId,
        },
        update(cache) {
          cache.evict({ id: `Image:${deletedImageId}` });
        },
      });
    },
    [mutation]
  );

  return [mutationWithVars, loading];
};

export const useBulkDeleteGalleryImages = (
  onCompleted: VoidFunction
): [(imagesIds: string[]) => void, boolean] => {
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<BulkDeleteGalleryImagesTypes.BulkDeleteGalleryImagesMutation>(
    BULK_DELETE_GALLERY_IMAGES,
    { onError, onCompleted }
  );

  const mutationWithVars = useCallback(
    (deletedImagesIds: string[]) => {
      mutation({
        variables: {
          ids: deletedImagesIds,
        },
        update(cache, { data }) {
          const { nodes, warnings } = data!.galleryMultipleDelete!;

          nodes.forEach(({ id }) => {
            cache.evict({ id: `Image:${id}` });
          });

          const allErrorMessages = warnings.map(({ message }) => message);

          addMultipleErrorRegularNotifications(allErrorMessages);

          if (nodes.length) {
            addSuccessRegularNotification(
              "The selected images are deleted from Gallery"
            );
          }
        },
      });
    },
    [mutation]
  );

  return [mutationWithVars, loading];
};
