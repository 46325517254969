import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";
import { styles } from "./ScheduleTasksModal.styles";

interface ScheduleTasksModalProps {
  onClose: VoidFunction;
  children: React.ReactNode;
}

export const ScheduleTasksModal: React.FC<ScheduleTasksModalProps> = ({
  onClose,
  children,
}) => {
  return (
    <MmsBaseModal
      contentClassName={styles.scheduleTasksModal}
      onClose={onClose}
      closeOnEscPress
      closeOnOutsideClick
    >
      {children}
    </MmsBaseModal>
  );
};
