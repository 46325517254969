import { DragAndDropOptions } from "@model/helperTypes/dragAndDrop";
import { noop } from "@utils/noop";
import { createContext, useContext } from "react";

export const DragAndDropContext = createContext<DragAndDropOptions<any>>({
  draggableItem: { id: 0, itemData: {} },
  moveDraggableItem: noop,
  onRerankEnd: noop,
  onTransferEnd: noop,
});
export const useDragAndDropContext = () => useContext(DragAndDropContext);
