import { css } from "@emotion/css";
import { themeVars } from "@theme/vars";

export const styles = {
  iconItem: (isSelected: boolean) => css`
    display: inline-block;

    border: ${isSelected ? "2px solid #4177F6" : "2px solid transparent"};
    border-radius: ${themeVars.borderRadius};
  `,
};
