import { useHiddenText } from "@hooks";
import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { AddonsSettingsStrategyEnum } from "@model/helperTypes/AddonsSettings";
import { checkIsAddonTempNew } from "@pages/RestaurantMenuPage/utils/Addon";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Clone, Trash } from "@uiKit/Atoms/SVGIcons";
import { MmsSwitch } from "@uiKit/Atoms/Switch/MmsSwitch";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { noop } from "@utils/noop";
import { getIsPartner } from "@utils/url";
import React, { useCallback, useMemo } from "react";
import { styles } from "./AddonTabPanel.styles";
import { useTranslation } from "react-i18next";

export interface AddonTabPanelProps {
  addonsSettingsStrategy: AddonsSettingsStrategyEnum;
  addon: ModelLocalAddon;
  isCloningDisabled?: boolean;
  isSelected: boolean;
  isUpdateProcessing: boolean;
  onClone: (addonId: string) => void;
  onDelete: (addonId: string) => void;
  onToggle: (addonId: string) => void;
}

export const AddonTabPanel: React.FC<AddonTabPanelProps> = ({
  addonsSettingsStrategy,
  addon,
  isCloningDisabled,
  isSelected,
  isUpdateProcessing,
  onClone,
  onDelete,
  onToggle,
}) => {
  const { t } = useTranslation(["common", "addons"]);

  const isAddonTempNew = useMemo(() => checkIsAddonTempNew(addon.id), [
    addon.id,
  ]);
  const [titleRef, isTooltipOpen] = useHiddenText();

  const handleClone = useCallback(() => {
    onClone(addon.id);
  }, [addon.id, onClone]);

  const handleDelete = useCallback(() => {
    onDelete(addon.id);
  }, [addon.id, onDelete]);

  const handleToggle = useCallback(() => {
    onToggle(addon.id);
  }, [addon.id, onToggle]);

  switch (addonsSettingsStrategy) {
    case AddonsSettingsStrategyEnum.category:
    case AddonsSettingsStrategyEnum.menu_item_attached: {
      return isAddonTempNew ? (
        <JustifyContentBetweenLayout
          data-testid={`addon-tab-panel-${addon.id}`}
        >
          <MmsTooltip title={addon.title} disable={!isTooltipOpen}>
            <div ref={titleRef} className={styles.title}>
              {addon.title || t("addons:new-addon")}
            </div>
          </MmsTooltip>
          <div className={styles.controls}>
            <AccessControl>
              <MmsTooltip title={t("common:delete")}>
                <div>
                  <MmsSvgButton
                    onClick={handleDelete}
                    id={`delete-addon-controls-${addon.id}`}
                    className={styles.baseControl}
                    disabled={isUpdateProcessing}
                  >
                    <Trash
                      id={`delete-addon-controls-${addon.id}-icon`}
                      size="small"
                    />
                  </MmsSvgButton>
                </div>
              </MmsTooltip>
            </AccessControl>
            <MmsTooltip
              title={
                addon.active
                  ? t("common:make-unavailable")
                  : t("common:make-available")
              }
            >
              <div className={styles.baseControl}>
                <MmsSwitch
                  checked={addon.active}
                  onChange={handleToggle}
                  disabled={!isSelected || isUpdateProcessing || getIsPartner()}
                  id={`toggle-addon-controls-${addon.id}`}
                />
              </div>
            </MmsTooltip>
          </div>
        </JustifyContentBetweenLayout>
      ) : (
        <JustifyContentBetweenLayout
          data-testid={`addon-tab-panel-${addon.id}`}
        >
          <MmsTooltip title={addon.title} disable={!isTooltipOpen}>
            <div ref={titleRef} className={styles.title}>
              {addon.title || "Undefined title!!!"}
            </div>
          </MmsTooltip>
          <div className={styles.controls}>
            <AccessControl>
              <MmsTooltip
                title={
                  isCloningDisabled
                    ? t("addons:cannot-clone")
                    : t("addons:clone-this-asset")
                }
              >
                <div>
                  <MmsSvgButton
                    onClick={handleClone}
                    id={`clone-addon-controls-${addon.id}`}
                    className={styles.baseControl}
                    disabled={isCloningDisabled || isUpdateProcessing}
                  >
                    <Clone
                      id={`clone-addon-controls-${addon.id}-icon`}
                      size="small"
                    />
                  </MmsSvgButton>
                </div>
              </MmsTooltip>
              <MmsTooltip title={t("common:delete")}>
                <div>
                  <MmsSvgButton
                    onClick={handleDelete}
                    id={`delete-addon-controls-${addon.id}`}
                    className={styles.baseControl}
                    disabled={isUpdateProcessing}
                  >
                    <Trash
                      id={`delete-addon-controls-${addon.id}-icon`}
                      size="small"
                    />
                  </MmsSvgButton>
                </div>
              </MmsTooltip>
            </AccessControl>
            <MmsTooltip
              title={
                addon.active
                  ? t("common:make-unavailable")
                  : t("common:make-available")
              }
            >
              <div className={styles.baseControl}>
                <MmsSwitch
                  checked={addon.active}
                  onChange={handleToggle}
                  disabled={!isSelected || isUpdateProcessing || getIsPartner()}
                  id={`toggle-addon-controls-${addon.id}`}
                />
              </div>
            </MmsTooltip>
          </div>
        </JustifyContentBetweenLayout>
      );
    }
    case AddonsSettingsStrategyEnum.menu_item_detached: {
      return (
        <JustifyContentBetweenLayout
          data-testid={`addon-tab-panel-${addon.id}`}
        >
          <MmsTooltip title={addon.title} disable={!isTooltipOpen}>
            <div ref={titleRef} className={styles.title}>
              {addon.title}
            </div>
          </MmsTooltip>
          <div className={styles.controls}>
            <MmsTooltip
              title={t("addons:go-to-category-addons-or-disconnect-this-item")}
            >
              <div className={styles.baseControl}>
                <MmsSwitch
                  checked={addon.active}
                  onChange={noop}
                  disabled
                  id={`toggle-addon-controls-${addon.id}`}
                />
              </div>
            </MmsTooltip>
          </div>
        </JustifyContentBetweenLayout>
      );
    }
    default: {
      throw new Error(
        "AddonsSettings -> AddonTabPanel -> render(): Wrong addonsSettingsStrategy"
      );
    }
  }
};
