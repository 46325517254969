import { themeColors } from "@theme/colors";
import React from "react";
import { styles } from "./ToggleAttachButton.styles";

export interface NewStatusBarToggleAttachButtonProps {
  title: string;
  color?: string;
  onClick: VoidFunction;
}

export const NewStatusBarToggleAttachButton: React.FC<NewStatusBarToggleAttachButtonProps> = ({
  title,
  color = themeColors.white,
  onClick,
}) => {
  return (
    <span className={styles(color)} onClick={onClick}>
      {title}
    </span>
  );
};
