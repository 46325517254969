import { cx } from "@emotion/css";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";
import { styles } from "./SaveToHistorySettingsModal.styles";

interface SaveToHistorySettingsModalProps {
  onClose: VoidFunction;
  children: React.ReactNode;
}

export const SaveToHistorySettingsModal: React.FC<SaveToHistorySettingsModalProps> = ({
  onClose,
  children,
}) => {
  return (
    <MmsBaseModal
      contentClassName={cx("saveMenuToHistoryModal", styles.modal)}
      onClose={onClose}
      closeOnOutsideClick={true}
    >
      {children}
    </MmsBaseModal>
  );
};
