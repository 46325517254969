import compassRose from "@assets/png/compass_rose.png";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { styles } from "./Content.styles";

export const PageNotFoundContent = () => {
  const history = useHistory();

  const goToOverview = useCallback(() => {
    history.push("/restaurant");
  }, [history]);

  return (
    <div className={styles.container}>
      <div className={styles.compassRose}>
        <img src={compassRose} alt="compass rose" />
      </div>
      <div className={styles.messageContainer}>
        <h1 className={styles.messageTitle}>We tried, but...</h1>
        <p className={styles.messageDescription}>
          The menu you’re trying to access does not exist
        </p>
      </div>
      <MmsPrimaryButton
        id="go-to-restaurant-overview"
        className={styles.actionButton}
        onClick={goToOverview}
      >
        Go to Restaurant Overview
      </MmsPrimaryButton>
    </div>
  );
};
