import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  icon: css`
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;

    border: 1px solid ${themeColors.lightGreen};
    border-radius: 50%;

    vertical-align: middle;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: ${themeColors.lightGreen};
    }
    &::before {
      width: 2px;
      margin: 3px auto;
    }
    &::after {
      height: 2px;
      margin: auto 3px;
    }
  `,
};
