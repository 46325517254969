import { css } from "@emotion/css";

export const styles = {
  container: css`
    position: absolute;
    display: flex;
    justify-content: center;

    width: 100%;
  `,
};
