import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    height: 100%;
  `,
  formTitle: css`
    margin-top: 12px;
    margin-bottom: 0;
  `,
  errorMessage: css`
    margin-top: 10px;

    color: ${themeColors.red};
    font-size: 12px;
  `,
  priceLevelErrorMessage: css`
    border: 1px solid ${themeColors.red};
    color: ${themeColors.red};
    margin-block: 10px;
    padding: 2px 10px 4px;
    font-size: 12px;
  `,
  colorError: css`
    color: ${themeColors.red};
  `,
  colorWarning: css`
    color: orange;
  `,
  infoLabel: css`
    text-align: center;
    font-size: 14px;
    color: ${themeColors.mediumGray};
  `,
  warningMessage: css`
    margin-top: 10px;
  `,
  additionalWarningMessage: css`
    margin-top: 10px;
    text-decoration: underline;
  `,
};
