import { useReactiveVar } from "@apollo/client";
import { ALL_FILTER_VALUE, LOGS_DEFAULT_PAGE } from "@constants";
import { LogFilters, LogFilterTypesEnum } from "@model/helperTypes/LogFilters";
import { logFiltersVar, logsPageVar } from "@utils/apolloReactiveVars";
import { equals, isEmpty, omit } from "ramda";
import { useCallback } from "react";

export const useLogFiltersPagination = (): [
  LogFilters,
  number,
  {
    onChangeFilters: (filterType: LogFilterTypesEnum, value: any) => void;
    onClearFilters: VoidFunction;
    onChangePage: (e: any, page: number) => void;
  }
] => {
  const logFilters = useReactiveVar(logFiltersVar);

  const onChangeFilters = useCallback(
    (filterType: LogFilterTypesEnum, value: any) => {
      if (equals(value, logFilters[filterType])) return;
      if (!logFilters[filterType] && value === "") return;

      let filtersObj = {};

      if (value === ALL_FILTER_VALUE || !value) {
        filtersObj = omit([filterType], logFilters);
      } else {
        filtersObj = { ...logFilters, [filterType]: value };
      }

      logFiltersVar(filtersObj);
      logsPageVar(LOGS_DEFAULT_PAGE);
    },
    [logFilters]
  );

  const onClearFilters = useCallback(() => {
    if (isEmpty(logFilters)) return;
    logFiltersVar({});
    logsPageVar(LOGS_DEFAULT_PAGE);
  }, [logFilters]);

  const onChangePage = useCallback(
    (e: any, page: number) => logsPageVar(page),
    []
  );

  return [
    useReactiveVar(logFiltersVar),
    useReactiveVar(logsPageVar),
    { onChangeFilters, onClearFilters, onChangePage },
  ];
};
