import compassRose from "@assets/png/compass_rose.png";
import React from "react";
import { styles } from "./Content.styles";

export const ErrorPageContent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.compassRose}>
        <img src={compassRose} alt="compass rose" />
      </div>
      <div className={styles.messageContainer}>
        <h1 className={styles.messageTitle}>We tried, but...</h1>
        <p className={styles.messageDescription}>
          Something went wrong. Please, reload the page or contact our support
          service.
        </p>
      </div>
    </div>
  );
};
