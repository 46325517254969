import { useReactiveVar } from "@apollo/client";
import partnerManual from "@assets/pdf/Menu_Builder_User_Manual_Partner_Centre_V11.pdf";
import adminManual from "@assets/pdf/MMS_User_Manual_V11.pdf";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { MmsRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink";
import { Clock, Globe, Release, UserManual } from "@uiKit/Atoms/SVGIcons";
import { IconedItemLayout } from "@uiKit/Layouts/IconedItemLayout";
import { IconedList } from "@uiKit/Layouts/IconedList";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsBaseMobileModal } from "@uiKit/Molecules/Modals/MmsBaseMobileModal";
import { restaurantCachedDataVar } from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import React from "react";
import { useParams } from "react-router-dom";
import { styles } from "./MoreActions.styles";
import { useTranslation } from "react-i18next";

interface MoreActionsMobileModalProps {
  isMoreActionsModalOpen: boolean;
  closeMoreActionsModal: VoidFunction;
}

export const MoreActionsMobileModal: React.FC<MoreActionsMobileModalProps> = ({
  isMoreActionsModalOpen,
  closeMoreActionsModal,
}) => {
  const { t } = useTranslation(["navigation"]);
  const isPartner = getIsPartner();
  const { restaurantId } = useParams<MatchParams>();
  const { family } = useReactiveVar(restaurantCachedDataVar);

  return (
    <MmsBaseMobileModal
      uniqId="more-actions-mobile-modal"
      contentClassName="moreActionsMobileModal"
      isOpen={isMoreActionsModalOpen}
      closeOnEscPress
      closeOnOutsideClick
      onClose={closeMoreActionsModal}
    >
      <IconedList>
        {family?.url && (
          <AccessControl>
            <MmsRouterLink
              className={styles.link}
              href={
                family?.url?.includes("https://")
                  ? family.url
                  : `https://${family.url}`
              }
              onClick={closeMoreActionsModal}
              shouldOpenInNewTab
            >
              <IconedItemLayout
                itemIcon={
                  <Globe
                    id={`go-to-restaurant-site-${restaurantId}-icon`}
                    size="small"
                  />
                }
                itemTitle={<span>{t("navigation:website")}</span>}
              />
            </MmsRouterLink>
          </AccessControl>
        )}
        <AccessControl>
          <MmsRouterLink
            className={styles.link}
            href={`/restaurant/${restaurantId}/menu/logs`}
            onClick={closeMoreActionsModal}
            shouldOpenInNewTab
          >
            <IconedItemLayout
              itemIcon={
                <Clock
                  id={`more-actions--go-to-menu-logs--icon`}
                  size="small"
                />
              }
              itemTitle={<span>{t("navigation:logs")}</span>}
            />
          </MmsRouterLink>
        </AccessControl>
        <AccessControl>
          <MmsRouterLink
            className={styles.link}
            href={`/restaurant/${restaurantId}/menu/release-notes`}
            onClick={closeMoreActionsModal}
            shouldOpenInNewTab
          >
            <IconedItemLayout
              itemIcon={
                <Release
                  id={`go-to-release-${restaurantId}-icon`}
                  size="small"
                />
              }
              itemTitle={<span>{t("navigation:release-notes")}</span>}
            />
          </MmsRouterLink>
        </AccessControl>
      </IconedList>
    </MmsBaseMobileModal>
  );
};
