import {
  MmsSvgButton,
  MmsSvgButtonProps,
} from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Users } from "@uiKit/Atoms/SVGIcons";
import React from "react";
import { styles } from "./WorkingUsersButton.styles";

interface WorkingUsersButtonProps extends MmsSvgButtonProps {
  workingUsersAmount?: number;
}

export const WorkingUsersButton = React.forwardRef<
  HTMLButtonElement,
  WorkingUsersButtonProps
>(({ workingUsersAmount, ...restProps }, ref) => {
  return (
    <MmsSvgButton {...restProps} ref={ref}>
      <Users id={`${restProps.id}-icon`} size="large" />
      {workingUsersAmount && (
        <div className={styles.workingUsersContainer}>
          <span className={styles.workingUsersAmount}>
            {workingUsersAmount}
          </span>
        </div>
      )}
    </MmsSvgButton>
  );
});
