import {
  useActiveAssets,
  useActiveImportDirection,
  useOpenClose,
} from "@hooks";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { useCallback } from "react";

export const useImportFromLibrary = (
  id: string,
  assetType: MenuAssetEnum
): [boolean, VoidFunction, VoidFunction] => {
  const [
    ,
    setImportDirection,
    cleanImportDirection,
  ] = useActiveImportDirection();

  const [, setAssets, cleanAssets] = useActiveAssets();

  const [
    isLibraryModalOpen,
    openLibraryModal,
    closeLibraryModal,
  ] = useOpenClose(false);

  const handleImport = useCallback(() => {
    openLibraryModal();
    setImportDirection(id, assetType);
    switch (assetType) {
      case MenuAssetEnum.menu: {
        setAssets({ menus: true, categories: true });
        break;
      }
      case MenuAssetEnum.category: {
        setAssets({ menuItems: true, addons: true });
        break;
      }
      default:
        return setAssets({ addons: true });
    }
  }, [assetType, id, openLibraryModal, setAssets, setImportDirection]);

  const handleCloseLibraryModal = useCallback(() => {
    closeLibraryModal();
    cleanAssets();
    cleanImportDirection();
  }, [cleanAssets, cleanImportDirection, closeLibraryModal]);

  return [isLibraryModalOpen, handleImport, handleCloseLibraryModal];
};
