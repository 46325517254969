import { useHiddenText } from "@hooks";
import { themeColors } from "@theme/colors";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./Title.styles";

export interface NewStatusBarTitleProps {
  title: string;
  color?: string;
}

export const NewStatusBarTitle: React.FC<NewStatusBarTitleProps> = ({
  title,
  color = themeColors.white,
}) => {
  const [titleRef, isTooltipOpen] = useHiddenText();

  return (
    <MmsTooltip title={title} disable={!isTooltipOpen}>
      <h2 ref={titleRef} className={styles(color)}>
        {title}
      </h2>
    </MmsTooltip>
  );
};
