import React, { useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";
import { styles } from "../_common/styles";

export interface MmsDummyRouterLinkProps {
  href: LinkProps["to"];
  children: LinkProps["children"];
  disableLinkPreview?: boolean;
}

export const MmsDummyRouterLink: React.FC<MmsDummyRouterLinkProps> = ({
  href,
  children,
  disableLinkPreview,
  ...restProps
}) => {
  const onClick = useCallback((e) => e.preventDefault(), []);
  if (disableLinkPreview) return <>{children}</>;
  return (
    <Link to={href} className={styles.link} onClick={onClick} {...restProps}>
      {children}
    </Link>
  );
};
