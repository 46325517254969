import loader from "@assets/SVG/loader.svg";
import React from "react";
import { withMobileHack } from "src/hocs/withMobileHack/withMobileHack";
import { styles } from "./styles";

export const MmsLogoSpinner: React.FC = () => {
  return (
    <div className={styles.container}>
      <img className={styles.img} src={loader} alt={"Loading..."} />
    </div>
  );
};

export default withMobileHack(MmsLogoSpinner);
