import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";
import { IMAGE_ESSENTIAL_DATA } from "../../fragments/Image";

export const UPDATE_MENU_ITEM_IMAGE = gql`
  mutation UpdateMenuItemImageMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuItem {
        image {
          ...ImageEssentialData
        }
      }
    }
  }
  ${IMAGE_ESSENTIAL_DATA}
`;

export const generateUpdateMenuItemImageVars = (
  restaurantId: string,
  menuItemId: string,
  imageId: string | null
) => ({
  input: {
    restaurantId,
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
    menuItem: {
      imageId,
    },
  },
});

export * as UpdateMenuItemImageTypes from "./__generated__/UpdateMenuItemImageMutation";
