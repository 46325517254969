import TablePagination, {
  TablePaginationProps,
} from "@material-ui/core/TablePagination";
import { isHasMore } from "@utils/pagination";
import React, { useMemo } from "react";
import "./globalStyles";

export interface MmsTablePaginationProps {
  page: TablePaginationProps["page"];
  pageCount: TablePaginationProps["count"];
  offset: number;
  perPage: TablePaginationProps["rowsPerPage"];
  onChangePage: TablePaginationProps["onPageChange"];
  disable?: boolean;
}

export const MmsTablePagination: React.FC<MmsTablePaginationProps> = ({
  page,
  pageCount,
  offset,
  perPage,
  onChangePage,
  disable,
}) => {
  const isBackBtnDisabled = useMemo(() => offset === 0, [offset]);

  const isNextBtnDisabled = useMemo(
    () => pageCount < perPage || !isHasMore(offset, perPage, pageCount),
    [offset, pageCount, perPage]
  );

  return (
    <TablePagination
      className={"MmsTablePagination"}
      component="div"
      count={pageCount}
      rowsPerPage={0}
      page={page}
      onPageChange={onChangePage}
      backIconButtonProps={{ disabled: isBackBtnDisabled || disable }}
      nextIconButtonProps={{ disabled: isNextBtnDisabled || disable }}
    />
  );
};
