import { gql } from "@apollo/client";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import {
  BulkRequestActionEnum,
  LevelsBulkEnum,
} from "@model/helperTypes/BulkEditing";

export const BULK_ENABLE_DISABLE_ITEMS = gql`
  mutation BulkEnableDisableItemsMutation(
    $categoryInput: BulkData!
    $menuItemInput: BulkData!
    $isCategoriesSelected: Boolean!
    $isMenuItemsSelected: Boolean!
  ) {
    enableDisableCategory: mmsBulk(input: $categoryInput)
      @include(if: $isCategoriesSelected)
    enableDisableCategoryMenuItem: mmsBulk(input: $menuItemInput)
      @include(if: $isMenuItemsSelected)
  }
`;

export const generateBulkEnableDisableItemsVars = (
  restaurantId: string,
  selectedCategoriesIds: ModelFullCategory["id"][],
  selectedMenuItemsIds: ModelMenuItem["id"][],
  bulkAction: BulkRequestActionEnum.enable | BulkRequestActionEnum.disable,
  autoenableTimestamp: number
) => ({
  categoryInput: {
    restaurantId,
    bulkAction,
    levelType: LevelsBulkEnum.category,
    ids: selectedCategoriesIds,
    params: {
      delayEnableAt: autoenableTimestamp || null,
    },
  },
  menuItemInput: {
    restaurantId,
    bulkAction,
    levelType: LevelsBulkEnum.menu_item,
    ids: selectedMenuItemsIds,
    params: {
      delayEnableAt: autoenableTimestamp || null,
    },
  },
  isCategoriesSelected: !!selectedCategoriesIds.length,
  isMenuItemsSelected: !!selectedMenuItemsIds.length,
});

export * as BulkEnableDisableItemsTypes from "./__generated__/BulkEnableDisableItemsMutation";
