import { cx } from "@emotion/css";
import { useHiddenText } from "@hooks";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import React from "react";
import { styles } from "./LibraryAssetTitleSection.styles";

interface LibraryAssetTitleSectionProps {
  title: string;
  onClick: VoidFunction;
}

export const LibraryAssetTitleSection: React.FC<LibraryAssetTitleSectionProps> = ({
  title,
  ...rest
}) => {
  const [titleRef, isTooltipOpen] = useHiddenText();
  return (
    <MmsTooltip title={title} disable={!isTooltipOpen}>
      <div
        ref={titleRef}
        className={cx("LibraryAssetTitle", styles.title)}
        {...rest}
      >
        {title}
      </div>
    </MmsTooltip>
  );
};
