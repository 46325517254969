import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  `,
  message: css`
    margin-bottom: 22px;

    color: ${themeColors.black};
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
  `,
  row: css`
    margin-bottom: 18px;
  `,
  footer: css`
    position: absolute;
    right: 35px;
    bottom: 23px;
  `,
};
