import { css } from "@emotion/css";
import { themeVars } from "@theme/vars";

export const styles = {
  skeletonContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1220px;
    height: 80px;
    margin-top: 8px;
    padding: 0 15px;

    border-radius: ${themeVars.borderRadius};

    background-color: white;

    &::first-child {
      margin-top: 0;
    }
  `,
  skeleton: css`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    min-height: 24px;
    margin: 5px;
  `,
  checkboxSkeleton: css`
    max-width: 24px;
  `,
  errorCell: css`
    width: 22px;
    padding-left: 0;
    padding-right: 0;
  `,
  categoryInfoSkeleton: css`
    width: 100%;
    margin-left: 0;
  `,
  controlsGroupSkeleton: css`
    max-width: 156px;
  `,
  toggleSkeleton: css`
    max-width: 43px;
  `,
  chevronSkeleton: css`
    max-width: 27px;
  `,
};
