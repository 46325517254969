import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = css`
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;

  background-color: ${themeColors.borderLightGrey};
`;
