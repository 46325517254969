import { useReactiveVar } from "@apollo/client";
import {
  DOWNLOAD_URLS,
  SCREEN_SIZE_TO_CUT_HEADER_BREADCRUMBS,
} from "@constants";
import {
  useActiveProgressBars,
  useDidUpdate,
  useFormErrorsProtection,
  useOpenClose,
  usePriceEditingMode,
  useTogglePopper,
} from "@hooks";
import { useMediaQuery } from "@material-ui/core";
import { ModelWorkingUser } from "@model/DAO/WorkingUsers";
import { BreadcrumbType } from "@model/helperTypes/Breadcrumb";
import { MenuStateEnum } from "@model/helperTypes/Menu";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { useReleaseReadState } from "@pages/ReleaseNotesPage/hooks/useReleaseReadState";
import HistorySettings from "@pages/RestaurantMenuPage/containers/HistorySettings/HistorySettings";
import { HistoryModal } from "@pages/RestaurantMenuPage/modals/HistoryModal/HistoryModal";
import { HeaderBreadcrumbList } from "@sharedComponents/HeaderBreadcrumbs";
import { HeaderLayout } from "@sharedComponents/HeaderLayout/HeaderLayout";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import {
  headerVisibilityVar,
  iwaiterIdVar,
  menuStateVar,
} from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import json2mq from "json2mq";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { CloudPopper } from "../../modals/CloudPopper/CloudPopper";
import { HeaderMorePopper } from "../../modals/HeaderMorePopper/HeaderMorePopper";
import { PublishingSettingsPopper } from "../../modals/PublishingSettingsPopper/PublishingSettingsPopper";
import { PublishMenuPopper } from "../../modals/PublishMenuPopper/PublishMenuPopper";
import { WorkingUsersPopper } from "../../modals/WorkingUsersPopper/WrokingUsersPopper";
import { generateLibraryProgressBarId } from "../../utils/Library";
import { MenuCloudButton } from "./buttons/MenuCloudButton/MenuCloudButton";
import { PublishMenuButton } from "./buttons/PublishMenuButton/PublishMenuButton";
import { RestoreMenuButton } from "./buttons/RestoreMenuButton/RestoreMenuButton";
import { ShowMoreButton } from "./buttons/ShowMoreButton/ShowMoreButton";
import { WorkingUsersButton } from "./buttons/WorkingUsersButton/WorkingUsersButton";
import { styles } from "./MenuHeader.styles";
import { useTranslation } from "react-i18next";

export interface MenuHeaderProps {
  name: string;
  status: string | null;
  publishValidationErrorMessage: string | undefined;
  workingUsers?: ModelWorkingUser[];
  isPublished: boolean;
  isBlockingErrorsAppeared: boolean;
  isPublishInProgress: boolean;
  isUploadMenuFromFileLoading: boolean;
  onSaveToLibrary: VoidFunction;
  onImportFromLibrary: VoidFunction;
  onPublishMenu: (force: boolean) => void;
  onPublishMenuWithHistory: (force: boolean) => void;
  onClosePublishValidationErrorMessage: VoidFunction;
  onUploadMenuFromFile: (file: File) => void;
  modals: React.ReactNode;
}

export const MenuHeader: React.FC<MenuHeaderProps> = ({
  name: restaurantName,
  status: restaurantStatus,
  publishValidationErrorMessage,
  workingUsers,
  isPublished,
  isBlockingErrorsAppeared,
  isPublishInProgress,
  isUploadMenuFromFileLoading,
  onSaveToLibrary,
  onImportFromLibrary,
  onPublishMenu,
  onPublishMenuWithHistory,
  onClosePublishValidationErrorMessage,
  onUploadMenuFromFile,
  modals,
}) => {
  const { t } = useTranslation(["common", "navigation", "library", "history"]);
  const publishMenuRef = useRef(null);
  const workingUsersBtnRef = useRef(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const headerVisibility = useReactiveVar(headerVisibilityVar);
  const iwaiterId = useReactiveVar(iwaiterIdVar);
  const menuState = useReactiveVar(menuStateVar);
  const [isReleaseRead] = useReleaseReadState();
  const isPartner = getIsPartner();

  const isCutBreadcrumbs = useMediaQuery(
    json2mq({
      minWidth: SCREEN_SIZE_TO_CUT_HEADER_BREADCRUMBS,
    })
  );

  const [
    libraryAnchorEl,
    isLibraryPopperOpen,
    openLibraryPopper,
    closeLibraryPopper,
  ] = useTogglePopper(false);

  const [
    publishingSettingsAnchorEl,
    isPublishingSettingsPopperOpen,
    openPublishingSettingsPopper,
    closePublishingSettingsPopper,
  ] = useTogglePopper(false);

  const [
    publishMenuAnchorEl,
    isPublishMenuPopperOpen,
    openPublishMenuPopper,
    closePublishMenuPopper,
  ] = useTogglePopper(false);

  const [
    moreOptionsAnchorEl,
    isHeaderMorePopperOpen,
    openHeaderMorePopper,
    closeHeaderMorePopper,
  ] = useTogglePopper(false);

  const [
    historyModalAnchorEl,
    isHistoryModalOpen,
    openHistoryModal,
    closeHistoryModal,
  ] = useTogglePopper(false);

  const [
    isImportMenuFromFileConfirmationModalOpen,
    openImportMenuFromFileConfirmationModal,
    closeImportMenuFromFileConfirmationModal,
  ] = useOpenClose(false);

  const [isEditingMode] = usePriceEditingMode();

  const extraBreadcrumbsProps = useMemo(
    () => (isEditingMode ? { tabIndex: -1 } : {}),
    [isEditingMode]
  );

  const [, setMenuProgressBarId] = useActiveProgressBars();
  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const handleOpenCloudPopper = useCallback(
    ({ target }) => openLibraryPopper(target),
    [openLibraryPopper]
  );

  const handleOpenMoreOptionsPopper = useCallback(
    ({ target }) => openHeaderMorePopper(target),
    [openHeaderMorePopper]
  );

  const handleOpenHistoryModal = useCallback(
    ({ target }) => openHistoryModal(target),
    [openHistoryModal]
  );

  const proceedImportMenuFromFile = useCallback(() => {
    closeImportMenuFromFileConfirmationModal();
    uploadInputRef.current?.click();
  }, [closeImportMenuFromFileConfirmationModal]);

  const handleOpenPublishingSettingsPopper = useCallback(
    ({ target }) => openPublishingSettingsPopper(target),
    [openPublishingSettingsPopper]
  );

  const handleSaveMenuToLibrary = useCallback(() => {
    setMenuProgressBarId(generateLibraryProgressBarId(MenuAssetEnum.menu));
    onSaveToLibrary();
  }, [onSaveToLibrary, setMenuProgressBarId]);

  const handlePublishMenu = useCallback(() => {
    closePublishMenuPopper();
    onPublishMenu(false);
  }, [closePublishMenuPopper, onPublishMenu]);

  const handlePublishMenuAnyway = useCallback(() => {
    closePublishMenuPopper();
    onPublishMenu(true);
  }, [closePublishMenuPopper, onPublishMenu]);

  const handlePublishWithHistory = useCallback(() => {
    closePublishMenuPopper();
    onPublishMenuWithHistory(false);
  }, [closePublishMenuPopper, onPublishMenuWithHistory]);

  const handleClosePublishValidationErrorMessage = useCallback(() => {
    closePublishMenuPopper();
    onClosePublishValidationErrorMessage();
  }, [closePublishMenuPopper, onClosePublishValidationErrorMessage]);

  const handleImportMenuFromFileClick = useCallback(() => {
    if (menuState === MenuStateEnum.showEmptyMenuBanner) {
      proceedImportMenuFromFile();
    } else {
      openImportMenuFromFileConfirmationModal();
    }
  }, [
    menuState,
    openImportMenuFromFileConfirmationModal,
    proceedImportMenuFromFile,
  ]);

  const handleUploadMenuFromFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      if (!file) return;

      onUploadMenuFromFile(file);
      setMenuProgressBarId(generateLibraryProgressBarId(MenuAssetEnum.menu));
    },
    [onUploadMenuFromFile, setMenuProgressBarId]
  );

  const handleDownloadCsv = useCallback(() => {
    document.location.href = `${DOWNLOAD_URLS.dataToolUrl}/?u=${DOWNLOAD_URLS.mmsUrl}/api/v1/restaurant/${iwaiterId}/menu`;
  }, [iwaiterId]);

  const [
    workingUsersAnchorEl,
    isWorkingUsersPopperOpen,
    openWorkingUsersPopper,
    closeWorkingUsersPopper,
  ] = useTogglePopper(false);

  const handleOpenWorkingUsersPopper = useCallback(() => {
    if (!workingUsersBtnRef.current) return;
    openWorkingUsersPopper(workingUsersBtnRef.current);
  }, [openWorkingUsersPopper]);

  const workingUsersAmountExcludingMe = useMemo(() => {
    return workingUsers?.filter((user) => !user.current).length || 0;
  }, [workingUsers]);

  const getBreadcrumbsData = (
    iwaiterId: number,
    restaurantName: string,
    isCutBreadcrumbs: boolean
  ) => {
    return [
      isCutBreadcrumbs && {
        label: t("navigation:restaurants"),
        href: `${process.env.REACT_APP_MMS2_CURRENT_ADMIN_APP_URL}`,
      },
      isCutBreadcrumbs && {
        name: "restaurantName",
        label: restaurantName,
        href: `${process.env.REACT_APP_MMS2_CURRENT_ADMIN_APP_URL}Restaurant/Edit/${iwaiterId}`,
      },
      { label: t("navigation:menu") },
    ].filter(Boolean) as BreadcrumbType[];
  };

  useEffect(() => {
    if (!workingUsersAmountExcludingMe && isWorkingUsersPopperOpen) {
      closeWorkingUsersPopper();
    }
  }, [
    closeWorkingUsersPopper,
    isWorkingUsersPopperOpen,
    workingUsersAmountExcludingMe,
  ]);

  useDidUpdate(() => {
    if (!publishValidationErrorMessage) return;
    openPublishMenuPopper(publishMenuRef.current);
  }, [publishValidationErrorMessage]);

  return (
    <>
      <HeaderLayout
        wrapperClassName={styles.headerLayout(headerVisibility)}
        leftCol={
          <AccessControl>
            {/* mobile view */}
            {/* <div className="d-flex d-lg-block justify-content-center align-items-center flex-column"> */}
            <div>
              <HeaderBreadcrumbList
                breadcrumbsData={getBreadcrumbsData(
                  iwaiterId,
                  restaurantName,
                  isCutBreadcrumbs
                )}
                restaurantStatus={restaurantStatus}
                {...extraBreadcrumbsProps}
              />
              {/* mobile view */}
              {/* <RestaurantName
                restaurantName={restaurantName}
                className="d-block d-lg-none"
              /> */}
            </div>
          </AccessControl>
        }
        rightCol={
          <>
            {/* mobile view */}
            {/* <div className="d-none d-lg-flex align-items-center"> */}
            {/* desktop view */}
            <div className="d-flex align-items-center">
              {/* desktop view */}
              <div className="d-none d-lg-block">
                {!!workingUsersAmountExcludingMe && (
                  <WorkingUsersButton
                    onClick={handleOpenWorkingUsersPopper}
                    id={`working-user-list-header-btn`}
                    className={styles.baseControl}
                    ref={workingUsersBtnRef}
                    workingUsersAmount={workingUsers?.length}
                  />
                )}
              </div>
              <AccessControl>
                <MmsTooltip
                  title={t("library:save-to-library")}
                  disable={isLibraryPopperOpen}
                >
                  <div>
                    <MenuCloudButton
                      onClick={withActiveFormsErrorsProtection(
                        handleOpenCloudPopper
                      )}
                      id={`menu-cloud-header-btn`}
                      className={styles.baseControl}
                    />
                  </div>
                </MmsTooltip>
                <MmsTooltip
                  title={t("history:restore-menus-from-history")}
                  disable={isHistoryModalOpen}
                >
                  <div>
                    <RestoreMenuButton
                      onClick={withActiveFormsErrorsProtection(
                        handleOpenHistoryModal
                      )}
                      id={`restore-menu-header-btn`}
                      className={styles.baseControl}
                    />
                  </div>
                </MmsTooltip>
              </AccessControl>
              <MmsTooltip
                title={
                  isPublished
                    ? t("menu:this-version-is-already-published")
                    : t("menu:publish-this-draft")
                }
                disable={
                  isBlockingErrorsAppeared ||
                  isPublishInProgress ||
                  isPublishMenuPopperOpen ||
                  isPublishingSettingsPopperOpen
                }
              >
                <div>
                  <PublishMenuButton
                    onClick={withActiveFormsErrorsProtection(
                      isPartner
                        ? handlePublishMenu
                        : handleOpenPublishingSettingsPopper
                    )}
                    id={`publish-menu-header-btn`}
                    className={styles.baseControl}
                    ref={publishMenuRef}
                    disabled={
                      isBlockingErrorsAppeared ||
                      isPublishInProgress ||
                      isPublished
                    }
                    isPublished={isPublished}
                  />
                </div>
              </MmsTooltip>
              {/* desktop view */}
              <div className="d-none d-lg-block">
                <ShowMoreButton
                  onClick={withActiveFormsErrorsProtection(
                    handleOpenMoreOptionsPopper
                  )}
                  id={`more-menu-header-btn`}
                  className={styles.baseControl}
                  marker={!isPartner && !isReleaseRead}
                />
              </div>
              {isLibraryPopperOpen && (
                <CloudPopper
                  uniqId={`${iwaiterId}`}
                  className={styles.libraryPopper}
                  anchorEl={libraryAnchorEl}
                  isPopperOwnerMenu
                  saveToLibraryTooltipTitle={t(
                    "menu:save-this-entire-menu-to-your-library"
                  )}
                  importFromLibraryTooltipTitle={t(
                    "menu:import-full-menus-or-categories-from-your-library-to-this-menu"
                  )}
                  importMenuTooltipTitle={t(
                    "menu:import-menu-by-uploading-the-json-file"
                  )}
                  downloadMenuTooltipTitle={t("menu:download-menu-to-csv-file")}
                  onClose={closeLibraryPopper}
                  onSaveToLibraryClick={handleSaveMenuToLibrary}
                  onImportFromLibraryClick={onImportFromLibrary}
                  onImportMenuClick={handleImportMenuFromFileClick}
                  onDownloadMenuCsv={handleDownloadCsv}
                />
              )}
              {isWorkingUsersPopperOpen && (
                <WorkingUsersPopper
                  id={"working-users-popper"}
                  anchorEl={workingUsersAnchorEl}
                  handleClosePopper={closeWorkingUsersPopper}
                  workingUsers={workingUsers}
                />
              )}
              {isPublishingSettingsPopperOpen && (
                <PublishingSettingsPopper
                  id={`publishing-settings-popper-${iwaiterId}`}
                  className={styles.publishMenuPopper}
                  anchorEl={publishingSettingsAnchorEl}
                  onPublish={withActiveFormsErrorsProtection(handlePublishMenu)}
                  onPublishWithHistory={withActiveFormsErrorsProtection(
                    handlePublishWithHistory
                  )}
                  onClose={closePublishingSettingsPopper}
                />
              )}
              {isPublishMenuPopperOpen && (
                <PublishMenuPopper
                  id={`${iwaiterId}`}
                  className={styles.publishMenuPopper}
                  anchorEl={publishMenuAnchorEl}
                  message={ReactHtmlParser(publishValidationErrorMessage!)}
                  onClose={handleClosePublishValidationErrorMessage}
                  onPublishAnyway={handlePublishMenuAnyway}
                />
              )}
              {isHeaderMorePopperOpen && (
                <HeaderMorePopper
                  id={"header-more-options-popper"}
                  anchorEl={moreOptionsAnchorEl}
                  onClose={closeHeaderMorePopper}
                  releaseMarker={!isPartner && !isReleaseRead}
                />
              )}
              {isHistoryModalOpen && (
                <HistoryModal
                  id={"history-settings-modal"}
                  anchorEl={historyModalAnchorEl}
                  onClose={closeHistoryModal}
                >
                  <HistorySettings onCancel={closeHistoryModal} />
                </HistoryModal>
              )}
              {isImportMenuFromFileConfirmationModalOpen && (
                <MmsConfirmationModal
                  message={t("menu:the-current-menu-assets-will-be-deleted")}
                  confirmBtnText={t("menu:proceed")}
                  declineBtnText={t("common:cancel")}
                  onConfirmBtnClick={proceedImportMenuFromFile}
                  onDeclineBtnClick={closeImportMenuFromFileConfirmationModal}
                />
              )}
            </div>

            {/* mobile view */}
            {/* <MmsSvgButton
              onClick={noop}
              id={`open-mobile-menu-filters-panel`}
              className={cx(styles.filterBtn, "d-lg-none")}
            >
              <ApplyFilters color={themeColors.black} />
            </MmsSvgButton> */}
          </>
        }
      />
      {!isUploadMenuFromFileLoading && (
        <input
          ref={uploadInputRef}
          accept="application/JSON"
          className={styles.uploadInput}
          type="file"
          id="upload-menu-input"
          onChange={handleUploadMenuFromFile}
        />
      )}
      {modals}
    </>
  );
};
