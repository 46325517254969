import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const DESTROY_MENU_ITEM = gql`
  mutation DestroyMenuItemMutation($input: DestroyData!) {
    mmsDestroy(input: $input)
  }
`;

export const generateDestroyMenuItemVars = (
  restaurantId: string,
  menuItemId: string
) => ({
  input: {
    restaurantId,
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
  },
});

export * as DestroyMenuItemTypes from "./__generated__/DestroyMenuItemMutation";
