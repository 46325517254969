import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { useMemo } from "react";
import { getActiveEntities } from "../utils/GenericEntity";

type ReturnTupleType = [ModelLocalAddon | undefined, boolean];

export const usePriceLevelAddon = (
  localAddons: ModelLocalAddon[]
): ReturnTupleType => {
  const activePriceLevelAddon = useMemo(() => {
    return getActiveEntities(localAddons).find(({ priceLevel }) => priceLevel);
  }, [localAddons]);

  const isPriceLevelAddonAvailable = useMemo(() => !activePriceLevelAddon, [
    activePriceLevelAddon,
  ]);

  return [activePriceLevelAddon, isPriceLevelAddonAvailable];
};
