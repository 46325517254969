import { MenuFooter } from "@pages/RestaurantMenuPage/components/MenuFooter/MenuFooter";
import { usePollNotificationData } from "@pages/RestaurantMenuPage/hooks/useReceiveNotificationsDataByInterval";
import React from "react";

interface MenuHeaderContainerProps {}

export const MenuFooterContainer: React.FC<MenuHeaderContainerProps> = () => {
  const [, workingUsers] = usePollNotificationData();

  return <MenuFooter workingUsers={workingUsers} />;
};
