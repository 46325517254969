import { gql } from "@apollo/client";
import { ModelCategoryDNA } from "@model/DAO/MenuCategory";
import { Levels } from "__generated__/globalTypes";

export const EDIT_CATEGORY_DISCOUNT_N_AVAILABILITY = gql`
  mutation EditCategoryDNAMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuCategory {
        id
        title
        discount
        takeawayDiscount
        deliveryDiscount
        activeFrom
        activeTo
        disabledOnDays
        isFreeItem
        minBasketValue
        active
      }
    }
  }
`;

export const generateEditCategoryDNAVars = (
  restaurantId: string,
  categoryId: string,
  { id, discount, ...newDNA }: ModelCategoryDNA
) => {
  return {
    input: {
      restaurantId,
      entityType: Levels.CATEGORY,
      entityId: categoryId,
      category: {
        discount,
        ...newDNA,
      },
    },
  };
};

export * as EditCategoryDNATypes from "./__generated__/EditCategoryDNAMutation";
