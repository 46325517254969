import { useReactiveVar } from "@apollo/client";
import { cx } from "@emotion/css";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { ModelMainCategory } from "@model/DAO/MenuCategory";
import { separatePriceLevelAddonFromRest } from "@pages/RestaurantMenuPage/utils/Addon";
import { getActiveEntities } from "@pages/RestaurantMenuPage/utils/GenericEntity";
import { MmsBaseButton } from "@uiKit/Atoms/Buttons/MmsBaseButton";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { menuFiltersVar } from "@utils/apolloReactiveVars";
import React, { useCallback, useMemo } from "react";
import { styles } from "./CategoryAddonsSection.styles";
import { useTranslation } from "react-i18next";
import { getEnabledDays } from "@pages/RestaurantMenuPage/utils/Discount&Availability";

interface CategoryDiscount {
  discount: number;
  title: string;
  isDiscountApplicable: boolean;
}

interface CategoryAddonsSectionProps {
  addons: ModelMenuAddon[];
  discount: ModelMainCategory["discount"];
  takeawayDiscount: ModelMainCategory["takeawayDiscount"];
  deliveryDiscount: ModelMainCategory["deliveryDiscount"];
  onShowMoreAddons: VoidFunction;
  categoryId: ModelMainCategory["id"];
  activeFrom: string | null;
  activeTo: string | null;
  disabledOnDays: number | null;
}

export const CategoryAddonsSection: React.FC<CategoryAddonsSectionProps> = ({
  addons,
  discount,
  takeawayDiscount,
  deliveryDiscount,
  onShowMoreAddons,
  categoryId,
  activeFrom,
  activeTo,
  disabledOnDays,
}) => {
  const { t } = useTranslation(["discounts", "addons", "common"]);

  const menuFilters = useReactiveVar(menuFiltersVar);
  const activeAddons = useMemo(() => getActiveEntities(addons), [addons]);

  const [restAddons, priceLevelAddon] = useMemo(
    () => separatePriceLevelAddonFromRest(activeAddons),
    [activeAddons]
  );

  const checkIsTitleHighlighted = useCallback(
    (title: string) => {
      if (menuFilters.titleCont === undefined) return false;
      return title.toLowerCase().includes(menuFilters.titleCont.toLowerCase());
    },
    [menuFilters.titleCont]
  );

  const discounts: CategoryDiscount[] = [
    {
      discount: takeawayDiscount || 0,
      title: t("discounts:takeaway"),
      isDiscountApplicable: true,
    },
    {
      discount: deliveryDiscount || 0,
      title: t("discounts:delivery-discount"),
      isDiscountApplicable: true,
    },
    {
      discount: discount || 0,
      title: t("discounts:common-discount"),
      isDiscountApplicable: true,
    },
  ].filter((d) => d.isDiscountApplicable && !!d.discount);

  const enabledDays = getEnabledDays(disabledOnDays)
    .map((day) => day.nameShort)
    .join(", ");

  return (
    <div data-testid="category-addon-container">
      {discounts.map((d) => (
        <div key={d.title} className={styles.row}>
          <div className={styles.addonIndicator} />
          <div
            data-testid="category-discount-title"
            className={styles.addonItemsTitleList}
          >
            {d.title}
          </div>
          <div
            data-testid="category-discount-value"
            className={styles.discountValue}
          >
            {`${d.discount}%`}
          </div>
        </div>
      ))}
      {activeFrom && activeTo && (
        <div className={styles.row}>
          <div className={styles.addonIndicator} />
          <div
            data-testid="category-availability-title"
            className={styles.addonItemsTitleList}
          >
            {t("discounts:available-hours")}
          </div>
          <div
            data-testid="category-availability-value"
            className={styles.discountValue}
          >
            {activeFrom} - {activeTo}
          </div>
        </div>
      )}
      {!!disabledOnDays && (
        <div className={styles.row}>
          <div className={styles.addonIndicator} />
          <div className={styles.addonItemsTitleList}>
            {t("discounts:available-days")}
          </div>
          <div className={styles.discountValue}>
            {enabledDays || t("discounts:available-days-none")}
          </div>
        </div>
      )}
      {priceLevelAddon && (
        <div data-testid="category-addon--price-level" className={styles.row}>
          <div className={styles.addonIndicator} />
          <div className={cx("addonItemTitle", styles.addonItemsTitleList)}>
            {getActiveEntities(priceLevelAddon.items).map(
              ({ title }, index, arr) => {
                const isHighlighted = checkIsTitleHighlighted(title);

                return (
                  <React.Fragment key={index}>
                    <span
                      className={styles.singleAddonItemTitle(isHighlighted)}
                    >
                      {title}
                    </span>
                    {index < arr.length - 1 && ", "}
                  </React.Fragment>
                );
              }
            )}
          </div>
        </div>
      )}
      {priceLevelAddon && !!restAddons.length && (
        <MmsTooltip title={t("addons:edit-addons")}>
          <MmsBaseButton
            id={`btn-list-category-controls-${categoryId}`}
            className={styles.extraAddons}
            onClick={onShowMoreAddons}
          >
            +{restAddons.length} {t("common:more")}
          </MmsBaseButton>
        </MmsTooltip>
      )}
    </div>
  );
};
