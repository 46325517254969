import { BreadcrumbType } from "@model/helperTypes/Breadcrumb";
import { MmsBreadcrumbs } from "@uiKit/Atoms/Breadcrumbs/MmsBreadcrumbs";
import React from "react";
import { HeaderBreadcrumbItem } from "..";

interface HeaderBreadcrumbListProps {
  breadcrumbsData: BreadcrumbType[];
  restaurantStatus: string | null;
}

export const HeaderBreadcrumbList: React.FC<HeaderBreadcrumbListProps> = ({
  breadcrumbsData,
  restaurantStatus,
  ...restBreadcrumbsProps
}) => {
  return (
    <MmsBreadcrumbs
      data={breadcrumbsData}
      renderBreadcrumbs={(breadcrumb) => (
        <HeaderBreadcrumbItem
          breadcrumbData={breadcrumb}
          restaurantStatus={restaurantStatus}
        />
      )}
      {...restBreadcrumbsProps}
    />
  );
};
