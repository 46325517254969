import { useQuery } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { useParams } from "react-router-dom";
import { GetHistoryTypes, GET_HISTORY } from "../../graphql/queries/GetHistory";

export const useGetHistory = (): [
  boolean,
  GetHistoryTypes.GetHistoryQuery | undefined
] => {
  const [, { onError }] = useErrorHandler([]);
  const { restaurantId } = useParams<MatchParams>();

  const { loading, data } = useQuery<GetHistoryTypes.GetHistoryQuery>(
    GET_HISTORY,
    { variables: { restaurantId }, onError }
  );

  return [loading, data];
};
