import { AVAILABILITY_OPTIONS } from "@constants";
import { useDidUpdate, useForceReloadOnUpdate } from "@hooks";
import { ModelCategoryDNA } from "@model/DAO/MenuCategory";
import {
  AvailableEnum,
  LocalAvailabilityData,
} from "@model/helperTypes/Discount&Availability";
import {
  decomposeLocalAvailabilityData,
  getInitLocalAvailabilityData,
} from "@pages/RestaurantMenuPage/utils/Discount&Availability";
import React, { useCallback, useState } from "react";
import { AvailabilityForm } from "./AvailabilityForm/AvailabilityForm";
import { DiscountForm } from "./DiscountForm/DiscountForm";
import { DiscountAndAavailabilityLayout } from "./Layout/Layout";
import { useTranslation } from "react-i18next";

interface DiscountAndAavailabilityProps {
  categoryDNA: ModelCategoryDNA;
  preserveLocalDNA: (newCategoryDNA: ModelCategoryDNA) => void;
  isDiscountTab: boolean;
}

export const DiscountAndAvailability: React.FC<DiscountAndAavailabilityProps> = ({
  categoryDNA: {
    id: categoryId,
    discount,
    takeawayDiscount,
    deliveryDiscount,
    activeFrom,
    activeTo,
    disabledOnDays,
    isFreeItem,
    minBasketValue,
  },
  preserveLocalDNA,
  isDiscountTab,
}) => {
  const { t } = useTranslation(["discounts"]);

  const [localDiscount, setLocalDiscount] = useState<string>(
    discount ? String(discount) : ""
  );
  const [localTakeawayDiscount, setlocalTakeawayDiscount] = useState<string>(
    takeawayDiscount ? String(takeawayDiscount) : ""
  );
  const [localDeliveryDiscount, setLocalDeliveryDiscount] = useState<string>(
    deliveryDiscount ? String(deliveryDiscount) : ""
  );
  const [
    localAvailabilityData,
    setLocalAvailabilityData,
  ] = useState<LocalAvailabilityData>(
    getInitLocalAvailabilityData(activeFrom, activeTo, disabledOnDays)
  );

  const handleChangeDiscount = useCallback(
    ({ discount, takeawayDiscount, deliveryDiscount }) => {
      setLocalDiscount(discount);
      setlocalTakeawayDiscount(takeawayDiscount);
      setLocalDeliveryDiscount(deliveryDiscount);
    },
    []
  );

  const handleChangeAvailable = useCallback(
    (newAvailable: AvailableEnum) => {
      setLocalAvailabilityData({
        ...localAvailabilityData,
        available: newAvailable,
      });
    },
    [localAvailabilityData]
  );

  const handleChangeAvailabilityData = useCallback(
    (newValues: LocalAvailabilityData) => {
      setLocalAvailabilityData(newValues);
    },
    []
  );

  const availabilityFormKey = useForceReloadOnUpdate([
    localAvailabilityData.available,
  ]);

  useDidUpdate(() => {
    preserveLocalDNA({
      id: categoryId,
      discount: localDiscount ? parseInt(localDiscount) : -1,
      takeawayDiscount: localTakeawayDiscount
        ? parseInt(localTakeawayDiscount)
        : -1,
      deliveryDiscount: localDeliveryDiscount
        ? parseInt(localDeliveryDiscount)
        : -1,
      ...decomposeLocalAvailabilityData(localAvailabilityData),
      isFreeItem: isFreeItem,
      minBasketValue: minBasketValue,
    });
  }, [
    localDiscount,
    localTakeawayDiscount,
    localDeliveryDiscount,
    localAvailabilityData,
  ]);

  return (
    <DiscountAndAavailabilityLayout
      discountSection={
        isDiscountTab && (
          <DiscountForm
            uniqId={categoryId}
            title={t("discounts:discount")}
            discount={localDiscount}
            takeawayDiscount={localTakeawayDiscount}
            deliveryDiscount={localDeliveryDiscount}
            onSubmit={handleChangeDiscount}
          />
        )
      }
      availabilitySection={
        !isDiscountTab && (
          <AvailabilityForm
            key={availabilityFormKey}
            uniqId={categoryId}
            title={t("discounts:availability")}
            onSubmit={handleChangeAvailabilityData}
            onChangeAvailable={handleChangeAvailable}
            availabilityData={localAvailabilityData}
            availabilityOptions={AVAILABILITY_OPTIONS}
          />
        )
      }
    />
  );
};
