import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: (inverted: boolean, checked: boolean, width: string, disabled: boolean) => css`
    display: flex;
    align-items: center;

    position: relative;
    width: ${width};
    padding: 7px 6px 8px 14px;

    border-radius: 13px;

    ${inverted
      ? css`
          background-color: transparent;
        `
      : css`
          background-color: ${checked
            ? themeColors.lightGreenTransparent
            : themeColors.lightPinkTransparent};
        `}
    transition: background-color 0.3s;

    ${disabled &&
    css`
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        cursor: not-allowed;
      }
    `}
  `,
  label: (inverted: boolean) => css`
    flex: 1;
    margin-right: 8px;

    font-size: 12px;
    ${inverted &&
    css`
      color: ${themeColors.white};
      font-weight: 700;
    `}

    cursor: pointer;
  `,
};
