import { notEmpty } from "@utils/formik.validators";
import { MenuItemAddonsFormData } from "../../../../utils/Addon";

export const validationMap = (formData: MenuItemAddonsFormData[]) => {
  return formData.reduce(
    (accum, { name }) => ({
      ...accum,
      [name]: [notEmpty("This field can't be blank")],
    }),
    {}
  );
};
