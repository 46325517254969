import { gql } from "@apollo/client";

export const GET_LIBRARY = gql`
  query GetLibrary(
    $menus: Boolean!
    $menuItems: Boolean!
    $categories: Boolean!
    $addons: Boolean!
    $page: Int!
  ) {
    library {
      menus(page: $page) @include(if: $menus) {
        nodes {
          id
          originType
          title
          favorite
          displayData {
            title
            items
          }
        }
        pagination {
          offset
          perPage
          total
        }
      }
      categories(page: $page) @include(if: $categories) {
        nodes {
          id
          originType
          title
          favorite
          displayData {
            title
            items
          }
        }
        pagination {
          offset
          perPage
          total
        }
      }
      menuItems(page: $page) @include(if: $menuItems) {
        nodes {
          id
          originType
          title
          favorite
          displayData {
            title
            items
          }
        }
        pagination {
          offset
          perPage
          total
        }
      }
      addons(page: $page) @include(if: $addons) {
        nodes {
          id
          originType
          title
          favorite
          displayData {
            title
            items
          }
        }
        pagination {
          offset
          perPage
          total
        }
      }
    }
  }
`;

export * as GetLibraryTypes from "./__generated__/GetLibrary";
