import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";
import { CATEGORY_ESSENTIAL_DATA } from "../../fragments/Category";

export const CLONE_CATEGORY = gql`
  mutation CloneCategoryMutation($input: CloneData!) {
    mmsClone(input: $input) {
      ...CategoryEssentialData
    }
  }
  ${CATEGORY_ESSENTIAL_DATA}
`;

export const generateCloneCategoryVars = (categoryId: string) => ({
  input: {
    entityType: Levels.CATEGORY,
    entityId: categoryId,
  },
});

export * as CloneCategoryTypes from "./__generated__/CloneCategoryMutation";
