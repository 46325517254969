import { ReactComponent as SVGChevronRight } from "@assets/SVG/chevron-right.svg";
import { cx } from "@emotion/css";
import { themeColors } from "@theme/colors";
import React from "react";
import { useSvgFilter } from "../_common/hooks";
import { DirectionType, IIconBase } from "../_common/types";
import { styles } from "./styles";

interface IChevronProps extends IIconBase {
  direction?: DirectionType;
  color?: string;
}

export const Chevron: React.FC<IChevronProps> = ({
  id,
  size = "medium",
  direction = "right",
  className,
  color = themeColors.black,
}) => {
  const ref = useSvgFilter(id);
  return (
    <SVGChevronRight
      ref={ref}
      className={cx(styles.icon({ size, direction }), className)}
      fill={color}
    />
  );
};
