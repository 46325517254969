import { ErrorCircle } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import React from "react";
import { styles } from "./MenuItemListErrorMessage.styles";
import { useTranslation } from "react-i18next";

export const MenuItemListErrorMessage: React.FC = () => {
  const { t } = useTranslation(["category"]);
  return (
    <JustifyContentBetweenLayout className={styles.container}>
      <ErrorCircle />
      <div>{t("category:failed-to-fetch-categories")}</div>
    </JustifyContentBetweenLayout>
  );
};
