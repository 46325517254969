import { gql } from "@apollo/client";

export const ADD_TO_HISTORY = gql`
  mutation AddToHistoryMutation($input: MenuRestoreAddData!) {
    mmsRestoreAdd(input: $input) {
      id
    }
  }
`;

export * as AddToHistoryMutationTypes from "./__generated__/AddToHistoryMutation";
