import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import { MmsFormDescription } from "@uiKit/Atoms/FormDescription/MmsFormDescription";

interface StaticFormValues {
  minBasketValue: number | null;
}

interface MinBasketValueFormContentProps {
  uniqId: string;
}

const MinBasketValueFormContent: React.FC<
  MinBasketValueFormContentProps & FormikProps<StaticFormValues>
> = ({ uniqId, ...formikProps }) => {
  const { t } = useTranslation(["common"]);
  const formId = `min-basket-value-form-${uniqId}`;

  const handleBlur = useCallback(() => {
    formikProps.submitForm();
  }, [formikProps]);

  return (
    <Form>
      <MmsFormTitle>{t("common:min-basket-value")}</MmsFormTitle>
      <NumberFormat
        data-testid={formId}
        id={formId}
        customInput={MmsMuiInput}
        name="minBasketValue"
        label={t("common:min-basket-value")}
        value={formikProps?.values?.minBasketValue}
        onBlur={handleBlur}
        allowNegative={false}
        fullWidth
        showUnderline
      />
      <MmsFormDescription>
        {t("common:min-basket-value-description")}
      </MmsFormDescription>
    </Form>
  );
};
export interface MinBasketValueFormProps
  extends MinBasketValueFormContentProps {
  minBasketValue: number | null;
  onSubmit: (values: any) => void;
}

export const MinBasketValueForm = withFormik<
  MinBasketValueFormProps,
  StaticFormValues
>({
  mapPropsToValues: ({ minBasketValue }) => ({
    minBasketValue,
  }),
  handleSubmit: (values: any, { props: { minBasketValue, onSubmit } }) => {
    if (minBasketValue !== values.minBasketValue)
      return onSubmit(
        values.minBasketValue ? Number(values.minBasketValue) : -1
      );
  },
})(MinBasketValueFormContent);
