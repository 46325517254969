import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { PositionDataType } from "@model/helperTypes/dragAndDrop";
import { CategoryContainerProps } from "../containers/Category/Category";
import { MenuItemContainerProps } from "../containers/MenuItem/MenuItem";

export const menuItemListDataToMenuItems = (
  data: MenuItemContainerProps[]
): ModelMenuItem[] =>
  data.map(({ menuItem }, index) => ({ ...menuItem, orderBy: index + 1 }));

export const categoryListDataToCategories = (
  data: CategoryContainerProps[]
): ModelFullCategory[] =>
  data.map(({ category }, index) => ({ ...category, orderBy: index + 1 }));

export const sortableEntitiesToPositionDataType = <
  T extends { id: string; orderBy: number }
>(
  entities: T[]
): PositionDataType[] => entities.map(({ id, orderBy }) => ({ id, orderBy }));
