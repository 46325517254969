import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";

export const styles = {
  banner: css`
    width: 405px;
    padding: 30px;

    background-color: ${themeColors.white};

    box-shadow: ${themeUtils.boxShadowSettings()} ${themeUtils.boxShadowOpacity(0.15)};
  `,
  title: css`
    margin-bottom: 15px;

    font-size: 20px;
    font-weight: bold;
  `,
  boldText: css`
    font-weight: bold;
  `,
  text: css`
    margin-bottom: 20px;

    font-size: 16px;
    font-weight: 300;
  `,
  image: css`
    width: 345px;
    height: 87px;
    margin-bottom: 30px;
  `,
  checkbox: css`
    margin-right: 15px;
  `,
  label: css`
    color: ${themeColors.lightGreen};
    font-size: 12px;
    font-weight: bold;

    cursor: pointer;
  `,
};
