import { css } from "@emotion/css";

export const styles = {
  container: css`
    align-items: center;
    gap: 20px;
    padding-bottom: 40px;
  `,
  selectboxFilter: css`
    flex: 1;

    border: 1px solid rgba(0, 0, 0, 0);
  `,
};
