import { useReactiveVar } from "@apollo/client";
import { isAddonsLibraryModalOpenVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useAddonsLibraryModal = (): [
  boolean,
  VoidFunction,
  VoidFunction
] => {
  const openAddonsLibraryModal = useCallback(() => {
    isAddonsLibraryModalOpenVar(true);
  }, []);

  const closeAddonsLibraryModal = useCallback(() => {
    isAddonsLibraryModalOpenVar(false);
  }, []);

  return [
    useReactiveVar(isAddonsLibraryModalOpenVar),
    openAddonsLibraryModal,
    closeAddonsLibraryModal,
  ];
};
