import { gql } from "@apollo/client";

export const UPDATE_LIBRARY_ASSET = gql`
  mutation UpdateLibraryAssetMutation($input: LibraryUpdate!) {
    libraryUpdate(input: $input) {
      id
      title
      favorite
    }
  }
`;

export const generateUpdateLibraryAssetVars = (
  id: string,
  title: string,
  favorite: boolean
) => ({
  input: {
    id,
    title,
    favorite,
  },
});

export * as UpdateLibraryAssetTypes from "./__generated__/UpdateLibraryAssetMutation";
