import { SCREEN_SIZES } from "@constants";
import { css } from "@emotion/css";

export const styles = {
  body: (isIFrame: boolean, backgroundColor: string) => css`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: ${backgroundColor};

    @media (max-width: ${SCREEN_SIZES.LG - 1}px) {
      padding-bottom: 60px;
    }

    ${isIFrame &&
    css`
      @media (max-width: ${SCREEN_SIZES.MD - 1}px) {
        margin-top: 110px;
      }
    `}
  `,
  container: css`
    padding-top: 45px;
    padding-bottom: 45px;
  `,
};
