import { cx } from "@emotion/css";
import { useDidMount } from "@hooks";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";
import { styles } from "./MenuItemImageModal.styles";

interface MenuItemImageModalProps {
  children: React.ReactNode;
  onOpen: VoidFunction;
  onClose: VoidFunction;
}

export const MenuItemImageModal: React.FC<MenuItemImageModalProps> = ({
  children,
  onOpen,
  onClose,
}) => {
  useDidMount(onOpen);
  return (
    <MmsBaseModal
      contentClassName={cx("menuItemImageModal", styles.imageModal)}
      onClose={onClose}
      closeOnEscPress={true}
    >
      {children}
    </MmsBaseModal>
  );
};
