import { useReactiveVar } from "@apollo/client";
import {
  useActiveImportDirection,
  useActiveProgressBars,
  useDidUpdate,
  useErrorCatch,
  useForceReloadOnUpdate,
  useOpenClose,
} from "@hooks";
import { ModelFullCategory } from "@model/DAO/MenuCategory";
import { ModelGalleryImage, ModelMenuGallery } from "@model/DAO/MenuGallery";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { AddonsOwnerEnum } from "@model/helperTypes/Addon";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { AutoenableSettings } from "@pages/RestaurantMenuPage/components/AutoenableSettings/AutoenableSettings";
import { MenuItemSkeleton } from "@pages/RestaurantMenuPage/components/MenuItem/MenuItemSkeleton/MenuItemSkeleton";
import { useImportFromLibrary } from "@pages/RestaurantMenuPage/hooks/useImportFromLibrary";
import { useSelectedGalleryImages } from "@pages/RestaurantMenuPage/hooks/useSelectedGalleryImages";
import { MenuItemChooseImageLayout } from "@pages/RestaurantMenuPage/layouts/MenuItemChooseImageLayout/MenuItemChooseImageLayout";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import { restaurantCurrencyVar } from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import { clearWysiwyg } from "@utils/strings";
import React, { useCallback, useEffect } from "react";
import { AddonsSettings } from "../../components/AddonsSettings/AddonsSettings";
import { MenuItem } from "../../components/MenuItem/MenuItem";
import { MenuItemChooseImageSettings } from "../../components/MenuItem/MenuItemChooseImageSettings/MenuItemChooseImageSettings";
import { MenuItemEditDescriptionForm } from "../../components/MenuItem/MenuItemEditDescription/MenuItemEditDescription";
import { MenuItemImageSettings } from "../../components/MenuItem/MenuItemImageSettings/MenuItemImageSettings";
import { useDeleteGalleryImage } from "../../gqlHooks/gallery/mutations";
import {
  useAddAssetToLibrary,
  useForceAddToLibrary,
} from "../../gqlHooks/library/mutations";
import {
  useCloneMenuItem,
  useDestroyMenuItem,
  useEditMenuItemDescription,
  useEditMenuItemPrices,
  useEditMenuItemSku,
  useEditMenuItemTitle,
  useToggleMenuItem,
  useToggleMenuItemHasOwnAddons,
  useUpdateMenuItemAddons,
  useUpdateMenuItemImage,
} from "../../gqlHooks/menuItem/mutations";
import { addUniqThrottledSuccessRegularNotification } from "../../hooks/useSuccessNotifications";
import { LibraryModal } from "../../modals/LibraryModal/LibraryModal";
import { MenuItemChooseImageModal } from "../../modals/MenuItemChooseImageModal/MenuItemChooseImageModal";
import { MenuItemEditDescriptionModal } from "../../modals/MenuItemEditDescriptionModal/MenuItemEditDescriptionModal";
import { MenuItemImageModal } from "../../modals/MenuItemImageModal/MenuItemImageModal";
import { getPriceLevelPrices } from "../../utils/Addon";
import { generateLibraryProgressBarId } from "../../utils/Library";
import { GalleryBulkPanelContainer } from "../GalleryBulkPanel/GalleryBulkPanel";
import { LibrarySettingsContainer } from "../LibrarySettings/LibrarySettings";
import { MenuItemSettingsContainer } from "../MenuItemSettings/MenuItemSettings";
import { useTranslation } from "react-i18next";
import { useUploadMultipleImagesToGallery } from "../../hooks/useUploadMultipleImagesToGallery";
import { useOpenCloseWithData } from "../../../../hooks/useOpenCloseWithData";
import { SettingsTabsEnum } from "../../components/MenuItemSettings/MenuItemSettings";

export interface MenuItemContainerProps {
  menuItem: ModelMenuItem;
  parentCategory: ModelFullCategory;
  imageGallery: ModelMenuGallery;
  fetchMoreGalleryImages: (page: number) => any;
  isGalleryLoading: boolean;
}

export const MenuItemContainer: React.FC<MenuItemContainerProps> = ({
  menuItem,
  parentCategory,
  imageGallery,
  fetchMoreGalleryImages,
  isGalleryLoading,
}) => {
  const { t } = useTranslation(["common", "menu-item"]);
  const [, , removeMenuItemProgressBarId] = useActiveProgressBars();
  const currency = useReactiveVar(restaurantCurrencyVar);

  const [toggleMenuItemMutation, isToggleMenuItemLoading] = useToggleMenuItem(
    menuItem.id,
    !menuItem.active
  );

  const menuItemImage = menuItem?.image || null;

  const [
    selectedImage,
    setSelectedImage,
  ] = React.useState<ModelGalleryImage | null>(
    menuItemImage as ModelGalleryImage
  );
  const [uploadImages, isUploadLoading] = useUploadMultipleImagesToGallery();
  const [deleteGalleryImage] = useDeleteGalleryImage();

  const [
    isMenuItemSettingsModalOpen,
    openMenuItemSettingsModal,
    closeMenuItemSettingsModal,
    initActiveSettingsTab,
  ] = useOpenCloseWithData(false, SettingsTabsEnum.allergens);

  const deleteGalleryImageCallback = React.useCallback(
    (imageId: string) => {
      deleteGalleryImage(imageId);
      setSelectedImage(menuItemImage as ModelGalleryImage);
    },
    [deleteGalleryImage, menuItemImage]
  );

  const [
    isLibraryModalOpen,
    openLibraryModal,
    closeLibraryModal,
  ] = useImportFromLibrary(menuItem.id, MenuAssetEnum.menu_item);

  const [
    isConnectToCategoryConfirmationModalOpen,
    openConnectToCategoryConfirmationModal,
    closeConnectToCategoryConfirmationModal,
  ] = useOpenClose(false);

  const [
    isEditDescriptionModalOpen,
    openEditDescriptionModal,
    closeEditDescriptionModal,
  ] = useOpenClose(false);

  const [
    isAddonsSettingModalOpen,
    openAddonsSettingsModal,
    closeAddonsSettingsModal,
  ] = useOpenClose(false);

  const [
    isLibraryConfirmationModalOpen,
    openLibraryConfirmationModal,
    closeLibraryConfirmationModal,
  ] = useOpenClose(false);

  const [
    isDeleteConfirmationModalOpen,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
  ] = useOpenClose(false);

  const [
    isAutoenableSettingsModalOpen,
    openAutoenableSettingsModal,
    closeAutoenableSettingsModal,
  ] = useOpenClose(false);

  const [isImageModalOpen, openImageModal, closeImageModal] = useOpenClose(
    false
  );

  const [
    isChooseFileModalOpen,
    openChooseFileModal,
    closeChooseFileModal,
  ] = useOpenClose(false);

  const [cloneMenuItem, isCloneMenuItemLoading] = useCloneMenuItem(
    parentCategory.id,
    menuItem.id
  );

  const [
    addingToLibraryError,
    setAddingToLibraryError,
    libraryMenuItemId,
    onCleanAddingToLibraryError,
  ] = useErrorCatch(openLibraryConfirmationModal);

  const [
    destroyMenuItemMutation,
    isDestroyMenuItemLoading,
  ] = useDestroyMenuItem(parentCategory.id, menuItem.id);

  const [
    editMenuItemTitleMutation,
    isEditMenuItemTitleLoading,
  ] = useEditMenuItemTitle(parentCategory.id, menuItem.id);

  const [
    editMenuItemSkuMutation,
    isEditMenuItemSkuLoading,
  ] = useEditMenuItemSku(menuItem.id, noop);

  const [
    editMenuItemDescriptionMutation,
    isEditMenuItemDescriptionLoading,
  ] = useEditMenuItemDescription(menuItem.id, closeEditDescriptionModal);

  const [
    updateMenuItemAddonsMutation,
    isUpdateMenuItemAddonsLoading,
    isUpdateMenuItemAddonsError,
  ] = useUpdateMenuItemAddons(menuItem.id);

  const [updateMenuItemPricesMutation] = useEditMenuItemPrices(menuItem.id);

  const [
    ,
    setImportDirection,
    cleanImportDirection,
  ] = useActiveImportDirection();

  const [
    toggleMenuItemHasOwnAddonsMutation,
    isToggleMenuItemHasOwnAddonsLoading,
  ] = useToggleMenuItemHasOwnAddons(menuItem.id, !menuItem.hasOwnAddons);

  const [updateMenuItemImage] = useUpdateMenuItemImage(
    menuItem.id,
    parentCategory.id
  );

  const [selectedImagesIds] = useSelectedGalleryImages();

  const updateMenuItemImageCallback = useCallback(
    (imageId: string) => {
      if (selectedImage !== null) {
        updateMenuItemImage(imageId);
      }
      closeImageModal();
    },
    [updateMenuItemImage, closeImageModal, selectedImage]
  );

  const onRemoveMenuItemImage = useCallback(() => {
    updateMenuItemImage("0");
  }, [updateMenuItemImage]);

  const handleOpenImageModal = useCallback(() => {
    menuItemImage && setSelectedImage(menuItemImage as ModelGalleryImage);
  }, [menuItemImage]);

  const handleChangeTitle = useCallback(
    (values: { title: string }) => {
      editMenuItemTitleMutation(values.title);
    },
    [editMenuItemTitleMutation]
  );

  const handleChangeSku = useCallback(
    (values: { title: string }) => {
      editMenuItemSkuMutation(values.title);
    },
    [editMenuItemSkuMutation]
  );

  const handleChangeDescription = useCallback(
    (values: { description: string }) => {
      editMenuItemDescriptionMutation(clearWysiwyg(values.description));
    },
    [editMenuItemDescriptionMutation]
  );

  const handleOpenAddonsSettingsModal = useCallback(() => {
    setImportDirection(menuItem.id, MenuAssetEnum.menu_item);
  }, [menuItem.id, setImportDirection]);

  const handleCloseAddonsSettingsModal = useCallback(() => {
    closeAddonsSettingsModal();
    cleanImportDirection();
  }, [cleanImportDirection, closeAddonsSettingsModal]);

  const handleCloseLibraryConfirmationModal = useCallback(() => {
    closeLibraryConfirmationModal();
    removeMenuItemProgressBarId(
      generateLibraryProgressBarId(MenuAssetEnum.menu_item, menuItem.id)
    );
  }, [closeLibraryConfirmationModal, menuItem.id, removeMenuItemProgressBarId]);

  const handleToggleMenuItem = useCallback(() => {
    menuItem.active ? openAutoenableSettingsModal() : toggleMenuItemMutation(0);
  }, [menuItem.active, openAutoenableSettingsModal, toggleMenuItemMutation]);

  const finishAutoenable = useCallback(
    (autoenableTimestamp: number) => {
      toggleMenuItemMutation(autoenableTimestamp);
      closeAutoenableSettingsModal();
    },
    [closeAutoenableSettingsModal, toggleMenuItemMutation]
  );

  const handleSkipAutoenable = useCallback(() => finishAutoenable(0), [
    finishAutoenable,
  ]);

  const handleSaveAutoenable = useCallback(
    (autoenableDateTime: Date) =>
      finishAutoenable(autoenableDateTime.getTime()),
    [finishAutoenable]
  );

  const topLevelPriceLevelPrices = getPriceLevelPrices(menuItem);

  const addonsSettingsKey = useForceReloadOnUpdate([menuItem.hasOwnAddons]);

  useDidUpdate(() => {
    !isToggleMenuItemHasOwnAddonsLoading &&
      closeConnectToCategoryConfirmationModal();
  }, [isToggleMenuItemHasOwnAddonsLoading]);

  const onCancelChooseImageModal = React.useCallback(() => {
    setSelectedImage((menuItemImage as ModelGalleryImage) || null);
    closeChooseFileModal();
  }, [closeChooseFileModal, setSelectedImage, menuItemImage]);

  const [
    onSaveMenuItemToLibrary,
    menuItemAddedToLibraryLoading,
    addedMenuItemId,
  ] = useAddAssetToLibrary(
    MenuAssetEnum.menu_item,
    menuItem.id,
    setAddingToLibraryError
  );

  const [
    onForceSaveMenuItemToLibrary,
    menuItemForceAddedToLibraryLoading,
    forceAddedMenuItemId,
    onEndForceMenuItemAdding,
  ] = useForceAddToLibrary(MenuAssetEnum.menu_item, libraryMenuItemId);

  const handleForceSaveToLibrary = useCallback(() => {
    closeLibraryConfirmationModal();
    onForceSaveMenuItemToLibrary();
  }, [closeLibraryConfirmationModal, onForceSaveMenuItemToLibrary]);

  useDidUpdate(() => {
    if (isDestroyMenuItemLoading) return;
    closeDeleteConfirmationModal();
  }, [isDestroyMenuItemLoading]);

  useEffect(() => {
    if (
      (!menuItemAddedToLibraryLoading && addedMenuItemId) ||
      (!menuItemForceAddedToLibraryLoading && forceAddedMenuItemId)
    ) {
      addUniqThrottledSuccessRegularNotification(
        t("menu-item:the-asset-was-successfully-added-to-library")
      );
      handleCloseLibraryConfirmationModal();
      onCleanAddingToLibraryError();
      onEndForceMenuItemAdding();
    }
  }, [
    addedMenuItemId,
    forceAddedMenuItemId,
    menuItemAddedToLibraryLoading,
    menuItemForceAddedToLibraryLoading,
    handleCloseLibraryConfirmationModal,
    onCleanAddingToLibraryError,
    onEndForceMenuItemAdding,
    t,
  ]);

  return (
    <>
      <MenuItem
        currency={currency}
        toggleImageModalOpen={openImageModal}
        parentCategory={parentCategory}
        menuItem={menuItem}
        onClone={isCloneMenuItemLoading ? noop : cloneMenuItem}
        onDelete={isDestroyMenuItemLoading ? noop : openDeleteConfirmationModal}
        onToggle={isToggleMenuItemLoading ? noop : handleToggleMenuItem}
        topLevelPriceLevelPrices={topLevelPriceLevelPrices}
        onChangeTitle={handleChangeTitle}
        onChangeSku={handleChangeSku}
        onChangePrices={updateMenuItemPricesMutation}
        onDescriptionClick={openEditDescriptionModal}
        onShowCommonSettings={openMenuItemSettingsModal}
        onShowAddons={openAddonsSettingsModal}
        onSave={onSaveMenuItemToLibrary}
        onImport={openLibraryModal}
        isActive={menuItem.active}
        isEditTitleLoading={isEditMenuItemTitleLoading}
        isEditSkuLoading={isEditMenuItemSkuLoading}
        modals={
          <>
            {isEditDescriptionModalOpen && (
              <MenuItemEditDescriptionModal
                closeModal={closeEditDescriptionModal}
              >
                <MenuItemEditDescriptionForm
                  menuItemId={menuItem.id}
                  isFormSubmitting={isEditMenuItemDescriptionLoading}
                  onSubmit={handleChangeDescription}
                  onCancelClick={closeEditDescriptionModal}
                  onSubmitEnded={closeEditDescriptionModal}
                  description={clearWysiwyg(menuItem.description || "")}
                />
              </MenuItemEditDescriptionModal>
            )}
            {isAddonsSettingModalOpen && (
              <AddonsSettings
                key={addonsSettingsKey}
                parentId={menuItem.id}
                parentTitle={menuItem.title}
                addons={
                  menuItem.hasOwnAddons
                    ? menuItem.addons
                    : parentCategory.addons
                }
                addonsOwner={AddonsOwnerEnum.menuItem}
                onCancel={handleCloseAddonsSettingsModal}
                onUpdate={updateMenuItemAddonsMutation}
                isUpdateProcessing={isUpdateMenuItemAddonsLoading}
                isAddonsAttachedToMenuItem={menuItem.hasOwnAddons}
                toggleAttachAddonsToMenuItem={
                  openConnectToCategoryConfirmationModal
                }
                isError={!!isUpdateMenuItemAddonsError}
                onAddonsSettingModalOpen={handleOpenAddonsSettingsModal}
              />
            )}
            {isConnectToCategoryConfirmationModalOpen && (
              <MmsConfirmationModal
                message={
                  menuItem.hasOwnAddons ? (
                    <>
                      {t(
                        "menu-item:are-you-sure-you-want-to-connect-addons-to"
                      )}
                      <strong> {t("menu-item:category")} </strong>?
                    </>
                  ) : (
                    <>
                      {t(
                        "menu-item:are-you-sure-you-want-to-connect-addons-to"
                      )}
                      <strong> {t("menu-item:item")} </strong>?
                    </>
                  )
                }
                confirmBtnText={t("common:yes")}
                declineBtnText={t("common:no")}
                isDataProcessing={isToggleMenuItemHasOwnAddonsLoading}
                onConfirmBtnClick={toggleMenuItemHasOwnAddonsMutation}
                onDeclineBtnClick={closeConnectToCategoryConfirmationModal}
              />
            )}
            {isImageModalOpen && (
              <MenuItemImageModal
                onOpen={handleOpenImageModal}
                onClose={closeImageModal}
              >
                <MenuItemImageSettings
                  onRemoveImage={onRemoveMenuItemImage}
                  onUpdateImage={updateMenuItemImageCallback}
                  onCancel={closeImageModal}
                  onChooseFile={openChooseFileModal}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </MenuItemImageModal>
            )}
            {isChooseFileModalOpen && (
              <MenuItemChooseImageModal onClose={onCancelChooseImageModal}>
                <MenuItemChooseImageLayout
                  galleryBulkPanel={
                    selectedImagesIds.length > 1 && (
                      <GalleryBulkPanelContainer />
                    )
                  }
                  galleryContent={
                    <MenuItemChooseImageSettings
                      isGalleryLoading={isGalleryLoading}
                      onDeleteImage={deleteGalleryImageCallback}
                      isUploadLoading={isUploadLoading}
                      onOkButtonPress={closeChooseFileModal}
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                      imageGallery={imageGallery}
                      onCancel={onCancelChooseImageModal}
                      uploadImages={uploadImages}
                      fetchMoreGalleryImages={fetchMoreGalleryImages}
                    />
                  }
                />
              </MenuItemChooseImageModal>
            )}
            {isLibraryModalOpen && (
              <LibraryModal closeModal={closeLibraryModal}>
                <LibrarySettingsContainer onFinish={closeLibraryModal} />
              </LibraryModal>
            )}
            {isLibraryConfirmationModalOpen && (
              <MmsConfirmationModal
                message={addingToLibraryError}
                confirmBtnText={t("common:update")}
                declineBtnText={t("common:cancel")}
                onConfirmBtnClick={handleForceSaveToLibrary}
                onDeclineBtnClick={handleCloseLibraryConfirmationModal}
              />
            )}
            {isDeleteConfirmationModalOpen && (
              <MmsConfirmationModal
                message={t("common:are-you-sure-you-want-to-delete", {
                  title: menuItem.title,
                })}
                confirmBtnText={t("common:delete")}
                declineBtnText={t("common:cancel")}
                isDataProcessing={isDestroyMenuItemLoading}
                onConfirmBtnClick={destroyMenuItemMutation}
                onDeclineBtnClick={closeDeleteConfirmationModal}
              />
            )}
            {isMenuItemSettingsModalOpen && (
              <MenuItemSettingsContainer
                menuItem={menuItem}
                parentCategory={parentCategory}
                closeMenuItemSettingsModal={closeMenuItemSettingsModal}
                initialTab={initActiveSettingsTab}
              />
            )}
            {isAutoenableSettingsModalOpen && (
              <AutoenableSettings
                isAutoenableLoading={isToggleMenuItemLoading}
                uniqId="toggle-menu-item-autoenable-settings"
                onSkip={handleSkipAutoenable}
                onSave={handleSaveAutoenable}
              />
            )}
          </>
        }
      />
      {isCloneMenuItemLoading && <MenuItemSkeleton />}
    </>
  );
};
