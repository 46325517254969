import { gql } from "@apollo/client";

export const GET_HISTORY = gql`
  query GetHistoryQuery($restaurantId: ID!) {
    history(restaurantId: $restaurantId) {
      id
      title
      description
      publishedBy
      publishedAt
    }
  }
`;

export * as GetHistoryTypes from "./__generated__/GetHistoryQuery";
