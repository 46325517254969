import { cx } from "@emotion/css";
import React from "react";
import { styles } from "./RestaurantName.styles";

export interface RestaurantNameProps {
  className?: string;
  restaurantName: string;
}

export const RestaurantName: React.FC<RestaurantNameProps> = ({
  className,
  restaurantName,
}) => {
  return <div className={cx(styles, className)}>{restaurantName}</div>;
};
