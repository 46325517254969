import { css } from "@emotion/css";

export const styles = {
  container: (isError: boolean, errorColor: string) => css`
    position: relative;
    display: inline-block;

    border: solid 1px ${isError ? errorColor : "transparent"};
  `,
  field: (width: number) => css`
    width: ${width}px;
  `,
  error: (errorColor: string) => css`
    position: absolute;

    color: ${errorColor};
    font-size: 12px;
  `,
};
