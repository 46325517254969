import { useReactiveVar } from "@apollo/client";
import handleImg from "@assets/png/drag_indicator.png";
import { cx } from "@emotion/css";
import { useDragAndDropClient, useFormErrorsProtection } from "@hooks";
import { ModelLocalAddonItem } from "@model/DAO/MenuAddonItem";
import { AddonsSettingsStrategyEnum } from "@model/helperTypes/AddonsSettings";
import { DraggableEnum } from "@model/helperTypes/dragAndDrop";
import { LogAuditableEnum } from "@model/helperTypes/LogFilters";
import { MatchParams } from "@pages/RestaurantMenuPage/RestaurantMenuPage";
import { generateLogsAssetParams } from "@pages/RestaurantMenuPage/utils/Logs";
import { generateMenuAssetUrl } from "@pages/RestaurantMenuPage/utils/Menu";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { MmsRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink";
import { MmsDummyRouterLink } from "@uiKit/Atoms/Link/MmsRouterLink/dummy";
import { Clock, Clone, Trash } from "@uiKit/Atoms/SVGIcons";
import { MmsSwitch } from "@uiKit/Atoms/Switch/MmsSwitch";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { MmsTooltip } from "@uiKit/Molecules/Tooltip/MmsTooltip";
import { iwaiterIdVar } from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import React, { ReactNode, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PriceItemData } from "../../../utils/AddonItem";
import { styles } from "./AddonItem.styles";
import { AddonItemForm } from "./AddonItemForm/AddonItemForm";
import { useTranslation } from "react-i18next";

export interface AddonItemProps {
  addonsSettingsStrategy: AddonsSettingsStrategyEnum;
  addonItem: ModelLocalAddonItem;
  priceListData: PriceItemData[] | null;
  isNew?: boolean;
  draggableType?: string;
  isCloningDisabled?: boolean;
  isUpdateProcessing: boolean;
  onClone: (id: string) => void;
  onDelete: (id: string) => void;
  onToggle: (id: string) => void;
  onChangeForm: (id: string, newTitle: string, newPrice: string) => void;
  createNewAfterUpdate: VoidFunction;
  infoLabel?: ReactNode;
}

export const AddonItem: React.FC<AddonItemProps> = ({
  addonsSettingsStrategy,
  addonItem,
  priceListData,
  isNew,
  draggableType = DraggableEnum.dummy,
  isCloningDisabled,
  isUpdateProcessing,
  onClone,
  onDelete,
  onToggle,
  onChangeForm,
  createNewAfterUpdate,
  infoLabel,
}) => {
  const { t } = useTranslation(["common", "addons"]);
  const { restaurantId } = useParams<MatchParams>();
  const iwaiterId = useReactiveVar(iwaiterIdVar);
  const isDragAndDropDisabled = !!isNew;

  const [withActiveFormsErrorsProtection] = useFormErrorsProtection();

  const [
    { draggableRef, handleRef, previewRef },
    { isDragging },
    setIsDragAndDropEnabled,
  ] = useDragAndDropClient(draggableType);

  useEffect(() => {
    setIsDragAndDropEnabled(!isDragAndDropDisabled);
  }, [isDragAndDropDisabled, setIsDragAndDropEnabled]);

  const handleClone = useCallback(() => {
    onClone(addonItem.id);
  }, [addonItem.id, onClone]);

  const handleDelete = useCallback(() => {
    onDelete(addonItem.id);
  }, [addonItem.id, onDelete]);

  const handleToggle = useCallback(() => {
    onToggle(addonItem.id);
  }, [addonItem.id, onToggle]);

  const handleChangeForm = useCallback(
    ({ title, ...priceFields }) => {
      onChangeForm(addonItem.id, title, priceFields);
    },
    [addonItem.id, onChangeForm]
  );

  const withDisabledMode = useCallback(
    (fn) => (...args: any[]) => {
      isUpdateProcessing || fn(...args);
    },
    [isUpdateProcessing]
  );

  switch (addonsSettingsStrategy) {
    case AddonsSettingsStrategyEnum.category:
    case AddonsSettingsStrategyEnum.menu_item_attached:
      return (
        <MmsDummyRouterLink
          href={generateMenuAssetUrl(iwaiterId, addonItem.iwaiterId)}
          disableLinkPreview={getIsPartner()}
          {...{ tabIndex: -1 }}
        >
          <div className={styles.wrapper} ref={previewRef}>
            <JustifyContentBetweenLayout
              data-testid={`addon-item-${addonItem.id}`}
              className={styles.container(isDragging)}
              ref={draggableRef}
            >
              <AccessControl>
                <div
                  className={styles.handle(isDragAndDropDisabled)}
                  ref={handleRef}
                >
                  <MmsTooltip title={t("addons:hold-down-to-drag-and-drop")}>
                    <img src={handleImg} alt="" />
                  </MmsTooltip>
                </div>
              </AccessControl>
              <AddonItemForm
                addonItemId={addonItem.id}
                addonItemTitle={addonItem.title}
                priceListData={priceListData}
                onSubmit={handleChangeForm}
                onPressEnter={createNewAfterUpdate}
                isNew={isNew}
                isEditModeDisabled={isUpdateProcessing}
              />
              {infoLabel}
              <div className={styles.controls}>
                <AccessControl>
                  <MmsTooltip title={t("common:view-asset-logs")}>
                    <div>
                      <MmsRouterLink
                        id={`addon-item-logs-controls-${addonItem.id}`}
                        href={`/restaurant/${restaurantId}/menu/logs`}
                        search={generateLogsAssetParams({
                          assetId: addonItem.id,
                          assetType: LogAuditableEnum.MenuAddonItem,
                        })}
                        className={cx(styles.baseControl, styles.logsControl)}
                        shouldOpenInNewTab
                        {...{ tabIndex: -1 }}
                      >
                        <Clock
                          id={`addon-item-logs-controls-${addonItem.id}-icon`}
                          size="small"
                        />
                      </MmsRouterLink>
                    </div>
                  </MmsTooltip>
                  {!isCloningDisabled && (
                    <MmsTooltip title={t("addons:clone-this-asset")}>
                      <div>
                        <MmsSvgButton
                          onClick={withActiveFormsErrorsProtection(
                            withDisabledMode(handleClone)
                          )}
                          id={`clone-addon-item-controls-${addonItem.id}`}
                          className={styles.baseControl}
                        >
                          <Clone
                            id={`clone-addon-item-controls-${addonItem.id}-icon`}
                            size="small"
                          />
                        </MmsSvgButton>
                      </div>
                    </MmsTooltip>
                  )}
                  <MmsTooltip title={t("common:delete")}>
                    <div>
                      <MmsSvgButton
                        onClick={withDisabledMode(handleDelete)}
                        id={`delete-addon-item-controls-${addonItem.id}`}
                        className={styles.baseControl}
                      >
                        <Trash
                          id={`delete-addon-item-controls-${addonItem.id}-icon`}
                          size="small"
                        />
                      </MmsSvgButton>
                    </div>
                  </MmsTooltip>
                </AccessControl>
                <div className={styles.baseControl}>
                  <MmsTooltip
                    title={
                      addonItem.active
                        ? t("common:make-unavailable")
                        : t("common:make-available")
                    }
                  >
                    <div>
                      <MmsSwitch
                        checked={addonItem.active}
                        onChange={withDisabledMode(handleToggle)}
                        disabled={isUpdateProcessing}
                        id={`toggle-addon-item-controls-${addonItem.id}`}
                      />
                    </div>
                  </MmsTooltip>
                </div>
              </div>
            </JustifyContentBetweenLayout>
          </div>
        </MmsDummyRouterLink>
      );
    case AddonsSettingsStrategyEnum.menu_item_detached:
      return (
        <MmsDummyRouterLink
          href={generateMenuAssetUrl(iwaiterId, addonItem.iwaiterId)}
          disableLinkPreview={getIsPartner()}
          {...{ tabIndex: -1 }}
        >
          <div className={styles.wrapper} ref={previewRef}>
            <JustifyContentBetweenLayout
              data-testid={`addon-item-${addonItem.id}`}
              className={styles.container(false)}
            >
              <AddonItemForm
                addonItemId={addonItem.id}
                addonItemTitle={addonItem.title}
                priceListData={priceListData}
                onSubmit={handleChangeForm}
                onPressEnter={createNewAfterUpdate}
                isNew={isNew}
                isEditModeDisabled={true}
              />
              <div className={styles.controls}>
                <div className={styles.baseControl}>
                  <MmsTooltip
                    title={t(
                      "addons:go-to-category-addons-or-disconnect-this-item"
                    )}
                  >
                    <div>
                      <MmsSwitch
                        checked={addonItem.active}
                        onChange={withDisabledMode(handleToggle)}
                        disabled
                        id={`toggle-addon-item-controls-${addonItem.id}`}
                      />
                    </div>
                  </MmsTooltip>
                </div>
              </div>
            </JustifyContentBetweenLayout>
          </div>
        </MmsDummyRouterLink>
      );
    default:
      throw new Error(
        "AddonItemsSettings -> AddonItem -> render(): Wrong addonsSettingsStrategy"
      );
  }
};
