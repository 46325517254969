import { gql } from "@apollo/client";
import { ModelAdditive } from "@model/DAO/Additive";
import { ModelAllergen } from "@model/DAO/Allergen";
import { ModelMenuFlagId } from "@model/DAO/MenuFlag";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { Levels, TaxAssociationData } from "__generated__/globalTypes";
import { MENU_ITEM_ESSENTIAL_DATA } from "../../fragments/MenuItem";
import { MenuItemPricesData } from "@model/helperTypes/Prices";
import { mapPricesForApi } from "@utils/prices";

export const UPDATE_MENU_ITEM_SETTINGS = gql`
  mutation UpdateMenuItemAdditivesMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ...MenuItemEssentialData
    }
  }
  ${MENU_ITEM_ESSENTIAL_DATA}
`;

export const generateUpdateMenuItemAdditivesVars = (
  restaurantId: string,
  menuItemId: ModelMenuItem["id"],
  additiveIds: ModelAdditive["id"][]
) => {
  return {
    input: {
      restaurantId,
      entityType: Levels.MENU_ITEM,
      entityId: menuItemId,
      menuItem: {
        additiveIds,
      },
    },
  };
};

export const generateUpdateMenuItemSettingsVars = (
  restaurantId: string,
  menuItemId: ModelMenuItem["id"],
  additiveIds: ModelAdditive["id"][],
  allergenIds: ModelAllergen["id"][],
  menuFlagIds: ModelMenuFlagId["id"][],
  spiceType: number,
  localTaxes: TaxAssociationData[],
  localPrices: MenuItemPricesData
) => {
  return {
    input: {
      restaurantId,
      entityType: Levels.MENU_ITEM,
      entityId: menuItemId,
      menuItem: {
        additiveIds,
        allergenIds,
        menuFlags: menuFlagIds,
        spiceType: parseInt(`${spiceType}`),
        taxAssociations: localTaxes,
        ...mapPricesForApi(localPrices),
      },
    },
  };
};

export * as UpdateMenuItemAdditivesTypes from "./__generated__/UpdateMenuItemAdditivesMutation";
