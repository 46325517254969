import { ModelMenuItem } from "@model/DAO/MenuItem";
import { RerankableEntity } from "@model/helperTypes/dragAndDrop";
import { useGetGallery } from "@pages/RestaurantMenuPage/gqlHooks/gallery/queries";
import {
  useRerankMenuItems,
  useTransferMenuItem,
} from "@pages/RestaurantMenuPage/gqlHooks/menuItem/mutations";
import { getPriceLevelPrices } from "@pages/RestaurantMenuPage/utils/Addon";
import { getNewOrderBy } from "@pages/RestaurantMenuPage/utils/GenericEntity";
import { menuItemListDataToMenuItems } from "@pages/RestaurantMenuPage/utils/rerank";
import { SortableList } from "@uiKit/Layouts/SortableList";
import { compose } from "ramda";
import React, { useCallback, useState } from "react";
import { LevelsRerank } from "__generated__/globalTypes";
import { AddNewMenuItem } from "../../components/AddNewMenuItem/AddNewMenuItem";
import {
  MenuItemContainer,
  MenuItemContainerProps,
} from "../MenuItem/MenuItem";
import { NewMenuItemContainer } from "../NewMenuItem/NewMenuItem";
import { OutgoingProps, withMenuItems } from "./withMenuItems";

export interface MenuItemListContainerProps extends OutgoingProps {}

const getNewMenuItemFixture = (itemList: ModelMenuItem[]): ModelMenuItem => ({
  id: "dummy",
  categoryId: "dummyCat",
  iwaiterId: null,
  title: "",
  sku: "",
  description: null,
  active: true,
  hasOwnAddons: false,
  disableBasePrices: false,
  enableDeliveryPrices: false,
  enableTakeawayPrices: false,
  enableDineInPrices: false,
  price: "0.00",
  priceTakeaway: null,
  priceDelivery: null,
  priceDineIn: null,
  priceLevel1: null,
  priceLevel2: null,
  priceLevel3: null,
  priceLevel4: null,
  priceLevel5: null,
  priceLevel1Takeaway: null,
  priceLevel2Takeaway: null,
  priceLevel3Takeaway: null,
  priceLevel4Takeaway: null,
  priceLevel5Takeaway: null,
  priceLevel1Delivery: null,
  priceLevel2Delivery: null,
  priceLevel3Delivery: null,
  priceLevel4Delivery: null,
  priceLevel5Delivery: null,
  priceLevel1DineIn: null,
  priceLevel2DineIn: null,
  priceLevel3DineIn: null,
  priceLevel4DineIn: null,
  priceLevel5DineIn: null,
  addons: [],
  allergens: [],
  additives: [],
  menuFlags: [],
  spiceType: 0,
  image: null,
  orderBy: getNewOrderBy<ModelMenuItem>(itemList),
  taxAssociations: [],
  __typename: "MenuItem",
});

const MenuItemListContainer: React.FC<MenuItemListContainerProps> = ({
  parentCategory,
  menuItems,
}) => {
  const [newMenuItem, setNewMenuItem] = useState<ModelMenuItem | null>(null);

  const makeNewMenuItem = useCallback((menuItems: ModelMenuItem[]) => {
    compose<ModelMenuItem[], ModelMenuItem, void>(
      setNewMenuItem,
      getNewMenuItemFixture
    )(menuItems);
  }, []);

  const [
    isGalleryLoading,
    imageGallery,
    fetchMoreGalleryImages,
  ] = useGetGallery(1);

  const clearNewMenuItem = useCallback(() => {
    setNewMenuItem(null);
  }, []);

  const [rerankMenuItemsMutation] = useRerankMenuItems(
    LevelsRerank.CATEGORY,
    parentCategory.id
  );

  const [transferMenuItemMutation] = useTransferMenuItem();

  const handleOnChangeOrder = useCallback(
    (reorderedData) => {
      compose(
        rerankMenuItemsMutation,
        menuItemListDataToMenuItems
      )(reorderedData);
    },
    [rerankMenuItemsMutation]
  );

  const topLevelPriceLevelPrices = newMenuItem
    ? getPriceLevelPrices(newMenuItem)
    : [];

  return (
    <>
      <SortableList<RerankableEntity<MenuItemContainerProps>>
        onChangeOrder={handleOnChangeOrder}
        onTransferItem={transferMenuItemMutation}
        data={menuItems.map((menuItem) => ({
          id: menuItem.id,
          menuItem,
          parentCategory,
          imageGallery,
          fetchMoreGalleryImages,
          isGalleryLoading,
        }))}
        ItemComponent={MenuItemContainer}
      />
      {newMenuItem && (
        <NewMenuItemContainer
          newMenuItem={newMenuItem}
          parentCategory={parentCategory}
          onMenuItemCreated={clearNewMenuItem}
          onDeleteClick={clearNewMenuItem}
          topLevelPriceLevelPrices={topLevelPriceLevelPrices}
        />
      )}
      <AddNewMenuItem
        onClick={makeNewMenuItem.bind(null, menuItems)}
        categoryId={parentCategory.id}
        categoryTitle={parentCategory.title}
      />
    </>
  );
};

export default withMenuItems(MenuItemListContainer);
