import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  title: css`
    padding: 15px 19px;

    background-color: ${themeColors.white};

    font-size: 20px;
    font-weight: bold;
  `,
  emtyListContainer: css`
    gap: 30px;
    height: 100%;
    align-items: center;
    padding: 0 20px;
  `,
  btnRow: css`
    gap: 14px;
    padding: 15px 21px;

    background-color: ${themeColors.white};
  `,
};
