import jwtDecode, { JwtPayload } from "jwt-decode";

export const getTokenLifeTime = (token: string): number => {
  const exp = jwtDecode<JwtPayload>(token).exp as number;
  return exp * 1000 - Date.now();
};

export const isTokenExpired = (token: string): boolean => {
  const exp = jwtDecode<JwtPayload>(token).exp as number;
  return exp * 1000 - Date.now() < 0;
};
