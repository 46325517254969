import { SaveToHistoryFormType } from "@model/DAO/History";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import React, { useCallback } from "react";
import { styles } from "./SaveToHistorySettings.styles";
import { SaveToHistorySettingsForm } from "./SaveToHistorySettingsForm/SaveToHistorySettingsForm";
import { useTranslation } from "react-i18next";

export interface SaveToHistorySettingsProps {
  onSaveToHistory: (values: SaveToHistoryFormType) => void;
  onSkip: VoidFunction;
  isSaveProcessing: boolean;
}

export const SaveToHistorySettings: React.FC<SaveToHistorySettingsProps> = ({
  onSaveToHistory,
  onSkip,
  isSaveProcessing,
}) => {
  const { t } = useTranslation(["common", "history"]);
  const handleSubmit = useCallback(
    (value: any) => {
      const { historyTitle, historyDescription } = value;
      onSaveToHistory({ historyTitle, historyDescription });
    },
    [onSaveToHistory]
  );

  return (
    <VerticalAlignBetween className={styles.container}>
      <h1 className={styles.title}>{t("history:save-menu-to-history")}</h1>
      <VerticalAlignBetween className={styles.infoSection}>
        <div>{t("history:save-this-menu-version")}</div>
        <div>{t("history:enter-useful-name-and-description")}</div>
      </VerticalAlignBetween>
      <SaveToHistorySettingsForm
        onSubmit={handleSubmit}
        isSaveProcessing={isSaveProcessing}
      />
      <MmsSecondaryButton id="skip-save-to-history" onClick={onSkip} fullWidth>
        {t("common:skip")}
      </MmsSecondaryButton>
    </VerticalAlignBetween>
  );
};
