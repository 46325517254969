import React, { useCallback } from "react";
import { MmsFormTitle } from "@uiKit/Atoms/FormTitle/MmsFormTitle";
import { MmsMuiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMuiSelectbox";
import { useReactiveVar } from "@apollo/client";
import { spiceLevelVar } from "@utils/apolloReactiveVars";
import { curry } from "ramda";
import { useTranslation } from "react-i18next";

export interface SpiceLevelFormProps {
  menuSpiceType: number;
  onSubmit: (menuSpiceType: number) => void;
}

export const SpiceLevelForm: React.FC<SpiceLevelFormProps> = ({
  menuSpiceType,
  onSubmit,
}) => {
  const { t } = useTranslation(["menu"]);
  const spiceTypes = useReactiveVar(spiceLevelVar);

  const handleChangeFilter = useCallback(
    (selectedOption) => {
      onSubmit(selectedOption);
    },
    [onSubmit]
  );

  return (
    <div>
      <MmsFormTitle>Spice Level</MmsFormTitle>
      <MmsMuiSelectbox
        id={"menu-time-spice-level"}
        name={"spicelevel"}
        label={t("menu:level")}
        fullWidth
        value={`${menuSpiceType}`}
        onChange={curry(handleChangeFilter)}
        data={[
          ...spiceTypes.map((s, i) => {
            return {
              title: s,
              value: `${i}`,
            };
          }),
        ]}
      />
    </div>
  );
};
