import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { routes } from "./routes";

export const getRoutes = (): React.ReactNode[] =>
  routes.map(
    ({ exact, path, FeatureComponent, extraProps, isPrivate }, index) => {
      return isPrivate ? (
        <PrivateRoute
          key={index}
          exact={exact}
          path={path}
          component={(props: RouteComponentProps) => (
            <FeatureComponent {...props} {...extraProps} />
          )}
        />
      ) : (
        <Route
          key={index}
          exact={exact}
          path={path}
          component={(props: RouteComponentProps) => {
            return <FeatureComponent {...props} {...extraProps} />;
          }}
        />
      );
    }
  );
