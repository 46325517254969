import { useReactiveVar } from "@apollo/client";
import { SCREEN_SIZE_TO_CUT_HEADER_BREADCRUMBS } from "@constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { BreadcrumbType } from "@model/helperTypes/Breadcrumb";
import { HeaderBreadcrumbList } from "@sharedComponents/HeaderBreadcrumbs/HeaderBreadcrumbList/HeaderBreadcrumbList";
import { HeaderLayout } from "@sharedComponents/HeaderLayout/HeaderLayout";
import { RestaurantName } from "@sharedComponents/RestaurantName/RestaurantName";
import { AccessControl } from "@uiKit/LogicalComponents/AccessControl/AccessControl";
import { iwaiterIdVar } from "@utils/apolloReactiveVars";
import { getIsPartner } from "@utils/url";
import json2mq from "json2mq";
import React from "react";

export interface LogsHeaderProps {
  name: string;
  status: string | null;
}

const getBreadcrumbsData = (
  iwaiterId: number,
  restaurantName: string,
  isCutBreadcrumbs: boolean
) => {
  const isPartner = getIsPartner();
  return [
    isCutBreadcrumbs && {
      label: "Restaurants",
      href: `${process.env.REACT_APP_MMS2_CURRENT_ADMIN_APP_URL}`,
    },
    isCutBreadcrumbs && {
      name: "restaurantName",
      label: restaurantName,
      href: `${process.env.REACT_APP_MMS2_CURRENT_ADMIN_APP_URL}Restaurant/Edit/${iwaiterId}`,
    },
    {
      label: "Menu",
      href: `/${isPartner ? "partner" : "restaurant"}/${iwaiterId}/menu`,
    },
    { label: "Logs" },
  ].filter(Boolean) as BreadcrumbType[];
};

export const LogsHeader: React.FC<LogsHeaderProps> = ({
  name: restaurantName,
  status: restaurantStatus,
}) => {
  const iwaiterId = useReactiveVar(iwaiterIdVar);
  const isCutBreadcrumbs = useMediaQuery(
    json2mq({
      minWidth: SCREEN_SIZE_TO_CUT_HEADER_BREADCRUMBS,
    })
  );

  return (
    <HeaderLayout
      leftCol={
        <AccessControl>
          <div className="d-flex d-lg-block justify-content-center align-items-center flex-column">
            <HeaderBreadcrumbList
              breadcrumbsData={getBreadcrumbsData(
                iwaiterId,
                restaurantName,
                isCutBreadcrumbs
              )}
              restaurantStatus={restaurantStatus}
            />
            <RestaurantName
              restaurantName={restaurantName}
              className="d-block d-lg-none"
            />
          </div>
        </AccessControl>
      }
    />
  );
};
