import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = css`
  margin-bottom: 10px;

  color: ${themeColors.darkGrey};
  font-size: 10px;
  letter-spacing: 1px;
`;
