import { HeaderVisibilityEnum } from "@model/helperTypes/Header";
import { headerVisibilityVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useHeaderVisibilityControls = (): [VoidFunction, VoidFunction] => {
  const show = useCallback(() => {
    headerVisibilityVar(HeaderVisibilityEnum.visible);
  }, []);

  const hide = useCallback(() => {
    headerVisibilityVar(HeaderVisibilityEnum.hidden);
  }, []);

  return [show, hide];
};
