import { cx } from "@emotion/css";
import { MmsBaseModal } from "@uiKit/Molecules/Modals/MmsBaseModal";
import React from "react";
import { styles } from "./MenuItemChooseImageModal.styles";

interface MenuItemChooseImageModalProps {
  children: React.ReactNode;
  onClose: VoidFunction;
}

export const MenuItemChooseImageModal: React.FC<MenuItemChooseImageModalProps> = ({
  children,
  onClose,
}) => {
  return (
    <MmsBaseModal
      contentClassName={cx("menuItemChooseImageModal", styles.chooseImageModal)}
      onClose={onClose}
      closeOnEscPress={true}
    >
      {children}
    </MmsBaseModal>
  );
};
