import { ModelMenuState } from "@model/DAO/MenuState";
import { ModelNotification } from "@model/DAO/Notifications";
import { ModelWorkingUser } from "@model/DAO/WorkingUsers";
import { useGetMenuState } from "../gqlHooks/menu/queries";
import { useGetNotifications } from "../gqlHooks/notifications/queries";

export const usePollNotificationData = (): [
  ModelNotification[] | undefined,
  ModelWorkingUser[] | undefined,
  ModelMenuState | undefined
] => {
  const [, menuStateData] = useGetMenuState();

  const [, notificationsData] = useGetNotifications(
    menuStateData?.restaurant?.state!
  );

  const { notifications, workingUsers, newState } =
    notificationsData?.notifications || {};

  return [notifications, workingUsers, newState];
};
