import { useActiveAssets, useWillUnmount } from "@hooks";
import { Library } from "@model/DAO/Library";
import { generateImportFromLibraryLoaderId } from "@pages/RestaurantMenuPage/utils/Library";
import React, { useCallback, useEffect, useState } from "react";
import { LibrarySettings } from "../../components/LibrarySettings/LibrarySettings";
import { useImportAssets } from "../../gqlHooks/library/mutations";
import { useGetLibrary } from "../../gqlHooks/library/queries";
import { addUniqThrottledSuccessRegularNotification } from "../../hooks/useSuccessNotifications";
import {
  LibraryAssetSettingsContainer,
  LibraryAssetSettingsContainerProps,
} from "../LibraryAssetSettings/LibraryAssetSettings";
import { useTranslation } from "react-i18next";

export interface LibrarySettingsContainerProps {
  onFinish: VoidFunction;
}

export const LibrarySettingsContainer: React.FC<LibrarySettingsContainerProps> = ({
  onFinish,
}) => {
  // I'm not sure why we need this, but my assumtion is because we have different types of LibrarySettings
  // and local flag helps them don't interfere with each other
  const [importLoadingLocal, setImportLoadingLocal] = useState(false);
  const [assets, , cleanAssets] = useActiveAssets();
  const { t } = useTranslation(["menu-item"]);

  const [
    libraryLoading,
    libraryData,
    fetchMoreLibraryData,
    { isFetchingMore, isUpdatingCache },
  ] = useGetLibrary(assets, 1);

  const [
    onImportAssets,
    importLoading,
    importData,
    importDataError,
  ] = useImportAssets();

  const handleImport = useCallback(
    (ids, destinationId, destinationType, originType) => {
      setImportLoadingLocal(true);
      onImportAssets(
        ids,
        destinationId,
        destinationType,
        originType,
        generateImportFromLibraryLoaderId(originType)
      );
    },
    [onImportAssets]
  );

  useEffect(() => {
    if (importDataError || importLoading || !importData || !importLoadingLocal)
      return;
    onFinish();
    setImportLoadingLocal(false);
    addUniqThrottledSuccessRegularNotification(
      t("menu-item:the-asset-was-successfully-added-to-library")
    );
  }, [
    importData,
    importDataError,
    importLoading,
    importLoadingLocal,
    onFinish,
    t,
  ]);

  useWillUnmount(cleanAssets);

  if (!isUpdatingCache && !isFetchingMore && !libraryData) return null;

  return (
    <LibrarySettings<LibraryAssetSettingsContainerProps>
      libraryData={libraryData || ({} as Library)}
      assetsTabs={assets}
      onImport={handleImport}
      onCancel={onFinish}
      onFetchMore={fetchMoreLibraryData}
      importLoading={importLoading}
      libraryLoading={libraryLoading}
      isRefreshing={isFetchingMore}
      ItemComponent={LibraryAssetSettingsContainer}
    />
  );
};
