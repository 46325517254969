import usageExample from "@assets/png/usage_example.png";
import { useToggle } from "@hooks";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsCheckbox } from "@uiKit/Atoms/Checkbox/MmsCheckbox";
import React, { useCallback } from "react";
import { styles } from "./PartnerBanner.styles";
import { useTranslation } from "react-i18next";

export interface PartnerBannerProps {
  onContinueClick: (dontShowAgain: boolean) => void;
}

export const PartnerBanner: React.FC<PartnerBannerProps> = ({
  onContinueClick,
}) => {
  const { t } = useTranslation(["partner-banner", "common"]);
  const [isCheckboxEnabled, toggleCheckboxEnabled] = useToggle(false);

  const handleContinueClick = useCallback(() => {
    onContinueClick(isCheckboxEnabled);
  }, [isCheckboxEnabled, onContinueClick]);

  return (
    <div className={styles.banner}>
      <div className={styles.title}>{t("partner-banner:please-note")}</div>
      <div className={styles.text}>
        {t("partner-banner:have-to-publish-changes")}
      </div>

      <div className={styles.text}>
        {t("partner-banner:click-on-the-yellow-publish")}
      </div>

      <img src={usageExample} className={styles.image} alt="usage-example" />

      <div className={styles.text}>
        {t(
          "partner-banner:you-can-leave-your-changes-as-draft-and-publish-them-later"
        )}
      </div>

      <div className={styles.text}>
        <MmsCheckbox
          id="dont-show-partner-banner-checkbox"
          containerClassName={styles.checkbox}
          checked={isCheckboxEnabled}
          onChange={toggleCheckboxEnabled}
        />
        <label className={styles.label} onClick={toggleCheckboxEnabled}>
          {t("partner-banner:dont-show-this-message-again")}
        </label>
      </div>

      <MmsPrimaryButton
        id={`partner-banner-button`}
        onClick={handleContinueClick}
        fullWidth
      >
        {t("common:continue")}
      </MmsPrimaryButton>
    </div>
  );
};
