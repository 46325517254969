import { FormikProps } from "formik";

export type ActiveFormType = {
  formId: string;
  formAPI: FormikProps<any>;
};

export enum KeyboardKeyEnum {
  Escape = "Escape",
  Enter = "Enter",
}
