import { useReactiveVar } from "@apollo/client";
import { screenWidthVar } from "@utils/apolloReactiveVars";
import React from "react";
import { styles } from "./withMobileHack.styles";

export const withMobileHack = <P,>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P) => {
  const screenWidth = useReactiveVar(screenWidthVar);

  return (
    <div className={styles.wrapper(screenWidth)}>
      <Component {...props} />
    </div>
  );
};
