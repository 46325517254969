import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const TOGGLE_USES_POPULAR_ITEMS = gql`
  mutation ToggleUsesPopularItemsMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on Menu {
        usesPopularItems
      }
    }
  }
`;

export const generateToggleUsesPopularItemsVars = (
  restaurantId: string,
  menuId: string,
  usesPopularItems: boolean
) => {
  return {
    input: {
      restaurantId,
      entityType: Levels.MENU,
      entityId: menuId,
      menu: {
        usesPopularItems,
      },
    },
  };
};

export * as ToggleUsesPopularItemsTypes from "./__generated__/ToggleUsesPopularItemsMutation";
