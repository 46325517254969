/* eslint-disable react-hooks/exhaustive-deps */
import { cx } from "@emotion/css";
import { useActiveForms, useDidMount, useDidUpdate } from "@hooks";
import { MmsTextInput } from "@uiKit/Atoms/Inputs/MmsTextInput";
import { validateForm } from "@utils/formik";
import { Form, FormikProps, withFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import { styles } from "./MenuItemEditTitle.styles";
import { validationMap } from "./MenuItemEditTitle.validationMap";

interface FormValues {
  title: string;
}

export interface MenuItemEditTitleProps {
  menuItemId: string;
  isFormSubmitting: boolean;
  onSubmitEnded: VoidFunction;
  onPressEsc: VoidFunction;
}

const MenuItemEditTitle: React.FC<
  MenuItemEditTitleProps & FormikProps<FormValues>
> = ({
  menuItemId,
  isFormSubmitting,
  onSubmitEnded,
  onPressEsc,
  ...formikProps
}) => {
  const formId = `menu-item-edit-title-${menuItemId}`;
  const [
    activeForms,
    addToActiveForms,
    removeFromActiveForms,
  ] = useActiveForms();

  const handleBlur = useCallback(() => {
    formikProps.submitForm();
  }, []);

  const handleWarnings = useCallback((title: String) => {
    return !!title.match("^(\\w|\\d)+\\.");
  }, []);

  const handlePressEsc = useCallback(() => {
    onSubmitEnded();
    onPressEsc();
  }, []);

  useDidMount(() => {
    addToActiveForms({ formId, formAPI: formikProps });
    return () => removeFromActiveForms(formId);
  });

  useDidUpdate(() => {
    if (!isFormSubmitting) onSubmitEnded();
  }, [isFormSubmitting]);

  useEffect(() => {
    if (formikProps.isValid) return;

    if (activeForms.length > 1) {
      handlePressEsc();
    }
  }, [formikProps.isValid, activeForms]);

  return (
    <Form className={styles.form}>
      <MmsTextInput
        data-testid={formId}
        name="title"
        type="text"
        placeholder="Enter item title here"
        disabled={isFormSubmitting}
        focusOnMount
        showErrorMessage
        warningMessage="Product shouldn't contains article number in name. Please use special input for it"
        showWarningMessage={handleWarnings(formikProps?.values?.title)}
        onBlur={handleBlur}
        onPressEsc={handlePressEsc}
        fieldClassName={cx("EditMenuItemInput", styles.input)}
      />
    </Form>
  );
};

interface MenuItemEditTitleFormProps extends MenuItemEditTitleProps {
  title?: string;
  onSubmit: (values: any) => void;
}

// Wrap our form with the withFormik HoC
export const MenuItemEditTitleForm = withFormik<
  MenuItemEditTitleFormProps,
  FormValues
>({
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    title: props.title || "",
  }),
  validate: validateForm(validationMap),
  handleSubmit: (values, { props }) => {
    values.title !== props.title
      ? props.onSubmit(values)
      : props.onSubmitEnded();
  },
})(MenuItemEditTitle);
