import { useReactiveVar } from "@apollo/client";
import { BulkActionEnum } from "@model/helperTypes/BulkEditing";
import { bulkActionVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useBulkEditing = (): [
  BulkActionEnum,
  (isOpen: boolean) => void,
  (isActive: boolean) => void,
  VoidFunction
] => {
  const setExpandCollapseAction = useCallback((isExpanded: boolean) => {
    const action = isExpanded ? BulkActionEnum.collapse : BulkActionEnum.expand;
    bulkActionVar(action);
  }, []);

  const setToggleAction = useCallback((isActive: boolean) => {
    const action = isActive
      ? BulkActionEnum.toggleDeselect
      : BulkActionEnum.toggleSelect;
    bulkActionVar(action);
  }, []);

  const cleanAction = useCallback(() => {
    bulkActionVar(BulkActionEnum.default);
  }, []);

  return [
    useReactiveVar(bulkActionVar),
    setExpandCollapseAction,
    setToggleAction,
    cleanAction,
  ];
};
