import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { MmsWysiwyg } from "@uiKit/Atoms/Inputs/MmsWysiwyg";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { BtnFlatList } from "@uiKit/Molecules/BtnGroup/BtnFlatList";
import { Form, FormikProps, withFormik } from "formik";
import React from "react";
import { styles } from "./MenuItemEditDescription.styles";
import { useTranslation } from "react-i18next";

type FormValues = {
  description: string;
};

interface MenuItemEditDescriptionProps {
  menuItemId: string;
  isFormSubmitting: boolean;
  onSubmitEnded: VoidFunction;
  onCancelClick: VoidFunction;
}

const MenuItemEditDescription: React.FC<
  MenuItemEditDescriptionProps & FormikProps<FormValues>
> = ({
  menuItemId,
  isFormSubmitting,
  onSubmitEnded,
  onCancelClick,
  ...formikProps
}) => {
  const { t } = useTranslation(["common"]);
  const formId = `menu-item-edit-description-${menuItemId}`;

  return (
    <Form className={styles.form}>
      <MmsWysiwyg
        placeholder={"Enter description here"}
        wysiwygClassName={styles.wysiwyg}
        value="Lorem ipsum"
        name="description"
        data-testid={formId}
      />
      <JustifyContentEndLayout>
        <BtnFlatList
          list={[
            <MmsSecondaryButton
              onClick={onCancelClick}
              id="menu-item-edit-description-cancel"
            >
              {t("common:cancel")}
            </MmsSecondaryButton>,
            <MmsPrimaryButton
              onClick={formikProps.submitForm}
              id="menu-item-edit-description-submit"
              processing={isFormSubmitting}
              disabled={isFormSubmitting || !formikProps.dirty}
            >
              {t("common:update")}
            </MmsPrimaryButton>,
          ]}
        />
      </JustifyContentEndLayout>
    </Form>
  );
};

interface MenuItemEditDescriptionFormProps
  extends MenuItemEditDescriptionProps {
  description: string;
  onSubmit: (values: any) => void;
}

// Wrap our form with the withFormik HoC
export const MenuItemEditDescriptionForm = withFormik<
  MenuItemEditDescriptionFormProps,
  FormValues
>({
  // Transform outer props into form values
  mapPropsToValues: (props) => ({
    description: props.description,
  }),
  handleSubmit: (values, { props }) => {
    values.description !== props.description
      ? props.onSubmit(values)
      : props.onSubmitEnded();
  },
})(MenuItemEditDescription);
