import { cx } from "@emotion/css";
import React from "react";
import Container, { ContainerProps } from "react-bootstrap/Container";
import { styles } from "./styles";

export interface MmsContainerProps extends ContainerProps {}

export const MmsContainer: React.FC<MmsContainerProps> = ({
  className,
  ...props
}) => {
  return <Container {...props} className={cx(styles, className)} />;
};
