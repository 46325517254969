import { gql } from "@apollo/client";
import { IMAGE_ESSENTIAL_DATA } from "../../fragments/Image";

export const GET_RESTAURANT_GALLERY = gql`
  query GetRestaurantGalleryQuery($restaurantId: ID!, $page: Int) {
    gallery(restaurantId: $restaurantId, page: $page) {
      nodes {
        ...ImageEssentialData
      }
      pagination {
        offset
        perPage
        total
      }
    }
  }
  ${IMAGE_ESSENTIAL_DATA}
`;

export * as GetRestaurantGalleryQueryTypes from "./__generated__/GetRestaurantGalleryQuery";
