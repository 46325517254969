import { getIsPartner } from "@utils/url";
import React from "react";
import { useHistory } from "react-router-dom";

export const withIsPartnerChecking = <P,>(
  Component: React.ComponentType<P>
): React.FC<P> => (props: P) => {
  const history = useHistory();
  const isPartner = getIsPartner();

  if (isPartner) {
    history.push("/404");
    return null;
  }
  return <Component {...props} />;
};
