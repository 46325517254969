import { css } from "@emotion/css";

export const styles = {
  loader: css`
    width: 17px;
    height: 16px;

    animation: rotation 2s infinite linear;
  `,
};
