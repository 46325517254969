import { useDidMount } from "@hooks";
import PageLayoutWithMobileHack from "@sharedComponents/PageLayout/PageLayout";
import { iwaiterIdVar } from "@utils/apolloReactiveVars";
import React from "react";
import { Helmet } from "react-helmet-async";
import { LogsFiltersList } from "./components/LogsFiltersList/LogsFiltersList";
import { LogsHeader } from "./components/LogsHeader/LogsHeader";
import { LogsTable } from "./components/LogsTable/LogsTable";
import { withEssentialData, WithEssentialDataProps } from "./withEssentialData";
import { withIsPartnerChecking } from "./withIsPartnerChecking";

interface LogsPageProps extends WithEssentialDataProps {}

const LogsPage: React.FC<LogsPageProps> = ({
  restaurant,
  logs,
  isFetchingMore,
}) => {
  useDidMount(() => {
    iwaiterIdVar(restaurant.iwaiterId);
  });

  return (
    <PageLayoutWithMobileHack header={<LogsHeader {...restaurant} />}>
      <Helmet>
        <title>Logs</title>
      </Helmet>
      <LogsFiltersList />
      <LogsTable logsData={logs} disablePaginationActions={isFetchingMore} />
    </PageLayoutWithMobileHack>
  );
};

export default withIsPartnerChecking(withEssentialData(LogsPage));
