import { gql } from "@apollo/client";

export const ALLERGEN_ESSENTIAL_DATA = gql`
  fragment AllergenEssentialData on Allergen {
    id
    code
    title
    country
  }
`;
