import { gql } from "@apollo/client";
import { MENU_FLAG_ESSENTIAL_DATA } from "./MenuFlag";

export const MENU_ADDON_ESSENTIAL_DATA = gql`
  fragment MenuAddonEssentialData on MenuAddon {
    id
    iwaiterId
    priceLevel
    priceLevelDependent
    selectOne
    active
    title
    orderBy
    min
    max
    items {
      id
      iwaiterId
      active
      title
      price
      priceTakeaway
      priceDelivery
      priceDineIn
      priceLevel1
      priceLevel1Disabled
      priceLevel2
      priceLevel2Disabled
      priceLevel3
      priceLevel3Disabled
      priceLevel4
      priceLevel4Disabled
      priceLevel5
      priceLevel5Disabled
      orderBy
    }
    spiceType
    menuFlags {
      ...MenuFlagEssentialData
    }
  }
  ${MENU_FLAG_ESSENTIAL_DATA}
`;
