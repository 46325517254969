import { useOpenClose } from "@hooks";
import { LogData } from "@model/DAO/Logs";
import { generateLogActionTypeName } from "@pages/LogsPage/utils/LogActionTypeName";
import { MmsSvgButton } from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Chevron } from "@uiKit/Atoms/SVGIcons";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { MmsTableCell } from "@uiKit/Molecules/Table/MmsTableCell";
import { MmsTableRow } from "@uiKit/Molecules/Table/MmsTableRow";
import { clearTimestamp } from "@utils/dateTime";
import React, { useMemo } from "react";
import { LogsTableRowDetails } from "../LogsTableRowDetails/LogsTableRowDetails";
import { styles } from "./LogsTableRow.styles";

interface LogsTableRowProps {
  id: LogData["id"];
  entityId: LogData["entityId"];
  title: LogData["title"];
  username: LogData["username"];
  action: LogData["action"];
  entityType: LogData["entityType"];
  createdAt: LogData["createdAt"];
  changes: LogData["changes"];
  comment: LogData["comment"];
}

export const LogsTableRow: React.FC<LogsTableRowProps> = ({
  id,
  title,
  entityId,
  username,
  action,
  entityType,
  createdAt,
  ...rest
}) => {
  const [isDetailsOpen, openDetails, closeDetails] = useOpenClose(false);

  const actionTypeName = useMemo(
    () => generateLogActionTypeName(`${action}`?.toUpperCase()),
    [action]
  );

  return (
    <>
      <MmsTableRow>
        <MmsTableCell>{entityId}</MmsTableCell>
        <MmsTableCell align={"left"}>{entityType}</MmsTableCell>
        <MmsTableCell align={"left"} className={styles.titleCell}>
          {title}
        </MmsTableCell>
        <MmsTableCell>{actionTypeName}</MmsTableCell>
        <MmsTableCell align={"left"}>{username}</MmsTableCell>
        <MmsTableCell>{clearTimestamp(createdAt)}</MmsTableCell>
        <MmsTableCell>
          <VerticalAlignBetween>
            <MmsSvgButton
              data-testid={`logs-row-${id}`}
              id={`logs-row-${id}`}
              onClick={isDetailsOpen ? closeDetails : openDetails}
            >
              <Chevron
                id={`logs-row-${id}-icon`}
                direction={isDetailsOpen ? "bottom" : "right"}
              />
            </MmsSvgButton>
          </VerticalAlignBetween>
        </MmsTableCell>
      </MmsTableRow>
      {isDetailsOpen && (
        <LogsTableRowDetails actionTypeName={actionTypeName} {...rest} />
      )}
    </>
  );
};
