import { ModelWorkingUser } from "@model/DAO/WorkingUsers";
import { User } from "@uiKit/Atoms/SVGIcons";
import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import { VerticalAlignBetween } from "@uiKit/Layouts/VerticalAlignBetween";
import { MmsPopper, MmsPopperProps } from "@uiKit/Molecules/Popper/MmsPopper";
import React from "react";
import { styles } from "./WorkingUsers.styles";
import { useTranslation } from "react-i18next";

export interface WorkingUsersPopperProps {
  id: string;
  anchorEl: MmsPopperProps["anchorEl"];
  handleClosePopper: VoidFunction;
  workingUsers?: ModelWorkingUser[];
}

export const WorkingUsersPopper: React.FC<WorkingUsersPopperProps> = ({
  id,
  handleClosePopper,
  anchorEl,
  workingUsers,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <MmsPopper
      id={id}
      onClose={handleClosePopper}
      anchorEl={anchorEl}
      className={styles.popper}
    >
      <VerticalAlignBetween className={styles.container}>
        {workingUsers?.map((user) => (
          <div key={user.id}>
            <JustifyContentBetweenLayout className={styles.listItem}>
              <User id={`working-user-${user.name}-header-icon`} size="small" />
              <div className={styles.btnText}>
                {`${user.name}${user.current ? ` (${t("common:you")})` : ""}`}
              </div>
            </JustifyContentBetweenLayout>
          </div>
        ))}
      </VerticalAlignBetween>
    </MmsPopper>
  );
};
