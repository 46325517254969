import { useEffect, useCallback } from "react";
import { useRefreshToken } from "../gqlHooks/auth/mutations";
import { getTokenLifeTime } from "../../../utils/jwt";
import { useLocalStorage, useInterval, useDidMount } from "@hooks";
import {
  JWT_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from "../constants/localStorageKeys";
import { getIsPartner } from "@utils/url";

const REFRESH_TOKEN_INTERVAL = 25 * 60000; // 25 mins

export const useRefreshTokenByInterval = () => {
  const isPartner = getIsPartner();
  const [jwtToken, setJwtToken] = useLocalStorage(JWT_TOKEN_KEY, "");
  const [refreshToken, setRefreshToken] = useLocalStorage(
    REFRESH_TOKEN_KEY,
    ""
  );
  const tokenLifeTime = isPartner ? null : getTokenLifeTime(jwtToken as string);

  const [refreshTokenFn, , data] = useRefreshToken();

  // check if should be refreshed before starting the interval
  useDidMount(() => {
    if (!tokenLifeTime) return;

    if (tokenLifeTime < REFRESH_TOKEN_INTERVAL) {
      refreshTokenFn(jwtToken, refreshToken!);
    }
  });

  const intervalCallback = useCallback(() => {
    if (isPartner || !jwtToken || !refreshToken) return;
    refreshTokenFn(jwtToken, refreshToken);
  }, [jwtToken, refreshToken, refreshTokenFn, isPartner]);

  useInterval(intervalCallback, REFRESH_TOKEN_INTERVAL);

  useEffect(() => {
    if (isPartner || !data?.authRefresh) return;

    setJwtToken(data?.authRefresh.jwtToken);
    setRefreshToken(data?.authRefresh.refreshToken);
  }, [data, setJwtToken, setRefreshToken, isPartner]);
};
