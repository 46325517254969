import { css } from "@emotion/css";

export const styles = {
  form: css`
    display: flex;

    .MuiFormControl-root {
      width: 60px;
      margin: 0 10px;
    }
  `,
};
