import { ModelLocalAddonItem } from "../DAO/MenuAddonItem";

export enum AddonTypeEnum {
  priceLevel = "priceLevel",
  selectOne = "selectOne",
  selectMultiple = "selectMultiple",
}

export enum AddonStrategyEnum {
  PRICE_LEVEL__CATEGORY_OWNER = "PRICE_LEVEL__CATEGORY_OWNER",
  PRICE_LEVEL__MENU_ITEM_OWNER = "PRICE_LEVEL__MENU_ITEM_OWNER",
  REGULAR__PRICE_LEVEL_DEPENDENT = "REGULAR__PRICE_LEVEL_DEPENDENT",
  REGULAR__PRICE_LEVEL_INDEPENDENT = "REGULAR__PRICE_LEVEL_INDEPENDENT",
}

// todo: need to separate menuItem on menuItemAttached and menuItemNotAttached
export enum AddonsOwnerEnum {
  menuItem = "menuItem",
  category = "category",
}

export type LocalAddonItemsMeta = {
  localAddonItems: ModelLocalAddonItem[];
  newAddonItems: ModelLocalAddonItem[];
  changedAddonItems: ModelLocalAddonItem[];
  removedAddonItems: ModelLocalAddonItem[];
};

export type AddonTypeData = { title: string; value: AddonTypeEnum };
