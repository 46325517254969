import { css } from "@emotion/css";

export const styles = {
  title: css`
    font-weight: 300;
    line-height: 30px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  baseControl: css`
    padding: 0 7px;
  `,
  controls: css`
    display: flex;
    align-items: center;
  `,
};
