import { injectGlobal } from "@emotion/css";
import { themeColors } from "@theme/colors";
import { themeUtils } from "@theme/utils";
import { themeVars } from "@theme/vars";

injectGlobal`
.MmsMuiSnackbar {
  position: relative;
  margin-top: 6%;
  padding: 15px 17px;

  border-radius: 10px !important;

  background-color: ${themeColors.white} !important;

  font-family: ${themeVars.fontFamily};
  font-size: 14px;

  box-shadow: ${themeUtils.boxShadowSettings(
    80
  )} ${themeUtils.boxShadowOpacity()};
}`;
