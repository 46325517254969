import { css } from "@emotion/css";

export const styles = {
  header: css`
    margin-bottom: 25px;
  `,
  colorList: css`
    display: flex;
    flex-wrap: wrap;
  `,
  colorItem: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100px;
    margin: 15px;
  `,
  color: (color: string) => css`
    position: relative;
    width: 100%;
    height: 50px;

    background-color: ${color};
  `,
  colorTitle: css`
    font-size: 12px;
    line-height: 1.1;

    overflow-wrap: break-word;
  `,
};
