import { useDidUpdate } from "@hooks";
import { ModelCategoryWithMenuItemsAmount } from "@model/DAO/MenuCategory";
import { noop } from "@utils/noop";
import React, { useCallback, useState } from "react";
import { Category } from "../../components/Category/Category";
import { useCreateCategory } from "../../gqlHooks/category/mutations";

export interface NewCategoryContainerProps {
  newCategoryFixture: ModelCategoryWithMenuItemsAmount;
  onCategoryCreated: VoidFunction;
  onDeleteClick: VoidFunction;
}

export const NewCategoryContainer: React.FC<NewCategoryContainerProps> = ({
  newCategoryFixture,
  onCategoryCreated,
  onDeleteClick,
}) => {
  const [newLocalCategory, setNewLocalCategory] = useState(newCategoryFixture);
  const [createCategoryMutation, isCreateCategoryLoading] = useCreateCategory();

  const toggleNewCategory = useCallback(() => {
    setNewLocalCategory({
      ...newLocalCategory,
      active: !newLocalCategory.active,
    });
  }, [newLocalCategory]);

  const createNewCategory = useCallback(
    (values: any) => {
      const nextCategoryState = { ...newLocalCategory, title: values.title };
      setNewLocalCategory(nextCategoryState);
      createCategoryMutation(nextCategoryState);
    },
    [createCategoryMutation, newLocalCategory]
  );

  useDidUpdate(() => {
    !isCreateCategoryLoading && onCategoryCreated();
  }, [isCreateCategoryLoading, onCategoryCreated]);

  return (
    <Category
      category={newLocalCategory}
      onToggle={toggleNewCategory}
      onDelete={isCreateCategoryLoading ? noop : onDeleteClick}
      onClone={noop}
      onShowCommonSettings={noop}
      onShowAddons={noop}
      onChangeTitle={createNewCategory}
      isActive={newLocalCategory.active}
      onToggleCategoryExpanded={noop}
      onToggleCategoryExpandedManual={noop}
      onDescriptionClick={noop}
      isCategoryExpanded={false}
      isEditTitleLoading={isCreateCategoryLoading}
      isNew
      modals={null}
    >
      {null}
    </Category>
  );
};
