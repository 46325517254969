import { css } from "@emotion/css";
import { themeUtils } from "@theme/utils";

export const styles = {
  container: css`
    display: flex;
    max-width: 100%;
  `,
  title: (isEditModeDisabled: boolean) => css`
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${!isEditModeDisabled &&
    css`
      cursor: pointer;
      ${themeUtils.underlineOnHover}
    `}
  `,
};
