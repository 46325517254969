import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 480px;
  `,
  flexEdge: css`
    flex: 0;
  `,
  flexCenter: css`
    flex: 1;
  `,
  header: css`
    padding: 15px 30px;

    border-bottom: 2px solid ${themeColors.cream};

    background-color: ${themeColors.white};

    font-size: 20px;
    font-weight: bold;
  `,
  footer: css`
    padding: 25px 30px;
  `,
  btnGroup: css`
    gap: 20px;
  `,
  formGroup: css`
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  mainContent: css`
    padding: 25px 30px 0;
  `,
};
