import { useReactiveVar } from "@apollo/client";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { activeAddonVar } from "@utils/apolloReactiveVars";
import { useCallback } from "react";

export const useActiveAddon = (): [
  ModelMenuAddon["id"],
  (addonId: ModelMenuAddon["id"]) => void,
  VoidFunction
] => {
  const addAddon = useCallback((addonId: ModelMenuAddon["id"]) => {
    activeAddonVar(addonId);
  }, []);

  const deleteAddon = useCallback(() => {
    activeAddonVar("");
  }, []);

  return [useReactiveVar(activeAddonVar), addAddon, deleteAddon];
};
