import { LogActionsEnum } from "@model/helperTypes/LogFilters";

export const generateLogActionTypeName = (action: string) => {
  switch (action) {
    case LogActionsEnum.CREATE:
      return "Create";
    case LogActionsEnum.UPDATE:
      return "Update";
    case LogActionsEnum.DESTROY:
      return "Delete";
    default:
      return "";
  }
};
