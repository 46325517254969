import { SCREEN_SIZES } from "@constants";
import { css } from "@emotion/css";

export const styles = {
  container: css`
    display: flex;
    gap: 10px;

    font-size: 22px;
    font-weight: 300;
  `,
  breadcrumb: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    cursor: pointer;
    /* mobile view */
    /*
    @media (max-width: ${SCREEN_SIZES.LG - 1}px) {
      font-size: 16px;
      line-height: 1.3;
    } */
  `,
  septum: css`
    @media (max-width: ${SCREEN_SIZES.LG - 1}px) {
      font-size: 16px;
      line-height: 1.3;
    }
  `,
};
