import {
  NetworkStatus,
  useApolloClient,
  useLazyQuery,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { useDidUpdate, useErrorHandler } from "@hooks";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import {
  GetAllCategoriesMenuItemsTypes,
  GET_ALL_CATEGORIES_MENU_ITEMS,
} from "@pages/RestaurantMenuPage/graphql/queries/GetAllCategoriesMenuItemsQuery";
import {
  GetMenuItemsByCatTypes,
  GET_MENU_ITEMS_BY_CAT,
} from "@pages/RestaurantMenuPage/graphql/queries/GetMenuItemsByCat";
import { formatMenuFiltersVars } from "@pages/RestaurantMenuPage/utils/MenuFilters";
import {
  allCategoriesMenuItemsVar,
  menuFiltersVar,
  menuIdVar,
} from "@utils/apolloReactiveVars";
import { useCallback } from "react";

interface QueryResult<T> {
  loading: boolean;
  data?: T;
  refetch?: VoidFunction;
  networkStatus?: NetworkStatus;
}

export const useGetMenuItems = (
  categoryId: string
): [boolean, GetMenuItemsByCatTypes.GetMenuItemsByCatQuery?] => {
  const [, { onError }] = useErrorHandler([]);
  const {
    loading,
    data,
  } = useQuery<GetMenuItemsByCatTypes.GetMenuItemsByCatQuery>(
    GET_MENU_ITEMS_BY_CAT,
    { variables: { categoryId }, onError }
  );

  return [loading, data];
};

export const useCachedGetMenuItems = (): [
  (categoryId: string) => ModelMenuItem[]
] => {
  const client = useApolloClient();

  const getCachedDataWithVars = useCallback(
    (categoryId: string) => {
      const data = client.readQuery<GetMenuItemsByCatTypes.GetMenuItemsByCatQuery>(
        {
          query: GET_MENU_ITEMS_BY_CAT,
          variables: { categoryId },
        }
      );

      return data?.category.items || [];
    },
    [client]
  );

  return [getCachedDataWithVars];
};

export const useLazyGetAllCategoriesMenuItems = (): [
  VoidFunction,
  QueryResult<GetAllCategoriesMenuItemsTypes.GetAllCategoriesMenuItemsQuery>
] => {
  const menuId = useReactiveVar(menuIdVar);
  const filters = useReactiveVar(menuFiltersVar);
  const [, { onError }] = useErrorHandler([]);

  const [
    lazyQuery,
    result,
  ] = useLazyQuery<GetAllCategoriesMenuItemsTypes.GetAllCategoriesMenuItemsQuery>(
    GET_ALL_CATEGORIES_MENU_ITEMS,
    {
      notifyOnNetworkStatusChange: true,
      onError,
    }
  );

  const lazyQueryWithVars = useCallback(() => {
    lazyQuery({
      variables: { menuId, filters: formatMenuFiltersVars(filters) },
    });
  }, [filters, lazyQuery, menuId]);

  const refetchWithVars = useCallback(() => {
    result.fetchMore?.({
      variables: {
        menuId,
        filters: formatMenuFiltersVars(filters),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return fetchMoreResult || prev;
      },
    });
  }, [filters, menuId, result]);

  useDidUpdate(() => {
    if (!result.data) return;
    allCategoriesMenuItemsVar(result.data.menu.categories);
  }, [result]);

  return [lazyQueryWithVars, { ...result, refetch: refetchWithVars }];
};
