import {
  ModelCategoryItemsIds,
  ModelCategoryWithMenuItemsAmount,
  ModelFullCategory,
} from "@model/DAO/MenuCategory";
import { pick } from "ramda";
import { getNewOrderBy } from "./GenericEntity";

export const getCategoriesIds = (categories: ModelFullCategory[]) => {
  return categories.filter((c) => c.isFreeItem !== true).map(({ id }) => id);
};

export const getNewCategoryFixture = (
  categoryList: ModelFullCategory[]
): ModelCategoryWithMenuItemsAmount => ({
  id: "dummy",
  menuItemsAmount: 0,
  iwaiterId: null,
  title: "",
  description: "",
  active: true,
  activeFrom: null,
  activeTo: null,
  disabledOnDays: null,
  discount: null,
  takeawayDiscount: null,
  deliveryDiscount: null,
  addons: [],
  orderBy: getNewOrderBy<ModelFullCategory>(categoryList),
  items: [],
  taxAssociations: [],
  menuFlags: [],
  isFreeItem: false,
  minBasketValue: null,
  __typename: "MenuCategory",
});

export const getNewFreeItemCategoryFixture = (): ModelCategoryWithMenuItemsAmount => ({
  id: "dummy",
  menuItemsAmount: 0,
  iwaiterId: null,
  title: "Free Item",
  description: "",
  active: false,
  activeFrom: null,
  activeTo: null,
  disabledOnDays: null,
  discount: null,
  takeawayDiscount: null,
  deliveryDiscount: null,
  addons: [],
  orderBy: 0,
  items: [],
  taxAssociations: [],
  menuFlags: [],
  isFreeItem: true,
  minBasketValue: null,
  __typename: "MenuCategory",
});

export const getCategoryMenuItemsAmount = (
  currentCategoryId: ModelFullCategory["id"],
  categoriesItemsIds: ModelCategoryItemsIds[]
) =>
  categoriesItemsIds.reduce((accum, { id, items }) => {
    return id !== currentCategoryId ? accum : accum + items.length;
  }, 0);

export const extractCategoriesItemsIdsFromCategoryList = (
  categoryList: ModelFullCategory[]
): ModelCategoryItemsIds[] => {
  return categoryList.map((category) => pick(["id", "items"], category));
};
