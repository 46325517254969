import { useMutation } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { MatchParams } from "../../RestaurantMenuPage";
import {
  DESTROY_SCHEDULE_TASK,
  DestroyScheduleTaskTypes,
  generateDestroyScheduleTaskVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/DestroyScheduleTask";
import {
  UPDATE_SCHEDULE_TASK,
  UpdateScheduleTaskTypes,
  generateUpdateScheduleTaskVars,
} from "@pages/RestaurantMenuPage/graphql/mutatioins/UpdateScheduleTask";

export const useDestroyScheduleTask = (id: string): [VoidFunction, boolean] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<DestroyScheduleTaskTypes.DestroyScheduleTaskMutation>(
    DESTROY_SCHEDULE_TASK,
    { onError }
  );

  const mutationWithVars = useCallback(() => {
    mutation({
      variables: generateDestroyScheduleTaskVars(restaurantId, id),
      update(cache) {
        cache.evict({ id: `MenuScheduleTask:${id}` });
      },
    });
  }, [id, mutation, restaurantId]);

  return [mutationWithVars, loading];
};

export const useUpdateScheduleTask = (
  id: string
): [(runAt: Date) => void, boolean] => {
  const { restaurantId } = useParams<MatchParams>();
  const [, { onError }] = useErrorHandler([]);

  const [
    mutation,
    { loading },
  ] = useMutation<UpdateScheduleTaskTypes.UpdateScheduleTaskMutation>(
    UPDATE_SCHEDULE_TASK,
    { onError }
  );

  const mutationWithVars = useCallback(
    (runAt: Date) => {
      mutation({
        variables: generateUpdateScheduleTaskVars(restaurantId, id, runAt),
      });
    },
    [id, mutation, restaurantId]
  );

  return [mutationWithVars, loading];
};
