import {
  MmsSvgButton,
  MmsSvgButtonProps,
} from "@uiKit/Atoms/Buttons/MmsSvgButton";
import { Back } from "@uiKit/Atoms/SVGIcons";
import React from "react";

interface RestoreMenuButtonProps extends MmsSvgButtonProps {}

export const RestoreMenuButton = React.forwardRef<
  HTMLButtonElement,
  RestoreMenuButtonProps
>((props, ref) => {
  return (
    <MmsSvgButton {...props} ref={ref}>
      <Back id={`${props.id}-icon`} size="large" />
    </MmsSvgButton>
  );
});
