import { useQuery, useReactiveVar } from "@apollo/client";
import { useErrorHandler } from "@hooks";
import {
  GetCountryAdditivesTypes,
  GET_COUNTRY_ADDITIVES,
} from "@pages/RestaurantMenuPage/graphql/queries/GetCountryAdditives";
import {
  countryAdditivesVar,
  restaurantCachedDataVar,
} from "@utils/apolloReactiveVars";
import { useEffect } from "react";

export const useGetCountryAdditives = (): void => {
  const [, { onError }] = useErrorHandler([]);
  const { country } = useReactiveVar(restaurantCachedDataVar);

  const { data } = useQuery<GetCountryAdditivesTypes.GetCountryAdditivesQuery>(
    GET_COUNTRY_ADDITIVES,
    {
      variables: { country },
      onError,
    }
  );

  useEffect(() => {
    if (!data) return;

    countryAdditivesVar(data.additives);
  }, [data]);
};
