export enum BulkActionEnum {
  default = "default",
  expand = "expand",
  collapse = "collapse",
  toggleSelect = "toggleSelect",
  toggleDeselect = "toggleDeselect",
}

export enum BulkMoreActionsEnum {
  discountUpdate = "discountUpdate",
  availabilityUpdate = "availabilityUpdate",
  menuItemPricesUpdate = "menuItemPricesUpdate",
  addonItemPricesUpdate = "addonItemPricesUpdate",
}

export enum BulkPriceUpdateOptionsEnum {
  increaseByPercent = "priceIncOnPercent",
  decreaseByPercent = "priceDecOnPercent",
  increaseByValue = "priceIncOnValue",
  decreaseByValue = "priceDecOnValue",
}

export enum BulkRequestActionEnum {
  enable = "enable",
  disable = "disable",
  destroy = "destroy",
  update = "update",
}

export enum LevelsBulkEnum {
  category = "category",
  menu_item = "menu_item",
}

export enum SubLevelsBulk {
  addon_item = "addon_item",
}
