import { useState } from "react";

export const useOpenCloseWithData = <T>(
  initOpen: boolean,
  initData?: T
): [boolean, (data?: T) => void, VoidFunction, T?] => {
  const [isOpen, setIsOpen] = useState(initOpen);
  const [openData, setOpenData] = useState(initData);

  const open = (data?: T) => {
    setIsOpen(true);
    if (data) {
      setOpenData(data);
    }
  };

  const close = () => {
    setIsOpen(false);
    if (openData) {
      setOpenData(initData);
    }
  };

  return [isOpen, open, close, openData];
};
