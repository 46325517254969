import { useReactiveVar } from "@apollo/client/react/hooks/useReactiveVar";
import { ModelCategoryDNA } from "@model/DAO/MenuCategory";
import { CommonSettingsLayout } from "@pages/RestaurantMenuPage/components/CommonSettings/Layout/Layout";
import { StatusBarLayout } from "@pages/RestaurantMenuPage/components/CommonSettings/StatusBarLayout/StatusBarLayout";
import { CommonSettingsTabPanelLayout } from "@pages/RestaurantMenuPage/components/CommonSettings/TabPanelLayout";
import { MmsPrimaryButton } from "@uiKit/Atoms/Buttons/MmsPrimaryButton";
import { MmsSecondaryButton } from "@uiKit/Atoms/Buttons/MmsSecondaryButton";
import { JustifyContentEndLayout } from "@uiKit/Layouts/JustifyContentEndLayout";
import { BtnFlatList } from "@uiKit/Molecules/BtnGroup/BtnFlatList";
import { MmsTab } from "@uiKit/Molecules/Tabs/MmsTab";
import { countryTaxesVar, menuFlagsVar } from "@utils/apolloReactiveVars";
import React, { useState } from "react";
import { Levels, TaxAssociationData } from "__generated__/globalTypes";
import { CommonSettingContentTitle } from "../CommonSettings/ContentTitle/ContentTitle";
import { NewStatusBarTitle } from "../NewAddonsSettings/NewStatusBar/Title/Title";
import { styles } from "./CategorySettings.styles";
import { DiscountAndAvailability } from "./Discount&Availability/Discount&Availability";
import { Taxes } from "./Taxes/Taxes";
import { MenuFlagsForm } from "@pages/RestaurantMenuPage/components/MenuItemSettings";
import { ModelMenuFlag } from "@model/DAO/MenuFlag";
import { useTranslation } from "react-i18next";
import { MinBasketValueForm } from "./MinBasketValueForm/MinBasketValueForm";

export interface CategorySettingsProps {
  categoryDNA: ModelCategoryDNA;
  categoryTaxes: TaxAssociationData[];
  categoryMenuFlags: ModelMenuFlag[];
  categoryTitle: string;
  isUpdateProcessing: boolean;
  isDNAChanged: boolean;
  haveTaxesChanged: boolean;
  preserveLocalDNA: (newCategoryDNA: ModelCategoryDNA) => void;
  preserveLocalTaxes: (newTaxes: TaxAssociationData[]) => void;
  preserveLocalMenuFlags: (menuFlagIds: ModelMenuFlag[]) => void;
  haveMenuFlagsChanged: boolean;
  onCancelClick: VoidFunction;
  onUpdateClick: VoidFunction;
  isFreeItem: boolean | null;
}

export enum CategorySettingsLevel {
  Discount,
  Availability,
  Taxes,
  MenuFlags,
  MinBasketValue,
}

export const CategorySettings: React.FC<CategorySettingsProps> = ({
  categoryDNA,
  categoryTitle,
  isUpdateProcessing,
  isDNAChanged,
  haveTaxesChanged,
  preserveLocalDNA,
  onCancelClick,
  onUpdateClick,
  categoryTaxes,
  haveMenuFlagsChanged,
  categoryMenuFlags,
  preserveLocalTaxes,
  preserveLocalMenuFlags,
  isFreeItem,
}) => {
  const menuFlags = useReactiveVar(menuFlagsVar);
  const { t } = useTranslation(["common", "discounts", "taxes", "category"]);
  const countryTaxes = useReactiveVar(countryTaxesVar);
  const settingsOptions = [
    ...(isFreeItem
      ? [
          {
            id: CategorySettingsLevel.MinBasketValue,
            title: t("common:min-basket-value"),
          },
        ]
      : []),
    ...(!isFreeItem
      ? [
          {
            id: CategorySettingsLevel.Discount,
            title: t("discounts:discount"),
          },
        ]
      : []),
    {
      id: CategorySettingsLevel.Availability,
      title: t("discounts:availability"),
    },
    ...(!isFreeItem
      ? [
          {
            id: CategorySettingsLevel.Taxes,
            title: t("taxes:taxes"),
          },
        ]
      : []),
    {
      id: CategorySettingsLevel.MenuFlags,
      title: t("category:menu-flags"),
    },
  ];
  const [
    settingsTabSelected,
    setSettingsTabSelected,
  ] = useState<CategorySettingsLevel>(0);

  const onSelect = (option: CategorySettingsLevel) => {
    setSettingsTabSelected(option);
  };

  const uniqId = categoryDNA?.id;

  const preserveLocalMinBasketValue = (minBasketValue: number | null): void =>
    preserveLocalDNA({
      ...categoryDNA,
      minBasketValue: minBasketValue,
    });

  return (
    <CommonSettingsLayout
      statusBar={
        <StatusBarLayout
          leftCol={
            <NewStatusBarTitle
              title={t("common:item_settings", { title: categoryTitle })}
            />
          }
        />
      }
      leftColContent={
        <>
          {settingsOptions.map((key, idx) => {
            if (
              key.id === CategorySettingsLevel.Taxes &&
              countryTaxes.length === 0
            ) {
              return null;
            } else {
              return (
                <MmsTab
                  tabIndex={idx}
                  isSelected={settingsTabSelected === idx}
                  onSelect={onSelect}
                  key={CategorySettingsLevel[idx]}
                >
                  <CommonSettingsTabPanelLayout>
                    {key.title}
                  </CommonSettingsTabPanelLayout>
                </MmsTab>
              );
            }
          })}
        </>
      }
      rightColHeaderTitle={
        <CommonSettingContentTitle
          uniqId={categoryDNA.id}
          title={settingsOptions[settingsTabSelected].title}
          isEditModeDisabled
        />
      }
      rightColContent={
        {
          [CategorySettingsLevel.Discount]: (
            <DiscountAndAvailability
              categoryDNA={categoryDNA}
              preserveLocalDNA={preserveLocalDNA}
              isDiscountTab={true}
            />
          ),
          [CategorySettingsLevel.Availability]: (
            <DiscountAndAvailability
              categoryDNA={categoryDNA}
              preserveLocalDNA={preserveLocalDNA}
              isDiscountTab={false}
            />
          ),
          [CategorySettingsLevel.Taxes]: (
            <Taxes
              associatedTaxes={categoryTaxes}
              preserveLocalTaxes={preserveLocalTaxes}
              uniqId={uniqId}
              entityType={Levels.CATEGORY}
            />
          ),
          [CategorySettingsLevel.MenuFlags]: (
            <MenuFlagsForm
              isAlwaysOpen={false}
              menuFlags={menuFlags}
              selectedMenuFlags={categoryMenuFlags || []}
              onSubmit={preserveLocalMenuFlags}
            />
          ),
          [CategorySettingsLevel.MinBasketValue]: (
            <MinBasketValueForm
              minBasketValue={categoryDNA.minBasketValue}
              onSubmit={preserveLocalMinBasketValue}
              uniqId={uniqId}
            />
          ),
        }[settingsOptions[settingsTabSelected].id]
      }
      rightColFooter={
        <JustifyContentEndLayout className={styles.btnGroup}>
          <BtnFlatList
            list={[
              <MmsSecondaryButton
                id="category-settings-cancel"
                className={styles.commonBtn}
                onClick={onCancelClick}
                disabled={isUpdateProcessing}
              >
                {t("common:cancel")}
              </MmsSecondaryButton>,
              <MmsPrimaryButton
                id="category-settings-update"
                className={styles.commonBtn}
                onClick={onUpdateClick}
                processing={isUpdateProcessing}
                disabled={
                  !isDNAChanged &&
                  !haveTaxesChanged &&
                  !haveMenuFlagsChanged &&
                  !!categoryTaxes
                }
              >
                {t("common:update")}
              </MmsPrimaryButton>,
            ]}
          />
        </JustifyContentEndLayout>
      }
    />
  );
};
