import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  marker: css`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: ${themeColors.red};
  `,
};
