import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  wrapper: css`
    position: relative;
  `,
  container: (isDragging: boolean) => css`
    align-items: center;

    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;

    background-color: ${isDragging ? themeColors.cream : "transparent"};

    border-bottom: solid 1px ${themeColors.borderLightGrey};

    opacity: ${isDragging ? 0.5 : 1};

    &:hover {
      img {
        opacity: 0.7;
      }
    }
  `,
  handle: (isDragAndDropDisabled: boolean) => css`
    display: flex;
    align-items: center;

    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 18px;

    cursor: ${isDragAndDropDisabled ? "auto" : "move"};

    img {
      width: 100%;

      opacity: 0;
      ${isDragAndDropDisabled &&
      css`
        display: none; ;
      `}
    }
  `,
  baseControl: css`
    padding: 0 4px 0 3px;
  `,
  logsControl: css`
    padding-bottom: 2px;
  `,
  controls: css`
    display: flex;
    align-items: center;
  `,
};
