import { gql } from "@apollo/client";
import { LogFilters } from "@model/helperTypes/LogFilters";

export const GET_LOGS = gql`
  query GetLogs($restaurantId: ID!, $page: Int!, $filter: LogsFilter) {
    logs(restaurantId: $restaurantId, page: $page, filter: $filter) {
      nodes {
        id
        userId
        username
        title
        requestUuid
        comment
        action
        associatedId
        associatedType
        entityType
        entityId
        changes
        createdAt
      }
      pagination {
        offset
        perPage
        total
      }
    }
  }
`;

export const generateGetLogsVars = (
  restaurantId: string,
  page: number,
  filter?: LogFilters
) => {
  if (filter) return { variables: { restaurantId, page, filter } };
  return { variables: { restaurantId, page } };
};

export * as GetLogsTypes from "./__generated__/GetLogs";
