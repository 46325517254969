import { isNotEmpty } from "@utils/ramdaHelpers";
import { useCallback } from "react";
import { useActiveForms } from "./useActiveForms";

export const useFormErrorsProtection = () => {
  const [activeForms] = useActiveForms();

  const withActiveFormsErrorsProtection = useCallback(
    (fn, shouldSkipProtection = false) => async (...args: any) => {
      const formsWithErrors = (
        await Promise.all(
          activeForms.map(async ({ formAPI }) => {
            const error = await formAPI.validateForm();
            return error;
          })
        )
      ).filter(isNotEmpty);

      if (formsWithErrors.length && !shouldSkipProtection) return;
      return fn(...args);
    },
    [activeForms]
  );

  return [withActiveFormsErrorsProtection];
};
