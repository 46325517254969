/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionType {
  CREATE = "CREATE",
  DESTROY = "DESTROY",
  UPDATE = "UPDATE",
}

export enum BulkActions {
  DESTROY = "DESTROY",
  DISABLE = "DISABLE",
  ENABLE = "ENABLE",
  UPDATE = "UPDATE",
}

export enum CloneLevels {
  CATEGORY = "CATEGORY",
  MENU_ITEM = "MENU_ITEM",
}

/**
 * Enums of countries
 */
export enum Countries {
  AT = "AT",
  AU = "AU",
  CA = "CA",
  DE = "DE",
  DK = "DK",
  FR = "FR",
  GB = "GB",
  GE = "GE",
  IE = "IE",
  MM = "MM",
  NO = "NO",
  NZ = "NZ",
  SE = "SE",
}

/**
 * Levels
 */
export enum Levels {
  ADDON = "ADDON",
  ADDON_ITEM = "ADDON_ITEM",
  CATEGORY = "CATEGORY",
  MENU = "MENU",
  MENU_ITEM = "MENU_ITEM",
}

/**
 * Origin entity type [category, menu_item, addon, addon_item]
 */
export enum LevelsAdd {
  ADDON = "ADDON",
  ADDON_ITEM = "ADDON_ITEM",
  CATEGORY = "CATEGORY",
  MENU = "MENU",
  MENU_ITEM = "MENU_ITEM",
}

/**
 * Entity type (the place to where we add new node) [menu, category, menu_item, addon]
 */
export enum LevelsAddEntry {
  ADDON = "ADDON",
  CATEGORY = "CATEGORY",
  MENU = "MENU",
  MENU_ITEM = "MENU_ITEM",
}

export enum LevelsBulk {
  CATEGORY = "CATEGORY",
  MENU_ITEM = "MENU_ITEM",
}

/**
 * Origin source [menu, category, menu_item, addon]
 */
export enum LevelsLibAdd {
  ADDON = "ADDON",
  CATEGORY = "CATEGORY",
  MENU = "MENU",
  MENU_ITEM = "MENU_ITEM",
}

export enum LevelsLibDestination {
  CATEGORY = "CATEGORY",
  MENU = "MENU",
  MENU_ITEM = "MENU_ITEM",
}

export enum LevelsRerank {
  CATEGORY = "CATEGORY",
  MENU = "MENU",
}

export enum LogAuditableType {
  Menu = "Menu",
  MenuAddon = "MenuAddon",
  MenuAddonItem = "MenuAddonItem",
  MenuCategory = "MenuCategory",
  MenuItem = "MenuItem",
}

export enum NotificationActions {
  BLOCK = "BLOCK",
  SKIP = "SKIP",
}

export enum NotificationCodes {
  MENU_IMPORTED = "MENU_IMPORTED",
  MENU_NOT_FOUND = "MENU_NOT_FOUND",
  MENU_PUBLISHED = "MENU_PUBLISHED",
  MENU_RESTORED = "MENU_RESTORED",
  MENU_UPDATED = "MENU_UPDATED",
}

/**
 *  Service Types
 */
export enum ServiceType {
  Delivery = "Delivery",
  DineIn = "DineIn",
  Pickup = "Pickup",
}

export enum SubLevelsBulk {
  ADDON_ITEM = "ADDON_ITEM",
}

/**
 * Tax Inclusion Types
 */
export enum TaxInclusionType {
  Additive = "Additive",
  Inclusive = "Inclusive",
}

/**
 * Addon input data
 */
export interface AddonData {
  destroy?: boolean | null;
  active?: boolean | null;
  delayEnableAt?: any | null;
  id?: string | null;
  itemsAttributes?: (AddonItemData | null)[] | null;
  orderBy?: number | null;
  priceLevel?: boolean | null;
  priceLevelDependent?: boolean | null;
  selectOne?: boolean | null;
  title?: string | null;
  menuFlagIds?: (any | null)[] | null;
  spiceType?: number | null;
}

/**
 * The AddonItem data
 */
export interface AddonItemData {
  destroy?: boolean | null;
  active?: boolean | null;
  delayEnableAt?: any | null;
  id?: string | null;
  orderBy?: number | null;
  price?: number | null;
  priceLevel1?: number | null;
  priceLevel2?: number | null;
  priceLevel3?: number | null;
  priceLevel4?: number | null;
  priceLevel5?: number | null;
  title?: string | null;
}

export interface AuthLoginData {
  code?: string | null;
  idToken?: string | null;
  nonce?: string | null;
  redirectUri?: string | null;
}

export interface AuthRefreshData {
  jwtToken?: string | null;
  refreshToken?: string | null;
}

/**
 * The Bulk input
 */
export interface BulkData {
  restaurantId: string;
  levelType: LevelsBulk;
  subLevelType?: SubLevelsBulk | null;
  ids: string[];
  bulkAction: BulkActions;
  params?: BulkParams | null;
}

/**
 * Bulk Params
 */
export interface BulkParams {
  priceIncOnPercent?: number | null;
  priceDecOnPercent?: number | null;
  priceIncOnValue?: number | null;
  priceDecOnValue?: number | null;
  discount?: number | null;
  deliveryDiscount?: number | null;
  takeawayDiscount?: number | null;
  activeFrom?: string | null;
  activeTo?: string | null;
  active?: boolean | null;
  delayEnableAt?: any | null;
}

/**
 * Category input data
 */
export interface CategoryData {
  active?: boolean | null;
  activeFrom?: string | null;
  activeTo?: string | null;
  addonsAttributes?: (AddonData | null)[] | null;
  delayEnableAt?: any | null;
  description?: string | null;
  discount?: number | null;
  id?: string | null;
  itemsAttributes?: (ItemData | null)[] | null;
  orderBy?: number | null;
  title?: string | null;
  deliveryDiscount?: number | null;
  takeawayDiscount?: number | null;
  menuFlags?: (any | null)[] | null;
  taxAssociations?: (TaxAssociationData | null)[] | null;
}

/**
 * The Clone input
 */
export interface CloneData {
  entityId: string;
  entityType?: CloneLevels | null;
}

/**
 * DestroyData
 */
export interface DestroyData {
  entityId: string;
  entityType: LevelsAdd;
  restaurantId: string;
}

/**
 * The Gallery Image add input
 */
export interface ImageAdd {
  name?: string | null;
  description?: string | null;
  restaurantId?: string | null;
}

/**
 * The Gallery Image add input
 */
export interface ImageMultiple {
  files?: any[] | null;
  restaurantId?: string | null;
}

/**
 * Item input data
 */
export interface ItemData {
  active?: boolean | null;
  activeFrom?: string | null;
  activeTo?: string | null;
  additiveIds?: (string | null)[] | null;
  addonsAttributes?: (AddonData | null)[] | null;
  allergenIds?: (string | null)[] | null;
  canDelivery?: boolean | null;
  canTakeaway?: boolean | null;
  delayEnableAt?: any | null;
  description?: string | null;
  hasOwnAddons?: boolean | null;
  id?: string | null;
  imageId?: string | null;
  orderBy?: number | null;
  price?: number | null;
  priceLevel1?: number | null;
  priceLevel2?: number | null;
  priceLevel3?: number | null;
  priceLevel4?: number | null;
  priceLevel5?: number | null;
  sku?: string | null;
  title?: string | null;
  menuFlags?: (any | null)[] | null;
  spiceType?: number | null;
  taxAssociations?: (TaxAssociationData | null)[] | null;
}

/**
 * The Library input
 */
export interface LibraryAdd {
  origin: LevelsLibAdd;
  originId: string;
  title?: string | null;
  favorite?: boolean | null;
}

/**
 * The Library Import Params
 */
export interface LibraryImport {
  ids: string[];
  destinationType: LevelsLibDestination;
  destinationId: string;
}

/**
 * The Library Import Params
 */
export interface LibraryUpdate {
  favorite?: boolean | null;
  id: string;
  title?: string | null;
}

/**
 * Logged entity id
 */
export interface LogsFilter {
  usernameCont?: string | null;
  auditableIdEq?: string | null;
  actionEq?: ActionType | null;
  auditableTypeEq?: LogAuditableType | null;
}

/**
 * An Menu input data
 */
export interface MenuData {
  categoriesAttributes?: (CategoryData | null)[] | null;
  description?: string | null;
  halalFriendly?: boolean | null;
  usesPopularItems?: boolean | null;
  itemsAttributes?: (ItemData | null)[] | null;
  title?: string | null;
}

/**
 * Menu Filter
 */
export interface MenuFilter {
  activeFromPresent?: boolean | null;
  activenessPresent?: boolean | null;
  discountPresent?: boolean | null;
  titleCont?: string | null;
}

/**
 * Import Menu from JSON
 */
export interface MenuImportData {
  file: any;
  restaurantId: string;
}

/**
 * An Entity input data to Add
 */
export interface MenuNodeAddData {
  restaurantId: string;
  levelType: LevelsAdd;
  entityType: LevelsAddEntry;
  entityId: string;
  category?: CategoryData | null;
  menuItem?: ItemData | null;
  addon?: AddonData | null;
  addonItem?: AddonItemData | null;
}

/**
 * An Entity input data to Update
 */
export interface MenuNodeUpdateData {
  addon?: AddonData | null;
  addonItem?: AddonItemData | null;
  category?: CategoryData | null;
  entityId: string;
  entityType: Levels;
  menu?: MenuData | null;
  menuItem?: ItemData | null;
  restaurantId: string;
}

/**
 * Publish Menu input data
 */
export interface MenuPublishData {
  restaurantId: string;
  force?: boolean | null;
}

/**
 * Add menu into Restorable 'History'
 */
export interface MenuRestoreAddData {
  restaurantId: string;
  menuId: string;
  title?: string | null;
  description?: string | null;
}

/**
 * Restore Menu input data
 */
export interface MenuRestoreData {
  menuId: string;
  restaurantId: string;
}

/**
 * Position
 */
export interface PositionData {
  id: string;
  orderBy: any;
}

/**
 * Restaurant ID
 */
export interface RerankData {
  areaId?: string | null;
  areaType?: LevelsRerank | null;
  positions: (PositionData | null)[];
  restaurantId: string;
}

/**
 * Task ID
 */
export interface ScheduleTaskDeleteData {
  restaurantId: string;
  id: string;
}

export interface ScheduleTaskUpdateData {
  restaurantId: string;
  id: string;
  runAt: any;
}

/**
 * An Menu state data
 */
export interface StateData {
  id: string;
  lastUpdatedAt?: string | null;
  lastUpdatedBy?: string | null;
  published?: boolean | null;
  publishedAt?: string | null;
  publishedBy?: string | null;
  restaurantId: string;
}

/**
 * Tax Association Input Data
 */
export interface TaxAssociationData {
  taxAssociationId?: string | null;
  taxIds: string[];
  menuCategoryId?: string | null;
  menuItemId?: string | null;
  serviceType?: ServiceType | null;
  destroy?: boolean | null;
}

/**
 * Transfer MenuItem data
 */
export interface TransferItemData {
  destinationId: string;
  itemId: string;
  position?: number | null;
  restaurantId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
