import { ModelMenuFlag } from "@model/DAO/MenuFlag";
import { MmsMultiSelectbox } from "@uiKit/Atoms/Selectbox/MmsMultiSelectbox";
import { MultiSelectboxDataType } from "@uiKit/Atoms/Selectbox/MmsMultiSelectbox/types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface MenuFlagsFormProps {
  isEditModeDisabled?: boolean;
  isAlwaysOpen: boolean;
  menuFlags: ModelMenuFlag[];
  selectedMenuFlags: ModelMenuFlag[];
  onSubmit: (menuFlags: ModelMenuFlag[]) => void;
}

const menuFlagsToMultiSelectboxDataType = (
  menuFlags: ModelMenuFlag[]
): MultiSelectboxDataType[] => {
  return menuFlags.map(({ id, title }) => ({
    label: `${title}`,
    value: id,
  }));
};

const selectedOptionsToMenuFlags = (
  options: MultiSelectboxDataType[],
  menuFlags: ModelMenuFlag[]
): ModelMenuFlag[] => {
  const optionsValues = options.map(({ value }) => value);
  return menuFlags.filter(({ id }) => optionsValues.includes(id));
};

export const MenuFlagsForm: React.FC<MenuFlagsFormProps> = ({
  isEditModeDisabled,
  isAlwaysOpen,
  menuFlags,
  selectedMenuFlags,
  onSubmit,
}) => {
  const { t } = useTranslation(["menu"]);
  const allOptions = useMemo(
    () => menuFlagsToMultiSelectboxDataType(menuFlags),
    [menuFlags]
  );
  const selectedOptions = useMemo(
    () => menuFlagsToMultiSelectboxDataType(selectedMenuFlags),
    [selectedMenuFlags]
  );

  const handleChangeMenuFlags = useCallback(
    (selectedOptions) => {
      onSubmit(selectedOptionsToMenuFlags(selectedOptions, menuFlags));
    },
    [menuFlags, onSubmit]
  );

  return (
    <MmsMultiSelectbox
      isDisabled={isEditModeDisabled}
      selectedOptions={selectedOptions}
      allOptions={allOptions}
      onChange={handleChangeMenuFlags}
      isAlwaysOpen={isAlwaysOpen}
      maxMenuHeight={225}
      placeholder={t("menu:no-menu-flags-available")}
      closeMenuOnSelect={false}
      noOptionsMessage={() => (isAlwaysOpen ? t("menu:no-options") : null)}
    />
  );
};
