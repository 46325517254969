import { cx } from "@emotion/css";
import { noop } from "@utils/noop";
import React from "react";
import { styles } from "./styles";

export interface MmsCheckboxProps {
  id: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  containerClassName?: string;
  shapeClassName?: string;
  onChange: (event: React.MouseEvent<HTMLLabelElement>) => void;
}

export const MmsCheckbox: React.FC<MmsCheckboxProps> = ({
  id,
  name,
  checked,
  disabled,
  containerClassName,
  shapeClassName,
  onChange,
}) => {
  return (
    <span className={containerClassName}>
      <input
        type="checkbox"
        className={styles.input}
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={noop}
      />
      <label
        className={cx(styles.shape, shapeClassName)}
        htmlFor={id}
        data-disabled={disabled}
        onClick={(e) => {
          if (!disabled) {
            onChange(e);
          }
        }}
      />
    </span>
  );
};
