import { gql } from "@apollo/client";

export const PUBLISH_DRAFT_MENU = gql`
  mutation PublishDraftMenuMutation(
    $input: MenuPublishData!
    $state: StateData!
  ) {
    mmsPublish(input: $input, state: $state) {
      id
      published
    }
  }
`;

interface IOptions {
  restaurantId: string;
  menuId: string;
  force: boolean;
  lastUpdatedAt: string | null;
  lastUpdatedBy: string | null;
}

export const generatePublishDraftMenuVars = ({
  restaurantId,
  menuId,
  force,
  lastUpdatedAt,
  lastUpdatedBy,
}: IOptions) => ({
  input: { restaurantId, force },
  state: { id: menuId, restaurantId, lastUpdatedAt, lastUpdatedBy },
});

export * as PublishDraftMenuTypes from "./__generated__/PublishDraftMenuMutation";
