import { gql } from "@apollo/client";
import { Levels } from "__generated__/globalTypes";

export const EDIT_MENU_ITEM_SPICE_TYPE = gql`
  mutation EditMenuItemSpiceTypeMutation($input: MenuNodeUpdateData!) {
    mmsUpdate(input: $input) {
      ... on MenuItem {
        spiceType
        id
      }
    }
  }
`;

export const generateEditMenuItemSpiceTypeVars = (
  restaurantId: string,
  menuItemId: string,
  spiceType: number
) => ({
  input: {
    restaurantId,
    entityType: Levels.MENU_ITEM,
    entityId: menuItemId,
    menuItem: {
      spiceType: parseInt(`${spiceType}`),
    },
  },
});

export * as EditMenuItemSpiceTypeTypes from "./__generated__/EditMenuItemSpiceTypeMutation";
