import { JustifyContentBetweenLayout } from "@uiKit/Layouts/JustifyContentBetweenLayout";
import React from "react";
import { styles } from "./CategoryList.styles";

export interface CategoryListProps {
  showToolbar?: boolean;
  bulkPanel?: React.ReactNode;
  filtersPanel?: React.ReactNode;
  modals?: React.ReactNode;
  children: React.ReactNode;
}

// todo: Split CategoryList and toolbar
export const CategoryList: React.FC<CategoryListProps> = ({
  showToolbar = true,
  bulkPanel,
  filtersPanel,
  modals,
  children,
}) => {
  return (
    <div className={styles.container}>
      {showToolbar && (
        <JustifyContentBetweenLayout className={styles.toolbar}>
          {filtersPanel || <div />}
          {bulkPanel || <div />}
        </JustifyContentBetweenLayout>
      )}
      {children}
      {modals}
    </div>
  );
};
