import { useReactiveVar } from "@apollo/client";
import { PUBLISH_MENU_PROGRESSBAR_ID } from "@constants";
import {
  useActiveProgressBars,
  useDidUpdate,
  useErrorCatch,
  useOpenClose,
  useToggle,
} from "@hooks";
import { ModelRestaurant } from "@model/DAO/Restaurant";
import { KnownErrorCodesEnum } from "@model/helperTypes/errors";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { useGetMenuState } from "@pages/RestaurantMenuPage/gqlHooks/menu/queries";
import { useImportFromLibrary } from "@pages/RestaurantMenuPage/hooks/useImportFromLibrary";
import { usePublishValidationErrors } from "@pages/RestaurantMenuPage/hooks/usePublishErrors";
import { SaveToHistorySettingsModal } from "@pages/RestaurantMenuPage/modals/SaveToHistorySettingsModal/SaveToHistorySettingsModal";
import { MmsOverlay } from "@uiKit/Atoms/Overlay";
import { MmsConfirmationModal } from "@uiKit/Molecules/Modals/MmsConfirmationModal";
import {
  blockingErrorsVar,
  isMenuCategoriesLoadingVar,
  menuFiltersVar,
  menuIdVar,
} from "@utils/apolloReactiveVars";
import { getErrorCode } from "@utils/errors";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MenuHeader } from "../../components/MenuHeader/MenuHeader";
import {
  useAddAssetToLibrary,
  useForceAddToLibrary,
} from "../../gqlHooks/library/mutations";
import { usePublishDraftMenu } from "../../gqlHooks/menu/mutations";
import { useHandleIncomingNotifications } from "../../hooks/useHandleIncomingNotifications";
import { usePollNotificationData } from "../../hooks/useReceiveNotificationsDataByInterval";
import { addUniqThrottledSuccessRegularNotification } from "../../hooks/useSuccessNotifications";
import { LibraryModal } from "../../modals/LibraryModal/LibraryModal";
import { generateLibraryProgressBarId } from "../../utils/Library";
import { LibrarySettingsContainer } from "../LibrarySettings/LibrarySettings";
import { SaveToHistorySettingsContainer } from "../SaveToHistorySettings/SaveToHistorySettings";
import { useTranslation } from "react-i18next";
import { useUploadMenuFromFile } from "../../hooks/useUploadMenuFromFile";

interface MenuHeaderContainerProps extends ModelRestaurant {}

export const MenuHeaderContainer: React.FC<MenuHeaderContainerProps> = ({
  name,
  status,
}) => {
  const { t } = useTranslation(["common", "menu-item"]);
  const menuId = useReactiveVar(menuIdVar);
  const menuFilters = useReactiveVar(menuFiltersVar);
  const isMenuCategoriesLoading = useReactiveVar(isMenuCategoriesLoadingVar);
  const [actionNotifications, workingUsers, state] = usePollNotificationData();
  useHandleIncomingNotifications(actionNotifications, state?.lastUpdatedAt!);
  const [, menuStateData] = useGetMenuState();
  const [
    uploadMenuFromFileMutation,
    isUploadMenuFromFileLoading,
  ] = useUploadMenuFromFile();

  const blockingErrors = useReactiveVar(blockingErrorsVar);
  const [areBlockingErrorsAppeared, setBlockingErrorsAppeared] = useState(
    false
  );

  const [shouldSaveMenuToHistory, , toggleSaveMenuToHistory] = useToggle(false);

  const [
    isSaveMenuToHistoryModalOpen,
    openSaveMenuToHistoryModal,
    closeSaveMenuToHistoryModal,
  ] = useOpenClose(false);

  const [
    ,
    publishValidationErrorMessage,
    {
      setErrors: setPublishValidationErrors,
      clearErrors: clearPublishValidationErrors,
    },
  ] = usePublishValidationErrors();

  const [
    ,
    setMenuProgressBarId,
    removeMenuProgressBarId,
  ] = useActiveProgressBars();

  const [
    publishDraftMenuMutation,
    {
      data: publishDraftMenuData,
      loading: isPublishDraftMenuLoading,
      error: publishDraftMenuError,
      clearError: clearPublishDraftMenuError,
    },
  ] = usePublishDraftMenu();

  const checkIsPublishValidationError = useCallback(
    (publishDraftMenuError) =>
      getErrorCode(publishDraftMenuError) ===
      KnownErrorCodesEnum.publishValidation,
    []
  );

  useDidUpdate(() => {
    // when error appears, and error's code is publishValidation - clear filters
    if (!publishDraftMenuError) return;
    if (!checkIsPublishValidationError(publishDraftMenuError)) return;

    // clearing filters cause refetching categories
    menuFiltersVar({});
  }, [publishDraftMenuError]);

  useDidUpdate(() => {
    // when categories and their items are loaded - process publishValidation errors
    if (!publishDraftMenuError) return;
    if (isMenuCategoriesLoading) return;

    setPublishValidationErrors(publishDraftMenuError);
    clearPublishDraftMenuError();
  }, [isMenuCategoriesLoading]);

  useDidUpdate(() => {
    // once blocking errors appeared - block `publish` button until the user reloads the page
    blockingErrors.length && setBlockingErrorsAppeared(true);
  }, [blockingErrors]);

  useDidUpdate(() => {
    // clear all publish errors when filters are changed
    clearPublishValidationErrors();
  }, [menuFilters]);

  const handlePublishDraftMenu = useCallback(
    (force) => {
      clearPublishValidationErrors();
      publishDraftMenuMutation(force);
      setMenuProgressBarId(PUBLISH_MENU_PROGRESSBAR_ID);
    },
    [
      clearPublishValidationErrors,
      publishDraftMenuMutation,
      setMenuProgressBarId,
    ]
  );

  const handlePublishDraftMenuWithHistory = useCallback(
    (force) => {
      handlePublishDraftMenu(force);
      toggleSaveMenuToHistory(true);
    },
    [handlePublishDraftMenu, toggleSaveMenuToHistory]
  );

  const [
    isLibraryModalOpen,
    openLibraryModal,
    closeLibraryModal,
  ] = useImportFromLibrary(menuId, MenuAssetEnum.menu);

  const [
    isConfirmationModalOpen,
    openConfirmationModal,
    closeConfirmationModal,
  ] = useOpenClose(false);

  const [
    addingToLibraryError,
    setAddingToLibraryError,
    libraryMenuId,
    onCleanAddingToLibraryError,
  ] = useErrorCatch(openConfirmationModal);

  const [
    onSaveMenuToLibrary,
    menuAddedToLibraryLoading,
    addedMenuId,
  ] = useAddAssetToLibrary(
    MenuAssetEnum.menu,
    menuId,
    setAddingToLibraryError,
    name
  );

  const [
    onForceSaveMenuToLibrary,
    menuForceAddedToLibraryLoading,
    forceAddedMenuId,
    onEndForceMenuAdding,
  ] = useForceAddToLibrary(MenuAssetEnum.menu, libraryMenuId, name);

  const handleForceSaveToLibrary = useCallback(() => {
    closeConfirmationModal();
    onForceSaveMenuToLibrary();
  }, [closeConfirmationModal, onForceSaveMenuToLibrary]);

  const handleCloseConfirmationModal = useCallback(() => {
    removeMenuProgressBarId(generateLibraryProgressBarId(MenuAssetEnum.menu));
    closeConfirmationModal();
  }, [closeConfirmationModal, removeMenuProgressBarId]);

  const handleCloseSaveToHistorySettingsModal = useCallback(() => {
    toggleSaveMenuToHistory(false);
    closeSaveMenuToHistoryModal();
  }, [closeSaveMenuToHistoryModal, toggleSaveMenuToHistory]);

  const handleClosePublishValidationErrorMessage = useCallback(() => {
    toggleSaveMenuToHistory(false);
  }, [toggleSaveMenuToHistory]);

  const isOverlayVisible = useMemo(
    () =>
      [isUploadMenuFromFileLoading, isPublishDraftMenuLoading].some(Boolean),
    [isPublishDraftMenuLoading, isUploadMenuFromFileLoading]
  );

  useEffect(() => {
    if (
      (!menuAddedToLibraryLoading && addedMenuId) ||
      (!menuForceAddedToLibraryLoading && forceAddedMenuId)
    ) {
      addUniqThrottledSuccessRegularNotification(
        t("menu-item:the-asset-was-successfully-added-to-library")
      );
      handleCloseConfirmationModal();
      onCleanAddingToLibraryError();
      onEndForceMenuAdding();
    }
  }, [
    addedMenuId,
    forceAddedMenuId,
    menuAddedToLibraryLoading,
    menuForceAddedToLibraryLoading,
    onCleanAddingToLibraryError,
    onEndForceMenuAdding,
    handleCloseConfirmationModal,
    t,
  ]);

  useDidUpdate(() => {
    if (isPublishDraftMenuLoading) return;
    removeMenuProgressBarId(PUBLISH_MENU_PROGRESSBAR_ID);
  }, [isPublishDraftMenuLoading]);

  useDidUpdate(() => {
    if (isUploadMenuFromFileLoading) return;
    removeMenuProgressBarId(generateLibraryProgressBarId(MenuAssetEnum.menu));
  }, [isUploadMenuFromFileLoading]);

  useEffect(() => {
    if (!shouldSaveMenuToHistory) return;
    if (!menuStateData?.restaurant.state?.published) return;

    openSaveMenuToHistoryModal();
  }, [shouldSaveMenuToHistory, menuStateData, openSaveMenuToHistoryModal]);

  return (
    <MenuHeader
      workingUsers={workingUsers}
      name={name}
      status={status}
      publishValidationErrorMessage={publishValidationErrorMessage}
      isBlockingErrorsAppeared={areBlockingErrorsAppeared}
      isPublishInProgress={isPublishDraftMenuLoading}
      isUploadMenuFromFileLoading={isUploadMenuFromFileLoading}
      isPublished={!!state?.published} // !!menuStateData?.restaurant.state?.published
      onSaveToLibrary={onSaveMenuToLibrary}
      onImportFromLibrary={openLibraryModal}
      onPublishMenu={handlePublishDraftMenu}
      onPublishMenuWithHistory={handlePublishDraftMenuWithHistory}
      onClosePublishValidationErrorMessage={
        handleClosePublishValidationErrorMessage
      }
      onUploadMenuFromFile={uploadMenuFromFileMutation}
      modals={
        <>
          {isLibraryModalOpen && (
            <LibraryModal closeModal={closeLibraryModal}>
              <LibrarySettingsContainer onFinish={closeLibraryModal} />
            </LibraryModal>
          )}
          {isConfirmationModalOpen && (
            <MmsConfirmationModal
              message={addingToLibraryError}
              confirmBtnText={t("common:update")}
              declineBtnText={t("common:cancel")}
              onConfirmBtnClick={handleForceSaveToLibrary}
              onDeclineBtnClick={handleCloseConfirmationModal}
            />
          )}
          {isSaveMenuToHistoryModalOpen &&
            publishDraftMenuData?.mmsPublish?.id && (
              <SaveToHistorySettingsModal onClose={closeSaveMenuToHistoryModal}>
                <SaveToHistorySettingsContainer
                  publishedMenuId={publishDraftMenuData.mmsPublish.id}
                  onSkip={handleCloseSaveToHistorySettingsModal}
                />
              </SaveToHistorySettingsModal>
            )}
          {isOverlayVisible && <MmsOverlay opacity={0.25} />}
        </>
      }
    />
  );
};
